import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import NodePhases from './NodePhases';
import OPFOptions from './OPFOptions';
import './HostingCapacityMenu.scss';

const NodalAnalysisInfo = {
  [ANALYSIS_TYPES.HOSTING_CAPACITY]: { type: 'generation', title: 'Hosting Capacity' },
  [ANALYSIS_TYPES.EV_CAPACITY]: { type: 'load', title: 'EV Capacity' },
};

const HostingCapacityMenu = ({
  onSubmit,
  onPreviousClick,
  canRunNetworkHostingCapacity,
  selectedAsset,
  nodes,
  simulationOptions,
  setSimulationOptions,
  selectedAnalysisType,
  substations,
  permissions,
}) => {
  const [phase, setPhase] = useState('');
  const [singleOrAll, setSingleOrAll] = useState('single');
  const [selNode, setSelNode] = useState(selectedAsset);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const theme = useContext(ThemeContext);

  const buttonState = state => setButtonDisabled(state);

  // Determine if we should disable the 'Apply' button
  const submitDisabled = () => {
    const invalidSingleNode = singleOrAll === 'single' && (!selNode.id || !phase);

    return invalidSingleNode;
  };

  const handleSubmit = () => {
    onSubmit(
      singleOrAll === 'single' ? [selNode.id] : [],
      phase,
      NodalAnalysisInfo[selectedAnalysisType].type,
    );
  };

  const getNodeOptions = () => (
    alphabetizeByKey(
      Object.entries(nodes)
        .map(([id, node]) => ({ value: id, label: node.name })),
      'label',
    )
  );

  const handleNodeChange = (option) => {
    if (!option) {
      setSelNode({});
    }
    const { value } = option;
    const node = nodes[value] || {};
    setSelNode(node);
    setPhase('');
  };

  const handleNodeTypeChange = (e) => {
    const { value } = e.target;
    setSingleOrAll(value);
    setPhase('');
  };

  return (
    <div className="hosting-capacity-menu config-panel">
      <p className="title-text">{NodalAnalysisInfo[selectedAnalysisType].title}</p>
      <div className="menu-body">
        <div className="menu-row">
          <p className="label">Nodes</p>
          <RadioButtonGroup
            id="analysis-type"
            options={[
              { id: 'single', label: 'Single' },
              { id: 'all', label: 'All', disabled: !canRunNetworkHostingCapacity },
            ]}
            theme={theme}
            listType="column"
            value={singleOrAll}
            onChange={handleNodeTypeChange}
          />
          {(permissions.has('modify_nodes_per_job') && singleOrAll === 'all') && (
            <NumberInput
              htmlFor="Number of Nodes per Job"
              id="nodes_per_HC_job"
              value={simulationOptions.nodes_per_HC_job === undefined
                ? 2 : simulationOptions.nodes_per_HC_job}
              onChange={({ value }) => {
                const val = Math.floor(value);
                setSimulationOptions({
                  ...simulationOptions,
                  nodes_per_HC_job: val,
                });
              }}
              label="Number of Nodes per Job"
              ge={1}
              theme={theme}
            />
          )}
        </div>
        {singleOrAll === 'single'
          && (
            <>
              <div className="menu-row selected-node-row">
                <p className="label">Selected Node</p>
                <Select
                  id="node-selector"
                  value={selNode.id}
                  onChange={handleNodeChange}
                  filterOption={(candidate, input) => {
                    if (input) return candidate.label.includes(input);
                    return true;
                  }}
                  loadOptions={getNodeOptions}
                  defaultOptions={selNode.id
                    ? [{ value: selNode.id, label: selNode.name }]
                    : []}
                  async
                  theme={theme}
                />
              </div>
              {selNode?.id && (
                <NodePhases node_id={selNode.id} phase={phase} setPhase={setPhase} />
              )}
            </>
          )}
      </div>
      <OPFOptions
        simulationOptions={simulationOptions}
        setSimulationOptions={setSimulationOptions}
        theme={theme}
        // Todo: Support multi substation analysis
        substationId={substations && substations.length ? substations[0].id : undefined}
        disableButton={buttonState}
        selectedAnalysisType={selectedAnalysisType}
      />

      <div className="menu-footer">
        <Button
          className="back-btn"
          id="back-btn"
          type="secondary"
          theme={theme}
          onClick={onPreviousClick}
          label="Previous"
        />

        <Button
          label="Run Analysis"
          className="apply-settings-btn"
          onClick={handleSubmit}
          disabled={buttonDisabled || submitDisabled()}
          theme={theme}
          type="primary"
        />
      </div>
    </div>
  );
};

HostingCapacityMenu.defaultProps = {
  selectedAsset: null,
  substations: [],
  nodes: {},
};

HostingCapacityMenu.propTypes = {
  selectedAnalysisType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  canRunNetworkHostingCapacity: PropTypes.bool.isRequired,
  selectedAsset: PropTypes.object,
  simulationOptions: PropTypes.object.isRequired,
  setSimulationOptions: PropTypes.func.isRequired,
  substations: PropTypes.array,
  permissions: PropTypes.object.isRequired,
  nodes: PropTypes.object,
};

export default HostingCapacityMenu;
