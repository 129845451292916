export enum UnitOfTime {
  YEARS = 'year',
  MONTHS = 'month',
  DAYS = 'day',
  HOURS = 'hour',
  MINUTES = 'minute',
}

export enum SubhourlyRanges {
  MINUTES_5 = 'Min_5',
  MINUTES_15 = 'Min_15',
  MINUTES_30 = 'Min_30',
}

export type ZoomLevel = UnitOfTime | SubhourlyRanges;

const MONTH_OPTIONS = [
  { value: 0, label: 'Jan' },
  { value: 1, label: 'Feb' },
  { value: 2, label: 'Mar' },
  { value: 3, label: 'Apr' },
  { value: 4, label: 'May' },
  { value: 5, label: 'Jun' },
  { value: 6, label: 'Jul' },
  { value: 7, label: 'Aug' },
  { value: 8, label: 'Sep' },
  { value: 9, label: 'Oct' },
  { value: 10, label: 'Nov' },
  { value: 11, label: 'Dec' },
];

const getTickCount = (
  zoomLevel: ZoomLevel, start: moment.Moment, end: moment.Moment, subHourInterval: number,
): number => {
  if (end.isSameOrBefore(start)) return 1;
  switch (zoomLevel) {
    case UnitOfTime.YEARS:
      return end.diff(start, 'years') + 1;
    case UnitOfTime.MONTHS:
      return end.diff(start, 'months') + 1;
    case UnitOfTime.DAYS:
      return end.diff(start, 'days') + 1;
    case UnitOfTime.HOURS:
      return end.diff(start, 'hours') + 1;
    case UnitOfTime.MINUTES:
      return end.diff(start, 'minutes') + 1;
    case SubhourlyRanges.MINUTES_30:
    case SubhourlyRanges.MINUTES_15:
    case SubhourlyRanges.MINUTES_5:
      const diff = end.diff(start, 'minutes') + 1;
      const subHourDiff = diff / subHourInterval;
      return Math.ceil(subHourDiff);
    default:
      return 1;
  }
};

const determineDefaultZoomLevel = (start: moment.Moment, end: moment.Moment, subHourInterval: number): ZoomLevel => {
  if ((end.year() - start.year()) >= 1) {
    return UnitOfTime.YEARS;
  }
  if ((end.month() - start.month()) >= 1) {
    return UnitOfTime.MONTHS;
  }
  if ((end.date() - start.date()) >= 1) {
    return UnitOfTime.DAYS;
  }
  if ((end.hour() - start.hour()) >= 12) {
    return UnitOfTime.HOURS;
  }
  if (subHourInterval && [5, 15, 30].includes(subHourInterval)) {
    if (subHourInterval === 30 && (end.hour() - start.hour()) <= 11) return SubhourlyRanges.MINUTES_30;
    if (subHourInterval === 15 && (end.hour() - start.hour()) <= 5) return SubhourlyRanges.MINUTES_15;
    if (subHourInterval === 5 && end.diff(start, 'minutes') <= 60) return SubhourlyRanges.MINUTES_5;
  }
  return UnitOfTime.HOURS;
};

const getSubHourIntervalType = (value: number): SubhourlyRanges => {
  let type = SubhourlyRanges.MINUTES_5;
  switch (value) {
    case 5:
      type = SubhourlyRanges.MINUTES_5;
      break;
    case 15:
      type = SubhourlyRanges.MINUTES_15;
      break;
    case 30:
      type = SubhourlyRanges.MINUTES_30;
      break;
    default:
      type = SubhourlyRanges.MINUTES_5;
  }
  return type;
};

const getIntervalSize = (value: ZoomLevel): [number, UnitOfTime] => {
  switch (value) {
    case UnitOfTime.YEARS:
      return [1, UnitOfTime.YEARS];
    case UnitOfTime.MONTHS:
      return [1, UnitOfTime.MONTHS];
    case UnitOfTime.DAYS:
      return [1, UnitOfTime.DAYS];
    case SubhourlyRanges.MINUTES_30:
      return [30, UnitOfTime.MINUTES];
    case SubhourlyRanges.MINUTES_15:
      return [15, UnitOfTime.MINUTES];
    case SubhourlyRanges.MINUTES_5:
      return [5, UnitOfTime.MINUTES];
    case UnitOfTime.HOURS:
    default:
      return [1, UnitOfTime.HOURS];
  }
};

const getStartOfInterval = (datetime: moment.Moment, zoomLevel: ZoomLevel): moment.Moment => {
  const [multiplier, unitOfTime] = getIntervalSize(zoomLevel);
  const remainder = datetime[unitOfTime]() % multiplier;
  return datetime.clone().startOf(unitOfTime).add(-remainder, unitOfTime);
};

const getEndOfInterval = (datetime: moment.Moment, zoomLevel: ZoomLevel): moment.Moment => {
  const [multiplier, unitOfTime] = getIntervalSize(zoomLevel);
  const startOfInterval = getStartOfInterval(datetime, zoomLevel);
  return startOfInterval.add(multiplier - 1, unitOfTime).endOf(unitOfTime);
};

const isHourlyOrSubHourly = (aggType: string): boolean => ['hour', 'Min_30', 'Min_15', 'Min_5'].includes(aggType);

export {
  MONTH_OPTIONS,
  getTickCount,
  determineDefaultZoomLevel,
  getSubHourIntervalType,
  getIntervalSize,
  getEndOfInterval,
  getStartOfInterval,
  isHourlyOrSubHourly,
};
