import { init as initApm } from '@elastic/apm-rum';

const apm = {};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
apm.captureError = (e) => {};
apm.configureApm = (environmentInfo) => {
  const elasticApm = initApm({
    serviceName: `webui-${environmentInfo.environment}`,
    serverUrl: environmentInfo.apmUrl,
    pageLoadTransactionName: 'reactLoad',
    environment: environmentInfo.environment,
    serviceVersion: environmentInfo.version,
  });
  apm.captureError = elasticApm.captureError.bind(elasticApm);
};

export default apm;
