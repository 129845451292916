import React from 'react';

import ACLineSegment from './ACLineSegment';
import AsynchronousMachine from './AsynchronousMachine/AsynchronousMachine';
import Battery from './Battery';
import Breaker from './Switch/Breaker';
import CHP from './SynchronousMachine/CHP';
import ConnectivityNode from './ConnectivityNode';
import Cut from './Switch/Cut';
import Disconnector from './Switch/Disconnector';
import ElectricVehicleChargingStation from './ElectricVehicleChargingStation';
import EnergyConsumer from './EnergyConsumer';
import EnergySource from './EnergySource';
import EquivalentSubstation from './EquivalentSubstation';
import Fuse from './Switch/Fuse';
import InverterPV from './InverterPV';
import Jumper from './Switch/Jumper';
import LinearShuntCompensator from './LinearShuntCompensator';
import PhotoVoltaic from './AsynchronousMachine/PhotoVoltaic';
import PowerTransformer from './PowerTransformer/PowerTransformer';
import Recloser from './Switch/Recloser';
import RunOfRiverHydro from './SynchronousMachine/RunOfRiverHydro';
import Sectionaliser from './Switch/Sectionaliser';
import Switch from './Switch';
import SynchronousMachine from './SynchronousMachine/SynchronousMachine';
import Wind from './AsynchronousMachine/Wind';

const Regulator = props => (<PowerTransformer {...props} />);

export default {
  ACLineSegment,
  AsynchronousMachine,
  Battery,
  Breaker,
  CHP,
  ConnectivityNode,
  Cut,
  Disconnector,
  ElectricVehicleChargingStation,
  EnergyConsumer,
  EnergySource,
  EquivalentSubstation,
  Fuse,
  InverterPV,
  Jumper,
  LinearShuntCompensator,
  PhotoVoltaic,
  PowerTransformer,
  Regulator,
  Recloser,
  RunOfRiverHydro,
  Sectionaliser,
  Switch,
  SynchronousMachine,
  Wind,
};
