import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Select from 'components/Select';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { IntlContext } from 'contexts/IntlContext';
import asyncActionStates from 'helpers/asyncActionStates';
import Button from 'components/Button';
import useTrackingState from 'hooks/useTrackingState';

const validationMessages = {
  lt0: 'must not be negative',
  blank: 'must be a number',
};

const CostSettingsTab = ({
  theme,
}) => {
  const { currencyCode, setCurrency, currencySymbol } = useContext(IntlContext);

  const [errMsgLoading, setErrMsgLoading] = useState('');
  const [errMsgCost, setErrMsgCost] = useState('');
  const [currencyState, setCurrencyState, currencyStateModified] = useTrackingState(currencyCode);

  const {
    settings, setSettings, settingsModified, saveSettings, loadingState,
  } = useContext(WorkspaceSettingsContext);

  useEffect(() => {
    if (loadingState === asyncActionStates.ERROR) {
      setErrMsgLoading('There was an error getting cost settings');
    } else if (loadingState === asyncActionStates.SUCCESS) {
      setErrMsgLoading('');
    }
  }, [loadingState]);

  const saveCostSetting = async () => {
    try {
      await saveSettings('cost');
      setErrMsgCost('');
    } catch (err) {
      const msg = err.response.status === 400 ? err.response.data.message : 'Failed to save changes';
      setErrMsgCost(msg);
    }
    setCurrency(currencyState);
  };

  const handleInputChange = (e, id) => {
    setSettings({ [id]: e });
  };

  const currencies = [
    { value: 'USD', label: 'US Dollar (USD)' },
    { value: 'CAD', label: 'Canada Dollar (CAD)' },
    { value: 'GBP', label: 'UK Pound (GBP)' },
    { value: 'EUR', label: 'Euro' },
  ];

  const isDisabled = (
    settings.CHLCost < 0
    || settings.CHLCost === ''
    || settings.CICost < 0
    || settings.CICost === ''
    || (!settingsModified.CICost && !settingsModified.CHLCost && !currencyStateModified)
  );

  return (
    <div className="settings-tab">
      <div className="setting-container">
        <div className="input-section">
          <span className="setting-label">Currency</span>
          <Select
            theme={theme}
            id="selectCurrency"
            key="select-currency-settings"
            options={currencies}
            disabled={false}
            value={currencyState}
            onChange={(event) => setCurrencyState(event.value)}
            clearable={false}
          />
        </div>
        <div className="cost-section">
          <div className="setting-label">
            Customer Minutes Lost Cost
          </div>
          <Input
            type="number"
            id="CHLCost"
            value={settings.CHLCost}
            onChange={(e) => handleInputChange(e, 'CHLCost')}
            theme={theme}
            unit={`${currencySymbol}/minute`}
            validation={{
              invalid: settings.CHLCost < 0 || settings.CHLCost === '',
              validationMessage: `CML Cost ${settings.CHLCost < 0 ? validationMessages.lt0 : validationMessages.blank}`,
              showValidationMessage: 'always',
            }}
            options={{
              divisor: 60,
              precision: 0,
            }}
          />
        </div>
        <div className="cost-section input-section">
          <div className="setting-label">
            Customer Interruption Cost
          </div>
          <Input
            type="number"
            id="CICost"
            value={settings.CICost}
            onChange={(e) => handleInputChange(e, 'CICost')}
            theme={theme}
            unit={`${currencySymbol}/interruption`}
            validation={{
              invalid: settings.CICost < 0 || settings.CICost === '',
              validationMessage: `CI Cost ${settings.CICost < 0 ? validationMessages.lt0 : validationMessages.blank}`,
              showValidationMessage: 'always',
            }}
            options={{
              divisor: 1,
              precision: 0,
            }}
          />
          <div className="save-container">
            <Button
              disabled={isDisabled}
              className="save-btn"
              onClick={saveCostSetting}
              label="Save"
              theme={theme}
            />
          </div>
          {errMsgLoading && (
            <div className="error-text">
              {errMsgLoading}
            </div>
          )}
          {errMsgCost && (
            <div className="error-text">
              {errMsgCost}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CostSettingsTab.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default CostSettingsTab;
