import React from 'react';
import { ReactSVG } from 'react-svg';

const NoAssetSelected = () => (
  <div className="no-asset-selected">
    <h2>Information</h2>
    <div className="panel-contents">
      <div>
        <ReactSVG
          src="/asset_info_panel_empty.svg"
          className="no-asset-icon"
        />
        <p>Select an Asset to Inspect</p>
      </div>
    </div>
  </div>
);

export default NoAssetSelected;
