import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';

const ProtectedRoute = ({
  isAuthenticated,
  children,
  isLoading,
  path,
  permissions,
  requiredPermissions,
  ...rest
}) => {
  const hasPermission = requiredPermissions
    ? requiredPermissions.every(permission => permissions.has(permission)) : true;

  if (!isLoading && !hasPermission) {
    return <Redirect to="/" />;
  }

  return (
    !isLoading && (
      <Route {...rest} path={path}>
        {isAuthenticated ? (
          children
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
    )
  );
};

ProtectedRoute.defaultProps = {
  requiredPermissions: [],
};
ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  permissions: PropTypes.object.isRequired,
  requiredPermissions: PropTypes.array,
};

const mapStateToProps = state => ({
  isAuthenticated: state.global.isAuthenticated,
  isLoading: !state.global.isAuthLoaded,
  permissions: state.global.permissions,
});

export default compose(withRouter, connect(mapStateToProps))(ProtectedRoute);
