import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './NumberInputGroup.scss';

class NumberInputGroup extends Component {
  state = {
    edited: false,
  };

  isInRange = () => {
    const {
      value, min, max, gt, lt, showValidationMessage,
    } = this.props;
    if (!this.state.edited && showValidationMessage === 'after-edit') return true;
    if (showValidationMessage === 'never') return true;
    let valid = true;
    const numVal = parseFloat(value);
    if (this.props.required && (value === '' || Number.isNaN(numVal))) {
      valid = false;
    }

    if (this.props.nonZero && numVal <= 0) {
      valid = false;
    }

    if (this.props.negNonZero && numVal >= 0) {
      valid = false;
    }

    if (min !== null && numVal < min) {
      valid = false;
    }

    if (gt !== null && numVal <= gt) {
      valid = false;
    }

    if (lt !== null && numVal >= lt) {
      valid = false;
    }

    if (max !== null && numVal > max) {
      valid = false;
    }

    if (this.props.invalid) {
      valid = !this.props.invalid;
    }
    return valid;
  };

  getErrorMessage = () => {
    const {
      value,
      min,
      max,
      lt,
      gt,
      invalid,
      validationMessage,
      required,
      nonZero,
      negNonZero,
    } = this.props;
    if (invalid) {
      if (typeof validationMessage !== 'string') {
        return validationMessage.reduce((acc, cur) => `${acc} ${cur.key}`, '');
      }
      return validationMessage;
    }
    const numVal = parseFloat(value);
    if (required && (value === '' || Number.isNaN(numVal))) {
      return 'Field is required.';
    }

    if (nonZero && numVal <= 0) {
      return 'Value must be greater than 0.';
    }

    if (negNonZero && numVal >= 0) {
      return 'Value must be less than 0.';
    }

    if (min !== null || max !== null) {
      const minMessage = min !== null ? `smaller than ${min}` : '';
      const maxMessage = max !== null ? `greater than ${max}` : '';
      return `Value must not be ${minMessage}${
        !!minMessage && !!maxMessage ? ' or ' : ''
      }${maxMessage}.`;
    }

    const minMessage = gt !== null ? `smaller than or equal to ${gt}` : '';
    const maxMessage = lt !== null ? `greater than or equal to ${lt}` : '';
    return `Value must not be ${minMessage}${
      !!minMessage && !!maxMessage ? ' or ' : ''
    }${maxMessage}.`;
  };

  handleOnInput = (e) => {
    if (!this.state.edited) {
      this.setState({ edited: true });
    }
    if (e.target.value === '-') {
      this.props.onChange(e);
    }
  };

  render() {
    const valueInRange = this.isInRange();
    let errorMessage;
    if (!valueInRange) {
      errorMessage = this.getErrorMessage();
    }
    const style = () => {
      const styleObj = {};
      if (this.props.inputWidth) styleObj.width = this.props.inputWidth;
      if (this.props.inputColor) styleObj.color = this.props.inputColor;
      return styleObj;
    };

    return (
      <div
        className={classNames({
          'number-input-group': true,
          [this.props.className]: true,
          [this.props.theme]: true,
          'number-input-group--invalid': !valueInRange || (this.state.edited && this.props.invalid),
        })}
      >
        <div className="number-input">
          {this.props.label && (
            <label htmlFor={this.props.htmlFor || this.props.id} className="number-input__label">
              {this.props.label}
              {this.props.required && this.props.showRequiredAsterisk ? ' *' : ''}
            </label>
          )}
          <div className="number-input-container" data-currency={this.props.currencySymbol}>
            <input
              id={this.props.htmlFor || this.props.id}
              type="number"
              min={this.props.min}
              max={this.props.max}
              step={this.props.step}
              className={`number-input__input ${this.props.unit ? 'number-input-with-unit' : ''}
                          ${!this.props.required && 'text-optional'}`}
              value={this.props.value}
              style={style()}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              onInput={this.handleOnInput}
              required={this.props.required}
              title={valueInRange ? null : errorMessage}
              onBlur={valueInRange && this.props.onBlur}
              onFocus={this.props.onFocus}
              name={this.props.name}
              phase={this.props.phase}
              realvalue={this.props.realValue}
            />
            {this.props.unit && <span className="number-input-unit">{this.props.unit}</span>}
          </div>
        </div>
        {!valueInRange && (
          <div className="input-error">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    );
  }
}

NumberInputGroup.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
  gt: PropTypes.number,
  lt: PropTypes.number,
  step: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  unit: PropTypes.node,
  inputWidth: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.string,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  validationMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  showValidationMessage: PropTypes.oneOf(['always', 'after-edit', 'never']),
  nonZero: PropTypes.bool,
  negNonZero: PropTypes.bool,
  showRequiredAsterisk: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  phase: PropTypes.string,
  // Optional prop hold the true value of input (before being converted to units
  realValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  htmlFor: PropTypes.string,
  inputColor: PropTypes.string,
  currencySymbol: PropTypes.string,
};

NumberInputGroup.defaultProps = {
  className: '',
  htmlFor: null,
  min: null,
  max: null,
  gt: null,
  lt: null,
  step: 'any',
  unit: null,
  inputWidth: null,
  label: null,
  disabled: false,
  nonZero: false,
  negNonZero: false,
  theme: 'dark',
  onChange: null,
  required: false,
  invalid: false,
  validationMessage: null,
  showValidationMessage: 'after-edit',
  showRequiredAsterisk: false,
  onBlur: undefined,
  onFocus: undefined,
  name: undefined,
  phase: null,
  realValue: null,
  inputColor: null,
  currencySymbol: null,
};

export default NumberInputGroup;
