import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';

import ModalPortal from 'components/ModalPortal';
import { getConfigurationType } from 'helpers/JSCIM/AssetInfo/WireSpacingInfo';

import LinkDeviceHeader from '../partials/LinkDeviceHeader';

const ACLineSegmentHeader = ({
  handleSave,
  ...props
}) => {
  const [unconfirmedChange, setUnconfirmedChange] = useState({ message: '', action: null });
  const clearUnconfirmedChange = () => setUnconfirmedChange({ message: '', action: null });

  const wire_spacing_info = props.asset.wire_spacing_info ?? {};

  const handleHeaderChange = (change) => {
    if (!change.phases) {
      handleSave(change);
      return;
    }

    const newPhases = change.phases.split('');
    const isTriplexSecondary = (getConfigurationType(wire_spacing_info) || '').includes('Triplex');
    if (isTriplexSecondary && [...newPhases].filter(el => el !== 'N').length > 1) {
      setUnconfirmedChange({
        message: (
          <>
            <p className="modal-message__p">This change will convert your triplex secondary line to a multi-phase one.</p>
            <p className="modal-message__p">As a consequence, links to geometry and conductors will be removed.</p>
          </>
        ),
        action: () => {
          handleSave({
            phases: [...newPhases].sort().join(''),
            wire_spacing_info: null,
            phase_wire_info: null,
          });
          clearUnconfirmedChange();
        },
      });
    } else {
      handleSave({ phases: newPhases.join('') });
    }
  };

  return (
    <>
      <LinkDeviceHeader
        {...props}
        handleSave={handleHeaderChange}
        allowNeutral
      />
      {unconfirmedChange.message && (
        <ModalPortal id="modal-portal">
          <Modal
            width="350px"
            active
            title="Type Change Warning"
            theme={props.theme}
            onCancel={clearUnconfirmedChange}
            onConfirm={unconfirmedChange.action}
          >
            {unconfirmedChange.message}
            <p className="modal-message__p">
              Would you like to proceed with this change?
            </p>
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

ACLineSegmentHeader.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default ACLineSegmentHeader;
