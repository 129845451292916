import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';

import SingleEditableValue from '../../Inputs/SingleEditableValue';

const noop = () => undefined;

const UnitField = ({
  onClear,
  onCopy,
  validationSchema,
  field,
  edited,
  disabled,
  editable,
  updateValue,
  value,
  handleSave,
  theme,
  ...props
}) => (
  <div className="inverter-unit-field">
    <SingleEditableValue
      {...validationSchema}
      {...field}
      {...props}
      edited={edited}
      disabled={!editable || disabled}
      onBlur={handleSave}
      onChange={updateValue}
      value={value}
    />
    {editable && [
      <IconButton
        key="copy"
        icon="file_copy"
        onClick={onCopy || noop}
        disabled={!onCopy}
        tooltip={onCopy ? 'Copy default value' : null}
        className="inverter-unit-field-button info-copy"
        theme={theme}
      />,
      <IconButton
        key="clear"
        icon="close"
        onClick={onClear || noop}
        disabled={!onClear || (!value && value !== 0)}
        tooltip={onClear && (value || value === 0) ? 'Clear value' : null}
        className="inverter-unit-field-button"
        theme={theme}
      />,
    ]}
  </div>
);

UnitField.propTypes = {
  onClear: PropTypes.func,
  onCopy: PropTypes.func,
  validationSchema: PropTypes.object,
  field: PropTypes.object.isRequired,
  edited: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  updateValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

UnitField.defaultProps = {
  onCopy: undefined,
  onClear: undefined,
  validationSchema: undefined,
  edited: undefined,
  value: undefined,
  theme: 'dark',
};

export default UnitField;
