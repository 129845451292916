import LinkDevice from './LinkDevice';
import TransformerTankInfo from '../AssetInfo/TransformerTankInfo';

const validate = (value, validationFunction, additionalParams = {}) => {
  const messages = [];
  let valid = true;
  if (typeof value !== 'object') {
    // its a single value
    const message = validationFunction(value, additionalParams);
    if (message !== '') {
      messages.push(message);
      valid = false;
    } else {
      valid = true;
    }
  } else {
    valid = {};
    // need to validate each phase
    if (value) {
      Object.entries(value).forEach(([phase, val]) => {
        const additionalParamsPhase = Object.keys(additionalParams).reduce(
          (prev, curr) => Object.assign(prev, { [curr]: additionalParams[curr][phase] }),
          {},
        );
        const message = validationFunction(val, additionalParamsPhase);
        if (message !== '') {
          messages.push(message);
          valid[phase] = false;
        } else {
          valid[phase] = true;
        }
      });
    }
  }

  return {
    valid,
    message: messages.join(' '),
  };
};

class PowerTransformer extends LinkDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'transformer';
    this._class = 'PowerTransformer';
    this.displayName = 'Transformer';
  }

  // Get the ratedS from the transformer's tank end
  get ratedS() {
    try {
      const endInfoPhases = Object.keys(this._transformerEndInfoPerPhase);

      if (endInfoPhases.length > 0) {
        return endInfoPhases.reduce((lu, phase) => {
          const endInfos = this._transformerEndInfoPerPhase[phase];
          const ratedS = endInfos
            .find(end => end.attributes['TransformerEndInfo.endNumber'] === 1)
            .attributes['TransformerEndInfo.ratedS'];

          if (phase.length > 1) {
            phase.replace('N', '').split('').forEach((phaseLetter) => {
              lu[phaseLetter] = ratedS / phase.length;
            });
          } else {
            lu[phase] = ratedS;
          }
          return lu;
        }, { A: null, B: null, C: null });
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  // Get the datasheet of each transformer tank
  get _dataSheetByPhase() {
    try {
      return this._transformerTanks.reduce((lu, tank) => {
        const phase = this.cimDict[tank.references['TransformerTank.TransformerTankEnds'][0]].attributes['TransformerTankEnd.phases'];
        let datasheetId = tank.references['PowerSystemResource.AssetDatasheet'];
        if (Array.isArray(datasheetId)) {
          [datasheetId] = datasheetId;
        }

        if (datasheetId) {
          lu[phase] = new TransformerTankInfo(datasheetId, this.cimDict);
        } else {
          lu[phase] = null;
        }

        return lu;
      }, {});
    } catch (err) {
      return {};
    }
  }

  // Get list of the transformer tank instances
  get _transformerTanks() {
    return this.extractReferenceList(this.id, 'PowerTransformer.TransformerTanks');
  }

  get _transformerEndInfoPerPhase() {
    try {
      const dataSheets = this._dataSheetByPhase;
      return this._transformerTanks.reduce((lu, tank) => {
        const phase = this.cimDict[tank.references['TransformerTank.TransformerTankEnds'][0]].attributes['TransformerTankEnd.phases'];
        const datasheet = dataSheets[phase];
        if (datasheet) {
          const endInfo = datasheet.references['TransformerTankInfo.TransformerEndInfos'];
          lu[phase] = endInfo.map(end => this.cimDict[end]);
        }

        return lu;
      }, {});
    } catch (err) {
      return {};
    }
  }

  static validationSchema = {
    targetValue: {
      customValidation: (value) => {
        const validateValue = (val) => {
          const valueDefined = val !== '';
          if (!valueDefined) {
            return 'Regulation Voltage is required.';
          }
          const validValue = (val <= 1.04 && val >= 0.96);
          if (!validValue) {
            return 'Value must be >= 0.96 and <= 1.04.';
          }

          return '';
        };

        return validate(value, validateValue);
      },
    },
    targetDeadband: {
      customValidation: (value) => {
        const validateValue = (val) => {
          const valueDefined = val !== '';
          if (!valueDefined) {
            return 'Bandwidth is required.';
          }

          const validValue = val <= 10 && val > 0;
          if (!validValue) {
            return 'Value must be > 0% and <= 10%.';
          }

          return '';
        };

        return validate(value, validateValue);
      },
    },
    normalStep: {
      customValidation: (value, { highStep, lowStep }) => {
        const validateValue = (val, { highStep: highStepVal, lowStep: lowStepVal }) => {
          const isInteger = Number.isInteger(val);
          if (!isInteger) {
            return 'Must be an integer value.';
          }
          const ltHighStep = val <= highStepVal;
          if (!ltHighStep) {
            return 'Values must be <= Max Tap Position.';
          }
          const gtLowStep = val >= lowStepVal;
          if (!gtLowStep) {
            return 'Values must be >= Min Tap Position.';
          }

          return '';
        };

        return validate(value, validateValue, { highStep, lowStep });
      },
    },
    highStep: {
      customValidation: (value, { normalStep, lowStep }) => {
        const validateValue = (val, { normalStep: normalStepVal, lowStep: lowStepVal }) => {
          const isInteger = Number.isInteger(val);
          if (!isInteger) {
            return 'Must be an integer value.';
          }

          const gtLowStep = val >= lowStepVal;
          if (!gtLowStep) {
            return 'Values must be >= Min Tap Position.';
          }

          const gtNormalStep = val >= normalStepVal;
          if (!gtNormalStep) {
            return 'Values must be >= Tap Position.';
          }

          return '';
        };

        return validate(value, validateValue, { normalStep, lowStep });
      },
    },
    lowStep: {
      customValidation: (value, { normalStep, highStep }) => {
        const validateValue = (val, { normalStep: normalStepVal, highStep: highStepVal }) => {
          const isInteger = Number.isInteger(val);
          if (!isInteger) {
            return 'Must be an integer value.';
          }

          const ltHighStep = val < highStepVal;
          if (!ltHighStep) {
            return 'Values must be < Max Tap Position.';
          }

          const ltNormalStep = val <= normalStepVal;
          if (!ltNormalStep) {
            return 'Values must be <= Tap Position.';
          }

          return '';
        };

        return validate(value, validateValue, { normalStep, highStep });
      },
    },
    neutralStep: {
      customValidation: (value, { highStep, lowStep }) => {
        const validateValue = (val, { highStep: highStepVal, lowStep: lowStepVal }) => {
          const isInteger = Number.isInteger(val);
          if (!isInteger) {
            return 'Must be an integer value.';
          }
          const ltHighStep = val <= highStepVal;
          if (!ltHighStep) {
            return 'Values must be <= Max Tap Position.';
          }
          const gtLowStep = val >= lowStepVal;
          if (!gtLowStep) {
            return 'Values must be >= Min Tap Position.';
          }

          return '';
        };

        return validate(value, validateValue, { highStep, lowStep });
      },
    },
    stepVoltageIncrement: {
      customValidation: () => ({ valid: true, message: '' }),
    },
  }
}

export default PowerTransformer;
