import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';

// Creates a single row with a label and a select menu
const SelectRow = props => (
  <div className="select-section">
    <div className="select-row">
      <div className="select-label">
        <p>
          {props.label}
          {props.isRequired ? ' *' : ''}
        </p>
      </div>
      <div className="select">
        <Select
          id={props.id}
          width={225}
          theme={props.theme}
          value={props.value}
          searchable={false}
          clearable={props.isClearable}
          options={props.options}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </div>
    </div>
  </div>
);

SelectRow.defaultProps = {
  options: [],
  onChange: null,
  disabled: false,
  isRequired: false,
  isClearable: false,
};

SelectRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
};

export default SelectRow;
