import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as globalActions from 'store/appState';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import WorkspaceSelection from '../components/WorkspaceSelection';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...globalActions.actions,
  },
  dispatch),
});

const mapStateToProps = state => ({
  workspaceSelection: state.workspaceSelection,
  authEnabled: state.global.authEnabled,
  permissions: state.global.permissions,
  theme: state.global.theme,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSelection);
