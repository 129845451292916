import React from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import PropTypes from 'prop-types';
import IconOption from 'components/IconOption';
import 'react-virtualized-select/styles.css';
import '../Select/Select.scss';
import './VirtualizeSelect.scss';

const VirtualizedReactSelect = ({
  theme, options, value, onChange, clearable, disabled,
}) => (
  <VirtualizedSelect
    className="mode-select virtualized-select"
    id="virtualized-select"
    options={options}
    value={value}
    onChange={onChange}
    clearable={clearable}
    disabled={disabled}
    theme={theme}
    components={{ Option: IconOption }}
  />
);
VirtualizedReactSelect.defaultProps = {
  theme: 'dark',
  options: [],
  value: '',
  disabled: false,
  clearable: true,
};
VirtualizedReactSelect.propTypes = {
  theme: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
};
export default VirtualizedReactSelect;
