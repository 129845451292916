import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadForecastActions } from '../../routes/Network/modules/loadForecast';
import { activityLogActions } from '../../routes/Network/modules/activityLog';
import { actions } from '../../routes/Network/modules/network';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import AnalysisSection from '../AnalysisSection';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    setSelectedAnalysis: loadForecastActions.setSelectedAnalysis,
    analysisActive: activityLogActions.analysisActive,
    setActivePanel: actions.setActivePanel,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  // From forecast state
  selectedScenario: state.loadForecast.selectedScenario,
  selectedScenarioType: state.loadForecast.selectedScenarioType,
  selectedAnalysis: state.loadForecast.selectedAnalysis,

  // From network state
  workspace: state.network.workspace,
  branch: state.network.branch,

  // From global state
  auth: {
    authEnabled: state.global.authEnabled,
    permissions: state.global.permissions,
  },
  logEntries: state.activityLog.logEntries,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisSection);
