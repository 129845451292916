import moment, { Moment, ISO_8601 } from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useQueryState<S>(
  initialState: S | (() => S),
  paramName: string,
  serialize: ((val: S) => string) | undefined = undefined,
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [stateValue, setState] = useState<S>(initialState);

  useEffect(() => {
    const queryParams = qs.parse(window.location.search.replaceAll('?', ''));

    let serializedValue = null;
    if (serialize) {
      serializedValue = serialize(stateValue);
    } else if (stateValue) {
      serializedValue = String(stateValue);
    }

    // To avoid infinite loops caused by history.replace (which triggers the history object to change)
    // Check to see if our tag is going to change and only update the query param if that is true
    if (queryParams[paramName] !== serializedValue) {
      const updatedQueryParams = {
        ...queryParams,
      };

      if (serializedValue !== null && typeof serializedValue !== 'undefined') {
        updatedQueryParams[paramName] = serializedValue;
      } else {
        delete updatedQueryParams[paramName];
      }

      const searchString = qs.stringify(updatedQueryParams);
      const newURL = `${pathname}?${searchString}`;
      history.replace(newURL);
    }
  }, [stateValue, history, paramName, pathname, serialize, search]);

  return [stateValue, setState];
}

export function serializeDate(date: Moment): string {
  return date.format('YYYY-MM-DD');
}

export function serializeDateTime(date: Moment): string {
  return date.toISOString();
}

export function getDateFromParam(params: URLSearchParams, paramName: string, defaultValue: Moment): Moment {
  const paramValue = params.get(paramName);

  if (paramValue === null) {
    return defaultValue;
  }

  const parsed = moment(paramValue, 'YYYY-MM-DD');
  return parsed.isValid() ? parsed.startOf('day') : defaultValue;
}

export function getDateTimeFromParam(params: URLSearchParams, paramName: string, defaultValue: Moment): Moment {
  const paramValue = params.get(paramName);

  if (paramValue === null) {
    return defaultValue;
  }

  const parsed = moment(paramValue, ISO_8601);
  return parsed.isValid() ? parsed : defaultValue;
}
