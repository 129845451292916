import React from 'react';
import Switch from './Switch';

// Create a value template for a Jumper.
// As there are no value difference between Jumper and Switch, we use the Swtich template
const Jumper = props => (
  <Switch {...props} />
);

export default Jumper;
