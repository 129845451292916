import React from 'react';
import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import CustomCheckbox from 'components/CustomCheckbox';
import { isDefined } from 'helpers/utils';
import Helpers from './EquipmentLibraryHelpers';
import SelectRow from '../components/SelectRow';
import {
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
} from './assetModelHelpers';

const constructionKinds = [
  { value: 'compacted', label: 'Compacted' },
  { value: 'compressed', label: 'Compressed' },
  { value: 'sector', label: 'Sector' },
  { value: 'segmental', label: 'Segmental' },
  { value: 'solid', label: 'Solid' },
  { value: 'stranded', label: 'Stranded' },
  { value: 'other', label: 'Other' },
];

const insulationTypes = [
  { value: 'asbestosAndVarnishedCambric', label: 'Asbestos and varnished cambric' },
  { value: 'butyl', label: 'Butyl' },
  { value: 'ethylenePropyleneRubber', label: 'Ethylene propylene rubber' },
  { value: 'highMolecularWeightPolyethylene', label: 'High nolecular weight polyethylene' },
  { value: 'treeResistantHighMolecularWeightPolyethylene', label: 'Tree resistant high molecular weight polyethylene' },
  { value: 'lowCapacitanceRubber', label: 'Low capacitance rubber' },
  { value: 'oilPaper', label: 'Oil paper' },
  { value: 'ozoneResistantRubber', label: 'Ozone resistant rubber' },
  { value: 'beltedPilc', label: 'Belted PILC' },
  { value: 'unbeltedPilc', label: 'Unbelted PILC' },
  { value: 'rubber', label: 'Rubber' },
  { value: 'siliconRubber', label: 'Silicon rubber' },
  { value: 'varnishedCambricCloth', label: 'Varnished cambric cloth' },
  { value: 'varnishedDacronGlass', label: 'Varnished dacron glass' },
  { value: 'crosslinkedPolyethylene', label: 'Crosslinked polyethylene' },
  { value: 'treeRetardantCrosslinkedPolyethylene', label: 'Tree retardant crosslinked polyethylene' },
  { value: 'highPressureFluidFilled', label: 'High pressure fluid filled' },
  { value: 'other', label: 'Other' },
];

const materialTypes = [
  { value: 'aaac', label: 'AAAC' },
  { value: 'acsr', label: 'ACSR' },
  { value: 'aluminum', label: 'Aluminum' },
  { value: 'aluminumAlloy', label: 'Aluminum Alloy' },
  { value: 'aluminumAlloySteel', label: 'Aluminum Alloy Steel' },
  { value: 'aluminumSteel', label: 'Aluminum Steel' },
  { value: 'copper', label: 'Copper' },
  { value: 'other', label: 'Other' },
  { value: 'steel', label: 'Steel' },
];

const outerJacketKinds = [
  { value: 'none', label: 'None' },
  { value: 'linearLowDensityPolyethylene', label: 'Linear low density polyethylene' },
  { value: 'pvc', label: 'PVC' },
  { value: 'polyethylene', label: 'Polyethylene' },
  { value: 'insulating', label: 'Insulating' },
  { value: 'semiconducting', label: 'Semiconducting' },
  { value: 'other', label: 'Other' },
];

const shieldTypes = [
  { value: 'lead', label: 'Lead' },
  { value: 'copper', label: 'Copper' },
  { value: 'steel', label: 'Steel' },
  { value: 'aluminum', label: 'Aluminum' },
  { value: 'other', label: 'Other' },
];

const getNewCable = () => ({
  description: '',
  name: '',
  material: '',
  radius: '',
  gmr: '',
  strandCount: '',
  ratedCurrent: '',
  rAC25: '',
  insulated: false,
  insulationMaterial: '',
  insulationThickness: '',
  constructionKind: '',
  outerJacketKind: 'none',
  sheathAsNeutral: false,
  shieldMaterial: '',
  assetModel: { ...(defaultAssetModel) },
  id: 'add',
  isValidShiftFactor: true,
});

const getNewConcentricNeutralCable = () => ({
  ...(getNewCable()),
  diameterOverNeutral: '',
  neutralStrandCount: '',
  neutralStrandGmr: '',
  neutralStrandRadius: '',
  neutralStrandRDC20: '',
});

const getNewTapeShieldCable = () => ({
  ...(getNewCable()),
  tapeLap: '',
  tapeThickness: '',
});

function renderCableInputs(
  inputDefaultProps,
  isDisabled,
  state,
  handleSelectChange,
  handleCheckChange,
) {
  const ohmPerMeter = '\u03A9/m';
  const material = Helpers.createDisplayObject('Material', 'material', state.material);
  const radius = Helpers.createDisplayObject('Outside Radius', 'radius', state.radius, 'm');
  const gmr = Helpers.createDisplayObject('Geometric Mean Radius', 'gmr', state.gmr, 'm');
  const strandCount = Helpers.createDisplayObject('Number of Strands', 'strandCount', state.strandCount);
  const ratedCurrent = Helpers.createDisplayObject('Rated Current', 'ratedCurrent', state.ratedCurrent, 'A');
  const rAC25 = Helpers.createDisplayObject(
    'Resistance at 25\u00B0C', 'rAC25', state.rAC25, ohmPerMeter,
  );
  const insulated = Helpers.createDisplayObject('Insulated', 'insulated', state.insulated);
  const insulationMaterial = Helpers.createDisplayObject('Insulation Material', 'insulationMaterial', state.insulationMaterial);
  const insulationThickness = Helpers.createDisplayObject('Insulation Thickness', 'insulationThickness', state.insulationThickness);
  const constructionKind = Helpers.createDisplayObject('Construction', 'constructionKind', state.constructionKind);
  const outerJacketKind = Helpers.createDisplayObject('Outer Jacket', 'outerJacketKind', state.outerJacketKind);
  const shieldMaterial = Helpers.createDisplayObject('Shield Material', 'shieldMaterial', state.shieldMaterial);
  const sheathAsNeutral = Helpers.createDisplayObject('Sheath Used as Neutral?', 'sheathAsNeutral', state.sheathAsNeutral);

  return (
    <>
      <TextInput
        id="name"
        required
        label="Name"
        value={state.name}
        {...inputDefaultProps}
        onChange={({ target }) => inputDefaultProps.onChange({ id: target.id, value: target.value })}
      />
      <SelectRow
        {...material}
        {...inputDefaultProps}
        options={materialTypes}
        disabled={isDisabled}
        onChange={e => handleSelectChange(material.id, e.value)}
        isRequired
      />
      <NumberInput
        ge={0.000001}
        {...inputDefaultProps}
        {...radius}
        required
        inputStyle="eq-lib"
      />
      <NumberInput
        ge={0.000001}
        {...inputDefaultProps}
        {...gmr}
        required
        inputStyle="eq-lib"
      />
      <NumberInput
        ge={1}
        step="1"
        {...inputDefaultProps}
        {...strandCount}
        required
        inputStyle="eq-lib"
      />
      <NumberInput
        ge={0.001}
        {...inputDefaultProps}
        {...ratedCurrent}
        required
        inputStyle="eq-lib"
      />
      <NumberInput
        ge={0.000001}
        {...inputDefaultProps}
        {...rAC25}
        required
        inputStyle="eq-lib"
      />
      <div className="checkbox-row">
        <div className="checkbox-label">
          <p>{insulated.label}</p>
        </div>
        <div className="checkbox">
          <CustomCheckbox
            {...insulated}
            disabled={isDisabled}
            onClick={handleCheckChange}
            checked={state[insulated.id]}
            value={insulated.id}
          />
        </div>
      </div>
      {state.insulated
        && (
        <>
          <SelectRow
            {...insulationMaterial}
            {...inputDefaultProps}
            options={insulationTypes}
            disabled={isDisabled}
            isRequired
            onChange={e => handleSelectChange(insulationMaterial.id, e.value)}
          />
          <NumberInput
            ge={0.000001}
            {...inputDefaultProps}
            {...insulationThickness}
            required
            inputStyle="eq-lib"
          />
        </>
        )}
      <SelectRow
        {...constructionKind}
        {...inputDefaultProps}
        options={constructionKinds}
        disabled={isDisabled}
        isRequired
        onChange={e => handleSelectChange(constructionKind.id, e.value)}
      />
      <SelectRow
        {...outerJacketKind}
        {...inputDefaultProps}
        options={outerJacketKinds}
        disabled={isDisabled}
        isRequired
        onChange={e => handleSelectChange(outerJacketKind.id, e.value)}
      />
      <div className="checkbox-row">
        <div className="checkbox-label">
          <p>{sheathAsNeutral.label}</p>
        </div>
        <div className="checkbox">
          <CustomCheckbox
            disabled={isDisabled}
            onClick={handleCheckChange}
            checked={state[sheathAsNeutral.id]}
            value={sheathAsNeutral.id}
            {...sheathAsNeutral}
          />
        </div>
      </div>
      <SelectRow
        {...shieldMaterial}
        {...inputDefaultProps}
        options={shieldTypes}
        disabled={isDisabled}
        onChange={e => handleSelectChange(shieldMaterial.id, e.value)}
        isRequired
        isClearable
      />
    </>
  );
}

function areBaseCableValuesUpdated(state, selected) {
  if (!selected) return true;
  const stringBoolUpdated = [
    'name', 'description', 'material', 'insulated',
    'constructionKind', 'outerJacketKind', 'shieldMaterial', 'sheathAsNeutral',
  ].some(val => `${selected[val]}` !== `${state[val]}`);
  const numberUpdated = ['radius', 'gmr', 'strandCount', 'ratedCurrent', 'rAC25']
    .some(val => selected[val] !== parseFloat(state[val]));
  let insulationUpdated = false;
  if (state.insulated) {
    insulationUpdated = ['insulationMaterial', 'insulationThickness'].some(val => (
      `${selected[val]}` !== `${state[val]}`
    ));
  }
  const assetModelUpdated = !areAssetModelsEqual(
    getAssetModelProperties(selected.AssetModel),
    state.assetModel,
  );
  return stringBoolUpdated || numberUpdated || insulationUpdated || assetModelUpdated;
}

function isBaseCableValid(state) {
  const positiveValue = val => (val?.toString().length ?? 0) > 0 && parseFloat(val) > 0;

  return (
    state.name.trim().length > 0
    && state.material.length > 0
    && positiveValue(state.radius)
    && positiveValue(state.gmr)
    && positiveValue(state.strandCount)
    && positiveValue(state.ratedCurrent)
    && positiveValue(state.rAC25)
    && isDefined(state.insulated)
    && (
      !state.insulated
      || (
        state.insulationMaterial.length > 0
        && positiveValue(state.insulationThickness)
      )
    )
    && state.constructionKind.length > 0
    && state.outerJacketKind.length > 0
    && state.shieldMaterial.length > 0
    && isAssetModelValid(state.assetModel)
    && isDefined(state.sheathAsNeutral)
  );
}

function getBaseCableInputValues(selected) {
  let asset = { ...(getNewCable()) };
  if (selected) {
    const {
      name, description, material, radius, gmr, strandCount,
      ratedCurrent, rAC25, insulated, insulationMaterial, insulationThickness,
      constructionKind, outerJacketKind, shieldMaterial,
      sheathAsNeutral, AssetModel, id,
    } = selected;
    asset = {
      description: description || asset.description,
      name: name || asset.name,
      material: material || asset.material,
      radius: `${radius}` || asset.radius,
      gmr: `${gmr}` || asset.gmr,
      strandCount: `${strandCount}` || asset.strandCount,
      ratedCurrent: `${ratedCurrent}` || asset.ratedCurrent,
      rAC25: `${rAC25}` || asset.rAC25,
      insulated: insulated || asset.insulated,
      insulationMaterial: insulationMaterial || asset.insulationMaterial,
      insulationThickness: `${insulationThickness}` || asset.insulationThickness,
      constructionKind: constructionKind || asset.constructionKind,
      outerJacketKind: outerJacketKind || asset.outerJacketKind,
      sheathAsNeutral: sheathAsNeutral || asset.sheathAsNeutral,
      shieldMaterial: shieldMaterial || asset.shieldMaterial,
      assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
      id,
    };
  }
  return asset;
}

function createEditDiffModel(selected, diffModel) {
  // Only submit the values that have changed
  const keys = Object.keys(diffModel);
  const editDiffModel = keys.reduce((diff, key) => {
    if (
      key === 'shieldMaterial'
      && (diffModel.shieldMaterial === 'undefined' && selected.shieldMaterial !== undefined)
    ) {
      diff[key] = null;
    } else if (key === 'AssetModel') {
      if (!areAssetModelsEqual(diffModel[key], selected[key])) {
        diff[key] = diffModel[key];
      }
    } else if (`${diffModel[key]}` !== `${selected[key]}`) {
      diff[key] = diffModel[key];
    }
    return diff;
  }, {});
  if (!diffModel.insulated && selected.insulated) {
    editDiffModel.insulationMaterial = null;
    editDiffModel.insulationThickness = null;
  }
  return editDiffModel;
}

function createBaseCableDiffModel(state) {
  let diffModel = {
    description: state.description,
    name: state.name,
    material: state.material,
    radius: parseFloat(state.radius),
    gmr: parseFloat(state.gmr),
    strandCount: parseFloat(state.strandCount),
    ratedCurrent: parseFloat(state.ratedCurrent),
    rAC25: parseFloat(state.rAC25),
    insulated: state.insulated,
    constructionKind: state.constructionKind,
    outerJacketKind: state.outerJacketKind,
    sheathAsNeutral: state.sheathAsNeutral,
    shieldMaterial: state.shieldMaterial,
    AssetModel: { ...state.assetModel },
  };

  if (state.insulated) {
    diffModel = {
      ...diffModel,
      insulationMaterial: state.insulationMaterial,
      insulationThickness: parseFloat(state.insulationThickness),
    };
  }

  return diffModel;
}

export {
  areBaseCableValuesUpdated,
  constructionKinds,
  createBaseCableDiffModel,
  createEditDiffModel,
  getBaseCableInputValues,
  insulationTypes,
  isBaseCableValid,
  materialTypes,
  getNewCable,
  getNewConcentricNeutralCable,
  getNewTapeShieldCable,
  outerJacketKinds,
  renderCableInputs,
  shieldTypes,
};
