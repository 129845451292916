import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { activityLogActions } from '../modules/activityLog';
import { actions } from '../modules/network';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ActivityLogPanel from '../components/SlidingInfoPanel/ActivityLogPanel';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    cancelJob: activityLogActions.cancelJob,
    getBatteryAnalysisResults: actions.getBatteryAnalysisResults,
  }, dispatch),
});

const mapStateToProps = state => ({
  ...state.activityLog,
  containers: state.feeders.list,
  selectedContainers: state.feeders.selected,
  theme: state.global.theme,
  permissions: state.global.permissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogPanel);
