import React, {
  useState, useEffect, useMemo, createContext,
} from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';

export const IntlContext = createContext();

export default function IntlContextProvider({ children, workspace }) {
  // this component supports in memory but no persistent state if there is no workspace selected.
  const [locale, setLocale] = useState('en-US');
  const [currencyCode, setCurrencyCode] = useState('USD');
  const settingsLocation = `WORKSPACE_${workspace}_SETTINGS`;

  const loadSettings = useMemo(
    () => location => {
      const str = localStorage.getItem(location);

      let settings;
      try {
        settings = JSON.parse(str) ?? {};
      } catch (err) {
        settings = {};
      }
      return settings;
    },
    [],
  );

  function loadIntl() {
    const settings = workspace ? loadSettings(settingsLocation) : {};
    setLocale(settings.locale ?? 'en-US');
    setCurrencyCode(settings.currency?.currencyCode ?? 'USD');
  }

  function persistCurrencyChanges(currency) {
    setCurrencyCode(currency);
    if (workspace) {
      const settings = loadSettings(settingsLocation);
      settings.currency = { currencyCode: currency };
      localStorage.setItem(settingsLocation, JSON.stringify(settings));
    }
  }

  useEffect(loadIntl, [workspace, settingsLocation, loadSettings]);

  const formatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
  });

  const valFormatter = new Intl.NumberFormat(locale, {
    notation: 'scientific',
    compactDisplay: 'short',
    maximumFractionDigits: 3,
  });

  const unitFormatter = (value, unit = null) => {
    const siFormatter = Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
      maximumFractionDigits: 2,
    });
    const valueParts = siFormatter.formatToParts(value);
    const parts = [];
    let prefix = '';
    valueParts.forEach(part => {
      if (part.type === 'compact') {
        // proper SI form for engineering units is G not B
        prefix = part.value.replace('B', 'G');
      } else if (part.type === 'unit') {
        unit = part.value;
      } else {
        parts.push(part.value);
      }
    });

    // attach the prefix to the unit if there is one, otherwise to the value
    const formatString = unit != null ? [' ', prefix, unit] : [prefix];
    parts.push(...formatString);
    return parts.join('');
  };

  return (
    <IntlContext.Provider
      value={{
        locale,
        currencyCode,
        setLocale: () => {},
        setCurrency: persistCurrencyChanges,
        currencyFormatter: formatter,
        currencySymbol: formatter.formatToParts(0).find(x => x.type === 'currency').value,
        valueFormatter: valFormatter,
        unitFormatter,
      }}
    >
      {children}
    </IntlContext.Provider>
  );
}

IntlContextProvider.propTypes = {
  workspace: nullable(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
};
