import React from 'react';
import PropTypes from 'prop-types';

// Create an input with search icon
const SearchInput = ({
  onChange, value, placeholder, theme,
}) => (
  <div className={`search-container ${theme}`}>
    <span className="search-input-wrapper">
      <input
        className="search-input"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </span>
  </div>
);

SearchInput.defaultProps = {
  value: undefined,
  placeholder: '',
  theme: 'dark',
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default SearchInput;
