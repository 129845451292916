/**
 * Determine if the given key is defined in the provided lookup object
 * @param  {String}  key     Key name to check the lookup for
 * @param  {Object}  lookup  Object containing all of the defined values
 * @return {Boolean}
 */
const hasKey = (key, lookup) => {
  const valueTypes = Object.keys(lookup);
  const value = lookup[key];
  return valueTypes.includes(key) && value !== null && value !== undefined;
};

/**
 * Determine if any of the list of values provided are defined in the provided
 * lookup object
 * @param  {Array}  types List of key names to check the lookup for
 * @param  {Object} lookup  Object containing all of the defined values
 * @return {Boolean}
 */
const hasValues = (types, lookup) => (
  types.some((i) => {
    let noValue = !lookup || lookup[i] === undefined || lookup[i] === null || lookup[i] === '';
    if (!noValue && typeof lookup[i] === 'object') {
      const values = Object.entries(lookup[i]);
      // Determine if object has any values and if those values match phases
      noValue = values.length === 0;
    }
    return !noValue;
  })
);

export default {
  hasValues,
  hasKey,
};
