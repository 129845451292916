import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ThemeContext from 'helpers/ThemeContext';

class WorkspaceLayout extends Component {
  componentDidMount() {
    this.props.actions.setCurrentWorkspace(this.props.match.params.workspace);
    this.props.actions.updateSelectedBranch(this.props.match.params.branch);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.match.params.workspace && prevProps.match.params.workspace !== this.props.match.params.workspace)
      || (this.props.network.workspace
        && prevProps.network.workspace !== this.props.network.workspace)
      || prevProps.network.branch !== this.props.network.branch
    ) {
      this.props.actions.setCurrentWorkspace(this.props.match.params.workspace);
    } else if (prevProps.view !== this.props.view) {
      // always update the workspace and branch if the view changes
      if (this.props.match.params.workspace) {
        this.props.actions.setCurrentWorkspace(this.props.match.params.workspace);
        if (this.props.match.params.branch && this.props.match.params.branch !== prevProps.match.params.branch) {
          this.props.actions.updateSelectedBranch(this.props.match.params.branch);
        }
      }
    }
  }

  renderContents = () => this.props.children;

  render() {
    const { theme } = this.props;
    const mainTheme = theme || 'light';
    return (
      <ThemeContext.Provider value={mainTheme}>
        <div className={`workspace-container ${theme}`}>
          {this.renderContents()}
        </div>
      </ThemeContext.Provider>
    );
  }
}

WorkspaceLayout.propTypes = {
  network: PropTypes.shape({
    workspace: PropTypes.string,
    branch: PropTypes.string,
  }).isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  theme: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
};
export default withRouter(WorkspaceLayout);
