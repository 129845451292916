import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from 'store/appState';
import Login from '../components/Login';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getAuthProvider: appActions.actions.getAuthProvider,
    updatePermissions: appActions.actions.updatePermissions,
  }, dispatch),
});

const mapStateToProps = state => ({
  authProvider: state.global.authProvider,
  authWorkspaceMismatch: state.global.authWorkspaceMismatch,
  theme: state.global.theme,
  workspaceForTenant: state.global.workspaceForTenant,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
