import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const PlusIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height,
  width,
}) => (
  <svg style={{ pointerEvents: 'none' }} height={height} width={width} x="0px" y="0px" viewBox="0 0 35 35">
    <g id="zoom_out" transform="translate(-1354.5 -2898)">
      <polygon
        fill={color}
        points="1377.5,2914.5 1373,2914.5 1373,2910 1371,2910 1371,2914.5 1366.5,2914.5 1366.5,2916.5 1371,2916.5 1371,2921 1373,2921 1373,2916.5 1377.5,2916.5"
      />
    </g>
  </svg>
);

export default PlusIcon;
