import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

// Creates a file input that looks like an icon button
class IconFileInput extends PureComponent {
  renderIconFileInput() {
    const {
      rotate, icon, disabled, theme, id,
    } = this.props;

    return (
      <div className={`icon-file-input-container ${theme} ${disabled ? 'disabled' : ''}`}>
        <label htmlFor={id}>
          <div>
            <span className="icon-container">
              <i
                {...(rotate && rotate > 0 && rotate < 360
                  ? { style: { transform: `rotate(${Math.trunc(rotate)}deg)` } }
                  : {})}
                className="material-icons"
              >
                {icon}
              </i>
            </span>
          </div>
          <input
            type="file"
            id={id}
            onChange={(event) => {
              this.props.onChange(event);
              // clear the input so that the file can be uploaded again
              event.currentTarget.value = null;
            }}
            accept={this.props.accept}
            disabled={this.props.disabled}
          />
        </label>
      </div>
    );
  }

  render() {
    return this.props.tooltip ? (
      <Tooltip content={this.props.tooltip} theme={this.props.theme}>
        {this.renderIconFileInput()}
      </Tooltip>
    ) : (
      <>
        {this.renderIconFileInput()}
      </>
    );
  }
}

IconFileInput.defaultProps = {
  rotate: 0,
  disabled: false,
  tooltip: null,
};

IconFileInput.propTypes = {
  rotate: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  accept: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default IconFileInput;
