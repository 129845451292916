import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'components/Button';
import ActionButtons from 'components/ActionButtons';
import DocumentationLink from 'components/DocumentationLink';
import Icons from 'components/Icons';
import Input from 'components/Input';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import Tooltip from 'components/Tooltip';
import Request from 'helpers/Request';
import { pluralize } from 'helpers/utils';
import { useRequestEffect } from 'hooks/useRequest';
import { BETA_PERMISSION } from 'helpers/permissions';
import { ScenarioTypes } from 'helpers/scenarios';
import { ANALYSIS_TYPES, FEEDER, SUBSTATION } from '../../helpers/NetworkHelpers';
import AnalysisTile from './AnalysisTile';
import ContainerSelection from './ContainerSelection';
import ControlSelectorList from './ControlSelectorList';
import { getMaxTime } from './DateHelpers';
import DateTimeSelector from './DateTimeSelector';
import HostingCapacityMenu from './HostingCapacityMenu';
import OptimalBatteryMenu from './OptimalBatteryMenu';
import QSTSMenu from './QSTSMenu';
import SimulationExampleGraph from './SimulationExampleGraph';
import ControlModesMenu from './ControlModesMenu';

const DETAILS = 'details';
const ANALYSIS_SELECTION = 'analysis_selection';
const {
  POWERFLOW, QSTS, QSTS_OPF, QSTS_NWE, HOSTING_CAPACITY, EV_CAPACITY, BATTERY_SIZING,
} = ANALYSIS_TYPES;

const getTimeRangeOptions = (lastSimulationRange) => {
  const lastSimulationDisabled = !lastSimulationRange.start || !lastSimulationRange.end;
  return [
    { label: 'Timebar', value: 'default' },
    { label: 'Last Simulation', value: 'last-simulation', disabled: lastSimulationDisabled },
    { label: 'Scenario Length', value: 'scenario-length' },
    { label: 'Custom', value: 'custom' },
  ];
};

const interpolationOptions = [
  { label: 'Linear', value: 'linear' },
  { label: 'Next Observation Carried Backward', value: 'stepBefore' },
  { label: 'Last Observation Carried Forward', value: 'stepAfter' },
];
const samplingOptions = [
  { label: 'Hold First Value', value: 'holdFirstValue' },
  { label: 'Weighted Average', value: 'weightedAverage' },
];

const existingResultsMessage = (hasResults) => {
  if (hasResults) {
    return (
      <p className="error existing-results">
        Warning: You are modifying an existing an analysis.
        The analysis will rerun and old results will be lost if you continue.
      </p>
    );
  }
  return null;
};

const getFirstAnalysisName = (usedAnalysisNames) => {
  for (let i = 1; i < 10000; i += 1) {
    const newName = `New Analysis ${i}`;
    if (!usedAnalysisNames.includes(newName)) {
      return newName;
    }
  }
  return 'New Analysis';
};

const addContainer = (allContainers, selectedContainers, containerToAdd) => {
  if (containerToAdd.type === FEEDER) {
    if (!containerToAdd.substation) {
      // new selection is only this feeder
      return [containerToAdd];
    }

    if (!selectedContainers.find(container => container.id === containerToAdd.substation)) {
      const sub = allContainers.find(container => container.id === containerToAdd.substation);
      // new selection is the substation and this feeder
      return [sub, containerToAdd];
    }

    // add the feeder
    return selectedContainers.concat([containerToAdd]);
  } if (containerToAdd.type === SUBSTATION) {
    const feeders = allContainers.filter(container => container.substation === containerToAdd.id);
    // new selection === substation + all feeders
    return [containerToAdd, ...feeders];
  }

  return [];
};

const removeContainer = (selectedContainers, containerToRemove) => {
  if (containerToRemove.type === FEEDER) {
    if (containerToRemove.substation && selectedContainers.length > 2) {
      return selectedContainers.filter(container => container.id !== containerToRemove.id);
    }
  }

  return [];
};

const getValidContainers = (selectedContainers, allFeeders) => {
  // verify against the two cases of valid containers
  // 1. substation with child feeders
  // 2. indepedent feeder
  // all other cases will clear the container selection
  const substations = selectedContainers.filter(container => container.type === SUBSTATION);
  const feeders = selectedContainers.filter(container => container.type === FEEDER);
  if (substations.length === 1) {
    if (!feeders.some(container => container.substation !== substations[0].id)) {
      // if all feeders are part of the substation containers are valid
      return selectedContainers;
    }
  } else if (substations.length === 0 && feeders.length === 1) {
    if (!feeders[0].substation) {
      // we have an independent feeder so containers are valid
      return selectedContainers;
    }
    const sub = allFeeders.find(container => container.id === feeders[0].substation);
    return selectedContainers.concat([sub]);
  } else if (substations.length === 0 && feeders.length > 1) {
    let allFeedSub;
    const feedersWithSub = feeders.filter(f => {
      allFeedSub = allFeeders.find(container => container.id === f.substation);
      return allFeedSub;
    });
    if (allFeedSub) {
      // if all feeders are part of any substation
      return feedersWithSub.concat([allFeedSub]);
    }
  }
  return [];
};

const AnalysisConfiguration = ({
  permissions,
  theme,
  scenarioRange,
  maxRange,
  lastSimulationRange,
  selectedScenario,
  selectedScenarioType,
  scenarioHasData,
  selectedAsset,
  selectedFeeders,
  feeders,
  handleRunHostingCapacity,
  handleRunPowerflow,
  handleRunOptimalBattery,
  hasResults,
  usedAnalysisNames,
  analyses,
  workspace,
  branch,
}) => {
  const { WarningIcon } = Icons;
  /* State */
  const [startRange, setStartRange] = useState(moment(maxRange.start ?? scenarioRange.start));
  const [endRange, setEndRange] = useState(moment(maxRange.end ?? scenarioRange.end));
  const [selectedAnalysisType, setSelectedAnalysisType] = useState(null);
  const [controlmodesInValid, setControlModesInValid] = useState(true);
  const [currentPanel, setCurrentPanel] = useState(ANALYSIS_SELECTION);
  const [simulationOptions, setSimulationOptions] = useState({
    NETWORK_REDUCTION_SHORT_LENGTH: 10,
    NETWORK_REDUCTION_MESH_LIMIT: 30,
    INTERVAL: 60,
    INTERPOLATION: 'stepAfter',
    SAMPLING_MODE: 'holdFirstValue',
    GENERATE_ANALYSIS_ARTIFACTS: 'ON_FAILURE',
    ANALYSIS_SCHEDULE_SELECTIONS: [],
  });
  const [timeMode, setTimeMode] = useState('default');
  const [analysisName, setAnalysisName] = useState(getFirstAnalysisName(usedAnalysisNames));
  const [simulatedOptionsEdited, setSimulationOptionsEdited] = useState({
    NETWORK_REDUCTION_SHORT_LENGTH: false,
    NETWORK_REDUCTION_MESH_LIMIT: false,
    INTERVAL: false,
    INTERPOLATION: false,
    SAMPLING_MODE: false,
    GENERATE_ANALYSIS_ARTIFACTS: false,
  });
  const [selectedContainers, setSelectedContainers] = useState(getValidContainers(selectedFeeders, feeders));
  const [nodeList, setNodeList] = useState({});
  const [containersNeedScheduleData, setContainersNeedScheduleData] = useState([]);
  const allContainersHaveScheduleData = async (scenario) => {
    // Substations aren't required to have scenario data.
    const selectedFeedersFromModal = selectedContainers.filter(c => c.type === FEEDER);
    try {
      const req = new Request(`/api/workspace/${workspace}/branch/${branch}/asset_schedule`);
      const resp = await req.get({
        params: {
          scenario_id: scenario,
          schedule_type: 'Feeder',
        },
      });
      const scheduledContainers = Object.keys(resp.data);
      setContainersNeedScheduleData(
        selectedFeedersFromModal.filter(f => !scheduledContainers.includes(f.id)).map(f => f.name),
      );
    } catch (err) {
      // if we cannot get schedules for any containers, they all are missing schedules
      setContainersNeedScheduleData(selectedFeedersFromModal.map(f => f.name));
    }
  };
  useEffect(() => {
    setStartRange(moment(maxRange.start ?? scenarioRange.start).startOf('hour'));
    setEndRange(moment(maxRange.end ?? scenarioRange.end));
    if (selectedScenario) {
      allContainersHaveScheduleData(selectedScenario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxRange, selectedScenario, scenarioRange.start, scenarioRange.end, selectedContainers]);

  /* Analysis Type Options */
  const invalidTimeseriesScenario = (
    !selectedScenario || !scenarioHasData || selectedScenarioType !== ScenarioTypes.timeseries
  );
  const containersHaveScheduleData = selectedScenario && containersNeedScheduleData.length === 0;

  const intervalOptions = [
    { label: '60mins', value: 60, disabled: false },
    { label: '30mins', value: 30, disabled: !permissions.has('run_powerflow_qsts_subhourly') },
    { label: '15mins', value: 15, disabled: !permissions.has('run_powerflow_qsts_subhourly') },
    { label: '5mins', value: 5, disabled: !permissions.has('run_powerflow_qsts_subhourly') },
  ];

  const options = [
    {
      value: POWERFLOW,
      label: 'Powerflow',
      disabled: selectedScenarioType !== ScenarioTypes.snapshot,
    },
    {
      value: QSTS,
      label: 'Timeseries Powerflow',
      disabled: invalidTimeseriesScenario || !containersHaveScheduleData || selectedContainers.length === 0,
    },
    {
      value: QSTS_OPF,
      label: 'Optimal Powerflow',
      disabled: invalidTimeseriesScenario || !containersHaveScheduleData || selectedContainers.length === 0,
    },
    {
      value: QSTS_NWE,
      label: 'Non-Wires Evaluation',
      disabled: invalidTimeseriesScenario || !containersHaveScheduleData || selectedContainers.length === 0,
    },
    {
      value: HOSTING_CAPACITY,
      label: 'Hosting Capacity',
      permissions: ['run_hosting_capacity', 'run_hosting_capacity_day', 'run_network_hosting_capacity', 'run_network_hosting_capacity_day'],
      disabled: (
        (
          !permissions.has('run_hosting_capacity')
          && !permissions.has('run_hosting_capacity_day')
          && !permissions.has('run_network_hosting_capacity')
          && !permissions.has('run_network_hosting_capacity_day')
        )
        || invalidTimeseriesScenario
        || !containersHaveScheduleData || selectedContainers.length === 0
      ),
    },
    {
      value: EV_CAPACITY,
      label: 'EV Capacity',
      permissions: ['run_hosting_capacity', 'run_hosting_capacity_day', 'run_network_hosting_capacity', 'run_network_hosting_capacity_day'],
      disabled: (
        (
          !permissions.has('run_hosting_capacity')
          && !permissions.has('run_hosting_capacity_day')
          && !permissions.has('run_network_hosting_capacity')
          && !permissions.has('run_network_hosting_capacity_day')
        )
        || invalidTimeseriesScenario
        || !containersHaveScheduleData || selectedContainers.length === 0
      ),
    },
    {
      value: BATTERY_SIZING,
      label: 'Battery Sizing',
      permissions: ['run_battery_sizing', 'run_battery_sizing_day'],
      disabled: (
        (
          !permissions.has('run_battery_sizing')
          && !permissions.has('run_battery_sizing_day')
        )
        || invalidTimeseriesScenario
        || !containersHaveScheduleData || selectedContainers.length === 0
      ),
    },
  ];

  /* Validation */
  const MAX_TIME = useMemo(() => getMaxTime(permissions, startRange, simulationOptions.INTERVAL),
    [permissions, startRange, simulationOptions.INTERVAL]);

  const selectionTooLarge = selectedAnalysisType
      && endRange.isAfter(MAX_TIME[selectedAnalysisType]);

  const invalidDate = endRange.isBefore(startRange) || selectionTooLarge;

  const getTooltipMessage = (analysisType, analysisTypeName) => {
    if (analysisType === POWERFLOW && selectedScenarioType !== ScenarioTypes.snapshot) {
      return 'Please select a snapshot scenario to run a powerflow analysis';
    }

    if (analysisType !== POWERFLOW && selectedScenarioType !== ScenarioTypes.timeseries) {
      return `Please select a timeseries scenario to run ${analysisTypeName}`;
    }

    if (!scenarioHasData) {
      return `The selected scenario has no data. Please select a scenario with data to run ${analysisTypeName}`;
    }

    if (containersNeedScheduleData.length > 0) {
      return `${pluralize('Feeder', containersNeedScheduleData.length)} ${containersNeedScheduleData.join(', ')} need${containersNeedScheduleData.length > 1 ? '' : 's'} schedule data to run this analysis.`;
    }

    if (selectedContainers.length === 0) {
      return 'Please select Feeder';
    }
    return null;
  };

  const getValidEndDate = () => {
    const maxDuration = MAX_TIME[selectedAnalysisType];
    const endInRange = maxDuration.isSameOrBefore(scenarioRange.end);
    const validEnd = endInRange ? maxDuration : scenarioRange.end;
    return validEnd.format('YYYY-MM-DD HH:mm');
  };

  /* Event Handlers */
  const handleEndDateChange = (updatedEnd) => {
    let end = moment(updatedEnd);

    if (end.isAfter(scenarioRange.end)) {
      end = scenarioRange.end;
    } else if (end.isBefore(scenarioRange.start)) {
      end = scenarioRange.start;
    }

    setEndRange(end);
    setTimeMode('custom');
  };

  const handleStartDateChange = (updatedStart) => {
    let start = moment(updatedStart);

    if (start.isAfter(scenarioRange.end)) {
      start = scenarioRange.end;
    } else if (start.isBefore(scenarioRange.start)) {
      start = scenarioRange.start;
    }

    let end = endRange.isBefore(start) ? moment(start).add(23, 'h') : endRange;
    if (end.isAfter(scenarioRange.end)) {
      end = scenarioRange.end;
    }

    setStartRange(start.startOf('hour'));
    setEndRange(end);
    setTimeMode('custom');
  };

  const handleTimeSelectChange = (newValue) => {
    setTimeMode(newValue);
    switch (newValue) {
      case 'default':
        setStartRange(moment(maxRange.start ?? scenarioRange.start).startOf('hour'));
        setEndRange(moment(maxRange.end ?? scenarioRange.end));
        break;
      case 'scenario-length':
        setStartRange(moment(scenarioRange.start).startOf('hour'));
        setEndRange(moment(scenarioRange.end));
        break;
      case 'last-simulation':
        setStartRange(moment(lastSimulationRange.start).startOf('hour'));
        setEndRange(moment(lastSimulationRange.end));
        break;
      case 'custom':
      default:
        break;
    }
  };

  const handlePreviousClick = () => setCurrentPanel(ANALYSIS_SELECTION);

  const noConfigRequired = [POWERFLOW, QSTS].includes(selectedAnalysisType);

  const showDateSelector = (
    selectedScenario && selectedAnalysisType && selectedAnalysisType !== POWERFLOW
  );
  const selectedSubstations = selectedContainers.filter(container => container.type === SUBSTATION);

  // indicates whether all assets which require a schedule have one selected
  const allRequiredSchedulesSelected = (
    Object.values(simulationOptions.ANALYSIS_SCHEDULE_SELECTIONS ?? {})
      .every(x => !!x.analysis_id)
  );

  const getRunErrorMessage = () => {
    if (selectedContainers.length === 0) {
      return 'Select one or more feeders with a single substation to run analysis';
    }
    if (!allRequiredSchedulesSelected) {
      return 'One or more assets requires an analysis to be selected as it is configured to use an analysis schedule';
    }
    if (invalidDate) {
      return 'The specified date range is invalid';
    }
    if (!selectedAnalysisType) {
      return 'An analysis type must be selected to run a simulation';
    }
    return '';
  };

  const { data: nodeObjects } = useRequestEffect({
    url: `/api/workspace/${workspace}/branch/${branch}/class/ConnectivityNode`,
    method: 'get',
    refetchOnChange: [workspace, branch],
    initialData: {},
    toast: {
      error: 'An error occurred loading the node list',
    },
  });
  useEffect(() => {
    const getNodeList = () => {
      const containerList = selectedContainers.map(c => c.id);
      const selectedNodes = {};
      try {
        if (containerList.length > 0) {
          Object.keys(nodeObjects)
            .forEach(nodeID => {
              if (containerList.includes(nodeObjects[nodeID].references['ConnectivityNode.ConnectivityNodeContainer'])) {
                selectedNodes[nodeID] = {
                  id: nodeID,
                  name: nodeObjects[nodeID].attributes['IdentifiedObject.name'],
                  class: 'ConnectivityNode',
                };
              }
            });
        }
      } catch (err) {
      }
      setNodeList(selectedNodes);
    };
    getNodeList();
  }, [selectedContainers, nodeObjects]);

  return (
    <div className="analysis-configuration">
      { currentPanel === ANALYSIS_SELECTION && (
        <>
          <section className="analysis-name-section">
            <p>Analysis Name</p>
            <TextInput
              id="analysis-name-input"
              value={analysisName}
              onChange={e => setAnalysisName(e.target.value)}
              theme={theme}
              required
              invalid={usedAnalysisNames.includes(analysisName)}
              validationMessage="Analysis name must be unique and at least 1 character"
            />
          </section>
          <section className="feeder-selection-section">
            <p className="analysis-message">Feeders and Substations</p>
            <ContainerSelection
              selectedContainers={selectedContainers}
              allContainers={feeders}
              addContainer={containerToAdd => setSelectedContainers(
                addContainer(feeders, selectedContainers, containerToAdd),
              )}
              removeContainer={containerToRemove => setSelectedContainers(
                removeContainer(selectedContainers, containerToRemove),
              )}
            />
          </section>
          <section className="analysis-selection-section">
            <p className="analysis-message">Select analysis to run</p>

            {existingResultsMessage(hasResults)}

            <div className="analysis-options">
              {options.map(option => (
                <AnalysisTile
                  option={option}
                  key={option.value}
                  onClick={e => setSelectedAnalysisType(e.target.id)}
                  active={option.value === selectedAnalysisType && selectedContainers.length > 0}
                  theme={theme}
                  permissionDenied={
                    !!option.permissions
                    && !option.permissions.some(permission => permissions.has(permission))
                  }
                  tooltipMessage={getTooltipMessage(option.value, option.label)}
                />
              ))}
            </div>
          </section>

          {showDateSelector && (
            <>
              <div className="date-range">
                <p>Results Interval</p>
                <Select
                  id="results-interval"
                  className="date-range-select"
                  disabled={
                    selectedAnalysisType === HOSTING_CAPACITY
                    || selectedAnalysisType === EV_CAPACITY
                    || selectedAnalysisType === BATTERY_SIZING
                  }
                  value={simulationOptions.INTERVAL}
                  options={intervalOptions}
                  theme={theme}
                  clearable={false}
                  onChange={e => {
                    setSimulationOptions({
                      ...simulationOptions,
                      INTERVAL: e.value,
                    });
                    setSimulationOptionsEdited({
                      ...simulatedOptionsEdited,
                      INTERVAL: true,
                    });
                  }}
                />
              </div>
              { [5, 15, 30].includes(simulationOptions.INTERVAL) && (
              <div className="date-range">
                <span className="message-text">
                  <WarningIcon color="#F3A200" height="18px" width="18px" />
                  <span>
                    {'Subhourly analyses are implemented as a combination of optimization problems so results may not be optimal. '}
                    <DocumentationLink
                      className="help-link"
                      documentationPath="powerflow/advanced/analysis-limitations"
                    >
                      Learn more
                    </DocumentationLink>
                  </span>
                </span>
              </div>
              )}
              <div className="date-range">
                <p>Analysis Time Range</p>
                <Select
                  id="time-range-type"
                  className="date-range-select"
                  value={timeMode}
                  options={getTimeRangeOptions(lastSimulationRange)}
                  theme={theme}
                  clearable={false}
                  onChange={e => handleTimeSelectChange(e.value)}
                />
              </div>
              <div className="date-range">
                <DateTimeSelector
                  label="Start"
                  theme={theme}
                  handleChange={handleStartDateChange}
                  date={startRange}
                  minDate={scenarioRange.start}
                  maxDate={scenarioRange.end}
                />
                <DateTimeSelector
                  label="End"
                  theme={theme}
                  handleChange={handleEndDateChange}
                  date={endRange}
                  minDate={scenarioRange.start}
                  maxDate={scenarioRange.end}
                  endMinutes={59}
                />
              </div>
              {startRange.format('z') !== 'UTC' && <p className="caption-text">* Time Displayed in UTC</p>}
              {invalidDate && (
                <p className="error">
                  {!selectionTooLarge && <span>Invalid Date Range.</span>}
                  {selectionTooLarge && (
                    <span id="selection-too-big">
                      {`Selection is too large. End date must be before ${getValidEndDate()}`}
                    </span>
                  )}
                </p>
              )}
              <section>
                <p className="analysis-message">Select analyses for assets with a Previous Analysis control mode</p>
                <ControlSelectorList
                  workspace={workspace}
                  branch={branch}
                  selectedContainers={selectedContainers}
                  analyses={analyses}
                  setSelectedPreviousSimulations={val => setSimulationOptions({
                    ...simulationOptions,
                    ANALYSIS_SCHEDULE_SELECTIONS: val,
                  })}
                />
              </section>
            </>
          )}
          {(permissions.has('modify_network_reduction_short_length') || permissions.has('modify_network_reduction_mesh_limit')) && (
            <section>
              <p className="analysis-message">Simulation Options</p>
              <div>
                {permissions.has('modify_network_reduction_short_length') && (
                  <Input
                    id="networkReductionShortLength"
                    label={(
                      <span className="option-label">
                        Short Line Length
                        <Tooltip placement="left" content="Longest line length that is considered short enough to be reduced.">
                          <i className="material-icons help-icon">help_outline</i>
                        </Tooltip>
                      </span>
                    )}
                    type="number"
                    unit="m"
                    editable
                    edited={simulatedOptionsEdited.NETWORK_REDUCTION_SHORT_LENGTH}
                    value={simulationOptions.NETWORK_REDUCTION_SHORT_LENGTH}
                    options={{
                      divisor: 1,
                    }}
                    validation={{
                      min: 0,
                      required: true,
                    }}
                    onChange={e => {
                      if (!Number.isNaN(e)) {
                        setSimulationOptions({
                          ...simulationOptions,
                          NETWORK_REDUCTION_SHORT_LENGTH: e,
                        });
                        setSimulationOptionsEdited({
                          ...simulatedOptionsEdited,
                          NETWORK_REDUCTION_SHORT_LENGTH: true,
                        });
                      }
                    }}
                    theme={theme}
                  />
                )}
                {permissions.has('modify_network_reduction_mesh_limit') && (
                  <Input
                    id="networkReductionMeshLimit"
                    label={(
                      <span className="option-label">
                        Mesh Limit
                        <Tooltip placement="left" content="Length of the longest side of a triangle mesh that will be reduced.">
                          <i className="material-icons help-icon">help_outline</i>
                        </Tooltip>
                      </span>
                    )}
                    type="number"
                    unit="m"
                    editable
                    edited={simulatedOptionsEdited.NETWORK_REDUCTION_MESH_LIMIT}
                    value={simulationOptions.NETWORK_REDUCTION_MESH_LIMIT}
                    validation={{
                      min: 0,
                      required: true,
                    }}
                    onChange={
                      e => {
                        if (!Number.isNaN(e)) {
                          setSimulationOptions({
                            ...simulationOptions,
                            NETWORK_REDUCTION_MESH_LIMIT: e,
                          });
                          setSimulationOptionsEdited({
                            ...simulatedOptionsEdited,
                            NETWORK_REDUCTION_MESH_LIMIT: true,
                          });
                        }
                      }
                    }
                    theme={theme}
                  />
                )}
                {(
                  permissions.has('view_analytics_simulation_debug_data')
                  || permissions.has('view_analytics_simulation_debug_summary')
                ) && (
                  <Select
                    options={[
                      { label: 'Always', value: 'ALWAYS' },
                      { label: 'On Failure', value: 'ON_FAILURE' },
                      { label: 'Never', value: 'NEVER' },
                    ]}
                    value={simulationOptions.GENERATE_ANALYSIS_ARTIFACTS}
                    label={(
                      <p className="option-label">
                        Generate Analysis Artifacts
                        <Tooltip
                          placement="left"
                          content="Specifies when optimization artifacts should be persisted."
                        >
                          <i className="material-icons help-icon">help_outline</i>
                        </Tooltip>
                      </p>
)}
                    labelPosition="left"
                    width="120px"
                    clearable={false}
                    theme={theme}
                    onChange={e => {
                      setSimulationOptions({
                        ...simulationOptions,
                        GENERATE_ANALYSIS_ARTIFACTS: e.value,
                      });
                      setSimulationOptionsEdited({
                        ...simulatedOptionsEdited,
                        GENERATE_ANALYSIS_ARTIFACTS: true,
                      });
                    }}
                  />
                )}
              </div>
            </section>
          )}
          {selectedAnalysisType && selectedAnalysisType !== ANALYSIS_TYPES.POWERFLOW && (
            <>
              <div id="interpolation" style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="data-interpolation">
                    <p>Results Interpolation Type</p>
                    <Select
                      className="data-interpolation-select"
                      value={simulationOptions.INTERPOLATION}
                      options={interpolationOptions}
                      theme={theme}
                      clearable={false}
                      onChange={e => {
                        setSimulationOptions({
                          ...simulationOptions,
                          INTERPOLATION: e.value,
                        });
                        setSimulationOptionsEdited({
                          ...simulatedOptionsEdited,
                          INTERPOLATION: true,
                        });
                      }}
                    />
                  </div>
                  <div className="data-interpolation">
                    <p>Sampling Mode</p>
                    <Select
                      className="data-interpolation-select"
                      value={simulationOptions.SAMPLING_MODE}
                      options={samplingOptions}
                      theme={theme}
                      clearable={false}
                      onChange={e => {
                        setSimulationOptions({
                          ...simulationOptions,
                          SAMPLING_MODE: e.value,
                        });
                        setSimulationOptionsEdited({
                          ...simulatedOptionsEdited,
                          SAMPLING_MODE: true,
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <SimulationExampleGraph
                    interpolationType={interpolationOptions.find(opt => opt.value === simulationOptions.INTERPOLATION)}
                    samplingType={samplingOptions.find(opt => opt.value === simulationOptions.SAMPLING_MODE)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="footer">
            <Button
              onClick={() => {
                if (noConfigRequired && !permissions.has(BETA_PERMISSION)) {
                  handleRunPowerflow(
                    selectedContainers,
                    analysisName,
                    selectedAnalysisType,
                    { start: startRange, end: endRange },
                    simulationOptions,
                  );
                } else {
                  setCurrentPanel(DETAILS);
                }
                setSimulationOptionsEdited({
                  NETWORK_REDUCTION_SHORT_LENGTH: false,
                  NETWORK_REDUCTION_MESH_LIMIT: false,
                  INTERVAL: false,
                  INTERPOLATION: false,
                  SAMPLING_MODE: false,
                });
              }}
              id="analysis-config-footer-btn"
              label={noConfigRequired && !permissions.has(BETA_PERMISSION) ? 'Run Analysis' : 'Next'}
              title={getRunErrorMessage()}
              disabled={
                invalidDate
                || !selectedAnalysisType
                || selectedContainers.length === 0
                || !allRequiredSchedulesSelected
              }
              theme={theme}
            />
          </div>
        </>
      )}

      {/* Analysis Specific Config Panels */}
      {currentPanel === DETAILS && permissions.has(BETA_PERMISSION) && (
        <div>
          <ControlModesMenu
            workspace={workspace}
            branch={branch}
            selectedContainers={selectedContainers}
            onChange={(args) => {
              if (JSON.stringify(args) !== JSON.stringify(simulationOptions.CONTROL_MODES)) {
                setControlModesInValid(Object.values(args)
                  .some((asset) => asset.control_mode === null));
                setSimulationOptions({
                  ...simulationOptions,
                  CONTROL_MODES: args,
                });
              }
            }}
            selectedAnalysisType={selectedAnalysisType}
          />
          { noConfigRequired && (
            <ActionButtons
              className="analysis-action-btns"
              submitLabel="Run Analysis"
              submitBtnId="submit-qsts"
              onClickBackBtn={handlePreviousClick}
              disableSubmit={controlmodesInValid}
              onClickSubmitBtn={() => {
                handleRunPowerflow(
                  selectedContainers,
                  analysisName,
                  selectedAnalysisType,
                  { start: startRange, end: endRange },
                  simulationOptions,
                );
              }}
            />
          )}
        </div>
      )}
      {(currentPanel === DETAILS
        && (selectedAnalysisType === QSTS_OPF || selectedAnalysisType === QSTS_NWE))
        && (
        <QSTSMenu
          onSubmit={(objective, extraSimulationOptions = {}) => (
            handleRunPowerflow(
              selectedContainers,
              analysisName,
              selectedAnalysisType,
              { start: startRange, end: endRange },
              { ...simulationOptions, ...extraSimulationOptions },
              objective,
            )
          )}
          substations={selectedSubstations}
          onPreviousClick={handlePreviousClick}
          simulationOptions={simulationOptions}
          setSimulationOptions={setSimulationOptions}
          selectedAnalysisType={selectedAnalysisType}
        />
        )}
      {(currentPanel === DETAILS && [HOSTING_CAPACITY, EV_CAPACITY].includes(selectedAnalysisType))
        && (
        <HostingCapacityMenu
          selectedAnalysisType={selectedAnalysisType}
          onSubmit={(...args) => (
            handleRunHostingCapacity(
              selectedContainers, analysisName,
              ...args, { start: startRange, end: endRange }, simulationOptions,
            )
          )}
          canRunNetworkHostingCapacity={
            permissions && (
              permissions.has('run_network_hosting_capacity')
              || permissions.has('run_network_hosting_capacity_day')
            )
          }
          onPreviousClick={handlePreviousClick}
          nodes={nodeList}
          selectedAsset={selectedAsset && selectedAsset.class === 'ConnectivityNode' ? selectedAsset : {}}
          simulationOptions={simulationOptions}
          setSimulationOptions={setSimulationOptions}
          substations={selectedSubstations}
          permissions={permissions}
        />
        )}
      {(currentPanel === DETAILS && selectedAnalysisType === BATTERY_SIZING) && (
        <OptimalBatteryMenu
          onSubmit={(...args) => {
            handleRunOptimalBattery(
              selectedContainers, analysisName, ...args,
              { start: startRange, end: endRange }, simulationOptions,
            );
          }}
          onPreviousClick={handlePreviousClick}
          nodes={nodeList}
          selectedAsset={selectedAsset && selectedAsset.class === 'ConnectivityNode' ? selectedAsset : {}}
          permissions={permissions}
        />
      )}
    </div>
  );
};

AnalysisConfiguration.defaultProps = {
  permissions: new Set([]),
  theme: 'dark',
  selectedScenario: null,
  selectedAsset: null,
  scenarioHasData: false,
};

AnalysisConfiguration.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  theme: PropTypes.string,
  scenarioRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  lastSimulationRange: PropTypes.object.isRequired,
  handleRunPowerflow: PropTypes.func.isRequired,
  handleRunHostingCapacity: PropTypes.func.isRequired,
  handleRunOptimalBattery: PropTypes.func.isRequired,
  selectedScenario: PropTypes.string,
  selectedScenarioType: PropTypes.string.isRequired,
  scenarioHasData: PropTypes.bool,
  hasResults: PropTypes.bool.isRequired,
  selectedAsset: PropTypes.object,
  selectedFeeders: PropTypes.array.isRequired,
  feeders: PropTypes.array.isRequired,
  usedAnalysisNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  analyses: PropTypes.array.isRequired,
};

export default AnalysisConfiguration;
