import React, {
  ReactElement, FunctionComponent, ReactChild, ReactNode,
} from 'react';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import Button from '../Button';
import { ThemeProp } from '../../types/index';

import './IconButton.scss';

type IconButtonProps = {
  className?: string,
  id?: string,
  theme?: ThemeProp,
  disabled?: boolean,
  icon?: string|Element,
  onClick: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  active?: boolean,
  hasNotification?: boolean,
  round?: boolean,
  rotate?: number,
  tooltip?: ReactElement | string;
  children?: ReactChild|ReactNode;
  loading?: boolean,
};

const BaseButton: FunctionComponent<IconButtonProps> = ({
  theme,
  onClick,
  active = false,
  disabled = false,
  hasNotification = false,
  round = false,
  className = '',
  children = null,
  rotate = null,
  icon = null,
  id = '',
  loading = false,
}) => (
  <div className={`icon-btn-container ${theme} ${className}`}>
    <Button
      type="primary"
      theme={theme}
      className={classNames({
        'icon-btn': true,
        'icon-btn--active': active,
        [className]: true,
        round,
      })}
      id={id}
      disabled={disabled}
      onClick={onClick}
    >
      {hasNotification && <div className="has-notification">&nbsp;</div>}
      {icon ? (
        <span className="icon-container">
          <i
            {...(rotate && rotate > 0 && rotate < 360
              ? { style: { transform: `rotate(${Math.trunc(rotate)}deg)` } }
              : {})}
            className="material-icons"
          >
            {icon}
          </i>
        </span>
      ) : (
        <div className="icon-container">
          {
            loading ? <i className="material-icons rotate">refresh</i> : children
          }
        </div>
      )}
    </Button>
  </div>
);

const IconButton: FunctionComponent<IconButtonProps> = ({
  tooltip = null, ...props
}) => (tooltip ? (
  <Tooltip content={tooltip} theme={props.theme}>
    <BaseButton {...props} />
  </Tooltip>
) : (
  <BaseButton {...props} />
));

export default IconButton;
