import React, {
  createContext, useState, useMemo, useContext,
} from 'react';

type ContextState = {
  baselineResults: any,
  setBaselineResults: (key: string, obj: any) => void,
}

export const ResultsComparisonContext = createContext<ContextState>({
  baselineResults: {},
  setBaselineResults: () => {},
});

interface ResultsComparisonProvider {
  children: JSX.Element
}

export function useBaseline<T>(key: string) : { baseline: T, setBaseline: (baseline: T) => void } {
  const { baselineResults, setBaselineResults } = useContext(ResultsComparisonContext);
  const setBaseline = useMemo(() => (obj: T) => {
    setBaselineResults(key, obj);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBaselineResults, key]);

  return {
    baseline: baselineResults[key] as T,
    setBaseline,
  };
}

export default function ResultsComparisonContextProvider({ children }: ResultsComparisonProvider): JSX.Element {
  const [results, setResults] = useState({});
  const setBaselineResults = useMemo(() => (key: string, obj: any) => {
    setResults((prevState) => ({
      ...prevState,
      [key]: obj,
    }));
  }, []);

  return (
    <ResultsComparisonContext.Provider
      value={{
        baselineResults: results,
        setBaselineResults,
      }}
    >
      {children}
    </ResultsComparisonContext.Provider>
  );
}
