import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SelectRow from '../../SelectRow';

import OverheadConductor from './OverheadConductor';
import TapeShieldCable from './TapeShieldCable';
import ConcentricNeutralCablePanel from './ConcentricNeutralCable';
import MultiConductor from './MultiConductor';
import CablePanel from './Cable';
import EquipmentBadge from '../../EquipmentBadge';

import '../common.scss';
import '../../EquipmentBadge.scss';

const conductorPanels = {
  OverheadWireInfo: OverheadConductor,
  ConcentricNeutralCableInfo: ConcentricNeutralCablePanel,
  TapeShieldCableInfo: TapeShieldCable,
  WireInfo: MultiConductor,
  CableInfo: CablePanel,
};

const options = [
  { label: 'Overhead Conductor', value: 'OverheadWireInfo' },
  { label: 'Cable', value: 'CableInfo' },
  { label: 'Concentric Neutral', value: 'ConcentricNeutralCableInfo' },
  { label: 'Tape-Shield Cable', value: 'TapeShieldCableInfo', disabled: true },
  { label: 'Multiconductor', value: 'WireInfo' },
];

const NewConductorPanel = (props) => {
  const [newConductorType, setNewConductorType] = useState('OverheadWireInfo');
  const NewInstanceInfo = conductorPanels[newConductorType];
  return (
    <NewInstanceInfo {...props}>
      <SelectRow
        id="conductorTypeSelector"
        theme={props.theme}
        value={newConductorType}
        label="Conductor Type"
        onChange={({ value }) => (setNewConductorType(value))}
        options={options.map(({ value, label }) => ({
          value,
          label: (
            <div className="equipment-select-option" title={label}>
              <p className="equipment-select-option-label">{label}</p>
              <EquipmentBadge equipmentInfoItem={{ name: '', class: value }} />
            </div>
          ),
        }))}
      />
    </NewInstanceInfo>
  );
};

NewConductorPanel.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default NewConductorPanel;
