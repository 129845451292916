const MODAL_TYPES = {
  TOGGLE: 'toggle',
  SAVEAS: 'saveAs',
  VERSION: 'networkVersion',
  VIEW: 'view',
  WORKSPACE: 'workspace',
};

const NEW_BRANCH = 'new_branch';
const OLD_BRANCH = 'old_branch';
const DISCARD = 'discard';

export {
  MODAL_TYPES,
  NEW_BRANCH,
  OLD_BRANCH,
  DISCARD,
};
