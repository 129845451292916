import React from 'react';
import PropTypes from 'prop-types';
import './EquipmentTypeTile.scss';

const AddEquipmentTile = (props) => {
  const {
    selected, theme, addEquipment, equipmentType,
  } = props;
  const dataSelected = selected ? 'equipment-select-row__selected' : '';
  return (
    <div className={`equipment-select-row ${dataSelected}`}>
      <span className="add-info-icon"><i className="material-icons">add</i></span>
      <button
        title="Add"
        className={`equipment-type-tile ${theme}`}
        onClick={() => addEquipment(equipmentType, 'add')}
        data-selected={selected}
        type="button"
      >
        <p className="equipment-type-label">Add New</p>
      </button>
    </div>

  );
};

AddEquipmentTile.defaultProps = {
  addEquipment: null,
  selected: false,
};

AddEquipmentTile.propTypes = {
  equipmentType: PropTypes.string.isRequired,
  addEquipment: PropTypes.func,
  selected: PropTypes.bool,
  theme: PropTypes.string.isRequired,
};
export default AddEquipmentTile;
