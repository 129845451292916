/* eslint jsx-a11y/no-static-element-interactions: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StandardMenuItem from './StandardMenuItem';

/**
 * Component that creates a generic dropdown menu.
 * Must be passed an element to act as a trigger (this.props.children)
 * @class DropdownMenu
 */
class DropdownMenu extends Component {
  state = {
    open: false,
  }

  optionTypes = {
    standard: 'standard',
    seperator: 'seperator',
  }

  componentDidMount() {
    if (this.props.open && this.props.menuOptions.length) {
      this.setState({ open: true }, () => {
        document.addEventListener('click', this.closeMenu);
      });
    }
  }

  componentDidUpdate(prevProps) {
    // If the open prop changes, this means there is a programmatic opening (true)
    // or closing (false) of the dropdown menu.
    if (prevProps.open !== this.props.open && !this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  // Set the correct icon type from the dropdown item instance.
  getIcon = (isSelected, icons) => (isSelected ? icons.highlighted : icons.basic);

  // Set the correct icon type from the dropdown item instance.
  getIcon = (isSelected, icons) => (isSelected ? icons.highlighted : icons.basic);

  /**
   * Take an array of menu item definitions and turns them into JSX representations.
   * @return {Array} List of JSX representing the menu items
   */
  createMenuItems = () => {
    const options = this.props.menuOptions.map((c) => {
      switch (c.type) {
        case this.optionTypes.standard:
          return this.createStandard(c);
        case this.optionTypes.seperator:
          return <div className="seperator" key={c.id} />;
        default:
          return null;
      }
    });
    return options;
  }

  showMenu = () => {
    if (this.props.menuOptions.length) {
      this.setState({ open: true }, () => {
        document.addEventListener('click', this.closeMenu);
      });
      if (this.props.onToggle) {
        this.props.onToggle(true, this.props.id);
      }
    }
  }

  closeMenu = () => {
    this.setState({ open: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
    if (this.props.onToggle) {
      this.props.onToggle(false, this.props.id);
    }
  }

  /**
   * Builds a standard menu item. Menu item acts as a button and triggers
   * the click callback.
   * Item can have an icon, a set theme, a specific height and a set callback for clicks.
   * @param  {Object}  option  Menu item being constructed.
   */
  createStandard(option) {
    const isSelected = this.props.selected.some(s => s === option.id);
    const icon = option.icon ? this.getIcon(isSelected, option.icon) : null;
    const itemProps = {
      selected: isSelected,
      icon,
      toggle: this.props.onClick,
      disabled: option.disabled,
      disabledMessage: option.disabledMessage,
    };
    return (
      <StandardMenuItem
        active={this.props.open}
        key={option.id}
        data={option}
        height={this.props.itemHeight}
        theme={this.props.theme}
        {...itemProps}
      />
    );
  }

  render() {
    // Remove no height items from height calculation
    const withContent = this.props.menuOptions.filter(o => o.type !== this.optionTypes.seperator);
    const noContent = this.props.menuOptions.filter(o => o.type === this.optionTypes.seperator);
    const height = (withContent.length * this.props.itemHeight) + (noContent.length * 4);
    return (
      <div className={`dropdown ${this.props.theme}`}>
        {this.props.children(this.showMenu)}
        {this.state.open ? (
          <div
            style={{ ...this.props.contentPosition, height }}
            className={`dropdown-content ${this.props.theme}`}
          >
            <div>
              {this.createMenuItems()}
            </div>
          </div>
        ) : (null)}
      </div>
    );
  }
}

DropdownMenu.defaultProps = {
  menuOptions: [],
  itemHeight: 35,
  selected: [],
  onToggle: null,
  open: false,
  theme: 'light',
  contentPosition: {},
  onClick: null,
};

DropdownMenu.propTypes = {
  menuOptions: PropTypes.array,
  selected: PropTypes.array,
  itemHeight: PropTypes.number,
  /* Callback for click on menu item */
  onClick: PropTypes.func,
  /* Callback for click on menu icon */
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  /* Color theme class name */
  theme: PropTypes.string,
  id: PropTypes.string.isRequired,
  /* The offset from the positiong of the toggle icon. Used to position menu items */
  contentPosition: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
    bottom: PropTypes.string,
  }),
  /* If the menu is disabled, used to prevent click events */
  children: PropTypes.func.isRequired,
};
export default DropdownMenu;
