import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

const AddAssetButton = ({
  icon,
  name,
  id,
  selected,
  addingAsset,
  disabled,
  onClick,
  className,
  active,
}) => (
  <div className={classNames('add-icon-container', { 'add-icon-container--disabled': disabled, [className]: className })}>
    <button
      onClick={e => onClick(e)}
      id={id}
      className={classNames('icon-button', { 'icon-button--active': active })}
      disabled={disabled}
      type="button"
    >
      <ReactSVG
        className={classNames(`icon-img icon-img--${id}`, { 'icon-img--disabled': disabled })}
        src={`/${icon}.svg`}
      />
      {(selected === id && addingAsset)
        && (
        <div className="loading-icon">
          <i className="material-icons rotate">refresh</i>
        </div>
        )}
    </button>
    <p className="icon-label">{name}</p>
  </div>
);

AddAssetButton.defaultProps = {
  selected: null,
  addingAsset: false,
  disabled: false,
  className: undefined,
  active: false,
};

AddAssetButton.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.string,
  addingAsset: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
};

export default AddAssetButton;
