import React from 'react';
import PropTypes from 'prop-types';

import './UserPanel.scss';
import UserList from '../../../components/UserList';

const UserPanel = ({
  disabled,
  displayAlertMessage,
  open,
  workspace,
}) => {
  const onError = (tryAgain) => {
    displayAlertMessage(
      'Failed to retrieve user list',
      'Could not retrieve user list Please try again.',
      null,
      () => tryAgain(),
    );
  };

  // Analytics Config State
  return (
    <div className="workspace-card-tab-panel user-panel">
      <UserList
        canRemoveUser={false}
        workspace={workspace}
        disabled={!open || disabled}
        loadingErrorHandler={onError}
      />
    </div>
  );
};

UserPanel.defaultProps = {
  disabled: true,
  open: false,
};

UserPanel.propTypes = {
  disabled: PropTypes.bool,
  displayAlertMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  workspace: PropTypes.string.isRequired,
};

export default UserPanel;
