import React, { FunctionComponent, ReactNode, ReactChild } from 'react';
import { ThemeProp } from '../../types/index';

type CardProps = {
  theme: ThemeProp,
  buttonGroup?: ReactNode|null,
  className?: string,
  children?: ReactChild|ReactNode,
  hideTitle?: boolean,
  title?: string,
  secondaryTitle?: string,
};
const Card: FunctionComponent<CardProps> = ({
  buttonGroup = null,
  className = '',
  children = null,
  title = '',
  secondaryTitle = '',
  hideTitle = false,
  theme = 'light',
}) => (
  <div className={`card ${className} ${theme}`}>
    <header className={!hideTitle ? 'border' : ''}>
      <div className="card-menu">
        {!hideTitle && (
          <div className="title-menu">
            <h3 className="title">{title}</h3>
            <h4 className="secondary-title">{secondaryTitle}</h4>
          </div>
        )}
      </div>
      <div className="button-menu">
        {buttonGroup}
      </div>
    </header>
    {children && (
      <div className={`content ${hideTitle ? 'no-border' : ''}`}>
        {children}
      </div>
    )}
  </div>
);

export default Card;
