/* eslint-disable react/prop-types, react/display-name */
import React from 'react';

export default ({
  type, name, attachToSubstation, inEditMode, openCreateContainerModal, id,
}) => inEditMode && (
<button
  id={id}
  className="feeder-tile new-feeder-button"
  onClick={openCreateContainerModal(type, attachToSubstation)}
  disabled={false}
  title={`New ${type}`}
  type="button"
>
  <div className="feeder-tile-contents">
    <i className="material-icons">add</i>
    <p className="new-feeder">{`New ${name || type}`}</p>
  </div>
</button>
);
