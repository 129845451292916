import React, { PureComponent } from 'react';
import CIMReferenceList from './CIMReferenceList';
import { CimJSONObject, CimObjectMap } from '../cim';
import { CimClassReference, ParsedReference } from '../references';

type CimClassRefenceProps = {
  cimObjectMap: CimObjectMap,
  columnStatus: string;
  followReferenceLink: (id: string) => void;
  instance?: CimJSONObject;
};

const getDisplayName = (obj: CimJSONObject) => obj.attributes['IdentifiedObject.name'] || obj.attributes['IdentifiedObject.mRID'];

const CLASS_TO_EXTRA_DATA_MAP: { [key: string]: (obj: CimJSONObject, lookup: CimObjectMap) => string; } = {
  Terminal: (obj: CimJSONObject, lookup: CimObjectMap) => {
    let extraData = `${obj.class}`;
    const ref = obj.references['Terminal.ConductingEquipment'];

    if (!Array.isArray(ref) && lookup[ref]) {
      extraData = `${obj.class} of ${lookup[ref].class} ${getDisplayName(lookup[ref])}`;
    }

    return extraData;
  },
};

class CIMClassReferences extends PureComponent<CimClassRefenceProps> {
  sortByDisplayName = (referenceList: string[]): string[] => (
    referenceList.sort((a, b) => {
      const aName = a.split('.')[1];
      const bName = b.split('.')[1];
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    })
  );

  /**
   * Determine which references are present and how many instances exist.
   * Create a label with the total in brackets
   * @param  {Object} instance Instance data
   * @return {Array}           List of reference types with their totals
   */
  processReferences = (instance: CimJSONObject|undefined, lookup: CimObjectMap): CimClassReference[] => {
    const referenceList: CimClassReference[] = [];

    if (instance && instance.references) {
      const types = this.sortByDisplayName(Object.keys(instance.references));

      types.forEach((type) => {
        let typeRefs = instance.references[type];
        if (!Array.isArray(typeRefs)) {
          typeRefs = [typeRefs];
        }

        const refsWithInfo = typeRefs.map((id) => {
          const values: ParsedReference = { id };
          const cimObject = lookup[id];

          if (cimObject) {
            values.extraData = cimObject.class;

            if (cimObject.attributes) {
              const keys = Object.keys(cimObject.attributes);
              keys.forEach((key) => {
                if (key.includes('.name')) {
                  values.name = cimObject.attributes[key];
                }
                if (key.includes('.phase')) {
                  values.phase = cimObject.attributes[key];
                }
                if (key.includes('.sequenceNumber')) {
                  values.sequenceNumber = cimObject.attributes[key];
                }
              });
            }

            if (CLASS_TO_EXTRA_DATA_MAP[cimObject.class] && !values.name) {
              // Override the class display for a nice experience
              values.extraData = '';
              values.name = CLASS_TO_EXTRA_DATA_MAP[cimObject.class](cimObject, lookup);
            }
          }
          return values;
        });

        const sortedReferences = refsWithInfo.sort((a, b) => {
          const aName = a.name || a.id;
          const bName = b.name || b.id;
          if (aName < bName) return -1;
          if (aName > bName) return 1;
          return 0;
        });

        referenceList.push({
          type,
          refs: sortedReferences,
        });
      });
    }

    return referenceList;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const {
      instance, columnStatus, cimObjectMap, followReferenceLink,
    } = this.props;
    const references = this.processReferences(instance, cimObjectMap);

    return (
      <>
        <div className={`cim-explorer-column-header cim-explorer-column-header--${columnStatus}`}>
          <h2 className="cim-explorer-column-header__h2">References</h2>
        </div>
        <div className="cim-references-container gray-scrollbars">
          <CIMReferenceList
            references={references}
            followReferenceLink={followReferenceLink}
          />
        </div>
      </>
    );
  }
}

export default CIMClassReferences;
