import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

// Create the icon to be displayed beside the asset name
const AssetPanelIcon = ({ type, icon }) => {
  let iconElem;
  if (!icon) {
    switch (type) {
      case 'ACLineSegment':
        iconElem = (
          <svg height="30" width="30">
            <line x1="5" y1="15" x2="35" y2="15" className="line-icon" />
          </svg>
        );
        break;
      case 'ConnectivityNode':
        iconElem = (
          <svg height="30" width="30">
            <circle cx="15" cy="15" r="10" className="circle-icon" />
          </svg>
        );
        break;
      default:
        iconElem = null;
    }
  } else {
    iconElem = (
      <ReactSVG className="icon-elem" src={`/${icon}.svg`} />
    );
  }
  return (
    <div className="asset-panel-icon">
      {iconElem}
    </div>
  );
};

AssetPanelIcon.defaultProps = {
  icon: '',
};

AssetPanelIcon.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default AssetPanelIcon;
