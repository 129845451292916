import React from 'react';
import CustomCheckbox from 'components/CustomCheckbox';

import { useAttachments } from 'contexts/AttachmentsContext';

export default function AttachmentsLayerToggle() {
  const { attachmentsLayerEnabled, toggleAttachmentsLayer } = useAttachments();
  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '5px 10px 10px 10px' }}>
      <CustomCheckbox
        id="attachment-layer"
        checked={attachmentsLayerEnabled}
        onClick={() => toggleAttachmentsLayer(!attachmentsLayerEnabled)}
      />
      <p className="caption-text">Show notes and files</p>
    </div>
  );
}
