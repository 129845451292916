import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';

function HelpTooltip({ message }) {
  return (
    <Tooltip placement="right" content={message}>
      <i className="material-icons help-icon">help_outline</i>
    </Tooltip>
  );
}

HelpTooltip.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default HelpTooltip;
