import React from 'react';
import PropTypes from 'prop-types';
import './EquipmentBadge.scss';

const symbols = {
  MULTICONDUCTOR: <strong>∴</strong>,
  SINGLECONDUCTOR: '•',
  OVERHEAD: '⍡',
  UNDERGROUND: (<i className="material-icons equipment-badge-icon">texture</i>),
  CABLE_CROSS_SECTION: (<i className="material-icons equipment-badge-icon">radio_button_checked</i>),
  SECONDARY: '2ⁿᵈ',
};

const wireGeometryBadges = {
  overheadTriplexSecondary: [symbols.SECONDARY, symbols.MULTICONDUCTOR, symbols.OVERHEAD],
  overheadMultiConductor: [symbols.MULTICONDUCTOR, symbols.OVERHEAD],
  undergroundTriplexSecondary: [symbols.SECONDARY, symbols.MULTICONDUCTOR, symbols.UNDERGROUND],
  undergroundMultiConductor: [symbols.MULTICONDUCTOR, symbols.UNDERGROUND],
  overhead4WireDistribution: [symbols.SINGLECONDUCTOR, symbols.OVERHEAD],
  undergroundCable: [symbols.CABLE_CROSS_SECTION, symbols.UNDERGROUND],
};

const badgeText = {
  OverheadWireInfo: () => (['ohw']),
  ConcentricNeutralCableInfo: () => (['cc']),
  TapeShieldCableInfo: () => (['ts']),
  WireInfo: () => (['multi']),
  CableInfo: () => (['cable']),
  WireSpacingInfo: (info) => (wireGeometryBadges[info.configurationType] || []),
};

const badgeHelp = (badge) => {
  switch (badge) {
    case 'ohw': return 'overhead wire phase conductor';
    case 'cc': return 'concentric neutral cable';
    case 'ts': return 'tape-shielded cable';
    case 'multi': return 'conductor in a multi-conductor package (duplex, triplex, quadruplex)';
    case 'cable': return 'armoured / sheathed cable or multi-conductor';
    case symbols.OVERHEAD: return 'above-ground';
    case symbols.UNDERGROUND: return 'under-ground';
    case symbols.SINGLECONDUCTOR: return 'individual conductors';
    case symbols.MULTICONDUCTOR: return 'multi-conductor (e.g. triplex, quadruplex)';
    case symbols.SECONDARY: return 'secondary distribution (+/-120V)';
    case symbols.CABLE_CROSS_SECTION: return 'cable';
    default: return '';
  }
};

const EquipmentBadge = ({ equipmentInfoItem }) => {
  const equipmentInfoBadges = equipmentInfoItem.badges || [];
  if (equipmentInfoBadges.length === 0) {
    if (!badgeText[equipmentInfoItem?.class]) return '';
    equipmentInfoBadges.push(...badgeText[equipmentInfoItem.class](equipmentInfoItem) || []);
  }
  if (equipmentInfoBadges.length === 0) return '';

  return (
    <div className="equipment-multibadge-container">
      {equipmentInfoBadges.map((badgeValue) => (
        <p
          className="equipment-badge"
          title={badgeHelp(badgeValue)}
          key={`badge-${badgeHelp(badgeValue).replace(/[^a-zA-Z0-9]/g, '-')}-${equipmentInfoItem.id || 'no_id'}`}
        >
          {badgeValue}
        </p>
      ))}
    </div>
  );
};

EquipmentBadge.propTypes = {
  equipmentInfoItem: PropTypes.shape({
    badges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
    configurationType: PropTypes.string,
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
};

export {
  EquipmentBadge as default,
  symbols,
};
