import ConductingEquipment from './ConductingEquipment';
import ConnectivityNode from './ConnectivityNode';
import { getUniqueCoordinates } from './extractorHelpers';

class ACLineSegment extends ConductingEquipment {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'ACLineSegment';
  }

  get displayName() {
    return this.isCable ? 'Cable' : 'Line';
  }

  get visibilityClass() {
    return this.isCable ? 'Cable' : 'Line';
  }

  get isCable() {
    try {
      // Check if it is a cable or by phase overhead wire
      const assetDataSheet = this.references['PowerSystemResource.AssetDatasheet'];
      if (assetDataSheet) {
        const attrs = this.cimDict[assetDataSheet].attributes;
        return attrs['WireSpacingInfo.isCable'];
      }

      // Check if it is an unbalanced overhead wire or cable
      const perLengthImpedance = this.references['ACLineSegment.PerLengthImpedance'];
      if (perLengthImpedance) {
        const wireSpacingInfo = this.cimDict[perLengthImpedance].references['PerLengthLineParameter.WireSpacingInfo'];
        if (wireSpacingInfo) {
          return this.cimDict[wireSpacingInfo].attributes['WireSpacingInfo.isCable'];
        }
      }

      // If none of the above it is a balanced overhead wire
      return false;
    } catch (err) {
      return false;
    }
  }

  get phase() {
    if (this._phaseReferences) {
      return Object.keys(this._phaseReferences).sort().join('');
    }
    return super.phase;
  }

  // Extract the coordinates of each terminal and merge in internal coordinates
  // if available.
  get coordinates() {
    const positionPoints = this._positionPoints.map(point => this.cimDict[point].attributes);
    const lineLocation = this.references['PowerSystemResource.Location'];
    if (lineLocation && this.cimDict[lineLocation]) {
      // Insert the internal coordinates of the multi-part line segment
      const positionList = this.cimDict[lineLocation].references['Location.PositionPoints'] || [];
      const positionInstances = positionList.map(pos => this.cimDict[pos].attributes);
      const sortedPoints = this.sortPositionPoints(positionInstances);
      return getUniqueCoordinates([positionPoints[0], ...sortedPoints, positionPoints[1]]);
    }
    return positionPoints;
  }

  get ratedCurrent() {
    try {
      const ratedCurrents = {};

      const phaseObjects = this.references['ACLineSegment.ACLineSegmentPhases'].map(id => this.cimDict[id]);
      const dataSheetByPhase = this.extractReferenceByPhase('ACLineSegmentPhase', phaseObjects, 'PowerSystemResource.AssetDatasheet');
      Object.entries(dataSheetByPhase).forEach((val) => {
        const [phase, dataSheet] = val;
        ratedCurrents[phase] = dataSheet ? dataSheet.attributes['WireInfo.ratedCurrent'] : null;
      });
      return ratedCurrents;
    } catch (err) {
      return {};
    }
  }

  // Get base voltage from ConnectivityNode
  get nodeBaseVoltage() {
    try {
      const node = new ConnectivityNode(this.nodes[0], this.cimDict);
      if (node._baseVoltage) {
        return node._baseVoltage.attributes['BaseVoltage.nominalVoltage'];
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  // Create dictionary of ACLineSegmentPhase referenced keyed on the phase
  get _phaseReferences() {
    const phaseRefs = this.references['ACLineSegment.ACLineSegmentPhases'];
    if (phaseRefs && phaseRefs.length > 0) {
      return phaseRefs.reduce((lu, id) => {
        try {
          const phase = this.cimDict[id].attributes['ACLineSegmentPhase.phase'];
          lu[phase] = id;
          return lu;
        } catch (err) {
          return lu;
        }
      }, {});
    }
    return null;
  }

  get _topologicalNode() {
    const term = this.cimDict[this.terminals[0]];
    return term.references['Terminal.TopologicalNode'];
  }
}

export default ACLineSegment;
