import { gramPerkWh, scaleFactorForUnit } from 'helpers/units';

export const defaultAssetModel = Object.freeze({
  estimatedUnitCost: 0,
  estimatedUnitDisposalCost: 0,
  estimatedUnitInstallationCost: 0,
  estimatedUnitRemovalCost: 0,
  estimatedUnitUpgradeCost: 0,
  estimatedUnitInstallationDays: '',
  communityGenerationCredit: 0,
  fixedMaintenanceCost: 0,
  perUnitMaintenanceCost: 0,
  EmissionRate: { emissionRate: '' },
  AssetFailureInfo: {
    mTTR: null,
    probabilityOfFailureEquation: null,
  },
});

/**
 * Gets the CostEditor properties from the JSCIM asset model
 * @param {*} assetModel the JSCIM asset model to read from
 */
export const getAssetModelProperties = assetModel => ({
  estimatedUnitCost: assetModel?.estimatedUnitCost ?? 0,
  estimatedUnitDisposalCost: assetModel?.estimatedUnitDisposalCost ?? 0,
  estimatedUnitInstallationCost: assetModel?.estimatedUnitInstallationCost ?? 0,
  estimatedUnitRemovalCost: assetModel?.estimatedUnitRemovalCost ?? 0,
  estimatedUnitUpgradeCost: assetModel?.estimatedUnitUpgradeCost ?? 0,
  estimatedUnitInstallationDays: assetModel?.estimatedUnitInstallationDays ?? '',
  communityGenerationCredit: assetModel?.communityGenerationCredit ?? 0,
  fixedMaintenanceCost: assetModel?.fixedMaintenanceCost ?? 0,
  perUnitMaintenanceCost: assetModel?.perUnitMaintenanceCost ?? 0,
  EmissionRate: {
    emissionRate: assetModel?.EmissionRate?.emissionRate
      ? assetModel?.EmissionRate?.emissionRate / scaleFactorForUnit(gramPerkWh) : '',
  },
  AssetFailureInfo: {
    mTTR: assetModel?.AssetFailureInfo?.mTTR ?? null,
    probabilityOfFailureEquation: assetModel?.AssetFailureInfo?.probabilityOfFailureEquation ?? null,
  },
});

export const areAssetModelPropertiesChanged = (assetModel1, assetModel2) => {
  let costsEdited = false;
  const editedPropsDict = {};
  Object.keys(assetModel1)
    .filter(key => key !== 'estimatedUnitInstallationDays'
      && key !== 'EmissionRate'
      && key !== 'AssetFailureInfo')
    .forEach(prop => {
      // cim stringifies money values but the input fields are numbers so convert to numbers
      editedPropsDict[prop] = !(Number(assetModel1?.[prop] ?? 0) === Number(assetModel2?.[prop] ?? 0));
      costsEdited = costsEdited || editedPropsDict[prop];
    });
  editedPropsDict.estimatedUnitInstallationDays = (assetModel1?.estimatedUnitInstallationDays ?? '')
    !== (assetModel2?.estimatedUnitInstallationDays ?? '');
  editedPropsDict.EmissionRate = {
    emission: (assetModel1?.EmissionRate?.emissionRate ?? '')
      !== (assetModel2?.EmissionRate?.emissionRate ?? ''),
  };
  editedPropsDict.AssetFailureInfo = {
    mTTR: (assetModel1?.AssetFailureInfo?.mTTR ?? null)
      !== (assetModel2?.AssetFailureInfo?.mTTR ?? null),
    probabilityOfFailureEquation: (assetModel1?.AssetFailureInfo?.probabilityOfFailureEquation ?? null)
      !== (assetModel2?.AssetFailureInfo?.probabilityOfFailureEquation ?? null),
  };
  const anyPropsChanged = costsEdited || editedPropsDict.estimatedUnitInstallationDays
    || editedPropsDict.EmissionRate.emission || editedPropsDict.AssetFailureInfo.mTTR
    || editedPropsDict.AssetFailureInfo.probabilityOfFailureEquation;
  return [anyPropsChanged, editedPropsDict];
};

export const areAssetModelsEqual = (assetModel1, assetModel2) => {
  const [areEqual] = areAssetModelPropertiesChanged(assetModel1, assetModel2);
  return !areEqual;
};

export const isAssetModelValid = assetModel => {
  const costsValid = Object.keys(assetModel)
    .filter(key => key !== 'estimatedUnitInstallationDays')
    .filter(key => key !== 'EmissionRate')
    .filter(key => key !== 'AssetFailureInfo')
    .every(prop => !Number.isNaN(parseFloat(assetModel[prop])));
  const daysValid = !Number.isNaN(parseInt(assetModel.estimatedUnitInstallationDays, 10))
    || assetModel.estimatedUnitInstallationDays?.length === 0;
  let restorationTimeValid = true;
  if (assetModel.AssetFailureInfo) {
    restorationTimeValid = !Number.isNaN(parseFloat(assetModel.AssetFailureInfo.mTTR))
      || assetModel.AssetFailureInfo.mTTR === null;
  }
  return costsValid && daysValid && restorationTimeValid;
};
