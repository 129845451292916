/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import IconButton from 'components/IconButton';
import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import CustomCheckbox from 'components/CustomCheckbox';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';
import PropTypes from 'prop-types';
import Request from 'helpers/Request';
import Analytics from 'helpers/Analytics';
import UserList from 'components/UserList';
import TextInput from 'components/TextInput';
import './ShareModal.scss';

const shareWorkspace = async (workspace, values, setLoadingState, setErrorMessage, emptyState) => {
  async function shareWS() {
    setLoadingState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace${workspace}/share`);
    Analytics.logEvent('User Shared Worksapce', 'Sharing');
    try {
      await request.post(values);
      setLoadingState(asyncActionStates.SUCCESS);
      emptyState();
    } catch (error) {
      setLoadingState(asyncActionStates.ERROR);
      setErrorMessage('Something went wrong. Please try again later.');
    }
  }
  shareWS();
};

const ShareModal = (props) => {
  const [modalActive, toggleModal] = useState(false);
  const [loadingState, setLoadingState] = useState(asyncActionStates.INITIAL);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState('');
  const [message, setMessage] = useState('');
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const canShare = props.permissions && props.permissions.has('share_workspace') && props.workspace.length > 1;

  // const canShare = true;
  const permissionsOptions = [
    {
      label: 'Viewer',
      value: 'view',
    }, {
      label: 'Editor',
      value: 'edit',
    }, {
      label: 'Editor + Sharing',
      value: 'edit and share',
    },
  ];

  const tooltipContents = (
    <div>
      <p>
        <b>Viewer</b>
        {' '}
        - can view analysis results and model data. Cannot share.
      </p>
      <p>
        <b>Editor</b>
        {' '}
        - can view and edit, including running analyses and
        editing model data. Cannot share.
      </p>
      <p>
        <b>Editor + Sharing</b>
        {' '}
        - can view and edit, including running analyses and
        editing model data. Can share.
      </p>
    </div>
  );

  const saveDisabled = (
    !agreePrivacy
    || errorMessage.length > 0
    || name.length === 0
    || email.length === 0
    || permission.length === 0
  );

  const emptyState = () => {
    setName('');
    setEmail('');
    setPermission('');
    setMessage('');
    setAgreePrivacy(false);
    setErrorMessage('');
  };

  const cancel = () => {
    emptyState();
    setLoadingState(asyncActionStates.INITIAL);
    toggleModal(false);
  };

  return (
    <div className="sharing-modal">
      <div className="share-button">
        <PermissionDisabledTip
          title="Share"
          hide={canShare}
          placement="top"
          theme={props.theme}
          message="You do not have permission to share this workspace."
        >
          <Button
            type="primary"
            onClick={() => toggleModal(true)}
            theme={props.theme}
            disabled={!canShare}
            id="share-button"
          >
            Share
          </Button>
        </PermissionDisabledTip>
      </div>
      {modalActive && (
        <Modal
          showFooter={false}
          onCancel={() => { }}
          showHeader={false}
          active={modalActive}
          theme={props.theme}
        >
          <div className="header">
            <h2>Share My Workspace</h2>
            <IconButton
              icon="close"
              onClick={cancel}
              tooltip="Close"
              theme={props.theme}
              id="close-modal-btn"
            />
          </div>
          {loadingState !== asyncActionStates.SUCCESS && (
            <>
              <div className="body">
                <div className="input-row">
                  <div className="input">
                    <label htmlFor="name">Name *</label>
                    <TextInput
                      id="name"
                      value={name}
                      onChange={(e) => { setErrorMessage(''); setName(e); }}
                      theme={props.theme}
                      required
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="input">
                    <label htmlFor="email">Email *</label>
                    <TextInput
                      id="email"
                      value={email}
                      onChange={(e) => { setErrorMessage(''); setEmail(e); }}
                      theme={props.theme}
                      required
                    />
                  </div>
                  <div className="input input--permissions">
                    <div className="permissions">
                      <label htmlFor="permissions">Permissions *</label>
                      <Tooltip
                        placement="right"
                        content={tooltipContents}
                      >
                        <i className="material-icons help-icon">help_outline</i>
                      </Tooltip>
                    </div>
                    <Select
                      value={permission}
                      options={permissionsOptions}
                      theme={props.theme}
                      onChange={e => setPermission(e.value)}
                      clearable={false}
                      id="permission"
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="message-area">
                    <label htmlFor="message" style={{ padding: '10px 0' }}>Message (optional)</label>
                    <textarea
                      autoComplete="off"
                      className={`${props.theme} message`}
                      spellCheck
                      value={message}
                      wrap="soft"
                      rows="5"
                      onChange={e => setMessage(e.target.value)}
                      theme={props.theme}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="users-area">
                    <label htmlFor="users" style={{ padding: '10px 0' }}>Team members</label>
                    <div className={`${props.theme} users-section`}>
                      <UserList
                        workspace={props.workspace}
                        theme={props.theme}
                        setErrorMessage={setErrorMessage}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-row">
                  <CustomCheckbox
                    checked={agreePrivacy}
                    onClick={() => setAgreePrivacy(!agreePrivacy)}
                    theme={props.theme}
                    borderColor="#949899"
                    id="agreePrivacy"
                  />
                  <span className="privacy-policy">
                    By sharing my workspace, I agree to the Opus One Solutions&nbsp;
                    <a
                      className="privacy-link"
                      href="https://www.opusonesolutions.com/eula/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </div>
              </div>
              <div className="footer">
                {errorMessage.length > 0 && (
                  <span className="error-message">{errorMessage}</span>
                )}
                <div style={{ display: 'flex' }}>
                  <Button
                    type="secondary"
                    className="footer-button"
                    theme={props.theme}
                    onClick={cancel}
                    id="cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="footer-button"
                    theme={props.theme}
                    onClick={() => shareWorkspace(
                      props.workspace,
                      {
                        name, email, permission, message,
                      },
                      setLoadingState,
                      setErrorMessage,
                      emptyState,
                    )}
                    disabled={saveDisabled}
                    loading={loadingState === asyncActionStates.LOADING}
                    id="save"
                  >
                    Share
                  </Button>
                </div>
              </div>
            </>
          )}
          {loadingState === asyncActionStates.SUCCESS && (
            <>
              <div id="thank-you" className="body">{`Thank you.  Workspace ${props.workspace.replace('/', '')} has been shared.`}</div>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

ShareModal.defaultProps = {
  theme: 'dark',
};

ShareModal.propTypes = {
  theme: PropTypes.string,
  permissions: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  workspace: PropTypes.string.isRequired,
};

export default ShareModal;
