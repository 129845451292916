import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import { isDefined } from 'helpers/utils';
import { gramPerkWh, scaleFactorForUnit } from 'helpers/units';

import Helpers from '../../helpers/EquipmentLibraryHelpers';
import {
  areAssetModelPropertiesChanged,
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
} from '../../helpers/assetModelHelpers';
import DescriptionEditor from './DescriptionEditor';
import CostEditor from './CostEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

const getNewCHPs = () => ({
  name: '',
  description: '',
  assetModel: { ...defaultAssetModel },
  id: 'add',
});

class CHPPanel extends Component {
  state = { ...(getNewCHPs()) }

  componentDidMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({ ...this.getInputValues(this.props.selected) });
    }
  }

  getInputValues = (selected) => {
    let asset = { ...(getNewCHPs()) };
    if (selected) {
      const {
        name, description, id, AssetModel,
      } = selected;
      asset = {
        name: name || asset.name,
        description: description || asset.description,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        id,
      };
    }
    return asset;
  }

  handleSave = () => {
    const diffModel = {
      name: this.state.name,
      description: this.state.description,
      AssetModel: {
        ...this.state.assetModel,
        EmissionRate: {
          emissionRate:
          parseFloat(this.state.assetModel.EmissionRate.emissionRate * scaleFactorForUnit(gramPerkWh)),
        },
      },
    };
    if (this.state.id === 'add') {
      this.props.handleCreate('cogeneration_generating_unit_info', diffModel, 'CogenerationGeneratingUnitInfo');
    } else {
      // Only submit the values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        switch (key) {
          case 'name':
          case 'description':
            if (diffModel[key] !== selected[key]) {
              diff[key] = diffModel[key];
            }
            return diff;
          case 'AssetModel':
            if (!areAssetModelsEqual(diffModel[key], selected[key])) {
              diff[key] = diffModel[key];
            }
            return diff;
          default:
            return diff;
        }
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  }

  handleInputChange = ({ target }) => this.setState({ [target.id]: target.value });

  handleOnChange = prop => this.setState(prevState => ({
    assetModel: {
      ...prevState.assetModel,
      EmissionRate: {
        ...prevState.assetModel.EmissionRate,
        ...prop,
      },
    },
  }))

  valuesUpdated = (selected) => {
    if (!selected) return true;
    const stringUpdated = ['name', 'description'].some(val => selected[val] !== this.state[val]);
    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selected.AssetModel),
      this.state.assetModel,
    );
    return stringUpdated || assetModelUpdated;
  }

  formValid = () => (
    this.state.name.trim().length > 0
    && isAssetModelValid(this.state.assetModel)
    && this.valuesUpdated(this.props.selected)
  );

  render() {
    const emissionRate = Helpers.createEmissionRate(this.state.assetModel.EmissionRate.emissionRate);

    const isDisabled = (
      this.props.authEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
      || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
      || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );

    const defaultProps = {
      disabled: isDisabled,
      theme: this.props.theme,
      inputWidth: '225px',
    };

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              <TextInput
                {...defaultProps}
                id="name"
                required
                label="Name"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
              <NumberInput
                onChange={({ id, value }) => this.handleOnChange({ [id]: value })}
                ge={0}
                step="0.001"
                {...defaultProps}
                {...emissionRate}
                inputStyle="eq-lib"
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={areAssetModelPropertiesChanged(
              this.state.assetModel,
              getAssetModelProperties(this.props.selected?.AssetModel),
            )[1]}
            includeCommunityCredit
            key={`${this.state.id}-costs`}
            onChange={prop => this.setState(prevState => ({
              assetModel: { ...prevState.assetModel, ...prop },
            }))}
            isDisabled={isDisabled}
            theme={this.props.theme}
          />,
        ]}
      </PanelTabs>
    );
  }
}

CHPPanel.defaultProps = {
  selected: null,
};

CHPPanel.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  authEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default CHPPanel;
