import React, {
  FunctionComponent, useContext, useState,
} from 'react';
import { useParams } from 'react-router';
import GridLayout from 'layouts/GridLayout';
import ResultsCard from 'components/ResultsCard';
import TitleBar from 'components/TitleBar';
import ThemeContext from 'helpers/ThemeContext';
import Icons from 'components/Icons';
import asyncActionStates from 'helpers/asyncActionStates';
import ResultsComparisonCard from './ResultsComparisonCard';
import ResultsComparisonContextProvider from '../context/ResultsComparisonContext';
import NetworkTopNav from '../../../containers/NetworkTopNavContainer';

const ResultsComparison: FunctionComponent = (props) => {
  const theme = useContext(ThemeContext);
  const resultsCards = [
    { id: 0, isBaseline: true },
    { id: 1, isBaseline: false },
    { id: 2, isBaseline: false },
  ];
  const [nextId, setNextId] = useState(resultsCards.length);
  const [resultsComparisonCards, setResultsComparisonCards] = useState(resultsCards as any);
  const resultsCardsLength = resultsComparisonCards.length;
  const { CirclePlusIcon } = Icons;
  let gridClassName = 'three-three-one';
  const maxResultsCards = 4;
  if (resultsCardsLength === 3) gridClassName = 'two-two-two-one';
  if (resultsCardsLength === maxResultsCards) gridClassName = 'two-two-two-two';
  if (resultsCardsLength === 1) gridClassName = 'three-one-three';
  return (
    <>
      <NetworkTopNav params={useParams()} />
      <ResultsComparisonContextProvider>
        <div className={`results-comparison ${theme}`} data-test="results-comparison">
          <GridLayout
            className="results-view"
            showHeader
            renderHeaderContent={() => (
              <div className="grid-columns">
                <div className="single-column">
                  <TitleBar
                    theme={theme}
                    title="Results dashboard"
                    caption="Add and rearrange scenarios to compare with the primary scenario"
                  />
                </div>
              </div>
            )}
          >
            {/* add "one-seven" className here to restore left most column */}
            <div className="grid-columns">
              <div className="single-column">
                <div className={`${gridClassName} grid-columns results-cards`}>
                  { resultsComparisonCards.map((card: any, index: any) => (
                    <ResultsComparisonCard
                      theme={theme}
                      {...props}
                      key={card.id}
                      id={index}
                      isBaseline={card.isBaseline}
                      removeResultsCard={(id: number) => {
                        let status = asyncActionStates.LOADING;
                        if (!card.isBaseline) {
                          const newCardValues = [...resultsComparisonCards];
                          newCardValues.splice(id, 1);
                          setResultsComparisonCards(newCardValues);
                          status = asyncActionStates.SUCCESS;
                        } else {
                          status = asyncActionStates.ERROR;
                        }
                        return status;
                      }}
                      title={`Result #${index + 1}`}
                    />
                  )) }
                  { resultsComparisonCards && Object.values(resultsComparisonCards).length < maxResultsCards && (
                    <ResultsCard theme={theme}>
                      <button
                        onClick={() => {
                          const newCardValues = [...resultsComparisonCards];
                          newCardValues.push({
                            id: nextId,
                            isBaseline: false,
                          });
                          setResultsComparisonCards(newCardValues);
                          setNextId(nextId + 1);
                        }}
                        type="button"
                        className="add-new-btn"
                        key="add-new-btnhgf"
                      >
                        <div className="add-new-result">
                          <CirclePlusIcon />
                        </div>
                        <div>
                          <h3 className="text-bold">Add new result</h3>
                        </div>
                      </button>
                    </ResultsCard>
                  )}
                </div>
              </div>
            </div>
          </GridLayout>
        </div>
      </ResultsComparisonContextProvider>
    </>
  );
};
export default ResultsComparison;
