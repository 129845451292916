import React from 'react';
import Switch from './Switch';

// Create a value template for a Breaker.
// As there are no value difference between Breaker and Switch, we use the Swtich template
const Breaker = props => (
  <Switch {...props} />
);

export default Breaker;
