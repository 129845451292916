import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const WarningIcon: FunctionComponent<IconPropsSchema> = ({
  color = 'red',
  width = '25px',
  height = '25px',
}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" height={height} width={width} viewBox="0 0 24 24">
    <path fill="white" d="M3.6,21h16.8c1.2,0,1.9-1.2,1.3-2.2L13.3,4.2c-0.6-1-2-1-2.6,0L2.3,18.7C1.7,19.7,2.4,21,3.6,21z" />
    <path fill={color} d="M3.6,21h16.8c1.2,0,1.9-1.2,1.3-2.2L13.3,4.2c-0.6-1-2-1-2.6,0L2.3,18.7C1.7,19.7,2.4,21,3.6,21z M13,18.2h-2v-2h2V18.2z M13,14.5h-2V8.8h2V14.5z" />
  </svg>
);

export default WarningIcon;
