import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from 'components/ExpandableSection';
import RadioButtonSelectGroup from 'components/RadioButtonSelectGroup';
import RadioButtonGroup from 'components/RadioButtonGroup';
import { getMultiplier } from 'helpers/utils';
import {
  createRangeBuckets, createLabels, createHeadroomLabels, getPhaseSelectOptions,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import RangeSelector from './RangeSelector';
import RangeSlider from './RangeSlider';
import LayerSelectionButton from './LayerSelectionButton';

class ApparentPowerSection extends Component {
  UNSAFE_componentWillMount() {
    this.setState({ powerGradient: this.getRangeBreaks(this.props.layerOptions) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldOptions = this.props.layerOptions;
    const newOptions = nextProps.layerOptions;
    if (
      oldOptions.colors !== newOptions.colors
      || oldOptions.rangeBreaks !== newOptions.rangeBreaks
      || oldOptions.percentBreaks !== newOptions.percentBreaks
      || oldOptions.layerType !== newOptions.layerType
    ) {
      this.setState({ powerGradient: this.getRangeBreaks(newOptions), editId: '' });
    }
  }

  /**
   * Create the correct range break labels based on layer type
   * @param  {Object} options Layer options
   * @return {Array}          List of layer labels
   */
  getRangeBreaks = (options) => {
    const {
      rangeBreaks, percentBreaks, layerType, colors,
    } = options;
    const breaks = layerType === 'loading' ? percentBreaks : rangeBreaks;
    const unit = layerType === 'loading' ? '%' : 'VA';
    const formattedBreaks = breaks.map((val) => {
      const { letter, value } = getMultiplier(val);
      return `${(val / value).toFixed(2)} ${letter}`;
    });
    const labelFormatter = layerType === 'headroom' ? createHeadroomLabels : createLabels;
    return createRangeBuckets(colors, labelFormatter(formattedBreaks, unit));
  }

  unCheckRange = (e) => {
    const index = parseInt(e.target.value, 10);
    const newSelection = [...this.props.layerOptions.selected];
    newSelection[index] = !newSelection[index];
    this.updateLayer({ selected: newSelection });
  }

  toggleEditPanel = id => this.setState({ editId: id });

  handleLayerUpdate = (updates) => {
    const { layerType } = this.props.layerOptions;

    if (updates.rangeBreaks && layerType === 'loading') {
      updates.percentBreaks = updates.rangeBreaks;
      delete updates.rangeBreaks;
      delete updates.range;
    } else if (updates.rangeBreaks && layerType === 'headroom') {
      updates.rangeBreaks = [...updates.rangeBreaks].reverse();
    }
    this.updateLayer(updates);
  }

  handleLayerOptionSelection = (phaseType, option) => {
    const updateType = phaseType === 'single' ? 'phase' : 'aggType';
    this.updateLayer({ [updateType]: option });
  }

  updateLayer = update => this.props.updateLayerOptions('apparent_power', update);

  getLayerRanges = (layerType, rangeBreaks, percentBreaks) => {
    switch (layerType) {
      case 'headroom':
        return [...rangeBreaks].reverse();
      case 'loading':
        return percentBreaks;
      default:
        return rangeBreaks;
    }
  }

  getSliderDomain = (layerType, layerRangeBreaks) => {
    let sliderDomain;

    if (layerType === 'loading') {
      sliderDomain = [0, layerRangeBreaks[0] * 1.1];
    } else if (layerType === 'actual') {
      sliderDomain = [0, layerRangeBreaks[0] * 1.1];
    } else {
      sliderDomain = [0, layerRangeBreaks[3] * 1.1];
    }

    return sliderDomain;
  }

  render() {
    const { layerOptions, showThreePhase } = this.props;
    const {
      layerType, phaseType, aggType, phase, selected, rangeBreaks, percentBreaks,
    } = layerOptions;

    const layerRangeBreaks = this.getLayerRanges(layerType, rangeBreaks, percentBreaks);
    const apparentPowerLayerOptions = {
      ...layerOptions,
      rangeBreaks: layerRangeBreaks,
    };

    const button = () => (
      <LayerSelectionButton
        selected={this.props.selected}
        option={{ label: 'Apparent Power', value: 'apparent_power' }}
        group="results"
        setSelectedLayer={this.props.setSelectedLayer}
      />
    );

    return (
      <ExpandableSection
        className={classNames({
          'layer-expandable-section': true,
          'selected-layer': this.props.selected,
          'layer-expandable-section--expanded': this.props.expanded,
        })}
        renderHeaderContent={button}
        showToggle={false}
        open={this.props.selected}
      >
        <div className="layer-section apparent-power-section">
          <div className="selection-section layer-phase-selector">
            <RadioButtonSelectGroup
              id="ratedS-phase-selector"
              value={phaseType}
              listType="column"
              theme={this.props.theme}
              onSelectChange={this.handleLayerOptionSelection}
              onChange={type => this.updateLayer({ phaseType: type })}
              options={getPhaseSelectOptions(aggType, phase, showThreePhase)}
            />
          </div>
          <div className="selection-section layer-selection">
            <RadioButtonGroup
              id="ratedS-layer-type"
              options={[
                { id: 'loading', label: 'Loading' },
                { id: 'actual', label: 'Actual' },
                { id: 'headroom', label: 'Headroom' },
              ]}
              value={layerType}
              onChange={({ target }) => this.updateLayer({ layerType: target.value })}
            />
          </div>
          <RangeSlider
            domain={this.getSliderDomain(layerType, layerRangeBreaks)}
            values={[...layerRangeBreaks].reverse()}
            step={layerType === 'loading' ? 0.005 : 1}
            colors={
              layerType === 'headroom'
                ? this.props.layerOptions.colors
                : this.props.layerOptions.colors.slice().reverse()
}
            format={d => (layerType === 'loading' ? `${d} %` : `${d / 1000} kVA`)}
            onUpdate={e => this.handleLayerUpdate({ rangeBreaks: layerType === 'headroom' ? e : e.reverse() })}
          />
          <div className="layer-checkbox-group">
            {this.state.powerGradient.length
              && this.state.powerGradient.map(checkbox => (
                <RangeSelector
                  key={`apparent-power-range-${checkbox.id}`}
                  settings={checkbox}
                  selected={selected[parseInt(checkbox.id, 10)]}
                  onClick={this.unCheckRange}
                  id={parseInt(checkbox.id, 10)}
                  withEdit
                  toggleEdit={this.toggleEditPanel}
                  showEditPanel={checkbox.id === this.state.editId}
                  layerOptions={apparentPowerLayerOptions}
                  handleUpdate={this.handleLayerUpdate}
                  editDisabled={!!this.state.editId}
                  editColor
                  editRange
                  unit={layerType === 'loading' ? '%' : 'VA'}
                  useMultiplier={layerType !== 'loading'}
                  ascending={layerType !== 'headroom'}
                  descending={layerType === 'headroom'}
                />
              ))}
          </div>
        </div>
      </ExpandableSection>
    );
  }
}

ApparentPowerSection.propTypes = {
  layerOptions: PropTypes.shape({
    selected: PropTypes.array,
    mode: PropTypes.string,
    phase: PropTypes.string,
    colors: PropTypes.array,
    rangeBreaks: PropTypes.array,
    percentBreaks: PropTypes.array,
    layerType: PropTypes.string,
    phaseType: PropTypes.string,
    aggType: PropTypes.string,
  }).isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  showThreePhase: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ApparentPowerSection;
