import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import RadioButtonGroup from 'components/RadioButtonGroup';
import { alphabetizeByKey } from 'helpers/utils';
import SwitchOptions from './SwitchOptions.js';

import './CreateDevicePanel.scss';

const templates = {
  switch: SwitchOptions,
};

const createOptions = nodes => nodes.filter(node => node.class === 'ConnectivityNode').map(node => ({
  id: node.id,
  label: node.name,
}));

const CreateDevicePanel = ({
  newAsset,
  lookup,
  selectedNode: line,
  selectedFeeders,
  newAssetFeeder,
  theme,
  workspace,
  branch,
  createNewLineAsset,
  setActivePanel,
  setHoveredObjectID,
  setNewAssetFeeder,
  toggleAddNodeMode,
}) => {
  const [selectedNode, selectNode] = useState('');
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const saveAsset = async () => {
    setLoading(true);
    const body = { ...data, line: line.id, at_node: selectedNode };
    await createNewLineAsset(workspace, branch, newAsset.id, body);
    setLoading(false);
  };

  // Clear the selected node when the panel type changes
  useEffect(() => selectNode(''), [newAsset]);

  const closeAction = newAsset && newAsset.id === 'node'
    ? toggleAddNodeMode
    : () => {
      setActivePanel('asset');
      setHoveredObjectID(null);
    };

  // Panel for link devices
  return (
    <div className={`create-device-panel ${theme}`}>
      {/* newAsset can be null during transition out of edit mode */}
      {newAsset && (
        <>
          <IconButton
            className="close"
            icon="close"
            onClick={closeAction}
            theme={theme}
          />

          <div className="heading">
            <h2>
              New
              {newAsset.name}
            </h2>
          </div>

          {templates[newAsset.id] && React.createElement(templates[newAsset.id], {
            values: data,
            updateValues: setData,
          })}

          {/* Template for adding Nodes */}
          {newAsset.id === 'node' && (
            <>
              <div className="subheading">
                <h4>Select which feeder this node should be added to :</h4>
              </div>

              <RadioButtonGroup
                options={alphabetizeByKey(selectedFeeders, 'name').map(({ id, name }) => ({ id, label: name || id }))}
                listType="column"
                value={newAssetFeeder}
                onChange={({ target }) => setNewAssetFeeder(target.value)}
              />
            </>
          )}

          {/* Template for adding link devices */}
          {newAsset.id !== 'node' && (
            <>
              <div className="subheading">
                <h4>Select which node this asset should be added to</h4>
              </div>

              <RadioButtonGroup
                className="options"
                options={createOptions(line.nodes.map(node => lookup[node]))}
                onChange={(e) => {
                  selectNode(e.target.value);
                  setHoveredObjectID(e.target.value);
                }}
                value={selectedNode}
              />

              <div className="submit">
                <Button
                  label="Add Asset"
                  loading={loading}
                  onClick={saveAsset}
                  theme={theme}
                  disabled={!selectedNode || loading}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

CreateDevicePanel.defaultProps = {
  selectedFeeders: [],
  newAssetFeeder: '',
  newAsset: {},
};

CreateDevicePanel.propTypes = {
  selectedNode: PropTypes.object.isRequired,
  lookup: PropTypes.object.isRequired,
  newAsset: PropTypes.object,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  createNewLineAsset: PropTypes.func.isRequired,
  setActivePanel: PropTypes.func.isRequired,
  setHoveredObjectID: PropTypes.func.isRequired,
  setNewAssetFeeder: PropTypes.func.isRequired,
  toggleAddNodeMode: PropTypes.func.isRequired,
  selectedFeeders: PropTypes.array,
  newAssetFeeder: PropTypes.string,
};

export default CreateDevicePanel;
