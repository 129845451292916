import React, { FunctionComponent, ReactElement } from 'react';
import { isDefined } from 'helpers/utils';

type DocumentationLinkProps = {
  documentationPath?: string;
  children: ReactElement;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
}

const DocumentationLink: FunctionComponent<DocumentationLinkProps> = ({
  documentationPath: to,
  onClick,
  children,
  className,
}) => {
  let documentationUrl = '/documentation';
  if (isDefined(to)) {
    documentationUrl += `/${to}`;
  }

  return (
    <a
      href={documentationUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      className={className}
    >
      {children}
    </a>
  );
};

export default DocumentationLink;
