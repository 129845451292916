import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types/index';
import './TitleBar.scss';

type TitleBarProps = {
  theme: ThemeProp,
  className?: string,
  title?: string,
  caption?: string,
  withBorder?: boolean,
};
const TitleBar: FunctionComponent<TitleBarProps> = ({
  className = '',
  title = null,
  caption = null,
  theme,
  withBorder = false,
}) => (
  <div className={`title-bar ${className} ${theme} ${withBorder ? 'include-border' : ''}`} data-test="title-bar">
    <header className="title-header">
      <h3 className="title">{title}</h3>
      <h4 className="secondary-title">{caption}</h4>
    </header>
  </div>
);

export default TitleBar;
