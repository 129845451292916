import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const GroupByHierarchyIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height,
  width,
}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" height={height} width={width} viewBox="0 0 19.2 24.1">
    <g id="Symbol_2_1" transform="translate(7828 474.982)">
      <g id="Ellipse_1" transform="translate(575 212.31)">
        <ellipse fill="none" cx="-8399.4" cy="-683.7" rx="3.6" ry="3.6" />
        <path
          fill={color}
          d="M-8399.4-680.1c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6
            C-8395.8-681.7-8397.4-680.1-8399.4-680.1z M-8399.4-685.3c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
            S-8398.5-685.3-8399.4-685.3z"
        />
      </g>
      <g id="Ellipse_2" transform="translate(586.982 219.979)">
        <ellipse fill="none" cx="-8399.4" cy="-683.7" rx="3.6" ry="3.6" />
        <path
          fill={color}
          d="M-8399.4-680.1c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6
          C-8395.8-681.7-8397.4-680.1-8399.4-680.1z M-8399.4-685.3c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6
          S-8398.5-685.3-8399.4-685.3z"
        />
      </g>
      <g id="Ellipse_3" transform="translate(586.982 229.24)">
        <ellipse fill="none" cx="-8399.4" cy="-683.7" rx="3.6" ry="3.6" />
        <path
          fill={color}
          d="M-8399.4-680.1c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6S-8397.4-680.1-8399.4-680.1z
          M-8399.4-685.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6C-8397.8-684.6-8398.5-685.3-8399.4-685.3z"
        />
      </g>
      <rect x="-7824.4" y="-464.7" fill={color} width="9.5" height="2" />
      <path
        fill={color}
        d="M-7815-453.3h-7c-0.9,0-1.7-0.3-2.3-0.9c-1.1-1.1-1.1-2.9-1.1-3.1v-11.3h2v11.3c0,0,0.1,1.2,0.5,1.6
        c0.1,0.1,0.4,0.3,0.9,0.3h7V-453.3z"
      />
    </g>
  </svg>
);

export default GroupByHierarchyIcon;
