import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import { useRequestEffect } from 'hooks/useRequest';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ThemeProp } from 'types/index';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey, isEmptyObject } from 'helpers/utils';
import Accordion from 'components/Accordion';
import { controlModesList } from 'helpers/cim/getControlModeName';
import RadioButtonGroup from 'components/RadioButtonGroup';

type ControlModesProps = {
  selectedContainers?: { id: string }[],
  workspace: string,
  branch: string,
  onChange: (args?: { [key: string]: string }) => void,
};

type ControlModeResults = [{
    cim_class: string,
    control_mode: string,
    id: string,
    linked_equipment_container: null|[]|string,
    name: string,
  }
];

type AssetsByControlModes = {
  [asset: string]: {
    [id: string]: {
      cim_class: string,
      control_mode: string,
      id: string,
      linked_equipment_container: null|[]|string,
      name: string,
    }
  }
};

const ControlModesMenu: FunctionComponent<ControlModesProps> = ({
  workspace,
  branch,
  selectedContainers,
  onChange,
}) => {
  const theme = useContext(ThemeContext) as ThemeProp;
  const [assetsByControlModes, setAssetsByControlModes] = useState({} as AssetsByControlModes);
  const {
    data: ControlmodesData, loading,
  } = useRequestEffect<ControlModeResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset/control_modes`,
    method: 'get',
    params: {
      container: selectedContainers?.map((x) => x.id),
    },
    refetchOnChange: [workspace, branch, selectedContainers],
    disabled: !(workspace && branch && selectedContainers && selectedContainers && selectedContainers?.length),
    onSuccess: (results) => {
      const resultsData = results as ControlModeResults;
      const allAssets = resultsData.reduce((list: AssetsByControlModes, asset) => {
        const assetClass = asset.cim_class;
        list[assetClass] = { ...list[assetClass], [asset.id]: asset };
        return list;
      }, {});
      setAssetsByControlModes(allAssets);
    },
  });
  const controlModes = alphabetizeByKey(Object.keys(controlModesList)?.map((key) => ({ id: key })), 'id');
  useEffect(() => {
    if (!isEmptyObject(assetsByControlModes)) {
      const controlModesUpdated = ControlmodesData?.reduce(
        (newMode: {[id: string]: string}, mode) => {
          newMode[mode.id] = assetsByControlModes[mode.cim_class][mode.id].control_mode;
          return newMode;
        }, {},
      );
      onChange(controlModesUpdated);
    }
  }, [assetsByControlModes, ControlmodesData, onChange]);
  return (
    <div className="controlmodes-selection">
      <p className="title-text">Control Modes</p>
      <div className="asset-group">
        <div className="asset-name" />
        <div className="radio-button-group controlmode-types" style={{ gridTemplateColumns: `repeat(${controlModes?.length}, 1fr)`, padding: '0' }}>
          {
            controlModes?.map((mode: {[id: string]: string}) => (
              <span key={mode.id}>{controlModesList[mode.id]}</span>))
          }
        </div>
      </div>
      { loading ? (
        <LoadingSkeleton template="line" width={100} theme={theme} count={4} />
      ) : (
        <Accordion
          theme={theme}
          tabs={Object.entries(assetsByControlModes)?.map(
            ([key, assetObj], index: number) => {
              const controlModeByAssetType = Object.values(assetObj).map((ast) => (ast.control_mode))
                .filter((v, i, a) => a.indexOf(v) === i);
              return ({
                key: index,
                title: key,
                tabHeader:
          <div className="radio-button-group">
            <RadioButtonGroup
              options={controlModes}
              id={`asset-type-selector-${key}`}
              key={`asset-type-selector-${key}`}
              theme={theme}
              value={controlModeByAssetType && controlModeByAssetType.length === 1
                ? controlModeByAssetType[0] : ''}
              listType="row"
              onChange={({ target }: {[key: string]: { value: string}}) => {
                const assetByIds = Object.values(assetObj).reduce((obj, assetType) => {
                  obj = {
                    ...obj,
                    [assetType.id]: { ...assetType, control_mode: target.value },
                  };
                  return obj;
                }, {});
                setAssetsByControlModes({
                  ...assetsByControlModes,
                  [key]: {
                    ...assetObj,
                    ...assetByIds,
                  },
                });
              }}
              radioType="primary"
            />
          </div>,
                tabBody:
            Object.values(assetObj).map((asset) => (
              <div className="asset-group" key={asset.id}>
                <div className="asset-name">{asset.name}</div>
                <div className="radio-button-group">
                  <RadioButtonGroup
                    options={controlModes}
                    id={`asset-selector-${asset.id}`}
                    key={`asset-selector-${asset.id}`}
                    theme={theme}
                    value={asset.control_mode}
                    listType="row"
                    onChange={({ target }: {[key: string]: { value: string}}) => {
                      const newAssetObj = {
                        ...assetsByControlModes,
                        [key]: { ...assetObj, [asset.id]: { ...asset, control_mode: target.value } },
                      };
                      setAssetsByControlModes(newAssetObj);
                    }}
                    radioType="primary"
                  />
                </div>
              </div>
            )),
              });
            },
          )}
        />
      )}
    </div>
  );
};
export default ControlModesMenu;
