import React, {
  useState, useEffect, useCallback, createContext,
} from 'react';
import useModifiedState from 'hooks/useModifiedState';
import PropTypes from 'prop-types';
import asyncActionStates from 'helpers/asyncActionStates';
import Request from 'helpers/Request';
import { isDefined } from 'helpers/utils';

export const WorkspaceSettingsContext = createContext();

const getApiProp = (prop) => {
  switch (prop) {
    case 'discountRate':
      return 'discount_rate';
    case 'remainingValueEquation':
      return 'remaining_value_of_replaced_assets';
    case 'CHLCost':
      return 'customer_hours_lost_cost';
    case 'CICost':
      return 'customer_interruption_cost';
    case 'mapboxSource':
      return 'mapbox_source';
    default:
      return prop;
  }
};

const mapResponseData = (data) => ['discountRate', 'remainingValueEquation', 'CHLCost', 'CICost', 'mapboxSource'].reduce(
  (prev, curr) => ({ ...prev, [curr]: data[getApiProp(curr)] ?? '' }), {},
);

export default function WorkspaceSettingsContextProvider({ children, workspace, permissions }) {
  const [discountRate, setDiscountRate, discountRateModified] = useModifiedState('');
  const [remainingValueEquation, setRemainingValueEquation, remainingValueEquationModified] = useModifiedState('');
  const [CHLCost, setCHLCost, CHLCostModified] = useModifiedState('');
  const [CICost, setCICost, CICostModified] = useModifiedState('');
  const [mapboxSource, setMapboxSource, mapboxSourceModified] = useModifiedState('');
  const [mapMode, setMapMode, mapModeModified] = useModifiedState('');
  const [loadingState, setLoadingState] = useState(asyncActionStates.INITIAL);

  /**
   * Updates the settings provided through the settings dict. Similar pattern to react setProps
   * @param {object} settings - the settings to be updated
   * @param {boolean} override - if true, sets the modified state of the settings to false.
   */
  const setSettings = useCallback((settings, override = false) => {
    if (isDefined(settings.discountRate)) {
      setDiscountRate(settings.discountRate, override);
    }
    if (isDefined(settings.remainingValueEquation)) {
      setRemainingValueEquation(settings.remainingValueEquation, override);
    }
    if (isDefined(settings.CHLCost)) {
      setCHLCost(settings.CHLCost, override);
    }
    if (isDefined(settings.CICost)) {
      setCICost(settings.CICost, override);
    }
    if (isDefined(settings.mapboxSource)) {
      setMapboxSource(settings.mapboxSource, override);
    }
    if (isDefined(settings.mapMode)) {
      setMapMode(settings.mapMode, override);
    }
  }, [setDiscountRate, setRemainingValueEquation, setCHLCost, setCICost, setMapboxSource, setMapMode]);

  useEffect(() => {
    const loadSettings = async () => {
      const url = `/api/workspace/${workspace}/cim-datastore-settings`;
      try {
        setLoadingState(asyncActionStates.LOADING);
        const results = await new Request(url).get();
        setSettings(mapResponseData(results.data), true);
        setLoadingState(asyncActionStates.SUCCESS);
      } catch (err) {
        setLoadingState(asyncActionStates.ERROR);
      }
    };
    if (workspace && permissions.has('cim_datastore_settings')) {
      loadSettings();
    } else {
      setLoadingState(asyncActionStates.ERROR);
    }
  }, [setSettings, workspace, permissions]);

  useEffect(() => {
    // get the value from the workspace setting (if available)
    // else get it from the (deprecated) global setting
    setMapMode(localStorage.getItem(`MAP_MODE_${workspace}`) ?? localStorage.getItem('mapMode') ?? '', true);
  }, [setMapMode, workspace]);

  const saveSettings = async (type = '') => {
    // caller is left to handle errors
    const settingsUrl = `/api/workspace/${workspace}/cim-datastore-settings`;
    if ((discountRateModified || remainingValueEquationModified) && type === 'npv') {
      const results = await new Request(settingsUrl).put({
        [getApiProp('discountRate')]: discountRate,
        [getApiProp('remainingValueEquation')]: remainingValueEquation,
      });
      setSettings(mapResponseData(results.data), true);
    }
    if ((CHLCostModified || CICostModified) && type === 'cost') {
      const results = await new Request(settingsUrl).put({
        [getApiProp('CHLCost')]: CHLCost,
        [getApiProp('CICost')]: CICost,
      });
      setSettings(mapResponseData(results.data), true);
    }
    if (mapboxSourceModified) {
      const adminSettingsUrl = `/api/workspace/${workspace}/cim-datastore-settings/admin`;
      const results = await new Request(adminSettingsUrl).put({
        [getApiProp('mapboxSource')]: mapboxSource,
      });
      setSettings(mapResponseData(results.data), true);
    }
    if (mapModeModified) {
      localStorage.setItem(`MAP_MODE_${workspace}`, mapMode);
      setSettings({ mapMode }, true);
    }
  };

  let mapModeDefault = mapMode;
  // if no saved mapMode is found, choose the default based on whether a source has been specified.
  if (mapMode === '') {
    if (mapboxSource === '') {
      mapModeDefault = '2d';
    } else {
      mapModeDefault = 'custom';
    }
  }

  return (
    <WorkspaceSettingsContext.Provider
      value={{
        settings: {
          discountRate, remainingValueEquation, CHLCost, CICost, mapboxSource, mapMode: mapModeDefault,
        },
        settingsModified: {
          discountRate: discountRateModified,
          remainingValueEquation: remainingValueEquationModified,
          CHLCost: CHLCostModified,
          CICost: CICostModified,
          mapboxSource: mapboxSourceModified,
          mapMode: mapModeModified,
        },
        setSettings: settings => setSettings(settings),
        saveSettings,
        loadingState,
      }}
    >
      {children}
    </WorkspaceSettingsContext.Provider>
  );
}

WorkspaceSettingsContextProvider.defaultProps = {
  workspace: null,
};

WorkspaceSettingsContextProvider.propTypes = {
  workspace: PropTypes.string,
  permissions: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};
