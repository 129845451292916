import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types/index';
import ServiceStatusTile from './ServiceStatusTile';

import './ServiceDashboard.scss';

type ServiceDashboardProps = {
  permissions: Set<string>;
  theme: ThemeProp;
}

const ServiceDashboard: FunctionComponent<ServiceDashboardProps> = ({
  permissions,
  theme,
}) => (
  <div className="service-dashboard">
    {permissions.has('view_service_status') ? (
      <>
        <ServiceStatusTile serviceName="Orchestrator" servicePath="" theme={theme} />
        <ServiceStatusTile serviceName="CIM Datastore" servicePath="/cim-datastore" theme={theme} />
        <ServiceStatusTile serviceName="Analytics" servicePath="/analytics" theme={theme} />
        <ServiceStatusTile serviceName="Auth" servicePath="/auth" theme={theme} />
      </>
    ) : <div className="service-dashboard-permissions">You do not have permission to view service status</div>}
  </div>
);

export default ServiceDashboard;
