import React, {
  FunctionComponent, useState, useEffect, useContext, useMemo,
} from 'react';
import { useParams } from 'react-router';
import ExpandableSection from 'components/ExpandableSection';
import GridLayout from 'layouts/GridLayout';
import Request from 'helpers/Request';
import asyncActionStates from 'helpers/asyncActionStates';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ScenarioTypes } from 'helpers/scenarios';
import ThemeContext from 'helpers/ThemeContext';
import Button from 'components/Button';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import SelectBadgeItem from 'components/SelectBadgeItem';
import NetworkTopNav from '../../../containers/NetworkTopNavContainer';

import './WorkspaceOverview.scss';

type NetworkVersionType = {
  name: string,
  notes?: string,
}

type ScenarioType = {
  id: string,
  name: string,
  start_date: string | null,
  end_date: string | null,
  scenario_type: string,
}

const WorkspaceOverview: FunctionComponent = () => {
  const theme = useContext(ThemeContext);
  const [networkVersions, setNetworkVersions] = useState<NetworkVersionType[]>([]);
  const [scenarios, setScenarios] = useState<{[key: string]: Array<ScenarioType>}>({});
  const [versionLoading, setVersionLoading] = useState(asyncActionStates.INITIAL);
  const [scenarioLoading, setScenarioLoading] = useState<{[key: string]: number}>({});
  const [versionsOpen, setVersionsOpen] = useState<{[key: string]: boolean}>({});

  const { workspace } = useParams<{ workspace: string, branch: string }>();

  const fetchNetworkVersions = useMemo(() => async () => {
    // get list of network versions
    const versionRequest = new Request(
      `/api/workspace/${workspace}/branch`,
    );
    setVersionLoading(asyncActionStates.LOADING);
    let versions = []; const scenObj = {}; let vOpen = {};
    try {
      const res = await versionRequest.get();
      const { data } = res;
      versions = data.map((version: NetworkVersionType) => ({
        name: version.name,
        notes: version.notes || '',
      }));
      vOpen = versions.reduce((obj: {[key: string]: boolean}, v: NetworkVersionType) => {
        obj[v.name] = false;
        return obj;
      }, {});
      setVersionLoading(asyncActionStates.SUCCESS);
    } catch (err) {
      setVersionLoading(asyncActionStates.ERROR);
    }
    setNetworkVersions(versions);
    setScenarios(scenObj);
    setVersionsOpen(vOpen);
  }, [workspace]);

  useEffect(() => {
    if (workspace) {
      fetchNetworkVersions();
    }
  }, [workspace, fetchNetworkVersions]);

  const fetchScenarios = async (branch: string) => {
    let scenarioList = scenarios[branch] || [];

    // get list of scenarios
    const scenarioRequest = new Request(
      `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`,
    );

    if (scenarioLoading[branch] !== asyncActionStates.LOADING) {
      if (scenarioLoading[branch] !== asyncActionStates.SUCCESS) {
        setScenarioLoading((prevState) => ({
          ...prevState,
          [branch]: asyncActionStates.LOADING,
        }));
      }
      try {
        const res = await scenarioRequest.get();
        const { data } = res;
        scenarioList = data.map((scenario: ScenarioType) => ({
          id: scenario.id,
          name: scenario.name,
          // forcing extra spacing into date format for readability, as per design
          start_date: scenario.start_date ? moment.utc(scenario.start_date).format('DD MMM, YYYY[\xa0\xa0\xa0\xa0]HH:mm') : null,
          end_date: scenario.end_date ? moment.utc(scenario.end_date).format('DD MMM, YYYY[\xa0\xa0\xa0\xa0]HH:mm') : null,
          scenario_type: scenario.scenario_type,
        }));
        setScenarioLoading((prevState) => ({
          ...prevState,
          [branch]: asyncActionStates.SUCCESS,
        }));
      } catch (err) {
        setScenarioLoading((prevState) => ({
          ...prevState,
          [branch]: asyncActionStates.ERROR,
        }));
      }
      setVersionsOpen((prevState) => ({
        ...prevState,
        [branch]: true,
      }));
      setScenarios((prevState) => ({
        ...prevState,
        [branch]: scenarioList,
      }));
    }
  };

  return (
    <div className="overview-with-nav">
      <NetworkTopNav params={useParams()} />
      <div className="workspace-overview">
        <div className="page-header-row">
          <div className="header-row">
            <GridLayout>
              <div className="one-seven grid-columns">
                <div className="single-column header">Versions</div>
                <div className="single-column header">Notes</div>
              </div>
            </GridLayout>
          </div>
          <Button
            label="Refresh page"
            className="refresh-button"
            onClick={fetchNetworkVersions}
            loading={versionLoading === asyncActionStates.LOADING}
          />
        </div>
        {[asyncActionStates.INITIAL, asyncActionStates.LOADING].includes(versionLoading) && (
          <LoadingSkeleton className="loading-skeleton-new-theme" template="line" width={100} theme={theme} count={3} />
        )}
        {versionLoading === asyncActionStates.SUCCESS && networkVersions.map((version: NetworkVersionType) => (
          <div className="version-row" key={version.name}>
            <ExpandableSection
              id={version.name}
              className="version-expandable-header"
              newExpandIcon
              toggleClick={async () => {
                if (!versionsOpen[version.name]) {
                  if (!scenarios[version.name] || scenarios[version.name].length === 0) {
                    await fetchScenarios(version.name);
                  }
                  versionsOpen[version.name] = true;
                } else {
                  versionsOpen[version.name] = false;
                }
              }}
              open={versionsOpen[version.name]}
              sectionName={(
                <GridLayout>
                  <div className="one-seven grid-columns version-expandable-text">
                    <div className="single-column workspace-name">
                      <span className="dot" />
                      {version.name}
                    </div>
                    <div className="single-column">{version.notes}</div>
                  </div>
                </GridLayout>
              )}
            >
              {[asyncActionStates.INITIAL, asyncActionStates.LOADING].includes(scenarioLoading[version.name]) && (
                <LoadingSkeleton className="loading-skeleton-new-theme" template="line" width={100} theme={theme} count={3} />
              )}
              {scenarioLoading[version.name] === asyncActionStates.SUCCESS && (
                <>
                  <GridLayout>
                    <div className="one-seven grid-columns header-row">
                      <div className="single-column header scenario-info">Scenarios</div>
                      <div className="single-column header scenario-info">
                        <div className="header scenario-dates"><ReactSVG src="/calendar.svg" /></div>
                        <div className="header scenario-dates">Start date</div>
                        <div className="header scenario-dates">End date</div>
                      </div>
                    </div>
                  </GridLayout>
                  {scenarios[version.name]?.map((scenario: ScenarioType) => (
                    <div key={scenario.name} className="scenario-row">
                      <div className="scenario-section">
                        <GridLayout>
                          <div className="one-seven grid-columns">
                            <div className="single-column scenario-info">
                              <SelectBadgeItem
                                item={scenario}
                                badgeInfos={[{
                                  tooltip: scenario.scenario_type === ScenarioTypes.timeseries ? 'Timeseries' : 'Snapshot',
                                  key: scenario.id ?? 'badge',
                                  label: scenario.scenario_type === ScenarioTypes.timeseries ? 'T' : 'S',
                                  noHover: true,
                                }]}
                              />
                              <div className="scenario-name">{scenario.name}</div>
                            </div>
                            <div className="single-column scenario-info">
                              <div className={scenario.start_date ? 'scenario-dates' : 'scenario-dates badge-empty'}><ReactSVG src="/calendar.svg" /></div>
                              {scenario.start_date ? (
                                <>
                                  <div className="scenario-dates date-badge">{scenario.start_date}</div>
                                  {scenario.scenario_type === ScenarioTypes.timeseries && scenario.end_date && (
                                    <div className="scenario-dates date-badge">{scenario.end_date}</div>
                                  )}
                                </>
                              ) : (<div className="scenario-dates-empty">No associated forecast</div>)}
                            </div>
                          </div>
                        </GridLayout>
                      </div>
                    </div>
                  ))}
                  {scenarios[version.name]?.length === 0 && (
                    <div className="no-scenarios">No scenarios for this network version</div>
                  )}
                </>
              )}
            </ExpandableSection>
          </div>
        ))}
        {versionLoading === asyncActionStates.ERROR && (
          <div className="version-row">There was an error loading network versions</div>
        )}
      </div>
    </div>
  );
};

export default WorkspaceOverview;
