import React, {
  useState, useEffect, FunctionComponent, CSSProperties,
} from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import CustomCheckbox from 'components/CustomCheckbox';
import StyledVirtualizedList from 'components/StyledVirtualizedList';
import { sortByName } from 'helpers/utils';
import {
  Node, LinkIcon, ShuntIcon, CimProperty, Line,
} from 'types/cim';
import InstanceTile from './InstanceTile';

const nameOrIdMatchesFilter = (filter: string, { id, name }: CimProperty) => {
  if (!filter) return true;
  if (!id && !name) return false;

  const regex = new RegExp(`.*${escapeRegExp(filter)}.*`, 'i');
  const str = name || id[0] || id;
  return str && str.match(regex);
};

type InstanceList = (Node|LinkIcon|ShuntIcon|Line)[];
const filterInstances = (
  assets: InstanceList,
  filter: string,
  showOnlyVisible: boolean,
  assetsInView: string[],
): InstanceList => {
  const filteredAssets = [];
  const assetSet = new Set(assetsInView);
  for (let i = 0; i < assets.length; i += 1) {
    const asset = assets[i];
    if (nameOrIdMatchesFilter(filter, asset.properties)) {
      if (showOnlyVisible && assetSet.has(asset.properties.id)) {
        filteredAssets.push(asset);
      }
      if (!showOnlyVisible) {
        filteredAssets.push(asset);
      }
    }
  }

  return filteredAssets.sort(sortByName);
};

type InstanceDropdownProps = {
  type: string;
  feeders: { id: string }[];
  selectedAssetID: string|undefined|null;
  selectedAssetViewModelClass: string;
  instanceList: InstanceList;
  setSelectedAssetID: (id: string|null) => void;
  onInstanceHover: (id: string|null) => void;
  filterString?: string;
  showOnlyVisible?: boolean;
  assetsInView: string[];
  openTabs: string[];
  onMenuClick: (type: string) => void;
  setAssetTypeVisibility: (type: string, visible: boolean) => void;
  assetTypeVisibility: { [key: string]: boolean };
  inEditMode: boolean;
};

/**
 * Creates a toggleable menu that accepts a CIM type and filters in view instance
 * data to display all items of that type as a list.
 */
const InstanceDropdown:FunctionComponent<InstanceDropdownProps> = ({
  type,
  feeders,
  selectedAssetID,
  selectedAssetViewModelClass,
  instanceList,
  setSelectedAssetID,
  onInstanceHover,
  filterString = '',
  showOnlyVisible = false,
  assetsInView,
  onMenuClick,
  openTabs,
  setAssetTypeVisibility,
  assetTypeVisibility,
  inEditMode,
}) => {
  /* State */
  const [inFeeder, setInFeeder] = useState<InstanceList>([]);
  const [filteredInstances, setFilteredInstances] = useState<InstanceList>([]);

  // Handle updates to asset data
  useEffect(() => {
    const newInFeeder = instanceList.filter(
      item => !item.properties.feeder || feeders.some(({ id }) => item.properties.feeder === id),
    );
    setInFeeder(newInFeeder);
    setFilteredInstances(
      filterInstances(newInFeeder, filterString, showOnlyVisible, assetsInView),
    );
  }, [feeders, instanceList, filterString, showOnlyVisible, assetsInView]);

  /* List Renderer */
  const rowRenderer = ({ index, style }: { index: number, style: CSSProperties|undefined }) => {
    const instance = filteredInstances[index];
    const { id } = instance.properties;
    return (
      <InstanceTile
        instance={instance}
        selected={selectedAssetID === id}
        style={style}
        key={id}
        onClick={setSelectedAssetID}
        onHover={onInstanceHover}
      />
    );
  };

  // Don't render dropdown if there are no instances in the selected feeders
  if (inFeeder.length === 0) return null;

  // Only render the dropdown if there are results to display
  // Min height is needed to ensure nested scroll area renders correctly
  const height = Math.min(200, filteredInstances.length * 32);
  const opened = openTabs.includes(type);
  const typeSelected = selectedAssetViewModelClass ? selectedAssetViewModelClass === type : false;
  // Styles for open and selected states
  const openedStyle = opened ? 'asset-type-tile-contents--open' : '';
  const selectedStyle = typeSelected ? 'active-tile' : '';

  return (
    <div key={type} className="asset-type-container">
      <div className={`asset-type-container-header ${selectedStyle}`}>
        {!inEditMode && (
        <CustomCheckbox
          // To avoid having to define all new types in the default state,
          // assume that no state for this type means that it is visible.
          id={`checkbox-${type}`}
          checked={assetTypeVisibility[type] === undefined || assetTypeVisibility[type]}
          disabled={filterInstances.length === 0}
          onClick={e => setAssetTypeVisibility(type, e.target.checked)}
        />
        )}
        <button
          className="asset-type-tile"
          onClick={() => onMenuClick(type)}
          disabled={filteredInstances.length === 0}
          type="button"
        >
          <div className={`asset-type-tile-contents ${openedStyle}`}>
            <p className="asset-type-tile-contents__p">
              <span>
                {`${type} (${filteredInstances.length}/${inFeeder.length})`}
              </span>
              <i className="material-icons">
                {opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </p>
          </div>
        </button>
      </div>
      <div>
        <ul
          style={{ height: `${height}px`, width: '270px' }}
          className={`asset-type-list ${
            opened ? 'asset-type-list--opened' : 'asset-type-list--closed'
          }`}
        >
          <StyledVirtualizedList
            scrollbarClassName="instance-dropdown-scrollbar"
            height={height}
            width={270}
            color="rgba(6,175,168,0.5)"
            overscanRowCount={10}
            rowCount={filteredInstances.length}
            rowHeight={32}
            rowRenderer={rowRenderer}
            autoHide={false}
            status={opened ? 'up' : 'down'}
          />
        </ul>
      </div>
    </div>
  );
};

export default InstanceDropdown;
