import asyncActionStates from 'helpers/asyncActionStates';
import Request from './Request';

export function GetRolesDisplayText(userRoles) {
  if (!userRoles) {
    return '';
  } if (userRoles.includes('sharing') && userRoles.includes('freemium_demo')) {
    return 'Editor + Sharing';
  } if (userRoles.includes('freemium_demo')) {
    return 'Editor';
  } if (userRoles.includes('freemium_demo_view')) {
    return 'Viewer';
  }
  return ''; // unknown role, default to no display
}

export async function FetchUsers(workspace, setUserLoadingState, setUsers) {
  const usersRequest = new Request(`/api/workspace/${workspace}/users`);
  const pendingUsersRequest = new Request(`/api/workspace/${workspace}/pending_users`);
  setUserLoadingState(asyncActionStates.LOADING);
  const usersResponse = await usersRequest.get();
  const pendingUsersResponse = await pendingUsersRequest.get();

  const userList = [...usersResponse.data];
  const pendingUsers = pendingUsersResponse.data;
  pendingUsers.forEach((element) => {
    userList.push({
      email: element.email,
      pending: true,
    });
  });
  setUsers(userList);
  setUserLoadingState(asyncActionStates.SUCCESS);
}
