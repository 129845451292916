import React, { FunctionComponent, useContext } from 'react';
import ThemeContext from 'helpers/ThemeContext';
import { IconPropsSchema } from '../../types/index';
import { getIconColor } from '../helpers/formatters';

const DownloadIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  width = '13px',
  height = '12px',
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width={width} height={height} viewBox="0 0 13 12" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01_IDP_RC-Copy-16" transform="translate(-383.000000, -144.000000)">
          <rect x="0" y="0" width="1366" height="800" />
          <rect id="Mask" stroke="#F6F6F7" x="0.5" y="101.5" width="511" height="709" rx="4" />
          <rect id="Rectangle-Copy-48" stroke="#EDF0F5" x="180.5" y="130.5" width="320" height="100" rx="5" />
          <g id="download" transform="translate(383.000000, 144.000000)" fill={getIconColor(theme, color)} fillRule="nonzero">
            <g id="Path">
              <path d="M12.5267565,7.57894737 C12.2653913,7.57894737 12.0535129,7.7884048 12.0535129,8.04678363 L12.0535129,9.64444444 C12.0535129,10.4286242 11.4104621,11.0643275 10.6172188,11.0643275 L2.38278122,11.0643275 C2.00185233,11.0643275 1.63652529,10.9147331 1.36716789,10.6484534 C1.09781049,10.3821736 0.946487077,10.0210208 0.946487077,9.64444444 L0.946487077,8.04678363 C0.946487077,7.7884048 0.734608728,7.57894737 0.473243538,7.57894737 C0.211878349,7.57894737 0,7.7884048 0,8.04678363 L0,9.64444444 C0.00130434709,10.9448474 1.06734815,11.9987106 2.38278122,12 L10.6172188,12 C11.9326519,11.9987106 12.9986957,10.9448474 13,9.64444444 L13,8.04678363 C13,7.7884048 12.7881217,7.57894737 12.5267565,7.57894737 L12.5267565,7.57894737 Z" />
              <path d="M6.17281464,8.70634759 C6.26431211,8.79323329 6.38886235,8.84210526 6.51879313,8.84210526 C6.64872391,8.84210526 6.77327415,8.79323329 6.86477162,8.70634759 L9.63747248,6.09490227 C9.7977612,5.91324643 9.78548738,5.64667152 9.60910395,5.47876633 C9.43272052,5.31086115 9.1498316,5.29646095 8.95526137,5.44548309 L7.00608678,7.28129702 L7.00608678,0.458953484 C7.00608678,0.205480474 6.78791798,0 6.51879313,0 C6.24966828,0 6.03149948,0.205480474 6.03149948,0.458953484 L6.03149948,7.27441272 L4.08232488,5.43859879 C3.89191905,5.25926664 3.5832102,5.25926664 3.39280437,5.43859879 C3.20239854,5.61793094 3.20239854,5.90868581 3.39280437,6.08801797 L6.17281464,8.70634759 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DownloadIcon;
