import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import AnalysisEvent from './AnalysisEvent';
import './ActivityLogPanel.scss';

const ActivityLogPanel = ({
  actions, containers, selectedContainers, logEntries, expanded, permissions,
}) => (
  <div className="activity-log-panel">
    <div
      className={classNames({
        'panel-header': true,
        'panel-header--expanded': expanded,
      })}
    >
      <h1 className="title-text">Activity Log</h1>
    </div>
    <div
      className={classNames({
        'activity-log-events': true,
        'activity-log-events--expanded': expanded,
      })}
    >
      <CustomScrollBar>
        {logEntries.map(evt => (
          <AnalysisEvent
            event={evt}
            containers={containers}
            selectedContainers={selectedContainers}
            cancelJob={actions.cancelJob}
            key={evt.job_id}
            permissions={permissions}
          />
        ))}
      </CustomScrollBar>
    </div>
  </div>
);

ActivityLogPanel.defaultProps = {
  logEntries: [],
};

ActivityLogPanel.propTypes = {
  actions: PropTypes.shape({
    cancelJob: PropTypes.func,
    getBatteryAnalysisResults: PropTypes.func,
  }).isRequired,
  containers: PropTypes.array.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  logEntries: PropTypes.array,
  expanded: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default ActivityLogPanel;
