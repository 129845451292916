/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from 'components/IconButton';
import ThemedTabs from 'components/ThemedTabs';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';

import WorkspaceSettingsContextProvider from 'contexts/WorkspaceSettingsContext';
import Request from 'helpers/Request';

import AnalyticsConfig from './AnalyticsConfig';
import AuthConfig from './AuthConfig';
import UserPanel from './UserPanel';
import NetworkImportsPanel from './NetworkImportsPanel';
import GisSettingsPanel from './GisSettingsPanel';

const WorkspaceCard = ({
  canEditAuth,
  canEditAnalytics,
  canEditCimDatastore,
  canViewUsers,
  canDelete,
  canDownload,
  permissions,
  displayAlertMessage,
  onDeleteWorkspace,
  onToggle,
  open,
  theme,
  workspace,
}) => {
  // Download workspace state
  const [downloadState, setDownloadState] = useState(asyncActionStates.INITIAL);
  const downloadWorkspace = async () => {
    setDownloadState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace/${workspace}/branch/master/xml`);

    try {
      const { data } = await request.get();
      const blob = new Blob([data], { type: 'application/xml' });
      FileSaver.saveAs(blob, `${workspace}.xml`);
      setDownloadState(asyncActionStates.SUCCESS);
    } catch (error) {
      setDownloadState(asyncActionStates.ERROR);
      // TODO: figure out where to get this from
      displayAlertMessage(
        'Download Failed',
        'There was a problem downloading your selected file. Please try again.',
        null,
        () => downloadWorkspace(workspace),
      );
    }
  };

  return (
    <div className={`workspace-card ${open ? 'workspace-card-open' : ''}`}>
      <div className="workspace-card-header">
        <div
          className="link"
          onClick={() => onToggle(!open)}
        >
          <Link to={`${workspace}/master/gis`} onClick={e => e.stopPropagation()}>
            <h2 className="workspace-card-title">{workspace}</h2>
          </Link>
        </div>
        <PermissionDisabledTip title="Download Network" placement="bottom" hide={canDownload} theme={theme}>
          {downloadState === asyncActionStates.LOADING
            ? <div className="loading-spinner"><i className="material-icons rotate">autorenew</i></div>
            : (
              <IconButton
                icon="get_app"
                className="download"
                disabled={!canDownload}
                onClick={() => downloadWorkspace()}
                theme={theme}
                tooltip="Download Workspace"
              />
            )}
        </PermissionDisabledTip>
        <PermissionDisabledTip title="Delete Workspace" placement="bottom" hide={canDelete} theme={theme}>
          <IconButton
            icon="delete"
            theme={theme}
            disabled={!canDelete}
            className="delete"
            onClick={() => onDeleteWorkspace(workspace)}
            tooltip="Delete Workspace"
          />
        </PermissionDisabledTip>
        <IconButton
          className="open-button"
          icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          onClick={() => onToggle(!open)}
          theme={theme}
        />
      </div>
      {open
        && (
        <div className="workspace-card-body">
          <WorkspaceSettingsContextProvider workspace={workspace} permissions={permissions}>
            <ThemedTabs
              theme={theme}
              underline="line"
              tabs={[
                { name: 'Auth' },
                { name: 'Analytics' },
                { name: 'Network Imports' },
                { name: 'Users' },
                { name: 'GIS Settings' },
              ]}
            >
              {TabPanel => (
                <>
                  <TabPanel>
                    <AuthConfig
                      disabled={!canEditAuth}
                      displayAlertMessage={displayAlertMessage}
                      open={open}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                  <TabPanel>
                    <AnalyticsConfig
                      disabled={!canEditAnalytics}
                      displayAlertMessage={displayAlertMessage}
                      open={open}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                  <TabPanel>
                    <NetworkImportsPanel
                      disabled={!canViewUsers}
                      displayAlertMessage={displayAlertMessage}
                      open={open}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                  <TabPanel>
                    <UserPanel
                      disabled={!canViewUsers}
                      displayAlertMessage={displayAlertMessage}
                      open={open}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                  <TabPanel>
                    <GisSettingsPanel
                      disabled={!canEditCimDatastore}
                      displayAlertMessage={displayAlertMessage}
                      open={open}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                </>
              )}
            </ThemedTabs>
          </WorkspaceSettingsContextProvider>
        </div>
        )}
    </div>
  );
};

WorkspaceCard.defaultProps = {
  canEditAnalytics: false,
  canEditAuth: false,
  canEditCimDatastore: false,
  canDelete: false,
  canDownload: false,
  canViewUsers: false,
  open: false,
};

WorkspaceCard.propTypes = {
  canEditAuth: PropTypes.bool,
  canEditAnalytics: PropTypes.bool,
  canEditCimDatastore: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  canViewUsers: PropTypes.bool,
  permissions: PropTypes.object.isRequired,
  displayAlertMessage: PropTypes.func.isRequired,
  onDeleteWorkspace: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
};

export default WorkspaceCard;
