import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const ShowEmptyAttributeIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height,
  width,
}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 21.3 20.9" height={height} width={width}>
    <g id="Ellipse_37" transform="translate(1557.502 633.655)">
      <ellipse fill="none" cx="-1549.8" cy="-626" rx="7.7" ry="7.7" />
      <path
        fill={color}
        d="M-1549.8-618.3c-4.2,0-7.7-3.4-7.7-7.7c0-4.2,3.4-7.7,7.7-7.7s7.7,3.4,7.7,7.7
        C-1542.1-621.8-1545.6-618.3-1549.8-618.3z M-1549.8-631.5c-3,0-5.5,2.5-5.5,5.5s2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5
        S-1546.8-631.5-1549.8-631.5z"
      />
    </g>
    <g id="Ellipse_38" transform="translate(1571.881 645.82)">
      <path
        fill={color}
        d="M-1554.1-625c-1.9,0-3.5-1.9-3.5-4.3s1.5-4.3,3.5-4.3s3.5,1.9,3.5,4.3S-1552.2-625-1554.1-625z M-1554.1-631.7
        c-0.8,0-1.5,1.1-1.5,2.3s0.7,2.3,1.5,2.3s1.5-1.1,1.5-2.3S-1553.3-631.7-1554.1-631.7z"
      />
    </g>
  </svg>
);

export default ShowEmptyAttributeIcon;
