import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import DropdownMenu from 'components/DropdownMenu';
import { displayDSP } from 'helpers/permissions';

export default function NetworkNavExpandedMenu({
  permissions,
  theme,
  handleMenuSelection,
}) {
  const [open, setOpen] = useState(false);
  const options = [
    {
      contents: 'API Documentation',
      id: 'apiDocs',
      type: 'standard',
    },
  ];

  const openMarkets = () => {
    const { host, hostname } = window.location;
    let markets;

    if (hostname === 'localhost') {
      markets = 'http://localhost:3002';
    } else {
      const parts = host.split('.');
      parts[0] = `${parts[0]}-markets`;
      markets = `https://${parts.join('.')}`;
    }

    const marketsWindow = window.open(markets);

    // Try to pass the token along at most 5 times
    const tokenInterval = setInterval(() => {
      marketsWindow.postMessage(
        {
          action: 'TOKEN',
          token: localStorage.getItem('encBearerToken'),
          authEndpoint: localStorage.getItem('authEndpoint'),
        },
        '*',
      );
    }, 1000);
    const clearTokenTimer = setTimeout(() => {
      clearInterval(tokenInterval);
    }, 5000);

    window.addEventListener('message', e => {
      const message = e.data || {};

      if (message.action === 'TOKEN_RECIEVED') {
        clearInterval(tokenInterval);
        clearTimeout(clearTokenTimer);
      }
    });
  };

  if (displayDSP(permissions)) {
    options.push({
      contents: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" onClick={openMarkets}>
          Markets
        </a>
      ),
      id: 'markets',
      type: 'standard',
    });
  }

  return (
    <DropdownMenu
      id="expanded-nav-menu"
      className="nav-menu"
      open={open}
      contentPosition={{ right: '0px', top: '50px', zIndex: 1001 }}
      theme={theme}
      onClick={id => {
        if (id !== 'markets') {
          handleMenuSelection(id);
        }
      }}
      onToggle={openState => setOpen(openState)}
      menuOptions={options}
    >
      {onClick => (
        <IconButton
          icon="more_vert"
          className="expanded-menu"
          onClick={onClick}
          theme={theme}
          tooltip="More Navigation"
        />
      )}
    </DropdownMenu>
  );
}

NetworkNavExpandedMenu.propTypes = {
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  handleMenuSelection: PropTypes.func.isRequired,
};
