import React from 'react';
import PropTypes from 'prop-types';
import ListValue from './ListValue';
import PhaseSelectorCheckboxes from './PhaseSelectorCheckboxes';
import NodeDisplayContainer from './NodeDisplayContainer';

const LinkDeviceHeader = ({
  asset: {
    container,
    nodes = [],
    phases,
    balanced,
  },
  toggleFeederPanel,
  inEditMode,
  disabled,
  setSelectedAssetID,
  allowNeutral,
  handleSave,
  iconName,
  theme,
}) => (
  <div className="header-container">
    <ListValue
      onClick={toggleFeederPanel}
      values={container}
      label="Feeder/Substation"
    />
    <NodeDisplayContainer
      disabled={disabled}
      canSwap={inEditMode}
      icon={iconName}
      onNodeClick={({ id: nodeId }) => setSelectedAssetID(nodeId)}
      onSwap={() => handleSave({ flip_direction: true })}
      leftNode={nodes[0]}
      rightNode={nodes[1]}
      theme={theme}
    />
    <PhaseSelectorCheckboxes
      disabled={disabled || balanced || !inEditMode}
      onClick={p => {
        let newPhases = p;
        if (phases.includes(p)) {
          newPhases = phases.replace(p, '');
        } else {
          const phaseArray = phases.split('');
          phaseArray.push(p);
          phaseArray.sort();
          newPhases = phaseArray.join('');
        }
        handleSave({ balanced, phases: newPhases });
      }}
      phases={phases}
      allowNeutral={allowNeutral}
    />
  </div>
);

LinkDeviceHeader.defaultProps = {
  allowNeutral: false,
};

LinkDeviceHeader.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  allowNeutral: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default LinkDeviceHeader;
