import React, { FunctionComponent, useContext } from 'react';
import ThemeContext from 'helpers/ThemeContext';
import { IconPropsSchema } from '../../types/index';
import { getIconColor } from '../helpers/formatters';

const CollapseIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  width = '12px',
  height = '15px',
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      <g id="Page-1" stroke="none" fill="none">
        <g id="Custom-Preset-Copy-3" transform="translate(-302.000000, -1353.000000)">
          <rect id="Rectangle-Copy-13" stroke="#EDF0F5" fill="none" x="285.5" y="1337.5" width="41" height="41" rx="4" />
          <g id="expand-copy" transform="translate(302.000000, 1350.000000)" fill={getIconColor(theme, color)}>
            <polygon id="Rectangle-Copy-22" transform="translate(4.500000, 4.500000) rotate(45.000000) translate(-4.500000, -4.500000) " points="7 2 7 7 2 7" />
            <polygon id="Rectangle-Copy-21" transform="translate(4.500000, 12.500000) scale(1, -1) rotate(45.000000) translate(-4.500000, -12.500000) " points="7 10 7 15 2 15" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CollapseIcon;
