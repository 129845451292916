import CustomCheckbox from 'components/CustomCheckbox';
import VoltageLimits from 'components/VoltageLimits';
import PropTypes from 'prop-types';
import React from 'react';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import './OPFOptions.scss';

const { QSTS_OPF, HOSTING_CAPACITY, EV_CAPACITY } = ANALYSIS_TYPES;

const options = {
  dont_constrain_currents: {
    label: 'Ignore Current Constraints',
    type: 'bool',
    id: 'dont_constrain_currents',
    default: false,
  },
  dont_constrain_powers: {
    label: 'Ignore Power Constraints',
    type: 'bool',
    id: 'dont_constrain_powers',
    default: false,
  },
  dont_constrain_voltages: {
    label: 'Ignore Voltage Constraints',
    type: 'bool',
    id: 'dont_constrain_voltages',
    default: false,
  },
};

const OPFOptions = ({
  simulationOptions,
  setSimulationOptions,
  substationId,
  disableButton,
  selectedAnalysisType,
}) => (
  <div className="opf-options-section">
    {[QSTS_OPF, HOSTING_CAPACITY, EV_CAPACITY].includes(selectedAnalysisType) && (
      <>
        <h3>Configure Constraints</h3>
        <div>
          {Object.entries(options).map(([key, opts]) => (
            <div className="opf-options-item" id={`opf-options--${key}`} key={key}>
              <>
                <CustomCheckbox
                  htmlFor={opts.label}
                  id={key}
                  checked={simulationOptions[key]}
                  onClick={() => setSimulationOptions({
                    ...simulationOptions,
                    [key]: !simulationOptions[key],
                  })}
                />
                <p className="opf-options-item-label">
                  {' '}
                  {opts.label}
                  {' '}
                </p>
              </>
            </div>
          ))}
        </div>
      </>
    )}
    <VoltageLimits
      substationId={substationId}
      simulationOptions={simulationOptions}
      setSimulationOptions={setSimulationOptions}
      isAnalysis
      disableButton={disableButton}
    />
  </div>
);

OPFOptions.defaultProps = {
  substationId: undefined,
  disableButton: undefined,
};

OPFOptions.propTypes = {
  simulationOptions: PropTypes.object.isRequired,
  setSimulationOptions: PropTypes.func.isRequired,
  substationId: PropTypes.string,
  disableButton: PropTypes.func,
  selectedAnalysisType: PropTypes.string.isRequired,
};

export default OPFOptions;
