/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import Analytics from 'helpers/Analytics';
import Modal from 'components/Modal';
import IconButton from 'components/IconButton';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';
import { MODAL_TYPES } from 'helpers/EditMode';
import Request from 'helpers/Request';
import { ImportsContext } from 'routes/ImportCIM/modules/importsContext';

import './DropdownSections.scss';

class BranchSection extends Component {
  state = {
    createBranchOpen: false,
    branchName: '',
    invalidName: false,
  };

  getBranchOptions = (branches) => {
    const masterBranch = [{ label: 'As Built', value: 'master' }];

    if (branches.length > 1) {
      const sortedBranches = branches.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return sortedBranches.reduce((list, branch) => {
        if (branch.name !== 'master') {
          return list.concat({ label: branch.name, value: branch.name });
        }
        return list;
      }, masterBranch);
    }

    return masterBranch;
  };

  hasPendingImports = () => {
    const { imports: { pending }, initialized } = this.context;
    return pending.length || !initialized;
  }

  renderButton = (canCreate) => {
    if (this.props.newBranchReq === asyncActionStates.LOADING) {
      return <i className="material-icons rotate">refresh</i>;
    }
    return (
      <PermissionDisabledTip title="Create Network Version" hide={canCreate} theme={this.props.theme}>
        <IconButton
          id="add"
          icon="add"
          onClick={this.handleCreateBranchClick}
          theme={this.props.theme}
          className="add-btn"
          disabled={!canCreate}
          tooltip={canCreate ? 'Create Network Version' : undefined}
        />
      </PermissionDisabledTip>
    );
  };

  handleCreateBranchClick = () => {
    if (this.props.inEditMode) {
      this.props.saveModalOpen(MODAL_TYPES.SAVEAS);
    } else {
      this.setState(prevState => ({ createBranchOpen: !prevState.createBranchOpen }));
    }
  }

  downloadBranch = async () => {
    const { branch, workspace } = this.props;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/xml`);
    Analytics.logEvent('Download Network Version', 'Network Version');
    try {
      const { data } = await request.get();
      const blob = new Blob([data], { type: 'application/xml' });

      const userBranch = branch === 'master' ? 'as_built' : branch;
      FileSaver.saveAs(blob, `${workspace}_${userBranch}.xml`);
    } catch (err) {
      this.props.displayAlertMessage(
        'Download Failed',
        'There was a problem downloading your selected file. Please try again.',
        null,
        () => this.downloadBranch(),
      );
    }
  }

  /* Handle Events */
  handleNameChange = ({ target }) => {
    const name = target.value;
    const invalidName = name.length === 0 || name.match(/[^A-Za-z0-9-_]/g) !== null;
    const duplicateName = this.props.branches.map(br => br.name).includes(name);
    this.setState({
      branchName: name,
      invalidName: invalidName || duplicateName,
    });
  };

  handleBranchChange = ({ value }) => {
    if (this.props.inEditMode) {
      this.props.saveModalOpen(MODAL_TYPES.VERSION, value);
    } else if (value !== this.props.branch) {
      this.props.updateSelectedBranch(value);
    }
  };

  handleCloseModal = () => {
    this.setState({
      branchName: '',
      invalidName: false,
      createBranchOpen: false,
    });
  };

  handleBranchCreation = () => {
    this.props.createBranch(
      this.props.workspace,
      this.state.branchName,
      this.props.branch,
    );
    this.handleCloseModal();
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.handleBranchCreation();
    }
  };

  actionButtons = canDelete => ([
    {
      id: 'download',
      icon: 'get_app',
      key: 'Download',
      className: 'top-row-buttons',
      tooltip: !this.props.permissions.has('download_CIM_xml') || this.props.inEditMode ? null : 'Download',
      onClick: this.downloadBranch,
      theme: this.props.theme,
      disabled: !this.props.permissions.has('download_CIM_xml') || this.props.inEditMode,
      disabledMessage: this.props.inEditMode ? 'You cannot download in edit mode' : 'You do not have download permission',
    }, {
      id: 'delete',
      icon: 'delete',
      key: 'Delete',
      className: 'top-row-buttons',
      tooltip: !canDelete || this.props.inEditMode ? null : 'Delete',
      onClick: () => {
        this.props.deleteBranch(this.props.workspace, this.props.branch);
        Analytics.logEvent('Delete Network Version', 'Network Version');
      },
      theme: this.props.theme,
      disabled: !canDelete || this.props.inEditMode,
      disabledMessage: !canDelete ? 'You do not have delete permission' : 'You cannot delete in edit mode',
    },
  ]);

  render() {
    const canDelete = this.props.branch !== 'master' && this.props.permissions.has('delete_workspace_branch');

    const canCreate = this.props.permissions.has('create_workspace_branch');
    return (
      <div className="branch-scenario-section branch-section" id="branch-selector-container" data-test="branch-selector">
        <div className="select-container">
          <div className="select-top-row">
            <p className="select-label">{this.props.title}</p>
            <div className="buttons">
              {this.props.view !== 'results' && this.renderButton(canCreate)}
              {this.props.view !== 'results' && this.actionButtons(canDelete).map(button => (
                <PermissionDisabledTip
                  key={button.key}
                  title={button.key}
                  hide={!button.disabled}
                  theme={this.props.theme}
                  message={button.disabledMessage}
                >
                  <IconButton
                    {...button}
                  />
                </PermissionDisabledTip>
              ))}
            </div>
          </div>
          <Select
            theme={this.props.theme}
            options={this.getBranchOptions(this.props.branches)}
            value={this.props.branch}
            onChange={this.handleBranchChange}
            clearable={false}
            searchable={false}
            id="branch-selector"
            type={this.props.selectType}
          />
        </div>
        <Modal
          active={this.state.createBranchOpen}
          onCancel={this.handleCloseModal}
          onConfirm={this.handleBranchCreation}
          width="320px"
          title="Create New Network Version"
          theme={this.props.theme}
          disableConfirm={!this.state.branchName || this.state.invalidName}
        >
          <div className="create-modal">
            <label htmlFor="branch-name">Enter New Version Name:</label>
            <TextInput
              id="branch-name"
              value={this.state.branchName}
              onChange={this.handleNameChange}
              onKeyPress={this.handleKeyPress}
              required
              invalid={this.state.invalidName}
              validationMessage="Must be unique, use at least 1 character and can not include special
              characters or spaces."
              theme={this.props.theme}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

BranchSection.contextType = ImportsContext;

BranchSection.defaultProps = {
  branches: [],
  newBranchReq: 0,
  createBranch: null,
  title: 'Network Version',
  deleteBranch: null,
  saveModalOpen: null,
  displayAlertMessage: null,
  inEditMode: false,
  permissions: null,
  selectType: 'default',
};

BranchSection.propTypes = {
  branch: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  createBranch: PropTypes.func,
  updateSelectedBranch: PropTypes.func.isRequired,
  deleteBranch: PropTypes.func,
  theme: PropTypes.string.isRequired,
  newBranchReq: PropTypes.number,
  branches: PropTypes.array,
  permissions: PropTypes.object,
  displayAlertMessage: PropTypes.func,
  view: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool,
  saveModalOpen: PropTypes.func,
  title: PropTypes.string,
  selectType: PropTypes.string,
};

export default BranchSection;
