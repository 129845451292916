/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icons from '../Icons';

// Creates a expandable panel section with custom contents.
class ExpandableSection extends PureComponent {
  headerRef = React.createRef();

  state = { open: this.props.open };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  openSection = () => {
    this.setState(state => ({ open: !state.open }), () => this.props.toggleClick());
  };

  handleEnter = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      this.headerRef.current.click();
    }
  };

  render() {
    let cssClassName = `expandable-section ${this.props.className}`;

    if (this.props.disableAnimation) {
      cssClassName += ' disable-animation';
    }

    const { ExpandIcon, CollapseIcon } = Icons;

    return (
      <div className={cssClassName} style={{ color: this.props.color, ...this.props.style }}>
        <div
          role="button"
          className={`header ${this.props.headerClickable ? 'clickable' : ''}`}
          id={this.props.id || 'header'}
          ref={this.headerRef}
          disabled={this.props.disabled}
          onClick={this.props.headerClickable ? this.openSection : () => undefined}
          tabIndex={-1}
          onKeyUp={this.handleEnter}
        >
          <div className="header-content">
            {/*
            Note: Prop renderHeaderContent()` is a new additon.
            Since this component is used in code that is not aware of the new `renderHeaderContent`
            prop, we try to be backwards compatible with the old `sectionName` prop that was used
            to render some text in the header.

            Basic rule: If `props.sectionName` not an empty string (as that is the new default
            value) we render `sectionName` and do not bother with `renderHeaderContent()`.
            */}
            {this.props.sectionName !== ''
              ? this.props.sectionName
              : this.props.renderHeaderContent(
                { ...this.props, open: this.state.open },
                this.openSection,
              )}
          </div>
          {this.props.showToggle && (
            <div
              className="toggle-content"
              // Only clickable if the rest of the header is not
              onClick={!this.props.headerClickable ? this.openSection : () => undefined}
              role="button"
              tabIndex={-1}
              onKeyUp={this.handleEnter}
            >
              <div className="toggle" style={{ color: this.props.color }}>
                {!this.props.newExpandIcon && (
                  <i className="material-icons">
                    {this.state.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                  </i>
                )}
                {this.props.newExpandIcon && (
                  this.state.open ? <CollapseIcon /> : <ExpandIcon />
                )}
              </div>
            </div>
          )}
        </div>
        <div className={`content-container ${this.state.open ? 'content-container--active' : ''}`}>
          <div className={`content ${this.props.sectionScroll ? 'content--scroll' : ''}`}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

ExpandableSection.defaultProps = {
  color: 'inherit',
  open: false,
  renderHeaderContent: () => {},
  sectionName: '',
  showToggle: true,
  style: {},
  className: null,
  toggleClick: () => {},
  sectionScroll: false,
  disableAnimation: false,
  disabled: false,
  headerClickable: true,
  id: '',
  newExpandIcon: false,
};

ExpandableSection.propTypes = {
  disableAnimation: PropTypes.bool,
  headerClickable: PropTypes.bool,
  color: PropTypes.string,
  open: PropTypes.bool,
  toggleClick: PropTypes.func,
  renderHeaderContent: PropTypes.func,
  showToggle: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  sectionName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  sectionScroll: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  newExpandIcon: PropTypes.bool,
};

export default ExpandableSection;
