/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint jsx-a11y/no-static-element-interactions: off */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button';
import './Modal.scss';

/**
 * Stateless component that create a modal overlay and a modal body.
 * Status is controlled by container component.
 * Requires 2 callback function:
 *   - onConfirm - For clicks on the confirm button
 *   - onCancel - For clicks on the cancel button or overlay
 * Header and footer can be removed using the props showHeader and showFooter
 * Accepts any valid JSX as children in the modal body
 */

const Modal = (props) => {
  const cancelBtn = (
    <Button
      className="modal-footer__btn modal-footer__btn--cancel"
      onClick={props.onCancel}
      label={props.labels.cancel || 'Cancel'}
      disabled={props.disableCancel}
      key="cancel-btn"
      theme={props.theme}
      type="secondary"
      id="cancel-btn"
    />
  );

  const confirmBtn = (
    <Button
      onClick={props.onConfirm}
      className="modal-footer__btn modal-footer__btn--confirm"
      label={props.labels.confirm || 'Confirm'}
      disabled={props.disableConfirm}
      key="confirm-btn"
      theme={props.theme}
      type="primary"
      id="confirm-btn"
    />
  );

  const getFooterButtons = () => {
    if (!props.showCancel) {
      return confirmBtn;
    }
    return props.reverseFooterButtons
      ? [confirmBtn, cancelBtn]
      : [cancelBtn, confirmBtn];
  };

  return (
    <div className={`modal ${props.theme} ${props.className}`}>
      <div
        className={classNames({
          'modal-overlay': true,
          'modal-overlay--active': props.active,
        })}
        onClick={props.onCancel}
      />
      <div className="modal-body-wrapper">
        <div
          style={{ width: props.width, height: props.height }}
          className={`modal-body modal-body--${props.active && 'active'}`}
        >

          {props.showHeader
            && <div className="modal-header"><p>{props.title}</p></div>}

          {props.active && (
            <div className={`modal-message modal-message ${props.scrollBody ? 'scroll-body' : ''}`}>{props.children}</div>
          )}

          {props.showFooter
            && (
              <div
                className={classNames({
                  'modal-footer': true,
                  'modal-footer--centered': !props.showCancel,
                })}
              >
                {getFooterButtons()}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  children: null,
  onConfirm: null,
  height: 'auto',
  labels: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  showHeader: true,
  showFooter: true,
  theme: 'dark',
  title: '',
  width: 'auto',
  disableConfirm: false,
  disableCancel: false,
  onCancel: null,
  active: false,
  showCancel: true,
  reverseFooterButtons: false,
  scrollBody: false,
  className: '',
};

Modal.propTypes = {
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  active: PropTypes.bool,
  labels: PropTypes.shape({
    cancel: PropTypes.node,
    confirm: PropTypes.node,
  }),
  height: PropTypes.string,
  width: PropTypes.string,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
  disableConfirm: PropTypes.bool,
  disableCancel: PropTypes.bool,
  showCancel: PropTypes.bool,
  reverseFooterButtons: PropTypes.bool,
  scrollBody: PropTypes.bool,
  className: PropTypes.string,
};

export default Modal;
