import { CompositeLayer } from 'deck.gl';
import violationIcons from './violation-icons.png';
import { isShuntDevice } from './helpers';
import GridOSIconLayer from './GridOSIconLayer';
import Supercluster from './supercluster'; // Note : Importing our own edited version of the library

// Uses the same icon map to achieve both the violating node / clusters,
// shunt device badges and link device badges
const ICON_MAPPING = {
  0: {
    x: 0, y: 0, width: 1, height: 1,
  },
  1: {
    x: 0, y: 0, width: 200, height: 170,
  },
  2: {
    x: 200, y: 0, width: 200, height: 170,
  },
  3: {
    x: 400, y: 0, width: 200, height: 170,
  },
  4: {
    x: 600, y: 0, width: 200, height: 170,
  },
  5: {
    x: 800, y: 0, width: 200, height: 170,
  },
  6: {
    x: 0, y: 180, width: 200, height: 170,
  },
  7: {
    x: 200, y: 180, width: 200, height: 170,
  },
  8: {
    x: 400, y: 180, width: 200, height: 170,
  },
  9: {
    x: 600, y: 180, width: 200, height: 170,
  },
  10: {
    x: 800, y: 180, width: 200, height: 170,
  },
  20: {
    x: 0, y: 360, width: 200, height: 170,
  },
  30: {
    x: 200, y: 360, width: 200, height: 170,
  },
  40: {
    x: 400, y: 360, width: 200, height: 170,
  },
  50: {
    x: 600, y: 360, width: 200, height: 170,
  },
  60: {
    x: 800, y: 360, width: 200, height: 170,
  },
  70: {
    x: 0, y: 540, width: 200, height: 170,
  },
  80: {
    x: 200, y: 540, width: 200, height: 170,
  },
  90: {
    x: 400, y: 540, width: 200, height: 170,
  },
  100: {
    x: 600, y: 540, width: 200, height: 170,
  },
  500: {
    x: 800, y: 540, width: 200, height: 170,
  },
  1000: {
    x: 0, y: 720, width: 200, height: 170,
  },
  5000: {
    x: 200, y: 720, width: 200, height: 170,
  },
  10000: {
    x: 400, y: 720, width: 200, height: 170,
  },
  50000: {
    x: 600, y: 720, width: 200, height: 170,
  },
  100000: {
    x: 800, y: 720, width: 200, height: 170,
  },
  '1_BADGE': {
    x: 0, y: 0, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '2_BADGE': {
    x: 200, y: 0, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '3_BADGE': {
    x: 400, y: 0, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '4_BADGE': {
    x: 600, y: 0, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '5_BADGE': {
    x: 800, y: 0, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '6_BADGE': {
    x: 0, y: 180, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '7_BADGE': {
    x: 200, y: 180, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '8_BADGE': {
    x: 400, y: 180, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '9_BADGE': {
    x: 600, y: 180, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '10_BADGE': {
    x: 800, y: 180, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '20_BADGE': {
    x: 0, y: 360, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '30_BADGE': {
    x: 200, y: 360, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '40_BADGE': {
    x: 400, y: 360, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '50_BADGE': {
    x: 600, y: 360, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '60_BADGE': {
    x: 800, y: 360, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '70_BADGE': {
    x: 0, y: 540, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '80_BADGE': {
    x: 200, y: 540, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '90_BADGE': {
    x: 400, y: 540, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '100_BADGE': {
    x: 600, y: 540, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '500_BADGE': {
    x: 800, y: 540, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '1000_BADGE': {
    x: 0, y: 720, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '5000_BADGE': {
    x: 200, y: 720, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '10000_BADGE': {
    x: 400, y: 720, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '50000_BADGE': {
    x: 600, y: 720, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '100000_BADGE': {
    x: 800, y: 720, width: 200, height: 170, anchorX: 0, anchorY: 200,
  },
  '1_BADGE_LINK': {
    x: 0, y: 0, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '2_BADGE_LINK': {
    x: 200, y: 0, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '3_BADGE_LINK': {
    x: 400, y: 0, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '4_BADGE_LINK': {
    x: 600, y: 0, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '5_BADGE_LINK': {
    x: 800, y: 0, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '6_BADGE_LINK': {
    x: 0, y: 180, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '7_BADGE_LINK': {
    x: 200, y: 180, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '8_BADGE_LINK': {
    x: 400, y: 180, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '9_BADGE_LINK': {
    x: 600, y: 180, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '10_BADGE_LINK': {
    x: 800, y: 180, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '20_BADGE_LINK': {
    x: 0, y: 360, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '30_BADGE_LINK': {
    x: 200, y: 360, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '40_BADGE_LINK': {
    x: 400, y: 360, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '50_BADGE_LINK': {
    x: 600, y: 360, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '60_BADGE_LINK': {
    x: 800, y: 360, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '70_BADGE_LINK': {
    x: 0, y: 540, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '80_BADGE_LINK': {
    x: 200, y: 540, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '90_BADGE_LINK': {
    x: 400, y: 540, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '100_BADGE_LINK': {
    x: 600, y: 540, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '500_BADGE_LINK': {
    x: 800, y: 540, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '1000_BADGE_LINK': {
    x: 0, y: 720, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '5000_BADGE_LINK': {
    x: 200, y: 720, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '10000_BADGE_LINK': {
    x: 400, y: 720, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '50000_BADGE_LINK': {
    x: 600, y: 720, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
  '100000_BADGE_LINK': {
    x: 800, y: 720, width: 200, height: 170, anchorX: -10, anchorY: 300,
  },
};

const getClusterFeatures = (map, cluster, zoom) => {
  const { _sw, _ne } = map.getMap().getBounds();

  return cluster.getClusters([_sw.lng, _sw.lat, _ne.lng, _ne.lat], Math.floor(zoom));
};

class ViolationsLayer extends CompositeLayer {
  initializeState() {
    this.state.clusterFeatures = [];
    this.state.cluster = {};
  }

  shouldUpdateState({ props, oldProps, changeFlags }) {
    // Make sure we update the cluster in state when the violations change;
    if (props.violations !== oldProps.violations
        || props.assetTypeVisibility !== oldProps.assetTypeVisibility) {
      return true;
    }

    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps }) {
    if (
      props.violations !== oldProps.violations
      || props.data !== oldProps.data
    ) {
      // Get the violation asset ids
      const withViolations = [];
      Object.entries(props.violations).forEach(([id, total]) => {
        if (total > 0) {
          withViolations.push(id);
        }
      });

      // Find the violating assets out of the currently visible assets
      const assets = props.data.filter(d => withViolations.includes(d.properties.id));

      // Create cluster and add assets to the cluster
      const cluster = new Supercluster({
        minZoom: 0,
        maxZoom: 25,
        radius: 60,
      });

      cluster.load(assets);
      this.state.cluster = cluster;

      if (props.map && props.map.getMap()) {
        // Get the clusters/features in the current viewport
        this.state.clusterFeatures = getClusterFeatures(props.map, cluster, props.zoom);
      }
    } else if (this.state.cluster && props.map && props.map.getMap()) {
      // Get the clusters/features in the current viewport
      this.state.clusterFeatures = getClusterFeatures(props.map, this.state.cluster, props.zoom);
    }
  }

  renderLayers() {
    return [
      new GridOSIconLayer(this.getSubLayerProps({
        id: 'violations-layer',
        data: this.state.clusterFeatures || [],
        iconAtlas: violationIcons,
        iconMapping: ICON_MAPPING,
        billboard: true,
        sizeScale: 1.2,
        getElevation: 1,
        updateTriggers: {
          getSize: [this.props.zoom],
          getAngle: [this.props.bearing, this.props.data],
          getPosition: [this.props.data],
          getIcon: [this.props.data, this.props.violations],
        },
        getIcon: (d) => {
          let total;
          if (d.properties.cluster) {
            let children;
            try {
              // Get all points in the cluster, not just the first 10 (library default)
              children = this.state.cluster.getLeaves(
                d.properties.cluster_id, Number.POSITIVE_INFINITY,
              );
            } catch (err) {
              children = [];
            }

            // Get the total violations over all of the assets in the cluster
            const assets = children.map(feature => feature.properties.id);
            total = assets.reduce((totalV, asset) => {
              if (this.props.violations[asset]) {
                totalV += this.props.violations[asset];
              }
              return totalV;
            }, 0);
          } else {
            total = this.props.violations[d.properties.id] || 0;
          }

          const shuntDevice = isShuntDevice(d.properties.asset_type);
          const isNode = d.properties.asset_type === 'ConnectivityNode';
          const isLine = d.properties.asset_type === 'ACLineSegment';
          let type = '';
          if (!d.properties.cluster && shuntDevice) {
            type = '_BADGE';
          } else if (!d.properties.cluster && !shuntDevice && !isNode && !isLine) {
            type = '_BADGE_LINK';
          }

          const breaks = [
            100000, 50000, 10000, 5000, 1000, 500, 100, 90, 80, 70,
            60, 50, 40, 30, 20, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
          ];

          for (let i = 0; i <= breaks.length; i += 1) {
            if (total >= breaks[i]) {
              return `${breaks[i]}${type}`;
            }
          }

          return '0';
        },
        getAngle: this.props.getAngle,
        getPosition: d => d.geometry.coordinates,
        getSize: (d) => {
          if (d.properties.cluster || d.properties.asset_type === 'ConnectivityNode') return 45;
          return 30;
        },
        onClick: this.props.onClick,
      })),
    ];
  }
}

ViolationsLayer.layerName = 'ViolationsLayer';

export default ViolationsLayer;
