import AssetInfo from './AssetInfo';

class WireInfo extends AssetInfo {
  get coreRadius() {
    return this.attributes['WireInfo.coreRadius'];
  }

  get coreStrandCount() {
    return this.attributes['WireInfo.coreStrandCount'];
  }

  get gmr() {
    return this.attributes['WireInfo.gmr'];
  }

  get insulated() {
    return this.attributes['WireInfo.insulated'];
  }

  get insulationMaterial() {
    return this.attributes['WireInfo.insulationMaterial'];
  }

  get insulationThickness() {
    return this.attributes['WireInfo.insulationThickness'];
  }

  get material() {
    return this.attributes['WireInfo.material'];
  }

  get rAC25() {
    return this.attributes['WireInfo.rAC25'];
  }

  get radius() {
    return this.attributes['WireInfo.radius'];
  }

  get ratedCurrent() {
    return this.attributes['WireInfo.ratedCurrent'];
  }

  get sizeDescription() {
    return this.attributes['WireInfo.sizeDescription'];
  }

  get strandCount() {
    return this.attributes['WireInfo.strandCount'];
  }
}

export default WireInfo;
