/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react';
import { IntlContext } from 'contexts/IntlContext';
import PropTypes from 'prop-types';
import './CostTable.scss';

const DERProgramCostTable = ({
  costData,
}) => {
  const { currencyFormatter, currencySymbol } = useContext(IntlContext);

  let rangeData = {};
  if (costData.maxRange.datapoints.totals && costData.selectedRange.datapoints.totals) {
    rangeData = ['maxRange', 'selectedRange'].reduce((obj, range) => {
      obj[range] = Object.keys(costData[range].datapoints.totals).reduce((data, cost) => {
        let header = cost.split('_')[0].charAt(0).toUpperCase() + cost.split('_')[0].slice(1);
        if (header === 'Utilization') header = 'Total';
        const value = costData[range].datapoints.totals[cost] === undefined
          || costData[range].datapoints.totals[cost] === null
          ? `${currencySymbol} -`
          : currencyFormatter.format(costData[range].datapoints.totals[cost]);
        data[header] = value;
        return data;
      }, {});
      return obj;
    }, {});
  }
  const rows = [
    { header: 'Administration', bold: false },
    { header: 'Availability', bold: false },
    { separator: true },
    { header: 'Utilisation', bold: true },
    { header: 'Power', bold: false },
    { header: 'Use', bold: false },
    { header: 'Utilisation Total', dataKey: 'Total', bold: true },
  ];
  const makeRow = (costRow, idx) => {
    if (costRow.separator) {
      return (
        <tr key={`${idx}-row`} className="divider">
          <td colSpan="3" />
        </tr>
      );
    }
    return (
      <tr key={`${idx}-row`}>
        <td className={`header-cell ${costRow.bold && 'bold'}`}>{costRow.header}</td>
        <td className="data-cell">{rangeData.selectedRange?.[costRow.dataKey ?? costRow.header]}</td>
        <td className="data-cell">{rangeData.maxRange?.[costRow.dataKey ?? costRow.header]}</td>
      </tr>
    );
  };
  return (
    <>
      <div className="cost-header-row">
        <h4>DER Program Costs</h4>
      </div>
      <table className="cost-table">
        <thead>
          <tr>
            <th />
            <th width="50%">Selected Range</th>
            <th width="50%">Full Range</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((cost, idx) => makeRow(cost, idx))}
        </tbody>
      </table>

    </>
  );
};

DERProgramCostTable.propTypes = {
  costData: PropTypes.shape({
    maxRange: PropTypes.shape({
      datapoints: PropTypes.object.isRequired,
      error: PropTypes.string.isRequired,
    }),
    selectedRange: PropTypes.shape({
      datapoints: PropTypes.object.isRequired,
      error: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default DERProgramCostTable;
