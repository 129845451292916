import React from 'react';
import { kWh, kWDC } from 'helpers/units';
import { filterObject } from 'helpers/utils';
import PowerElectronicsUnit from './PowerElectronicsUnit';

// The single editable values to be displayed at the top of the panel
const editableRatings = [
  {
    precision: 1, id: 'rated_energy', label: 'Rated Energy', unit: kWh, divisor: 1000, type: 'number', infoId: 'ratedEnergy',
  },
  {
    precision: 1, id: 'max_energy', label: 'Max Charge Level', unit: kWh, divisor: 1000, type: 'number', infoId: 'maxEnergy',
  },
  {
    precision: 1, id: 'min_energy', label: 'Min Charge Level', unit: kWh, divisor: 1000, type: 'number', infoId: 'minEnergy',
  },
  {
    id: 'maxP', label: 'Max Rate of Charge', unit: kWDC, divisor: 1000, type: 'number', precision: 1,
  },
  {
    id: 'minP', label: 'Max Rate of Discharge', unit: kWDC, divisor: -1000, type: 'number', precision: 1,
  },
];

const BatteryUnit = (props) => (
  <PowerElectronicsUnit
    {...props}
    type="bess_unit"
    fields={editableRatings}
    name="Battery Unit"
  />
);

BatteryUnit.defaultProps = filterObject(PowerElectronicsUnit.defaultProps, ['type', 'fields', 'name']);
BatteryUnit.propTypes = filterObject(PowerElectronicsUnit.propTypes, ['type', 'fields', 'name']);

export default BatteryUnit;
