import ShuntDevice from './ShuntDevice';

class LinearShuntCompensator extends ShuntDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'LinearShuntCompensator';
    this.iconTypes = ['shunt_capacitor', 'shunt_reactor'];
  }

  get visibilityClass() {
    return this.isCapacitor ? 'ShuntCapacitor' : 'ShuntReactor';
  }

  get displayName() {
    return this.isCapacitor ? 'Shunt Capacitor' : 'Shunt Reactor';
  }

  get icon() {
    return this.isCapacitor ? 'shunt_capacitor' : 'shunt_reactor';
  }

  get isBalanced() {
    return this._phases.length === 0;
  }

  // Get the bPerSection for each phase
  get bPerSectionBalanced() {
    return this.attributes['LinearShuntCompensator.bPerSection'] || ' ';
  }

  // Determine if this LinearShuntCompensator is a capacitor or reactor
  get isCapacitor() {
    if (this.isBalanced) {
      // Since the bPerSection is a charging susceptance, positive values indicate capacitors
      return this.bPerSectionBalanced && this.bPerSectionBalanced >= 0;
    }

    // Now we need to check the LinearShuntCompensatorPhase objects
    return this._phases.every((shuntCompensatorPhase) => {
      const susceptance = shuntCompensatorPhase.attributes['LinearShuntCompensatorPhase.bPerSection'] || 0;
      return susceptance >= 0;
    });
  }

  validationSchema = {
    upperVoltageThreshold: {
      customValidation: (value, { controlMode, lowerVoltageThreshold }) => {
        const messages = [];
        const valDefined = value !== '' || (value === '' && controlMode !== 'locallyControlled');
        const GTlower = value > lowerVoltageThreshold;

        if (!valDefined) {
          messages.push('Upper voltage threshold is required.');
        }

        if (!GTlower) {
          messages.push('Value must be > Lower Voltage Threshold.');
        }

        const valid = valDefined && GTlower;
        return {
          valid,
          message: !valid ? messages.join(' ') : null,
        };
      },
    },
    lowerVoltageThreshold: {
      customValidation: (value, { controlMode, upperVoltageThreshold }) => {
        const messages = [];
        const valDefined = value !== '' || (value === '' && controlMode !== 'locallyControlled');
        const LTupper = value < upperVoltageThreshold;

        if (!valDefined) {
          messages.push('Lower Voltage Threshold is required.');
        }

        if (!LTupper) {
          messages.push('Value must be < Upper Voltage Threshold.');
        }

        const valid = valDefined && LTupper;

        return {
          valid,
          message: !valid ? messages.join(' ') : null,
        };
      },
    },
    nominalVoltage: { min: 1, required: true },
  }

  get _phases() {
    return this.extractReferenceList(this.id, 'ShuntCompensator.ShuntCompensatorPhase');
  }
}

export default LinearShuntCompensator;
