import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Select from 'components/Select';
import RadioButtonGroup from 'components/RadioButtonGroup';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';
import './HostingCapacityMenu.scss';

const OptimalBatteryMenu = ({
  selectedAsset, title, onSubmit, onPreviousClick, nodes, permissions,
}) => {
  const isNode = selectedAsset?.class === 'ConnectivityNode';
  const [selectedNodes, setSelectedNodes] = useState(
    isNode ? [{ value: selectedAsset.id, label: selectedAsset.name }] : [],
  );
  const [nodeSelector, setNodeSelector] = useState('pick');
  const theme = useContext(ThemeContext);

  const handleSubmit = () => onSubmit(
    selectedNodes.map(node => ({ id: node.value, name: node.label })),
  );

  const getNodeOptions = () => (
    alphabetizeByKey(
      Object.values(nodes)
        .map(node => ({ value: node.id, label: node.name })),
      'label',
    )
  );

  const handleNodeSelectorChange = ({ target: { value } }) => {
    if (value === nodeSelector) {
      return;
    }

    if (value === 'all') {
      setSelectedNodes(getNodeOptions());
    } else {
      setSelectedNodes([]);
    }

    setNodeSelector(value);
  };

  const canRunMultinode = permissions.has('run_multinode_battery_sizing') || permissions.has('run_multinode_battery_sizing_day');

  return (
    <div className="battery-sizing-config">
      <p className="title-text">{title}</p>
      <div className="menu-body">
        <div className="menu-row">
          <p className="label">Select the nodes to evaluate</p>
          <RadioButtonGroup
            id="analysis-type"
            options={[
              { id: 'all', label: 'All Nodes', disabled: !canRunMultinode },
              { id: 'pick', label: 'Specific Nodes' },
            ]}
            theme={theme}
            listType="column"
            value={nodeSelector}
            onChange={handleNodeSelectorChange}
          />
        </div>
        <div className="menu-row selected-node-row">
          <Select
            options={nodeSelector === 'all' ? [] : getNodeOptions()}
            value={nodeSelector === 'all' ? [] : selectedNodes}
            clearable
            searchable
            onChange={newNodes => {
              if (canRunMultinode && newNodes) {
                setSelectedNodes(newNodes);
              } else if (newNodes) {
                setSelectedNodes([newNodes]);
              } else {
                setSelectedNodes([]);
              }
            }}
            isMulti={canRunMultinode}
            theme={theme}
          />
        </div>
      </div>
      <div className="menu-footer">
        <Button
          className="back-btn"
          id="back-btn"
          type="secondary"
          theme={theme}
          onClick={onPreviousClick}
          label="Previous"
        />

        <Button
          label="Run Analysis"
          className="apply-settings-btn"
          onClick={handleSubmit}
          disabled={selectedNodes.length === 0}
          theme={theme}
          type="primary"
        />
      </div>
    </div>
  );
};

OptimalBatteryMenu.defaultProps = {
  title: 'Optimal Battery Sizing',
  selectedAsset: null,
  nodes: {},
};

OptimalBatteryMenu.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  selectedAsset: PropTypes.object,
  nodes: PropTypes.object,
  permissions: PropTypes.object.isRequired,
};

export default OptimalBatteryMenu;
