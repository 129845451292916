/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react';
import { IntlContext } from 'contexts/IntlContext';
import PropTypes from 'prop-types';
import moment from 'moment';
import './CostTable.scss';

const costReducer = (sum, current) => ({
  community_generation_credit: (sum.community_generation_credit + current.community_generation_credit),
});

const CommGenCostTable = ({
  highlightRange,
  commGenCostData,
}) => {
  const { currencyFormatter } = useContext(IntlContext);

  const selectedCostData = commGenCostData.datapoints
    .filter(x => moment(x.timepoint).isBetween(
      highlightRange.start, highlightRange.end, undefined, [],
    ))
    .reduce(costReducer, {
      community_generation_credit: 0,
    });
  const totalCostData = commGenCostData.datapoints.reduce(costReducer, {
    community_generation_credit: 0,
  });

  return (
    <>
      <div className="cost-header-row">
        <h4>Community Generation Cost</h4>
      </div>
      <table className="cost-table">
        <thead>
          <tr>
            <th width="50%">Selected Range</th>
            <th width="50%">Full Range</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="data-cell">{currencyFormatter.format(selectedCostData.community_generation_credit)}</td>
            <td className="data-cell">{currencyFormatter.format(totalCostData.community_generation_credit)}</td>
          </tr>
        </tbody>
      </table>

    </>
  );
};

CommGenCostTable.propTypes = {
  highlightRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  commGenCostData: PropTypes.shape({
    datapoints: PropTypes.array.isRequired,
  }).isRequired,
};

export default CommGenCostTable;
