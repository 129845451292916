import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useImportsContext } from 'routes/ImportCIM/modules/importsContext';
import IconButton from '../IconButton';
import ExpandableSection from '../ExpandableSection';
import Tooltip from '../Tooltip';
import PermissionDisabledTip from '../PermissionDisabledTip';

import './FileUploads.scss';

const JobDisplay = ({
  importJob, theme, deleteImport, permissions,
}) => {
  const names = importJob.file_names.map(name => (
    <Tooltip
      content={(
        <p
          style={{
            wordBreak: 'break-word',
          }}
        >
          {name}
        </p>
      )}
      key={name}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: '223px',
          fontWeight: 'bold',
        }}
      >
        {name}
      </div>
    </Tooltip>
  ));
  const now = moment(new Date()); // todays date
  const duration = moment.duration(moment(importJob.created_time).diff(now));
  const uploaded = duration.humanize(true);
  const canDelete = permissions.has('import_network');

  return (
    <div className="job" key={importJob.uploaded}>
      <div className="files-delete">
        <div>{names}</div>
        <div>
          <PermissionDisabledTip
            title="Delete"
            hide={canDelete}
            placement="top"
            theme={theme}
          >
            <IconButton
              className="delete"
              icon="delete"
              onClick={() => deleteImport(importJob.id)}
              tooltip={canDelete ? 'Delete' : null}
              theme={theme}
              id={importJob.id}
              disabled={!canDelete}
            />
          </PermissionDisabledTip>
        </div>
      </div>
      <div className="type-date-status">
        Type:
        {importJob.file_type}
      </div>
      <div className="type-date-status">
        Uploaded:
        {uploaded}
      </div>
      <div className="type-date-status">
        Status:
        {importJob.status}
      </div>
    </div>
  );
};

const FileUploads = ({ theme, permissions }) => {
  const { imports: { pending, done }, deleteImport } = useImportsContext();
  const imports = [...pending, ...done];

  return imports.length > 0 ? (
    <ExpandableSection
      sectionName="Network Imports"
      className="container-section lone-feeders"
      open
      id="network-imports"
    >
      {imports.map(importJob => (
        <JobDisplay
          importJob={importJob}
          theme={theme}
          deleteImport={deleteImport}
          key={importJob.created_time}
          permissions={permissions}
        />
      ))}
    </ExpandableSection>
  ) : null;
};

JobDisplay.defaultProps = {
  theme: 'dark',
  importJob: undefined,
};
FileUploads.defaultProps = {
  theme: 'dark',
};

JobDisplay.propTypes = {
  theme: PropTypes.string,
  importJob: PropTypes.object,
  deleteImport: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
};
FileUploads.propTypes = {
  theme: PropTypes.string,
  permissions: PropTypes.object.isRequired,
};

export default FileUploads;
