import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import CustomCheckbox from 'components/CustomCheckbox';
import ExpandableSection from 'components/ExpandableSection';
import Input from 'components/Input';
import asyncStates from 'helpers/asyncActionStates';
import './Allocation.scss';

const PFMin = -1;
const PFMax = 1;

class Allocation extends Component {
  state = {
    submitted: false,
    failed: false,
  };

  UNSAFE_componentWillMount() {
    this.props.actions.setDefaults();
    this.props.actions.getAllocationDefaults(this.props.workspace, this.props.feeder.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { SUCCESS, LOADING, ERROR } = asyncStates;
    const { uploadStatus: newStatus } = nextProps.allocation;
    const { uploadStatus: oldStatus } = this.props.allocation;
    if (newStatus === SUCCESS && oldStatus === LOADING) {
      this.setState({ submitted: true });
    } else if (newStatus === ERROR && oldStatus === LOADING) {
      this.setState({ failed: true });
    }

    if (this.props.branch !== nextProps.branch) {
      this.props.actions.getAllocationDefaults(nextProps.workspace, nextProps.feeder.id);
    }
  }

  componentWillUnmount() {
    // Clear input values stored in state when navigating away
    this.props.actions.resetAllocationValues();
  }

  /* Helpers for form validation */
  isNumber = value => typeof value === 'number'

  isLoading = () => this.props.allocation.uploadStatus === asyncStates.LOADING;

  hasPhaseValues = type => this.isNumber(this.props.allocation[type].A)
    && this.isNumber(this.props.allocation[type].B) && this.isNumber(this.props.allocation[type].C);

  hasNetValues = type => this.isNumber(this.props.allocation[type].net);

  hasDefinedValues = type => (
    type === 'net'
      ? this.hasNetValues('bulk') && this.hasNetValues('DER') && this.hasNetValues('loadPF') && this.hasNetValues('pvPF')
      : this.hasPhaseValues('bulk') && this.hasPhaseValues('DER') && this.hasPhaseValues('loadPF') && this.hasPhaseValues('pvPF')
  )

  isInvalidPowerFactor = value => !value || value === 0 || value < PFMin || value > PFMax;

  hasInvalidPowerFactor = (type) => {
    const { loadPF, pvPF } = this.props.allocation;
    const { isInvalidPowerFactor: invalidPF } = this;
    return type === 'net'
      ? invalidPF(loadPF.net) || invalidPF(pvPF.net)
      : invalidPF(loadPF.A) || invalidPF(loadPF.B) || invalidPF(loadPF.C)
      || invalidPF(pvPF.A) || invalidPF(pvPF.B) || invalidPF(pvPF.C);
  }

  submitDisabled = (editable) => {
    const valuesDefined = this.hasDefinedValues(this.props.allocation.valueType);
    const invalidPF = this.hasInvalidPowerFactor(this.props.allocation.valueType);
    const uploading = this.props.allocation.uploadStatus === asyncStates.LOADING;
    return !valuesDefined || uploading || invalidPF || !editable;
  }

  /* Event Handlers */

  /**
   * Change the selected data type when checkboxes are clicked.
   * @param  {String} type Currently selected value type
   */
  handleTypeSelection = type => this.props.actions.setSelectedValueType(type);

  /**
   * Update the per phase and total values when users change input values
   * @param  {String} row   Key for the updated row
   * @param  {String} col   Key for the updated column
   * @param  {String} value Value extracted from user input
   */
  updatePowerValue = (row, col, value) => {
    const valueType = col === 'net' ? 'net' : 'per-phase';
    this.props.actions.updatePerPhaseValue(row, col, value, valueType);
    this.setState({
      submitted: false,
      failed: false,
    });
  }

  // Submit the allocation details when users click the save button
  handleSubmit = () => {
    this.setState({ failed: false, submitted: false });
    const { workspace } = this.props;
    const {
      valueType, bulk, DER, pvPF, loadPF,
    } = this.props.allocation;
    const values = {
      bulk, DER, pvPF, loadPF,
    };
    this.props.actions.submitAllocationChange(workspace, this.props.feeder, valueType, values);
  }

  /* Markup Creation Heleprs */

  /**
   * Create a column in the per phase values grid
   * @param  {String}  heading    The row label
   * @param  {Array}   row        Rows that this column belongs to
   * @param  {Boolean} editable   If this column should be editable (has permission to edit)
   * @param  {Boolean} noValue    If this column should have no value (another value type selected)
   * @return {JSX}                Input that represents a column cell in a grid
   */
  generateColumn = (heading, row, editable, noValue, min, max) => {
    let invalid = false;

    // Check powerfactor values and highlight if invalid
    if (row === 'loadPF' || row === 'pvPF') {
      const value = this.props.allocation[row][heading];
      invalid = this.isInvalidPowerFactor(value);
    }

    return (
      <div className="allocation-cell">
        <Input
          value={noValue ? '' : this.props.allocation[row][heading]}
          disabled={!(editable && !noValue && this.props.inEditMode)}
          id={row}
          onChange={(val, id, phase) => this.updatePowerValue(id, phase, val, min, max)}
          type="number"
          inputStyle="panel"
          theme={this.props.theme}
          validation={{
            invalid,
          }}
          options={{
            step: '0.01',
            precision: 2,
          }}
          customAttributes={{
            phase: heading,
          }}
        />
      </div>
    );
  }

  /**
   * Create a row of inputs for a load / generation allocation variable
   * @param  {Array}  row   Row being generated
   * @param  {String} type  The currently selected value type
   * @return {JSX}          Row of inputs
   */
  generateGridRow = (row, type, editable, min, max) => (
    <div className="allocation-cell-row" key={`row-${row.label}`}>
      <div className="allocation-cell allocation-cell-label">
        <h3 className="allocation-row-label">{row.label}</h3>
      </div>
      {this.generateColumn('net', row.key, editable, type === 'per-phase', min, max)}
      {this.generateColumn('A', row.key, editable, type === 'net', min, max)}
      {this.generateColumn('B', row.key, editable, type === 'net', min, max)}
      {this.generateColumn('C', row.key, editable, type === 'net', min, max)}
    </div>
  );

  generateGridHeaders = (cols, type) => {
    const netActive = type === 'net';
    return (
      <div className="allocation-cell-row">
        <div className="allocation-cell allocation-cell-label" />
        { cols.map((col) => {
          const isNet = col === 'ABC';
          const isActive = (netActive && isNet) || (!netActive && !isNet);
          return (
            <div className="allocation-cell allocation-cell-header" key={`header-${col}`}>
              <h3 className={`allocation-cell__h3 allocation-cell__h3--${isActive && 'active'}`}>{col}</h3>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { valueType } = this.props.allocation;
    const { submitted, failed } = this.state;
    const invalidPF = this.hasInvalidPowerFactor(this.props.allocation.valueType);
    const editable = this.props.permissions.has('modify_network')
      && !(this.props.branch === 'master' && !this.props.permissions.has('modify_network_as_built'));

    return (
      <div className="load-generation">
        <ExpandableSection sectionName="Load & Generation Allocation" id="load-gen-allocation">
          <div className="allocation-contents">
            <div className="allocation-settings">
              <div className="power-type-check-container">
                <h2 className="section-header">Aggregated Power</h2>
              </div>
              { this.generateGridHeaders(['ABC', 'A', 'B', 'C'], valueType) }
              {[
                { key: 'bulk', label: 'P_bulk (kW)' },
                { key: 'DER', label: 'P_DER (kW)' },
              ].map(row => this.generateGridRow(row, valueType, editable))}
            </div>
            <div className="allocation-settings">
              <h2 className="section-header">Power Factor Settings</h2>
              { this.generateGridHeaders(['ABC', 'A', 'B', 'C'], valueType) }
              { [
                { key: 'loadPF', label: 'Load' },
                { key: 'pvPF', label: 'DER' },
              ].map(row => this.generateGridRow(row, valueType, editable, PFMin, PFMax))}
            </div>
            {invalidPF
              && (
              <p className="allocation-cell-message">
                Power Factor must be between
                {PFMin}
                {' '}
                and
                {PFMax}
                {' '}
                and not be 0.
              </p>
              )}
            <div className="allocation-submit">
              <div style={{ marginRight: 20 }}>
                <button className="power-type-checkbox" onClick={() => this.handleTypeSelection('net')} type="button">
                  <CustomCheckbox
                    id="net"
                    checked={valueType === 'net'}
                    focusable={false}
                  />
                  <p className="power-type-label">ABC</p>
                </button>
                <button className="power-type-checkbox" onClick={() => this.handleTypeSelection('per-phase')} type="button">
                  <CustomCheckbox
                    id="per-phase"
                    checked={valueType === 'per-phase'}
                    focusable={false}
                  />
                  <p className="power-type-label">A, B, C</p>
                </button>
              </div>
              {this.props.inEditMode && (
                <Button
                  label={this.isLoading() ? 'Saving...' : 'Save'}
                  className="allocation-submit-btn"
                  onClick={this.handleSubmit}
                  disabled={this.submitDisabled(editable)}
                  loading={this.isLoading()}
                  theme={this.props.theme}
                  type="primary"
                />
              )}
            </div>
            <div className="allocation-feedback">
              { submitted && <p className="allocation-success">Allocation applied successfully</p> }
              { failed
                && (
                <p className="allocation-feedback allocation-error">
                  Error occured during upload. Please try again.
                </p>
                )}
            </div>
          </div>
        </ExpandableSection>
      </div>
    );
  }
}

Allocation.propTypes = {
  workspace: PropTypes.string.isRequired,
  feeder: PropTypes.object.isRequired,
  branch: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  allocation: PropTypes.shape({
    DER: PropTypes.object,
    bulk: PropTypes.object,
    loadPF: PropTypes.object,
    pvPF: PropTypes.object,
    valueType: PropTypes.string,
    uploadStatus: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    submitAllocationChange: PropTypes.func,
    updatePerPhaseValue: PropTypes.func,
    setSelectedValueType: PropTypes.func,
    setDefaults: PropTypes.func,
    resetAllocationValues: PropTypes.func,
    getAllocationDefaults: PropTypes.func,
  }).isRequired,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
};

export default Allocation;
