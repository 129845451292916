import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomScrollBar from 'components/CustomScrollBar';
import Input from 'components/Input';
import AssetViolations from './AssetViolations';
import * as PFHelpers from '../../../helpers/PowerflowHelpers';
import './ViolationsPanel.scss';

class ViolationsPanel extends PureComponent {
  clickCheckAll = (allChecked, allDash) => {
    if (allDash || !allChecked) {
      this.props.actions.updateSelectedViolationsAllNone(true);
    } else if (allChecked && !allDash) {
      this.props.actions.updateSelectedViolationsAllNone(false);
    }
  };

  render() {
    const {
      violationsSelected, actions, theme, expanded, violationDisplayMin,
    } = this.props;
    const violationValues = Object.values(this.props.violationsSelected);
    const allChecked = violationValues.some(v => v === true);
    const allDash = violationValues.some(v => v === true)
      && !violationValues.every(v => v === true);
    return (
      <CustomScrollBar>
        <div
          className={classNames({
            'violations-panel': true,
            'violations-panel--expanded': expanded,
          })}
        >
          <div className="panel-header">
            <h1 className="title-text">Analysis Violations</h1>
            <div className="violation-min">
              <Input
                type="number"
                id="violations_count_min"
                theme={theme}
                label="Show if more than  "
                value={violationDisplayMin}
                validation={{
                  min: 0,
                  required: true,
                }}
                options={{
                  step: '1',
                  precision: 0,
                }}
                inputWidth="60px"
                onChange={e => actions.updateViolationDisplayMin(e)}
              />
              <span className="units">{` violation${violationDisplayMin === 1 ? '' : 's'}`}</span>
            </div>
          </div>
          <div className="violations-checkbox-area">
            <div className="violations-checkbox-row">
              <div className="violations-checkbox-container">
                <CustomCheckbox
                  id="all-checked"
                  checked={allChecked}
                  dash={allDash}
                  onClick={() => this.clickCheckAll(allChecked, allDash)}
                />
                <div className="violations-checkbox-label">All Violations</div>
              </div>
            </div>
            {PFHelpers.violationKeys.map(v => (
              <div className="violations-checkbox-row" key={v}>
                <div className="violations-checkbox-container" key={v}>
                  <CustomCheckbox
                    id={`checkbox-violation-${v}`}
                    checked={violationsSelected[v]}
                    value={v}
                    onClick={actions.updateSelectedViolations}
                  />
                  <div className="violations-checkbox-label">
                    {PFHelpers.violationCategory[v].label}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <AssetViolations />
        </div>
      </CustomScrollBar>
    );
  }
}

ViolationsPanel.defaultProps = {
  violationsSelected: {},
  theme: 'dark',
};

ViolationsPanel.propTypes = {
  actions: PropTypes.object.isRequired,
  violationsSelected: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  violationDisplayMin: PropTypes.number.isRequired,
};

export default ViolationsPanel;
