import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Input from 'components/Input';
import { amps, kVll } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import WireSpacingSelector from './WireSpacingSelector';
import WireConductorSelector from './WireConductorSelector';
import PerPhaseContainer from '../partials/PerPhaseContainer';
import PerPhaseRow from '../partials/PerPhaseRow';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import './InstanceInfo.scss';

const InstanceInfo = ({
  asset,
  workspace,
  displayBranch,
  theme,
  handleSave,
  recalculateLength,
  disabled,
  inEditMode,
}) => {
  const {
    attributes,
    phase_wire_info,
    phase: phaseCode,
    base_voltage,
  } = asset;

  const [length, setLength, lengthEdited] = useTrackingState(attributes.length);

  return (
    <div>
      <div className="header-container">
        <WireSpacingSelector
          asset={asset}
          handleSave={handleSave}
          disabled={disabled}
          inEditMode={inEditMode}
          workspace={workspace}
          theme={theme}
          displayBranch={displayBranch}
        />
        <WireConductorSelector
          asset={asset}
          handleSave={handleSave}
          disabled={disabled}
          inEditMode={inEditMode}
          workspace={workspace}
          theme={theme}
          displayBranch={displayBranch}
        />
        <hr className="section-divider" />
        <div className="editable-section">
          <div className="input-spacing">
            <Input
              label="Nominal Voltage"
              value={base_voltage}
              key="nodeBaseVoltage"
              id="nodeBaseVoltage"
              type="number"
              inputStyle="panel"
              unit={kVll}
              theme={theme}
              disabled
              options={{
                divisor: 1000,
              }}
            />
          </div>
          <div className="input-spacing">
            <SingleEditableValue
              key="length"
              id="length"
              label="Length"
              type="number"
              precision={3}
              unit="m"
              value={length}
              disabled={disabled}
              onBlur={() => handleSave({ length })}
              onChange={setLength}
              min={0}
              required
              edited={lengthEdited}
            />
          </div>
          {inEditMode && (
            <div className="recalc-container input-spacing">
              <Button
                type="text"
                theme={theme}
                label="Recalculate Length"
                className="recalc-button"
                onClick={recalculateLength}
                disabled={disabled}
              />
            </div>
          )}

          <PerPhaseContainer>
            <PerPhaseRow
              label="Rated Current"
              id="ratedCurrents"
              key="ratedCurrents"
              unit={amps}
              divisor={1}
              values={Object.entries(phase_wire_info).reduce(
                (phase_map, [phase, info]) => ({
                  ...phase_map,
                  [phase]: info?.rated_current,
                }),
                {},
              )}
              getTotal={() => ''}
              type="number"
              phases={phaseCode}
            />
          </PerPhaseContainer>
        </div>
      </div>
    </div>
  );
};

InstanceInfo.defaultProps = {
  disabled: true,
  theme: 'dark',
  inEditMode: false,
};

InstanceInfo.propTypes = {
  disabled: PropTypes.bool,
  asset: PropTypes.object.isRequired,
  theme: PropTypes.string,
  inEditMode: PropTypes.bool,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  recalculateLength: PropTypes.func.isRequired,
};

export default InstanceInfo;
