import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './CustomScrollBar.scss';

const CustomScrollBar = ({
  children, className, alwaysShow, ...props
}) => (
  <PerfectScrollbar
    className={classNames({
      'custom-scrollbar': true,
      [className]: true,
      'custom-scrollbar--always-show': alwaysShow,
    })}
    {...props}
  >
    {children}
  </PerfectScrollbar>
);

CustomScrollBar.propTypes = {
  alwaysShow: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomScrollBar.defaultProps = {
  alwaysShow: false,
  className: '',
  children: null,
};

export default CustomScrollBar;
