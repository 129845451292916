import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import HelpTooltip from 'components/HelpTooltip';
import Select from 'components/Select';
import getControlModeName from 'helpers/cim/getControlModeName';
import Request from 'helpers/Request';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';

const ControlSelectorList = ({
  workspace, branch, selectedContainers, analyses, setSelectedPreviousSimulations,
}) => {
  const [assetList, setAssetList] = useState([]);
  const [selectedAnalysisMappings, setSelectedAnalysisMappings] = useState({});
  const theme = useContext(ThemeContext);

  const loadAssetList = async () => {
    const req = new Request(`/api/workspace/${workspace}/branch/${branch}/asset/control_modes`);
    setSelectedAnalysisMappings({});
    setAssetList([]);
    try {
      const assetListResponse = await req.get({
        params: {
          container: selectedContainers.map(x => x.id),
        },
      });
      const assetListFiltered = assetListResponse.data.filter((asset) => ['EnergySource', 'EquivalentSubstation'].includes(asset.cim_class));
      setAssetList(
        // sort by class then name
        alphabetizeByKey(
          alphabetizeByKey(assetListFiltered, 'cim_class'),
          'name',
        ),
      );

      setSelectedAnalysisMappings(assetListFiltered.reduce((prev, curr) => {
        if (curr.control_mode === 'analysisSchedule') {
          prev[curr.id] = null;
        }
        return prev;
      }, {}));
    } catch (ex) {
      setSelectedAnalysisMappings({});
      setAssetList([]);
    }
  };

  useEffect(() => {
    if (workspace && branch && selectedContainers.length) {
      loadAssetList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, selectedContainers]);

  useEffect(() => {
    setSelectedPreviousSimulations(
      Object.entries(selectedAnalysisMappings)
        .map(([key, value]) => ({ asset_id: key, analysis_id: value })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnalysisMappings]);

  const getHelpTooptip = asset => {
    if (asset.control_mode === 'analysisSchedule') {
      return 'This asset is configured to use the results of a previous analysis for its schedule. '
        + 'Select which analysis should be used or change the control mode in the asset panel.';
    }

    return `Only assets with a ${getControlModeName(asset.cim_class, 'analysisSchedule')} control mode need an analysis selected`;
  };

  return (
    <div style={{ padding: '10px' }}>
      {assetList.length === 0 && (
        <p className="caption-text">No assets in the selected feeders & substations support analysis schedules</p>
      )}
      {assetList.map(asset => (
        <div key={asset.id} style={{ marginBottom: '10px' }}>
          <p>
            {asset.name}
            {' '}
            (
            {getControlModeName(asset.cim_class, asset.control_mode)}
            )
          </p>
          <div className="flex-centered" style={{ padding: '5px 0' }}>
            <Select
              value={selectedAnalysisMappings[asset.id]}
              options={analyses.filter(
                analysis => analysis.containers.includes(asset.linked_equipment_container),
              ).map(
                x => ({ value: x.id, label: x.name }),
              )}
              theme={theme}
              onChange={(analysis) => {
                const newMappings = {
                  ...selectedAnalysisMappings,
                  [asset.id]: analysis.value,
                };
                setSelectedAnalysisMappings(newMappings);
              }}
              clearable={false}
              disabled={asset.control_mode !== 'analysisSchedule'}
            />
            <HelpTooltip message={getHelpTooptip(asset)} />
          </div>
        </div>
      ))}
    </div>
  );
};

ControlSelectorList.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  analyses: PropTypes.array.isRequired,
  setSelectedPreviousSimulations: PropTypes.func.isRequired,
};

export default ControlSelectorList;
