import React, { useState } from 'react';
import Select from 'components/Select';
import PropTypes from 'prop-types';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';
import ValidationErrors from './ValidationErrors';
import './ErrorPage.scss';

/**
 * Displays errors per profile
 */
const ErrorPage = ({
  theme,
  workspaceErrors,
}) => {
  const [currentErrorType, setCurrentErrorType] = useState(null);
  const [currentAsset, setCurrentAsset] = useState(null);

  /**
   * Create a dropdown that contains all profiles.
   * @param  {Object} Errors  All errors keyed by type
   * @return {JSX}            Dropdown menu with label
   */
  const createObjectTypeSelector = (errors) => {
    if (!errors) {
      return null;
    }

    let options = Object.entries(errors).map(([type, { name }]) => ({
      value: type, label: name,
    }));
    options = alphabetizeByKey(options, 'label');

    return (
      <div className="selector-container">
        <Select
          className="error-type-selector"
          clearable={false}
          name="Asset Type"
          options={options}
          onChange={(e) => {
            setCurrentErrorType(e.value);
            setCurrentAsset(null);
          }}
          placeholder="Select a type"
          theme={theme}
          value={currentErrorType}
        />
      </div>
    );
  };

  const createInstanceSelector = (type) => {
    if (!workspaceErrors || !workspaceErrors[type] || !workspaceErrors[type].data) {
      return null;
    }

    let options = Object.entries(workspaceErrors[type].data).map(
      ([id, data]) => {
        let hasError = false;
        let hasWarning = false;

        for (let i = 0; i < data.messages.length; i += 1) {
          const msg = data.messages[i];
          if (msg.severity === 'ERROR') {
            hasError = true;
          } else if (msg.severity === 'WARNING') {
            hasWarning = true;
          }
        }

        return {
          value: id,
          label: data.name,
          hasError,
          hasWarning,
        };
      },
    );
    options = alphabetizeByKey(options, 'label');
    return (
      <div className="selector-container">
        <Select
          className="instance-selector"
          clearable={false}
          name="Instance"
          options={options}
          onChange={e => setCurrentAsset(e.value)}
          placeholder="Select an instance"
          theme={theme}
          value={currentAsset}
        />
      </div>
    );
  };

  let errors = {};
  if (workspaceErrors && currentAsset && currentErrorType) {
    errors = workspaceErrors[currentErrorType].data[currentAsset];
  }

  return (
    <div className="error-page">
      <div className="validation-header error-page-header">
        <h2>Errors and Warnings</h2>
      </div>
      {createObjectTypeSelector(workspaceErrors)}
      {createInstanceSelector(currentErrorType)}
      <span className="error-table-container">
        <ValidationErrors errors={errors} />
      </span>
    </div>
  );
};

ErrorPage.contextType = ThemeContext;

ErrorPage.defaultProps = {
  workspaceErrors: {},
};

ErrorPage.propTypes = {
  theme: PropTypes.string.isRequired,
  workspaceErrors: PropTypes.object,
};

export default ErrorPage;
