import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './TextInputGroup.scss';

// Creates a styled text input with a label.
class TextInputGroup extends PureComponent {
  state = {
    edited: false,
  }

  textInput = null;

  componentDidMount() {
    // autofocus the input on mount
    this.focusTextInput();
  }

  setTextInputRef = (element) => {
    this.textInput = element;
  };

  focusTextInput = () => {
    // Focus the text input using the raw DOM API
    if (this.props.setFocus && this.textInput) this.textInput.focus();
  };

  onChange = (e) => {
    if (!this.state.edited) {
      this.setState({ edited: true });
    }
    this.props.onChange(e);
  }

  validate = () => {
    const { required, value, invalid } = this.props;
    if (!this.state.edited) {
      return true;
    }

    let valid = true;
    if (required) {
      valid = value && value.length > 0;
    }
    if (invalid) {
      valid = false;
    }
    return valid;
  }

  style = () => {
    const styleObj = {};
    if (this.props.inputWidth) styleObj.width = this.props.inputWidth;
    if (this.props.inputColor) styleObj.color = this.props.inputColor;
    return styleObj;
  };

  render() {
    return (
      <div
        className={classnames({
          'text-input-group': true,
          [this.props.theme]: true,
          [this.props.className]: true,
        })}
      >
        <div className="text-input">
          {(this.props.label && !this.props.hideLabel) && (
            <label htmlFor={this.props.htmlFor || this.props.id} className="text-input__label">
              {this.props.label}
              {this.props.required ? ' *' : ''}
            </label>
          )}
          <div className="text-input-container">
            <input
              id={this.props.htmlFor || this.props.id}
              type="text"
              className={classnames({
                'text-input__input': true,
                'text-invalid': !this.validate(),
                'text-optional': !this.props.required,
              })}
              onBlur={this.validate() && this.props.onBlur}
              onFocus={this.props.onFocus}
              name={this.props.name}
              value={this.props.value}
              title={this.props.value}
              onChange={e => this.onChange(e)}
              style={this.style()}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              required={this.props.required}
              onKeyPress={e => this.props.onKeyPress(e)}
              ref={this.setTextInputRef}
              phase={this.props.phase}
            />
            {this.props.unit && <span className="number-input-unit">{this.props.unit}</span>}
          </div>
        </div>
        {!this.validate() && (
          <div className="input-error">
            <p>
              {this.props.validationMessage ? this.props.validationMessage : `Please enter a valid ${this.props.label ? this.props.label : this.props.id}`}
            </p>
          </div>
        )}
      </div>
    );
  }
}

TextInputGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputWidth: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  validationMessage: PropTypes.string,
  onKeyPress: PropTypes.func,
  setFocus: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  phase: PropTypes.string,
  htmlFor: PropTypes.string,
  inputColor: PropTypes.string,
  unit: PropTypes.node,
};

TextInputGroup.defaultProps = {
  inputWidth: null,
  htmlFor: null,
  placeholder: null,
  disabled: false,
  theme: 'dark',
  label: null,
  className: '',
  required: false,
  invalid: false,
  validationMessage: null,
  setFocus: false,
  hideLabel: false,
  onChange: () => null,
  onKeyPress: () => null,
  onBlur: undefined,
  onFocus: undefined,
  name: undefined,
  phase: null,
  inputColor: null,
  unit: null,
};

export default TextInputGroup;
