import React, { Component, ReactNode } from 'react';
import { List, AutoSizer } from 'react-virtualized';

import CIMClassInstance from './CIMClassInstance';
import { CimInstanceLookup, CimInstanceLookupItem, CimJSONObject } from '../cim';

type InstancesViewProps = {
  expandedInstances: { [id: string]: boolean };
  instanceTree: CimInstanceLookup | null;
  selectedClass: string|null;
  selectedID: string|null;
  selectedInstance?: CimJSONObject;
  setSelectedInstance: (id: string|null) => void;
  setHoveredInstance: (id: string|null) => void;
};
type InstancesViewState = {
  instances: CimInstanceLookupItem[];
  scrollToIndex?: number;
};

class InstancesView extends Component<InstancesViewProps, InstancesViewState> {
  state = { instances: [], scrollToIndex: undefined };

  componentDidMount(): void {
    const instances = this.getInstances();
    this.setState({ instances });
  }

  componentDidUpdate(prevProps: InstancesViewProps): void {
    if (this.props.selectedClass !== prevProps.selectedClass || this.props.instanceTree !== prevProps.instanceTree) {
      const instances = this.getInstances();
      let scrollToIndex;

      if (this.props.expandedInstances && Object.keys(this.props.expandedInstances).length > 0) {
        const selectedInstance = Object.keys(this.props.expandedInstances)[0];
        for (let i = 0; i < instances.length; i += 1) {
          if (instances[i].id === selectedInstance) {
            scrollToIndex = i;
            break;
          }
        }
      }

      this.setState({ instances, scrollToIndex });
    }
  }

  /**
   * Determine the type of instances to display, the selected node's instaneces
   * only, or the instance and all of its children's instances.
   */
  getInstances(): CimInstanceLookupItem[] {
    const { selectedClass, instanceTree } = this.props;

    let instances: CimInstanceLookupItem[] = [];

    if (selectedClass && instanceTree) {
      // If an instance of the selected class exists in the instanceTree, it is
      // a leaf node. Display only its instances.
      const root = instanceTree[selectedClass];
      if (root) {
        instances = root.instanceList;
      }
    }

    const sortedInstances = instances.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return sortedInstances;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  _instanceRenderer = (instances: CimInstanceLookupItem[]) => ({ index, key, style }: any) => (
    <CIMClassInstance
      style={style}
      key={key}
      selectedID={this.props.selectedID}
      selectedInstance={this.props.selectedInstance}
      setSelectedInstance={this.props.setSelectedInstance}
      setHoveredInstance={this.props.setHoveredInstance}
      id={instances[index].id}
      name={instances[index].name}
    />
  )

  render(): ReactNode {
    const { instances } = this.state;
    const status = instances.length > 0 ? 'active' : 'inactive';
    return (
      <div className="instances-view-container">
        <div className={`cim-explorer-column-header cim-explorer-column-header--${status}`}>
          <h2 className="cim-explorer-column-header__h2">Instances</h2>
        </div>
        <div className="gray-scrollbars cim-explorer-column-content cim-explorer-indented-list">
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="gray-scrollbars"
                overscanColumnCount={10}
                rowCount={instances.length}
                rowHeight={34}
                width={width}
                height={height}
                scrollToIndex={this.state.scrollToIndex}
                scrollToAlignment="start"
                rowRenderer={this._instanceRenderer(instances)}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default InstancesView;
