import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';

const ContainerHeader = ({
  container,
  renameContainer,
  inEditMode,
  theme,
  loading,
  deleteFn,
}) => {
  const [edited, setEdited] = useState(false);
  const [containerName, setContainerName] = useState(container.name || container.id);

  const updateContainerName = ({ target: { value } }) => {
    setEdited(true);
    setContainerName(value);
  };

  const rename = () => {
    renameContainer(containerName);
    setEdited(false);
  };
  useEffect(() => {
    setContainerName(container.name);
  }, [container]);
  return (
    <div className="edit-container-name">
      <input
        className={`asset-panel-name ${edited ? 'asset-panel-name--edited' : ''}`}
        value={containerName}
        onChange={updateContainerName}
        disabled={!inEditMode || loading}
        {...(edited ? { onBlur: rename } : {})}
        title={containerName}
      />
      <span>
        {loading && (
          <span className="loading-placeholder">
            <i className="material-icons rotate">refresh</i>
          </span>
        )}
        {inEditMode && deleteFn !== null && (
          <IconButton
            icon="delete"
            tooltip={`Delete ${container.type}`}
            onClick={deleteFn}
            theme={theme}
          />
        )}
      </span>
    </div>
  );
};

ContainerHeader.propTypes = {
  container: PropTypes.object.isRequired,
  renameContainer: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  deleteFn: PropTypes.func,
  loading: PropTypes.bool,
};

ContainerHeader.defaultProps = {
  deleteFn: null,
  loading: false,
};

export default ContainerHeader;
