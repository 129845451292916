/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './PhaseBadgeContainer.scss';

class PhaseBadgeContainer extends React.Component {
  getClassName(phase) {
    if (this.props.disabledPhases.includes(phase)) {
      return 'phase-badge--disabled';
    }

    return this.props.activePhases[phase] ? '' : 'phase-badge--off';
  }

  generateBadge = phase => (
    <button
      disabled={
        !Object.keys(this.props.activePhases).includes(phase)
        || this.props.disabledPhases.includes(phase)
      }
      className={`phase-badge ${this.getClassName(phase)}`}
      style={{ backgroundColor: this.props.phaseColors[phase] ? this.props.phaseColors[phase] : '#FFFFFF' }}
      onMouseOver={() => this.props.onHoverChange(phase)}
      onMouseOut={() => this.props.onHoverChange(null)}
      onClick={() => this.props.onActivePhaseChange({ [phase]: !this.props.activePhases[phase] })}
      type="button"
    >
      {phase}
    </button>
  )

  render() {
    return (
      <div className="phase-badge-container">
        { this.generateBadge('ABC') }
        { this.generateBadge('A') }
        { this.generateBadge('B') }
        { this.generateBadge('C') }
      </div>
    );
  }
}

PhaseBadgeContainer.defaultProps = {
  disabledPhases: [],
};

PhaseBadgeContainer.propTypes = {
  activePhases: PropTypes.object.isRequired,
  disabledPhases: PropTypes.array,
  phaseColors: PropTypes.object.isRequired,
  onActivePhaseChange: PropTypes.func.isRequired,
  onHoverChange: PropTypes.func.isRequired,
};

export default PhaseBadgeContainer;
