/* eslint-disable react/jsx-key */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import ThemedTabs from 'components/ThemedTabs';
import CustomScrollBar from 'components/CustomScrollBar';
import asyncActionStates from 'helpers/asyncActionStates';

import { useAttachments } from 'contexts/AttachmentsContext';
import ThemeContext from 'helpers/ThemeContext';
import FilesPanel from 'components/Files';
import NotesPanel from 'components/Notes';

import './PanelTabs.scss';

const PanelTabs = ({
  submitDisabled,
  showSave,
  onSubmit,
  createInstanceReq,
  tabs,
  children,
  assetID,
}) => {
  const theme = useContext(ThemeContext) || 'dark';
  const { files, notes } = useAttachments();
  const tabsToRender = () => {
    const validAsset = assetID && assetID !== 'add';

    const propTabs = tabs.map(tab => ({
      disabled: false,
      name: tab,
    }));

    const toRender = [
      ...propTabs,
      {
        name: `Files${files && files.length ? ` (${files.length})` : ''}`,
        disabled: !validAsset,
      },
      {
        name: `Notes${notes && notes.length ? ` (${notes.length})` : ''}`,
        disabled: !validAsset,
      },
    ];
    return toRender;
  };

  const renderSaveButton = () => {
    if (!showSave) {
      return null;
    }
    return (
      <div className="save-container">
        {createInstanceReq === asyncActionStates.ERROR && (
          <div className="save-feedback--error">
            <i className="material-icons">warning</i>
            Unable to save changes.
          </div>
        )}
        <Button
          disabled={submitDisabled || createInstanceReq === asyncActionStates.LOADING}
          className="save-btn"
          onClick={onSubmit}
          loading={createInstanceReq === asyncActionStates.LOADING}
          label="Save"
          theme={theme}
        />
      </div>
    );
  };

  const renderPanels = (TabPanel) => {
    const panels = [
      ...children.map(panel => <CustomScrollBar>{panel}</CustomScrollBar>),
      <FilesPanel assetID={assetID} key="files-tab" />,
      <NotesPanel assetID={assetID} key="notes-tab" />,
    ];

    return panels.map((panel, i) => (
      <TabPanel key={`panel-${panels.length - i}`}>{panel}</TabPanel>
    ));
  };

  return (
    <ThemedTabs
      className="panel-tabs"
      theme={theme}
      tabs={tabsToRender()}
      headerRenderer={renderSaveButton}
    >
      {TabPanel => renderPanels(TabPanel)}
    </ThemedTabs>
  );
};

PanelTabs.defaultProps = {
  submitDisabled: true,
  showSave: false,
  onSubmit: null,
  createInstanceReq: asyncActionStates.INITIAL,
  assetID: undefined,
};

PanelTabs.propTypes = {
  submitDisabled: PropTypes.bool,
  showSave: PropTypes.bool,
  onSubmit: PropTypes.func,
  createInstanceReq: PropTypes.number,
  tabs: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
  assetID: PropTypes.string,
};

export default PanelTabs;
