import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import TextInput from 'components/TextInput';
import asyncActionStates from 'helpers/asyncActionStates';

import Request from 'helpers/Request';

const AuthConfig = ({
  disabled,
  displayAlertMessage,
  open,
  theme,
  workspace,
}) => {
  // Auth Config State
  const [authLoadingState, setAuthLoadingState] = useState(asyncActionStates.INITIAL);
  const [authEdited, setAuthEdited] = useState(false);
  const [authSavingState, setAuthSavingState] = useState(asyncActionStates.INITIAL);
  const [tenantDomain, setTenantDomain] = useState('');
  const [tenantID, setTenantId] = useState('');
  const [tenantSecret, setTenantSecret] = useState('');

  const loadAuthConfig = () => {
    let didCancel = false;

    async function fetchAuthConfig() {
      const request = new Request(`/api/config-tenant/${workspace}`);
      setAuthLoadingState(asyncActionStates.LOADING);
      try {
        const { data } = await request.get();

        if (didCancel) {
          // Cancelled before the request finished so do nothing
          setAuthLoadingState(asyncActionStates.CANCELLED);
          return;
        }

        setTenantDomain(data.domain);
        setTenantId(data.client_id);

        // We don't get the real secret back, just a boolean indicating
        // if it is set or not. Fill in the UI with some fake '*' so the
        // user knows that a value is set
        setTenantSecret(data.secret ? '******' : '');
        setAuthLoadingState(asyncActionStates.SUCCESS);
      } catch (error) {
        if (!error.response || error.response.status !== 403) {
          // Seeing this message for all 403 could be annoying
          displayAlertMessage(
            'Failed to retrieve auth configuration',
            'Could not retrieve auth configuration',
            null,
            null,
          );
        }
        setAuthLoadingState(asyncActionStates.ERROR);
      }
    }

    // Only do a load when a component is opened
    if (open && !disabled) {
      fetchAuthConfig();
    }
    return () => { didCancel = true; };
  };
  const clearAuthConfig = async () => {
    // Clear by sending delete query
    const requestUrl = `/api/config-tenant/${encodeURIComponent(workspace)}`;
    const request = new Request(requestUrl);
    setAuthSavingState(asyncActionStates.LOADING);

    try {
      await request.delete();
      loadAuthConfig();
      setAuthSavingState(asyncActionStates.SUCCESS);
      setAuthEdited(false);
    } catch (error) {
      setAuthSavingState(asyncActionStates.ERROR);
      let message = 'Could not save auth configuration.';

      if (error.response && error.response.status === 403) {
        message = 'Permission to configure auth is not set.';
      }

      displayAlertMessage(
        'Failed to save auth configuration',
        message,
        null,
        () => clearAuthConfig(),
      );
    }
  };
  const saveAuthConfig = async () => {
    const requestUrl = '/api/config-tenant'
      + `?workspace=${encodeURIComponent(workspace)}`
      + `&domain=${encodeURIComponent(tenantDomain)}`
      + `&client_id=${encodeURIComponent(tenantID)}`
      + `&client_secret=${encodeURIComponent(tenantSecret)}`;
    const request = new Request(requestUrl);
    setAuthSavingState(asyncActionStates.LOADING);
    try {
      await request.post();
      setAuthSavingState(asyncActionStates.SUCCESS);
      setAuthEdited(false);
    } catch (error) {
      setAuthSavingState(asyncActionStates.ERROR);

      let message = 'Could not save auth configuration.';

      if (error.response && error.response.status === 403) {
        message = 'Permission to configure auth is not set.';
      }

      displayAlertMessage(
        'Failed to save auth configuration',
        message,
        null,
        () => clearAuthConfig(),
      );
    }
  };
  useEffect(loadAuthConfig, [open, disabled, displayAlertMessage, workspace]);

  return (
    <div className="workspace-card-tab-panel">
      <div className="column column-left">
        <TextInput
          label="Tenant ID"
          value={tenantID}
          id="tenantID"
          disabled={
            authLoadingState === asyncActionStates.LOADING
            || authSavingState === asyncActionStates.LOADING
            || disabled
          }
          onChange={(e) => {
            setAuthEdited(true);
            setTenantId(e.target.value);
          }}
          theme={theme}
          inputWidth="300px"
        />
        <TextInput
          label="Tenant Secret"
          value={tenantSecret}
          id="tenantSecret"
          disabled={
            authLoadingState === asyncActionStates.LOADING
            || authSavingState === asyncActionStates.LOADING
            || disabled
          }
          onChange={(e) => {
            setAuthEdited(true);
            setTenantSecret(e.target.value);
          }}
          theme={theme}
          inputWidth="300px"
        />
        <TextInput
          label="Tenant Domain"
          value={tenantDomain}
          id="tenantDomain"
          disabled={
            authLoadingState === asyncActionStates.LOADING
            || authSavingState === asyncActionStates.LOADING
            || disabled
          }
          onChange={(e) => {
            setAuthEdited(true);
            setTenantDomain(e.target.value);
          }}
          theme={theme}
          inputWidth="300px"
        />
      </div>
      <div className="column column-right">
        <Button
          label="Apply Changes"
          className="workspace-card-button"
          id="auth-button"
          disabled={
            !authEdited
            || !tenantDomain
            || !tenantID
            || !tenantSecret
            || authSavingState === asyncActionStates.LOADING
            || disabled
          }
          onClick={saveAuthConfig}
          loading={authSavingState === asyncActionStates.LOADING}
          theme={theme}
          type="primary"
        />
        <Button
          label="Clear Configuration"
          className="workspace-card-button"
          disabled={authSavingState === asyncActionStates.LOADING || disabled}
          onClick={clearAuthConfig}
          loading={authSavingState === asyncActionStates.LOADING}
          theme={theme}
          type="primary"
        />
      </div>
    </div>
  );
};

AuthConfig.defaultProps = {
  disabled: true,
  open: false,
};

AuthConfig.propTypes = {
  disabled: PropTypes.bool,
  displayAlertMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
};

export default AuthConfig;
