import React from 'react';
import PropTypes from 'prop-types';
import CheckmarkIcon from '../Icons/CheckmarkIcon';
import PermissionDisabledTip from '../PermissionDisabledTip';
/**
 * Component to create a standard dropdown menu item. Can have icon and has check
 * option to denote selected
 */
const StandardMenuItem = props => (
  <PermissionDisabledTip
    hide={!props.disabled || !props.disabledMessage}
    title={props.data.contents}
    message={props.disabledMessage}
  >
    <button
      className={`dropdown-item__btn ${props.selected ? 'selected' : ''}`}
      style={{ height: props.height }}
      data-type={props.data.id}
      onClick={() => props.toggle(props.data.id)}
      tabIndex={props.active ? 0 : -1}
      disabled={props.disabled}
      type="button"
    >
      {props.icon && <div className="dropdown-item__svg--main">{props.icon}</div>}
      {props.data.contents}
      {props.selected && (
        <div className="dropdown-item__svg--check">
          <CheckmarkIcon color="#5A9CDE" height={14} width={14} />
        </div>
      )}
    </button>
  </PermissionDisabledTip>
);

StandardMenuItem.propTypes = {
  height: PropTypes.number,
  data: PropTypes.shape({
    contents: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  selected: PropTypes.bool,
  /* Callback for button click */
  toggle: PropTypes.func.isRequired,
  icon: PropTypes.element,
  /* If the menu is open */
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
};

StandardMenuItem.defaultProps = {
  selected: false,
  height: 44,
  icon: null,
  disabled: false,
  disabledMessage: undefined,
};

export default StandardMenuItem;
