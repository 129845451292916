import React from 'react';
import { useParams } from 'react-router-dom';
import NetworkTopNav from '../../../../../containers/NetworkTopNavContainer';

import './ReviewNetwork.scss';

const ReviewNetwork = () => (
  <>
    <NetworkTopNav params={useParams()} />
    <div className="review-network">
      Network import review page
    </div>
  </>
);

export default ReviewNetwork;
