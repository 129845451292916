import moment from 'moment';
import Request from 'helpers/Request';
import { alphabetizeByKey } from 'helpers/utils';
import { getInverterType } from 'helpers/NetworkCIMMapping';
import { calculatePerUnit } from 'helpers/JSCIM/extractorHelpers';
import RotatingMachine from 'helpers/JSCIM/Shunt/RotatingMachine';
import Inverter from 'helpers/JSCIM/Shunt/Inverter';
import { getTapChangers } from './LinkHelpers';

const extractFeederNodes = (feederList, lineInstances) => {
  const feederIDs = feederList.map(fdr => fdr.id);
  const selectedLines = Object.values(lineInstances).filter(line => feederIDs.includes(line.id));
  const nodes = selectedLines.reduce((list, line) => [...list, ...line.nodes], []);
  return nodes;
};

const getBatteries = (instanceData) => {
  const batteries = instanceData.classes.PowerElectronicsConnection;
  const lookup = instanceData.objects;

  if (!batteries) {
    return [];
  }

  return batteries.filter((inverterId) => {
    const inverter = lookup[inverterId];

    if (inverter === undefined) {
      return false;
    }
    const batteryId = inverter.references['PowerElectronicsConnection.PowerElectronicsUnit'];
    if (batteryId === undefined) {
      return false;
    }

    return getInverterType(inverterId, lookup) === 'Battery';
  });
};

const getInverterPVs = (instanceData) => {
  const inverters = instanceData.classes.PowerElectronicsConnection;

  const lookup = instanceData.objects;
  if (!inverters) {
    return [];
  }

  return inverters.filter((inverterId) => {
    const inverter = lookup[inverterId];

    if (inverter === undefined) {
      return false;
    }

    const powerElectronicsUnitId = inverter.references['PowerElectronicsConnection.PowerElectronicsUnit'];
    if (powerElectronicsUnitId === undefined) {
      return false;
    }

    return getInverterType(inverterId, lookup) === 'InverterPV';
  });
};

const groupByAsyncMachineTypes = (instanceData) => {
  const types = {
    PhotoVoltaic: instanceData.classes.PhotoVoltaic || [],
    Wind: instanceData.classes.Wind || [],
    AsynchronousMachine: [],
  };
  if (!instanceData.classes.AsynchronousMachine) { return types; }
  instanceData.classes.AsynchronousMachine.forEach((am) => {
    const rotatingMachine = instanceData.objects[am].references['RotatingMachine.GeneratingUnit'];
    if (rotatingMachine) {
      const rotatingMachineType = instanceData.objects[rotatingMachine];
      if (rotatingMachineType.class === 'SolarGeneratingUnit') {
        types.PhotoVoltaic.push(am);
      } else if (rotatingMachineType.class === 'WindGeneratingUnit') {
        types.Wind.push(am);
      } else {
        types.AsynchronousMachine.push(am);
      }
    } else {
      types.AsynchronousMachine.push(am);
    }
  });
  return types;
};

const groupBySyncMachineTypes = (instanceData) => {
  const types = {
    CHP: [],
    RunOfRiverHydro: [],
    SynchronousMachine: [],
  };
  if (!instanceData.classes.SynchronousMachine) { return types; }
  instanceData.classes.SynchronousMachine.forEach((sm) => {
    const gen_unit = instanceData.objects[sm].references['RotatingMachine.GeneratingUnit'];
    if (gen_unit) {
      const gen_unit_obj = instanceData.objects[gen_unit];
      if (gen_unit_obj.class === 'ThermalGeneratingUnit') {
        const cogenPlantReference = gen_unit_obj.references['ThermalGeneratingUnit.CogenerationPlant'];
        if (cogenPlantReference) {
          types.CHP.push(sm);
        } else {
          types.SynchronousMachine.push(sm);
        }
      } else if (gen_unit_obj.class === 'HydroGeneratingUnit') {
        const hydroPowerPlant = gen_unit_obj.references['HydroGeneratingUnit.HydroPowerPlant'];
        if (hydroPowerPlant) {
          types.RunOfRiverHydro.push(sm);
        } else {
          types.SynchronousMachine.push(sm);
        }
      }
    } else {
      types.SynchronousMachine.push(sm);
    }
  });
  return types;
};

// Get the center between two lat/lng points
const getCenter = coordList => ([
  (coordList[0][0] + coordList[1][0]) / 2,
  (coordList[0][1] + coordList[1][1]) / 2,
]);
/**
 * Get the center in lat/lng of any cim asset.
 * The geojson icon is considered the center point
 * @param  {String}  assetId  id of the asset
 * @param  {Object}  geoJSON  the geojson data containing the asset
 * @return {Array<Number>|null}          A single lat/lng coordinate
 */
const getCenterCoords = (assetId, geoJSON) => {
  // first try the easy one where there is a single coordinate (of the icon)
  const assetIcon = geoJSON?.nodes[assetId] ?? geoJSON?.nodeIcons[assetId] ?? geoJSON?.linkIcons[assetId];
  if (assetIcon) {
    // geojson is stored lng/lat so we need to reverse it
    return assetIcon.geometry.coordinates.slice().reverse();
  }

  const line = geoJSON.lines[assetId];
  if (line) {
    const [segment1, segment2] = line.geometry.coordinates;
    return getCenter([segment1.slice().reverse(), segment2.slice().reverse()]);
    // get the centerpoint of the first segment of the line
  }

  return null;
};

/**
 * Generate a color along the range of hues base on the total colors required
 * @param  {Number}  offset  Offset from max and min hues (red) to use
 * @param  {Number}  count   Total colors to be generated
 * @param  {Number}  index   Position of the current color in the list
 * @return {String}          A CSS hsl value
 */
const generateColor = (offset, count, index) => {
  const delta = (360 - (2 * offset)) / count;
  const hue = offset + (index * delta);
  return `hsl(${hue}, 60%, 60%)`;
};

const getShortURNCode = (crsUrn) => {
  if (!crsUrn) {
    return '';
  }
  const urnParts = crsUrn.split(':');
  let urn = crsUrn;
  if (urnParts.length === 7) {
    urn = `${urnParts[4]}:${urnParts[6]}`;
  }

  return urn;
};

const createGeoJSON = (nodes, shunt, lineSegments, links) => {
  const shuntDevices = Object.values(shunt).reduce((lu, type) => {
    Object.values(type).forEach((assetValues) => {
      const {
        asset,
        terminal,
      } = assetValues.coordinates;

      const assetCoords = [
        parseFloat(asset['PositionPoint.xPosition']),
        parseFloat(asset['PositionPoint.yPosition']),
      ];

      const terminalCoords = [
        parseFloat(terminal['PositionPoint.xPosition']),
        parseFloat(terminal['PositionPoint.yPosition']),
      ];

      const iconMarker = {
        type: 'Feature',
        properties: {
          id: assetValues.id,
          name: assetValues.name,
          asset_type: assetValues.class,
          display_type: assetValues.displayName,
          feeder: assetValues.feeder,
          totalP: assetValues.totalP,
          phase: assetValues.phase,
        },
        geometry: {
          type: 'Point',
          coordinates: assetCoords,
        },
      };

      if (assetValues instanceof RotatingMachine || assetValues instanceof Inverter) {
        iconMarker.properties.ratedS = assetValues.ratedS;
      }
      if (assetValues.class === 'LinearShuntCompensator') {
        iconMarker.properties.sub_type = assetValues.isCapacitor ? 'ShuntCapacitor' : 'ShuntReactor';
      } else if (assetValues.class === 'ElectricVehicleChargingStation') {
        iconMarker.properties.sub_type = assetValues.chargerType === 'DC' ? 'DC' : 'AC';
      } else if (assetValues.class === 'EnergySource') {
        iconMarker.properties.nominalVoltage = assetValues?.nominalVoltage;
      } else if (assetValues.class === 'EquivalentSubstation') {
        iconMarker.properties.sourceVoltage = shunt.EnergySource[assetValues.EnergySource]?.nominalVoltage;
      }

      if (assetValues.class === 'EnergyConsumer' && assetValues.usagePoints.filter((values) => ['ShiftingDemandResponseProgram', 'CurtailingDemandResponseProgram']
        .includes(values.demandResponse.class)).length > 0) {
        iconMarker.properties.sub_type = 'DR';
        iconMarker.properties.display_type = 'Demand Response Load';
      }
      const offsetLine = {
        type: 'Feature',
        properties: {
          id: assetValues.id,
          name: assetValues.name,
          asset_type: assetValues.class,
          display_type: assetValues.displayName,
          feeder: assetValues.feeder,
          isShuntDevice: true,
        },
        geometry: {
          type: 'LineString',
          coordinates: [assetCoords, terminalCoords],
        },
      };
      lu.nodeIcons[assetValues.id] = iconMarker;
      lu.nodeConnectors[assetValues.id] = offsetLine;
    });
    return lu;
  }, { nodeIcons: {}, nodeConnectors: {} });

  const linkDevices = Object.values(links).reduce((lu, type) => {
    Object.values(type).forEach((asset) => {
      const coordinates = [
        [
          parseFloat(asset._terminalPositions[0]['PositionPoint.xPosition']),
          parseFloat(asset._terminalPositions[0]['PositionPoint.yPosition']),
        ],
        [
          parseFloat(asset._terminalPositions[1]['PositionPoint.xPosition']),
          parseFloat(asset._terminalPositions[1]['PositionPoint.yPosition']),
        ],
      ];

      lu.linkIcons[asset.id] = {
        type: 'Feature',
        properties: {
          id: asset.id,
          name: asset.name,
          asset_type: asset.class,
          display_type: asset.displayName,
          feeder: asset.feeder,
          phase: asset.phase,
          ratedS: asset.ratedS,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            (coordinates[0][0] + coordinates[1][0]) / 2,
            (coordinates[0][1] + coordinates[1][1]) / 2,
          ],
        },
      };

      if (asset.class === 'Switch' || asset.class === 'Cut' || asset.class === 'Disconnector' || asset.class === 'Jumper') {
        lu.linkIcons[asset.id].properties.closed = asset.closed;
        lu.linkIcons[asset.id].properties.perPhaseRatedCurrent = asset.perPhaseRatedCurrent;
      }

      if (asset.class === 'Regulator') {
        const checkRotation = (instance) => {
          const tapChanger = getTapChangers(instance, instance.cimDict);
          if (tapChanger) {
            // Check if there is a RegulatingControl.Terminal.This is used to determine the
            // orientation of the icon.
            // Terminal Sequence #1 is the From node
            // Terminal Sequence #2 is the To node.
            // Icon is drawn with the primary on the left.
            const tapChangerControl = instance.cimDict[tapChanger[0].references['TapChanger.TapChangerControl']];
            const regControl = tapChangerControl.references['RegulatingControl.Terminal'];
            // If the regulator has length, apply a rotation offset.
            // If the regulator has no length, but has a RegulatingControl Terminal,
            // check its sequence number to determine its orientation.
            if (regControl) {
              // If the regulator has a RegulatingControl Terminal check its sequence
              // number to determine its orientation.
              const sequence = instance.cimDict[regControl].attributes['ACDCTerminal.sequenceNumber'];
              if (sequence === 1) {
                return true;
              }
            }
          }
          return false;
        };

        lu.linkIcons[asset.id].properties.flipIcon = checkRotation(asset);
      }

      lu.linkConnectors[asset.id] = {
        type: 'Feature',
        properties: {
          id: asset.id,
          name: asset.name,
          asset_type: asset.class,
          display_type: asset.displayName,
          nodes: asset.nodes,
          feeder: asset.feeder,
        },
        geometry: {
          type: 'LineString',
          coordinates,
        },
      };
    });
    return lu;
  }, { linkIcons: {}, linkConnectors: {} });

  return {
    nodes: Object.values(nodes).reduce((lu, node) => {
      lu[node.id] = {
        type: 'Feature',
        properties: {
          id: node.id,
          name: node.name,
          asset_type: node.class,
          display_type: node.displayName,
          connected_devices: node.connectedEquipment.map(eq => eq.id),
          feeder: node.feeder,
          phase: node.phase,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(node.coordinates['PositionPoint.xPosition']),
            parseFloat(node.coordinates['PositionPoint.yPosition']),
          ],
        },
      };
      return lu;
    }, {}),
    lines: Object.values(lineSegments).reduce((lu, line) => {
      lu[line.id] = {
        type: 'Feature',
        properties: {
          id: line.id,
          name: line.name,
          asset_type: line.class,
          sub_type: line.visibilityClass,
          display_type: line.displayName,
          nodes: line.nodes,
          phase: line.phase,
          feeder: line.feeder,
          nodeBaseVoltage: line.nodeBaseVoltage,
          ratedCurrent: line.ratedCurrent,
          hasReducedTopology: line.hasReducedTopology,
        },
        geometry: {
          type: 'LineString',
          coordinates: line.coordinates.map(coord => ([
            parseFloat(coord['PositionPoint.xPosition']),
            parseFloat(coord['PositionPoint.yPosition']),
          ])),
        },
      };
      return lu;
    }, {}),
    ...shuntDevices,
    ...linkDevices,
  };
};

export const FEEDER = 'Feeder';
export const SUBSTATION = 'Substation';

export const ANALYSIS_TYPES = {
  POWERFLOW: 'POWERFLOW',
  QSTS: 'QSTS',
  QSTS_OPF: 'OPTIMAL_POWERFLOW',
  QSTS_NWE: 'NON_WIRES_EVALUATION',
  HOSTING_CAPACITY: 'HOSTING_CAPACITY',
  EV_CAPACITY: 'EV_CAPACITY',
  BATTERY_SIZING: 'BATTERY_SIZE',
  PEAK_SHAVING: 'peak_shaving',
  CVR: 'cvr',
  COST: 'cost',
  CLONING_SCENARIOS: 'CLONING_SCENARIOS',
  ASSET_SCHEDULE_PREAGG_MIGRATION: 'ASSET_SCHEDULE_PREAGG_MIGRATION',
};

export const ACTIVITY_LOG_INTERVAL = 5000;

export const ACTIVITY_LOG_STATUS = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PARTIAL_COMPLETED: 'PARTIAL_COMPLETED',
  RUNNING: 'RUNNING',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  CANCELING: 'CANCELING',
  CANCELED_PARTIAL_COMPLETED: 'CANCELED_PARTIAL_COMPLETED',
  POSTPROCESSING: 'POSTPROCESSING',
};
export const TYPE_MAP = {
  ACLineSegment: { assetType: 'line' },
  AsynchronousMachine: { assetType: 'asynchronous_machine' },
  Battery: { assetType: 'inverter' },
  Breaker: { assetType: 'switch' },
  CHP: { assetType: 'synchronous_machine' },
  Cut: { assetType: 'switch' },
  Disconnector: { assetType: 'switch' },
  ElectricVehicleChargingStation: { assetType: 'ev_station' },
  EnergyConsumer: { assetType: 'energy_consumer' },
  EnergySource: { assetType: 'slack' },
  EquivalentSubstation: { assetType: 'equivalent_substation' },
  Fuse: { assetType: 'switch' },
  InverterPV: { assetType: 'inverter' },
  Jumper: { assetType: 'switch' },
  LinearShuntCompensator: { assetType: 'capacitor' },
  PhotoVoltaic: { assetType: 'asynchronous_machine' },
  PowerTransformer: { assetType: 'power_transformer' },
  Recloser: { assetType: 'switch' },
  Regulator: { assetType: 'power_transformer' },
  Sectionaliser: { assetType: 'switch' },
  Switch: { assetType: 'switch' },
  SynchronousMachine: { assetType: 'synchronous_machine' },
  RunOfRiverHydro: { assetType: 'synchronous_machine' },
  Wind: { assetType: 'asynchronous_machine' },
  ConnectivityNode: { assetType: 'node' },
};
const validateEnd = (start, end) => {
  // If the range is <= 10 years, get the timepoints
  // Else get the timepoints for the first 10 years
  const tenYearsLater = moment(start).add(10, 'years');
  return end.diff(start, 'years') > 10 ? tenYearsLater : end;
};

export const determineScenarioTimeSpan = async (workspace, branch, scenarioId) => {
  const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${scenarioId}/timespan`;
  let timeSpan = { start: null, end: null };
  if (scenarioId) {
    try {
      const rangeResp = await new Request(url).get();
      const { start_date, end_date } = rangeResp.data;
      const start = moment.parseZone(start_date).utc();
      const end = validateEnd(start, moment.parseZone(end_date).utc());
      timeSpan = { start, end };
    } catch (err) {
    }
  }
  return timeSpan;
};

export const getAnalysisTimepoints = async (
  workspace, branch, scenarioId, feeders, analysis,
) => {
  const params = {
    feeder: feeders.map(m => m.id),
    scenario_id: scenarioId,
    analysis_name: analysis.name,
  };
  try {
    let url = `/api/workspace/${workspace}/branch/${branch}`;
    switch (analysis.type) {
      case ANALYSIS_TYPES.POWERFLOW:
      case ANALYSIS_TYPES.QSTS:
      case ANALYSIS_TYPES.QSTS_OPF:
        url += '/power-flow-results/timepoints';
        break;
      case ANALYSIS_TYPES.HOSTING_CAPACITY:
        url += '/hosting-capacity-results/timepoints';
        params.hc_type = 'generation';
        break;
      case ANALYSIS_TYPES.EV_CAPACITY:
        url += '/hosting-capacity-results/timepoints';
        params.hc_type = 'load';
        break;
      // Battery sizing results are not associated with timepoints
      case ANALYSIS_TYPES.BATTERY_SIZING:
      default:
        throw new Error('unsupported analysis type');
    }
    const request = new Request(url);
    const response = await request.get({ params });
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getAnalyses = async (
  workspace,
  branch,
  selectedScenario,
  permissions,
  cancellationToken = null,
) => {
  const request = new Request(`/api/workspace/${workspace}/branch/${branch}/analysis`);
  let analyses = [];
  if (!selectedScenario || !permissions.has('get_analysis')) {
    return analyses;
  }

  try {
    const { data } = await request.get({ params: { scenario_id: selectedScenario } });
    if (cancellationToken?.cancelled) {
      // Cancelled before the request finished so do nothing
      // eslint-disable-next-line consistent-return
      return analyses;
    }
    analyses = alphabetizeByKey(data, 'name').map(x => ({
      id: x.id,
      name: x.name,
      type: x.analysis_type,
      containers: x.result_containers,
    }));
  } catch (error) {
  }
  return analyses;
};

export const getProjects = async (workspace) => {
  const request = new Request(`/api/workspace/${workspace}/construction_project`);
  let projects = [];
  try {
    const { data } = await request.get();
    projects = data;
  } catch (error) {
  }
  return projects;
};

export const getAnalysisInfo = async (workspace, branch, analysisID) => {
  const request = new Request(`/api/workspace/${workspace}/branch/${branch}/analysis/${analysisID}`);
  let analsisDetails = {};
  try {
    const { data } = await request.get();
    analsisDetails = data;
  } catch (error) {
  }
  return analsisDetails;
};

export const getAllBatterySizingResults = async (workspace, branch, feeder_id,
  scenario, analysis) => {
  const req = new Request(`/api/workspace/${workspace}/branch/${branch}/nodal-analysis-results/summary`);
  let results;
  try {
    const res = await req.get({
      params: {
        analysis_subtype: 'battery',
        feeder: feeder_id,
        scenario_id: scenario,
        analysis_name: analysis,
      },
    });
    results = res.data;
  } catch (err) {
    results = null;
  }
  return results;
};

export const extractAggregatedSV = (results, key) => {
  if (Object.keys(results).length > 0) {
    return {
      A_avg: results[`${key}_a_avg`],
      B_avg: results[`${key}_b_avg`],
      C_avg: results[`${key}_c_avg`],
      A_min: results[`${key}_a_min`],
      B_min: results[`${key}_b_min`],
      C_min: results[`${key}_c_min`],
      A_max: results[`${key}_a_max`],
      B_max: results[`${key}_b_max`],
      C_max: results[`${key}_c_max`],
      ABC_min_avg: results[`${key}_abcmin_avg`],
      ABC_min_min: results[`${key}_abcmin_min`],
      ABC_min_max: results[`${key}_abcmin_max`],
      ABC_max_avg: results[`${key}_abcmax_avg`],
      ABC_max_min: results[`${key}_abcmax_min`],
      ABC_max_max: results[`${key}_abcmax_max`],
      ABC_avg_avg: results[`${key}_abcavg_avg`],
      ABC_avg_min: results[`${key}_abcavg_min`],
      ABC_avg_max: results[`${key}_abcavg_max`],
      ABC_sum_avg: results[`${key}_abcsum_avg`],
      ABC_sum_min: results[`${key}_abcsum_min`],
      ABC_sum_max: results[`${key}_abcsum_max`],
    };
  }
  return { A: null, B: null, C: null };
};

export const getVoltages = (deviceNodes, nodeResults) => {
  const getValue = (result, resultLU) => {
    if (resultLU && result) {
      return Math.min(result, resultLU);
    }
    return result || resultLU;
  };

  return deviceNodes.map(node => nodeResults?.[node]?.voltages).reduce((lu, nodeV) => {
    if (nodeV) {
      try {
        lu.A_avg = getValue(nodeV.A_avg, lu.A_avg);
        lu.B_avg = getValue(nodeV.B_avg, lu.B_avg);
        lu.C_avg = getValue(nodeV.C_avg, lu.C_avg);
        lu.A_min = getValue(nodeV.A_min, lu.A_min);
        lu.B_min = getValue(nodeV.B_min, lu.B_min);
        lu.C_min = getValue(nodeV.C_min, lu.C_min);
        lu.A_max = getValue(nodeV.A_max, lu.A_max);
        lu.B_max = getValue(nodeV.B_max, lu.B_max);
        lu.C_max = getValue(nodeV.C_max, lu.C_max);
        lu.ABC_avg_avg = getValue(nodeV.ABC_avg_avg, lu.ABC_avg_avg);
        lu.ABC_avg_min = getValue(nodeV.ABC_avg_min, lu.ABC_avg_min);
        lu.ABC_avg_max = getValue(nodeV.ABC_avg_max, lu.ABC_avg_max);
        lu.ABC_min_avg = getValue(nodeV.ABC_min_avg, lu.ABC_min_avg);
        lu.ABC_min_min = getValue(nodeV.ABC_min_min, lu.ABC_min_min);
        lu.ABC_min_max = getValue(nodeV.ABC_min_max, lu.ABC_min_max);
        lu.ABC_max_avg = getValue(nodeV.ABC_max_avg, lu.ABC_max_avg);
        lu.ABC_max_min = getValue(nodeV.ABC_max_min, lu.ABC_max_min);
        lu.ABC_max_max = getValue(nodeV.ABC_max_max, lu.ABC_max_max);
        return lu;
      } catch (err) {
        return lu;
      }
    }
    return lu;
  }, {});
};

export const getLinkDeviceResults = (
  cables, linkDevices, linkSVs, nodeResults,
) => {
  // link devices and cables
  const filteredDevices = Object.values(linkDevices).filter(lD => Object.keys(lD).length > 0);
  let linkResults = {}; let linkD = [];
  if (filteredDevices.length > 0) {
    linkD = filteredDevices.map(d => {
      const devs = Object.values(d);
      return devs.map(dev => ({
        id: dev.id,
        linkDeviceTerminalID: dev.linkDeviceTerminalID,
        nodes: dev.nodes,
      }));
    }).flat();
  }
  let devices = [];
  devices = Object.values(cables).map(c => ({
    id: c.id,
    linkDeviceTerminalID: c.linkDeviceTerminalID,
    nodes: c.nodes,
  })).concat(linkD);
  linkResults = Object.keys(linkSVs).reduce((links, linkTerminalId) => {
    const device = devices.find(dev => dev.linkDeviceTerminalID === linkTerminalId);
    if (device) {
      const { id, nodes } = device;
      if (id) {
        links[id] = {
          i: extractAggregatedSV(linkSVs[linkTerminalId], 'i_mag'),
          puVoltage: nodes.map(node => nodeResults?.[node]?.puVoltage),
          voltages: getVoltages(nodes, nodeResults),
          actualP: extractAggregatedSV(linkSVs[linkTerminalId], 'p'),
          actualQ: extractAggregatedSV(linkSVs[linkTerminalId], 'q'),
          apparentPower: extractAggregatedSV(linkSVs[linkTerminalId], 's'),
          powerFactor: extractAggregatedSV(linkSVs[linkTerminalId], 'pf'),
          pLosses: extractAggregatedSV(linkSVs[linkTerminalId], 'p_loss'),
          qLosses: extractAggregatedSV(linkSVs[linkTerminalId], 'q_loss'),
        };
      }
    }
    return links;
  }, {});
  return linkResults;
};

export const getShuntDeviceResults = (shuntDevices, shuntSVs) => {
  // shunt devices
  const shuntD = Object.values(shuntDevices).filter(sD => Object.keys(sD).length > 0).map(d => {
    const devs = Object.values(d);
    return devs.map(dev => ({
      id: dev.id,
      terminalIdForPQTimeSeries: dev.terminalIdForPQTimeSeries,
    }));
  }).flat();
  const shuntResults = Object.keys(shuntSVs).reduce((shunts, shuntTerminalId) => {
    const shuntDevice = shuntD.find(dev => dev.terminalIdForPQTimeSeries === shuntTerminalId);
    if (shuntDevice) {
      shunts[shuntDevice.id] = {
        actualP: extractAggregatedSV(shuntSVs[shuntTerminalId], 'p'),
        actualQ: extractAggregatedSV(shuntSVs[shuntTerminalId], 'q'),
        apparentPower: extractAggregatedSV(shuntSVs[shuntTerminalId], 's'),
        powerFactor: extractAggregatedSV(shuntSVs[shuntTerminalId], 'pf'),
        pLosses: extractAggregatedSV(shuntSVs[shuntTerminalId], 'p_loss'),
      };
    }
    return shunts;
  }, {});
  return shuntResults;
};

const getNodeVoltages = (voltages, magOrAngle) => {
  const nodeVoltages = {
    A_avg: voltages?.[`v_${magOrAngle}_a_avg`],
    B_avg: voltages?.[`v_${magOrAngle}_b_avg`],
    C_avg: voltages?.[`v_${magOrAngle}_c_avg`],
    A_min: voltages?.[`v_${magOrAngle}_a_min`],
    B_min: voltages?.[`v_${magOrAngle}_b_min`],
    C_min: voltages?.[`v_${magOrAngle}_c_min`],
    A_max: voltages?.[`v_${magOrAngle}_a_max`],
    B_max: voltages?.[`v_${magOrAngle}_b_max`],
    C_max: voltages?.[`v_${magOrAngle}_c_max`],
    ABC_avg_avg: voltages?.[`v_${magOrAngle}_abcavg_avg`],
    ABC_avg_min: voltages?.[`v_${magOrAngle}_abcavg_min`],
    ABC_avg_max: voltages?.[`v_${magOrAngle}_abcavg_max`],
    ABC_min_avg: voltages?.[`v_${magOrAngle}_abcmin_avg`],
    ABC_min_min: voltages?.[`v_${magOrAngle}_abcmin_min`],
    ABC_min_max: voltages?.[`v_${magOrAngle}_abcmin_max`],
    ABC_max_avg: voltages?.[`v_${magOrAngle}_abcmax_avg`],
    ABC_max_min: voltages?.[`v_${magOrAngle}_abcmax_min`],
    ABC_max_max: voltages?.[`v_${magOrAngle}_abcmax_max`],
  };
  // remove any undefined values
  Object.keys(nodeVoltages).forEach(key => (nodeVoltages[key] === undefined ? delete nodeVoltages[key] : {}));
  return nodeVoltages;
};

export const getNodeResults = (connectivityNodes, voltages) => {
  const nodeList = Object.values(connectivityNodes).map(node => ({
    id: node.id,
    topologicalNode: node._topologicalNode,
    baseVoltage: node.baseVoltage, // assumed to be line-line
  }));
  const nodeResults = Object.values(nodeList).reduce((nodes, node) => {
    const nodeVoltageDict = voltages[node.id] ?? voltages[node.topologicalNode];
    if (nodeVoltageDict) {
      const nodeVoltages = getNodeVoltages(nodeVoltageDict, 'mag');
      Object.keys(nodeVoltages).forEach(key => {
        nodeVoltages[key] *= Math.sqrt(3); // convert to line-line
      });
      const nodeAngles = getNodeVoltages(nodeVoltageDict, 'angle');
      nodes[node.id] = {
        voltages: nodeVoltages,
        voltageAngles: nodeAngles,
        puVoltage: calculatePerUnit(node.baseVoltage, nodeVoltages),
      };
    }
    return nodes;
  }, {});

  return nodeResults;
};

export default {
  ACTIVITY_LOG_INTERVAL,
  ACTIVITY_LOG_STATUS,
  ANALYSIS_TYPES,
  TYPE_MAP,
  createGeoJSON,
  extractFeederNodes,
  generateColor,
  getBatteries,
  getCenterCoords,
  getInverterPVs,
  getShortURNCode,
  groupByAsyncMachineTypes,
  groupBySyncMachineTypes,
  extractAggregatedSV,
  getVoltages,
  getLinkDeviceResults,
  getShuntDeviceResults,
  getNodeResults,
};
