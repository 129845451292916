import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from 'components/ExpandableSection';
import RangeSelector from './RangeSelector';
import LayerSelectionButton from './LayerSelectionButton';

class GenerationLoadSection extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      powerGradient: this.createRangeBuckets(this.props.layerOptions),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldOptions = this.props.layerOptions;
    const newOptions = nextProps.layerOptions;
    if (
      oldOptions.colors !== newOptions.colors
      || oldOptions.rangeBreaks !== newOptions.rangeBreaks
    ) {
      this.setState({
        powerGradient: this.createRangeBuckets(newOptions), editId: '',
      });
    }
  }

  // Set up colors and range labels for each checkbox
  createRangeBuckets = (options) => {
    const { colors } = options;

    return [{
      id: '0',
      color: colors[0],
      label: 'Load',
    }, {
      id: '1',
      color: colors[1],
      label: 'Generation',
    }];
  }

  unCheckRange = (e) => {
    const index = parseInt(e.target.value, 10);
    const newSelection = [...this.props.layerOptions.selected];
    newSelection[index] = !newSelection[index];
    this.props.updateLayerOptions('generation_load', { selected: newSelection });
  }

  toggleEditPanel = (id) => {
    this.setState({ editId: id });
  }

  handleLayerUpdate = (updates) => {
    this.props.updateLayerOptions('generation_load', updates);
  }

  render() {
    const button = () => (
      <LayerSelectionButton
        selected={this.props.selected}
        option={{ label: 'Snapshot Allocation', value: 'generation_load' }}
        group="results"
        setSelectedLayer={this.props.setSelectedLayer}
      />
    );

    return (
      <ExpandableSection
        className={classNames({
          'layer-expandable-section': true,
          'selected-layer': this.props.selected,
          'layer-expandable-section--expanded': this.props.expanded,
        })}
        renderHeaderContent={button}
        showToggle={false}
        open={this.props.selected}
      >
        <div className="layer-section">
          <div className="layer-checkbox-group">
            {this.state.powerGradient.length
              && this.state.powerGradient.map(checkbox => (
                <RangeSelector
                  settings={checkbox}
                  key={checkbox.id}
                  selected={this.props.layerOptions.selected[parseInt(checkbox.id, 10)]}
                  onClick={this.unCheckRange}
                  id={parseInt(checkbox.id, 10)}
                  withEdit
                  toggleEdit={this.toggleEditPanel}
                  showEditPanel={checkbox.id === this.state.editId}
                  layerOptions={this.props.layerOptions}
                  handleUpdate={this.handleLayerUpdate}
                  editDisabled={this.state.editId}
                  editColor
                />
              ))}
          </div>
        </div>
      </ExpandableSection>
    );
  }
}

GenerationLoadSection.propTypes = {
  layerOptions: PropTypes.shape({
    selected: PropTypes.array,
    mode: PropTypes.string,
    phase: PropTypes.string,
    colors: PropTypes.array,
    rangeBreaks: PropTypes.array,
  }).isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default GenerationLoadSection;
