import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import asyncActionStates from 'helpers/asyncActionStates';
import { pu } from 'helpers/units';
import ThemeContext from 'helpers/ThemeContext';
import { useRequestEffect } from 'hooks/useRequest';
import NumberInput from '../NumberInput';
import Button from '../Button';

const limitInfo = {
  voltage_lower_limit: {
    label: 'Lower voltage limit',
    type: 'number',
    unit: pu,
    default: 0.94,
    // Disable when voltage limits disabled
    disabled: options => options.dont_constrain_voltages,
  },
  voltage_upper_limit: {
    label: 'Upper voltage limit',
    type: 'number',
    unit: pu,
    default: 1.06,
    // Disable when voltage limits disabled
    disabled: options => options.dont_constrain_voltages,
  },
};

const VoltageLimits = ({
  workspace,
  branch,
  updateContainer,
  substationId,
  isAnalysis,
  simulationOptions,
  setSimulationOptions,
  request,
  disableButton,
}) => {
  const theme = useContext(ThemeContext);
  const [limits, setLimits] = useState({});
  const defaultLimits = {};
  Object.entries(limitInfo).forEach(([limit, info]) => {
    defaultLimits[limit] = info.default;
  });

  const { data: substation, loading, refetch } = useRequestEffect({
    url: `/api/workspace/${workspace}/branch/${branch}/asset_panel/substation/${substationId}`,
    method: 'get',
    refetchOnChange: [workspace, branch, substationId],
    initialData: {},
    toast: {
      error: 'An error occurred loading the substation voltage limits. Default values are displayed',
    },
    disabled: !substationId,
  });

  const getLimit = (limit) => limits?.[limit] ?? substation?.[limit] ?? defaultLimits[limit];

  const upper = getLimit('voltage_upper_limit');
  const lower = getLimit('voltage_lower_limit');
  const validation = {
    voltage_upper_limit: {
      invalid:
        lower >= upper
        || upper >= 1.5,
      required: true,
      gt: lower,
      lt: 1.5,
      validationMessage: `Voltage limit must be between ${lower || limitInfo.voltage_lower_limit.default} and 1.5`,
    },
    voltage_lower_limit: {
      invalid:
        lower >= upper
        || lower <= 0.6,
      gt: 0.6,
      lt: upper,
      required: true,
      validationMessage: `Voltage limit must be between 0.6 and ${upper || limitInfo.voltage_upper_limit.default}`,
    },
  };

  const updateLimits = async () => {
    const updatedLimits = Object.keys(limits).reduce(
      (body, limit) => ({
        ...body,
        [limit]: limits[limit] || limitInfo[limit].default,
      }),
      {},
    );
    await updateContainer('substation', substationId, updatedLimits, 'updateSubstation');
    refetch();
  };

  const saving = request === asyncActionStates.LOADING;
  const anyInvalid = Object.values(validation).some(limit => limit.invalid);
  const showSaveButton = !isAnalysis
    && !anyInvalid
    && (limits
      && substation
      && Object.keys(limits).some(limit => limits[limit] !== substation[limit]));

  if (disableButton) {
    disableButton(false); // To prevent permanent disabling
    if (anyInvalid) {
      disableButton(true);
    }
  }

  if (loading) {
    return (
      <div
        className="asset-loading"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: '0.5',
          marginTop: '37px',
          marginBottom: '37px',
        }}
      >
        Loading Limit data...
      </div>
    );
  }
  return (
    <div>
      <p style={{
        color: 'crimson', display: 'flex', alignItems: 'center', fontSize: '12px',
      }}
      >
        {request === asyncActionStates.NOTALLOWED && (
          <>
            <i className="material-icons edit-values-error__i">warning</i>
            You do not have permission to edit.
          </>
        )}
        {request === asyncActionStates.ERROR && (
          <>
            <i className="material-icons edit-values-error__i">warning</i>
            Failed to update.
          </>
        )}
      </p>
      {Object.entries(limitInfo).map(([limit, info]) => (
        <NumberInput
          step="0.001"
          id={limit}
          key={limit}
          unit={info.unit}
          value={getLimit(limit)}
          onChange={(evt) => {
            setLimits({ ...limits, [limit]: evt.value });
            if (isAnalysis) {
              setSimulationOptions({
                ...simulationOptions,
                [limit]: evt.value,
              });
            }
          }}
          label={info.label}
          {...validation[limit]}
          disabled={saving || (isAnalysis && info.disabled(simulationOptions))}
          theme={theme}
        />
      ))}
      {showSaveButton && (
        <Button
          id="update-limits"
          onClick={updateLimits}
          theme={theme}
          loading={saving}
          disabled={saving || loading}
        >
          Update voltage limits
        </Button>
      )}
    </div>
  );
};

VoltageLimits.defaultProps = {
  isAnalysis: false,
  simulationOptions: undefined,
  setSimulationOptions: undefined,
  request: undefined,
  substationId: undefined,
  disableButton: undefined,
};

VoltageLimits.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  isAnalysis: PropTypes.bool,
  simulationOptions: PropTypes.object,
  setSimulationOptions: PropTypes.func,
  request: PropTypes.number,
  substationId: PropTypes.string,
  updateContainer: PropTypes.func.isRequired,
  disableButton: PropTypes.func,
};

export default VoltageLimits;
