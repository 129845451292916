/**
 * Get the location of a connectivityNode
 * @param  {String} node   ID of a connectivityNode
 * @param  {Object} lookup CIM Instance lookup
 * @return {Object}        The CIM Location for the the ConnectivityNode
 */
const getConnectivityNodeLocation = (node, lookup) => {
  const cncID = lookup[node].references['ConnectivityNode.ConnectivityNodeContainer'];
  const connectivityNodesContainer = cncID && lookup[cncID];
  let location;

  if (connectivityNodesContainer && connectivityNodesContainer.class === 'ConnectivityNodeContainer') {
    location = connectivityNodesContainer.references['PowerSystemResource.Location'];
  } else {
    // Look for a Connector (Junction or BusbarSection)
    let i = 0;
    let busBarLocation;
    const terminals = lookup[node].references['ConnectivityNode.Terminals'];

    for (i = 0; i < terminals.length; i += 1) {
      const t = lookup[terminals[i]];
      // in the case of meshed feeders, some of the terminals may not be present in the network
      // as they are part of another feeder
      if (t) {
        const eq = lookup[t.references['Terminal.ConductingEquipment']];
        if (eq !== undefined) {
          if (eq.class === 'Junction') {
            location = eq.references['PowerSystemResource.Location'];
            break;
          } else if (eq.class === 'BusbarSection') {
            busBarLocation = eq.references['PowerSystemResource.Location'];
          }
        }
      }
    }

    // If there is a Junction location use it, then fallback to the BusbarSection location
    location = location || busBarLocation;
  }
  return lookup[location];
};

/**
 * Get the position of a connectivityNode
 * @param  {String} node   ID of a connectivityNode
 * @param  {Object} lookup CIM Instance lookup
 * @return {Object}        All attributes, including coordinates of node
 */
const getConnectivityNodeCoordinates = (node, lookup) => {
  const location = getConnectivityNodeLocation(node, lookup);
  const position = lookup[location].references['Location.PositionPoints'][0];
  const coordinates = lookup[position].attributes;
  return coordinates;
};

export {
  getConnectivityNodeLocation,
  getConnectivityNodeCoordinates,
};
