import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const CirclePlusIcon: FunctionComponent<IconPropsSchema> = ({
  width = '50px',
  height = '50px',
}) => (
  <svg width={width} height={height} viewBox="0 0 50 50" version="1.1">
    <title>plus copy 6</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01_IDP_RC-Copy-6" transform="translate(-1256.000000, -140.000000)" fillRule="nonzero">
        <g id="Group-4" transform="translate(1233.000000, 140.000000)">
          <g id="plus-copy-6" transform="translate(23.000000, 0.000000)">
            <circle id="Oval" fill="#A4C2F7" cx="25" cy="24" r="24" />
            <ellipse id="Oval" fill="#E3E7F2" cx="25" cy="24" rx="21" ry="24" />
            <path d="M3.8800507,24 C3.8800507,10.7451758 13.3357898,0 25,0 C11.7451758,0 1,10.7451758 1,24 C1,37.2548242 11.7451758,48 25,48 C13.3357898,48 3.8800507,37.2548242 3.8800507,24 Z" id="Path" fill="#FFFFFF" />
            <g id="Group" fill="#428DFF">
              <path d="M25,50 C11.1928711,50 0,38.8071289 0,25 C0,11.1928711 11.1928711,0 25,0 C38.8071289,0 50,11.1928711 50,25 C49.9845703,38.8006836 38.8006836,49.9845703 25,50 Z M25,1.92304688 C12.2549805,1.92304688 1.92304688,12.2549805 1.92304688,25 C1.92304688,37.7450195 12.2549805,48.0769531 25,48.0769531 C37.7450195,48.0769531 48.0769531,37.7450195 48.0769531,25 C48.0623047,12.2610352 37.7389648,1.93769531 25,1.92304688 Z" id="Shape" />
              <path d="M25,38.4615235 C24.7449219,38.4615235 24.5003906,38.3603516 24.3200195,38.1799805 C24.1396484,37.9996094 24.0383789,37.7550781 24.0384765,37.5 L24.0384765,13.4615234 C24.0384765,12.9304688 24.4689453,12.5 25,12.5 C25.5310547,12.5 25.9615235,12.9304687 25.9615235,13.4615234 L25.9615235,37.5 C25.9616211,37.7550781 25.8603516,37.9996094 25.6799805,38.1799805 C25.4996094,38.3603516 25.2550781,38.4615235 25,38.4615235 L25,38.4615235 Z" id="Path" />
              <path d="M37.0192383,25.9615234 L12.9807617,25.9615234 C12.449707,25.9615234 12.0192383,25.5310547 12.0192383,25 C12.0192383,24.4689453 12.449707,24.0384766 12.9807617,24.0384766 L37.0192383,24.0384766 C37.550293,24.0384766 37.9807617,24.4689453 37.9807617,25 C37.9807617,25.5310547 37.550293,25.9615234 37.0192383,25.9615234 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CirclePlusIcon;
