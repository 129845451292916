import React from 'react';
import Switch from './Switch';

// Create a value template for a Disconnector.
// As there are no value difference between Disconnector and Switch, we use the Swtich template
const Disconnector = props => (
  <Switch {...props} />
);

export default Disconnector;
