import React from 'react';
import AsynchronousMachine from './AsynchronousMachine';

// Creates a value template for SolarThermal instances
// Uses AsynchronousMachine as that template includes all SolarThermal values
const generatorType = 'PV';
const PhotoVoltaic = props => {
  const newProps = { ...props, generatorType };
  return (
    <AsynchronousMachine {...newProps} />
  );
};

export default PhotoVoltaic;
