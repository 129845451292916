import { useState } from 'react';

type SetTrackingState<T> = (newValue: T) => void

/**
 * similar to use state except it tracks the specified field
 * so the state will be overwritten if the prop changes
 */
export default function useTrackingState<T>(field: T): [T, SetTrackingState<T>, boolean] {
  const [original, setOriginal] = useState<T>(field);
  const [value, setValue] = useState<T>(field);
  if (JSON.stringify(original) !== JSON.stringify(field)) {
    setOriginal(field);
    setValue(field);
  }

  return [value, setValue, value !== field];
}
