import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Analytics from 'helpers/Analytics';

import DocumentationLink from 'components/DocumentationLink';
import RadioButtonGroup from 'components/RadioButtonGroup';
import IconButton from 'components/IconButton';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { logout } from 'helpers/Auth';
import './SettingsMenu.scss';

const SettingsMenu = ({
  actions, theme, authProvider, showTour,
  authEnabled, productDetails, workspace,
  view, inEditMode,
}) => {
  const [settingsMenu, setSettingsMenu] = useState(false);
  const {
    settings, setSettings, saveSettings, settingsModified,
  } = useContext(WorkspaceSettingsContext);

  const handleChangeTheme = (e) => {
    const newTheme = e.target.value;
    const oldTheme = theme;

    if (newTheme !== oldTheme) {
      actions.changeTheme(newTheme);
      Analytics.logEvent('Change Theme', 'Settings Menu', newTheme);
    }
  };

  const handleLogoutClick = () => {
    logout(authProvider);
    Analytics.logEvent('Log Out', 'Settings Menu');
  };

  const closeMenu = useCallback(() => {
    setSettingsMenu(false);
  }, []);

  const showReactTour = () => {
    localStorage.setItem('tour', 'show');
    showTour();
    Analytics.logEvent('Guided Tour', 'Settings Menu');
  };

  useEffect(() => {
    actions.getAuthProvider();
  }, [actions]);

  useEffect(() => {
    // we want to save the mapMode if it changes since there is no save button
    if (settingsModified.mapMode) {
      // save will silently fail as its async
      saveSettings();
    }
  }, [saveSettings, settingsModified]);

  useEffect(() => {
    // add a screenwide click handler so that the menu closes
    // anytime a click occurs
    if (settingsMenu) {
      document.addEventListener('click', closeMenu);
    } else {
      document.removeEventListener('click', closeMenu);
    }
  }, [settingsMenu, closeMenu]);

  const mapModeOptions = [
    { id: 'off', label: 'Off' },
    { id: '2d', label: 'Normal' },
    { id: '3d', label: '3D' },
  ];
  if (settings.mapboxSource?.length) {
    mapModeOptions.push({ id: 'custom', label: 'Custom' });
  }

  return (
    <div className={`settings ${theme}`}>
      <IconButton
        className={`settings__btn ${settingsMenu ? 'settings__btn--active' : ''}`}
        icon="settings"
        onClick={() => setSettingsMenu(!settingsMenu)}
        tooltip="Settings"
        theme={theme}
      />
      <div className={`settings-container ${settingsMenu ? 'settings-container--active' : ''}`}>
        <div className="settings-tile settings-tile--help">
          <DocumentationLink
            className="settings-link"
            onClick={() => Analytics.logEvent('Product Manual', 'Settings Menu')}
          >
            Help
          </DocumentationLink>
        </div>
        <div className="settings-tile settings-tile--service">
          <Link
            className="settings-link"
            onClick={() => {
              Analytics.logEvent('Analysis Settings', 'Settings Menu');
            }}
            to={`${
              workspace !== undefined ? `/settings/${workspace}` : '/settings'
            }`}
          >
            Settings
          </Link>
        </div>
        <div className="settings-tile settings-tile--service">
          <a
            className="settings-link"
            href="https://opusonesolutions.atlassian.net/servicedesk/customer/portal/5"
            onClick={() => {
              Analytics.logEvent('Report an Issue', 'Settings Menu');
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            Report an Issue
          </a>
        </div>
        <div className="settings-tile">
          <PermissionDisabledTip
            hide={view === 'gis' && !inEditMode}
            title="Guided Tour"
            theme={theme}
            message={inEditMode ? 'You must leave edit mode to start the tour' : 'You must be in Analysis View to start the tour'}
          >
            <button
              id="tour"
              className="settings-tour__btn"
              onClick={showReactTour}
              disabled={view !== 'gis' || inEditMode}
              type="button"
            >
              Guided Tour
            </button>
          </PermissionDisabledTip>
        </div>
        { authEnabled
          && (
          <div className="settings-tile">
            <button className="settings-logout__btn" onClick={handleLogoutClick} type="button">
              <i className="material-icons logout-icon" aria-hidden="true">exit_to_app</i>
              Log Out
            </button>
          </div>
          )}
        <>
          <div className="settings-separator" />
          <div className="settings-tile-theme">
            <h3 className="settings-tile-title">Theme</h3>
            <RadioButtonGroup
              options={[{ id: 'dark', label: 'Dark' }, { id: 'light', label: 'Light' }]}
              id="theme-selector"
              theme={theme}
              value={theme}
              listType="column"
              onChange={handleChangeTheme}
            />
          </div>
          {workspace && (
            // only show the mapMode selector in a workspace
            <div className="settings-tile-theme">
              <h3 className="settings-tile-title">Map Mode</h3>
              <RadioButtonGroup
                options={mapModeOptions}
                id="map-selector"
                theme={theme}
                value={settings.mapMode}
                listType="column"
                onChange={async ({ target }) => {
                  setSettings({ mapMode: target.value });
                  Analytics.logEvent('Change Map Mode', 'Settings Menu', target.value);
                }}
              />
            </div>
          )}
        </>
        <div className="settings-separator" />
        <div className="settings-version-container settings-version-container--EQL">
          <img className="settings-logo" src="/gridOS-small.png" alt="GridOS logo" />
          <p className="settings-version">
            {`Version: ${productDetails.version}`}
          </p>
        </div>
      </div>

    </div>
  );
};

SettingsMenu.defaultProps = {
  workspace: undefined,
};

SettingsMenu.propTypes = {
  actions: PropTypes.shape({
    changeTheme: PropTypes.func,
    getAuthProvider: PropTypes.func,
    changeMapMode: PropTypes.func,
  }).isRequired,
  authEnabled: PropTypes.bool.isRequired,
  authProvider: PropTypes.string.isRequired,
  productDetails: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']).isRequired,
  showTour: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  workspace: PropTypes.string,
};

export default SettingsMenu;
