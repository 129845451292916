import React from 'react';
import { useParams } from 'react-router-dom';
import NetworkTopNav from '../../../../../containers/NetworkTopNavContainer';

import './ImportNetwork.scss';

const ImportNetwork = () => (
  <>
    <NetworkTopNav params={useParams()} />
    <div className="import-network">
      Network import page
    </div>
  </>
);

export default ImportNetwork;
