/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// Need to allow key down on the container and click on the zoom button.

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Track extends PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.source.percent !== this.props.source.percent) {
      this.track.focus();
    }
  }

  // Move the track when the left and right arrows are pressed.
  // Each arrow press should also trigger the onDrop custom callback
  onKeyDown = (e) => {
    const { key } = e;
    if (key === 'ArrowRight') {
      const range = this.props.moveTrack(1);
      this.props.onDrop(range);
    } else if (key === 'ArrowLeft') {
      const range = this.props.moveTrack(-1);
      this.props.onDrop(range);
    }
  }

  render() {
    const highlightWidth = this.props.target.value - this.props.source.value;

    return (
      <div
        className="simple-range-slider-track"
        style={{
          backgroundColor: this.props.highlightColor,
          left: `${this.props.source.percent}%`,
          width: `${this.props.target.percent - this.props.source.percent}%`,
        }}
        ref={(e) => { this.track = e; }}
        onKeyDown={this.onKeyDown}
        tabIndex={0}
      >
        {this.props.showZoom && highlightWidth === 1 && (
          <button
            className="zoom-button"
            onClick={this.props.onZoomIn}
            type="button"
          >
            <i className="material-icons">
              zoom_in
            </i>
          </button>
        )}
      </div>
    );
  }
}

Track.defaultProps = {
  onZoomIn: () => null,
};

Track.propTypes = {
  moveTrack: PropTypes.func.isRequired,
  source: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  highlightColor: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  showZoom: PropTypes.bool.isRequired,
  onZoomIn: PropTypes.func,
};

export default Track;
