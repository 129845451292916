import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

import { ohmsPerMeter, ohmsPerKiloMeter, ohmsPerMile } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import AssetSingleSelect from '../../Inputs/AssetSingleSelect';
import './ImpedanceGrid.scss';
import SingleEditableValue from '../../Inputs/SingleEditableValue';

const UNITS = {
  [ohmsPerMeter]: 1,
  [ohmsPerKiloMeter]: 1000.00,
  [ohmsPerMile]: 1609.34, // number of meters in a mile
};

const ImpedanceGrid = ({
  disabled,
  handleSave,
  impedances,
  inEditMode,
}) => {
  const [unit, setUnit] = useState(ohmsPerMeter);
  const [impedanceMatrix, setImpedanceMatrix] = useTrackingState(impedances);

  const setImpedances = (editedImpedance, seqNumber, index) => {
    const newImpedate = {
      ...impedanceMatrix,
      [seqNumber]: [
        index === 0 ? editedImpedance : impedanceMatrix[seqNumber][0],
        index === 1 ? editedImpedance : impedanceMatrix[seqNumber][1],
      ],
    };
    setImpedanceMatrix(newImpedate);
  };

  const hasValidImpedances = impedances && Object.values(impedances).every(
    keys => Number.isFinite(keys[0]) && Number.isFinite(keys[1]),
  );

  // Create a phase header cell
  const createHeader = phase => <div className="impedance-cell impedance-header">{phase}</div>;

  // Lookup to generate unique key names
  const phaseLookup = { 0: 'A', 1: 'B', 2: 'C' };

  // Create a single cell with a value in it
  const createValueCell = (phase, seqNumber, i) => (
    <div key={`val-${phase}-${phaseLookup[i]}`} className="impedance-value-part">
      {['r', 'x'].map((value, index) => (
        <div key={value} style={{ display: 'flex' }}>
          <div style={{ width: '2px' }}>{value === 'r' ? '' : ('j')}</div>
          <SingleEditableValue
            id={`imp-val-${value}-${i}-${seqNumber}`}
            label=""
            type="number"
            precision={6}
            value={impedanceMatrix?.[seqNumber]?.[index]}
            disabled={disabled}
            divisor={1 / UNITS[unit]}
            onBlur={() => handleSave({ impedances: impedanceMatrix })}
            onChange={val => setImpedances(val, seqNumber, index)}
            edited={impedanceMatrix?.[seqNumber]?.[index] !== impedances?.[seqNumber]?.[index]}
            invalid={
              Number.isNaN(parseFloat(impedanceMatrix?.[seqNumber]?.[index]))
              || impedanceMatrix?.[seqNumber]?.[index] < 0
            }
            validationMessage="Must be >= 0"
          />
        </div>
      ))}
    </div>
  );

  // Create the value cells for a row from the provided list
  const createRowValues = (phase, seqNumbers) => (
    seqNumbers.map((seqNumber, i) => {
      if (seqNumber === null || impedances[seqNumber] === undefined) {
        return <div key={`null-${phase}-${seqNumber}`} className="impedance-cell impedance-empty" />;
      }
      return createValueCell(phase, seqNumber, i);
    })
  );

  return (
    <div className="impedance-container">
      <AssetSingleSelect
        label="Impedance"
        key="units"
        options={[
          { value: ohmsPerMeter, label: ohmsPerMeter },
          { value: ohmsPerKiloMeter, label: ohmsPerKiloMeter },
          { value: ohmsPerMile, label: ohmsPerMile },
        ]}
        value={unit}
        onChange={setUnit}
        id="impedance-unit-select"
      />
      <div className="impedance-grid">
        {!hasValidImpedances
          && (
          <div className="overlay">
            <p>An error occurred calculating impedance values.</p>
          </div>
          )}
        <div className="impedance-row">
          <div className="impedance-cell" />
          {createHeader('A')}
          {createHeader('B')}
          {createHeader('C')}
        </div>
        <div className="impedance-row">
          {createHeader('A')}
          {hasValidImpedances && createRowValues('A', [1, 2, 3])}
        </div>
        <div className="impedance-row">
          {createHeader('B')}
          {/* use a fake sequence id for empty rows so react has a unique key */}
          {hasValidImpedances && createRowValues('B', ['b0', 4, 5])}
        </div>
        <div className="impedance-row">
          {createHeader('C')}
          {/* use a fake sequence id for empty rows so react has a unique key */}
          {hasValidImpedances && createRowValues('C', ['c0', 'c1', 6])}
        </div>
      </div>
      {inEditMode && (
        <div className="recalc-container input-spacing">
          <Button
            type="text"
            label="Recalculate Impedance"
            className="recalc-button"
            onClick={() => handleSave({ impedances: null })}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

ImpedanceGrid.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  impedances: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
};

export default ImpedanceGrid;
