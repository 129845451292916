import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import './TimeSeriesChart.scss';

const ResultsChartCard = ({
  title, state, message, children, theme,
}) => (
  <Card hideTitle className="time-series-chart" theme={theme}>
    <h4>{title}</h4>
    {state === 'loaded' && children}
    {state !== 'loaded'
      && (
      <div className="graph-placeholder">
        {state === 'loading'
          && (
          <>
            <h3>{message ?? 'Loading...'}</h3>
            <i className="material-icons rotate" style={{ fontSize: 40 }}>refresh</i>
          </>
          )}
        {state === 'initial'
          && <p>{message ?? 'No results available for time range'}</p>}
        {state === 'error'
          && <p>{message ?? 'An error occured while loading this chart'}</p>}
      </div>
      )}
  </Card>
);

ResultsChartCard.defaultProps = {
  message: undefined,
};

ResultsChartCard.propTypes = {
  theme: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  state: PropTypes.oneOf(['initial', 'loading', 'loaded', 'error']).isRequired,
  message: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ResultsChartCard;
