import React from 'react';
import SynchronousMachine from './SynchronousMachine';

// Creates a value template for RunOfRiverHydro instances
// Uses SynchronousMachine as that template includes all RunOfRiverHydro values
const RunOfRiverHydro = props => (
  <SynchronousMachine {...props} />
);

export default RunOfRiverHydro;
