import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import asyncStates from 'helpers/asyncActionStates';

import ThemeContext from 'helpers/ThemeContext';
import { kVAr, kW } from 'helpers/units';

import LifecycleSection from '../partials/LifecycleSection';
import InstanceInfo from './InstanceInfo';
import AssetSchedule from '../partials/AssetSchedule';
import CustomerProgramSelector from '../partials/CustomerProgramSelector';
import EditableControlMode from '../partials/EditableControlMode';

// Control Panel
const controlHelp = {
  uncontrolled:
    "During powerflow and timeseries powerflow, synchronous machines set to 'fixed' operate at a constant power.",
};

const controlPanelValues = {
  uncontrolled: [
    {
      id: 'p', key: 'p', label: 'Real Power', unit: kW, divisor: -1000, type: 'number',
    },
    {
      id: 'q',
      key: 'q',
      label: 'Reactive Power',
      unit: kVAr,
      divisor: -1000,
      type: 'number',
    },
  ],
  globallyOptimized: [
    {
      id: 'maxOperatingP',
      key: 'maxOperatingP',
      label: 'Max Operating Power',
      unit: kW,
      divisor: 1000,
      type: 'number',
    },
    {
      id: 'minOperatingP',
      key: 'minOperatingP',
      label: 'Min Operating Power',
      unit: kW,
      divisor: 1000,
      type: 'number',
    },
  ],
};

const controlTypes = [
  { value: 'uncontrolled', label: 'Fixed' },
  { value: 'locallyControlled', disabled: true, label: 'Local' },
  { value: 'globallyOptimized', label: 'Global' },
  { value: 'scheduled', disabled: false, label: 'Schedule' },
];

function SynchronousMachine({
  asset: synchMachine,
  inEditMode,
  canEditNetwork,
  expanded,
  selected: { id, class: assetClass },
  applyDifferenceModelRequest,
  editActions,
  workspace,
  branch,
  displayBranch,
  toggleFeederPanel,
  loadForecast: { selectedScenario, selectedScenarioType },
  setSelectedAssetID,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  authEnabled,
  permissions,
}) {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const saveReq = body => editActions.editSingleEquipment(workspace, branch, 'synchronous_machine', id, body);

  const handleLifeCycleChange = lifecycle => saveReq({ lifecycle, phases: synchMachine.phases });

  const controlMode = synchMachine.analysis_control.mode;
  const controlModeAttributes = {
    ...synchMachine.attributes,
    ...synchMachine.generating_unit,
  };

  return (
    <>
      <div
        className={classNames({
          'asset-panel-values': true,
          'asset-panel-values--expanded': expanded,
        })}
      >
        <InstanceInfo
          key={id}
          asset={synchMachine}
          assetId={id}
          inEditMode={inEditMode}
          toggleFeederPanel={toggleFeederPanel}
          editSingleEquipment={editActions.editSingleEquipment}
          displayBranch={displayBranch}
          branch={branch}
          workspace={workspace}
          disabled={!canEditNetwork || saving}
          setSelectedAssetID={setSelectedAssetID}
          handleSave={saveReq}
          theme={theme}
          assetClass={assetClass}
        />

        {!inEditMode
        && (
        <>
          <hr className="section-divider" />
          <AssetSchedule
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Cost"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            editable={!authEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        </>
        )}
        <hr className="section-divider" />

        {!inEditMode && permissions.has('get_der_program_mapping') && (
          <section id="customer-program-section">
            <h2 className="title-text">Customer Program</h2>
            <CustomerProgramSelector
              workspace={workspace}
              branch={branch}
              scenarioId={selectedScenario}
              assetId={id}
              customerPrograms={synchMachine.customer_agreements ?? []}
              disabled={!selectedScenario || !permissions.has('alter_der_program_mapping')}
            />
          </section>
        )}

        <hr className="section-divider" />
        <EditableControlMode
          controlMode={controlMode}
          help={controlHelp[controlMode]}
          asset={assetClass}
          editableValues={controlPanelValues[controlMode]}
          controlModeOptions={controlTypes}
          attributes={controlModeAttributes}
          disabled={saving || !canEditNetwork}
          onSave={saveReq}
          theme={theme}
          attributeTypeMapping={{
            maxOperatingP: 'generating_unit_attributes',
            minOperatingP: 'generating_unit_attributes',
          }}
        >
          {controlMode === 'scheduled' && !inEditMode && (
            <AssetSchedule
              workspace={workspace}
              branch={branch}
              scenario={selectedScenario}
              scenarioType={selectedScenarioType}
              asset={{ id, class: assetClass }}
              scheduleType="Normal"
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              panelValues={{ ...synchMachine.attributes, ...synchMachine.generating_unit }}
              editable={!authEnabled || permissions.has('modify_asset_schedule')}
            />
          )}
        </EditableControlMode>

        <LifecycleSection
          disabled={saving || !inEditMode || !canEditNetwork}
          lifecycle={synchMachine.lifecycle}
          onChange={handleLifeCycleChange}
          healthMetric={synchMachine.reliability_info?.healthMetric}
          onHealthMetricChange={saveReq}
        />
      </div>
    </>
  );
}

SynchronousMachine.propTypes = {
  asset: PropTypes.object.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
  }).isRequired,
  displayBranch: PropTypes.string.isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  editActions: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default SynchronousMachine;
