import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ImportWizardNav.scss';

const ImportWizardNav = ({
  branch,
  path,
  workspace,
}) => {
  const baseURL = `/${workspace}/${branch}/import`;
  const onImport = path.startsWith(`${baseURL}/import`);
  const onValidate = path.startsWith(`${baseURL}/validate`);
  const onReview = path.startsWith(`${baseURL}/review`);

  return (
    <div className="import-wizard-nav">
      <Link
        className="import-nav-link"
        to={`/${workspace}/${branch}/import/import`}
      >
        <div
          className={classNames('import-wizard-nav-step', { 'import-wizard-nav-step--active': onImport || onValidate || onReview })}
        >
          <span className="nav-text">Import</span>
        </div>
      </Link>
      <Link
        className="import-nav-link"
        to={`/${workspace}/${branch}/import/validate`}
      >
        <div
          className={classNames('import-wizard-nav-step', { 'import-wizard-nav-step--active': onValidate || onReview })}
        >
          <span className="nav-text">Validate &amp; Fix</span>
        </div>
      </Link>
      <Link
        className="import-nav-link"
        to={`/${workspace}/${branch}/import/review`}
      >
        <div
          className={classNames('import-wizard-nav-step', { 'import-wizard-nav-step--active': onReview })}
        >
          <span className="nav-text">Review</span>
        </div>
      </Link>
    </div>
  );
};

ImportWizardNav.propTypes = {
  branch: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
};

export default ImportWizardNav;
