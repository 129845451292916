export default function getControlModeName(className: string, controlMode: string): string {
  const names: {[key: string]: {[key: string]: string}} = {
    EnergySource: {
      fixedValue: 'Fixed',
      attachedSchedule: 'Schedule',
      analysisSchedule: 'Previous Analysis',
    },
    EquivalentSubstation: {
      fixedValue: 'Allocate',
      attachedSchedule: 'Schedule',
      analysisSchedule: 'Previous Analysis',
    },
  };

  return names?.[className]?.[controlMode] ?? controlMode;
}

export const controlModesList:{[key: string]: string} = {
  fixedValue: 'Fixed Value',
  attachedSchedule: 'Attached Schedule',
  analysisSchedule: 'Previous Analysis',
  globallyOptimized: 'Global',
  scheduled: 'Schedule',
  uncontrolled: 'Uncontrolled',
  locallyControlled: 'Local',
  fixed: 'Fixed',
};
