export const getIconColor = (theme, color = null) => {
  let iconColor = null;
  if (color) {
    iconColor = color;
  } else {
    iconColor = theme === 'dark' ? '#fff' : '#000';
  }
  return iconColor;
};

const getFormattedCurrency = (formatter, value) => {
  const parts = formatter.formatToParts(value);

  const currency = parts.find(x => x.type === 'currency').value;
  const anyNaN = parts.find(x => x.type === 'nan');

  return {
    currencySymbol: currency,
    formattedValue: anyNaN ? '-' : parts.filter(x => x.type !== 'currency').map(x => x.value).join(''),
  };
};

export default getFormattedCurrency;
