import Request from 'helpers/Request';

class EnvironmentInfo {
  constructor(environment, version, googleAnalytics, apmUrl, authProvider) {
    this.environment = environment;
    this.version = version;
    this.GA = googleAnalytics;
    this.apmUrl = apmUrl;
    this.authProvider = authProvider;
  }
}

let environmentInfo = null;
let requestPromise = null;

const getEnvInfo = async () => {
  // if already resolved, just return the info
  if (environmentInfo) {
    return environmentInfo;
  }

  // if a we do not already have a request ongoing, create one.
  if (!requestPromise) {
    const request = new Request('/environment_info.json');
    requestPromise = request.get();
  }

  // return the file data
  try {
    const result = await requestPromise;
    environmentInfo = new EnvironmentInfo(
      result.data.environment,
      result.data.version,
      result.data.GA,
      result.data.APM,
      result.data.use_auth0 ? 'auth0' : 'keycloak',
    );

    localStorage.setItem('app-environment', environmentInfo.environment);

    return environmentInfo;
  } catch (ex) {
    throw new Error('Unable to get environment info');
  }
};

/**
 * Get the environment info for the current environment
 * @returns {Promise<EnvironmentInfo>} Returns the environment info for the current environment
 */
export default getEnvInfo;
