import AssetInfo from './AssetInfo';

class ThermalGeneratingUnitInfo extends AssetInfo {
  get fossilFuelId() {
    return this._fossilFuels.length > 0 ? this._fossilFuels[0].id : null;
  }

  get fossilFuelType() {
    return this._fossilFuels.length > 0 ? this._fossilFuels[0].attributes['FossilFuel.fossilFuelType'] : null;
  }

  get fuelHeatContent() {
    return this._fossilFuels.length > 0 ? this._fossilFuels[0].attributes['FossilFuel.fuelHeatContent'] : null;
  }

  /* Extract Curve and Fuel References */

  get _fossilFuels() {
    return this.extractReferenceList(this.id, 'ThermalGeneratingUnitInfo.FossilFuels');
  }
}

export default ThermalGeneratingUnitInfo;
