import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';

import { filterTransformers } from 'routes/WorkspaceLayout/routes/Network/helpers/LinkHelpers';
import Helpers from 'routes/WorkspaceLayout/routes/Network/helpers/NetworkHelpers';
import NetworkCIMMapping from './NetworkCIMMapping';
import JSCIM from './JSCIM';

class Network {
  constructor(classes, objects) {
    this._classes = classes;
    this._objects = objects;

    this._extractAssets(classes, objects);
    this._feeders = this._createFeederLookup(classes, objects);
  }

  get lookup() {
    return this._objects;
  }

  get classes() {
    return this._classes;
  }

  get shuntDevices() {
    return this._shuntDevices;
  }

  get linkDevices() {
    return this._linkDevices;
  }

  get connectivityNodes() {
    return this._connectivityNodes;
  }

  get cables() {
    return this._cables;
  }

  get feeders() {
    return this._feeders;
  }

  // Get the min & max of the rated currents in the network
  get ratedCurrentRange() {
    const wireInfos = this._classes.WireInfo;
    if (wireInfos) {
      const ratedCurrents = wireInfos.reduce((list, id) => {
        const ratedCurrent = this._objects[id].attributes['WireInfo.ratedCurrent'];
        if (ratedCurrent) {
          return [...list, ratedCurrent];
        }
        return list;
      }, []);
      return { min: Math.min(...ratedCurrents), max: Math.max(...ratedCurrents) };
    }
    return { min: null, max: null };
  }

  // Get the min & max of the rated currents in the network
  get baseVoltages() {
    let baseVoltagesList = [];

    const baseVoltageIds = this._classes.BaseVoltage;
    if (baseVoltageIds) {
      const baseVoltages = baseVoltageIds.reduce((list, id) => {
        const obj = this._objects[id];
        const baseVoltage = obj && obj.attributes && obj.attributes['BaseVoltage.nominalVoltage'];
        if (baseVoltage) {
          return list.add(baseVoltage);
        }
        return list;
      }, new Set());

      baseVoltagesList = [...baseVoltages];
    }
    return baseVoltagesList;
  }

  // Get the min & max of the rated apparent power in the network
  get ratedSRange() {
    let ratedSList = [];
    const transformerEndInfos = this._classes.TransformerEndInfo;
    const switchInfos = this._classes.SwitchInfo;
    if (transformerEndInfos) {
      ratedSList = transformerEndInfos.reduce((list, id) => {
        const transformerRatedS = this._objects[id].attributes['TransformerEndInfo.ratedS'];
        if (transformerRatedS) {
          return [...list, transformerRatedS];
        }
        return list;
      }, ratedSList);
    }

    if (switchInfos) {
      ratedSList = switchInfos.reduce((list, id) => {
        const ratedCurrent = this._objects[id].attributes['SwitchInfo.ratedCurrent'];
        const ratedVoltage = this._objects[id].attributes['SwitchInfo.ratedVoltage'];
        if (ratedCurrent && ratedVoltage) {
          return [...list, ratedCurrent * ratedVoltage];
        }
        return list;
      }, ratedSList);
    }

    if (ratedSList.length) {
      return { min: Math.min(...ratedSList), max: Math.max(...ratedSList) };
    }
    return { min: null, max: null };
  }

  /**
   * Merge a new feeder into the existing network
   * Creates all of the instances and buckets them according to type
   * @param {Object} classes CIM JSON's classes
   * @param {Object} objects CIM JSON's objectss
   */
  addFeeder(classes, objects) {
    this._objects = merge(this._objects, objects);
    this._classes = mergeWith(this._classes, classes, this._mergeClasses);
    this._connectivityNodes = {
      ...this._connectivityNodes,
      ...this._extractConnectivityNodes(classes, this._objects),
    };
    this._cables = {
      ...this._cables,
      ...this._extractCables(classes, this._objects),
    };
    this._linkDevices = merge(
      {},
      this._linkDevices,
      this._extractLinkDevices(classes, this._objects),
    );
    this._shuntDevices = merge(
      {},
      this._shuntDevices,
      this._extractShuntDevices(classes, this._objects),
    );
    this._feeders = {
      ...this._feeders,
      ...this._createFeederLookup(classes, this._objects),
    };
  }

  // Get all of the infos of a certain class type
  getInfos(type) {
    if (this._classes[type] && JSCIM[type]) {
      return this._classes[type].map(id => new JSCIM[type](id, this._objects));
    }
    return [];
  }

  _updateLookup(id, asset) {
    this._objects[id] = asset;
  }

  /**
   * Merge 2 CIM JSON classes objects
   * Method used by lodash/merge to either add keys to object or add references to array
   */
  _mergeClasses(objValue, srcValue) {
    if (isArray(objValue)) {
      return [...new Set(objValue.concat(srcValue))];
    }
    if (!objValue) {
      return srcValue;
    }
    return objValue;
  }

  _extractAssets(classes, objects) {
    // NOTE - Do Not Change This Load Order!! Object lookup mutated with necessary details.
    this._connectivityNodes = merge(
      {},
      this._connectivityNodes,
      this._extractConnectivityNodes(classes, objects),
    );
    this._cables = merge({}, this._cables, this._extractCables(classes, objects));
    this._linkDevices = merge({}, this._linkDevices, this._extractLinkDevices(classes, objects));
    this._shuntDevices = merge(
      {},
      this._shuntDevices,
      this._extractShuntDevices(classes, objects),
    );
  }

  // Extracts connectivity nodes from the CIM JSON response and creates instances
  _extractConnectivityNodes(classes, objects) {
    if (!classes.ConnectivityNode || !classes.ConnectivityNode.length) {
      return {};
    }

    return this._createInstanceLookup(classes.ConnectivityNode, 'ConnectivityNode', objects);
  }

  // Extracts wires/cables from the CIM JSON response and creates instances
  _extractCables(classes, objects) {
    if (!classes.ACLineSegment || !classes.ACLineSegment.length) {
      return {};
    }

    return this._createInstanceLookup(classes.ACLineSegment, 'ACLineSegment', objects);
  }

  // Extracts link devices from the CIM JSON response and creates instances
  _extractLinkDevices(classes, objects) {
    const {
      Breaker = [],
      Cut = [],
      Disconnector = [],
      Fuse = [],
      Jumper = [],
      Recloser = [],
      Sectionaliser = [],
      Switch = [],
      Regulator = [],
    } = classes;
    const TransformerList = classes.PowerTransformer || [];
    const nonSwitches = [
      ...Fuse,
      ...Recloser,
      ...Breaker,
      ...Sectionaliser,
      ...Cut,
      ...Disconnector,
      ...Jumper,
    ];
    const SwitchList = Switch.filter(s => !nonSwitches.includes(s));
    const { regulators, transformers } = filterTransformers(TransformerList, objects);

    const linkDevices = {
      Breaker,
      Cut,
      Disconnector,
      Fuse,
      Jumper,
      PowerTransformer: transformers,
      Recloser,
      Regulator: [...Regulator, ...regulators],
      Sectionaliser,
      Switch: SwitchList,
    };

    return this._createInstancesByType(linkDevices, objects);
  }

  // Extracts shunt devices from the CIM JSON response and creates instances
  _extractShuntDevices(classes, objects) {
    // Extract Shunt Device IDs from CIM
    const {
      ElectricVehicleChargingStation = [],
      EnergyConsumer = [],
      EnergySource = [],
      EquivalentSubstation = [],
      LinearShuntCompensator = [],
      Battery = [],
      InverterPV = [],
    } = classes;

    // Sort the asyncMachines into their actual types (PV, generator)
    const asyncMachines = Helpers.groupByAsyncMachineTypes({ classes, objects });
    const syncMachines = Helpers.groupBySyncMachineTypes({ classes, objects });
    const batteries = Helpers.getBatteries({ classes, objects });
    const inverterPVs = Helpers.getInverterPVs({ classes, objects });

    const shuntDevices = {
      ElectricVehicleChargingStation,
      EquivalentSubstation,
      EnergyConsumer,
      EnergySource,
      ...syncMachines,
      LinearShuntCompensator,
      Battery: [...Battery, ...batteries],
      ...asyncMachines,
      InverterPV: [...InverterPV, ...inverterPVs],
    };

    return this._createInstancesByType(shuntDevices, objects);
  }

  /**
   * Take list of IDs and create an instance of a JSCIM class for each
   * @param  {Array}  idList  List of UUIDs
   * @param  {String} type    Name of the JSCIM class
   * @param  {Object} objects Lookup of all objects in network
   * @return {Object}         Lookup of all of the newly created instances
   */
  _createInstanceLookup(idList, type, objects) {
    return idList.reduce((lu, cn) => {
      const instance = new JSCIM[type](cn, objects);
      lu[cn] = instance;
      this._updateLookup(cn, instance);
      return lu;
    }, {});
  }

  /**
   * Takes an object containing keys (JSCIM class name) with lists of values (CIM UUIDs)
   * to be converted into JSCIM class instances
   * @param  {Object} devices Lookup of CIM UUIDs keyed on their JSCIM class type
   * @param  {Object} objects Lookup of all CIM objects
   * @return {Object}         Lookup with lists of JSCIM instances keyed on their JSCIM class type
   */
  _createInstancesByType(devices, objects) {
    const deviceTypes = Object.keys(devices);

    return deviceTypes.reduce((lu, type) => {
      if (devices[type]) {
        const instanceList = devices[type].reduce((instanceLU, device) => {
          const instance = new JSCIM[type](device, objects);
          instanceLU[device] = instance;
          this._updateLookup(device, instance);
          return instanceLU;
        }, {});
        lu[type] = instanceList;
      } else {
        lu[type] = {};
      }
      return lu;
    }, {});
  }

  // Creates a lookup of the feeders in the network and associated the
  // EnergySource to the JSCIM Line.
  _createFeederLookup(classes, cimObjects) {
    const lines = classes.Line || [];
    const feeders = classes.Feeder || [];
    const substations = classes.Substation || [];

    const getEnergySource = (container) => {
      if (classes.EnergySource) {
        const sources = classes.EnergySource.map(es => cimObjects[es]).filter(es => es.references['Equipment.EquipmentContainer'] === container);
        if (sources.length > 0) {
          return sources[0];
        }
      } return undefined;
    };

    return {
      ...[...lines, ...feeders].reduce((lu, line) => {
        const instance = new JSCIM.Feeder(line, cimObjects);
        lu[line] = instance;
        this._updateLookup(line, instance);
        lu[line].energySource = getEnergySource(line);
        return lu;
      }, {}),
      ...substations.reduce((lu, sub) => {
        const instance = new JSCIM.Substation(sub, cimObjects);
        lu[sub] = instance;
        this._updateLookup(sub, instance);
        lu[sub].energySource = getEnergySource(sub);
        return lu;
      }, {}),
    };
  }

  // Difference Model application logic
  applyDifferenceModel(diffModel) {
    const {
      set = {}, create = {}, delete: deleteObj = {}, unset = {},
    } = diffModel;
    // Order here mirrors PyCIM
    this._applyDifferenceModelDelete(deleteObj);
    const newAssets = this._applyDifferenceModelCreate(create);
    this._applyDifferenceModelUnset(unset);
    this._applyDifferenceModelSet(set);
    return newAssets;
  }

  /**
   * Apply create portion of the difference model to the network
   * @param  {Object} create Difference model's create
   * @return {Array}         List containing new assets (used to set the selected node on UI)
   */
  _applyDifferenceModelCreate(create) {
    const newAssets = Object.keys(create);
    this._objects = merge(this._objects, create);

    const newDevices = newAssets.reduce((lu, asset) => {
      // repair single refs
      const { references } = this._objects[asset];
      Object.keys(references).forEach((key) => {
        if ([
          'PowerSystemResource.AssetDatasheet',
          'ACLineSegment.PerLengthImpedance',
          'EnergyConsumer.LoadResponse',
          'ConnectivityNode.ConnectivityNodeContainer',
        ].includes(key) && Array.isArray(references[key])) {
          // Convert single sided datasheet ref back to string
          // eslint-disable-next-line prefer-destructuring
          references[key] = references[key][0];
        }
      });

      const type = NetworkCIMMapping(asset, this._objects)[this._objects[asset].class];
      if (type) {
        const { JSCIM: className } = type;
        if (lu[className]) {
          lu[className] = [...lu[className], asset];
        } else {
          lu[className] = [asset];
        }
      }
      return lu;
    }, {});

    this._extractAssets(newDevices, this._objects);
    return Object.values(newDevices).reduce((list, type) => [...list, ...type], []);
  }

  /**
   * Applies the delete portion of the difference model
   * @param  {Object} deleteObj Difference model's delete
   */
  _applyDifferenceModelDelete(deleteObj) {
    const removedAssets = Object.keys(deleteObj);

    removedAssets.forEach((id) => {
      const type = NetworkCIMMapping(id, this._objects)[this._objects[id].class];
      if (type) {
        const { category, JSCIM: className } = type;
        const categoryKey = `_${category}`;

        if (category === 'connectivityNodes' || category === 'cables') {
          delete this[categoryKey][id];
        } else {
          delete this[categoryKey][className][id];
        }
      }
    });

    removedAssets.forEach((id) => {
      // note this is definitely buggy as it only removes the most derived class
      // and only if its using the actual cim class but I don't have a good solution
      // for fixing this aside from the warning do not use the cim classes property
      const assetClass = this._objects[id].class;
      if (this._classes[assetClass]) {
        this._classes[assetClass] = this._classes[assetClass].filter(item => item === id);
      }

      delete this._objects[id];
    });
  }

  /**
   * Applies the set portion of the difference model
   * @param  {Object} set  Difference model's set
   */
  _applyDifferenceModelSet(set) {
    const updatedAssets = Object.keys(set);

    updatedAssets.forEach((idea) => {
      const asset = this._objects[idea];
      if (asset) {
        const { attributes, references } = set[idea];
        asset.attributes = { ...asset.attributes, ...attributes };

        // Iterate over all references and merge with any existing lists of references
        const updatedRefs = Object.keys(references);
        updatedRefs.forEach((ref) => {
          // Handle references that were originally arrays
          if (asset.references[ref] && Array.isArray(asset.references[ref])) {
            // Remove dupes from reference list
            const refSet = new Set([...asset.references[ref], ...references[ref]]);
            asset.references[ref] = [...refSet];
          } else if (
            Array.isArray(references[ref])
            && [
              'PowerSystemResource.AssetDatasheet',
              'ACLineSegment.PerLengthImpedance',
              'EnergyConsumer.LoadResponse',
              'ConnectivityNode.ConnectivityNodeContainer',
            ].includes(ref)
          ) {
            // Convert single sided datasheet ref back to string
            // eslint-disable-next-line prefer-destructuring
            asset.references[ref] = references[ref][0];
          } else {
            asset.references[ref] = references[ref];
          }
        });
      }
    });
  }

  /**
   * Applies the unset portion of the difference model
   * @param  {Object} unset  Difference model's unset
   */
  _applyDifferenceModelUnset(unset) {
    const updatedAssets = Object.keys(unset);

    updatedAssets.forEach((id) => {
      const asset = this._objects[id];
      if (asset) {
        const { attributes, references } = unset[id];
        const attributesToRemove = Object.keys(attributes);
        asset.attributes = omit(asset.attributes, attributesToRemove);

        // Iterate over all references and remove the unneeded references
        const removedRefs = Object.keys(references);
        removedRefs.forEach((ref) => {
          // If the reference exists, remove it from the asset instance
          if (asset.references[ref] && Array.isArray(asset.references[ref])) {
            const existingRefs = new Set(asset.references[ref]);
            const refsToRemove = new Set(references[ref]);
            const difference = new Set([...existingRefs].filter(x => !refsToRemove.has(x)));
            asset.references[ref] = [...difference];
          } else if (asset.references[ref] && typeof asset.references[ref] === 'string') {
            delete asset.references[ref];
          }
        });
      }
    });
  }
}

export default Network;
