import React, { Component } from 'react';
import Button from 'components/Button';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import VoltageDistanceGraph from '../../containers/VoltageDistanceGraphContainer';
import LoadGenDistanceGraph from '../../containers/LoadGenDistanceGraphContainer';
import VoltagePUHistogram from '../../containers/VoltagePUHistogramContainer';
import './AnalysisResultsSection.scss';

const graphTypes = {
  DISTANCE: 'distance',
  TIME: 'time',
  HISTOGRAM: 'histogram',
};

const parameters = {
  VOLTAGE: 'voltage',
  LOAD_GEN: 'loadgen',
};

class AnalysisResultsSection extends Component {
  state = {
    graphType: 'distance',
    parameter: 'voltage',
  }

  getParameterOptions = () => {
    const voltage = { label: 'Voltage', value: parameters.VOLTAGE };
    const loadgen = { label: 'Load & Generation', value: parameters.LOAD_GEN };

    return this.state.graphType === 'distance'
      ? [voltage, loadgen]
      : [voltage];
  }

  getGraphComponent = (graphType, parameter) => {
    if (graphType === graphTypes.DISTANCE && parameter === parameters.VOLTAGE) {
      return <VoltageDistanceGraph />;
    } if (graphType === graphTypes.DISTANCE && parameter === parameters.LOAD_GEN) {
      return <LoadGenDistanceGraph />;
    } if (graphType === graphTypes.HISTOGRAM && parameter === parameters.VOLTAGE) {
      return <VoltagePUHistogram />;
    }
    return null;
  }

  render() {
    const theme = typeof this.context === 'string' ? this.context : 'dark';
    const { graphType, parameter } = this.state;
    const parameterOptions = this.getParameterOptions();

    return (
      <div className="analysis-results-section">
        <h3>Analysis Results</h3>
        <p className="caption-text">See results over...</p>
        <div className="analysis-results-controls">
          <Button
            className="analysis-results-controls-button"
            label="Distance"
            onClick={() => this.setState({
              graphType: graphTypes.DISTANCE,
              parameter: parameterOptions[0].value,
            })}
            type={graphType === graphTypes.DISTANCE ? 'primary' : 'secondary'}
            theme={theme}
          />
          <Button
            className="analysis-results-controls-button"
            label="Histogram"
            onClick={() => this.setState({
              graphType: graphTypes.HISTOGRAM,
              parameter: parameterOptions[0].value,
            })}
            type={graphType === graphTypes.HISTOGRAM ? 'primary' : 'secondary'}
            theme={theme}
          />
        </div>
        <p className="caption-text">With the following parameter</p>
        <Select
          id="parameter-select"
          className="parameter-select"
          theme={theme}
          width={200}
          options={parameterOptions}
          value={parameter}
          onChange={({ value }) => this.setState({ parameter: value })}
          clearable={false}
          searchable={false}
        />
        {this.getGraphComponent(graphType, parameter)}
      </div>
    );
  }
}

AnalysisResultsSection.contextType = ThemeContext;

export default AnalysisResultsSection;
