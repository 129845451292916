import React, { useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import CoreLayoutContainer from 'layouts/CoreLayout/CoreLayoutContainer';

import ProtectedRoute from 'components/ProtectedRoute';
import { requireAuth } from 'helpers/Auth';
import browserHistory from 'routes/history';
import ImportCIM from 'routes/ImportCIM';
import Login from 'routes/Login';
import Profile from 'routes/Profile';
import Settings from 'routes/Settings';
import ServiceDashboard from 'routes/ServiceDashboard';
import WorkspaceLayout from 'routes/WorkspaceLayout';
import WorkspaceSelection from 'routes/WorkspaceSelection';
import LoadingSpinner from 'components/LoadingSpinner';

const ApiDocs = lazy(() => import('routes/ApiDocs'));

const AppRouter = ({
  location,
  store,
}) => {
  const state = store.getState();
  useEffect(() => {
    if (!state.global.authLoaded) {
      requireAuth({ location }, browserHistory.replace, store.dispatch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CoreLayoutContainer>
      <Switch>
        <Route
          exact
          path={[
            '/login',
            '/login/oauth-callback',
            '/:workspace/login',
            '/:workspace/login/oauth-callback',
          ]}
        >
          <Login />
        </Route>
        <ProtectedRoute exact path="/api-docs">
          <Suspense
            fallback={(
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <LoadingSpinner style={{ height: '76px', width: '180px' }} />
              </div>
            )}
          >
            <ApiDocs />
          </Suspense>
        </ProtectedRoute>
        <ProtectedRoute exact path="/health-check">
          <ServiceDashboard />
        </ProtectedRoute>
        <ProtectedRoute
          path={[
            '/settings/:workspace',
            '/settings',
          ]}
        >
          <Settings />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/import-cim/:workspace?/:branch?"
          requiredPermissions={['import_network']}
        >
          <ImportCIM />
        </ProtectedRoute>
        <ProtectedRoute exact path="/profile">
          <Profile />
        </ProtectedRoute>
        <ProtectedRoute exact path="/">
          <WorkspaceSelection />
        </ProtectedRoute>
        {/*
          This route needs to match both "/:workspace" and "/:workspace/:branch"
          because the outer WorkspaceLayout container needs the branch parameter.

          Routes are matched in order, so the more specific route is listed first
        */}
        <ProtectedRoute path={['/:workspace/:branch', '/:workspace']}>
          <WorkspaceLayout />
        </ProtectedRoute>
        <Route
          path="*"
          render={({ history }) => {
            const workspace = localStorage.getItem('authWorkspace');
            history.push(workspace ? `${workspace}/master/gis` : '/');
          }}
        />
      </Switch>
    </CoreLayoutContainer>
  );
};

AppRouter.propTypes = {
  location: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

export default withRouter(AppRouter);
