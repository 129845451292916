import React, { Component } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import Button from 'components/Button';
import RadioButtonGroup from 'components/RadioButtonGroup';
import LinkDevice from 'helpers/JSCIM/Link/LinkDevice';
import asyncActionStates from 'helpers/asyncActionStates';
import { BETA_PERMISSION } from 'helpers/permissions';
import AssetContextProvider from 'contexts/AssetContext';
import { AttachmentsContext } from 'contexts/AttachmentsContext';
import ConfirmDelete from 'components/ConfirmDelete/ConfirmDelete';
import ModalPortal from 'components/ModalPortal';
import LayerPanel from './LayerPanel';
import AssetPanel from '../../containers/AssetPanelContainer';
import ContainerPanel from './ContainerPanel';
import WorkspacePanel from './WorkspacePanel';
import ActivityLogPanel from '../../containers/ActivityLogContainer';
import NoAssetSelected from './NoAssetSelected';
import ValidationPanel from '../../containers/ValidationContainer';
import CreateDevicePanel from '../../containers/CreateDevicePanelContainer';
import ViolationsPanel from '../../containers/ViolationsPanelContainer';
import NetworkNavLinks from '../../../../NetworkTopNav/NetworkNavLinks';

import './SlidingInfoPanel.scss';

// Creates a slide out right rail panel for the Network (GIS) view
class SlidingInfoPanel extends Component {
  state = {
    modalActive: false,
    deleteCalledBy: '',
    deleteMode: 'none',
    notifications: {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { LOADING } = asyncActionStates;
    let deleteStatus = asyncActionStates.INITIAL;
    let nextDeleteStatus = asyncActionStates.INITIAL;
    if (this.state.deleteCalledBy === 'Asset') {
      deleteStatus = this.props.network.deleteStatus;
      nextDeleteStatus = nextProps.network.deleteStatus;
    } else if (this.state.deleteCalledBy === 'Feeder') {
      deleteStatus = this.props.network.deleteStatus;
      nextDeleteStatus = nextProps.network.deleteStatus;
    }
    if (deleteStatus === LOADING && nextDeleteStatus !== LOADING) {
      this.closeModal();
    }

    if (nextProps.powerflow.jobQueued && !this.props.powerflow.jobQueued) {
      this.jobQueuedTimer(5000);
    }
  }

  jobQueuedTimer = (delay) => {
    const panel = this.props.network.panelOptions.active;
    if (panel !== 'activityLog' || (panel === 'activityLog' && !this.props.network.panelOptions.open)) {
      this.setNotification('newActivity', true);
    }
    setTimeout(() => {
      this.props.actions.resetJobQueued();
    }, delay);
  };

  setNotification = (notification, value) => this.setState(prevState => ({
    notifications: { ...prevState.notifications, [notification]: value },
  }));

  getLayerOptions() {
    const layerOptions = [
      { label: 'Per Feeder', value: 'per_feeder' },
    ];

    if (!this.props.authEnabled || this.props.permissions.has(BETA_PERMISSION)) {
      layerOptions.push({ label: 'Reduced Topology', value: 'topology' });
      layerOptions.push({ label: 'Phasing', value: 'phase' });
    }

    return layerOptions;
  }

  handleTabClick = (panel) => {
    // TODO Refactor now that icons are actually on the panel - IDP-4035
    // if (this.props.network.panelOptions.active === panel) {
    //   this.props.actions.toggleAssetPanel();
    // } else {
    //   this.props.actions.setActivePanel(panel);
    // }
    if (panel === 'activityLog') {
      this.setNotification('newActivity', false);
    }
    this.props.actions.setActivePanel(panel);
  };

  closeModal = () => {
    this.setState({
      modalActive: false,
      deleteCalledBy: '',
      deleteMode: 'none',
    });
  };

  showModal = (deleteCalledBy) => {
    this.setState({
      modalActive: true,
      deleteCalledBy,
    });
  };

  deleteItem = () => {
    const { workspace } = this.props;
    const { deleteCalledBy } = this.state;
    if (typeof deleteCalledBy === 'object') {
      const { branch } = this.props.network;
      const { selected } = this.props.feeders;
      this.props.actions.deleteContainer(selected[0], workspace, branch);
    } else if (deleteCalledBy === 'Asset') {
      const { selectedAssetID, branch } = this.props.network;
      const { selectedProject } = this.props;
      this.props.actions.deleteAsset(selectedAssetID, workspace, branch, this.state.deleteMode === 'merge', selectedProject);
    }
  };

  deleteModalConfig = () => {
    const { deleteCalledBy: toDelete } = this.state;
    const { network: { deleteStatus: currDeleteStatus, selectedNode } } = this.props;
    let modalBody = item => ({
      message1: `Once ${item} has been deleted, it cannot be restored.`,
      message2: 'Would you like to permanently remove this item?',
    });
    let item = '';
    const deleteStatus = currDeleteStatus || asyncActionStates.INITIAL;
    let deletedItemDesc = this.state.deleteCalledBy;
    if (typeof toDelete === 'object') {
      return this.containerDeleteModalConfig(toDelete, deleteStatus, modalBody);
    } if (toDelete === 'Asset' && selectedNode instanceof LinkDevice) {
      modalBody = () => ({
        message1: 'The deletion of this asset will create a discontinuity in the network',
        message2: (
          <>
            <p>How would you like to address this?</p>
            <br />
            <RadioButtonGroup
              id="delete-link-confirmation-option"
              options={[{ id: 'merge', label: 'Merge the two nodes' }, { id: 'none', label: 'Leave the nodes as is' }]}
              theme={this.props.theme}
              value={this.state.deleteMode}
              listType="column"
              onChange={({ target }) => this.setState({ deleteMode: target.value })}
            />
          </>
        ),
      });
      deletedItemDesc = 'Link Device';
    } else if (toDelete === 'Asset') {
      item = 'an asset';
    }

    return {
      theme: this.props.theme,
      modalActive: this.state.modalActive,
      closeModal: this.closeModal,
      deleteItem: this.deleteItem,
      deleteStatus,
      deletedItemDesc,
      modalBody: modalBody(item),
    };
  };

  containerDeleteModalConfig = ({ type, id }, deleteStatus, defaultModalBody) => {
    const config = {
      modalActive: this.state.modalActive,
      theme: this.props.theme,
      closeModal: this.closeModal,
      deleteStatus,
      deletedItemDesc: type,
    };

    const feeders = type === 'Substation' && this.props.feeders.list.filter(f => f.substation === id);

    if (!feeders || !feeders.length) {
      return {
        ...config,
        deleteItem: this.deleteItem,
        modalBody: defaultModalBody(`a ${type}`),
      };
    }

    return {
      ...config,
      deleteItem: this.closeModal,
      closeModal: undefined,
      modalBody: {
        message1: 'This Substation has associated Feeders.',
        message2: 'Please delete those first.',
      },
    };
  }

  render() {
    const {
      inEditMode, network, feeders, actions,
    } = this.props;
    const onNetwork = network.leftRailPanel === 'network';
    const { active, expanded } = network.panelOptions;
    const { timeRange, selectedNode } = network;
    const layerActive = active === 'layers';
    const validationActive = active === 'validation';
    const logActive = active === 'activityLog';
    const violationsActive = active === 'violations';
    const workspaceActive = feeders.selected.length === 0 && !layerActive
      && !validationActive && !logActive && !violationsActive;
    const createDeviceOptionsActive = inEditMode && active === 'createDeviceOptions';
    const containerActive = onNetwork
      && !validationActive
      && !logActive
      && !layerActive
      && !createDeviceOptionsActive
      && !violationsActive
      && feeders.selected.length > 0;
    const assetActive = !onNetwork && active === 'asset' && selectedNode;
    const noAssetActive = !onNetwork && active === 'asset' && !selectedNode;
    const showDeleteModal = (
      this.props.network.selectedNode
      || this.props.loadForecast.selectedScenario
      || (this.props.feeders.selected
        && typeof this.state.deleteCalledBy === 'object'
        && (this.state.deleteCalledBy.type === 'Feeder' || this.state.deleteCalledBy.type === 'Substation'))
    );
    const showTooltip = this.props.powerflow.jobQueued
      && (this.props.network.panelOptions.active !== 'activityLog'
        || (this.props.network.panelOptions.active === 'activityLog' && !this.props.network.panelOptions.open));
    return (
      <AssetContextProvider>
        <div id="sliding-panel" className="sliding-panel">
          <div
            className={classNames({
              'expand-panel': true,
              'expand-panel--expanded': this.props.network.panelOptions.expanded,
            })}
          >
            <Button type="text" onClick={actions.expandPanel}>
              <i className="material-icons" style={{ transform: 'rotate(-90deg)' }}>height</i>
              <span style={{ paddingLeft: '10px' }}>{expanded ? 'Minimize panel' : 'Expand panel'}</span>
            </Button>
          </div>
          <div
            className={classNames({
              'panel-icons-row': true,
              'panel-icons-row--expanded': this.props.network.panelOptions.expanded,
            })}
          >
            <NetworkNavLinks
              active={this.props.network.panelOptions.active}
              open={this.props.network.panelOptions.open}
              showTooltip={showTooltip}
              handleMenuSelection={this.handleMenuSelection}
              handleTabClick={this.handleTabClick}
              theme={this.props.theme}
              permissions={this.props.permissions}
              authEnabled={this.props.authEnabled}
              {...this.state.notifications}
            />
          </div>
          <div className="sliding-panel-container">
            {createDeviceOptionsActive && <CreateDevicePanel />}
            {noAssetActive && <NoAssetSelected />}
            {assetActive && (
              <AssetPanel
                zoomToAsset={this.props.zoomToAsset}
                toggleFeederPanel={this.props.actions.toggleFeederPanel}
                setActivePanel={this.props.actions.setActivePanel}
                workspace={this.props.workspace}
                feeders={this.props.match.params.feeder_ids}
                showModal={this.showModal}
                actions={this.props.actions}
                getMap={this.props.getMap}
                panelOptions={this.props.network.panelOptions}
                notes={this.context.notes}
                files={this.context.files}
                selectedNode={selectedNode}
              />
            )}
            {layerActive && (
              <LayerPanel
                selectedLayer={this.props.network.panelOptions.selectedLayer}
                setSelectedLayer={this.props.actions.setSelectedLayer}
                updateLayerOptions={this.props.actions.updateLayerOptions}
                updateLayerAggregation={this.props.actions.updateLayerAggregation}
                layerOptions={this.props.powerflow.layerOptions}
                basicLayerOptions={this.getLayerOptions()}
                powerGradient={this.state.powerGradient}
                unCheckRange={this.unCheckRange}
                showThreePhase={timeRange?.end?.diff(timeRange.start, 'h') <= 24}
                theme={this.props.theme}
                expanded={this.props.network.panelOptions.expanded}
                toggleDirectionalFlow={this.props.actions.toggleDirectionalFlow}
                selectedScenario={this.props.loadForecast.selectedScenario}
                getLayerOptions={this.props.actions.getLayerOptions}
                workspace={this.props.network.workspace}
                branch={this.props.network.branch}
                displayBranch={this.props.network.displayBranch}
                inEditMode={this.props.inEditMode}
                selectedAnalysis={this.props.loadForecast.selectedAnalysis}
              />
            )}
            {containerActive && (
              <ContainerPanel
                showModal={this.showModal}
                onClose={this.props.actions.toggleFeederPanel}
                expanded={this.props.network.panelOptions.expanded}
                selectedAssetID={this.props.network.selectedAssetID}
                selectedAssetViewModelClass={this.props.network.selectedAssetViewModelClass}
              />
            )}
            {workspaceActive && (
              <WorkspacePanel
                actions={this.props.actions}
                authEnabled={this.props.authEnabled}
                permissions={this.props.permissions}
                theme={this.props.theme}
                workspace={this.props.workspace}
                expanded={this.props.network.panelOptions.expanded}
              />
            )}
            {validationActive && (
              <CustomScrollBar>
                <ValidationPanel
                  authEnabled={this.props.authEnabled}
                  permissions={this.props.permissions}
                  selectedFeeders={this.props.feeders.selected}
                  theme={this.props.theme}
                  workspace={this.props.workspace}
                  branch={this.props.network.branch}
                  expanded={this.props.network.panelOptions.expanded}
                />
              </CustomScrollBar>
            )}
            {logActive
              && (
              <ActivityLogPanel
                actions={this.props.actions}
                expanded={this.props.network.panelOptions.expanded}
                theme={this.props.theme}
              />
              )}
            {violationsActive && (
              <ViolationsPanel
                actions={this.props.actions}
                expanded={this.props.network.panelOptions.expanded}
                theme={this.props.theme}
              />
            )}
          </div>
        </div>
        {showDeleteModal && (
          <ModalPortal id="modal-portal">
            <ConfirmDelete {...this.deleteModalConfig()} />
          </ModalPortal>
        )}
      </AssetContextProvider>
    );
  }
}

SlidingInfoPanel.contextType = AttachmentsContext;
SlidingInfoPanel.propTypes = {
  network: PropTypes.shape({
    shunt: PropTypes.shape({
      PhotoVoltaic: PropTypes.object,
      Battery: PropTypes.object,
      InverterPV: PropTypes.object,
      SynchronousMachine: PropTypes.object,
    }).isRequired,
    deleteStatus: PropTypes.number,
    selectedNode: PropTypes.object,
    selectedAssetID: PropTypes.string,
    selectedAssetViewModelClass: PropTypes.string,
    addAssetRequest: PropTypes.number,
    panelOptions: PropTypes.shape({
      open: PropTypes.bool,
      active: PropTypes.string,
      selectedLayer: PropTypes.string,
      expanded: PropTypes.bool,
    }),
    leftRailPanel: PropTypes.string,
    tapPositionTimeSeriesData: PropTypes.array,
    timeRange: PropTypes.shape({
      start: PropTypes.object, // moment datetime
      end: PropTypes.object, // moment datetime
    }).isRequired,
    scenarioStatus: PropTypes.number,
    errors: PropTypes.object.isRequired,
    applyDifferenceModelRequest: PropTypes.object,
    branch: PropTypes.string.isRequired,
    displayBranch: PropTypes.string,
    workspace: PropTypes.string.isRequired,
  }).isRequired,
  powerflow: PropTypes.shape({
    layerOptions: PropTypes.object.isRequired,
    jobQueued: PropTypes.bool.isRequired,
  }).isRequired,
  feeders: PropTypes.shape({
    list: PropTypes.array,
    selected: PropTypes.array,
  }).isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string,
    selectedAnalysis: nullable(PropTypes.shape({
      type: PropTypes.string.isRequired,
    })).isRequired,
    deleteStatus: PropTypes.number,
  }).isRequired,
  actions: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  zoomToAsset: PropTypes.func.isRequired,
  getMap: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  selectedProject: nullable(PropTypes.number).isRequired,
};

export default SlidingInfoPanel;
