import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import './ErrorPage.scss';

const isDev = process.env.NODE_ENV === 'development';

const ErrorPage = props => (
  <div className="error-page-container">
    <div className="error-contents">
      <i className="material-icons">warning</i>
      <h2>Something went wrong</h2>
      {isDev
        && (
        <>
          <h4>Error Type</h4>
          <p>{props.errorInfo.error}</p>
          <h4>Stack Trace</h4>
          <p>{props.errorInfo.info}</p>
        </>
        )}
      <Button
        onClick={props.onButtonClick}
        className="home-btn"
        theme="dark"
        type="primary"
      >
        Go To Home
      </Button>
    </div>
  </div>
);

ErrorPage.defaultProps = {
  errorInfo: {},
};

ErrorPage.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  errorInfo: PropTypes.shape({
    info: PropTypes.string,
    error: PropTypes.string,
  }),
};
export default ErrorPage;
