import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import {
  amps, kV, kVAr, kVln, siemens, VA,
} from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import { transposePhaseAttributes } from '../../helpers/valueExtractors';
import EditablePhaseValues from '../../Inputs/EditablePhaseValues';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import PerPhaseContainer from '../partials/PerPhaseContainer';
import AssetSingleSelect from '../../Inputs/AssetSingleSelect';

const shuntCompensatorInfoFields = [
  {
    label: 'Rated Voltage', key: 'ratedVoltage', unit: kV, options: { divisor: 1000 },
  },
  { label: 'Rated Current', key: 'ratedCurrent', unit: amps },
  {
    label: 'Rated Reactive Power', key: 'ratedReactivePower', unit: kVAr, options: { divisor: 1000 },
  },
  { label: 'Max Power Loss', key: 'maxPowerLoss', unit: VA },
];

const bPerSectionFields = {
  label: 'Susceptance', key: 'bPerSection', unit: siemens, precision: 5,
};
const nominalQFields = {
  label: 'Nominal Power', key: 'nominalQ', unit: kVAr, precision: 3,
};

const getPhaseAttributeDiff = (edited, original) => {
  const phaseDiffs = {};
  Object.keys(original).forEach((phase) => {
    if (original[phase] !== edited[phase]) {
      phaseDiffs[phase] = true;
    }
  });
  return phaseDiffs;
};

function InstanceInfo({
  asset,
  inEditMode,
  disabled,
  theme,
  handleSave,
}) {
  const [nominalVoltage, setNominalVoltage] = useState('');
  const [bPerSection, setBPerSection] = useState('');
  const [
    inService, setInService, setInServiceEdited,
  ] = useTrackingState(asset.attributes.normallyInService);

  useEffect(() => {
    setNominalVoltage(asset.attributes.nomU);
    if (asset.balanced) {
      setBPerSection(asset.attributes?.bPerSection);
    } else {
      setBPerSection(transposePhaseAttributes(asset.phase_attributes).bPerSection);
    }
  }, [asset]);

  return (
    <>
      <SingleEditableValue
        theme={theme}
        type="number"
        id="nomU"
        label="Nominal Voltage"
        value={nominalVoltage}
        edited={nominalVoltage !== asset.attributes.nomU}
        onChange={value => setNominalVoltage(value)}
        onBlur={() => {
          if (nominalVoltage !== asset.attributes.nomU) {
            handleSave({ attributes: { nomU: nominalVoltage } });
          }
        }}
        disabled={disabled || !inEditMode}
        min={1}
        unit={kVln}
        divisor={1000}
      />

      {shuntCompensatorInfoFields.map(field_info => (
        <Input
          type="number"
          inputStyle="panel"
          theme={theme}
          disabled
          key={field_info.key}
          {...field_info}
          value={asset.shunt_compensator_info?.[field_info.key] ?? ''}
        />
      ))}

      <AssetSingleSelect
        id="normallyInService"
        label="Status"
        value={inService ?? true}
        options={[
          { label: 'In Service', value: true },
          { label: 'Out of Service', value: false },
        ]}
        onChange={(value) => {
          setInService(value);
          handleSave({ attributes: { normallyInService: value } });
        }}
        edited={setInServiceEdited}
        disabled={disabled || !inEditMode}
      />

      {asset.balanced ? (
        <>
          {[nominalQFields, bPerSectionFields].map((fields) => (
            <SingleEditableValue
              id={fields.key}
              type="number"
              inputStyle="panel"
              theme={theme}
              disabled={disabled || !inEditMode}
              {...fields}
              value={bPerSection}
              key={fields.key}
              divisor={fields.key === 'nominalQ' ? 1000 / (nominalVoltage ** 2) : 1}
              edited={bPerSection !== asset.attributes.bPerSection}
              onChange={value => setBPerSection(value)}
              onBlur={() => {
                if (bPerSection !== asset.attributes.bPerSection) {
                  handleSave({ attributes: { bPerSection } });
                }
              }}
            />
          ))}
        </>
      ) : (
        <PerPhaseContainer showTotal>
          {[nominalQFields, bPerSectionFields].map((fields) => (
            <EditablePhaseValues
              getTotal={
                (values, divisor) => Object.values(values).reduce((prev, curr) => prev + (curr / divisor), 0)
              }
              id={fields.key}
              key={fields.key}
              type="number"
              inputStyle="panel"
              theme={theme}
              disabled={disabled || !inEditMode}
              {...fields}
              phase={asset.phases}
              {...{ [fields.key]: bPerSection }}
              divisor={fields.key === 'nominalQ' ? 1000 / (nominalVoltage ** 2) : 1}
              edited={{
                [fields.key]: getPhaseAttributeDiff(
                  bPerSection,
                  transposePhaseAttributes(asset.phase_attributes).bPerSection,
                ),
              }}
              onChange={(value, _, phase) => setBPerSection({
                ...bPerSection,
                [phase]: value,
              })}
              onBlur={async () => {
                const phase_attributes = transposePhaseAttributes({ bPerSection });
                await handleSave({ phase_attributes });
              }}
            />
          ))}
        </PerPhaseContainer>
      )}
    </>
  );
}

InstanceInfo.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired, // JSCIM Object
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
};

export default InstanceInfo;
