import React from 'react';
import PropTypes from 'prop-types';

import { kVA, kVll } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';

import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';
import SingleEditableValue from '../../Inputs/SingleEditableValue';

// Editable & summed per phase values to be rendered
const editableValues = [
  {
    id: 'ratedU', label: 'Rated Voltage', unit: kVll, divisor: 1000, type: 'number',
  },
  {
    id: 'ratedS', label: 'Rated Power', unit: kVA, divisor: 1000, type: 'number',
  },
  {
    id: 'ratedPowerFactor', label: 'Rated Power Factor', unit: ' ', divisor: 1, type: 'number',
  },
];

const validationSchema = {
  ratedPowerFactor: { min: -1, max: 1, required: true },
  ratedS: { min: 1, required: true },
  ratedU: { min: 1, required: true },
};

const InstanceInfo = ({
  workspace,
  displayBranch,
  asset,
  handleSave,
  disabled,
  inEditMode,
  generatorType,
}) => {
  const [values, setValues] = useTrackingState(asset.attributes);
  const [windInfo, setWindInfo, windInfoEdited] = useTrackingState(
    asset.generating_unit_info,
  );
  const windOptions = asset.generating_unit_infos ?? [];
  const mappedWindInfoOptions = [
    {
      value: windInfo?.id,
      label: windInfo?.name ?? 'Select Wind',
      disabled: true,
    },
    ...windOptions
      .filter(wind => wind.id !== windInfo?.id)
      .map(wind => ({ value: wind.id, label: wind.name || wind.id })),
  ];

  return (
    <>
      {generatorType === 'Wind' && (
        <EquipmentInfoSelector
          eqLibURL={`/${workspace}/${displayBranch}/library/winds`}
          displayName="Wind"
          assetId={asset.id}
          value={windInfo?.id}
          defaultOptions={mappedWindInfoOptions}
          loadOptions={() => mappedWindInfoOptions}
          id="wind-info-select"
          onChange={({ value: id, label: name }) => {
            setWindInfo({ id, name });
            handleSave({ generating_unit_info: id });
          }}
          disabled={disabled || !inEditMode}
          edited={windInfoEdited}
          feeder={asset.container.id}
        />
      )}
      <div className="editable-section">
        {editableValues.map(field => (
          <div className="input-spacing" key={field.id}>
            <SingleEditableValue
              {...field}
              {...validationSchema[field.id]}
              value={values[field.id]}
              key={field.id}
              onChange={val => setValues({ ...values, [field.id]: val })}
              edited={values[field.id] !== asset.attributes[field.id]}
              onBlur={() => handleSave({ attributes: { [field.id]: values[field.id] } })}
              disabled={disabled || !inEditMode}
            />
          </div>
        ))}
      </div>
    </>
  );
};

InstanceInfo.propTypes = {
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  generatorType: PropTypes.string.isRequired,
};

export default InstanceInfo;
