import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const GroupByPackageIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height,
  width,
}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" height={height} width={width} viewBox="0 0 25 25">
    <g id="Symbol_1_1" transform="translate(7738.5 474.5)">
      <g id="Rectangle_8" transform="translate(621.509 215.509)">
        <path
          fill="none"
          d="M-8352.7-686.9h10.4c2.3,0,4.2,1.9,4.2,4.2v10.4c0,2.3-1.9,4.2-4.2,4.2h-10.4c-2.3,0-4.2-1.9-4.2-4.2v-10.4
          C-8356.9-685-8355-686.9-8352.7-686.9z"
        />
        <path
          fill={color}
          d="M-8342.3-668.1h-10.4c-2.3,0-4.2-1.9-4.2-4.2v-10.4c0-2.3,1.9-4.2,4.2-4.2h10.4c2.3,0,4.2,1.9,4.2,4.2v10.4
          C-8338.1-670-8340-668.1-8342.3-668.1z M-8352.7-684.8c-1.1,0-2.1,0.9-2.1,2.1v10.4c0,1.1,0.9,2.1,2.1,2.1h10.4
          c1.1,0,2.1-0.9,2.1-2.1v-10.4c0-1.1-0.9-2.1-2.1-2.1H-8352.7z"
        />
      </g>
      <polygon
        fill={color}
        points="-7713.5,-460.9 -7713.5,-463 -7725,-463.1 -7725,-474.5 -7727,-474.5 -7727,-463.1 -7738.5,-463
          -7738.5,-461 -7727,-461 -7727,-449.5 -7725,-449.5 -7725,-461"
      />
    </g>
  </svg>
);

export default GroupByPackageIcon;
