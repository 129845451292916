import Request from 'helpers/Request';
import moment from 'moment';

import { ACTIVITY_LOG_STATUS, ANALYSIS_TYPES } from '../helpers/NetworkHelpers';
import { loadForecastActions } from './loadForecast';
import { actions } from './network';

// ------------------------------------
// Constants
// ------------------------------------
export const GET_LOG_ENTRIES_SUCCESS = 'GET_LOG_ENTRIES_SUCCESS';
const GET_LOG_ENTRIES_FAILURE = 'GET_LOG_ENTRIES_FAILURE';
const CANCEL_JOB_SUCCESS = 'CANCEL_JOB_SUCCESS';
const CANCEL_JOB_FAILURE = 'CANCEL_JOB_FAILURE';

const {
  PARTIAL_COMPLETED, COMPLETED, CANCELING,
} = ACTIVITY_LOG_STATUS;

function fetchLogEntries(interval) {
  return async (dispatch, getState) => {
    const state = getState();
    const { workspace, branch } = state.network;
    const scenarioId = state.loadForecast.selectedScenario;
    const analysis = state.loadForecast.selectedAnalysis;

    const url = `/api/workspace/${workspace}/branch/${branch}/activity-log`;
    try {
      const results = await new Request(url).get({
        params: { subjob_limit: 10 },
      });
      const logEntries = results.data;
      const lastChecked = moment.utc().subtract(interval, 'ms');
      const activity = logEntries.find(
        logEntry => logEntry.workspace === workspace
          && logEntry.branch === branch
          && logEntry.scenario_id === scenarioId
          && logEntry.additional_info?.analysis_name === analysis?.name
          && (logEntry.status === COMPLETED || logEntry.status === PARTIAL_COMPLETED)
          && lastChecked.isBefore(moment.utc(logEntry.last_changed)),
      );

      if (activity) {
        dispatch(actions.determineTimeRangeForAnalysis(
          workspace, branch, scenarioId, state.feeders.selected, analysis,
        ));
      }

      const cloningEntry = logEntries.find(entry => entry.activity_type === ANALYSIS_TYPES.CLONING_SCENARIOS) || {};
      let cloningScenarioReq = '';
      if (
        Object.keys(cloningEntry).length > 0
        && cloningEntry.status === COMPLETED
        && state.activityLog.cloningScenarioReq !== COMPLETED
      ) {
        await dispatch(loadForecastActions.fetchScenarios(workspace, branch));
        await dispatch(loadForecastActions.clearSelectedScenario(workspace, branch));
      }

      cloningScenarioReq = Object.keys(cloningEntry) && cloningEntry.status ? cloningEntry.status : '';

      return dispatch({
        type: GET_LOG_ENTRIES_SUCCESS,
        payload: {
          logEntries,
          cloningScenarioReq,
        },
      });
    } catch (err) {
      return dispatch({ type: GET_LOG_ENTRIES_FAILURE });
    }
  };
}

const cancelJob = (workspace, branch, jobId) => (
  async (dispatch) => {
    try {
      const url = `/api/workspace/${workspace}/branch/${branch}/activity/${jobId}/cancel`;
      const req = new Request(url);
      const response = await req.post();

      return dispatch({
        type: CANCEL_JOB_SUCCESS,
        job: jobId,
        payload: response.data,
      });
    } catch (err) {
      return dispatch({
        type: CANCEL_JOB_FAILURE,
        status: err.response ? err.response.status : null,
        job: jobId,
      });
    }
  }
);

const analysisActive = (branch, scenario_id, analysisName) => (_, getState) => {
  const { logEntries } = getState().activityLog;
  const entry = logEntries.find(x => x.branch === branch
      && x.scenario_id === scenario_id
      && x.additional_info.analysis_name === analysisName);
  return [
    ACTIVITY_LOG_STATUS.CANCELING,
    ACTIVITY_LOG_STATUS.PENDING,
    ACTIVITY_LOG_STATUS.POSTPROCESSING,
    ACTIVITY_LOG_STATUS.RUNNING,
  ].includes(entry?.status);
};

export const activityLogActions = {
  fetchLogEntries,
  cancelJob,
  analysisActive,
};

// Reducers
// ----------------------------------------------
export const initialState = {
  logEntries: [],
  lastLogUpdateTime: moment().subtract(1, 'hours'),
  cloningScenarioReq: '',
};

export default function activityLogReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOG_ENTRIES_SUCCESS:
      return {
        ...state,
        logEntries: action.payload.logEntries,
        lastLogUpdateTime: moment(),
        cloningScenarioReq: action.payload.cloningScenarioReq,
      };
    case CANCEL_JOB_SUCCESS:
    {
      // Mark the job as cancled right away so that user sees feedback.
      const modifiedLogEntries = state.logEntries.slice();
      const activity = modifiedLogEntries.find(logEntry => logEntry.job_id === action.job);
      if (activity) {
        activity.status = CANCELING;
      }
      return {
        ...state,
        logEntries: modifiedLogEntries,
      };
    }
    default:
      return state;
  }
}
