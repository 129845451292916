import React, { useContext, Fragment } from 'react';
import propTypes from 'prop-types';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomScrollBar from 'components/CustomScrollBar';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';

import './ContainerSelection.scss';

const yieldChildren = (containerId, containers) => {
  const children = [];
  containers.forEach(container => {
    if (container.substation === containerId || container.upstream_feeder === containerId) {
      children.push(container);
      children.push(...yieldChildren(container.id, containers));
    }
  });
  return children;
};

const sortContainers = (containers) => {
  const sortedContainers = [];

  // do an initial sort
  const sorted = alphabetizeByKey(containers, 'name');
  const topLevel = containers.filter(x => !x.upstream_feeder && !x.substation);
  // create a flat list based on the tree structure
  topLevel.forEach(container => {
    sortedContainers.push(container);
    sortedContainers.push(...yieldChildren(container.id, sorted));
  });

  return sortedContainers;
};

const ContainerSelection = ({
  selectedContainers,
  allContainers,
  addContainer,
  removeContainer,
}) => {
  const theme = useContext(ThemeContext);

  const sortedContainers = sortContainers(allContainers);
  return (
    <div className="container-selection-container">
      <p className="container-selection-label">Include in Analysis?</p>
      <CustomScrollBar alwaysShow className="container-selection-scroll">
        {sortedContainers.map(container => (
          <Fragment key={container.id}>
            <div className="container-selection-item">
              <CustomCheckbox
                id={`container-selector-${container.id}`}
                checked={!!selectedContainers.find(x => x.id === container.id)}
                onClick={() => {
                  if (selectedContainers.find(selected => selected.id === container.id)) {
                    removeContainer(container);
                  } else {
                    addContainer(container);
                  }
                }}
                theme={theme}
              />
              <span className="selection-text">{container.name || container.id}</span>
            </div>
          </Fragment>
        ))}
      </CustomScrollBar>
    </div>
  );
};

ContainerSelection.propTypes = {
  selectedContainers: propTypes.array.isRequired,
  allContainers: propTypes.array.isRequired,
  addContainer: propTypes.func.isRequired,
  removeContainer: propTypes.func.isRequired,
};

export default ContainerSelection;
