import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import AssetContextProvider from 'contexts/AssetContext';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import AnalysisConfiguration from './AnalysisConfiguration';
import './AnalysisModal.scss';

const AnalysisModal = (props) => {
  const { modalActive, toggleModal, hasResults } = props;
  const handleRunPowerflow = (selectedFeeders, analysisName, selectedAnalysisType, range, simulationOptions, objective = 'pf') => {
    const { workspace, branch, selectedScenario } = props;

    if (selectedAnalysisType === ANALYSIS_TYPES.POWERFLOW) {
      props.actions.runPowerFlow(
        workspace,
        branch,
        selectedFeeders,
        selectedScenario,
        analysisName,
        simulationOptions,
      );
    } else {
      props.actions.runQSTSPowerFlow(
        workspace,
        branch,
        selectedFeeders,
        selectedScenario,
        analysisName,
        simulationOptions,
        range,
        objective,
      );
    }
    toggleModal(false);
  };

  const handleRunHostingCapacity = (
    selectedFeeders, analysisName, nodes, phases, hc_type, timeRange, simulationOptions,
  ) => {
    const { workspace, selectedScenario, branch } = props;
    props.actions.runHostingCapacityAnalysis(
      workspace,
      branch,
      selectedFeeders,
      selectedScenario,
      analysisName,
      simulationOptions,
      phases,
      nodes,
      timeRange,
      hc_type,
    );
    return toggleModal(false);
  };

  const handleRunOptimalBattery = (
    selectedFeeders, analysisName, selectedNodes, timeRange, simulationOptions,
  ) => {
    const { workspace, selectedScenario, branch } = props;
    props.actions.runNodalAnalysis(
      workspace,
      branch,
      selectedFeeders,
      selectedScenario,
      analysisName,
      simulationOptions,
      timeRange,
      selectedNodes,
    );
    return toggleModal(false, true);
  };

  const lastSimulationRange = () => {
    const { logEntries } = props;
    const logInfo = logEntries?.[0]?.additional_info ?? {};
    const start = logInfo.start_date ? moment.utc(logInfo.start_date) : null;
    const end = logInfo.end_date ? moment.utc(logInfo.end_date) : null;
    return { start, end };
  };

  return (
    <AssetContextProvider>
      <div className="analysis-modal">
        <Modal
          className="analysis-modal"
          showFooter={false}
          onCancel={() => { toggleModal(false); }}
          showHeader={false}
          active
          theme={props.theme}
        >
          <div className="header">
            <h2>Create New Analysis</h2>
            <IconButton
              icon="close"
              onClick={() => toggleModal(false)}
              tooltip="Close"
              theme={props.theme}
              id="close-modal-btn"
            />
          </div>
          <AnalysisConfiguration
            workspace={props.workspace}
            branch={props.branch}
            selectedScenario={props.selectedScenario}
            selectedScenarioType={props.selectedScenarioType}
            scenarioRange={props.scenarioRange}
            maxRange={props.maxRange}
            lastSimulationRange={lastSimulationRange()}
            theme={props.theme}
            permissions={props.permissions}
            actions={props.actions}
            toggleModal={toggleModal}
            handleRunPowerflow={handleRunPowerflow}
            handleRunHostingCapacity={handleRunHostingCapacity}
            handleRunOptimalBattery={handleRunOptimalBattery}
            hasResults={hasResults}
            scenarioHasData={props.scenarioHasData}
            selectedAsset={{
              id: props.selectedAssetID,
              class: props.selectedAssetViewModelClass,
              name: props.selectedAssetName,
            }}
            key={modalActive ? 'active' : 'inactive'}
            selectedFeeders={props.selectedFeeders}
            feeders={props.feeders}
            usedAnalysisNames={props.usedAnalysisNames}
            analyses={props.analyses}
          />
        </Modal>
      </div>
    </AssetContextProvider>
  );
};

AnalysisModal.defaultProps = {
  theme: 'dark',
  selectedFeeders: [],
  maxRange: {},
  scenarioRange: {},
  selectedScenario: null,
  permissions: null,
  scenarioHasData: false,
  selectedAssetID: '',
  selectedAssetViewModelClass: '',
  selectedAssetName: '',
  workspace: '',
  branch: '',
};

AnalysisModal.propTypes = {
  workspace: PropTypes.string,
  branch: PropTypes.string,
  theme: PropTypes.string,
  selectedFeeders: PropTypes.array,
  feeders: PropTypes.array.isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
  scenarioRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
  selectedScenario: PropTypes.string,
  selectedScenarioType: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  scenarioHasData: PropTypes.bool,
  selectedAssetID: PropTypes.string,
  selectedAssetViewModelClass: PropTypes.string,
  selectedAssetName: PropTypes.string,
  hasResults: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  modalActive: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  usedAnalysisNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  analyses: PropTypes.array.isRequired,
  logEntries: PropTypes.array.isRequired,
};

export default AnalysisModal;
