/* eslint camelcase: 0 */
/* eslint-disable import/no-cycle */
import Request from 'helpers/Request';
import asyncActionStates from 'helpers/asyncActionStates';
import { actions, CLEAR_NETWORK_DATA, CLEAR_ANALYSIS_RESULTS } from './network';
import { getAnalyses, getAnalysisInfo } from '../helpers/NetworkHelpers';

// ------------------------------------
// Constants
// ------------------------------------
const SET_DEFAULTS = 'SET_DEFAULTS';
const SET_SELECTED_VALUE_TYPE = 'SET_SELECTED_VALUE_TYPE';
const UPDATE_PER_PHASE_VALUE = 'UPDATE_PER_PHASE_VALUE';
const START_ALLOCATION_REQUEST = 'START_ALLOCATION_REQUEST';
const ALLOCATION_REQUEST_SUCCESS = 'ALLOCATION_REQUEST_SUCCESS';
const ALLOCATION_REQUEST_FAILURE = 'ALLOCATION_REQUEST_FAILURE';
const RESET_ALLOCATION_VALUES = 'RESET_ALLOCATION_VALUES';
const SET_ALLOCATION_DEFAULTS = 'SET_ALLOCATION_DEFAULTS';
const FETCH_SCENARIOS_LOADING = 'FETCH_SCENARIOS_LOADING';
const FETCH_SCENARIOS_SUCCESS = 'FETCH_SCENARIOS_SUCCESS';
const FETCH_SCENARIOS_FAILURE = 'FETCH_SCENARIOS_FAILURE';
export const UPDATE_SELECTED_SCENARIO_LOADING = 'UPDATE_SELECTED_SCENARIO_LOADING';
const UPDATE_SELECTED_SCENARIO_SUCCESS = 'UPDATE_SELECTED_SCENARIO_SUCCESS';
const UPDATE_SELECTED_SCENARIO_FAILURE = 'UPDATE_SELECTED_SCENARIO_FAILURE';
export const CLEAR_SCENARIO_DATA = 'CLEAR_SCENARIO_DATA';
export const CLEAR_SELECTED_SCENARIO = 'CLEAR_SELECTED_SCENARIO';
export const SCENARIO_HAS_DATA = 'SCENARIO_HAS_DATA';
const SET_SELECTED_ANALYSIS = 'SET_SELECTED_ANALYSIS';

// ------------------------------------
// Actions
// ------------------------------------
function setDefaults() {
  return {
    type: SET_DEFAULTS,
  };
}

function setSelectedValueType(selection) {
  return {
    type: SET_SELECTED_VALUE_TYPE,
    selection,
  };
}

function resetAllocationValues() {
  return {
    type: RESET_ALLOCATION_VALUES,
  };
}

function updatePerPhaseValue(row, col, val, type) {
  const valueType = col === 'net' ? 'net' : 'per-phase';

  let updatedType = type;
  if (!val) {
    updatedType = valueType;
  }
  return {
    type: UPDATE_PER_PHASE_VALUE,
    col,
    row,
    val,
    updatedType,
  };
}

// Get the default values from the load allocator to display in the right panel
function getAllocationDefaults(workspace, feeder) {
  return async (dispatch, getState) => {
    try {
      const { branch } = getState().network;
      const perPhase = await new Request(`/api/workspace/${workspace}/branch/${branch}/allocation/per-phase`).get({
        params: { feeder },
      });
      const net = await new Request(`/api/workspace/${workspace}/branch/${branch}/allocation/net`).get({
        params: { feeder },
      });
      const { der_power_factor_a, der_power_factor_b, der_power_factor_c } = perPhase.data;
      const { load_power_factor_a, load_power_factor_b, load_power_factor_c } = perPhase.data;
      const { p_der_a, p_der_b, p_der_c } = perPhase.data;
      const { p_load_a, p_load_b, p_load_c } = perPhase.data;
      const {
        der_power_factor, load_power_factor, p_der, p_load,
      } = net.data;

      const defaults = {
        loadPF: {
          net: load_power_factor,
          A: load_power_factor_a,
          B: load_power_factor_b,
          C: load_power_factor_c,
        },
        pvPF: {
          net: der_power_factor,
          A: der_power_factor_a,
          B: der_power_factor_b,
          C: der_power_factor_c,
        },
        bulk: {
          net: p_load / 1000,
          A: p_load_a / 1000,
          B: p_load_b / 1000,
          C: p_load_c / 1000,
        },
        DER: {
          net: p_der / 1000,
          A: p_der_a / 1000,
          B: p_der_b / 1000,
          C: p_der_c / 1000,
        },
      };

      dispatch({
        type: SET_ALLOCATION_DEFAULTS,
        payload: defaults,
      });
    } catch (err) {
    }
  };
}

function submitAllocationChange(workspace, feeder, type, values) {
  return (dispatch, getState) => {
    const { branch } = getState().network;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/allocation/${type}?feeder=${feeder.id}`);

    dispatch({
      type: START_ALLOCATION_REQUEST,
    });
    // Create the endpoint (value type) specific post request bodies
    let formatted;
    if (type === 'per-phase') {
      formatted = {
        p_load_a: values.bulk.A * 1000,
        p_load_b: values.bulk.B * 1000,
        p_load_c: values.bulk.C * 1000,
        load_power_factor_a: values.loadPF.A,
        load_power_factor_b: values.loadPF.B,
        load_power_factor_c: values.loadPF.C,
        p_der_a: values.DER.A * 1000,
        p_der_b: values.DER.B * 1000,
        p_der_c: values.DER.C * 1000,
        der_power_factor_a: values.pvPF.A,
        der_power_factor_b: values.pvPF.B,
        der_power_factor_c: values.pvPF.C,
      };
    } else if (type === 'net') {
      formatted = {
        p_load: values.bulk.net * 1000,
        load_power_factor: values.loadPF.net,
        p_der: values.DER.net * 1000,
        der_power_factor: values.pvPF.net,
      };
    }
    return request.post(formatted)
      .then(() => {
        dispatch({
          type: ALLOCATION_REQUEST_SUCCESS,
        });
        dispatch(getAllocationDefaults(workspace, feeder.id));
        dispatch(actions.loadNetworkData(workspace, branch, [feeder.id]));
      })
      .catch(() => {
        dispatch({
          type: ALLOCATION_REQUEST_FAILURE,
        });
      });
  };
}

const fetchScenarios = (workspace, branch) => (
  async (dispatch, getState) => {
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`;

    const oldRequest = getState().loadForecast.fetchScenariosRequest;
    if (oldRequest) {
      oldRequest.cancel();
    }
    const request = new Request(url);
    try {
      dispatch({ type: FETCH_SCENARIOS_LOADING, payload: { request } });
      const results = await request.get();
      const scenarios = results.data.map(val => ({
        value: val.id, label: val.name, type: val.scenario_type,
      }));
      dispatch({
        type: FETCH_SCENARIOS_SUCCESS,
        payload: scenarios,
      });
    } catch (err) {
      if (!request.wasCancelled) {
        dispatch({ type: FETCH_SCENARIOS_FAILURE });
      }
    }
  }
);

const clearSelectedScenario = () => (
  async (dispatch) => {
    await dispatch({ type: CLEAR_SELECTED_SCENARIO });
    await dispatch(actions.setMaxRange(null, null));
    await dispatch(actions.setScenarioRange(null, null));
    await dispatch(actions.clearResults());
  }
);

const updateSelectedScenario = (workspace, branch, id, scenarioType) => (
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SELECTED_SCENARIO_LOADING, payload: { id, scenarioType } });
      await dispatch(actions.clearResults());
      await dispatch(actions.determineTimeRangeForScenario(workspace, branch, id));
      return dispatch({ type: UPDATE_SELECTED_SCENARIO_SUCCESS });
    } catch (err) {
      return dispatch({ type: UPDATE_SELECTED_SCENARIO_FAILURE });
    }
  }
);

function updateAndSelectScenario(workspace, branch, scenario) {
  return async (dispatch) => {
    await dispatch(fetchScenarios(workspace, branch));
    await dispatch(
      updateSelectedScenario(
        workspace,
        branch,
        scenario,
      ),
    );
  };
}

const clearScenarioData = () => ({ type: CLEAR_SCENARIO_DATA });

export const setSelectedAnalysis = (analysis, allFeeders = false) => async (dispatch, getState) => {
  const { workspace, branch } = getState().network;
  const { selectedScenario } = getState().loadForecast;
  const { selected, list } = getState().feeders;
  const { permissions } = getState().global;
  const selectedContainers = allFeeders ? list : selected;
  dispatch(actions.clearResults());

  let selectedAnalysis = analysis;
  let subHourInterval = 5;
  if (typeof analysis === 'string') {
    const analyses = await getAnalyses(
      workspace,
      branch,
      selectedScenario,
      permissions,
    );
    selectedAnalysis = analyses?.find(a => a.id === analysis);
  }
  if (selectedAnalysis && selectedAnalysis?.id) {
    const analysisInfo = await getAnalysisInfo(
      workspace,
      branch,
      selectedAnalysis?.id,
    );
    subHourInterval = analysisInfo?.analysis_configuration?.interval ?? 60;
  }
  dispatch({ type: SET_SELECTED_ANALYSIS, payload: selectedAnalysis, subHourInterval });

  if (analysis) {
    dispatch(actions.determineTimeRangeForAnalysis(
      workspace, branch, selectedScenario, selectedContainers, selectedAnalysis,
    ));
  } else {
    dispatch(actions.determineTimeRangeForScenario(
      workspace, branch, selectedScenario,
    ));
  }
};

export const loadForecastActions = {
  setDefaults,
  submitAllocationChange,
  setSelectedValueType,
  updatePerPhaseValue,
  resetAllocationValues,
  getAllocationDefaults,
  fetchScenarios,
  updateSelectedScenario,
  clearScenarioData,
  clearSelectedScenario,
  updateAndSelectScenario,
  setSelectedAnalysis,
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  bulk: {
    net: 0, A: 0, B: 0, C: 0,
  },
  DER: {
    net: 0, A: 0, B: 0, C: 0,
  },
  loadPF: {
    net: 0.90, A: 0.90, B: 0.90, C: 0.90,
  },
  pvPF: {
    net: 0.90, A: 0.90, B: 0.90, C: 0.90,
  },
  valueType: 'net',
  uploadStatus: 0,
  scenarios: [],
  selectedScenario: '',
  selectedScenarioType: '',
  selectedAnalysis: null,
  scenarioTimeRange: null,
  scenarioHasData: false,
  subHourInterval: 5,
};

export default function allocationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEFAULTS:
      return {
        ...state,
        uploadStatus: 0,
        valueType: 'net',
      };
    case START_ALLOCATION_REQUEST:
      return {
        ...state,
        uploadStatus: asyncActionStates.LOADING,
      };
    case ALLOCATION_REQUEST_SUCCESS:
      return {
        ...state,
        uploadStatus: asyncActionStates.SUCCESS,
      };
    case ALLOCATION_REQUEST_FAILURE:
      return {
        ...state,
        uploadStatus: asyncActionStates.ERROR,
      };
    case SET_SELECTED_VALUE_TYPE:
      return {
        ...state,
        valueType: action.selection,
      };
    case UPDATE_PER_PHASE_VALUE:
      const {
        row, col, val, updatedType,
      } = action;
      return {
        ...state,
        [row]: { ...state[row], [col]: val },
        valueType: updatedType,
      };
    case RESET_ALLOCATION_VALUES:
      return {
        ...state,
        bulk: initialState.bulk,
        DER: initialState.DER,
        loadPF: initialState.loadPF,
        pvPF: initialState.pvPF,
        valueType: 'net',
        uploadStatus: 0,
      };
    case CLEAR_NETWORK_DATA:
      return {
        ...initialState,
      };
    case CLEAR_ANALYSIS_RESULTS:
      return {
        ...state,
        subHourInterval: initialState.subHourInterval,
      };
    case SET_ALLOCATION_DEFAULTS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_SCENARIOS_LOADING:
      return {
        ...state,
        fetchScenariosRequest: action.payload.request,
      };
    case FETCH_SCENARIOS_SUCCESS:
      return {
        ...state,
        scenarios: action.payload,
        scenariosReq: asyncActionStates.SUCCESS,
        fetchScenariosRequest: null,
      };
    case FETCH_SCENARIOS_FAILURE:
      return {
        ...state,
        scenariosReq: asyncActionStates.ERROR,
        fetchScenariosRequest: null,
      };
    case UPDATE_SELECTED_SCENARIO_LOADING:
      return {
        ...state,
        selectedScenario: action.payload.id,
        selectedScenarioType: state.scenarios?.find(item => item.value === action.payload.id)?.type
        || action.payload.scenarioType,
        updateSelectedScenario: asyncActionStates.LOADING,
      };
    case UPDATE_SELECTED_SCENARIO_SUCCESS:
      return {
        ...state,
        updateSelectedScenario: asyncActionStates.SUCCESS,
      };
    case UPDATE_SELECTED_SCENARIO_FAILURE:
      return {
        ...state,
        updateSelectedScenario: asyncActionStates.ERROR,
      };
    case CLEAR_SCENARIO_DATA:
      return {
        ...state,
        scenarios: [],
        selectedScenario: initialState.selectedScenario,
        selectedScenarioType: initialState.selectedScenarioType,
      };
    case CLEAR_SELECTED_SCENARIO:
      return {
        ...state,
        selectedScenario: initialState.selectedScenario,
        selectedScenarioType: initialState.selectedScenarioType,
      };
    case SCENARIO_HAS_DATA:
      return {
        ...state,
        scenarioHasData: action.payload,
      };
    case SET_SELECTED_ANALYSIS:
      return {
        ...state,
        selectedAnalysis: action.payload,
        subHourInterval: action.subHourInterval,
      };
    default:
      return state;
  }
}
