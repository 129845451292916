import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from 'components/Card';
import Select from 'components/Select';
import LoadingSkeleton from 'components/LoadingSkeleton';

import asyncActionStates from 'helpers/asyncActionStates';
import {
  ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Scatter, Dot, BarChart, Bar, Brush, ResponsiveContainer,
} from 'recharts';
import { isDefined, graphHeight } from 'helpers/utils';
import { isHourlyOrSubHourly } from 'components/ZoomableRangeSlider/intervals';
import './DistanceGraph.scss';

const { LOADING } = asyncActionStates;
const MAX_NUM_BUCKETS = 500;

class VoltageDistanceGraph extends Component {
  state = {
    data: [],
    phase: 'ABC_avg',
  }

  componentDidMount() {
    this.getGraphData();
  }

  componentDidUpdate(prevProps) {
    const newNodes = this.props.nodes !== prevProps.nodes;
    const newSVs = this.props.results !== prevProps.results;
    const newDistances = this.props.nodeSubstationDistances !== prevProps.nodeSubstationDistances;

    const loadingResults = (
      this.props.resultsRequest === LOADING
      && prevProps.resultsRequest !== LOADING
    );

    if (newNodes || newSVs || newDistances) {
      this.getGraphData();
    } else if (loadingResults) {
      this.setState({ data: [] });
    }
  }

  getGraphData = () => {
    const { aggType, nodes, results } = this.props;
    const isHourlyOrSub = isHourlyOrSubHourly(aggType);

    if (!results) {
      // Happens when no results are loaded yet to the UI
      return;
    }

    let data = [];
    if (isHourlyOrSub) {
      // If aggregation is hourly, we take just the average value for the selected phase
      for (let i = 0; i < nodes.length; i += 1) {
        const nodeID = nodes[i];
        const distance = this.props.nodeSubstationDistances[nodeID];
        const puVoltage = results?.[nodeID]?.puVoltage ?? {};
        const value = puVoltage[`${this.state.phase}_avg`];
        if (isDefined(distance) && isDefined(value)) {
          data = data.concat({ value, distance });
        }
      }
    } else if (isDefined(aggType)) {
      const maxDistance = Math.max(...Object.values(this.props.nodeSubstationDistances));
      // Create the bucket size based on distance
      // Should be a max of 500 buckets and each bucket is rounded to the nearest 10
      // Min bucket size should be 10 meters.
      const bucketSize = Math.max(Math.ceil((maxDistance / MAX_NUM_BUCKETS) / 10) * 10, 10);
      // If any other aggregation level is selected, bucket the values into
      // 10 meter buckets and determine the min and max values of each distance bucket
      const distanceLookup = {};

      for (let i = 0; i < nodes.length; i += 1) {
        const nodeID = nodes[i];
        const puVoltage = results?.[nodeID]?.puVoltage ?? {};
        let distance = this.props.nodeSubstationDistances[nodeID];
        if (isDefined(distance)) {
          let values = [];
          if (this.state.phase === 'ABC_avg') {
            values = Object.values(puVoltage);
          } else {
            values = [
              puVoltage[`${this.state.phase}_min`],
              puVoltage[`${this.state.phase}_max`],
            ];
          }

          const filteredValues = values.filter(val => isDefined(val));

          if (filteredValues.length > 0) {
            distance = Math.ceil(distance / bucketSize) * bucketSize;

            // If distance bucket already exists determine the new min / max values for that bucket
            if (distanceLookup[distance]) {
              distanceLookup[distance] = [
                Math.min(...filteredValues, ...distanceLookup[distance]),
                Math.max(...filteredValues, ...distanceLookup[distance]),
              ];
            } else {
              distanceLookup[distance] = [Math.min(...filteredValues), Math.max(...filteredValues)];
            }
          }
        }
      }

      data = Object.entries(distanceLookup).map(([distance, values]) => ({
        voltages: values,
        distance,
      }));
    }
    this.setState({ data });
  }

  getScatterTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].value.toFixed(3)}${payload[0].unit}`}</p>
          <p className="label">{`${payload[1].name} : ${payload[1].value.toFixed(3)}${payload[1].unit}`}</p>
        </div>
      );
    }

    return null;
  };

  getBarTooltip = ({ active, payload }) => {
    if (active) {
      const { distance, voltages } = payload[0]?.payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Distance : ${distance}m`}</p>
          <p className="label">{`p.u voltage range : ${voltages[0].toFixed(3)} ~ ${voltages[1].toFixed(3)}`}</p>
        </div>
      );
    }
    return null;
  }

  render() {
    const isHourlyOrSub = isHourlyOrSubHourly(this.props.aggType);
    return (
      <Card theme={this.props.theme} className="distance-graph" hideTitle>
        <p>Phase</p>
        <Select
          options={[
            { label: 'ABC Average', value: 'ABC_avg' },
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' },
          ]}
          value={this.state.phase}
          clearable={false}
          searchable={false}
          className="phase-selector"
          theme={this.props.theme}
          onChange={({ value }) => this.setState({ phase: value }, this.getGraphData)}
        />
        <div className="phase-selection-message">
          {this.state.phase === 'ABC_avg' && <span className="caption-text">Average value of all 3 phases</span>}
        </div>
        <div className="legend">
          <div className="legend-entry pu-distance-legend">
            <div
              className={classNames({
                'axis-box': !isHourlyOrSub,
                'axis-circle': isHourlyOrSub,
              })}
            />
            <div>Per Unit Voltage</div>
          </div>
        </div>
        {(this.state.data.length > 0 && isHourlyOrSub)
          && (
          <ResponsiveContainer width="100%" height={graphHeight(this.props.expanded)}>
            <ScatterChart
              margin={{
                top: 15, right: 20, bottom: 10, left: -10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="distance"
                name="Distance"
                unit="m"
              />
              <YAxis
                type="number"
                tickFormatter={tick => tick.toFixed(3)}
                dataKey="value"
                name="Per Unit Voltage"
                domain={[dataMin => Math.min(dataMin, 0.94), dataMax => Math.max(dataMax, 1.06)]}
              />
              <Tooltip
                content={this.getScatterTooltip}
                cursor={{ strokeDasharray: '3 3' }}
              />
              <Scatter
                data={this.state.data}
                fill="#de6e00"
                name="p.u. voltage"
                shape={props => <Dot {...props} r={3} />}
              />
            </ScatterChart>
          </ResponsiveContainer>
          )}
        { (this.state.data.length > 0 && !isHourlyOrSub)
          && (
          <>
            <ResponsiveContainer width="100%" height={graphHeight(this.props.expanded)}>
              <BarChart
                data={this.state.data}
                margin={{
                  top: 30, right: 20, bottom: 10, left: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="distance"
                  unit="m"
                  name="Distance from Substation"
                />
                <YAxis
                  domain={[dataMin => Math.min(dataMin, 0.94), dataMax => Math.max(dataMax, 1.06)]}
                  tickFormatter={tick => tick.toFixed(3)}
                />
                <Tooltip
                  content={this.getBarTooltip}
                />
                <Bar dataKey="voltages" name="p.u. voltage" fill="#de6e00" barSize={4} />
                <Brush dataKey="distance" height={15} stroke="#606060" />
              </BarChart>
            </ResponsiveContainer>
            <p className="caption-text brush-label">Zoom In / Out</p>
          </>
          )}
        { this.state.data.length === 0
          && (
          <div className="graph-placeholder">
            {(this.props.distanceRequest === LOADING || this.props.resultsRequest === LOADING) ? (
              <LoadingSkeleton template="square" height={225} width={303} />
            )
              : <p>No results available for time range</p>}
          </div>
          )}
      </Card>
    );
  }
}

VoltageDistanceGraph.defaultProps = {
  nodes: [],
  nodeSubstationDistances: {},
  results: {},
  resultsRequest: 0,
  distanceRequest: 0,
  aggType: '',
  expanded: false,
};

VoltageDistanceGraph.propTypes = {
  theme: PropTypes.string.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.string),
  nodeSubstationDistances: PropTypes.object,
  results: PropTypes.object,
  resultsRequest: PropTypes.number,
  distanceRequest: PropTypes.number,
  aggType: PropTypes.string,
  expanded: PropTypes.bool,
};

export default VoltageDistanceGraph;
