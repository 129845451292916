import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import Qs from 'qs';

const { CancelToken } = axios;

class Request {
  cancelToken: CancelTokenSource

  url: string;

  wasCancelled: boolean;

  constructor(url: string) {
    this.url = url;
    this.cancelToken = CancelToken.source();
    this.wasCancelled = false;
  }

  static cancelled = (error: any):boolean => axios.isCancel(error);

  get<T = any>(options: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    return this.catchUnauthorized(axios.get<T>(this.url, this.updateOptions(options)));
  }

  head(options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    return this.catchUnauthorized(axios.head(this.url, this.updateOptions(options)));
  }

  /**
   * Provides default parameters for retrieving a file
   * @param {Object} options the axios options
   */
  getFile(options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.timeout = options.timeout ?? 600000;
    options.responseType = options.responseType ?? 'blob';
    options.headers = options.headers ?? {};
    options.headers['Cache-Control'] = options.headers['Cache-Control'] ?? 'no-cache, no-store';
    options.headers.Pragma = options.headers.Pragma ?? 'no-cache';
    options.headers.Expires = options.headers.Expires ?? '0';
    return this.get(options);
  }

  // Post Request returns a file as Response
  postRequestToGetFile(data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.timeout = options.timeout ?? 600000;
    options.responseType = options.responseType ?? 'blob';
    options.headers = options.headers ?? {};
    options.headers['Cache-Control'] = options.headers['Cache-Control'] ?? 'no-cache, no-store';
    options.headers.Pragma = options.headers.Pragma ?? 'no-cache';
    options.headers.Expires = options.headers.Expires ?? '0';
    return this.post(data, options);
  }

  post(data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    return this.catchUnauthorized(axios.post(this.url, data, this.updateOptions(options)));
  }

  put(data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    return this.catchUnauthorized(axios.put(this.url, data, this.updateOptions(options)));
  }

  patch(data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    return this.catchUnauthorized(axios.patch(this.url, data, this.updateOptions(options)));
  }

  delete(options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    return this.catchUnauthorized(axios.delete(this.url, this.updateOptions(options)));
  }

  deleteWithData(data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> {
    options.headers = options.headers || {};
    options.headers['X-Proxy-Options'] = 'raw';
    options.data = data;
    return this.catchUnauthorized(axios.delete(this.url, this.updateOptions(options)));
  }

  cancel(message?: string):void {
    this.cancelToken.cancel(message);
    this.wasCancelled = true;
  }

  updateOptions(options: AxiosRequestConfig): AxiosRequestConfig {
    options = options || {};
    const authToken = localStorage.getItem('encBearerToken');
    return {
      ...options,
      cancelToken: this.cancelToken.token,
      headers: {
        ...(options.headers || {}),
        ...(authToken ? { Authentication: authToken } : {}),
      },
      paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
    };
  }

  catchUnauthorized<T>(request: Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> {
    return request.catch((error) => {
      if (error.response && error.response.status === 401) {
        // set a token that we can use when returning to the login
        // page to put them back on the workspace login page
        const workspace = localStorage.getItem('authWorkspace');

        if (workspace) {
          localStorage.setItem('loginRedirect', workspace);
        }

        // Set another token to indicate that where to redirect back to
        // after the user re logs in
        localStorage.setItem('postLoginRedirect', window.location.pathname);

        // Clear out other auth data and go back to the login page
        const baseUri = window.location.origin;
        window.location.href = `${baseUri}/login`;
        localStorage.removeItem('encBearerToken');
        localStorage.removeItem('authEndpoint');
        localStorage.removeItem('authWorkspace');
        return request;
      }

      throw error;
    });
  }
}

export default Request;

/**
 * Object descripting current rate limit behavior
 * @readonly
 */
export const rateLimit = Object.freeze({
  /** the maximum number of concurrent requests to make */
  concurrentRequests: 5,
  /** the time to wait before subsequent requests (ms) */
  resetTimeout: 1000,
});
