import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'what-input';
import 'formdata-polyfill';

import AppRouter from 'routes';
import history from 'routes/history';
import { actions } from 'store/appState';

class AppContainer extends Component {
  componentDidMount() {
    actions.getProductDetails()(this.props.store.dispatch);
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <div style={{ height: '100%' }}>
          <Router history={history}>
            <AppRouter store={this.props.store} />
          </Router>
        </div>
      </Provider>
    );
  }
}

AppContainer.propTypes = {
  store: PropTypes.shape({
    asyncReducers: PropTypes.object,
    dispatch: PropTypes.func,
    getState: PropTypes.func,
    liftedStore: PropTypes.object,
    replaceReducer: PropTypes.func,
    subscribe: PropTypes.func,
    unsubscribeHistory: PropTypes.func,
    'Symbol(observable)': PropTypes.object,
  }).isRequired,
};

export default AppContainer;
