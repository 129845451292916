import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import NumberInput from 'components/NumberInput';
import asyncActionStates from 'helpers/asyncActionStates';
import { perMeter } from 'helpers/units';
import {
  areAssetModelPropertiesChanged,
  getAssetModelProperties,
} from 'routes/WorkspaceLayout/routes/EquipmentLibrary/helpers/assetModelHelpers';
import {
  areBaseCableValuesUpdated,
  createBaseCableDiffModel,
  createEditDiffModel,
  getBaseCableInputValues,
  isBaseCableValid,
  getNewConcentricNeutralCable,
  renderCableInputs,
} from '../../../helpers/cablePanelHelpers';
import Helpers from '../../../helpers/EquipmentLibraryHelpers';

import DescriptionEditor from '../DescriptionEditor';
import CostEditor from '../CostEditor';
import ReliabilityMetrics from '../ReliabilityMetrics';
import PanelTabs from '../PanelTabs';

import '../common.scss';

class ConcentricNeutralCablePanel extends Component {
  state = { ...(getNewConcentricNeutralCable()) }

  UNSAFE_componentWillMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps({ createInstanceReq, selected }) {
    const { LOADING, SUCCESS } = asyncActionStates;
    const newSelected = this.props.selected !== selected;
    const saved = this.props.createInstanceReq === LOADING && createInstanceReq === SUCCESS;

    if (newSelected || saved) {
      this.setState({ ...this.getInputValues(selected) });
    }
  }

  getInputValues = (selected) => {
    let asset = { ...(getNewConcentricNeutralCable()) };

    if (selected) {
      const baseInputValues = getBaseCableInputValues(selected);
      const {
        diameterOverNeutral, neutralStrandCount,
        neutralStrandGmr, neutralStrandRadius,
        neutralStrandRDC20,
      } = selected;
      asset = {
        ...baseInputValues,
        diameterOverNeutral: `${diameterOverNeutral}` || asset.diameterOverNeutral,
        neutralStrandCount: `${neutralStrandCount}` || asset.neutralStrandCount,
        neutralStrandGmr: `${neutralStrandGmr}` || asset.neutralStrandGmr,
        neutralStrandRadius: `${neutralStrandRadius}` || asset.neutralStrandRadius,
        neutralStrandRDC20: `${neutralStrandRDC20}` || asset.neutralStrandRDC20,
      };
    }

    return asset;
  }

  handleSave = () => {
    let diffModel = createBaseCableDiffModel(this.state);

    diffModel = {
      ...diffModel,
      diameterOverNeutral: parseFloat(this.state.diameterOverNeutral),
      neutralStrandCount: parseFloat(this.state.neutralStrandCount),
      neutralStrandGmr: parseFloat(this.state.neutralStrandGmr),
      neutralStrandRadius: parseFloat(this.state.neutralStrandRadius),
      neutralStrandRDC20: parseFloat(this.state.neutralStrandRDC20),
    };

    if (this.state.id === 'add') {
      this.props.handleCreate('concentric_neutral_cable_info', diffModel, 'ConcentricNeutralCableInfo');
    } else {
      const { selected } = this.props;
      const editDiffModel = createEditDiffModel(selected, diffModel);
      this.props.handleEdit(selected.id, editDiffModel);
    }
  }

  handleInputChange = ({ id, value }) => this.setState({ [id]: value });

  handleSelectChange = (id, value) => this.setState({ [id]: value });

  handleCheckChange = (e) => {
    const { id } = e.target;
    this.setState(prevState => ({ [id]: !prevState[id] }));
  };

  valuesUpdated = (selected) => {
    if (!selected) return true;

    const numberUpdated = [
      'diameterOverNeutral',
      'neutralStrandCount',
      'neutralStrandGmr',
      'neutralStrandRadius',
      'neutralStrandRDC20',
    ].some(val => selected[val] !== parseFloat(this.state[val]));

    return areBaseCableValuesUpdated(this.state, selected) || numberUpdated;
  }

  shiftFactorValid = (isShiftFactorValid = false) => this.setState({ isValidShiftFactor: isShiftFactorValid });

  formValid = () => {
    const { state } = this;

    if (!this.valuesUpdated(this.props.selected)) {
      return false;
    }

    if (!isBaseCableValid(state)) {
      return false;
    }

    const positiveValue = val => (val?.toString().length ?? 0) > 0 && parseFloat(val) > 0;

    return (
      positiveValue(state.diameterOverNeutral)
      && positiveValue(state.neutralStrandCount)
      && positiveValue(state.neutralStrandGmr)
      && positiveValue(state.neutralStrandRadius)
      && positiveValue(state.neutralStrandRDC20)
      && this.state.isValidShiftFactor
    );
  };

  updateAssetModel = prop => this.setState(prevState => ({
    assetModel: {
      ...prevState.assetModel,
      ...prop,
    },
  }));

  render() {
    const isDisabled = (
      this.props.authEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
      || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
      || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );

    const defaultProps = {
      theme: this.props.theme,
      disabled: isDisabled,
    };

    const inputDefaultProps = { ...defaultProps, onChange: this.handleInputChange };

    const diameterOverNeutral = Helpers.createDisplayObject('Diameter Over Neutral', 'diameterOverNeutral', this.state.diameterOverNeutral, 'm');
    const neutralStrandCount = Helpers.createDisplayObject('Neutral Strand Count', 'neutralStrandCount', this.state.neutralStrandCount);
    const neutralStrandGmr = Helpers.createDisplayObject(
      'Neutral Strand GMR',
      'neutralStrandGmr',
      this.state.neutralStrandGmr,
      <span>
        m
        <sup>2</sup>
      </span>,
    );
    const neutralStrandRadius = Helpers.createDisplayObject('Neutral Strand Radius', 'neutralStrandRadius', this.state.neutralStrandRadius, 'm');
    const neutralStrandRDC20 = Helpers.createDisplayObject('Neutral Strand Resistance at 20\u00B0C', 'neutralStrandRDC20', this.state.neutralStrandRDC20, '\u03A9/m');

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs', 'Reliability Metrics']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              {this.props.children}
              <h2>Concentric Neutral Cable</h2>
              {renderCableInputs(
                inputDefaultProps,
                isDisabled,
                this.state,
                this.handleSelectChange,
                this.handleCheckChange,
              )}
              <NumberInput
                ge={0.001}
                {...inputDefaultProps}
                {...diameterOverNeutral}
                required
                inputStyle="eq-lib"
              />
              <NumberInput
                ge={1}
                step="1"
                {...inputDefaultProps}
                {...neutralStrandCount}
                required
                inputStyle="eq-lib"
              />
              <NumberInput
                ge={0.001}
                {...inputDefaultProps}
                {...neutralStrandGmr}
                required
                inputStyle="eq-lib"
              />
              <NumberInput
                ge={0.001}
                {...inputDefaultProps}
                {...neutralStrandRadius}
                required
                inputStyle="eq-lib"
              />
              <NumberInput
                ge={0.000001}
                {...inputDefaultProps}
                {...neutralStrandRDC20}
                required
                inputStyle="eq-lib"
                className="neutral-strand-resist"
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={areAssetModelPropertiesChanged(
              this.state.assetModel,
              getAssetModelProperties(this.props.selected?.AssetModel),
            )[1]}
            key={`${this.state.id}-costs`}
            onChange={prop => this.setState(prevState => ({
              assetModel: { ...prevState.assetModel, ...prop },
            }))}
            isDisabled={isDisabled}
            theme={this.props.theme}
            unit={perMeter}
            includePerUnitMaintenance
          />,
          <ReliabilityMetrics
            assetModel={this.state.assetModel || null}
            probabilityOfFailureEquation={this.state.assetModel.AssetFailureInfo.probabilityOfFailureEquation || null}
            mTTR={this.state.assetModel.AssetFailureInfo.mTTR}
            key={`${this.state.id}-reliability`}
            onChange={this.updateAssetModel}
            isDisabled={isDisabled}
            theme={this.props.theme}
            saveLibraryError={this.props.saveLibraryError}
            shiftFactorValid={this.shiftFactorValid}
          />,
        ]}
      </PanelTabs>
    );
  }
}

ConcentricNeutralCablePanel.defaultProps = {
  selected: null,
  children: null,
  saveLibraryError: {},
};

ConcentricNeutralCablePanel.propTypes = {
  children: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  authEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  saveLibraryError: PropTypes.object,
};

export default ConcentricNeutralCablePanel;
