import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Request from 'helpers/Request';
import Analytics from 'helpers/Analytics';
import { FetchUsers, GetRolesDisplayText } from 'helpers/Users';
import { List, AutoSizer } from 'react-virtualized';

import asyncActionStates from 'helpers/asyncActionStates';
import IconButton from '../IconButton';

const deleteUser = async (workspace, email, setUserLoadingState, setErrorMessage, loadUserList) => {
  async function delUser() {
    setUserLoadingState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace${workspace}/user?user_email=${email}`);
    Analytics.logEvent('Revoked User Access', 'Sharing');
    try {
      await request.delete();
      loadUserList();
    } catch (error) {
      setErrorMessage('Could not delete user.\nTry again later or contact Opus One support.');
    }
  }
  delUser();
};

const UserList = ({
  canRemoveUser,
  disabled,
  workspace,
  loadingErrorHandler,
  theme,
  setErrorMessage,
}) => {
  const [userLoadingState, setUserLoadingState] = useState(asyncActionStates.INITIAL);
  const [users, setUsers] = useState([]);

  const loadUserList = () => {
    async function doFetchUsers() {
      try {
        setUserLoadingState(asyncActionStates.LOADING);
        await FetchUsers(workspace, setUserLoadingState, setUsers);
      } catch (error) {
        setUserLoadingState(asyncActionStates.ERROR);
        if (loadingErrorHandler !== null) {
          loadingErrorHandler(() => doFetchUsers());
        }
      }
    }

    // Only do a load when a component is opened
    if (!disabled) {
      doFetchUsers();
    }
  };

  // Renderer for each row of the virtualized list
  // eslint-disable-next-line react/prop-types
  const rowRenderer = ({ index, style }) => {
    const user = users[index];

    return (
      <div
        className={`user-row ${theme}`}
        key={`note-${user.email}`}
        style={{ ...style, paddingRight: '10px' }}
      >
        <p className={`user-email ${theme}`}>
          <div className="email" title={user.email}>{`${user.email}`}</div>
          <span className="user-permission">
            <div>{`${user.pending ? '(Pending) ' : ''}${GetRolesDisplayText(user.roles)}`}</div>
          </span>
          {canRemoveUser && (
          <IconButton
            className="remove-user"
            icon="close"
            onClick={() => deleteUser(
              workspace, user.email, setUserLoadingState, setErrorMessage, loadUserList,
            )}
            tooltip="Remove user"
            theme={theme}
            id="close-modal-btn"
          />
          )}
        </p>
      </div>
    );
  };

  if (userLoadingState === asyncActionStates.INITIAL) {
    loadUserList(setErrorMessage);
  }

  if (userLoadingState === asyncActionStates.LOADING
    || userLoadingState === asyncActionStates.INITIAL) {
    return (
      <div className="loading-message">
        Loading...
      </div>
    );
  } if (userLoadingState === asyncActionStates.ERROR) {
    return (
      <div className="error-message">
        Could not retrieve user list...
      </div>
    );
  }
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="gray-scrollbars"
          overscanColumnCount={10}
          rowCount={users.length}
          rowHeight={20}
          width={width}
          height={height}
          rowRenderer={e => rowRenderer(e, setErrorMessage)}
        />
      )}
    </AutoSizer>
  );
};

UserList.defaultProps = {
  canRemoveUser: true,
  disabled: false,
  loadingErrorHandler: null,
  theme: 'dark',
};

UserList.propTypes = {
  canRemoveUser: PropTypes.bool,
  disabled: PropTypes.bool,
  workspace: PropTypes.string.isRequired,
  loadingErrorHandler: PropTypes.func,
  theme: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
};

export default UserList;
