import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from '../CustomCheckbox';

import './LeftRailExpandableSection.scss';

// Creates a section in the left rail with a title
// When clicked, the section opens and the children are shown
class LeftRailExpandableSection extends Component {
  handleClick() {
    const { handleExpandCategory } = this.props;

    if (handleExpandCategory) {
      handleExpandCategory();
    }
  }

  render() {
    const {
      title, addCheckbox, selectCategory, categoryDash, theme, open,
    } = this.props;
    const openedStyle = open ? 'asset-type-tile-contents--open' : '';

    return (
      <div className={`left-rail-expandable-section ${theme}`}>
        <div className="left-rail-title">
          {addCheckbox
            && (
            <CustomCheckbox
              id={`${this.props.title}-checkbox`}
              name="checkbox"
              checked={selectCategory}
              dash={categoryDash}
              onClick={this.props.handleSelectCategory}
              disabled={this.props.emptyList}
            />
            )}
          <button
            className="expand-button"
            onClick={() => this.handleClick()}
            disabled={this.props.emptyList}
            type="button"
          >
            <div className={`expand-button-contents ${openedStyle}`}>
              <p className="expand-button-title">{title}</p>
              <i className="material-icons">
                {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </div>
          </button>
        </div>
        { open && this.props.children }
      </div>
    );
  }
}

LeftRailExpandableSection.defaultProps = {
  children: [],
  addCheckbox: false,
  selectCategory: false,
  categoryDash: false,
  handleSelectCategory: null,
  handleExpandCategory: null,
  emptyList: false,
  open: false,
};

LeftRailExpandableSection.propTypes = {
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  title: PropTypes.string.isRequired,
  addCheckbox: PropTypes.bool,
  selectCategory: PropTypes.bool,
  categoryDash: PropTypes.bool,
  handleSelectCategory: PropTypes.func,
  handleExpandCategory: PropTypes.func,
  emptyList: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

export default LeftRailExpandableSection;
