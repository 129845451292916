import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import nullable from 'helpers/nullablePropType';
import { alphabetizeByKey } from 'helpers/utils';

const MultiContainerPanel = ({
  containers,
  selectedContainer,
  setSelectedContainer,
  theme,
}) => (
  <div className="container-select-section">
    <Select
      theme={theme}
      options={alphabetizeByKey(
        Object.values(containers).map(x => ({ value: x.id, label: x.name })),
        'label',
      )}
      value={selectedContainer?.[0]?.id}
      onChange={(e) => setSelectedContainer(e ? containers.filter((container) => container.id === e.value) : null)}
      clearable={false}
      placeholder="Select Container..."
    />
  </div>
);

MultiContainerPanel.propTypes = {
  containers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  theme: PropTypes.string.isRequired,
  selectedContainer: nullable(PropTypes.array).isRequired,
  setSelectedContainer: PropTypes.func.isRequired,
};

export default MultiContainerPanel;
