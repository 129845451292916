/* eslint react/no-unused-prop-types:0 */ // Not picking up props in helper functions
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import { W, Wh, VAr } from 'helpers/units';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import Select from 'components/Select';
import Button from 'components/Button';
import { updateLayerStorage, defaultLayerOptions } from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import LayerSelectionButton from './LayerSelectionButton';
import HostingCapacitySection from './HostingCapacitySection';
import BatteryAnalsisSection from './BatteryAnalysisSection';
import VoltageSection from './VoltageSection';
import CurrentSection from './CurrentSection';
import ApparentPowerSection from './ApparentPowerSection';
import PowerSection from './PowerSection';
import PowerFactorSection from './PowerFactorSection';
import GenerationLoadSection from './GenerationLoadSection';

import './LayerPanel.scss';
import { ANALYSIS_TYPES } from '../../../helpers/NetworkHelpers';

// Create the panel to select which layer gets applied to GIS view
class LayerPanel extends Component {
  componentWillUnmount() {
    if (this.props.selectedAnalysis) {
      updateLayerStorage(
        this.props.inEditMode,
        this.props.branch,
        this.props.workspace,
        this.props.selectedScenario,
        this.props.selectedAnalysis.id,
        this.props.layerOptions,
        this.props.displayBranch,
      );
    }
  }

  // Create a group of selectable items
  createTabs = (optionList = [], selected) => (
    optionList.map(option => (
      <LayerSelectionButton
        key={option.value}
        selected={option.value === selected}
        {...{ option, setSelectedLayer: this.props.setSelectedLayer }}
      />
    ))
  )

  render() {
    const { selectedLayer, theme, selectedAnalysis } = this.props;

    const getMinValue = arr => (Array.isArray(arr) ? arr[arr.length - 1] : 0);

    return (
      <div className="layer-panel">
        <div
          className={classNames({
            'layer-panel-container': true,
            'layer-panel-container--expanded': this.props.expanded,
          })}
        >
          <div className="layer-panel-container-header">
            <h3 className="layer-panel-container-header__h3">Visual Layers</h3>
            <Button
              type="text"
              onClick={() => {
                updateLayerStorage(
                  this.props.inEditMode,
                  this.props.branch,
                  this.props.workspace,
                  this.props.selectedScenario,
                  this.props.selectedAnalysis.id,
                  defaultLayerOptions,
                  this.props.displayBranch,
                );
                this.props.getLayerOptions();
              }}
              theme={theme}
              id="reset-options"
            >
              Reset Layer Options
            </Button>
          </div>
          {selectedAnalysis?.type !== ANALYSIS_TYPES.BATTERY_SIZING && (
            <div className="agg-options">
              <Select
                options={[
                  { label: 'Average', value: 'avg' },
                  { label: 'Min', value: 'min' },
                  { label: 'Max', value: 'max' },
                ]}
                value={this.props.layerOptions.aggregation}
                onChange={({ value }) => this.props.updateLayerAggregation(value)}
                clearable={false}
                searchable={false}
                width={100}
                theme={this.props.theme}
              />
            </div>
          )}
        </div>
        <CustomScrollBar>
          {[ANALYSIS_TYPES.POWERFLOW].includes(selectedAnalysis?.type) && (
            <GenerationLoadSection
              setSelectedLayer={this.props.setSelectedLayer}
              layerOptions={this.props.layerOptions.generation_load}
              updateLayerOptions={this.props.updateLayerOptions}
              selected={selectedLayer === 'generation_load'}
              expanded={this.props.expanded}
            />
          )}
          {[
            ANALYSIS_TYPES.POWERFLOW, ANALYSIS_TYPES.QSTS, ANALYSIS_TYPES.QSTS_OPF,
          ].includes(selectedAnalysis?.type) && (
            <>
              <VoltageSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.voltage}
                updateLayerOptions={this.props.updateLayerOptions}
                selected={selectedLayer === 'voltage'}
                theme={this.props.theme}
                showThreePhase={this.props.showThreePhase}
                expanded={this.props.expanded}
              />
              <CurrentSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.current}
                updateLayerOptions={this.props.updateLayerOptions}
                selected={selectedLayer === 'current'}
                theme={this.props.theme}
                showThreePhase={this.props.showThreePhase}
                expanded={this.props.expanded}
              />
              <ApparentPowerSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.apparent_power}
                updateLayerOptions={this.props.updateLayerOptions}
                selected={selectedLayer === 'apparent_power'}
                theme={this.props.theme}
                showThreePhase={this.props.showThreePhase}
                expanded={this.props.expanded}
              />
              <PowerSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.real_power}
                updateLayerOptions={this.props.updateLayerOptions}
                selected={selectedLayer === 'real_power'}
                layerID="real_power"
                layerName="Real Power"
                unit={W}
                theme={this.props.theme}
                showThreePhase={this.props.showThreePhase}
                min={getMinValue(this.props.layerOptions.real_power.rangeBreaks)}
                expanded={this.props.expanded}
                toggleDirectionalFlow={this.props.toggleDirectionalFlow}
              />
              <PowerSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.reactive_power}
                updateLayerOptions={this.props.updateLayerOptions}
                selected={selectedLayer === 'reactive_power'}
                layerID="reactive_power"
                layerName="Reactive Power"
                unit={VAr}
                theme={this.props.theme}
                showThreePhase={this.props.showThreePhase}
                min={getMinValue(this.props.layerOptions.reactive_power.rangeBreaks)}
                expanded={this.props.expanded}
                toggleDirectionalFlow={this.props.toggleDirectionalFlow}
              />
              <PowerFactorSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.power_factor}
                updateLayerOptions={this.props.updateLayerOptions}
                selected={selectedLayer === 'power_factor'}
                theme={this.props.theme}
                showThreePhase={this.props.showThreePhase}
                expanded={this.props.expanded}
              />
              <PowerSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.real_power_losses}
                updateLayerOptions={this.props.updateLayerOptions}
                selected={selectedLayer === 'real_power_losses'}
                layerID="real_power_losses"
                layerName="Real Power Losses"
                unit={W}
                addSum
                theme={this.props.theme}
                showThreePhase={this.props.showThreePhase}
                min={0}
                expanded={this.props.expanded}
              />
            </>
          )}
          {selectedAnalysis?.type === ANALYSIS_TYPES.HOSTING_CAPACITY && (
            <HostingCapacitySection
              setSelectedLayer={this.props.setSelectedLayer}
              layerOptions={this.props.layerOptions.hosting_capacity}
              updateLayerOptions={updates => this.props.updateLayerOptions('hosting_capacity', updates)}
              selected={selectedLayer === 'hosting_capacity'}
              theme={this.props.theme}
              expanded={this.props.expanded}
              layerName="Hosting Capacity"
              layerID="hosting_capacity"
            />
          )}
          {selectedAnalysis?.type === ANALYSIS_TYPES.EV_CAPACITY && (
            <HostingCapacitySection
              setSelectedLayer={this.props.setSelectedLayer}
              layerOptions={this.props.layerOptions.ev_capacity}
              updateLayerOptions={updates => this.props.updateLayerOptions('ev_capacity', updates)}
              selected={selectedLayer === 'ev_capacity'}
              theme={this.props.theme}
              expanded={this.props.expanded}
              layerName="EV Capacity"
              layerID="ev_capacity"
            />
          )}
          {selectedAnalysis?.type === ANALYSIS_TYPES.BATTERY_SIZING && (
            <>
              <BatteryAnalsisSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.battery_sizing_energy}
                updateLayerOptions={updates => this.props.updateLayerOptions('battery_sizing_energy', updates)}
                selected={selectedLayer === 'battery_sizing_energy'}
                theme={this.props.theme}
                expanded={this.props.expanded}
                layerName="Energy"
                layerID="battery_sizing_energy"
                unit={Wh}
              />
              <BatteryAnalsisSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.battery_sizing_real_power}
                updateLayerOptions={updates => this.props.updateLayerOptions('battery_sizing_real_power', updates)}
                selected={selectedLayer === 'battery_sizing_real_power'}
                theme={this.props.theme}
                expanded={this.props.expanded}
                layerName="Real Power"
                layerID="battery_sizing_real_power"
                unit={W}
              />
              <BatteryAnalsisSection
                setSelectedLayer={this.props.setSelectedLayer}
                layerOptions={this.props.layerOptions.battery_sizing_reactive_power}
                updateLayerOptions={updates => this.props.updateLayerOptions('battery_sizing_reactive_power', updates)}
                selected={selectedLayer === 'battery_sizing_reactive_power'}
                theme={this.props.theme}
                expanded={this.props.expanded}
                layerName="Reactive Power"
                layerID="battery_sizing_reactive_power"
                unit={VAr}
              />
            </>
          )}
          {this.createTabs(this.props.basicLayerOptions, selectedLayer)}
        </CustomScrollBar>
      </div>
    );
  }
}

LayerPanel.defaultProps = {
  selectedLayer: null,
  basicLayerOptions: [],
  expanded: false,
};

LayerPanel.propTypes = {
  selectedLayer: PropTypes.string,
  selectedAnalysis: nullable(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  layerOptions: PropTypes.object.isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  updateLayerAggregation: PropTypes.func.isRequired,
  basicLayerOptions: PropTypes.array,
  theme: PropTypes.string.isRequired,
  showThreePhase: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  toggleDirectionalFlow: PropTypes.func.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  getLayerOptions: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
};

export default LayerPanel;
