import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import flatpickr from 'flatpickr';
import moment from 'moment';
import IconButton from 'components/IconButton';

/*
 * Creates a date picker instance that has forward and back buttons for the dates
 * and an input that triggers the calendar to open. The default is to allow selection
 * of both date and time
*/
class DatePicker extends Component {
  constructor(props) {
    super(props);
    // if this is controlled, use the controlled date
    let defaultDate = this.props.date;
    if (!defaultDate) {
      // if uncontrolled use the default date
      defaultDate = this.props.defaultDate;

      if (!defaultDate && !props.clearable) {
        // if there is no default and its not clearable, set it to now
        defaultDate = props.useUTC ? moment.utc() : moment();
      }
    }

    this.state = {
      date: defaultDate,
    };

    this.dateInput = null;
  }

  componentDidMount() {
    this.createDateField(this.state.date);
  }

  componentDidUpdate(prevProps) {
    // If calendar is controlled, update the internal date state
    if (this.props.date && !this.props.date.isSame(prevProps.date)) {
      this.setState({ date: this.props.date });
      this.date.setDate(this.props.date.format());
      this.createDateField(this.props.date);
    }

    if (!isEqual(prevProps.options, this.props.options)) {
      Object.keys(this.props.options).forEach((key) => {
        this.date.set(key, this.props.options[key]);
      });
    }

    if (this.props.theme !== prevProps.theme && this.date) {
      this.date.calendarContainer.classList.remove(`flatpickr-${prevProps.theme}`);
      this.date.calendarContainer.classList.add(`flatpickr-${this.props.theme}`);
    }
  }

  componentWillUnmount() {
    this.date.destroy();
  }

  createDateField = (inputDate) => {
    const currentValue = moment(inputDate);
    const minDate = moment(this.props.options.minDate);
    const maxDate = moment(this.props.options.maxDate);
    const minDateLimit = moment(this.props.options.minDateLimit);
    const now = new Date(inputDate.format());
    const timezoneOffsetInMinutes = now.getTimezoneOffset();
    if (this.props.useUTC) {
      currentValue.add(timezoneOffsetInMinutes, 'm');
    }

    let options = { ...this.props.options };
    if (this.props.options.minDate) {
      if (this.props.useUTC) {
        minDate.add(timezoneOffsetInMinutes, 'm');
        maxDate.add(timezoneOffsetInMinutes, 'm');
      }

      options = {
        ...options,
        minDate: minDate.valueOf(),
        maxDate: maxDate.valueOf(),
      };
    }
    if (this.props.options.minDateLimit) {
      if (this.props.useUTC) {
        minDate.add(timezoneOffsetInMinutes, 'm');
      }
      options = {
        ...options,
        minDate: minDateLimit.valueOf(),
      };
    }
    this.date = flatpickr(this.dateInput, {
      time_24hr: true,
      minuteIncrement: 1,
      dateFormat: this.props.dateFormat,
      onChange: date => {
        const newDate = date.length ? moment(date[0]) : null;
        this.handleDateChange(newDate);
      },
      defaultDate: currentValue.valueOf(),
      ...options,
    });
    this.date.calendarContainer.classList.add(`flatpickr-${this.props.theme}`);
    this.date.calendarContainer.classList.add('DatePicker-calendar-instance');
  }

  // Add one date to the current date
  addDate = () => {
    const newDate = moment(this.state.date).add(1, 'd');
    const maxDate = this.date.config._maxDate;

    // Ensure that we do not go past maxDate if set...
    if (maxDate !== undefined && newDate.isAfter(maxDate, 'day')) {
      return;
    }
    this.handleDateChange(newDate);
  };

  // Subtract one date from the current date
  subtractDate = () => {
    const newDate = moment(this.state.date).subtract(1, 'd');
    this.handleDateChange(newDate);
  }

  handleDateChange = (date) => {
    this.setState({ date });
    this.props.onChange(date);
  }

  render() {
    const {
      showArrows, clearable, theme, disabled,
    } = this.props;
    return (
      <div className={`date-picker ${theme} `}>
        {showArrows && (
          <button className="date-picker__btn" onClick={this.subtractDate} disabled={disabled} type="button">
            <i className="material-icons" aria-hidden="true">chevron_left</i>
          </button>
        )}
        <input className="date-picker__input" name="startDate" disabled={disabled} ref={(div) => { this.dateInput = div; }} />
        {clearable && (
          <IconButton
            id="clear"
            onClick={() => this.date.clear()}
            icon="close"
            className="close"
            theme={theme}
            tooltip="Clear date"
          />
        )}
        {showArrows && (
          <button className="date-picker__btn" onClick={this.addDate} disabled={disabled} type="button">
            <i className="material-icons" aria-hidden="true">chevron_right</i>
          </button>
        )}
      </div>
    );
  }
}

DatePicker.defaultProps = {
  date: null,
  defaultDate: null,
  disabled: false,
  options: {},
  theme: 'dark',
  showArrows: true,
  dateFormat: 'F j, Y',
  useUTC: true,
  clearable: false,
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.object, // Controlled Component Date (moment obj)
  defaultDate: PropTypes.object, // Default date only (moment obj)
  disabled: PropTypes.bool,
  options: PropTypes.object,
  theme: PropTypes.oneOf(['dark', 'light']),
  showArrows: PropTypes.bool,
  dateFormat: PropTypes.string, // Flatpickr date format string
  useUTC: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default DatePicker;
