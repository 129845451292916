import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { validate } from '@mapbox/mapbox-gl-style-spec';

import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import asyncActionStates from 'helpers/asyncActionStates';

const GisSettingsPanel = ({
  disabled,
  displayAlertMessage,
  theme,
}) => {
  const {
    settings, setSettings, settingsModified, saveSettings, loadingState,
  } = useContext(WorkspaceSettingsContext);

  const saveGISMapboxConfig = async () => {
    try {
      await saveSettings();
    } catch (er) {
      displayAlertMessage(
        'Failed to save GIS settings',
        'Could not save GIS settings. Please try again.',
        null,
        () => saveGISMapboxConfig(),
      );
    }
  };
  return (
    <div className="workspace-card-tab-panel">
      <div className="gis-tab">
        <span className="gis-title">Mapbox GL Source</span>
        <textarea
          style={{ width: '100%' }}
          autoComplete="off"
          className={`${theme} message`}
          onChange={e => {
            setSettings({ mapboxSource: e.target.value });
            if (validate(e.target.value).length > 0) {
              displayAlertMessage(
                'MapBox Error: Invalid Map source Data',
                'Invalid JSON Blob',
                null,
                null,
              );
            }
          }}
          placeholder="Enter a mapbox gl compatible style source"
          value={settings.mapboxSource}
          disabled={disabled || loadingState === asyncActionStates.LOADING}
          wrap="soft"
        />
        <Button
          label="Apply Changes"
          className="workspace-card-button"
          id="auth-button"
          disabled={
            !settingsModified.mapboxSource || disabled || loadingState === asyncActionStates.LOADING
            || validate(settings.mapboxSource).length > 0
          }
          onClick={saveGISMapboxConfig}
          theme={theme}
          type="primary"
        />
      </div>
    </div>
  );
};

GisSettingsPanel.defaultProps = {
  disabled: true,
};

GisSettingsPanel.propTypes = {
  disabled: PropTypes.bool,
  displayAlertMessage: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default GisSettingsPanel;
