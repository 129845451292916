import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip';

import ThemeContext from 'helpers/ThemeContext';
import { ErrorIcon, WarningIcon } from '../../../../components/Icons';

import './ErrorTypeLeftRail.scss';

const ErrorTypeLeftRail = ({
  data,
  selectedID,
  setSelectedID,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <aside className="error-type-left-rail">
      <ul className="name-list">
        {data.map(entry => (
          <button
            className={classNames('name-list-item', { 'name-list-item--selected': selectedID === entry.id })}
            key={entry.id}
            onClick={() => setSelectedID(entry.id === selectedID ? null : entry.id)}
            type="button"
          >
            {entry.hasWarning && WarningIcon()}
            {entry.hasError && ErrorIcon()}
            <Tooltip content={entry.name} theme={theme}>
              <span className="item-name">{entry.name}</span>
            </Tooltip>
          </button>
        ))}
      </ul>
    </aside>
  );
};

ErrorTypeLeftRail.propTypes = {
  data: PropTypes.array.isRequired,
  selectedID: PropTypes.string.isRequired,
  setSelectedID: PropTypes.func.isRequired,
};

export default ErrorTypeLeftRail;
