import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ThemeContext from 'helpers/ThemeContext';
import Select from 'components/Select';

// Creates a single value dropdown menu. Used to update single attribute values
const AssetSingleSelect = (props) => {
  const {
    value, label, edited, id, onChange, options, defaultValue, disabled, format,
  } = props;
  const theme = useContext(ThemeContext);

  if (value === null && !edited) {
    return null;
  }

  const formattedValue = value !== '' ? value : defaultValue;

  return (
    <div className="single-row">
      <p className="single-row-label">{label}</p>
      <div>
        <Select
          onChange={({ value: val }) => onChange(val, id)}
          value={format ? formattedValue : value || defaultValue}
          disabled={disabled}
          className={`single-row-value single-row-select ${
            edited ? 'single-row-value--edited' : ''
          }`}
          options={options}
          clearable={false}
          searchable={false}
          theme={theme}
        />
      </div>
    </div>
  );
};

AssetSingleSelect.defaultProps = {
  defaultValue: '',
  disabled: false,
  value: '',
  edited: false,
  onChange: null,
  format: true,
};

AssetSingleSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  edited: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  format: PropTypes.bool,
};

export default AssetSingleSelect;
