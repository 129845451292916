import axios from 'axios';
import Analytics from 'helpers/Analytics';
import getEnvironmentInfo from 'helpers/EnvironmentInfo';

export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const GET_AUTH_PROVIDER = 'GET_AUTH_PROVIDER';
export const SET_AUTH_STATUS_TRUE = 'SET_AUTH_STATUS_TRUE';
export const SET_AUTH_STATUS_FALSE = 'SET_AUTH_STATUS_FALSE';
export const SET_AUTH_WORKSPACE_MISMATCH = 'SET_AUTH_WORKSPACE_MISMATCH';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
export const DISPLAY_ALERT_MESSAGE = 'DISPLAY_ALERT_MESSAGE';
export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE';
export const SET_WORKSPACE_FOR_TENANT = 'SET_WORKSPACE_FOR_TENANT';
export const SET_THEME = 'SET_THEME';
export const CHANGE_MAP_MODE = 'CHANGE_MAP_MODE';
export const SET_VIEW = 'SET_VIEW';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_AUTH_LOADED = 'SET_AUTH_LOADED';

const LOAD_SETTINGS = 'LOAD_SETTINGS';
const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

function getProductDetails() {
  return (dispatch) => {
    getEnvironmentInfo()
      .then((envInfo) => {
        localStorage.removeItem('analyticsID');
        // Attempt to initialize Google Analytics tracking
        Analytics.initialize(envInfo.GA);
        dispatch({
          type: GET_PRODUCT_DETAILS,
          payload: envInfo,
        });
      })
      .catch(() => {});
  };
}

function setAuthWorkspaceMismatch(isMismatch) {
  return {
    type: SET_AUTH_WORKSPACE_MISMATCH,
    payload: isMismatch,
  };
}

function getAuthProvider() {
  return async (dispatch) => {
    try {
      const {
        data: {
          use_auth0: useAuth0,
        },
      } = await axios('/api/about');
      return dispatch({
        type: GET_AUTH_PROVIDER,
        payload: useAuth0 ? 'auth0' : 'keycloak',
      });
    } catch (error) {
      return dispatch({
        type: GET_AUTH_PROVIDER,
        payload: 'auth0',
      });
    }
  };
}

function setAuthenticated() {
  return {
    type: SET_AUTHENTICATED,
  };
}

function setAuthLoaded() {
  return {
    type: SET_AUTH_LOADED,
  };
}

function setAuthStatus(status) {
  if (!status) {
    return {
      type: SET_AUTH_STATUS_FALSE,
    };
  }
  return {
    type: SET_AUTH_STATUS_TRUE,
  };
}

function setWorkspaceForTenant(tenant) {
  return {
    type: SET_WORKSPACE_FOR_TENANT,
    payload: tenant,
  };
}

function setView(view) {
  Analytics.logEvent('Activated View', view);
  return {
    type: SET_VIEW,
    payload: view,
  };
}

export function updatePermissions(permissions) {
  return {
    type: UPDATE_PERMISSIONS,
    permissions: new Set(permissions),
  };
}

export function displayAlertMessage(title, message, onConfirm, onRetry) {
  return {
    type: DISPLAY_ALERT_MESSAGE,
    title,
    message,
    onRetry,
    onConfirm,
  };
}

function clearAlertMessage() {
  return {
    type: CLEAR_ALERT_MESSAGE,
  };
}

function changeTheme(theme) {
  return (dispatch) => {
    dispatch({
      type: SET_THEME,
      theme,
    });
  };
}

function loadSettings() {
  const str = localStorage.getItem('GRIDOS_SETTINGS');

  let settings;
  try {
    settings = str ? JSON.parse(str) : {};
  } catch (err) {
    settings = {};
  }

  return {
    type: LOAD_SETTINGS,
    settings: {
      ...initialState.settings, // ensure defaults are applied
      ...settings,
    },
  };
}

function updateSettings(key, mergeSettings) {
  return (dispatch, getState) => {
    const state = getState().global;
    const settings = {
      ...state.settings,
      [key]: {
        ...(state.settings[key] || {}),
        ...mergeSettings,
      },
    };

    localStorage.setItem('GRIDOS_SETTINGS', JSON.stringify(settings));

    dispatch({
      type: UPDATE_SETTINGS,
      settings,
    });
  };
}

export const actions = {
  getProductDetails,
  getAuthProvider,
  setAuthenticated,
  setAuthStatus,
  setAuthLoaded,
  setAuthWorkspaceMismatch,
  updatePermissions,
  displayAlertMessage,
  clearAlertMessage,
  setWorkspaceForTenant,
  changeTheme,
  loadSettings,
  updateSettings,
  setView,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  productDetails: {},
  authEnabled: true,
  authProvider: '',
  isAuthenticated: false,
  isAuthLoaded: false,
  permissions: new Set([]),
  alertMessage: null,
  authWorkspaceMismatch: false,
  workspaceForTenant: '',
  themes: localStorage.getItem('theme')
    ? localStorage.getItem('theme').split(' ')
    : ['dark'],
  theme: localStorage.getItem('theme')
    ? localStorage.getItem('theme').split(' ')[0]
    : 'dark',
  settings: {
    analysis: {},
  },
  view: 'overview',
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WORKSPACE_FOR_TENANT:
      return { ...state, workspaceForTenant: action.payload };
    case SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload,
      };
    case UPDATE_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case GET_AUTH_PROVIDER:
      return { ...state, authProvider: action.payload };
    case SET_AUTH_STATUS_TRUE:
      return { ...state, authEnabled: true };
    case SET_AUTH_WORKSPACE_MISMATCH:
      return {
        ...state,
        authWorkspaceMismatch: action.payload,
      };
    case SET_AUTH_STATUS_FALSE:
      // If auth is disabled, have app act like user has all permissions
      return {
        ...state,
        authEnabled: false,
        permissions: {
          has: () => true,
        },
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SET_AUTH_LOADED:
      return {
        ...state,
        isAuthLoaded: true,
      };
    case DISPLAY_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: {
          title: action.title,
          message: action.message,
          onRetry: action.onRetry,
          onConfirm: action.onConfirm,
        },
      };
    case CLEAR_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: null,
      };
    case SET_THEME:
      const { theme } = action;
      const updatedThemes = [theme, ...state.themes.slice(1)];
      localStorage.setItem('theme', updatedThemes.join(' '));
      return {
        ...state,
        themes: updatedThemes,
        theme,
      };
    case LOAD_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    default:
      return state;
  }
}
