import React, { FunctionComponent, CSSProperties } from 'react';
import { useAttachments } from 'contexts/AttachmentsContext';
import {
  Node, LinkIcon, ShuntIcon, Line,
} from 'types/cim';
import AttachmentIndicator from './AttachmentIndicator';

type InstanceTileProps = {
  instance: Node|LinkIcon|ShuntIcon|Line;
  onClick: (id: string|null) => void;
  onHover: (id: string|null) => void;
  selected: boolean;
  style: CSSProperties|undefined;
}

type Attachments = { assetsWithFiles: string[], assetsWithNotes: string[] }
/**
 * Creates a single clickable tile that displays the name or id of a CIM instance
 */
const InstanceTile: FunctionComponent<InstanceTileProps> = ({
  instance, onClick, onHover, selected, style = {},
}) => {
  const { id, name } = instance.properties;
  const { assetsWithFiles, assetsWithNotes }: Attachments = useAttachments();
  return (
    <li key={id} style={style} className="asset-type-instance">
      <button
        className={`asset-type-instance__btn ${selected ? 'active-tile' : ''}`}
        onClick={() => onClick(id)}
        onMouseEnter={() => onHover(id)}
        onMouseLeave={() => onHover(null)}
        title={name}
        type="button"
      >
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          {name}
        </span>
        {assetsWithNotes.includes(id) && (
          <AttachmentIndicator color="#4444a6" />
        )}
        {assetsWithFiles.includes(id) && (
          <AttachmentIndicator color="#d184f0" />
        )}
      </button>
    </li>
  );
};

export default InstanceTile;
