import React, { Component } from 'react';
import PropTypes from 'prop-types';

import asyncActionStates from 'helpers/asyncActionStates';
import Modal from '../Modal';
import './ConfirmDelete.scss';

const { SUCCESS, ERROR, LOADING } = asyncActionStates;
/**
 * Creates the contents of the confirm delete modal
 */
class ConfirmDelete extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deleteStatus } = nextProps;
    // Once the request is done, close the modal
    const wasLoading = this.props.deleteStatus === LOADING;
    const completed = deleteStatus === SUCCESS || deleteStatus === ERROR;
    if (wasLoading && completed) {
      this.props.closeModal();
    }
  }

  render() {
    const item = this.props.deletedItemDesc;
    const isLoading = this.props.deleteStatus === LOADING;
    return (
      <Modal
        active={this.props.modalActive}
        title={`Delete ${item}`}
        width="300px"
        onCancel={this.props.closeModal}
        onConfirm={this.props.deleteItem}
        labels={{
          confirm: isLoading
            ? <i className="material-icons rotate">autorenew</i>
            : 'Delete',
        }}
        reverseFooterButtons
        theme={this.props.theme}
        disableConfirm={isLoading || !this.props.deleteItem}
        disableCancel={isLoading}
        showCancel={!!this.props.closeModal}
      >
        <p className="modal-message__p">
          {this.props.modalBody.message1}
        </p>
        <p className="modal-message__p">
          {this.props.modalBody.message2}
        </p>
      </Modal>
    );
  }
}

ConfirmDelete.defaultProps = {
  deleteStatus: asyncActionStates.INITIAL,
  modalBody: {
    message1: 'Once an item has been deleted, it cannot be restored.',
    message2: 'Would you like to permanently remove this item?',
  },
  theme: 'dark',
};

ConfirmDelete.propTypes = {
  modalActive: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteStatus: PropTypes.number,
  deleteItem: PropTypes.func.isRequired,
  deletedItemDesc: PropTypes.string.isRequired,
  modalBody: PropTypes.object,
  theme: PropTypes.string,
};

export default ConfirmDelete;
