/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react';
import { IntlContext } from 'contexts/IntlContext';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'components/Button';
import asyncActionStates from 'helpers/asyncActionStates';
import './CostTable.scss';

const costReducer = (sum, current) => ({
  active_energy_cost: (sum.active_energy_cost + current.active_energy_cost),
  reactive_energy_cost: (sum.reactive_energy_cost + current.reactive_energy_cost),
  active_power_cost: (sum.active_power_cost + current.active_power_cost),
  reactive_power_cost: (sum.reactive_power_cost + current.reactive_power_cost),
  total_cost: (sum.total_cost
               + current.active_energy_cost + current.reactive_energy_cost
               + current.active_power_cost + current.reactive_power_cost
  ),
});

const OperationCostTable = ({
  highlightRange,
  costData, downloadOperationCostReport, operationCostReportStatus,
}) => {
  const { currencyFormatter } = useContext(IntlContext);

  const selectedCostData = costData.datapoints
    .filter(x => moment(x.timepoint).isBetween(
      highlightRange.start, highlightRange.end, undefined, [],
    ))
    .reduce(costReducer, {
      active_energy_cost: 0,
      reactive_energy_cost: 0,
      active_power_cost: 0,
      reactive_power_cost: 0,
      total_cost: 0,
    });
  const totalCostData = costData.datapoints.reduce(costReducer, {
    active_energy_cost: 0,
    reactive_energy_cost: 0,
    active_power_cost: 0,
    reactive_power_cost: 0,
    total_cost: 0,
  });

  return (
    <>
      <div className="cost-header-row">
        <h4>Operating Costs</h4>
        <Button
          onClick={downloadOperationCostReport}
          loading={operationCostReportStatus === asyncActionStates.LOADING}
          label="Download Report"
          className="report-download-btn"
        />
      </div>
      <table className="cost-table">
        <thead>
          <tr>
            <th />
            <th width="50%">Selected Range</th>
            <th width="50%">Full Range</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Active Energy</td>
            <td className="data-cell">{currencyFormatter.format(selectedCostData.active_energy_cost)}</td>
            <td className="data-cell">{currencyFormatter.format(totalCostData.active_energy_cost)}</td>
          </tr>
          <tr>
            <td>Reactive Energy</td>
            <td className="data-cell">{currencyFormatter.format(selectedCostData.reactive_energy_cost)}</td>
            <td className="data-cell">{currencyFormatter.format(totalCostData.reactive_energy_cost)}</td>
          </tr>
          <tr>
            <td>Active Power</td>
            <td className="data-cell">{currencyFormatter.format(selectedCostData.active_power_cost)}</td>
            <td className="data-cell">{currencyFormatter.format(totalCostData.active_power_cost)}</td>
          </tr>
          <tr>
            <td>Reactive Power</td>
            <td className="data-cell">{currencyFormatter.format(selectedCostData.reactive_power_cost)}</td>
            <td className="data-cell">{currencyFormatter.format(totalCostData.reactive_power_cost)}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td className="data-cell">{currencyFormatter.format(selectedCostData.total_cost)}</td>
            <td className="data-cell">{currencyFormatter.format(totalCostData.total_cost)}</td>
          </tr>
        </tbody>
      </table>

    </>
  );
};

OperationCostTable.propTypes = {
  downloadOperationCostReport: PropTypes.func.isRequired,
  operationCostReportStatus: PropTypes.number.isRequired,
  highlightRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  costData: PropTypes.shape({
    datapoints: PropTypes.array.isRequired,
  }).isRequired,
};

export default OperationCostTable;
