import React, {
  FunctionComponent,
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  ReactChild,
} from 'react';
import classnames from 'classnames';
import { ThemeProp } from '../../types/index';
import './TextInput.scss';

type TextInputProps = {
  className?: string,
  disabled?: boolean,
  htmlFor?: string,
  id?: string,
  inputColor?: string,
  inputWidth?: string,
  invalid?: boolean,
  label?: ReactChild,
  onBlur?: (event?: React.ChangeEvent<HTMLElement>) => void|null;
  onChange: (event?: React.ChangeEvent<HTMLElement>) => void|null;
  onKeyPress?: (event?: React.KeyboardEvent<HTMLElement>) => void|null;
  placeholder?: string,
  required?: boolean,
  setFocus?: boolean,
  theme?: ThemeProp,
  unit?: string,
  validationMessage?: string,
  value?: string,
};

const TextInput: FunctionComponent<TextInputProps> = ({
  className = '',
  disabled = false,
  htmlFor = '',
  id = '',
  inputColor = '',
  inputWidth = '',
  invalid = false,
  label = '',
  onBlur,
  onChange,
  onKeyPress,
  placeholder = '',
  required = false,
  setFocus = false,
  theme = 'dark',
  unit = '',
  validationMessage = '',
  value = '',
}) => {
  const [edited, setEdited] = useState(false);
  const [valid, setValid] = useState(true);

  const textInput = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    const focusTextInput = () => {
      if (setFocus && textInput.current !== null) textInput.current.focus();
    };

    focusTextInput();
  }, [setFocus]);

  const onInputChange = (e: React.ChangeEvent<HTMLElement>) => {
    if (!edited) {
      setEdited(true);
    }
    onChange(e);
  };

  const onInputKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (onKeyPress) {
      onKeyPress(e);
    }
  };

  const onInputBlur = (e: React.ChangeEvent<HTMLElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    let validInput = true;
    if (edited) {
      if (required) {
        validInput = value?.length > 0;
      }
      if (invalid) {
        validInput = false;
      }
    }
    setValid(validInput);
  }, [edited, invalid, required, value]);

  const style = () => {
    interface Style {
      width?: string,
      color?: string,
    }
    const styleObj: Style = {};
    if (inputWidth) styleObj.width = inputWidth;
    if (inputColor) styleObj.color = inputColor;
    return styleObj;
  };
  return (
    <div
      className={classnames({
        'text-input-all': true,
        [theme]: true,
        [className]: true,
      })}
    >
      <div className="text-input">
        <div className="text-input-container">
          <div className={classnames({
            'input-with-label': true,
            'input-with-label-modern': theme === 'modern',
          })}
          >
            {label && (
              <label
                htmlFor={htmlFor || id}
                className={classnames({
                  'text-input__label': true,
                  'text-input__label-modern': theme === 'modern',
                })}
              >
                {label}
                {required ? ' *' : ''}
              </label>
            )}
            <input
              type="text"
              id={id}
              className={classnames({
                'text-input__input': true,
                'text-invalid': !valid,
                'text-optional': !required,
                'text-input__input-modern': theme === 'modern',
              })}
              value={value}
              title={value}
              ref={textInput}
              onChange={e => onInputChange(e)}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              onKeyPress={e => onInputKeyPress(e)}
              onBlur={e => onInputBlur(e)}
              style={style()}
              data-test="text-input"
            />
          </div>
          {unit && (
            <span className={classnames({
              unit: true,
              'unit-modern': theme === 'modern',
            })}
            >
              {unit}
            </span>
          )}
        </div>

        {!valid && (
          <div className="input-error">
            <p>
              {validationMessage || `Please enter a valid ${label || id}`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
