/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import asyncActionStates from 'helpers/asyncActionStates';

import ThemeContext from 'helpers/ThemeContext';

const SubstationSelect = ({
  feeder, substations, updateContainer, status, disabled,
}) => {
  const subIsSelected = sub => feeder.substation === sub;

  const selectSubstation = (substation) => {
    if (!subIsSelected(substation)) {
      updateContainer('feeder', feeder.id, { substation }, 'updateFeeder');
    }
  };

  return (
    <div className="select-row">
      <label htmlFor="Substation">Substation</label>
      <Select
        name="Substation"
        value={feeder.substation}
        className="custom-react-select-theme"
        disabled={disabled || status === asyncActionStates.LOADING}
        clearable={false}
        searchable
        theme={useContext(ThemeContext)}
        onChange={({ value }) => selectSubstation(value)}
        options={[
          { value: null, label: 'None', disabled: subIsSelected(null) },
          ...substations.map(sub => ({
            value: sub.id,
            label: sub.name || sub.id,
            disabled: subIsSelected(sub.id),
          })),
        ]}
      />
    </div>
  );
};

SubstationSelect.propTypes = {
  feeder: PropTypes.object.isRequired,
  substations: PropTypes.array.isRequired,
  updateContainer: PropTypes.func.isRequired,
  status: PropTypes.number,
  disabled: PropTypes.bool,
};

SubstationSelect.defaultProps = {
  status: undefined,
  disabled: false,
};

export default SubstationSelect;
