import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import asyncStates from 'helpers/asyncActionStates';

import ThemeContext from 'helpers/ThemeContext';
import { kW, kVAr } from 'helpers/units';

import EditableControlMode from '../partials/EditableControlMode';
import LifecycleSection from '../partials/LifecycleSection';
import AssetSchedule from '../partials/AssetSchedule';
import ShuntDeviceHeader from '../partials/ShuntDeviceHeader';
import InstanceInfo from './InstanceInfo';

// Control Panel
const controlHelp = {
  uncontrolled:
    "During powerflow and timeseries powerflow, EV Stations set to 'fixed' operate at a constant power.",
  scheduled:
    "During powerflow and timeseries powerflow, EV Stations set to 'schedule' follow the uploaded PQ schedule directly.",
};

const controlPanelValues = {
  uncontrolled: [
    {
      id: 'p', key: 'p', label: 'Active Power', unit: kW, divisor: 1000, type: 'number', min: 0,
    },
    {
      id: 'q', key: 'q', label: 'Reactive Power', unit: kVAr, divisor: 1000, type: 'number', min: 0,
    },
  ],
};

const controlTypes = [
  { value: 'uncontrolled', label: 'Fixed' },
  { value: 'locallyControlled', disabled: true, label: 'Local' },
  { value: 'globallyOptimized', label: 'Global' },
  { value: 'scheduled', label: 'Schedule' },
];

const ElectricVehicleChargingStation = ({
  asset: evCharger,
  workspace,
  branch,
  displayBranch,
  selected: { id, class: assetClass },
  applyDifferenceModelRequest,
  toggleFeederPanel,
  canEditNetwork,
  inEditMode,
  loadForecast: { selectedScenario, selectedScenarioType },
  setSelectedAssetID,
  editActions,
  expanded,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  authEnabled,
  permissions,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'ev_station', id, body);
  const controlMode = evCharger.analysis_control?.mode;
  const controlModeAttributes = {
    ...evCharger.attributes,
  };

  return (
    <>
      <div
        className={classNames({
          'asset-panel-values': true,
          'asset-panel-values--expanded': expanded,
        })}
      >
        <ShuntDeviceHeader
          asset={evCharger}
          assetId={id}
          toggleFeederPanel={toggleFeederPanel}
          inEditMode={inEditMode}
          disabled={saving || !canEditNetwork}
          setSelectedAssetID={setSelectedAssetID}
          handleSave={handleSave}
        />

        <hr className="section-divider" />

        <InstanceInfo
          asset={evCharger}
          assetId={id}
          disabled={saving || !canEditNetwork}
          inEditMode={inEditMode}
          handleSave={handleSave}
          theme={theme}
          workspace={workspace}
          displayBranch={displayBranch}
        />

        <hr className="section-divider" />
        <EditableControlMode
          controlMode={controlMode}
          help={controlHelp[controlMode]}
          asset={assetClass}
          editableValues={controlPanelValues[controlMode]}
          controlModeOptions={controlTypes}
          attributes={controlModeAttributes}
          disabled={saving || !canEditNetwork}
          onSave={handleSave}
          theme={theme}
        />

        {['scheduled', 'globallyOptimized'].includes(controlMode) && !inEditMode && (
          <AssetSchedule
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType={controlMode === 'globallyOptimized' ? 'Global' : 'Normal'}
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            panelValues={{ ...controlModeAttributes }}
            editable={!authEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        )}

        <hr className="section-divider" />

        <LifecycleSection
          disabled={saving || !canEditNetwork || !inEditMode}
          lifecycle={evCharger.lifecycle}
          onChange={lifecycle => handleSave({ lifecycle })}
          healthMetric={evCharger.reliability_info?.healthMetric}
          onHealthMetricChange={handleSave}
        />
      </div>
    </>
  );
};

ElectricVehicleChargingStation.propTypes = {
  asset: PropTypes.object.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  applyDifferenceModelRequest: PropTypes.number.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  editActions: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ElectricVehicleChargingStation;
