import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { networkEditActions } from '../modules/networkEdit';
import { actions } from '../modules/network';

/*
  This is a container component. This component is only responsible for
  wiring in the actions and state necessary to render a presentational
  component
*/

import AssetPanel from '../components/SlidingInfoPanel/AssetPanel';

/*
  Object of action creators (can also be function that returns object).
  Keys will be passed as props to presentational components.
*/

const mapDispatchToProps = dispatch => ({
  editActions: bindActionCreators({
    ...actions,
    ...networkEditActions,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  // From global state
  authEnabled: state.global.authEnabled,
  permissions: state.global.permissions,
  theme: state.global.theme,

  // From network state
  applyDifferenceModelRequest: state.network?.applyDifferenceModelRequest,
  branch: state.network?.branch,
  displayBranch: state.network?.displayBranch,
  SV: state.network?.SV,
  timeRange: state.network?.timeRange,
  maxRange: state.network?.maxRange,
  timeBarZoomLevel: state.network?.timeBarZoomLevel,
  panelOptions: state.network?.panelOptions,
  deleteStatus: state.network?.deleteStatus,
  deleteError: state.network?.errors?.delete,
  selectedAsset: state.network?.selectedAsset,

  // From feeders state
  selectedFeeders: state.feeders.selected,

  // Load forecast state
  loadForecast: state.loadForecast,

  inEditMode: state.edit.inEditMode,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetPanel);
