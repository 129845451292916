import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import asyncStates from 'helpers/asyncActionStates';
import classNames from 'classnames';

import Analytics from 'helpers/Analytics';
import getControlModeName from 'helpers/cim/getControlModeName';
import ThemeContext from 'helpers/ThemeContext';
import { validationSchema } from 'helpers/JSCIM/Shunt/EnergySource';

import AssetSchedule from '../partials/AssetSchedule';
import AnalysisSchedule from '../partials/AnalysisSchedule';
import ControlPanel from '../partials/ControlPanel';
import ShuntDeviceHeader from '../partials/ShuntDeviceHeader';
import InstanceInfo from './InstanceInfo';

import './EnergySource.scss';

const controlTypes = hasEquivalentSubstation => [
  { value: 'fixedValue', label: getControlModeName('EnergySource', 'fixedValue') },
  { value: 'attachedSchedule', label: getControlModeName('EnergySource', 'attachedSchedule') },
  { value: 'analysisSchedule', label: getControlModeName('EnergySource', 'analysisSchedule'), disabled: !hasEquivalentSubstation },
];

const controlHelp = {
  fixedValue: 'During powerflow and timeseries powerflow, operating voltage will be fixed to the specified value',
  attachedSchedule: 'During timeseries powerflow, operating voltage will follow the attached schedule. During powerflow, operating voltage will be fixed to the specified value',
  analysisSchedule: 'During timeseries powerflow, the operating voltage will follow the values from a previously run analysis on the upstream substation.'
      + 'The previous analysis will be selected in the analysis menu. It can be previewed below',
};

// Creates a values template for EnergySource instances
const EnergySource = ({
  asset: energySource,
  workspace,
  branch,
  selected: { id, class: assetClass },
  canEditNetwork,
  inEditMode,
  applyDifferenceModelRequest,
  toggleFeederPanel,
  setSelectedAssetID,
  editActions,
  loadForecast: { selectedScenario, selectedScenarioType },
  timeRange,
  maxRange,
  timeBarZoomLevel,
  permissions,
  authEnabled,
  expanded,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'slack', id, body);

  const hasEquivalentSubstation = !!energySource.linked_feeder;
  const analysisBehavior = energySource.analysis_options?.analysisBehavior;

  return (
    <div
      key={id}
      className={classNames({
        'asset-panel-values': true,
        'asset-panel-values--expanded': expanded,
        'energy-source': true,
      })}
    >
      <ShuntDeviceHeader
        asset={{ ...energySource }}
        assetId={id}
        toggleFeederPanel={toggleFeederPanel}
        inEditMode={inEditMode}
        disabled={saving || !canEditNetwork}
        setSelectedAssetID={setSelectedAssetID}
        handleSave={handleSave}
        phaseOptions="readOnly"
      />

      <hr className="section-divider" />

      <InstanceInfo
        key={id}
        asset={energySource}
        handleSave={handleSave}
        inEditMode={inEditMode}
        disabled={!canEditNetwork || saving}
        theme={theme}
      />

      {!inEditMode
        && (
        <>
          <hr className="section-divider" />
          <AssetSchedule
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Cost"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            editable={!authEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        </>
        )}

      <hr className="section-divider" />

      <ControlPanel
        selectedControlType={analysisBehavior}
        controlTypes={controlTypes(hasEquivalentSubstation)}
        controlPanelValues={[]}
        help={controlHelp[analysisBehavior]}
        onChangeControlType={(value) => {
          handleSave({ analysis_options: { analysisBehavior: value.value } });
          Analytics.logEvent('Change Energy Source Control Mode', 'Control Modes', value.value);
        }}
        validationSchema={validationSchema}
        disabled={saving || !canEditNetwork}
      >
        {analysisBehavior === 'attachedSchedule' && !inEditMode
          && (
          <AssetSchedule
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Normal"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            editable={!authEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
          )}
        {analysisBehavior === 'analysisSchedule' && !inEditMode
          && (
          <AnalysisSchedule
            asset={{
              id,
              class: assetClass,
              linked: energySource.linked_equivalent_substation,
              control_mode: energySource.analysis_options?.analysisBehavior,
              linked_equipment_container: energySource.linked_feeder?.id,
            }}
            branch={branch}
            expanded={expanded}
            maxRange={maxRange}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            timeRange={timeRange}
            workspace={workspace}
            permissions={permissions}
            theme={theme}
            timeBarZoomLevel={timeBarZoomLevel}
          />
          )}
      </ControlPanel>
    </div>
  );
};

EnergySource.propTypes = {
  asset: PropTypes.object.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  editActions: PropTypes.object.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
  }).isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default EnergySource;
