import React, { FunctionComponent } from 'react';

import { CimInstanceLookup } from '../cim';

type LeafNodeProps = {
  selectedClass: string|null;
  instanceLookup: CimInstanceLookup;
  setSelectedClass: (id: string) => void;
};

const LeafNodeView: FunctionComponent<LeafNodeProps> = ({
  selectedClass,
  instanceLookup,
  setSelectedClass,
}) => {
  const classTypes = Object.keys(instanceLookup).sort();
  // Render list item for each type of class in dataset
  const cimTypes = classTypes.map((n) => {
    const item = instanceLookup[n];
    const content = item.name;
    // Determine if the item is the currently selected item
    let style = 'inactive';
    if (selectedClass) {
      style = selectedClass === item.id ? 'active' : 'inactive';
    }
    return (
      <li key={`${item.id}-Type`} className={`cim-types cim-types--${style}`}>
        <button
          className="cim-types__button"
          onClick={() => setSelectedClass(item.id)}
          title={content}
          type="button"
        >
          <i className="material-icons">crop_square</i>
          <span className="cim-types__span">
            {content}
            {' '}
            (
            {item.instanceList.length}
            )
          </span>
        </button>
      </li>
    );
  });

  return (
    <div className="cim-explorer-column-content gray-scrollbars">
      <ul className="cim-explorer-column-content__ul">{cimTypes}</ul>
    </div>
  );
};

export default LeafNodeView;
