import React from 'react';
import PropTypes from 'prop-types';

import { kVA } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';

import SingleEditableValue from '../../Inputs/SingleEditableValue';
import AssetSelector from '../partials/AssetSelector';
import ListValue from '../partials/ListValue';
import './InstanceInfo.scss';

const InstanceInfo = ({
  getContainersList,
  toggleFeederPanel,
  asset: { attributes, linked_substation: linkedSubstation, substations },
  disabled,
  inEditMode,
  assetId,
  theme,
  handleSave,
}) => {
  const selectedSubstation = linkedSubstation?.id ?? 'none';
  const [
    connectedCapacity, setConnectedCapacity, connectedCapacityEdited,
  ] = useTrackingState(attributes?.ratedS);

  return (
    <div>
      <>
        <div className="header-container">
          {inEditMode && (
            <AssetSelector
              name="Substation"
              disabled={disabled}
              assetList={substations}
              onChange={async val => {
                await handleSave(val);
                getContainersList();
              }}
              selectedAsset={selectedSubstation}
              id={assetId}
              linkedAssetType="linked_substation"
            />
          )}
          {!inEditMode && (
            <ListValue
              onClick={toggleFeederPanel}
              values={linkedSubstation || []}
              label="Substation"
            />
          )}
        </div>
        <div className="editable-section">
          <SingleEditableValue
            theme={theme}
            disabled={disabled || !inEditMode}
            unit={kVA}
            id="ratedS"
            label="Connected Capacity"
            type="number"
            min={0}
            divisor={1000}
            precision={2}
            value={connectedCapacity}
            onChange={setConnectedCapacity}
            onBlur={() => {
              handleSave({ attributes: { ratedS: connectedCapacity } });
            }}
            edited={connectedCapacityEdited}
          />
        </div>
      </>
    </div>
  );
};

InstanceInfo.defaultProps = {
  disabled: true,
  theme: 'dark',
  inEditMode: false,
};

InstanceInfo.propTypes = {
  assetId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  asset: PropTypes.object.isRequired, // JSCIM Object
  toggleFeederPanel: PropTypes.func.isRequired,
  getContainersList: PropTypes.func.isRequired,
  theme: PropTypes.string,
  inEditMode: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
};

export default InstanceInfo;
