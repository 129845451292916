import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

import './PermissionDisabledTip.scss';

// Create a specialized tool tip that will show a message about being diabled in a demo.
const PermissionDisabledTip = ({
  title, children, hide, theme, message, ...rest
}) => (hide ? (
  children
) : (
  <Tooltip
    {...rest}
    expires={false}
    theme={theme}
    content={(
      <div className="permission-disabled-tip">
        <h3>{title}</h3>
        {message}
      </div>
    )}
  >
    {children}
  </Tooltip>
));

PermissionDisabledTip.defaultProps = {
  hide: true,
  placement: undefined, // Auto
  theme: 'dark',
  message: 'You don\'t have permission to access this feature.',
};

PermissionDisabledTip.propTypes = {
  hide: PropTypes.bool,
  title: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'none']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  theme: PropTypes.string,
  message: PropTypes.string,
};

export default PermissionDisabledTip;
