import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import './NodeDisplayContainer.scss';

const NodeDisplayContainer = ({
  disabled, onSwap, canSwap, icon, onNodeClick,
  leftNode, rightNode, theme,
}) => (
  <div className="node-display-container">
    <div className="list-value-row-label">Nodes</div>
    <div className="swap-container">
      <div className="display-row">
        <button
          className="list-value-row-value node-display-container-node"
          onClick={() => onNodeClick(leftNode)}
          type="button"
        >
          {leftNode.name}
        </button>
        <div className="between-nodes">
          {icon && (
          <div className="icon-container">
            <ReactSVG className="node-display-container-icon" src={`/${icon}.svg`} />
            {canSwap
              && (
              <IconButton
                icon="swap_horiz"
                theme={theme}
                title="Swap Nodes"
                onClick={onSwap}
                disabled={disabled}
              />
              )}
          </div>
          )}
        </div>
        <button
          className="list-value-row-value node-display-container-node"
          onClick={() => onNodeClick(rightNode)}
          type="button"
        >
          {rightNode.name}
        </button>
      </div>
    </div>
  </div>
);

NodeDisplayContainer.defaultProps = {
  disabled: false,
  canSwap: false,
  icon: null,
  onNodeClick: () => {},
  onSwap: () => {},
};

NodeDisplayContainer.propTypes = {
  disabled: PropTypes.bool,
  onSwap: PropTypes.func,
  onNodeClick: PropTypes.func,
  canSwap: PropTypes.bool,
  icon: PropTypes.string,
  leftNode: PropTypes.object.isRequired,
  rightNode: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

export default NodeDisplayContainer;
