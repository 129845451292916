import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';

import './ZoomControls.scss';

/**
 * Creates the zoom in and zoom out control buttons for leaflet map.
 * Buttons can be placed absolutely with the position prop.
 * Zoom functions must be provided as props. Must be an anonymous function that
 * invokes the leaflet library zoom functions or the internal setZoom function fails.
 */
const ZoomControls = props => (
  <div className="zoom-controls" style={props.position}>
    <div className="button-pair">
      <IconButton
        className="zoom-controls__button zoom-controls-plus zoom-controls-top"
        onClick={props.zoomIn}
        tooltip="Zoom In"
        icon="add"
        theme={props.theme}
      />
      <IconButton
        icon="remove"
        className="zoom-controls__button zoom-controls-minus zoom-controls-bottom"
        onClick={props.zoomOut}
        tooltip="Zoom Out"
        theme={props.theme}
      />
    </div>

    {props.resetTilt && props.resetOrientation && (
      <div className="button-pair">
        <IconButton
          icon="360"
          className="zoom-controls__button zoom-controls-top"
          onClick={props.resetTilt}
          tooltip={props.isTilted ? 'Reset Tilt' : ''}
          theme={props.theme}
          disabled={!props.isTilted}
          rotate={90}
        />
        <IconButton
          icon="compass_calibration"
          className="zoom-controls__button zoom-controls-bottom"
          onClick={props.resetOrientation}
          tooltip={props.orientationChanged ? 'Reset Orientation' : ''}
          theme={props.theme}
          disabled={!props.orientationChanged}
        />
      </div>
    )}

    <div className="zoom-to-fit">
      <IconButton
        className="zoom-to-fit-btn"
        onClick={props.zoomToBounds}
        tooltip="Zoom to Fit"
        icon="zoom_out_map"
        theme={props.theme}
      />
    </div>
  </div>
);

ZoomControls.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  zoomToBounds: PropTypes.func.isRequired,
  resetTilt: PropTypes.func,
  resetOrientation: PropTypes.func,
  isTilted: PropTypes.bool,
  orientationChanged: PropTypes.bool,
  position: PropTypes.object,
  theme: PropTypes.string,
};

ZoomControls.defaultProps = {
  resetTilt: undefined,
  resetOrientation: undefined,
  isTilted: false,
  orientationChanged: false,
  position: {},
  theme: 'dark',
};

export default ZoomControls;
