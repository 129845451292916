import TextInput from 'components/TextInput';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ThemeContext from 'helpers/ThemeContext';

import ListValue from './ListValue';
import PhaseSelectorComboBox from './PhaseSelectorComboBox';
import PhaseSelectorCheckboxes from './PhaseSelectorCheckboxes';
import AssetSelector from './AssetSelector';

const ShuntDeviceHeader = ({
  asset: {
    container,
    node,
    nodes = [],
    phases,
    balanced,
  },
  assetId,
  toggleFeederPanel,
  inEditMode,
  disabled,
  setSelectedAssetID,
  handleSave,
  phaseOptions,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className="header-container">
      <ListValue onClick={toggleFeederPanel} values={container} label="Feeder/Substation" />
      {inEditMode && (
        <AssetSelector
          name="Node"
          disabled={disabled}
          assetList={nodes}
          onChange={handleSave}
          selectedAsset={node.id}
          id={assetId}
          linkedAssetType="node"
        />
      )}
      {!inEditMode && (
        <ListValue onClick={() => setSelectedAssetID(node.id)} values={node} label="Node" />
      )}
      <div className="panel-header-row">
        {phaseOptions === 'single' && (
          <>
            <div className="left-label">Phase</div>
            <PhaseSelectorComboBox
              disabled={disabled || !inEditMode}
              onChange={p => handleSave({ phases: p })}
              phase={phases}
            />
          </>
        )}
        {phaseOptions === 'many' && (
          <PhaseSelectorCheckboxes
            disabled={disabled || balanced || !inEditMode}
            onClick={p => {
              let newPhases = p;
              if (phases.includes(p)) {
                newPhases = phases.replace(p, '');
              } else {
                const phaseArray = phases.split('');
                phaseArray.push(p);
                phaseArray.sort();
                newPhases = phaseArray.join('');
              }
              handleSave({ balanced, phases: newPhases });
            }}
            phases={phases}
          />
        )}
        {phaseOptions === 'readOnly' && (
          <TextInput
            label="Phase"
            className="display-only"
            value={phases}
            id="phase"
            inputStyle="panel"
            theme={theme}
            disabled
          />
        )}
      </div>
    </div>
  );
};

ShuntDeviceHeader.defaultProps = {
  phaseOptions: 'single',
};

ShuntDeviceHeader.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  assetId: PropTypes.string.isRequired,
  phaseOptions: PropTypes.oneOf(['single', 'many', 'readOnly']),
};

export default ShuntDeviceHeader;
