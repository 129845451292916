import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import ModalPortal from 'components/ModalPortal';
import Helpers from 'routes/WorkspaceLayout/routes/EquipmentLibrary/helpers/EquipmentLibraryHelpers';
import EquipmentBadge from 'routes/WorkspaceLayout/routes/EquipmentLibrary/components/EquipmentBadge.js';
import { getConfigurationType, isMulti } from 'helpers/JSCIM/AssetInfo/WireSpacingInfo';
import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';

function byConfigurationTypeThenLabel(a, b) {
  a.configurationType = getConfigurationType(a);
  b.configurationType = getConfigurationType(b);
  return Helpers.byConfigurationTypeThenLabel(a, b);
}

function groupDisabled(options) {
  // bucket by disabled vs enabled
  const groupedOptions = options.reduce((optionMap, el) => {
    if (el.disabled) optionMap.disabled.push(el);
    else optionMap.enabled.push(el);
    return optionMap;
  }, { disabled: [], enabled: [] });

  // unbucket by group
  return groupedOptions.enabled.concat(groupedOptions.disabled);
}

const WireSpacingSelector = ({
  asset: {
    attributes,
    container,
    wire_spacing_info = {},
    wire_spacing_info_options = [],
    phase: phaseCode,
  },
  workspace,
  displayBranch,
  disabled,
  inEditMode,
  handleSave,
  theme,
}) => {
  if (!wire_spacing_info) wire_spacing_info = {};
  wire_spacing_info_options.sort(byConfigurationTypeThenLabel);
  const [unconfirmedChange, setUnconfirmedChange] = useState({ message: '', action: null });
  const clearUnconfirmedChange = () => setUnconfirmedChange({ message: '', action: null });
  const handleSpacingSelect = spacing => {
    if (spacing === null) {
      return setUnconfirmedChange({
        message: (
          <>
            <p className="modal-message__p">This change will clear the line type.</p>
            <p className="modal-message__p">
              As a consequence, existing links to conductors will
              be unset and impedances will be cleared.
            </p>
          </>
        ),
        action: () => {
          handleSave({ wire_spacing_info: null, phase_wire_info: null });
          clearUnconfirmedChange();
        },
      });
    }
    let deviceDescription;
    if (isMulti(spacing) && !isMulti(wire_spacing_info)) deviceDescription = 'a multiconductor';
    if (isMulti(wire_spacing_info) && !isMulti(spacing)) deviceDescription = 'an open 4-wire distribution line';
    if (!wire_spacing_info?.is_cable && spacing.isCable === true) deviceDescription = 'a cable';
    if (wire_spacing_info?.is_cable === true && !(spacing?.isCable)) deviceDescription = 'a line';
    if (deviceDescription) {
      return setUnconfirmedChange({
        message: (
          <>
            <p className="modal-message__p">
              {`This change will convert your asset into ${deviceDescription}.`}
            </p>
            <p className="modal-message__p">As a consequence, existing links to conductors will be unset.</p>
          </>
        ),
        action: () => {
          handleSave({ wire_spacing_info: spacing.value, phase_wire_info: null });
          clearUnconfirmedChange();
        },
      });
    }

    // if we don't need confirmation, skip right to saving the change
    return handleSave({ wire_spacing_info: spacing.value });
  };

  const spacingIsValid = (wire_spacing) => {
    const nonNeutralPhaseList = phaseCode.replace('N', '').split('');
    // can't use a triplex secondary on a multi-phase line
    if (nonNeutralPhaseList.length > 1 && wire_spacing.usage === 'secondary') return false;
    return true;
  };

  const spacingToOption = ({
    id, name, is_cable, usage, wire_positions = [],
  }) => ({
    value: id,
    label: (
      <div className="instanceinfo-select-item" title={name || id}>
        <p className="instanceinfo-select-item-label">{name || id}</p>
        <EquipmentBadge
          equipmentInfoItem={{
            name: name || id,
            class: 'WireSpacingInfo',
            configurationType: getConfigurationType({ wire_positions, usage }),
          }}
        />
      </div>
    ),
    disabled: !spacingIsValid({ wire_positions, usage }),
    usage,
    wire_positions,
    isCable: is_cable,
    name: name || id,
  });

  const spacingOptions = () => groupDisabled(wire_spacing_info_options.map(spacingToOption));

  return (
    <div className="panel-header-row" key="header-row-3">
      <div className="left-label" key="left-label-2">
        Geometry
      </div>
      <EquipmentInfoSelector
        id="wire-spacing-select"
        onChange={handleSpacingSelect}
        loadOptions={spacingOptions}
        defaultOptions={
          inEditMode
            ? spacingOptions()
            : [spacingToOption(wire_spacing_info ?? {})]
        }
        eqLibURL={`/${workspace}/${displayBranch}/library/wireGeometries`}
        disabled={disabled}
        value={wire_spacing_info.id}
        assetId={attributes.id}
        feeder={container.id}
        clearable
      />
      {(unconfirmedChange.message) && (
        <ModalPortal id="modal-portal">
          <Modal
            width="350px"
            active
            title="Type Change Warning"
            theme={theme}
            onCancel={clearUnconfirmedChange}
            onConfirm={unconfirmedChange.action}
          >
            {unconfirmedChange.message}
            <p className="modal-message__p">
              Would you like to proceed with this change?
            </p>
          </Modal>
        </ModalPortal>
      )}
    </div>
  );
};

WireSpacingSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default WireSpacingSelector;
