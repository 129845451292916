import axios from 'axios';
import qs from 'qs';
import apm from 'helpers/apm';
import getEnvironmentInfo from 'helpers/EnvironmentInfo';
import Request from 'helpers/Request';
import { actions } from 'store/appState';

function getLoginData(workspace) {
  const loginDataUri = `/auth/login?workspace=${encodeURIComponent(workspace || '')}`;
  return axios.get(loginDataUri, { headers: { 'X-Proxy-Options': 'raw' } });
}

const getGAUserId = (email, userId) => {
  const totallyGDPRCompliantEmail = email && email.match(/^(.*)@/)[1];
  return totallyGDPRCompliantEmail ? `${totallyGDPRCompliantEmail}_${userId}` : undefined;
};

const STATIC_ROUTES = [
  'api-docs',
  'health-check',
  'profile',
  'settings',
];

/**
 * Using the response from services, determine if the user should be redirected
 * and store the active product in state
 */
const processServicesResponse = async (
  resp,
  nextState,
  dispatch,
  replace,
  asyncGetLoginData,
) => {
  dispatch(actions.setAuthStatus(resp.auth));
  dispatch(actions.loadSettings());

  // configure the APM to send responses to the apm server
  if (resp.apm) {
    try {
      const envInfo = await getEnvironmentInfo();
      apm.configureApm(envInfo);
    } catch (error) {
    }
  }

  // If there is no auth, we can bail out early
  if (resp.auth === false) {
    // If we mark we are loaded before we mark being authenticated,
    // the app fails to work because of how ProtectedRoute renders multiple times
    dispatch(actions.setAuthenticated());
    dispatch(actions.setAuthLoaded());
    return;
  }

  const notLogin = !nextState.location.pathname.includes('/login');
  let noTokenForWorkspace = localStorage.getItem('encBearerToken') === null;

  // extract the workspace name out of the path
  let workspaceName = '';
  let pathForLogin = nextState.location.pathname;

  if (pathForLogin === '/login' || pathForLogin === '/login/oauth-callback') {
    pathForLogin = '';
  } else {
    if (pathForLogin.endsWith('/login')) {
      pathForLogin = pathForLogin.substring(0, pathForLogin.length - '/login'.length);
    }
    workspaceName = pathForLogin.substr(1).split('/')[0] || '';
  }
  if (workspaceName.length <= 1) {
    try {
      const query = qs.parse(nextState.location.search.substr(1));
      workspaceName = JSON.parse(query.state).workspace;
    } catch (Error) {
      workspaceName = '';
    }
  }

  if (STATIC_ROUTES.indexOf(workspaceName) !== -1) {
    workspaceName = '';
  }

  dispatch(actions.setWorkspaceForTenant(workspaceName));

  const prevAuthEndpoint = localStorage.getItem('authEndpoint');
  const prevWorkspace = localStorage.getItem('authWorkspace') || '';

  const response = await asyncGetLoginData(workspaceName);
  // we need to keep track of the auth endpoint because
  // we will use that to see if we need to re-login
  const authEndpoint = response.data.authorization_endpoint;

  // make sure the token we have matches the auth endpoint, if not clear it
  if (!noTokenForWorkspace && (authEndpoint !== prevAuthEndpoint) && (prevWorkspace !== '')) {
  // if we have changed tenants AND we didn't login in as the root tenant then
  // we will need to log in again so set noTokenForWorkspace to true
    noTokenForWorkspace = true; // no suitable token for workspace
    dispatch(actions.setAuthWorkspaceMismatch(true));
  } else {
    dispatch(actions.setAuthWorkspaceMismatch(false));
  }

  if (notLogin && noTokenForWorkspace) {
    if (pathForLogin !== '') {
      localStorage.setItem('postLoginRedirect', pathForLogin);
    }

    if (workspaceName && workspaceName.length >= 1 && workspaceName !== '') {
      // go to workspace login page
      replace(`/${workspaceName}/login`);
    } else if (prevWorkspace !== undefined && prevWorkspace.length >= 1) {
      // go to previous workspace login page
      replace(`/${prevWorkspace}/login`);
    } else {
      // go to master login page
      replace('/login');
    }
  } else if (notLogin) {
    const request = new Request('/api/permissions');

    try {
      const { data } = await request.get();
      dispatch(actions.updatePermissions(data));
      dispatch(actions.setAuthenticated());
    } catch (error) {
      apm.captureError(error);
    }
  } else {
    dispatch(actions.setAuthenticated());
  }

  dispatch(actions.setAuthLoaded());
};

/**
 * Get data from services endpoint on which products are enabled and if auth
 * is to be presented to the user
 */
async function requireAuth(nextState, replace, dispatch) {
  const { data } = await axios('/api/services', { headers: { 'X-Proxy-Options': 'raw' } });
  await processServicesResponse(data, nextState, dispatch, replace, getLoginData);
}

async function logout(authProvider = 'auth0') {
  const authEndpoint = localStorage.getItem('authEndpoint');
  const workspace = localStorage.getItem('authWorkspace');
  const returnAddress = `${window.location.origin}/login`;

  localStorage.removeItem('authEndpoint');
  localStorage.removeItem('authWorkspace');
  localStorage.removeItem('encBearerToken');
  localStorage.removeItem('username');
  localStorage.removeItem('userId');

  // set a token that we can use when returning to the login page to put them back on the workspace
  // login page
  if (workspace) {
    localStorage.setItem('loginRedirect', workspace);
  }

  const { data } = await getLoginData(workspace);
  let logoutUri = '';

  if (authProvider !== 'auth0') {
    logoutUri = `${authEndpoint.replace(/\/auth$/, '/logout')}`
      + `?redirect_uri=${encodeURIComponent(returnAddress)}`
      + `&client_id=${data.client_id}`;
  } else {
    logoutUri = `${authEndpoint.replace(/\/authorize$/, '/v2/logout')}`
      + `?returnTo=${encodeURIComponent(returnAddress)}`
      + `&client_id=${data.client_id}`;
  }

  window.location.assign(logoutUri);
}

function getLoginUri({ data }, workspace) {
  const baseUri = window.location.origin;
  const {
    authorization_endpoint: authEndpoint,
    client_id: clientId,
    response_type: responseType,
    scope,
  } = data;
  const state = Math.random().toString(36).slice(-5);
  const redirectUri = `${baseUri}/login/oauth-callback`;
  const uri_state = JSON.stringify({
    state,
    redirect_uri: redirectUri,
    workspace,
  });

  return `${authEndpoint}?scope=${scope}&response_type=${responseType}`
    + `&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}`
    + `&state=${encodeURIComponent(uri_state)}`;
}

function getToken(response) {
  const { code } = response;
  const { state } = response;
  const tokenEndpoint = '/auth/oauth-callback';
  const uri = `${tokenEndpoint}?code=${code}&state=${state}`;
  return axios.get(uri, { headers: { 'X-Proxy-Options': 'raw' } });
}

export {
  getGAUserId,
  getLoginData,
  getLoginUri,
  getToken,
  logout,
  processServicesResponse,
  requireAuth,
};
