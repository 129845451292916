import React, {
  createContext, useState, useCallback, useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useInterval } from 'helpers/hooks';
import Request from 'helpers/Request';
import Analytics from 'helpers/Analytics';

import { getContainersList } from 'routes/WorkspaceLayout/routes/Network/modules/feeders';

const COMPLETED = 'COMPLETED';
const FAILED = 'FAILED';
const CANCELED = 'CANCELED';

const doneStatuses = [FAILED, COMPLETED, CANCELED];

const initial = {
  imports: {
    done: [],
    pending: [],
  },
  initialized: false,
  error: false,
  deleteImport: () => undefined,
};

const isDone = importJob => doneStatuses.includes(importJob.status);

const classifyImports = imports => imports.reduce(
  (acc, job) => (isDone(job)
    ? { ...acc, done: [...acc.done, job] }
    : { ...acc, pending: [...acc.pending, job] }),
  initial.imports,
);

export const jobsHaveCompleted = (prevJobs, newJobs) => {
  const [prevStatusMap, newStatusMap] = [
    [...prevJobs],
    [...newJobs]].map(jobs => jobs.reduce((statusMap, job) => ({ ...statusMap, [job.id]: job.status }), {}));
  const hasCompleted = jobId => prevStatusMap[jobId] !== COMPLETED && newStatusMap[jobId] === COMPLETED;

  return Object.keys(newStatusMap).some(hasCompleted);
};

export const importJobs = async (workspace, branch, { imports }, setImports, getContainers) => {
  const request = new Request(`/api/workspace/${workspace}/branch/${branch}/import`);
  try {
    const { data: newImports } = await request.get();

    if (jobsHaveCompleted([...imports.done, ...imports.pending], newImports)) {
      getContainers();
    }

    setImports({ imports: classifyImports(newImports), initialized: true });
  } catch (error) {
    setImports({ imports: initial.imports, initialized: true, error: true });
  }
};

export const deleteImportJob = async (workspace, branch, importId) => {
  const request = new Request(`/api/workspace/${workspace}/branch/${branch}/import/${importId}`);
  Analytics.logEvent('Delete File', 'File Imports');
  try {
    await request.delete();
  } catch (error) {
  }
};

// Need to use a wrapper for async useEffect callbacks https://www.robinwieruch.de/react-hooks-fetch-data
const updateImports = (...args) => {
  importJobs(...args);
};

export const ImportsContext = createContext(initial);
export const useImportsContext = () => useContext(ImportsContext);
const ImportsContextProvider = ({ workspace, branch, children }) => {
  const [imports, setImports] = useState(initial);

  // Prevent unnecessary rerenders https://react-redux.js.org/api/hooks
  const dispatch = useDispatch();
  const getContainers = useCallback(() => dispatch(getContainersList([])),
    [dispatch]);
  const updateImportJobs = () => updateImports(workspace, branch, imports, setImports, getContainers);

  const deleteImport = async (importId) => {
    await deleteImportJob(workspace, branch, importId);
    updateImportJobs();
  };
  useInterval(updateImportJobs, 5000);

  return (
    <ImportsContext.Provider value={{ ...imports, deleteImport }}>
      {children}
    </ImportsContext.Provider>
  );
};

ImportsContextProvider.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ImportsContextProvider;
