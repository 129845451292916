import { isRegulator } from 'routes/WorkspaceLayout/routes/Network/helpers/LinkHelpers';

const getAsyncMachineType = (item, lu) => {
  if (lu[item] && lu[item].references['RotatingMachine.GeneratingUnit']) {
    let rmID = lu[item].references['RotatingMachine.GeneratingUnit'];
    if (Array.isArray(rmID)) {
      [rmID] = rmID;
    }
    const type = lu[rmID].class;
    return type === 'SolarGeneratingUnit' ? 'PhotoVoltaic' : 'Wind';
  }
  return 'AsynchronousMachine';
};

const getTransformerType = (item, lu) => {
  if (lu[item].class === 'PowerTransformer') {
    const regulator = isRegulator(lu[item], lu);
    return regulator ? 'Regulator' : 'PowerTransformer';
  }
  return null;
};

export const getInverterType = (item_id, lu) => {
  const item = lu[item_id];
  if (!['PowerElectronicsConnection', 'ScheduledPowerElectronicsConnection', 'Inverter'].includes(item.class)) {
    return null;
  }

  const unit_ids = item.references['PowerElectronicsConnection.PowerElectronicsUnit'];
  const all_pv = unit_ids.every((id) => {
    const unit = lu[id];
    return unit.class === 'PhotoVoltaicUnit';
  });
  return all_pv ? 'InverterPV' : 'Battery';
};

const mapping = (asset, lookup) => ({
  ACLineSegment: {
    category: 'cables',
    JSCIM: 'ACLineSegment',
  },
  AsynchronousMachine: {
    category: 'shuntDevices',
    JSCIM: getAsyncMachineType(asset, lookup),
  },
  Battery: {
    category: 'shuntDevices',
    JSCIM: 'Battery',
  },
  Breaker: {
    category: 'linkDevices',
    JSCIM: 'Breaker',
  },
  CHP: {
    category: 'shuntDevices',
    JSCIM: 'CHP',
  },
  ConnectivityNode: {
    category: 'connectivityNodes',
    JSCIM: 'ConnectivityNode',
  },
  Cut: {
    category: 'linkDevices',
    JSCIM: 'Cut',
  },
  Disconnector: {
    category: 'linkDevices',
    JSCIM: 'Disconnector',
  },
  ElectricVehicleChargingStation: {
    category: 'shuntDevices',
    JSCIM: 'ElectricVehicleChargingStation',
  },
  EnergyConsumer: {
    category: 'shuntDevices',
    JSCIM: 'EnergyConsumer',
  },
  EquivalentSubstation: {
    category: 'shuntDevices',
    JSCIM: 'EquivalentSubstation',
  },
  EnergySource: {
    category: 'shuntDevices',
    JSCIM: 'EnergySource',
  },
  Fuse: {
    category: 'linkDevices',
    JSCIM: 'Fuse',
  },
  Inverter: {
    category: 'shuntDevices',
    JSCIM: getInverterType(asset, lookup),
  },
  InverterPV: {
    category: 'shuntDevices',
    JSCIM: 'InverterPV',
  },
  Jumper: {
    category: 'linkDevices',
    JSCIM: 'Jumper',
  },
  LinearShuntCompensator: {
    category: 'shuntDevices',
    JSCIM: 'LinearShuntCompensator',
  },
  PhotoVoltaic: {
    category: 'shuntDevices',
    JSCIM: 'PhotoVoltaic',
  },
  PowerElectronicsConnection: {
    category: 'shuntDevices',
    JSCIM: getInverterType(asset, lookup),
  },
  PowerTransformer: {
    category: 'linkDevices',
    JSCIM: getTransformerType(asset, lookup),
  },
  Recloser: {
    category: 'linkDevices',
    JSCIM: 'Recloser',
  },
  Regulator: {
    category: 'linkDevices',
    JSCIM: 'Regulator',
  },
  RunOfRiverHydro: {
    category: 'shuntDevices',
    JSCIM: 'RunOfRiverHydro',
  },
  ScheduledPowerElectronicsConnection: {
    category: 'shuntDevices',
    JSCIM: 'Battery',
  },
  Sectionaliser: {
    category: 'linkDevices',
    JSCIM: 'Sectionaliser',
  },
  SynchronousMachine: {
    category: 'shuntDevices',
    JSCIM: 'SynchronousMachine',
  },
  Switch: {
    category: 'linkDevices',
    JSCIM: 'Switch',
  },
  Wind: {
    category: 'shuntDevices',
    JSCIM: 'Wind',
  },
});

export default mapping;
