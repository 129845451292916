/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import './ControlPanel.scss';

const ControlPanel = props => (
  <ThemeContext.Consumer>
    {theme => (
      <div className="control-mode">
        <div className="control-mode-aux-content">
          <span className="control-mode-title">Control Mode</span>
          <div className="control-mode-select">
            <Select
              name="control-panel-control-type"
              id="control-panel-control-type"
              theme={theme}
              value={props.selectedControlType}
              className="custom-react-select-theme"
              disabled={props.disabled}
              placeholder="Select Control Type"
              options={props.controlTypes}
              clearable={false}
              searchable={false}
              onChange={props.onChangeControlType}
            />
            {props.help && (
              <Tooltip placement="left" content={props.help}>
                <i className="material-icons help-icon">help_outline</i>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="editable-section">
          {props.controlPanelValues.map(value => (
            <div className="input-spacing" key={value.id}>
              <SingleEditableValue
                {...(props.selected.validationSchema
                  ? props.selected.validationSchema[value.id]
                  : {})}
                {...props}
                {...value}
                key={value.id}
                name={value.id}
              />
            </div>
          ))}
        </div>
        {props.children}
        <hr className="section-divider" />
      </div>
    )}
  </ThemeContext.Consumer>
);

ControlPanel.defaultProps = {
  disabled: false,
  selected: {},
  children: null,
  controlPanelValues: [],
  isRegulator: false,
  help: '',
  controlMode: 'uncontrolled',
  normalStep: null,
  onChangeControlType: undefined,
};

ControlPanel.propTypes = {
  disabled: PropTypes.bool,
  isRegulator: PropTypes.bool,
  controlMode: PropTypes.string,
  normalStep: PropTypes.object,
  selectedControlType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controlTypes: PropTypes.array.isRequired,
  controlPanelValues: PropTypes.array,
  onChangeControlType: PropTypes.func,
  selected: PropTypes.shape({
    validationSchema: PropTypes.object,
  }),
  children: PropTypes.node,
};

export default ControlPanel;
