import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';

import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';

import './AssetSelector.scss';

const AssetSelector = (props) => {
  const [value, setValue] = useState();

  const [assetList, setAssetList] = useState();

  const processAssets = () => {
    const options = [];
    if (props.linkedAssetType === 'linked_substation') options.push({ label: 'None', value: 'none' });
    props.assetList.forEach((asset) => {
      options.push({
        label: asset.name,
        value: asset.id,
      });
    });
    alphabetizeByKey(options, 'label');

    setAssetList(options);
  };

  useEffect(() => setValue(props.selectedAsset), [props.selectedAsset]);
  useEffect(processAssets, [props.assetList, props.linkedAssetType]);

  const changeValue = async (val) => {
    setValue(val);
    if (value !== val) {
      const newVal = val === 'none' ? null : val;
      await props.onChange({ [props.linkedAssetType]: newVal });
    }
  };

  return (
    <div className="asset-selector-row">
      <div className="left-label">{props.name}</div>
      <div className="dropdown">
        <Select
          value={value || props.selectedAsset}
          className="custom-react-select-theme"
          disabled={props.disabled}
          clearable={false}
          searchable
          theme={useContext(ThemeContext)}
          onChange={val => changeValue(val.value)}
          options={assetList}
        />
      </div>
    </div>
  );
};

AssetSelector.defaultProps = {
  disabled: false,
  selectedAsset: '',
  assetList: [],
};

AssetSelector.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  assetList: PropTypes.array, // eslint-disable-line
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedAsset: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  linkedAssetType: PropTypes.string.isRequired,
};

export default AssetSelector;
