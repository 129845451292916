import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PanelHeader from './PanelHeader';
import './WorkspacePanel.scss';

/**
 * Creates the workspace details section of the workspace right panel.
 * Contains area to validate the workspace for powerflow.
 */
const WorkspacePanel = props => (
  <div
    className={classNames({
      'workspace-details': true,
      'workspace-details--expanded': props.expanded,
    })}
  >
    <PanelHeader
      name={props.workspace}
      type="Workspace"
      icon="/ic_workspace.svg"
      header="Information"
    />
  </div>
);

WorkspacePanel.defaultProps = {
};

WorkspacePanel.propTypes = {
  workspace: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default WorkspacePanel;
