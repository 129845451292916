import React from 'react';
import PropTypes from 'prop-types';

const InverterConstraintDiagramLegend = ({
  showPowerFactor,
  showPowerLimits,
}) => (
  <div className="legend">
    <ul>
      {showPowerLimits && (
        <li>
          <span className="legend-icon legend-icon-pq" />
          Active and Reactive Power Limits
        </li>
      )}
      <li>
        <span className="legend-icon legend-icon-s" />
        Apparent Power Limit
      </li>
      {showPowerFactor && (
        <li>
          <span className="legend-icon legend-icon-pf" />
          Minimum Power Factor
        </li>
      )}
    </ul>
  </div>
);

InverterConstraintDiagramLegend.defaultProps = {
  showPowerFactor: true,
  showPowerLimits: true,
};

InverterConstraintDiagramLegend.propTypes = {
  showPowerFactor: PropTypes.bool,
  showPowerLimits: PropTypes.bool,
};

export default InverterConstraintDiagramLegend;
