import { isDefined } from 'helpers/utils';
/**
 * Create a lookup up of p or q values per phase for EnergyConsumers
 * @param  {String} type    Attribute being processed
 * @param  {Array}  phases  List of phase instances
 * @param  {Object} lookup  The CIM instance lookup
 * @return {Object}         Values indexed by phase
 */
const extractPQ = (type, phases, lookup) => {
  try {
    const values = {};
    phases.forEach((phase) => {
      const { attributes } = lookup[phase];
      values[attributes['EnergyConsumerPhase.phase']] = attributes[`EnergyConsumerPhase.${type}fixed`];
    });
    return values;
  } catch (err) {
    return {};
  }
};

const extractUsagePoints = (usagePoints, lookup) => {
  let usagePointObjects;
  try {
    if (usagePoints) {
      usagePointObjects = usagePoints.map((point) => {
        let phases; let ratedPower; let id; const demandResponse = {};
        if (lookup && lookup[point]) {
          phases = lookup[point].attributes['UsagePoint.phaseCode'] || '';
          ratedPower = lookup[point].attributes['UsagePoint.ratedPower'] || 0;
          const usagePointGroup = lookup[point].references['UsagePoint.UsagePointGroups']?.[0];
          if (usagePointGroup && lookup[usagePointGroup]) {
            const demandResponseID = lookup[usagePointGroup].references['UsagePointGroup.DemandResponsePrograms']?.[0];
            if (demandResponseID && lookup[demandResponseID]) {
              const demandResObject = lookup[demandResponseID];
              demandResponse.class = demandResObject.class;
              demandResponse.attributes = demandResObject.attributes;
            }
          }
          id = point;
        }
        return {
          phases,
          ratedPower,
          id,
          demandResponse,
        };
      });
    }
    return usagePointObjects;
  } catch (err) {
    return usagePointObjects;
  }
};

/**
 * Calculate the average v for a link device
 * @param  {Object} voltages Lookup with v values for all available phases
 * @return {Object}          Average v value per phase
 */
const calculateAverageV = (voltages) => {
  const getAverage = (phase, data, type) => {
    const total = data.reduce((sum, terminal) => {
      const key = `v_mag_${phase.toLowerCase()}${type}`;
      if (!terminal[key]) return sum;
      return sum + terminal[key];
    }, 0);
    return total / data.length;
  };

  try {
    const withData = voltages.filter(lu => lu && Object.keys(lu).length > 0);

    if (withData.length === 0) { return {}; }
    return {
      A_avg: getAverage('A', withData, '_avg'),
      B_avg: getAverage('B', withData, '_avg'),
      C_avg: getAverage('C', withData, '_avg'),
      A_min: getAverage('A', withData, '_min'),
      B_min: getAverage('B', withData, '_min'),
      C_min: getAverage('C', withData, '_min'),
      A_max: getAverage('A', withData, '_max'),
      B_max: getAverage('B', withData, '_max'),
      C_max: getAverage('C', withData, '_max'),
      ABC_min_min: getAverage('ABC', withData, 'min_min'),
      ABC_min_max: getAverage('ABC', withData, 'min_max'),
      ABC_min_avg: getAverage('ABC', withData, 'min_avg'),
      ABC_max_min: getAverage('ABC', withData, 'max_min'),
      ABC_max_max: getAverage('ABC', withData, 'max_max'),
      ABC_max_avg: getAverage('ABC', withData, 'max_avg'),
      ABC_avg_min: getAverage('ABC', withData, 'avg_min'),
      ABC_avg_max: getAverage('ABC', withData, 'avg_max'),
      ABC_avg_avg: getAverage('ABC', withData, 'avg_avg'),
    };
  } catch (err) {
    return {};
  }
};

const getAverageV = (voltages, asset) => {
  try {
    const nodes = asset.nodes ?? asset.topological_nodes;
    const terminalVoltages = nodes.map(node => voltages[node.id]);
    return calculateAverageV(terminalVoltages);
  } catch (err) {
    return null;
  }
};

/**
 * Calculate the per unit value of a node.
 * Assumes both voltages are in the same units
 * @param  {Object} bv   Base Voltage of the node
 * @param  {Object} sv   The sv voltage values per phase
 * @return {Object}      The per unit value per phase
 */
const calculatePerUnit = (bv, sv) => {
  if (!bv || !sv) return {};
  let bvV;
  if (typeof bv === 'object') {
    bvV = bv.attributes['BaseVoltage.nominalVoltage'];
  } else {
    bvV = bv;
  }
  const puPerPhase = {};
  const phases = Object.keys(sv);
  phases.forEach((phase) => {
    const hasSV = isDefined(sv[phase]);
    if (hasSV && !!bvV) {
      puPerPhase[phase] = sv[phase] / bvV;
    }
  });
  return puPerPhase;
};

const getUniqueCoordinates = coordList => (
  coordList.reduce((list, coord, i) => {
    if (list.length === 0) {
      return list.concat(coord);
    }

    const prevCoord = list.slice(-1)[0];
    const sameX = prevCoord['PositionPoint.xPosition'] === coord['PositionPoint.xPosition'];
    const sameY = prevCoord['PositionPoint.yPosition'] === coord['PositionPoint.yPosition'];
    if (!sameX || !sameY) {
      return list.concat(coord);
    }

    if (i === (coordList.length - 1) && list.length === 1) {
      // We need to make sure the list of coordinates always has at least 2 points so
      // the line drawing logic doesn't break
      return list.concat(coord);
    }

    return list;
  }, [])
);

export {
  extractPQ,
  extractUsagePoints,
  getAverageV,
  calculatePerUnit,
  getUniqueCoordinates,
};
