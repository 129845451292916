import React, { FunctionComponent, ReactNode } from 'react';
import { shouldInvertRealPower, AnalysisSettings, shouldInvertReactivePower } from 'routes/WorkspaceLayout/routes/Network/components/SlidingInfoPanel/AssetPanel/helpers/assetClass';

import './MapTooltip.scss';
import { kVln } from 'helpers/units';

interface MapTooltipProps {
  aggregation: string;
  dataOverride?: {
    type: 'hc' | 'ev';
    value: { [key: string]: number};
  };
  displayName: string;
  name: string;
  assetClass: string;
  permissions: Set<string>;
  phase: string;
  pointerX: number;
  pointerY: number;
  results: { [key: string]: { [key: string]: number } } | undefined;
  analysisSettings: AnalysisSettings;
}

const PROPS: { [key: string]: { label: ReactNode; div: number; }} = {
  actualP: { label: 'P (kW)', div: 1000 },
  actualQ: { label: 'Q (kVAr)', div: 1000 },
  ev: { label: 'EV Capacity (kW)', div: 1000 },
  hc: { label: 'Hosting Capacity (kW)', div: 1000 },
  i: { label: 'I (A)', div: 1 },
  apparentPower: { label: 'S (kVA)', div: 1000 },
  pLosses: { label: 'Loss (kW)', div: 1000 },
  qLosses: { label: 'Loss (kVAr)', div: 1000 },
  powerFactor: { label: 'PF', div: 1 },
  voltages: { label: kVln, div: 1000 * Math.sqrt(3) },
};

const MapTooltip: FunctionComponent<MapTooltipProps> = ({
  aggregation,
  dataOverride,
  displayName,
  name,
  assetClass,
  permissions,
  phase,
  pointerX,
  pointerY,
  results,
  analysisSettings,
}) => {
  const params = ['actualP', 'actualQ', 'i', 'apparentPower', 'pLosses', 'qLosses', 'powerFactor', 'voltages'];

  const renderHCRow = () => {
    if (!dataOverride?.value) {
      return (<></>);
    }

    const prop = PROPS[dataOverride.type];
    const { label } = PROPS[dataOverride.type];
    const value = dataOverride.value[phase] / prop.div;

    return <span className="map-tooltip-subtitle">{`${label}: ${value.toFixed(2)}`}</span>;
  };
  return (
    <div
      className="map-tooltip"
      style={{
        left: pointerX,
        top: pointerY,
      }}
    >
      <div className="map-tooltip-header">
        <span className="map-tooltip-title">{`${name} - ${displayName}`}</span>
        <span className="map-tooltip-subtitle">{`Phase: ${phase}`}</span>
        {renderHCRow()}
      </div>
      {results && permissions.has('view_idp_enhanced_map_tooltip') && (
        <div className="tooltip-results-table">
          <div className="header">
            <span className="column" />
            {['A', 'B', 'C', 'Avg', 'Sum'].map(ph => (<span key={ph} className="column">{ph}</span>))}
          </div>
          {params.map(param => {
            const data = results[param];
            const prop = PROPS[param];
            if (!data) { return null; }
            return (
              <div key={param} className="row">
                <span className="column">{prop.label}</span>
                {['A', 'B', 'C', 'avg', 'sum'].map(ph => {
                  let value = null;

                  if (ph === 'avg') {
                    const key = `ABC_avg_${aggregation}`;
                    value = data && data[key] ? (data[key] / prop.div) : null;
                  } else if (ph === 'sum' && ['voltages', 'powerFactor'].indexOf(param) === -1) {
                    value = data ? (['A', 'B', 'C'].reduce((prev, current) => {
                      const ph_val = data[`${current}_${aggregation}`];
                      return ph_val ? prev + ph_val : prev;
                    }, 0.0) / prop.div) : null;
                  } else {
                    const key = `${ph}_${aggregation}`;
                    value = data && data[key] ? (data[key] / prop.div) : null;
                  }

                  if (value !== null && param === 'actualP' && shouldInvertRealPower(assetClass, analysisSettings)) {
                    value = -value;
                  } else if (value !== null && ['actualQ', 'powerFactor'].includes(param) && shouldInvertReactivePower(assetClass, analysisSettings)) {
                    value = -value;
                  }

                  return (
                    <span key={ph} className="column">
                      {value?.toFixed(2)}
                    </span>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MapTooltip;
