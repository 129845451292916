import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import asyncActionStates from 'helpers/asyncActionStates';

import SelectRow from '../SelectRow';
import EquipmentBadge, { symbols as badge_symbols } from '../EquipmentBadge';
import Helpers from '../../helpers/EquipmentLibraryHelpers';
import DescriptionEditor from './DescriptionEditor';
import PanelTabs from './PanelTabs';

import '../EquipmentBadge.scss';
import './common.scss';

const MULTI = 'multi';
const overOrUnder = { OVERHEAD: 'overhead', UNDERGROUND: 'underground' };

const overUnderSelectorOptions = [
  { value: overOrUnder.OVERHEAD, label: 'Overhead', badge: badge_symbols.OVERHEAD },
  { value: overOrUnder.UNDERGROUND, label: 'Underground', badge: badge_symbols.UNDERGROUND },
];

class WireGeometryPanel extends Component {
  configurationOptions = {
    overhead: [
      { label: 'Open 4-wire', value: 'overhead4WireDistribution' },
      { label: 'Multiconductor', value: 'overheadMultiConductor' },
      { label: 'Secondary (Triplex Multiconductor)', value: 'overheadTriplexSecondary' },
    ],
    underground: [
      { label: 'Cable (Concentric Neutral or Tape-Shield)', value: 'undergroundCable' },
      { label: 'Multiconductor', value: 'undergroundMultiConductor' },
      { label: 'Secondary (Triplex Multiconductor)', value: 'undergroundTriplexSecondary' },
    ],
  };

  separatePhasePositions = {
    A: { x: '', y: '' },
    B: { x: '', y: '' },
    C: { x: '', y: '' },
    N: { x: '', y: '' },
  }

  singleGroupedPhasePositions = {
    [MULTI]: { x: '', y: '' },
  }

  newWireGeometry = {
    name: '',
    description: '',
    id: 'add',
    overOrUnder: overOrUnder.OVERHEAD,
    configurationType: 'overhead4WireDistribution',
    isCable: false,
    position: this.separatePhasePositions,
  }

  phases = ['A', 'B', 'C', 'N']

  phasePositions = {
    overhead4WireDistribution: this.separatePhasePositions,
    overheadMultiConductor: this.singleGroupedPhasePositions,
    overheadTriplexSecondary: this.singleGroupedPhasePositions,
    undergroundCable: this.separatePhasePositions,
    undergroundMultiConductor: this.singleGroupedPhasePositions,
    undergroundTriplexSecondary: this.singleGroupedPhasePositions,
  }

  UNSAFE_componentWillMount() {
    this.setState({ ...this.extractGeometryValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { LOADING, SUCCESS } = asyncActionStates;
    const saveComplete = (
      this.props.createInstanceReq === LOADING
      && nextProps.createInstanceReq === SUCCESS
    );
    const newSelected = this.props.selected !== nextProps.selected;
    // Update the instance in state if save was successful or new instance selected
    if (newSelected || saveComplete) {
      this.setState({ ...this.extractGeometryValues(nextProps.selected) });
    }
  }

  extractGeometryValues(selected) {
    const asset = { ...this.newWireGeometry };

    if (selected) {
      const phases = Object.keys(selected.WirePositions).filter(
        phase => selected.WirePositions[phase] !== null,
      );

      asset.name = selected.name;
      asset.description = selected.description;
      asset.id = selected.id;
      asset.isCable = selected.isCable;
      asset.configurationType = selected.configurationType;
      asset.position = phases.reduce((position, phase) => {
        const phaseValues = selected.WirePositions[phase];
        position[phase] = {
          x: phaseValues ? Number(phaseValues.x.toFixed(3)) : '',
          y: phaseValues ? Number(phaseValues.y.toFixed(3)) : '',
        };
        return position;
      }, {});
      asset.overOrUnder = phases.some(phase => (asset.position[phase].y > 0))
        ? overOrUnder.OVERHEAD : overOrUnder.UNDERGROUND;
    } else {
      asset.position = this.separatePhasePositions;
    }
    return asset;
  }

  isMultiConductor = () => Object.keys(this.state.position).some(phase => phase === MULTI);

  signOfY = () => (this.state.overOrUnder === overOrUnder.OVERHEAD ? 1 : -1)

  atLeastOnePhaseCompleted = () => (
    (!this.isMultiConductor() && (
      (this.state.position.A !== undefined && this.state.position.A.x !== '' && this.state.position.A.y !== '')
      || (this.state.position.B !== undefined && this.state.position.B.x !== '' && this.state.position.B.y !== '')
      || (this.state.position.C !== undefined && this.state.position.C.x !== '' && this.state.position.C.y !== '')
    )) || (this.isMultiConductor() && this.state.position[MULTI].y !== '')
  );

  invalidPhasePosition = () => (
    Object.keys(this.state.position).some(phase => (
      (this.state.position[phase].x.length > 0 && !this.state.position[phase].y.length)
      || (!this.state.position[phase].x.length && this.state.position[phase].y.length > 0)
      // Check for invalid input states like '-'
      || (!!document.getElementById(`${phase}-x`) && !document.getElementById(`${phase}-x`).checkValidity())
      || (!!document.getElementById(`${phase}-y`) && !document.getElementById(`${phase}-y`).checkValidity())
    ))
  )

  atLeastOneFieldChanged = () => {
    if (this.state.id === 'add') {
      return true;
    }
    const wirePositions = this.props.selected?.WirePositions || { ...this.separatePhasePositions };
    // Return true if either the name or one of the phase positions have changed
    return (
      this.state.name !== this.props.selected.name
      || this.state.description !== this.props.selected.description
      || Object.keys(this.state.position).some(phase => (
        // x or y set on a not-yet-existing wire position
        (this.state.position[phase].x !== '' && !wirePositions[phase])
        || (this.state.position[phase].y !== '' && !wirePositions[phase])
        // x or y deleted on an existing wire position
        || (this.state.position[phase].x === '' && wirePositions[phase])
        || (this.state.position[phase].y === '' && wirePositions[phase])
        // x or y are changed
        || (this.state.position[phase].x !== '' && (
          this.state.position[phase].x.toFixed(3) !== wirePositions[phase].x.toFixed(3)
        ))
        || (this.state.position[phase].y !== '' && (
          this.state.position[phase].y.toFixed(3) !== wirePositions[phase].y.toFixed(3)
        ))
      )));
  }

  formValid = () => (
    this.state.name.trim().length > 0
      && this.atLeastOnePhaseCompleted()
      && !this.invalidPhasePosition()
      && this.atLeastOneFieldChanged()
  );

  handleInputChange = ({ target }) => this.setState({ [target.id]: target.value });

  handlePositionChange = ({ id, value }) => {
    const [phase, position] = id.split('-');
    let positionValue = null;
    if (value === '') {
      positionValue = '';
    } else if (position === 'y') {
      positionValue = value * this.signOfY();
    } else if (position === 'x') {
      positionValue = Number(value);
    }
    this.setState(prevState => ({
      position: {
        ...prevState.position,
        [phase]: {
          ...prevState.position[phase],
          [position]: positionValue,
        },
      },
    }));
  }

  generateDiff = () => {
    // makes a diff for both add / modify cases; assumes that validation
    // already ensured valid (x,y) and phase combos
    const inAddMode = this.state.id === 'add';
    const diffModel = {};

    const existingWirePositions = this.props.selected?.WirePositions || {};
    const wirePositions = Object.keys(this.state.position).sort().reduce((list, phase) => {
      const { x: xCoord, y: yCoord } = this.state.position[phase];
      const wirePosition = {};
      if (yCoord !== '' && (xCoord !== '' || phase === MULTI)) {
        if (phase !== MULTI) { wirePosition.phase = phase; }
        if (xCoord !== '') { wirePosition.xCoord = xCoord; }
        wirePosition.yCoord = yCoord;

        // Attach reference if the phase already exists
        if (existingWirePositions[phase]?.id) {
          wirePosition.UUID = existingWirePositions[phase].id;
        }
        return list.concat(wirePosition);
      }
      return list;
    }, []);
    if (wirePositions.length > 0) { diffModel.WirePositions = wirePositions; }

    if (inAddMode || this.state.name !== this.props.selected.name) {
      diffModel.name = this.state.name;
    }

    if (inAddMode || this.state.description !== this.props.selected.description) {
      diffModel.description = this.state.description;
    }

    if (inAddMode) {
      diffModel.isCable = this.state.isCable;
      diffModel.usage = (
        this.state.configurationType === 'overheadTriplexSecondary'
        || this.state.configurationType === 'undergroundTriplexSecondary'
      ) ? 'secondary' : 'distribution';
    }
    return diffModel;
  }

  handleSave = () => {
    const diffModel = this.generateDiff();
    if (this.state.id === 'add') {
      this.props.handleCreate('wire_spacing_info', diffModel, 'WireSpacingInfo');
    } else {
      this.props.handleEdit(this.props.selected.id, diffModel);
    }
  }

  render() {
    const isDisabled = (
      this.props.authEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
      || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
      || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );
    const positionObjs = Object.keys(this.state.position).map((phase) => {
      const { x, y } = this.state.position[phase];
      const X = Helpers.createDisplayObject(phase, `${phase}-x`, x, 'm');
      const Y = Helpers.createDisplayObject('', `${phase}-y`, y === '' ? '' : y * this.signOfY(), 'm');
      return ({ phase, X, Y });
    });
    const yLabel = this.state.overOrUnder === overOrUnder.OVERHEAD ? 'Height' : 'Depth';
    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container overhead-geo-panel" key={this.state.id}>
            <div className="right-panel">
              <div className="equipment-input-section">
                <TextInput
                  id="name"
                  label="Name"
                  theme={this.props.theme}
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  disabled={isDisabled}
                  required
                  inputWidth="225px"
                />
              </div>
              <SelectRow
                label="Overhead/Underground"
                theme={this.props.theme}
                id="selectOverUnder"
                key="select-overhead-underground"
                options={overUnderSelectorOptions.map(
                  ({ value, label, badge }) => ({
                    value,
                    label: (
                      <div className="equipment-select-option" title={label}>
                        <p className="equipment-select-option-label">{label}</p>
                        <EquipmentBadge equipmentInfoItem={{ badges: [badge], id: value }} />
                      </div>
                    ),
                  }),
                )}
                value={this.state.overOrUnder}
                disabled={this.state.id !== 'add'}
                onChange={({ value }) => this.setState({
                  overOrUnder: value,
                  isCable: value === overOrUnder.UNDERGROUND,
                  configurationType: this.configurationOptions[value][0].value,
                  position: { ...this.phasePositions[this.configurationOptions[value][0].value] },
                })}
              />
              <SelectRow
                label="Conductor Type"
                id="select-configuration"
                key="select-configuration"
                theme={this.props.theme}
                disabled={this.state.id !== 'add'}
                options={this.configurationOptions[this.state.overOrUnder].map(
                  ({ value, label }) => ({
                    value,
                    label: (
                      <div className="equipment-select-option" title={label}>
                        <p className="equipment-select-option-label">{label}</p>
                        <EquipmentBadge equipmentInfoItem={{ class: 'WireSpacingInfo', configurationType: value, id: value }} />
                      </div>
                    ),
                  }),
                )}
                value={this.state.configurationType}
                onChange={({ value }) => this.setState({
                  configurationType: value,
                  position: this.phasePositions[value],
                })}
              />
              {this.isMultiConductor()
                && (
                <NumberInput
                  className="input-y"
                  theme={this.props.theme}
                  onChange={this.handlePositionChange}
                  disabled={isDisabled}
                  step="0.001"
                  ge={0.001}
                  {...positionObjs[0].Y}
                  label={yLabel}
                  unit="m"
                  required
                  inputStyle="eq-lib"
                />
                )}
              {!this.isMultiConductor()
                && (
                <div className="equipment-input-section">
                  <div className="coordinates-table">
                    <div className="label-section">
                      Phase Position
                      <br />
                      {' '}
                      <span className="label-caption">(At least one phase is required)</span>
                    </div>
                    <div className="table-section">
                      <div className="row-inputs">
                        <div className="coord-heading">X</div>
                        <div className="coord-heading">{yLabel}</div>
                      </div>
                      {positionObjs.map(position => (
                        <div className="row-inputs" key={`${position.phase}-positions`}>
                          {new Set(this.phases).has(position.phase)
                            && (
                            <NumberInput
                              className="x-input"
                              theme={this.props.theme}
                              onChange={this.handlePositionChange}
                              disabled={isDisabled}
                              step="0.001"
                              {...position.X}
                              inputStyle="eq-lib"
                              inputWidth="55px"
                            />
                            )}
                          <NumberInput
                            className="y-input"
                            theme={this.props.theme}
                            step="0.001"
                            ge={0.001}
                            onChange={this.handlePositionChange}
                            disabled={isDisabled}
                            {...position.Y}
                            inputStyle="eq-lib"
                            inputWidth="55px"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                )}
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
        ]}
      </PanelTabs>
    );
  }
}

WireGeometryPanel.defaultProps = {
  selected: null,
};

WireGeometryPanel.propTypes = {
  selected: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default WireGeometryPanel;
