import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import { kWh, scaleFactorForUnit } from 'helpers/units';
import { IntlContext } from 'contexts/IntlContext';

const CostEditor = ({
  assetModel,
  assetModelDiff,
  includeCommunityCredit,
  includePerUnitMaintenance,
  isDisabled,
  onChange,
  theme,
  unit,
}) => {
  const { currencySymbol } = useContext(IntlContext);

  return (
    <div className="equipment-info-container cost-container" id="cost-tab">
      <div className="right-panel">
        <Input
          type="number"
          id="estimatedUnitCost"
          label="Equipment Cost"
          value={assetModel.estimatedUnitCost}
          edited={assetModelDiff?.estimatedUnitCost}
          onChange={(e => onChange({ estimatedUnitCost: e }))}
          disabled={isDisabled}
          theme={theme}
          validation={{
            required: true,
          }}
          options={{
            precision: 2,
          }}
          showRequiredAsterisk
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <Input
          type="number"
          id="estimatedUnitDisposalCost"
          label="Equipment Disposal Cost"
          value={assetModel.estimatedUnitDisposalCost}
          edited={assetModelDiff?.estimatedUnitDisposalCost}
          onChange={(e => onChange({ estimatedUnitDisposalCost: e }))}
          disabled={isDisabled}
          theme={theme}
          validation={{
            required: true,
          }}
          options={{
            precision: 2,
          }}
          showRequiredAsterisk
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <Input
          type="number"
          id="estimatedUnitInstallationCost"
          label="Installation Labour Cost"
          value={assetModel.estimatedUnitInstallationCost}
          edited={assetModelDiff?.estimatedUnitInstallationCost}
          onChange={(e => onChange({ estimatedUnitInstallationCost: e }))}
          disabled={isDisabled}
          theme={theme}
          validation={{
            required: true,
          }}
          options={{
            precision: 2,
          }}
          showRequiredAsterisk
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <Input
          type="number"
          id="estimatedUnitRemovalCost"
          label="Disposal Labour Cost"
          value={assetModel.estimatedUnitRemovalCost}
          edited={assetModelDiff?.estimatedUnitRemovalCost}
          onChange={(e => onChange({ estimatedUnitRemovalCost: e }))}
          disabled={isDisabled}
          theme={theme}
          validation={{
            required: true,
          }}
          options={{
            precision: 2,
          }}
          showRequiredAsterisk
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <Input
          type="number"
          id="estimatedUnitUpgradeCost"
          label="Change Labour Cost"
          value={assetModel.estimatedUnitUpgradeCost}
          edited={assetModelDiff?.estimatedUnitUpgradeCost}
          onChange={(e => onChange({ estimatedUnitUpgradeCost: e }))}
          disabled={isDisabled}
          theme={theme}
          validation={{
            required: true,
          }}
          options={{
            precision: 2,
          }}
          showRequiredAsterisk
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <Input
          type="number"
          id="fixedMaintenanceCost"
          label="Annual Maintenance Cost"
          value={assetModel.fixedMaintenanceCost}
          edited={assetModelDiff?.fixedMaintenanceCost}
          onChange={(e => onChange({ fixedMaintenanceCost: e }))}
          disabled={isDisabled}
          theme={theme}
          validation={{
            required: true,
          }}
          options={{
            precision: 2,
          }}
          showRequiredAsterisk
          currencySymbol={currencySymbol}
          unit="/year"
        />
        {includePerUnitMaintenance && (
          <Input
            type="number"
            id="perUnitMaintenanceCost"
            label="Maintenance Cost"
            value={assetModel.perUnitMaintenanceCost}
            edited={assetModelDiff?.perUnitMaintenanceCost}
            onChange={e => onChange({ perUnitMaintenanceCost: e })}
            disabled={isDisabled}
            theme={theme}
            validation={{
              required: true,
            }}
            options={{
              precision: 2,
            }}
            showRequiredAsterisk
            currencySymbol={currencySymbol}
            unit={`${unit} /year`}
          />
        )}
        {/* Use Input to allow for a precision setting */}
        <Input
          type="number"
          id="estimatedUnitInstallationDays"
          label="Time to construct"
          value={assetModel.estimatedUnitInstallationDays}
          edited={assetModelDiff?.estimatedUnitInstallationDays}
          onChange={e => onChange({ estimatedUnitInstallationDays: e })}
          disabled={isDisabled}
          theme={theme}
          validation={{
            min: 0,
          }}
          options={{
            precision: 0,
          }}
          unit="days"
        />
        {includeCommunityCredit && (
          <Input
            type="number"
            id="communityGenerationCredit"
            label="Community Generation Credit"
            value={assetModel.communityGenerationCredit}
            edited={assetModelDiff?.communityGenerationCredit}
            onChange={e => onChange({ communityGenerationCredit: e })}
            disabled={isDisabled}
            theme={theme}
            validation={{
              required: true,
            }}
            options={{
              divisor: 1 / scaleFactorForUnit(kWh),
              precision: 2,
            }}
            showRequiredAsterisk
            currencySymbol={currencySymbol}
            unit={`/${kWh}`}
          />
        )}
      </div>
    </div>
  );
};
CostEditor.defaultProps = {
  includeCommunityCredit: false,
  includePerUnitMaintenance: false,
  unit: '',
};
CostEditor.propTypes = {
  assetModel: PropTypes.object.isRequired,
  assetModelDiff: PropTypes.object.isRequired,
  includeCommunityCredit: PropTypes.bool,
  includePerUnitMaintenance: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  unit: PropTypes.string,
};

export default CostEditor;
