/* eslint-disable react/no-did-update-set-state */
// Disabled until preset is updated to allow for set state with conditionals https://reactjs.org/docs/react-component.html#componentdidupdate
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Slider, { Handles, Tracks, Ticks } from 'react-compound-slider';
import Track from './Track';
import Tick from './Tick';

class RangeSlider extends Component {
  state = {
    values: this.props.selectedValues || [0, this.props.totalTicks],
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedValues !== this.props.selectedValues) {
      this.setState({ values: this.props.selectedValues });
    } else if (prevProps.totalTicks !== this.props.totalTicks) {
      this.setState({ values: [0, this.props.totalTicks] });
    }
  }

  updateValues = (values) => {
    this.setState({ values });
    this.props.onChange(values);
  }

  // Update the slider's range if the selected range has values.
  moveTrack = (num) => {
    const newValues = [this.state.values[0] + num, this.state.values[1] + num];
    const validMin = newValues[0] >= 0;
    const validMax = newValues[1] <= this.props.totalTicks;
    if (validMin && validMax) {
      this.updateValues(newValues);
      return newValues;
    }
    return this.state.values;
  }

  // Allow click on rail to move the track to another section of the rail.
  handleTickClick = (val) => {
    const { totalTicks } = this.props;
    const range = this.state.values[1] - this.state.values[0];
    const rangeEnd = val + range > totalTicks ? totalTicks : val + range;
    const newRange = [val, rangeEnd];
    this.updateValues(newRange);
  }

  handleZoomIn = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onZoomIn();
  }

  render() {
    const { values } = this.state;
    const domain = [0, this.props.totalTicks];
    return (
      <div style={{ width: '100%' }}>
        <Slider
          className="simple-range-slider"
          mode={2}
          step={1}
          domain={domain}
          rootStyle={{ position: 'relative', width: '100%' }}
          onSlideEnd={this.updateValues}
          values={values}
        >
          <div className="simple-range-slider-rail" />
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {[...handles].map(handle => (
                  <div
                    key={handle.id}
                    style={{ left: `${handle.percent}%` }}
                    className="simple-range-slider-handle"
                    {...getHandleProps(handle.id)}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    highlightColor={this.props.highlightColor}
                    moveTrack={this.moveTrack}
                    onDrop={this.updateValues}
                    showZoom={this.props.showZoom}
                    onZoomIn={this.handleZoomIn}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks values={[...Array(this.props.totalTicks).keys()]}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick
                    key={tick.id}
                    tick={tick}
                    count={ticks.length}
                    tickFormatter={this.props.tickFormatter}
                    onClick={this.handleTickClick}
                    skipOne={this.props.skipOne}
                  />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    );
  }
}

RangeSlider.defaultProps = {
  onChange: () => null,
  onZoomIn: () => null,
  tickFormatter: null,
  showZoom: false,
  selectedValues: null,
  skipOne: false,
};

RangeSlider.propTypes = {
  highlightColor: PropTypes.string.isRequired,
  totalTicks: PropTypes.number.isRequired,
  showZoom: PropTypes.bool,
  selectedValues: PropTypes.array,
  tickFormatter: PropTypes.func,
  onChange: PropTypes.func,
  onZoomIn: PropTypes.func,
  skipOne: PropTypes.bool,
};

export default RangeSlider;
