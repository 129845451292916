import React from 'react';
import Switch from './Switch';

// Creates a value template for Fuse instances.
// As Fuse shares all of its values with Switch, the switch template is used
const Fuse = props => (
  <Switch {...props} />
);

export default Fuse;
