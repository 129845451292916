import React from 'react';
import AsynchronousMachine from './AsynchronousMachine';

const controlHelp = {
  uncontrolled:
    'During timeseries powerflow, generators set to \'fixed\' are allocated by the load allocation process.',
  globallyOptimized:
    'Winds set to \'Global\' mode will curtail from their scheduled (or allocated)'
    + ' active/reactive power outputs, when it serves the global objective to do so.',
};

const controlTypes = [
  { value: 'uncontrolled', label: 'Fixed (no control)' },
  { value: 'locallyControlled', disabled: true, label: 'Local' },
  { value: 'globallyOptimized', disabled: false, label: 'Global' },
  { value: 'scheduled', disabled: false, label: 'Schedule' },
];
const generatorType = 'Wind';

// Creates a value template for Wind instances
// Uses AsynchronousMachine as that template includes all Wind values\
const Wind = (props) => {
  const newProps = {
    ...props, controlHelp, controlTypes, generatorType,
  };
  return (
    <AsynchronousMachine {...newProps} />
  );
};

export default Wind;
