import Agreement from './Agreement';

class DerAuxiliaryAgreement extends Agreement {
  get availabilityPerYear() {
    return this.attributes['DerAuxiliaryAgreement.availabilityPerYear'] || 0;
  }

  get administrationCostPerYear() {
    return this.attributes['DerAuxiliaryAgreement.administrationCostPerYear'] || 0;
  }

  get utilizationPaymentPerHour() {
    return this.attributes['DerAuxiliaryAgreement.utilizationPaymentPerHour'] || 0;
  }

  get utilizationPaymentPerWattHour() {
    return this.attributes['DerAuxiliaryAgreement.utilizationPaymentPerWattHour'] || 0;
  }

  get utilizationPaymentPerYear() {
    return this.attributes['DerAuxiliaryAgreement.utilizationPaymentPerYear'] || 0;
  }
}

export default DerAuxiliaryAgreement;
