import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';
import asyncActionStates from 'helpers/asyncActionStates';
import MapGL from 'react-map-gl';

import { AssetViolationsContext } from 'contexts/AssetViolationsContext';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';

import DeckGLMap from '../containers/DeckGLMapContainer';

class GISPanel extends Component {
  constructor(props) {
    super(props);

    // only perform this check once as it is expensive
    this.glMapSupported = MapGL.supported();
  }

  state = { showErrorScreen: false };

  componentDidUpdate(prevProps) {
    const newData = this.props.network.lookup !== prevProps.network.lookup;
    const newFeederSelection = this.props.selectedFeeders !== prevProps.selectedFeeders;

    if (this.state.showErrorScreen && (newData || newFeederSelection)) {
      this.setState({ showErrorScreen: false });
    }
  }

  componentDidCatch() {
    this.setState({ showErrorScreen: true });
  }

  // Get which component to display in the main area of the GIS view
  getGISState = () => {
    const { feeder_ids: feederIDs } = this.props.match.params;
    const { ERROR } = asyncActionStates;
    const {
      invalidCoordinateSystem,
      fixersRunning,
      selectedFeeders,
    } = this.props;
    const { selected } = this.props.feeders;
    const {
      networkInstance, pendingReqs, requestStatus, lookup,
    } = this.props.network;
    const feeders = networkInstance ? networkInstance.feeders : {};
    const totalLoadedFeeders = Object.keys(feeders).length;
    const totalPending = Object.keys(pendingReqs).length;
    const totalInstances = Object.keys(lookup).length;
    // GIS state checks
    const hasFailures = Object.entries(requestStatus).some(
      req => req[0].includes('_') && req[1] === ERROR,
    );
    const initialLoad = totalPending > 0 && totalLoadedFeeders === 0;
    const loadingSelected = selectedFeeders.length === 1
      && Object.keys(pendingReqs)[0] === selectedFeeders[0].id;
    const noPending = totalPending === 0 && selected.length === 0;
    const noFeederParams = !feederIDs || feederIDs.length === 0;

    // State per overlay / panel type
    const showSelectFeeder = noPending
      && noFeederParams
      && !invalidCoordinateSystem
      && !fixersRunning
      && !hasFailures
      && selected.length === 0;
    const showProcessing = noPending
      && !noFeederParams
      && !invalidCoordinateSystem
      && !hasFailures
      && !initialLoad
      && !loadingSelected;
    const showLoading = ((initialLoad || loadingSelected) && !hasFailures)
      || (totalPending > 0 && totalLoadedFeeders === 0);
    const showInvalidCoord = !initialLoad && !fixersRunning && invalidCoordinateSystem && !showLoading;
    const showGISError = hasFailures && totalLoadedFeeders === 0 && noPending;
    const showGIS = !showSelectFeeder
      && !showProcessing
      && !showLoading
      && !showInvalidCoord
      && !showGISError
      && !fixersRunning
      && totalInstances > 0;
    return {
      showSelectFeeder,
      showGIS,
      showProcessing,
      showLoading,
      showInvalidCoord,
      showGISError,
    };
  };

  // Get the unique ids of all fo the assets in view
  filterDevices = inView => {
    const instances = new Set();
    for (let i = 0; i < inView.length; i += 1) {
      if (inView[i] && inView[i].object && inView[i].object.properties) {
        const { id } = inView[i].object.properties;
        if (id) {
          instances.add(id);
        }
      }
    }

    this.props.actions.setFilteredPanel([...instances.values()]);
  };

  render() {
    const { fixersRunning } = this.props;
    const currentState = this.getGISState();

    if (!this.glMapSupported) {
      return (
        <div className="gis-overlay">
          <div className="gis-error-message webgl-missing">
            <i className="material-icons error-icon">warning</i>
            <div>
              The GIS of your network model cannot be viewed because WebGL is
              disabled.
              {' '}
              <br />
              Enable WebGL to view your network model.
            </div>
          </div>
        </div>
      );
    }
    if (this.state.showErrorScreen) {
      return (
        <div className="gis-overlay">
          <div className="gis-error-message">
            <i className="material-icons error-icon">warning</i>
            <div>Unable to process GIS Data</div>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
        }}
      >
        {currentState.showGIS && (
          <AssetViolationsContext.Consumer>
            {({ violatingAssetIds, layersEnabled }) => (
              <WorkspaceSettingsContext.Consumer>
                {({ settings }) => (
                  <DeckGLMap
                    setFilteredView={this.filterDevices}
                    violatingAssetIds={violatingAssetIds}
                    assetViolationLayers={layersEnabled}
                    customMapBoxSource={settings.mapboxSource}
                    mapMode={settings.mapMode}
                  />
                )}
              </WorkspaceSettingsContext.Consumer>
            )}
          </AssetViolationsContext.Consumer>
        )}
        {this.props.network.qstsLoading && (
          <div className="loading-overlay">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i className="material-icons rotate">refresh</i>
              <span className="sr-only">Loading...</span>
              <p style={{ marginLeft: '5px' }}>Loading Powerflow Results</p>
            </div>
          </div>
        )}
        {!currentState.showGIS && (
          <div className="gis-overlay">
            {!this.props.error && (
              <>
                {currentState.showSelectFeeder && (
                  <div className="overlay-message">Please select a feeder</div>
                )}
                {currentState.showProcessing && (
                  <div className="overlay-message">Processing...</div>
                )}
                {fixersRunning && (
                  <div className="overlay-message">Applying Fixers...</div>
                )}
                {currentState.showLoading && (
                  <div className="gis-loading-message">
                    <div className="gis-loading-spinner">
                      <LoadingSpinner />
                    </div>
                    <div>Loading GIS Data</div>
                  </div>
                )}
                {currentState.showInvalidCoord && (
                  <>
                    <div className="gis-error-symbol">?</div>
                    <div className="gis-error-message">
                      GIS data contains an unsupported Coordinate System.
                    </div>
                  </>
                )}
                {currentState.showGISError && (
                  <div className="gis-error-message">
                    <i className="material-icons error-icon">warning</i>
                    <div>Unable to fetch GIS Data</div>
                    <div>Please re-select feeder to try again</div>
                  </div>
                )}
              </>
            )}
            {this.props.error && (
              <div className="gis-error-message">
                <i className="material-icons error-icon">warning</i>
                <div>Unable to process GIS Data</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

GISPanel.defaultProps = {
  invalidCoordinateSystem: undefined,
  error: false,
};

GISPanel.propTypes = {
  network: PropTypes.object.isRequired,
  feeders: PropTypes.shape({
    selected: PropTypes.array,
    list: PropTypes.array,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      feeder_ids: PropTypes.string,
    }),
  }).isRequired,
  selectedFeeders: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  invalidCoordinateSystem: PropTypes.bool,
  error: PropTypes.bool,
  fixersRunning: PropTypes.bool.isRequired,
};

export default GISPanel;
