import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';

class DelayedModal extends Component {
  state = {
    displayModal: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      this.timer = setTimeout(this.enableMessage, this.props.delay);
    } else if (prevProps.active && !this.props.active) {
      clearTimeout(this.timer);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableMessage = () => this.setState({ displayModal: true });

  render() {
    if (!this.state.displayModal || !this.props.active) {
      return null;
    }

    const { children, ...props } = this.props;

    return (
      <Modal
        active={this.state.displayModal}
        {...props}
      >
        {children}
      </Modal>
    );
  }
}

DelayedModal.defaultProps = {
  active: false,
  delay: 500,
};

DelayedModal.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any.isRequired,
  delay: PropTypes.number,
};

export default DelayedModal;
