import React from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from 'components/CustomCheckbox';

const isDisabled = (phases, phase) => (
  phases.toString() === [`${phase}`, 'N'].toString() || phases.toString() === [phase].toString()
);

const PhaseSelectorCheckboxes = ({
  disabled, onClick, phases, allowNeutral,
}) => {
  const phasesArray = phases.split('').sort();
  const phaseOptions = ['A', 'B', 'C', ...(allowNeutral ? ['N'] : [])];
  return (
    <div className="panel-header-row" key="header-row-1">
      <div className="left-label" key="left-label-1">
        Phase
      </div>
      <div className="phase-check-container">
        {phaseOptions.map(ph => (
          <div className="input-group" key={`input-group-${ph}`}>
            <div className="checkbox" key={`checkbox-${ph}`}>
              <CustomCheckbox
                key={`phase-${ph}`}
                checked={phasesArray.includes(ph)}
                disabled={disabled || isDisabled(phasesArray, ph)}
                focusable
                id={`phase-${ph}`}
                value={ph}
                onClick={() => onClick(ph)}
              />
            </div>
            <div className="label" key={`label-${ph}`}>
              {ph}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PhaseSelectorCheckboxes.defaultProps = {
  disabled: false,
  allowNeutral: false,
};

PhaseSelectorCheckboxes.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  phases: PropTypes.string.isRequired,
  allowNeutral: PropTypes.bool,
};

export default PhaseSelectorCheckboxes;
