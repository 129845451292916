import React from 'react';
import PropTypes from 'prop-types';

// Create a labeled list of values in the list section of the panel
const ListValue = ({
  label, values, onClick, className,
}) => {
  const vals = values || {};
  const processValues = (valueObj) => {
    if (Array.isArray(valueObj)) {
      return valueObj.map(val => (
        <button onClick={() => onClick(val)} className="list-value-row-value" key={val.id} type="button">
          <p className="list-value-row-value__p">{val.name}</p>
        </button>
      ));
    }
    return (
      <button onClick={() => onClick(valueObj)} className="list-value-row-value" key={valueObj.id} type="button">
        <p className="list-value-row-value__p">{valueObj.name}</p>
      </button>
    );
  };

  return (
    <div className={`list-value-row ${className || ''}`}>
      <div className="list-value-row-label"><p>{label}</p></div>
      <div className="list-value-row-container">{processValues(vals)}</div>
    </div>
  );
};

ListValue.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ListValue.defaultProps = {
  className: '',
};

export default ListValue;
