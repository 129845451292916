import { connect } from 'react-redux';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import VoltageDistanceGraph from '../components/VoltageDistanceGraph';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapStateToProps = state => ({
  nodes: Object.keys(state.network.networkInstance?.connectivityNodes || {}),
  nodeSubstationDistances: state.network.nodeSubstationDistances,
  results: state.network.results,
  theme: state.global.theme,
  resultsRequest: state.requests.qstsValues,
  distanceRequest: state.requests.getNodeSubstationDistance,
  aggType: state.network.timeBarZoomLevel,
  expanded: state.network.panelOptions.expanded,
});

export default connect(mapStateToProps)(VoltageDistanceGraph);
