/* eslint-disable no-bitwise */
import {
  determineColorByLayerType, noValueColor, determineActualResultsValue, determineColorIndex, getPowerFactorColor,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';

const TRANSPARENT = [255, 255, 255, 0];

/**
 * Converts a string to an RGB color
 * @param   {String} string String to convert
 * @returns {Array}         RGB color as an array
 */
export const convertStringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash &= hash;
  }
  const rgb = [0, 0, 0];
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return rgb;
};

export const getBounds = (nodes, feeders) => {
  if (!nodes) return null;

  let xMin = 162.836955;
  let xMax = -171.035508;
  let yMin = 72.038016;
  let yMax = -51.941901;

  const feederIDS = new Set([...feeders.map(fdr => fdr.id)]);

  Object.values(nodes).forEach((node) => {
    if (feederIDS.has(node.properties.feeder)) {
      const xPosition = node.geometry.coordinates[0];
      const yPosition = node.geometry.coordinates[1];
      xMax = Math.max(xMax, xPosition);
      yMax = Math.max(yMax, yPosition);
      xMin = Math.min(xMin, xPosition);
      yMin = Math.min(yMin, yPosition);
    }
  });

  // Handle case of there being only a single node in the network
  if (xMin === xMax && yMin === yMax) {
    xMin *= 0.9999;
    xMax *= 1.0001;
    yMin *= 0.9999;
    yMax *= 1.0001;
  }

  return [[xMin, yMin], [xMax, yMax]];
};

export const hexToRGB = (color) => {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  return [r, g, b, 255];
};

export const getLoadingHeadroomValues = (value, rating, phase, options) => {
  if (value) {
    const breaks = options.layerType === 'loading' ? options.percentBreaks : options.rangeBreaks;
    let ratings = {};
    if (rating && typeof rating === 'number') {
      // If rating is a single value, split it over the available phases
      ratings = phase.split('').reduce((lu, ph) => {
        lu[ph] = rating / phase.length;
        return lu;
      }, {});
    } else if (rating) {
      ratings = rating;
    }

    const color = determineColorByLayerType(options, value, ratings, breaks);
    const validColor = color && color !== noValueColor;
    if (validColor) {
      return hexToRGB(color);
    }
  }
  return TRANSPARENT;
};

export const getPowerLayerColor = (options, result) => {
  if (result) {
    const value = determineActualResultsValue(options, result);
    const index = determineColorIndex(value, options.rangeBreaks);
    const validIndex = index !== undefined && index !== null && options.selected[index];
    if (validIndex) {
      return hexToRGB(options.colors[index]);
    }
  }
  return TRANSPARENT;
};

const adjustOpacity = (opacity) => {
  const adjustedForMax = Math.min(opacity, 0.9);
  const adjustedForMin = Math.max(adjustedForMax, 0.1);
  return adjustedForMin;
};

export const getVisualizationColor = (
  feature, maxP, layerOptions, layer, hcData, evData, batteryData, result,
) => {
  const options = layerOptions[layer];

  const getNodalAnalysisLayerColor = (dataset, valueKey) => {
    if (dataset[feature.properties.id]) {
      const value = dataset[feature.properties.id][valueKey];
      const colorBreakIndex = determineColorIndex(value, options.rangeBreaks);
      if (options.colors[colorBreakIndex] && options.selected[colorBreakIndex]) {
        return hexToRGB(options.colors[colorBreakIndex]);
      }
    }
    return TRANSPARENT;
  };

  switch (layer) {
    case 'generation_load': {
      if (feature.properties.asset_type === 'EnergyConsumer' && typeof feature.properties.totalP === 'number') {
        const opacity = Math.abs(feature.properties.totalP / maxP[0]);
        const instanceColor = hexToRGB(options.colors[0]);
        instanceColor[3] = options.selected[0] ? 255 * adjustOpacity(opacity) : 0;
        return instanceColor;
      } if (typeof feature.properties.totalP === 'number') {
        const opacity = Math.abs(feature.properties.totalP / maxP[1]);
        const instanceColor = hexToRGB(options.colors[1]);
        instanceColor[3] = options.selected[1] ? 255 * adjustOpacity(opacity) : 0;
        return instanceColor;
      }
      break;
    }
    case 'current': {
      options.aggregation = layerOptions.aggregation;
      return getLoadingHeadroomValues(
        result?.i,
        feature.properties.perPhaseRatedCurrent,
        feature.properties.phase,
        options,
      );
    }
    case 'apparent_power': {
      options.aggregation = layerOptions.aggregation;
      return getLoadingHeadroomValues(
        result?.apparentPower,
        feature.properties.ratedS,
        feature.properties.phase,
        options,
      );
    }
    case 'real_power': {
      options.aggregation = layerOptions.aggregation;
      return getPowerLayerColor(options, result?.actualP);
    }
    case 'reactive_power': {
      options.aggregation = layerOptions.aggregation;
      return getPowerLayerColor(options, result?.actualQ);
    }
    case 'real_power_losses': {
      options.aggregation = layerOptions.aggregation;
      return getPowerLayerColor(options, result?.pLosses);
    }
    case 'power_factor': {
      options.aggregation = layerOptions.aggregation;
      if (result?.powerFactor) {
        const assetColor = getPowerFactorColor(result?.powerFactor, options);
        if (assetColor && assetColor !== noValueColor) {
          return hexToRGB(assetColor);
        }
      }
      break;
    }
    case 'hosting_capacity':
      return getNodalAnalysisLayerColor(hcData, options.mode === 'balanced' ? 'ABC' : options.phase);
    case 'ev_capacity':
      return getNodalAnalysisLayerColor(evData, options.mode === 'balanced' ? 'ABC' : options.phase);
    case 'battery_sizing_energy':
      return getNodalAnalysisLayerColor(batteryData, 'max_energy');
    case 'battery_sizing_real_power':
      return getNodalAnalysisLayerColor(batteryData, 'max_p');
    case 'battery_sizing_reactive_power':
      return getNodalAnalysisLayerColor(batteryData, 'max_q');
    default:
      return TRANSPARENT;
  }
  return TRANSPARENT;
};

const SHUNT_TYPES = [
  'AsynchronousMachine', 'Battery', 'CHP', 'EnergyConsumer', 'EnergySource', 'InverterPV',
  'LinearShuntCompensator', 'PhotoVoltaic', 'RunOfRiverHydro', 'SynchronousMachine', 'Wind', 'EquivalentSubstation',
  'ElectricVehicleChargingStation',
];

export const isShuntDevice = type => SHUNT_TYPES.includes(type);

export const createPlaceholderNode = lngLat => ({
  _newNode: {
    type: 'Feature',
    properties: {
      id: '_newNode',
      name: 'New Node',
      asset_type: 'ConnectivityNode',
      connected_devices: [],
    },
    geometry: {
      type: 'Point',
      coordinates: lngLat,
    },
  },
});

export const createPlaceholderLine = node => ({
  type: 'Feature',
  properties: {
    id: '_newLine',
    asset_type: 'ACLineSegment',
    name: 'New Line',
    nodes: [node.properties.id],
    phase: 'ABC',
    feeder: node.properties.feeder,
    color: [78, 78, 78, 200],
  },
  geometry: {
    type: 'LineString',
    coordinates: [node.geometry.coordinates],
  },
});

export const createHoverLine = (newLine, lngLat) => ({
  type: 'Feature',
  properties: {
    id: '_drawPlaceholder',
    feeder: newLine.properties.feeder,
    color: [78, 78, 78, 200],
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      newLine.geometry.coordinates.slice(-1)[0],
      lngLat,
    ],
  },
});

export const addLineCoordinates = (lineFeature, coordinates) => ({
  ...lineFeature,
  geometry: {
    ...lineFeature.geometry,
    coordinates: [
      ...lineFeature.geometry.coordinates,
      coordinates,
    ],
  },
});

export const convertLinesToBadges = assets => (
  Object.values(assets).map((asset) => {
    if (asset.properties.asset_type !== 'ACLineSegment') {
      return asset;
    }

    let coord;
    if (asset.geometry.coordinates.length % 2 === 0) {
      const endPoint = asset.geometry.coordinates.length / 2;
      const startPoint = endPoint - 1;
      coord = [
        (asset.geometry.coordinates[startPoint][0] + asset.geometry.coordinates[endPoint][0]) / 2,
        (asset.geometry.coordinates[startPoint][1] + asset.geometry.coordinates[endPoint][1]) / 2,
      ];
    } else {
      coord = asset.geometry.coordinates[Math.floor(asset.geometry.coordinates.length / 2)];
    }
    return {
      ...asset,
      geometry: {
        type: 'Point',
        coordinates: coord,
      },
    };
  })
);

const selectionTemplate = ({ id, asset_type, connected_devices }, type, coordinates) => ({
  type: 'Feature',
  properties: {
    id,
    asset_type,
    connected_devices,
  },
  geometry: {
    type,
    coordinates,
  },
});

export const makeSelectedAssetFeature = (assetData, assetId) => {
  const asset = assetData[assetId];
  if (!asset) {
    return null;
  }

  return selectionTemplate(asset.properties, asset.geometry.type, asset.geometry.coordinates);
};

export default {
  convertStringToColor,
  isShuntDevice,
  createPlaceholderLine,
};
