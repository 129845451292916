import React, {
  FunctionComponent, ReactNode, useContext, Fragment,
} from 'react';
import Button from 'components/Button';
import ModalPortal from 'components/ModalPortal';
import Modal from 'components/Modal';

import ThemeContext from 'helpers/ThemeContext';
import './AnalysisLogs.scss';

interface DebugFiles {
  [file_path: string]: string,
}

interface SubjobAdditionalInfo {
  debug_files?: DebugFiles,
  node_name?: string | Array<string>,
  node?: string | Array<string>,
  start_date: string,
  end_date: string,
}

interface JobType {
  additional_info: SubjobAdditionalInfo,
  job_id: string,
  status: string,
}

type AnalysisLogsProps = {
  extractRowLabel: (additional_info: SubjobAdditionalInfo) => ReactNode,
  extractNodeLabel: (additional_info: SubjobAdditionalInfo) => ReactNode,
  failureLogsActive: boolean,
  toggleFailureLogsActive: (isActive: boolean) => void,
  jobs: Array<JobType>,
  downloadLogFiles: (pathname: string, jobId: string) => void,
  permissions: Set<string>,
}

const AnalysisLogs: FunctionComponent<AnalysisLogsProps> = ({
  extractRowLabel,
  extractNodeLabel,
  failureLogsActive = false,
  toggleFailureLogsActive,
  jobs = [],
  downloadLogFiles,
  permissions,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <ModalPortal id="modal-portal">
      <Modal
        active={failureLogsActive}
        onConfirm={() => toggleFailureLogsActive(false)}
        showFooter
        title="Analysis Logs"
        theme={theme}
        showCancel={false}
        showHeader
        labels={{
          confirm: 'Done',
        }}
        scrollBody
      >
        <div className="log">
          <div className="header">Subjob</div>
          <div className="header">Node</div>
          <div className="header">Timestamp</div>
          <div className="header">Download</div>
          {jobs.length > 0 && jobs.map(job => {
            let row = null;
            if (job.additional_info?.debug_files) {
              const debug_files: { file: string, path: string }[] = [];
              Object.entries(job.additional_info.debug_files).forEach(([file, path]) => {
                if (/^\/?summary/.test(file)) {
                  if (permissions.has('view_analytics_simulation_debug_summary')) {
                    debug_files.push({
                      file: `${file.replace(/^\/?summary\//, '')} [Summary]`,
                      path,
                    });
                  }
                } else if (permissions.has('view_analytics_simulation_debug_data')) {
                  debug_files.push({
                    file: `${file} [Debug]`,
                    path,
                  });
                }
              });
              row = (
                <Fragment key={job.job_id}>
                  <div className="cell">{job.job_id}</div>
                  <div className="cell">{job.additional_info && extractNodeLabel(job.additional_info)}</div>
                  <div className="cell">{job.additional_info && extractRowLabel(job.additional_info)}</div>
                  <div className="cell">
                    {debug_files.map(({ file, path }) => (
                      <div key={file}>
                        <Button
                          className="downloadlink"
                          type="text"
                          onClick={() => downloadLogFiles(path, job.job_id)}
                        >
                          {file}
                        </Button>
                      </div>
                    ))}
                  </div>
                </Fragment>
              );
            }
            return row;
          })}
        </div>
      </Modal>
    </ModalPortal>
  );
};

export default AnalysisLogs;
