import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types/index';
import { Moment } from 'moment';
import { isEmptyObject } from 'helpers/utils';
import CostSummary from './Costs';
import ReliabilitySummary from './Reliability';
import EnergySummary from './Energy';
import Violations from './Violations';
import EmissionsSummary from './Emissions';
import NetPresentValueSummary from './NetPresentValue';

type ResultsSummaryProps = {
    theme: ThemeProp,
    violationLimit?: number|null,
    workspace: string|null,
    branch: string|null,
    maxRange: {
      start?: Moment|null,
      end?: Moment|null,
    },
    scenario: string|null,
    analysis: {[key: string]: any}|null,
    feeders?: [],
    violationType: string,
    setViolationType: (value: string) => void,
    isBaseline: boolean,
    violationsError: boolean,
    setTooManyViolationsError: (error: boolean) => void,
  };

const ResultsSummary: FunctionComponent<ResultsSummaryProps> = ({
  theme,
  violationLimit = 0,
  workspace,
  branch,
  maxRange,
  scenario,
  analysis,
  feeders = [],
  violationType,
  setViolationType,
  isBaseline,
  violationsError,
  setTooManyViolationsError,
}) => {
  const commonProps = {
    workspace,
    branch,
    theme,
  };
  const analysisProps = {
    ...commonProps,
    maxRange,
    scenario,
    analysis,
    feeders,
    isBaseline,
  };
  return (
    <>
      {scenario && analysis && !isEmptyObject(analysis) && (
      <>
        <Violations
          theme={theme}
          violationLimit={violationLimit}
          violationType={violationType}
          setViolationType={setViolationType}
          isBaseline={isBaseline}
          workspace={workspace}
          resultsBranch={branch}
          feeders={feeders}
          maxRangeSelected={maxRange}
          scenario={scenario}
          analysis={analysis}
          violationsError={violationsError}
          setTooManyViolationsError={setTooManyViolationsError}
        />
        <EmissionsSummary {...analysisProps} />
        <NetPresentValueSummary {...commonProps} />
        <ReliabilitySummary {...analysisProps} />
        <EnergySummary {...analysisProps} />
        <CostSummary {...analysisProps} />
      </>
      )}
    </>
  );
};

export default ResultsSummary;
