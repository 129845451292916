import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import './IconOption.scss';

const { Option } = components;
const IconOption = props => (
  <Option {...props}>
    <div className="option-row">
      <div
        title={props.data?.label}
        className="label-row"
      >
        <div className="label-text">{props.data?.label}</div>
        <div>
          {props.data?.hasError && <i className="material-icons" style={{ color: 'red' }}>highlight_off</i>}
          {!props.data?.hasError && props.data?.hasWarning && <i className="material-icons" style={{ color: 'orange' }}>warning</i>}
        </div>
      </div>
      <div className="option-icon">
        {
          props.selectProps?.value?.value === props.data?.value && (
          <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Custom-Preset-Copy-3" transform="translate(-1271.000000, -1630.000000)">
                <g id="dropdown/menu/open" transform="translate(1087.000000, 1614.000000)">
                  <g id="dropdown/menu/selected" transform="translate(14.000000, 14.000000)">
                    <g id="checked" transform="translate(170.000000, 2.000000)">
                      <path d="M9.96258342,5.00013108 C9.96258342,7.75709926 7.72788529,10 4.98132075,10 C2.23449505,10 5.80944191e-05,7.75709926 5.80944191e-05,5.00013108 C5.80944191e-05,2.24290074 2.23449505,0 4.98132075,0 C7.72788529,0 9.96258342,2.24290074 9.96258342,5.00013108 Z" id="Path" />
                      <path d="M7.27222642,4.14893939 L4.98132075,6.44890152 L4.77196226,6.65905303 C4.65973585,6.77170455 4.51271698,6.82787879 4.36532075,6.82787879 L4.35664151,6.82787879 C4.21150943,6.82787879 4.06641509,6.7725 3.95566038,6.66136364 L2.7225283,5.42318182 C2.5010566,5.20125 2.5010566,4.84087121 2.7225283,4.61859848 C2.944,4.39632576 3.30301887,4.39628788 3.52407547,4.61859848 L4.35169811,5.44897727 L4.98128302,4.81700758 L6.45966038,3.3330303 C6.68415094,3.10768939 7.04807547,3.10768939 7.27218868,3.3330303 C7.49671698,3.55829545 7.49671698,3.92359848 7.27222642,4.14893939 Z" id="Path" fill="#FFFFFF" fillRule="nonzero" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          )
        }
      </div>
    </div>
  </Option>
);

IconOption.propTypes = {
  selectProps: PropTypes.shape({
    value: PropTypes.object,
  }).isRequired,
  data: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    hasError: PropTypes.bool,
    hasWarning: PropTypes.bool,
  }).isRequired,
};

export default IconOption;
