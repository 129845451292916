import React, {
  ReactNode, FunctionComponent, Fragment, ReactChild,
} from 'react';
import { Link } from 'react-router-dom';

import './Breadcrumbs.scss';

type BreadcrumbsProps = {
  currentHeader: string,
  parents: {
    label: string | ReactChild,
    to: string,
  }[],
  separator?: string | ReactNode;
};

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  parents,
  currentHeader,
  separator = '/',
}) => {
  const sep = typeof separator === 'string' ? (
    <p className="breadcrumbs-separator">{separator}</p>
  ) : (
    separator
  );
  return (
    <div className="breadcrumbs-container">
      {parents.map(({ to, label }) => (
        <Fragment key={to}>
          <Link to={to}>
            {typeof label === 'string' ? (
              <h2 className="breadcrumbs-label">{label}</h2>
            ) : (
              label
            )}
          </Link>
          {sep}
        </Fragment>
      ))}
      <h2 className="breadcrumbs-label">{currentHeader}</h2>
    </div>
  );
};

export default Breadcrumbs;
