import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appStateReducer from 'store/appState';
import { actions } from '../routes/Network/modules/network';
import { feederActions } from '../routes/Network/modules/feeders';
import { powerflowActions } from '../routes/Network/modules/powerflow';
import { loadForecastActions } from '../routes/Network/modules/loadForecast';
import { activityLogActions } from '../routes/Network/modules/activityLog';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import WorkspaceLayout from '../WorkspaceLayout';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
    ...feederActions,
    ...powerflowActions,
    ...appStateReducer.actions,
    ...loadForecastActions,
    ...activityLogActions,
  }, dispatch),
});

const mapStateToProps = state => ({
  network: state.network,
  feeders: state.feeders,
  theme: state.global.theme,
  view: state.global.view,
  loadForecast: state.loadForecast,
  jobQueued: state.powerflow.jobQueued,
  inEditMode: state.edit.inEditMode,
  permissions: state.global.permissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceLayout);
