import ConductingEquipment from '../ConductingEquipment';
import ConnectivityNode from '../ConnectivityNode';

class ShuntDevice extends ConductingEquipment {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.isShunt = true;
  }

  // Get the terminal coordinates and attempt to find an offset asset coordinates
  // If no asset coordinates exist, default to using terminal coordinates
  get coordinates() {
    if (this._assetPosition) {
      return {
        asset: this._assetPosition,
        terminal: this._terminalPosition,
      };
    }
    return {
      asset: this._terminalPosition,
      terminal: this._terminalPosition,
    };
  }

  get baseVoltage() {
    // the voltage at which this device is connected
    const node = new ConnectivityNode(this.connectivityNode, this.cimDict);
    return node.baseVoltage;
  }

  get terminalIdForPQTimeSeries() {
    return this.terminals[0];
  }

  /* Map Render Helpers */
  get _terminalPosition() {
    if (this._positionPoints && this._positionPoints.length) {
      return this.cimDict[this._positionPoints[0]].attributes;
    }
    return [];
  }

  get _assetPosition() {
    const LOCATION = 'PowerSystemResource.Location';
    const POSITION = 'Location.PositionPoints';

    try {
      if (this.references[LOCATION]) {
        const location = this.cimDict[this.references[LOCATION]];
        let positionPoint;
        if (location.references[POSITION].length > 1) {
          const points = location.references[POSITION];
          // If there are multiple position points, use the last one in the sequence.
          [positionPoint] = points.filter(point => (
            this.cimDict[point].attributes['PositionPoint.sequenceNumber'] === points.length - 1
          ));
        } else {
          [positionPoint] = location.references[POSITION];
        }
        // Extract the correct position point.
        return this.cimDict[positionPoint].attributes;
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  /* SV Helpers */
  get connectivityNode() {
    const terminal = this.cimDict[this.terminals[0]];
    if (terminal) {
      const node_id = terminal.references['Terminal.ConnectivityNode'];
      return Array.isArray(node_id) ? node_id[0] : node_id;
    }
    return undefined;
  }

  get _topologicalNode() {
    const terminal = this.cimDict[this.terminals[0]];
    if (terminal) {
      const node_id = terminal.references['Terminal.TopologicalNode'];
      return Array.isArray(node_id) ? node_id[0] : node_id;
    }
    return undefined;
  }
}

export default ShuntDevice;
