import React, {
  FunctionComponent, ReactNode, ReactChild, useState,
} from 'react';
import { ThemeProp } from 'types/index';
import IconButton from '../IconButton';
import Icons from '../Icons';

type AccordionProps = {
  theme: ThemeProp,
  className?: string,
  withBorder?: boolean,
  tabs: { key: string|number, title: string, tabHeader?:ReactChild|ReactNode, tabBody: ReactChild|ReactNode }[],
};
const Accordion: FunctionComponent<AccordionProps> = ({
  className = '',
  withBorder = false,
  tabs,
  theme,
}) => {
  const [active, setActive] = useState(tabs[0]?.key);
  const { ExpandIcon, CollapseIcon } = Icons;
  return (
    <div className={`accordion-section ${className} ${theme}`} data-test="accordion">
      {tabs.map((tab) => (
        <div className={`accordion ${withBorder ? 'include-border' : ''}`} key={tab.key}>
          <header className={`accordion-header ${active === tab.key && 'active'}`}>
            <h3 className="title">{tab.title}</h3>
            {tab.tabHeader && (tab.tabHeader)}
            <div className="button-menu">
              <IconButton
                onClick={() => setActive(tab.key)}
                theme={theme}
                className="expand-icon"
              >
                {active === tab.key ? <CollapseIcon /> : <ExpandIcon />}
              </IconButton>
            </div>
          </header>
          <div className="content" style={{ display: active === tab.key ? 'block' : 'none' }}>
            {tab.tabBody}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
