import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import asyncStates from 'helpers/asyncActionStates';
import ThemeContext from 'helpers/ThemeContext';
import { kVAr, kW } from 'helpers/units';

import AssetSchedule from '../partials/AssetSchedule';
import CustomerProgramSelector from '../partials/CustomerProgramSelector';
import EditableControlMode from '../partials/EditableControlMode';
import LifecycleSection from '../partials/LifecycleSection';
import ShuntDeviceHeader from '../partials/ShuntDeviceHeader';
import InstanceInfo from './InstanceInfo';

import './AsynchronousMachine.scss';

// Control Panel
const controlHelpDefault = {
  uncontrolled:
    'During timeseries powerflow, generators set to \'fixed\' are allocated by the load allocation process.',
};
const controlPanelValues = {
  uncontrolled: [
    {
      id: 'p', key: 'p', label: 'Total Power', unit: kW, divisor: -1000, type: 'number',
    },
    {
      id: 'q', key: 'q', label: 'Total Reactive Power', unit: kVAr, divisor: -1000, type: 'number',
    },
  ],
};

const controlTypesDefault = [
  { value: 'uncontrolled', label: 'Fixed (no control)' },
  { value: 'locallyControlled', disabled: true, label: 'Local' },
  { value: 'globallyOptimized', disabled: true, label: 'Global' },
  { value: 'scheduled', disabled: false, label: 'Schedule' },
];

function AsynchronousMachine({
  asset: asyncMachine,
  inEditMode,
  canEditNetwork,
  expanded,
  selected: { id, class: assetClass },
  applyDifferenceModelRequest,
  editActions,
  workspace,
  branch,
  displayBranch,
  toggleFeederPanel,
  loadForecast: { selectedScenario, selectedScenarioType },
  setSelectedAssetID,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  authEnabled,
  permissions,
  controlHelp,
  controlTypes,
  generatorType,
}) {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'asynchronous_machine', id, body);

  const handleLifeCycleChange = lifecycle => handleSave({ lifecycle, phases: asyncMachine.phases });
  const controlMode = asyncMachine.analysis_control?.mode;

  return (
    <>
      <div
        className={classNames({
          'asset-panel-values': true,
          'asset-panel-values--expanded': expanded,
        })}
      >
        <ShuntDeviceHeader
          asset={{ ...asyncMachine }}
          assetId={id}
          toggleFeederPanel={toggleFeederPanel}
          inEditMode={inEditMode}
          disabled={saving || !canEditNetwork}
          setSelectedAssetID={setSelectedAssetID}
          handleSave={handleSave}
        />

        <hr className="section-divider" />
        <InstanceInfo
          asset={asyncMachine}
          inEditMode={inEditMode}
          displayBranch={displayBranch}
          workspace={workspace}
          disabled={!inEditMode || !canEditNetwork || saving}
          handleSave={handleSave}
          generatorType={generatorType}
        />

        <hr className="section-divider" />

        {!inEditMode && permissions.has('get_der_program_mapping') && (
          <section id="customer-program-section">
            <h2 className="title-text">Customer Program</h2>
            <CustomerProgramSelector
              workspace={workspace}
              branch={branch}
              scenarioId={selectedScenario}
              assetId={id}
              customerPrograms={asyncMachine.customer_agreements ?? []}
              disabled={!selectedScenario || !permissions.has('alter_der_program_mapping')}
            />
            <hr className="section-divider" />
          </section>
        )}
        <EditableControlMode
          controlMode={controlMode}
          help={(controlHelp || controlHelpDefault)[controlMode]}
          asset={assetClass}
          editableValues={controlPanelValues[controlMode]}
          controlModeOptions={controlTypes || controlTypesDefault}
          attributes={{ ...asyncMachine.attributes }}
          disabled={saving || !canEditNetwork}
          onSave={handleSave}
          theme={theme}
        >
          {['scheduled', 'globallyOptimized'].includes(controlMode) && !inEditMode && (
            <AssetSchedule
              workspace={workspace}
              branch={branch}
              scenario={selectedScenario}
              scenarioType={selectedScenarioType}
              asset={{ id, class: assetClass }}
              scheduleType="Normal"
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              panelValues={{ ...asyncMachine.attributes }}
              editable={!authEnabled || permissions.has('modify_asset_schedule')}
            />
          )}
        </EditableControlMode>

        <LifecycleSection
          disabled={saving || !inEditMode || !canEditNetwork}
          lifecycle={asyncMachine.lifecycle}
          onChange={handleLifeCycleChange}
          healthMetric={asyncMachine.reliability_info?.healthMetric}
          onHealthMetricChange={handleSave}
        />

      </div>
    </>
  );
}

AsynchronousMachine.defaultProps = {
  controlHelp: null,
  controlTypes: null,
  generatorType: 'async',
};

AsynchronousMachine.propTypes = {
  asset: PropTypes.object.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
  }).isRequired,
  displayBranch: PropTypes.string.isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  editActions: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  controlHelp: PropTypes.object,
  controlTypes: PropTypes.array,
  generatorType: PropTypes.string,
};

export default AsynchronousMachine;
