import React, { FunctionComponent, useContext, useEffect } from 'react';
import Select from 'components/Select';
import { useAsset } from 'contexts/AssetContext';
import ThemeContext from 'helpers/ThemeContext';
import './HostingCapacityMenu.scss';

type NodePhasesProps = {
  node_id: string,
  phase: string,
  setPhase: (phase: string) => void,
};

const NodePhases: FunctionComponent<NodePhasesProps> = ({
  node_id,
  phase,
  setPhase,
}) => {
  const theme = useContext(ThemeContext);
  const { asset } = useAsset(node_id, 'node');
  useEffect(() => {
    if (!phase && ['A', 'B', 'C', 'ABC'].some(ph => asset?.phase === ph)) {
      setPhase(asset.phase);
    }
  }, [asset, phase, setPhase]);
  return (
    <div className="menu-row">
      <p className="label">Phases</p>
      <Select
        id="phase-selector"
        options={[
          { label: 'A', value: 'A', disabled: !asset?.phase.includes('A') },
          { label: 'B', value: 'B', disabled: !asset?.phase.includes('B') },
          { label: 'C', value: 'C', disabled: !asset?.phase.includes('C') },
          { label: 'ABC', value: 'ABC', disabled: !asset?.phase.includes('ABC') },
        ]}
        value={phase || asset?.phase}
        disabled={!asset}
        onChange={(e: any) => setPhase(e?.value)}
        theme={theme}
      />
    </div>
  );
};

export default NodePhases;
