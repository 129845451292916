import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import { isDefined } from 'helpers/utils';

import Helpers from '../../helpers/EquipmentLibraryHelpers';
import {
  areAssetModelsEqual,
  areAssetModelPropertiesChanged,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
} from '../../helpers/assetModelHelpers';
import DescriptionEditor from './DescriptionEditor';
import CostEditor from './CostEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

const getNewBattery = () => ({
  description: '',
  name: '',
  ratedEnergy: '',
  minP: '',
  maxP: '',
  maxEnergy: '',
  minEnergy: '',
  chargeEfficiency: '',
  dischargeEfficiency: '',
  assetModel: { ...defaultAssetModel },
  id: 'add',
});

class BatteryPanel extends Component {
  state = { ...(getNewBattery()) }

  componentDidMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({ ...this.getInputValues(this.props.selected) });
    }
  }

  getInputValues = (selected) => {
    let asset = { ...(getNewBattery()) };
    if (selected) {
      const {
        description,
        name,
        id,
        ratedEnergy,
        minP,
        maxP,
        minEnergy,
        maxEnergy,
        chargeEfficiency,
        dischargeEfficiency,
        AssetModel,
      } = selected;
      asset = {
        description,
        name: name || asset.name,
        ratedEnergy: `${ratedEnergy / 1000}` || asset.ratedEnergy,
        minP: `${minP / -1000}` || asset.minP,
        maxP: `${maxP / 1000}` || asset.maxP,
        minEnergy: `${minEnergy / 1000}` || asset.minEnergy,
        maxEnergy: `${maxEnergy / 1000}` || asset.maxEnergy,
        chargeEfficiency: `${chargeEfficiency}` || asset.chargeEfficiency,
        dischargeEfficiency: `${dischargeEfficiency}` || asset.dischargeEfficiency,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        id,
      };
    }
    return asset;
  }

  handleSave = () => {
    const diffModel = {
      name: this.state.name,
      description: this.state.description,
      ratedEnergy: parseFloat(this.state.ratedEnergy * 1000),
      minP: parseFloat(this.state.minP * -1000),
      maxP: parseFloat(this.state.maxP * 1000),
      minEnergy: parseFloat(this.state.minEnergy * 1000),
      maxEnergy: parseFloat(this.state.maxEnergy * 1000),
      chargeEfficiency: parseFloat(this.state.chargeEfficiency),
      dischargeEfficiency: parseFloat(this.state.dischargeEfficiency),
      AssetModel: { ...this.state.assetModel },
    };
    if (this.state.id === 'add') {
      this.props.handleCreate('energy_storage_unit_info', diffModel, 'EnergyStorageUnitInfo');
    } else {
      // Only submit the values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        if (key === 'AssetModel') {
          if (!areAssetModelsEqual(diffModel[key], selected[key])) {
            diff[key] = diffModel[key];
          }
        } else if (diffModel[key] !== selected[key]) {
          diff[key] = diffModel[key];
        }
        return diff;
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  }

  handleInputChange = ({ id, value }) => {
    this.setState({ [id]: value });
  }

  valuesUpdated = (selected) => {
    if (!selected) return true;
    const stringUpdated = selected.name !== this.state.name
      || selected.description !== this.state.description;
    const propMultipliers = [{
      name: 'ratedEnergy',
      multiplier: 1000,
    }, {
      name: 'minP',
      multiplier: -1000,
    }, {
      name: 'maxP',
      multiplier: 1000,
    }, {
      name: 'minEnergy',
      multiplier: 1000,
    }, {
      name: 'maxEnergy',
      multiplier: 1000,
    }, {
      name: 'chargeEfficiency',
      multiplier: 1,
    }, {
      name: 'dischargeEfficiency',
      multiplier: 1,
    }];
    const numberUpdated = propMultipliers.some(val => selected[`${val.name}`] !== (parseFloat(this.state[val.name]) * val.multiplier));
    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selected.AssetModel),
      this.state.assetModel,
    );
    return stringUpdated || numberUpdated || assetModelUpdated;
  }

  formValid = () => {
    const positiveValue = val => val !== null && val >= 0;
    const nonZero = (val, posNeg = 'pos') => val !== null && (posNeg === 'pos' ? val > 0 : val < 0);
    const percentage = val => nonZero(val) && val <= 100;
    return (
      this.state.name.trim().length > 0
      && nonZero(this.state.maxP)
      && nonZero(this.state.minP)
      && nonZero(this.state.ratedEnergy)
      && positiveValue(this.state.minEnergy)
      && nonZero(this.state.maxEnergy)
      && percentage(this.state.chargeEfficiency)
      && percentage(this.state.dischargeEfficiency)
      && parseFloat(this.state.minEnergy) <= parseFloat(this.state.maxEnergy)
      && parseFloat(this.state.maxEnergy) <= parseFloat(this.state.ratedEnergy)
      && isAssetModelValid(this.state.assetModel)
      && this.valuesUpdated(this.props.selected)
    );
  };

  render() {
    const ratedEnergy = Helpers.createDisplayObject('Rated Energy', 'ratedEnergy', this.state.ratedEnergy, 'kWh');
    const minP = Helpers.createDisplayObject('Maximum Rate of Discharge', 'minP', this.state.minP, 'kW');
    const maxP = Helpers.createDisplayObject('Maximum Rate of Charge', 'maxP', this.state.maxP, 'kW');
    const minEnergy = Helpers.createDisplayObject('Minimum Energy', 'minEnergy', this.state.minEnergy, 'kWh');
    const maxEnergy = Helpers.createDisplayObject('Maximum Energy', 'maxEnergy', this.state.maxEnergy, 'kWh');
    const chargeEfficiency = Helpers.createDisplayObject('Charge Efficiency', 'chargeEfficiency', this.state.chargeEfficiency, '%');
    const dischargeEfficiency = Helpers.createDisplayObject('Discharge Efficiency', 'dischargeEfficiency', this.state.dischargeEfficiency, '%');
    const isDisabled = (
      this.props.authEnabled
      && ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type'))
      || (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type'))
      || (this.props.match.params.branch === 'master' && !this.props.permissions.has('modify_network_as_built')))
    );

    const defaultProps = {
      disabled: isDisabled,
      theme: this.props.theme,
    };

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              <TextInput
                {...defaultProps}
                id="name"
                label="Name"
                value={this.state.name}
                onChange={({ target }) => this.handleInputChange({ id: target.id, value: target.value })}
                required
                inputWidth="225px"
              />
              <NumberInput
                onChange={this.handleInputChange}
                step="0.001"
                required
                posNonZero
                {...defaultProps}
                {...minP}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={this.handleInputChange}
                step="0.001"
                posNonZero
                required
                {...defaultProps}
                {...maxP}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={this.handleInputChange}
                ge={0}
                step="0.001"
                required
                invalid={parseFloat(this.state.minEnergy) > parseFloat(this.state.maxEnergy)}
                validationMessage="Max Energy must be >= Min Energy"
                {...defaultProps}
                {...minEnergy}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={this.handleInputChange}
                posNonZero
                step="0.001"
                required
                invalid={parseFloat(this.state.minEnergy) > parseFloat(this.state.maxEnergy)
                  || parseFloat(this.state.maxEnergy) > parseFloat(this.state.ratedEnergy)}
                validationMessage={'Max Energy must '
                  + 'be >= Min Energy and <= Rated Energy'}
                {...defaultProps}
                {...maxEnergy}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={this.handleInputChange}
                posNonZero
                step="0.001"
                required
                invalid={parseFloat(this.state.maxEnergy) > parseFloat(this.state.ratedEnergy)}
                validationMessage="Max Energy must be <= Rated Energy"
                {...defaultProps}
                {...ratedEnergy}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={this.handleInputChange}
                le={100}
                posNonZero
                step="0.001"
                required
                {...defaultProps}
                {...chargeEfficiency}
                inputStyle="eq-lib"
              />
              <NumberInput
                onChange={this.handleInputChange}
                le={100}
                posNonZero
                step="0.001"
                required
                {...defaultProps}
                {...dischargeEfficiency}
                inputStyle="eq-lib"
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown
                  escapeHtml
                  source={decodeURIComponent(this.state.description)}
                />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={areAssetModelPropertiesChanged(
              this.state.assetModel,
              getAssetModelProperties(this.props.selected?.AssetModel),
            )[1]}
            key={`${this.state.id}-costs`}
            onChange={prop => this.setState(prevState => ({
              assetModel: { ...prevState.assetModel, ...prop },
            }))}
            isDisabled={isDisabled}
            theme={this.props.theme}
          />,
        ]}
      </PanelTabs>
    );
  }
}

BatteryPanel.defaultProps = {
  selected: null,
};

BatteryPanel.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  authEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default BatteryPanel;
