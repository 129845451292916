import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import ThemeContext from 'helpers/ThemeContext';
import { isDefined } from 'helpers/utils';

// Create an editable field. Can only be used for single values. Per phase values
// should be rendered with `EditablePhaseValues`

const SingleEditableValue = (props) => {
  const getValue = () => {
    if (isDefined(props.value)) return props.value;
    return props[props.id] !== undefined ? props[props.id] : '';
  };
  const isInvalid = (val, validator) => validator && validator(val, { ...props, [props.id]: getValue() });

  const validationCheck = isInvalid(getValue(), props.customValidation);

  const getValidationMessage = () => {
    if (props.validationMessage && Array.isArray(props.validationMessage)) {
      return props.validationMessage.map(msg => (
        <span className="validation-message" key={msg}>
          {msg}
        </span>
      ));
    }
    const validatorMessage = validationCheck ? validationCheck.message : null;
    return props.validationMessage || validatorMessage;
  };

  const invalid = props.invalid || (validationCheck && !validationCheck.valid);
  const edited = props.edited[props.id] || props.edited === true;
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Input
          key={`${props.id}-editable`}
          inputStyle="panel"
          label={props.label}
          value={getValue()}
          unit={props.unit}
          onChange={props.onChange}
          edited={edited}
          id={props.id}
          name={props.id}
          htmlFor={props.htmlFor}
          type={props.type}
          theme={theme}
          disabled={!props.editable || props.disabled}
          onBlur={(!invalid && edited) ? props.onBlur : null}
          phases={props.phases}
          validation={{
            min: props.min,
            max: props.max,
            required: props.required,
            invalid,
            validationMessage: getValidationMessage(),
            showValidationMessage: props.showValidationMessage,
          }}
          options={{
            step: props.step,
            divisor: props.divisor,
            precision: props.precision,
          }}
        />
      )}
    </ThemeContext.Consumer>
  );
};

SingleEditableValue.defaultProps = {
  unit: null,
  min: null,
  max: null,
  required: false,
  step: 'any',
  invalid: false,
  validationMessage: null,
  customValidation: null,
  precision: null,
  divisor: 1,
  editable: true,
  disabled: false,
  onBlur: undefined,
  phases: undefined,
  showValidationMessage: 'after-edit',
  edited: {},
  onChange: null,
  htmlFor: undefined,
  value: undefined,
};

SingleEditableValue.propTypes = {
  id: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  divisor: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  edited: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  type: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  required: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  invalid: PropTypes.bool,
  validationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customValidation: PropTypes.func,
  precision: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  phases: PropTypes.string,
  showValidationMessage: PropTypes.oneOf(['always', 'after-edit', 'never']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SingleEditableValue;
