import React, { PureComponent } from 'react';
import Request from 'helpers/Request';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  Tooltip,
} from 'recharts';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import ThemeContext from 'helpers/ThemeContext';
import ResultsChartCard from './ResultsChartCard';
import './TimeSeriesChart.scss';

class StateOfChargeChart extends PureComponent {
  state = {
    socData: [],
    loading: 'initial',
  }

  componentDidMount() {
    this.fetchSOC();
  }

  componentDidUpdate(prevProps) {
    const {
      workspace, branch, scenario, analysisName, feeder, maxRange, ev_id,
    } = prevProps;
    if (
      this.props.workspace !== workspace
      || this.props.branch !== branch
      || this.props.scenario !== scenario
      || this.props.analysisName !== analysisName
      || this.props.feeder !== feeder
      || this.props.maxRange.start !== maxRange.start
      || this.props.maxRange.end !== maxRange.end
      || this.props.ev_id !== ev_id
    ) {
      this.fetchSOC();
    }
  }

  setTimeframe = () => (
    Math.abs(
      moment(this.props.maxRange.end).diff(moment(this.props.maxRange.start), 'hours'),
    )
      > 24
  );

  fetchSOC = async () => {
    this.setState({ loading: 'loading' });
    const timeframeGT24 = this.setTimeframe();
    const url = `/api/workspace/${this.props.workspace}/branch/${this.props.branch}/power-flow-results/charging_events`;
    try {
      let socData = [];
      if (this.props.analysisName) {
        const response = await new Request(url).get({
          params: {
            scenario_id: this.props.scenario,
            analysis_name: this.props.analysisName,
            feeder: this.props.feeder,
            start_date: moment.utc(this.props.maxRange.start).toISOString(),
            end_date: moment.utc(this.props.maxRange.end).toISOString(),
            ev_id: this.props.ev_id,
          },
        });
        socData = response.data;
      }
      let loading;
      if (socData.length === 0) {
        loading = 'initial';
      } else if (timeframeGT24) {
        loading = 'error';
      } else {
        loading = 'loaded';
      }
      this.setState({ socData, loading });
    } catch (err) {
      this.setState({ socData: [], loading: 'initial' });
    }
  }

  generateLine = (event) => (
    <Line
      key={event}
      name={event}
      type="stepAfter"
      dataKey={event}
      stroke="#639DD1"
      strokeWidth={2}
      dot={false}
    />
  );

  customTooltip = (e, events) => (
    <div className="tooltip">
      <p><b>{e.label}</b></p>
      <table>
        <tbody>
          {(e.payload ?? []).map(event => (
            <tr key={event.name}>
              <td><b>{`Event ${events.findIndex(ev => ev === event.name) + 1 || ''}:`}</b></td>
              <td>{`${event.value.toFixed(2)} %`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  render() {
    const theme = typeof this.context === 'string' ? this.context : 'dark';
    const graphData = this.state.socData.map(tp => {
      const gData = {
        timepoint: moment(tp.timepoint).toISOString(),
      };
      if (tp.charging_events) {
        tp.charging_events.reduce((acc, curr) => {
          acc[curr.id] = curr.initial_soc;
          return acc;
        }, gData);
      }
      return gData;
    });

    const events = [...new Set(graphData.reduce((acc, curr) => {
      const keys = [...acc, ...Object.keys(curr).filter(k => k !== 'timepoint')];
      return keys;
    }, []))];

    const placeholderMessage = () => {
      let message = null;
      if (this.state.loading === 'error') {
        message = 'Please select a timeframe less than 24 hours';
      }
      return message;
    };
    return (
      <ResultsChartCard
        title="State of Charge"
        className="time-series-chart"
        theme={theme}
        state={this.state.loading}
        message={placeholderMessage()}
      >
        <>
          <div className="unit-label-row">
            <p>(%)</p>
          </div>
          <div
            className={classNames({
              'chart-pane': true,
              'chart-pane--expanded': this.props.expanded,
            })}
          >
            <ResponsiveContainer height="100%">
              <LineChart width={298} height={198} data={graphData}>
                <CartesianGrid horizontal vertical={false} />
                <Tooltip content={e => this.customTooltip(e, events)} />
                <XAxis
                  dataKey="timepoint"
                  tick={{ fill: '#949899' }}
                  tickFormatter={val => moment.utc(val).format('HH:mm')}
                />
                <YAxis domain={[0, 100]} tick={{ fill: '#949899' }} />
                {events.map(event => this.generateLine(event))}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      </ResultsChartCard>
    );
  }
}

StateOfChargeChart.contextType = ThemeContext;

StateOfChargeChart.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  feeder: PropTypes.string.isRequired,
  scenario: PropTypes.string.isRequired,
  analysisName: PropTypes.string.isRequired,
  ev_id: PropTypes.string.isRequired,
  maxRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default StateOfChargeChart;
