import React, { FunctionComponent, useState } from 'react';
import ResultsCard from 'components/ResultsCard';
import { useRequestEffect } from 'hooks/useRequest';
import { ThemeProp } from 'types/index';
import { isDefined, range } from 'helpers/utils';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import IconButton from 'components/IconButton';
import moment from 'moment';
import Icons from 'components/Icons';

import ValueCard from './ValueCard';

const { RefreshIcon } = Icons;

type NetPresentValueProps = {
  theme: ThemeProp;
  workspace: string | null;
  branch: string | null;
};

type NpvValues = {
  cost_of_upgrade: number | null;
  labour_costs: number | null;
  maintenance_costs: number | null;
  material_costs: number | null;
  remaining_value_of_replaced_assets: number | null;
};

type NpvResults = {
  adjusted: NpvValues;
  current: NpvValues;
  savings: NpvValues;
};

type ConstructionProject = {
  completion_date: string;
  id: number;
  name: string;
};

const NetPresentValueSummary: FunctionComponent<NetPresentValueProps> = ({
  theme,
  workspace,
  branch,
}) => {
  const [adjustedDate, setAdjustedDate] = useState<moment.Moment|null>(null);
  const [selectedProject, setSelectedProject] = useState<ConstructionProject|null>(null);
  const [maintenanceYears, setMaintenanceYears] = useState(10);

  const {
    data: constructionProjects, loading: constructionProjectsLoading,
  } = useRequestEffect<ConstructionProject[]>({
    url: `/api/workspace/${workspace}/construction_project`,
    method: 'get',
    refetchOnChange: [workspace],
    disabled: !(workspace),
  });

  const {
    data: allProjectNPV, loading: allProjectNPVLoading,
  } = useRequestEffect<NpvResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/npv`,
    method: 'get',
    refetchOnChange: [workspace, branch],
    disabled: !(workspace && branch),
  });

  const {
    data: selectedProjectNPV,
    loading: selectedProjectNPVLoading,
    refetch: refetchSelectedProject,
    reset: resetSelectedProject,
  } = useRequestEffect<NpvResults>({
    url: `/api/workspace/${workspace}/branch/${branch}/npv`,
    params: {
      project_id: selectedProject?.id,
      maintenance_years: maintenanceYears,
      ...(adjustedDate ? { adjusted_date: adjustedDate.format('Y-MM-DD') } : {}),
    },
    method: 'get',
    refetchOnChange: [workspace, branch, selectedProject, maintenanceYears],
    disabled: !(workspace && branch && selectedProject),
  });

  const getTotalCost = (
    npvResult: NpvValues|undefined,
  ) => (npvResult?.cost_of_upgrade ?? 0) + (npvResult?.maintenance_costs ?? 0);
  // note: we are intentioning nulling falsy values including 0 such that they appear as --
  return (
    <ResultsCard
      theme={theme}
      expandableContents={(
        <>
          <div className="npv-values">
            <div className="item-text item-text-title">Completion Date</div>
            <div>
              {isDefined(selectedProject) ? (
                <DatePicker
                  date={moment.utc(selectedProject?.completion_date)}
                  disabled
                  theme={theme}
                  showArrows={false}
                  dateFormat="Y-m-d"
                  onChange={() => {}}
                />
              ) : <>--</>}
            </div>
            <div />
            <div className="item-text item-text-title">Adjust Completion Date</div>
            <div>
              {isDefined(selectedProject) ? (
                <>
                  <DatePicker
                    defaultDate={moment.utc(selectedProject?.completion_date)}
                    theme={theme}
                    showArrows={false}
                    dateFormat="Y-m-d"
                    onChange={(value: moment.Moment) => setAdjustedDate(value)}
                    options={{
                      // set date range from now to now + 99 years
                      minDate: moment.utc().startOf('date').toDate(),
                      maxDate: moment.utc().startOf('date').add(99, 'years').toDate(),
                    }}
                  />

                </>
              ) : <>--</>}
            </div>
            {isDefined(selectedProject) ? (
              <IconButton
                className="npv-refresh"
                disabled={!isDefined(adjustedDate)}
                theme={theme}
                onClick={refetchSelectedProject}
              >
                <RefreshIcon type={isDefined(adjustedDate) ? 'primary' : 'disabled'} />
              </IconButton>
            ) : <div />}
            {isDefined(adjustedDate) ? (
              <>
                <div className="item-text item-text-title">Deferral Savings</div>
                <ValueCard
                  className="color-separator all-project"
                  loading={allProjectNPVLoading}
                  value={null}
                  isCurrency
                />
                <ValueCard
                  className="color-separator selected-project"
                  loading={selectedProjectNPVLoading}
                  value={getTotalCost(selectedProjectNPV?.savings) || null}
                  isCurrency
                />
              </>
            ) : (
              <>
                <div />
                <div className="color-separator all-project" />
                <div className="color-separator selected-project" />
              </>
            )}
            <div className="item-text item-text-title">Material cost</div>
            <ValueCard
              loading={allProjectNPVLoading}
              value={allProjectNPV?.current?.material_costs || null}
              isCurrency
            />
            <ValueCard
              loading={selectedProjectNPVLoading}
              value={selectedProjectNPV?.adjusted?.material_costs || null}
              isCurrency
            />
            <div className="item-text item-text-title">Labour cost</div>
            <ValueCard
              loading={allProjectNPVLoading}
              value={allProjectNPV?.current?.labour_costs || null}
              isCurrency
            />
            <ValueCard
              loading={selectedProjectNPVLoading}
              value={selectedProjectNPV?.adjusted?.labour_costs || null}
              isCurrency
            />
            <div className="item-text item-text-title">Remaining value of replaced assets</div>
            <ValueCard
              loading={allProjectNPVLoading}
              value={allProjectNPV?.current?.remaining_value_of_replaced_assets || null}
              isCurrency
            />
            <ValueCard
              loading={selectedProjectNPVLoading}
              value={selectedProjectNPV?.adjusted?.remaining_value_of_replaced_assets || null}
              isCurrency
            />
            <div className="item-text item-text-title">Cost of upgrade</div>
            <ValueCard
              loading={allProjectNPVLoading}
              value={allProjectNPV?.current?.cost_of_upgrade}
              isCurrency
            />
            <ValueCard
              loading={selectedProjectNPVLoading}
              value={selectedProjectNPV?.adjusted?.cost_of_upgrade}
              isCurrency
            />
            <div>
              <div className="item-text item-text-title">Maintenance cost</div>
              <Select
                clearable={false}
                value={maintenanceYears}
                options={range(0, 100).map(val => ({ value: val, label: `${val} years` }))}
                onChange={val => setMaintenanceYears(val?.value ?? 10)}
                type="primary"
                width={150}
                theme={theme}
              />
            </div>

            <ValueCard
              loading={allProjectNPVLoading}
              value={allProjectNPV?.current?.maintenance_costs || null}
              isCurrency
            />
            <ValueCard
              loading={selectedProjectNPVLoading}
              value={selectedProjectNPV?.adjusted?.maintenance_costs || null}
              isCurrency
            />
          </div>
        </>
      )}
      className="results-comparison-card summary-card"
    >
      <div className="one-one grid-columns items-centered npv-heading">
        <ValueCard
          className="all-project"
          primary
          value={getTotalCost(allProjectNPV?.current) || null}
          isCurrency
          loading={allProjectNPVLoading}
          label="All Projects"
        />
        <ValueCard
          className="selected-project"
          primary
          value={getTotalCost(selectedProjectNPV?.adjusted) || null}
          isCurrency
          loading={selectedProjectNPVLoading || constructionProjectsLoading}
          label={(
            <Select
              searchable={false}
              value={selectedProject?.id}
              options={constructionProjects?.map(cp => {
                const date = moment.utc(cp.completion_date);
                const isCompleted = date < moment.utc().startOf('date');
                return {
                  value: cp.id,
                  label: `${cp.name}${isCompleted ? ' (complete)' : ''}`,
                  disabled: isCompleted,
                };
              }) ?? []}
              onChange={evt => {
                if (evt) {
                  setSelectedProject(constructionProjects?.find(cp => cp.id === evt.value) ?? null);
                } else {
                  setSelectedProject(null);
                  resetSelectedProject();
                }
              }}
              type="text"
              width={200}
              theme={theme}
            />
          )}
        />
      </div>
    </ResultsCard>
  );
};

export default NetPresentValueSummary;
