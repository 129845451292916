import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import asyncActionStates from 'helpers/asyncActionStates';
import IconButton from 'components/IconButton';
import CustomScrollBar from 'components/CustomScrollBar';
import FileSaver from 'file-saver';
import Request from 'helpers/Request';

import './NetworkImportPanel.scss';

const NetworkImportsPanel = ({
  disabled,
  displayAlertMessage,
  open,
  theme,
  workspace,
}) => {
  const [networkImportLoadingState, setLoadingImportState] = useState(asyncActionStates.INITIAL);
  const [downloadNetworkState, setDownloadNetworkState] = useState(asyncActionStates.INITIAL);
  const [updateJobStatus, setUpdateJobStatus] = useState(asyncActionStates.INITIAL);
  const [jobs, setJobs] = useState([]);

  const statusOptions = [
    {
      label: 'Pending',
      value: 'PENDING',
    }, {
      label: 'Converting',
      value: 'CONVERTING',
    }, {
      label: 'Importing',
      value: 'IMPORTING',
    }, {
      label: 'Completed',
      value: 'COMPLETED',
    }, {
      label: 'Failed',
      value: 'FAILED',
    },
  ];

  const loadNetworkImport = () => {
    let didCancel = false;

    async function fetchAnalyticsConfig() {
      const request = new Request(`/api/workspace/${workspace}/import`);
      setLoadingImportState(asyncActionStates.LOADING);
      try {
        const { data } = await request.get();

        if (didCancel) {
          // Cancelled before the request finished so do nothing
          setLoadingImportState(asyncActionStates.CANCELLED);
          return;
        }

        data.sort((val1, val2) => val1.id - val2.id);

        setJobs(data);
        setLoadingImportState(asyncActionStates.SUCCESS);
      } catch (error) {
        displayAlertMessage(
          'Failed to retrieve import jobs',
          'Could not retrieve import jobs. Please try again.',
          null,
          () => fetchAnalyticsConfig(),
        );
        setLoadingImportState(asyncActionStates.ERROR);
      }
    }

    // Only do a load when a component is opened
    if (open && !disabled) {
      fetchAnalyticsConfig();
    }
    return () => { didCancel = true; };
  };
  const downloadFile = async (job, file) => {
    const request = new Request(`/api/files${job.paths[file]}`);
    setDownloadNetworkState(asyncActionStates.LOADING);
    try {
      const { data, headers } = await request.get();
      const blob = new Blob([data], { type: headers['content-type'] });
      FileSaver.saveAs(blob, file);
      setDownloadNetworkState(asyncActionStates.SUCCESS);
    } catch (error) {
      displayAlertMessage(
        'Failed to download network data',
        'Could not downlod network data. Please try again.',
        null,
        () => downloadFile(),
      );
      setDownloadNetworkState(asyncActionStates.ERROR);
    }
  };

  const changeStatus = async (job, new_status) => {
    const request = new Request(`/api/workspace/${workspace}/branch/${job.branch}/import/${job.id}`);
    setUpdateJobStatus(asyncActionStates.LOADING);
    try {
      await request.patch({
        status: new_status,
      });
      setUpdateJobStatus(asyncActionStates.SUCCESS);
      loadNetworkImport();
    } catch (error) {
      displayAlertMessage(
        'Failed to update state',
        'Could not update state. Please try again.',
        null,
        () => changeStatus(),
      );
      setUpdateJobStatus(asyncActionStates.ERROR);
    }
  };

  useEffect(loadNetworkImport, [open, disabled, displayAlertMessage, workspace]);

  // Analytics Config State
  return (
    <CustomScrollBar>
      <table className={`network-import-panel ${theme}`}>
        <thead>
          <tr>
            <th>Upload ID</th>
            <th>Version</th>
            <th width="180px">Status</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(job => (
            <tr key={job.id} align="center">
              <td>{job.id}</td>
              <td>{job.branch}</td>
              <td>
                <select
                  onChange={e => changeStatus(job, e.target.value)}
                  value={job.status}
                  disabled={
                    disabled || networkImportLoadingState === asyncActionStates.LOADING
                    || updateJobStatus === asyncActionStates.LOADING
                  }
                >
                  {statusOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </td>
              <td>
                {job.file_names.map(name => (
                  <IconButton
                    key={name}
                    icon="get_app"
                    onClick={() => downloadFile(job, name)}
                    theme={theme}
                    disabled={
                    disabled || networkImportLoadingState === asyncActionStates.LOADING
                    || downloadNetworkState === asyncActionStates.LOADING
                  }
                    tooltip={name}
                  />
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CustomScrollBar>
  );
};

NetworkImportsPanel.defaultProps = {
  disabled: true,
  open: false,
};

NetworkImportsPanel.propTypes = {
  disabled: PropTypes.bool,
  displayAlertMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
};

export default NetworkImportsPanel;
