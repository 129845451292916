import React, {
  FunctionComponent, useState, useEffect, useMemo, useContext,
} from 'react';
import { useRequestEffect } from 'hooks/useRequest';
import ResultsCard from 'components/ResultsCard';
import BranchSection from 'routes/WorkspaceLayout/NetworkTopNav/BranchSection';
import ScenarioSection from 'routes/WorkspaceLayout/NetworkTopNav/containers/ScenarioSectionContainer';
import { isEmptyObject } from 'helpers/utils';
import Analytics from 'helpers/Analytics';
import Request from 'helpers/Request';
import Select from 'components/Select';
import moment, { Moment } from 'moment';
import { ThemeProp } from 'types/index';
import NumberInput from 'components/NumberInput';
import CalendarPicker from 'components/CalendarPicker';
import DeleteModal from 'components/DeleteModal';
import { useToasts } from 'react-toast-notifications';
import asyncActionStates from 'helpers/asyncActionStates';
import fileExportSave from 'helpers/FileDownload';
import './ResultsComparisonCard.scss';
import {
  getAnalyses, ANALYSIS_TYPES, determineScenarioTimeSpan, getAnalysisTimepoints,
} from '../../Network/helpers/NetworkHelpers';
import getBadgedAnalysisOption from '../../Network/components/getBadgedAnalysisOption';
import ResultsSummary from './ResultsSummary';
import { ResultsComparisonContext } from '../context/ResultsComparisonContext';

type ResultsComparisonCardProps = {
    theme?: ThemeProp,
    branches?: [],
    selectedScenario?: string|null,
    selectedAnalysis?: {[key: string]: any},
    scenarios?: [],
    match?: {
      params: {
        workspace: string|null,
        branch: string|null,
      },
    },
    maxRange?: {
      start?: Moment|null,
      end?: Moment|null,
    },
    updateSelectedScenario?: (event?: React.MouseEvent<HTMLElement, MouseEvent>|null) => void|null,
    permissions?: Set<string>,
    feeders?: [],
    pendingReq?: Record<string, unknown>|null,
    actions?: { analysisActive?: (branch?: string|null, scenario?: string|null, name?: string|null) => boolean,
      getContainersList?:() => null|void
    },
    removeResultsCard: (id: number) => void|null|number,
    id: number,
    isBaseline: boolean,
    title: string,
  };
const ResultsComparisonCard: FunctionComponent<ResultsComparisonCardProps> = ({
  theme = 'light',
  branches = [],
  selectedScenario = null,
  scenarios = [],
  match,
  updateSelectedScenario,
  permissions,
  maxRange,
  feeders = [],
  pendingReq,
  actions,
  selectedAnalysis = null,
  removeResultsCard,
  id,
  isBaseline,
  title,
}) => {
  const { addToast } = useToasts();
  const workspace = match?.params?.workspace || null;
  const [resultsBranch, setBranch] = useState(match?.params?.branch || null);
  const [allScenarios, setScenarios] = useState(scenarios);
  const [scenario, setScenario] = useState(selectedScenario || null);
  const [maxRangeSelected, setMaxRangeSelected] = useState(maxRange || {});
  const [analysesList, setAnalyses] = useState([]);
  const [analysis, setSelectedAnalysis] = useState(selectedAnalysis || null);
  const [modalActive, setModalActive] = useState(false);
  const [tooManyViolationsError, setTooManyViolationsError] = useState(false);

  const [reportLoading, setReportLoading] = useState(false);
  type PowerflowResults = {
    analysis_configuration: { interval: number };
  };
  const {
    data: resultsData,
  } = useRequestEffect<PowerflowResults>({
    url: `/api/workspace/${workspace}/branch/${resultsBranch}/analysis/${analysis?.id}`,
    method: 'get',
    refetchOnChange: [scenario, analysis, analysis?.id, workspace, resultsBranch],
    disabled: !(scenario && analysis && workspace && resultsBranch),
  });
  const { baselineResults: { baselineViolations }, setBaselineResults } = useContext(ResultsComparisonContext);
  const resultsType = 'baselineViolations';
  const fetchAnalyses = useMemo(() => async (scenarioID: string) => {
    let allAnalyses = [];
    try {
      if (scenarioID && workspace && resultsBranch) {
        allAnalyses = await getAnalyses(
          workspace,
          resultsBranch,
          scenarioID,
          permissions,
        );
        allAnalyses = allAnalyses.filter((analysisSingle: any) => ![
          ANALYSIS_TYPES.HOSTING_CAPACITY, ANALYSIS_TYPES.EV_CAPACITY, ANALYSIS_TYPES.BATTERY_SIZING,
        ].includes(analysisSingle.type));
      }
    } catch (err) {
    }
    setAnalyses(allAnalyses);
  }, [permissions, resultsBranch, workspace]);

  const updateMaxRange = async (scenarioId: string|null, analysisSel: any = null) => {
    let start; let end;
    try {
      if (workspace && resultsBranch && scenarioId && analysisSel && !isEmptyObject(analysisSel) && feeders) {
        const timepoints = await getAnalysisTimepoints(
          workspace,
          resultsBranch,
          scenarioId,
          feeders,
          analysisSel,
        );
        if (timepoints.length > 0) {
          start = moment.utc(timepoints[0]);
          end = moment.utc(timepoints[timepoints.length - 1]);
        }
      } else if (scenarioId) {
        const timeSpan = await determineScenarioTimeSpan(
          workspace, resultsBranch, scenarioId,
        );
        start = timeSpan.start;
        end = timeSpan.end;
      }
    } catch (err) {
      start = null;
      end = null;
    }
    setMaxRangeSelected({ start, end });
  };

  const fetchScenarios = useMemo(() => async () => {
    const url = `/api/workspace/${workspace}/branch/${resultsBranch}/qsts_scenarios`;
    try {
      const results = await new Request(url).get();
      const allScenariosData = results.data.map((sc: any) => (
        { value: sc.id, label: sc.name, type: sc.scenario_type }
      ));
      setScenarios(allScenariosData);
    } catch (err) {
      setScenarios([]);
    }
  }, [resultsBranch, workspace]);

  const getTimepoints = async (start: any, end: any, aggregation: any) => {
    const params = {
      feeder: feeders?.map((x:any) => x.id),
      start_date: start.toISOString(),
      end_date: end.toISOString(),
      aggregation,
    };

    const violationsTimepointsRequest = new Request(
      `/api/workspace/${workspace}/branch/${resultsBranch}/power-flow-results/violations/per-time`,
    );

    const feederScheduleTimepointsRequest = new Request(
      `/api/workspace/${workspace}/branch/${resultsBranch}/qsts_scenarios/${scenario}/timepoints`,
    );

    let violationsTimepoints = [];
    let feederScheduleTimepoints = [];

    if (analysis && [
      ANALYSIS_TYPES.POWERFLOW,
      ANALYSIS_TYPES.QSTS,
      ANALYSIS_TYPES.QSTS_OPF,
    ].includes(analysis?.type)) {
      try {
        const pfResponse = await violationsTimepointsRequest.get({
          params: {
            ...params,
            scenario_id: scenario,
            analysis_name: analysis.name,
          },
        });
        violationsTimepoints = pfResponse.data.map((timepoint: any) => moment.utc(timepoint));
      } catch {
      }
    }

    try {
      const scResponse = await feederScheduleTimepointsRequest.get({ params });
      feederScheduleTimepoints = scResponse.data.map((timepoint: any) => moment.utc(timepoint));
    } catch {
    }
    return {
      violations: violationsTimepoints,
      scenario: feederScheduleTimepoints,
    };
  };

  const deleteModalConfig = () => {
    const modalBody = (
      <p className="modal-message__p">
        You can add another result later, but your current selection will not be retained
      </p>
    );
    let deleteStatus = asyncActionStates.INITIAL;
    return {
      modalActive,
      closeModal: () => setModalActive(false),
      deleteItem: () => {
        const deleteResultCardStatus = removeResultsCard(id) as number;
        deleteStatus = deleteResultCardStatus;
        if (deleteResultCardStatus === asyncActionStates.SUCCESS) {
          addToast(`${title} has been removed!`, { appearance: 'success' });
          setModalActive(false);
        }
      },
      title: `Remove ${title}?`,
      modalBody,
      theme,
      deleteStatus,
    };
  };

  const downloadResultsComparisonReport = async () => {
    const url = `/api/workspace/${workspace}/branch/${resultsBranch}/power-flow-results/results-comparison/report`;
    setReportLoading(true);
    try {
      const request = new Request(url);
      const { data, headers } = await request.getFile({
        params: {
          feeder: feeders.map((fdr:any) => fdr.id),
          scenario_id: scenario,
          start_date: maxRangeSelected?.start?.toISOString(),
          end_date: maxRangeSelected?.end?.toISOString(),
          analysis_name: analysis?.name,
        },
      });
      fileExportSave(data, headers);
      setReportLoading(false);
    } catch (err) {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    fetchScenarios();
  }, [workspace, resultsBranch, fetchScenarios]);

  useEffect(() => {
    if (scenario) fetchAnalyses(scenario);
  }, [fetchAnalyses, scenario]);

  useEffect(() => {
    if (!feeders?.length && actions?.getContainersList && pendingReq && isEmptyObject(pendingReq)) {
      actions.getContainersList();
    }
  }, [actions, feeders, pendingReq]);

  return (
    <div data-test={`results-comparison-${title}`}>
      <ResultsCard
        theme={theme}
        title={isBaseline ? `Primary ${title}` : title}
        className="results-comparison-card title-bold top-section"
        onClose={() => {
          if (isBaseline) {
            addToast(`${title} can not be removed!`, { appearance: 'error' });
          } else {
            setModalActive(true);
          }
        }}
        isDownloadDisable={
          !(workspace && resultsBranch && feeders?.length !== 0 && scenario && analysis && !isEmptyObject(analysis))
        }
        downloadLoading={reportLoading}
        onDownload={() => downloadResultsComparisonReport()}
        expandableContents={(
          <>
            <div className="grid-columns one-one margin-tb">
              <ScenarioSection
                workspace={workspace}
                branch={resultsBranch}
                scenario={scenario}
                scenarios={allScenarios}
                view="results"
                handleScenarioChange={(e: any) => {
                  if (updateSelectedScenario) updateSelectedScenario(e);
                  setScenario(e);
                  updateMaxRange(e);
                  setSelectedAnalysis(null);
                  Analytics.logEvent(`Scenario Selected for ${title}`, 'Scenarios');
                }}
                theme={theme}
                hideButtons
                selectType="secondary"
              />
              <CalendarPicker
                startDate={maxRangeSelected?.start?.toISOString() || moment.utc().startOf('day').toISOString()}
                endDate={maxRangeSelected?.end?.toISOString() || moment.utc().endOf('day').startOf('hour').toISOString()}
                theme={theme}
                onApply={(newStart: string, newEnd: string) => {
                  setMaxRangeSelected({ start: moment.utc(newStart), end: moment.utc(newEnd) });
                  Analytics.logEvent(`Timeframe change for ${title}`, 'Timeframe');
                }}
                onViewChanged={getTimepoints}
                dots={['scenario', 'violations']}
                styleFor="topnav"
                subHourInterval={resultsData && analysis && analysis?.id
                  ? resultsData.analysis_configuration?.interval : 5}
              />
            </div>
            <div className="grid-columns one-one margin-tb">
              <div>
                <p className="select-label space-bottom">Analysis</p>
                <Select
                  id="results-analysis-section"
                  theme={theme}
                  options={analysesList.map((opt: any) => {
                    let isAnalysisActive = false;
                    if (typeof actions?.analysisActive === 'function') {
                      isAnalysisActive = actions.analysisActive(resultsBranch, scenario, opt.name);
                    }
                    return getBadgedAnalysisOption(opt, isAnalysisActive);
                  })}
                  value={analysis?.id}
                  onChange={(e: any) => {
                    setSelectedAnalysis(e?.analysis);
                    updateMaxRange(scenario, e?.analysis);
                    Analytics.logEvent(`Analysis change for ${title}`, 'Analysis');
                  }}
                  type="secondary"
                />
              </div>
              <div>
                <p className="select-label space-bottom">Over limit (%)</p>
                <NumberInput
                  onChange={(e: any) => {
                    if (isBaseline) {
                      setBaselineResults(resultsType, {
                        ...baselineViolations,
                        violationLimit: e.value,
                      });
                      Analytics.logEvent(`Violation change for ${title}`, 'Violation Limit');
                    }
                  }}
                  id="limit"
                  value={baselineViolations?.violationLimit ?? 0}
                  theme={theme}
                  disabled={!isBaseline}
                />
                { tooManyViolationsError && (
                  <div key="error" className="error-label">
                    <p className="error caption-text">
                      There are too many violations to load. Try reducing the timeframe of the selected analysis.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      >
        <BranchSection
          workspace={workspace}
          branch={resultsBranch}
          branches={branches}
          theme={theme}
          permissions={new Set([])}
          view="results"
          updateSelectedBranch={(e: any) => {
            setBranch(e);
            setScenario('');
            setAnalyses([]);
            setSelectedAnalysis({});
            setMaxRangeSelected(maxRange || {});
            Analytics.logEvent(`Network Version updated for ${title}`, 'Network Version');
          }}
          selectType="primary"
        />
      </ResultsCard>
      <DeleteModal {...deleteModalConfig()} />
      <ResultsSummary
        key={`results-summary-${title}`}
        theme={theme}
        violationLimit={baselineViolations?.violationLimit ?? 0}
        workspace={workspace}
        branch={resultsBranch}
        scenario={scenario}
        analysis={analysis}
        maxRange={maxRangeSelected}
        feeders={feeders}
        setViolationType={(value: string) => {
          if (isBaseline) {
            setBaselineResults(resultsType, {
              ...baselineViolations,
              violationType: value,
            });
            Analytics.logEvent(`Violation change for ${title}`, 'Violation Type');
          }
        }}
        violationType={baselineViolations?.violationType ?? 'All'}
        isBaseline={isBaseline}
        violationsError={tooManyViolationsError}
        setTooManyViolationsError={setTooManyViolationsError}
      />
    </div>
  );
};

export default ResultsComparisonCard;
