import React, { Component } from 'react';
import qs from 'qs';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';
import Request from 'helpers/Request';
import {
  getGAUserId, getLoginUri, getLoginData, getToken,
} from 'helpers/Auth';
import browserHistory from 'routes/history';

import './Login.scss';

class Login extends Component {
  state = {
    authenticated: false,
  };

  UNSAFE_componentWillMount() {
    if (this.props.location) {
      // React-router stores search with a ? in the first character.
      // QS does not support parsing this, so strip out that otherwise
      // we get "?code" as the key in the query object
      const queryParams = qs.parse(this.props.location.search.substr(1));
      if (queryParams.code) {
        // Remove any old login redirect now that we have logged in
        localStorage.removeItem('loginRedirect');
        this.setState({ authenticated: true });
        this.handleCallback();
      }
    }

    // if the loginRedirect token exists, redirect them to the workspace login page.
    // If we are already on the correct page, then we don't need to do anything
    const workspaceToLogInTo = localStorage.getItem('loginRedirect');
    const newLoginPage = `/${workspaceToLogInTo}/login`;

    if (workspaceToLogInTo && workspaceToLogInTo !== '/' && this.props.location.pathname !== newLoginPage) {
      window.location.assign(newLoginPage);
    }
  }

  componentDidMount() {
    this.props.actions.getAuthProvider();
  }

  handleClick = async () => {
    try {
      const response = await getLoginData(this.props.workspaceForTenant);
      const uri = getLoginUri(response, this.props.workspaceForTenant);
      window.location.assign(uri);
    } catch (error) {
    }
  };

  getRedirectPath = () => {
    const prevLocation = localStorage.getItem('postLoginRedirect');
    const workspace = localStorage.getItem('authWorkspace');

    let redirect = '/';

    if (prevLocation) {
      // The user was on a location and got kicked out.
      // Return them to that location now and remove the token so that this only happens once
      redirect = prevLocation;
      localStorage.removeItem('postLoginRedirect');
    } else if (workspace) {
      // The user is logging into a specific workspace. Make them go there
      redirect = `/${workspace}/master/gis`;
    }

    return redirect;
  }

  handleCallback = async () => {
    try {
      // React-router stores search with a ? in the first character.
      // QS does not support parsing this, so strip out that otherwise
      // we get "?code" as the key in the query object
      const query = qs.parse(this.props.location.search.substr(1));
      const {
        data: {
          user_email,
          user_id,
          authorization_endpoint,
          encrypted_bearer_token,
        },
      } = await getToken(query);

      localStorage.setItem('username', user_email);
      localStorage.setItem('userId', user_id);
      localStorage.setItem('encBearerToken', encrypted_bearer_token);
      localStorage.setItem('authEndpoint', authorization_endpoint);

      if (this.props.workspaceForTenant) {
        localStorage.setItem('authWorkspace', this.props.workspaceForTenant);
      }

      if (localStorage.getItem('analyticsID')) {
        ReactGA.set({ userId: getGAUserId(user_email, user_id) });
      }

      const request = new Request('/api/permissions');
      const { data: permissions } = await request.get();
      this.props.actions.updatePermissions(permissions);

      browserHistory.push(this.getRedirectPath());
    } catch (error) {
    }
  }

  getLoginText() {
    if (this.props.authProvider === 'auth0') {
      return 'Login via Auth0';
    } if (this.props.authProvider === 'keycloak') {
      return 'Login via Keycloak';
    }
    return 'Login';
  }

  render() {
    const workspaceName = this.props.workspaceForTenant ? this.props.workspaceForTenant : '';

    return (
      <div className="login-container">
        {!this.state.authenticated
        && (
        <Card className="center-modal" hideTitle theme={this.props.theme}>
          {this.props.authWorkspaceMismatch
            && (
            <h3>
              <div className="access-denied-message">
                You are not authorized to access this page, please log in with appropriate credentials.
              </div>
            </h3>
            )}
          <div className="logo-container">
            <img
              src={this.props.theme === 'dark' ? '/gridOS-white-red.png' : '/gridos_logo_light.png'}
              alt="gridos-logo"
              className="gridos-logo"
            />
          </div>
          <div className="contents-container">
            {workspaceName
              && (
              <>
                <p>Your workspace</p>
                <p className="workspace-text">{workspaceName}</p>
              </>
              )}
            <Button
              className="login-button"
              onClick={this.handleClick}
              theme={this.props.theme}
            >
              {this.getLoginText()}
            </Button>
          </div>
        </Card>
        )}
        {this.state.authenticated
        && (
        <div className="contents-container">
          <LoadingSpinner className="spinner" />
        </div>
        )}
      </div>
    );
  }
}

Login.defaultProps = {
  authProvider: '',
  authWorkspaceMismatch: false,
  workspaceForTenant: '',
};

Login.propTypes = {
  workspaceForTenant: PropTypes.string,
  authProvider: PropTypes.string,
  authWorkspaceMismatch: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    getAuthProvider: PropTypes.func,
    updatePermissions: PropTypes.func,
  }).isRequired,
  theme: PropTypes.string.isRequired,
};

export default Login;
