import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import classNames from 'classnames';
import Button from 'components/Button';
import CustomScrollBar from 'components/CustomScrollBar';

import VoltageLimits from 'components/VoltageLimits';

import AssetSchedule from '../AssetPanel/templates/partials/AssetSchedule';
import AnalysisResultsSection from '../FeederPanel/components/AnalysisResultsSection';
import { ANALYSIS_TYPES } from '../../../helpers/NetworkHelpers';

import '../FeederPanel/FeederPanel.scss';

const SubstationPanel = ({
  theme,
  anyResults,
  selectedAnalysis,
  selectedScenario,
  selectedScenarioType,
  analysisActive,
  downloadingPFReport,
  downloadingHCReport,
  downloadHostingCapacityReport,
  downloadPowerFlowReport,
  getResultsTooltip,
  workspace,
  branch,
  authEnabled,
  selectedContainers,
  inEditMode,
  permissions,
  expanded,
  isValidHCReportTimeRange,
  fetchNodeSubstationDistances,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  containerInAnalysis,
  updateSelectedScenario,
}) => {
  const substation = selectedContainers[0];
  const updateScenario = useCallback(
    () => updateSelectedScenario(workspace, branch, selectedScenario, selectedScenarioType),
    [workspace, branch, selectedScenario, selectedScenarioType, updateSelectedScenario],
  );

  useEffect(() => {
    fetchNodeSubstationDistances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainers]);

  let analysisRunning = false;
  if (selectedAnalysis) {
    analysisRunning = analysisActive(branch, selectedScenario, selectedAnalysis.name);
  }

  return (
    <CustomScrollBar>
      <div
        className={classNames({
          'container-panel-tab-contents': true,
          'container-panel-tab-contents--expanded': expanded,
        })}
      >
        <VoltageLimits substationId={substation.id} />
        {!inEditMode && (
          <>
            <h3>Report</h3>
            <div className="powerflow-btn-row">
              <p>Powerflow</p>
              <Button
                onClick={downloadPowerFlowReport}
                disabled={
                  !selectedAnalysis
                  || !anyResults
                  || analysisRunning
                  || downloadingPFReport
                  || !containerInAnalysis
                  || ![
                    ANALYSIS_TYPES.POWERFLOW,
                    ANALYSIS_TYPES.QSTS,
                    ANALYSIS_TYPES.QSTS_OPF,
                  ].includes(selectedAnalysis.type)
                }
                loading={downloadingPFReport}
                iconPath="/download.svg"
                label="Download"
                className="report-download-btn"
                title={getResultsTooltip(anyResults, analysisRunning, 'Powerflow')}
                theme={theme}
              />
            </div>
            <div className="hosting-capacity-btn-row">
              <p>Hosting Capacity</p>
              <Button
                onClick={downloadHostingCapacityReport}
                disabled={
                  !selectedAnalysis
                  || !anyResults
                  || analysisRunning
                  || downloadingHCReport
                  || !containerInAnalysis
                  || !isValidHCReportTimeRange()
                  || selectedAnalysis.type !== ANALYSIS_TYPES.HOSTING_CAPACITY
                }
                iconPath="/download.svg"
                label="Download"
                loading={downloadingHCReport}
                className="report-download-btn"
                title={getResultsTooltip(
                  anyResults,
                  analysisRunning,
                  'Hosting capacity',
                  isValidHCReportTimeRange(),
                )}
                theme={theme}
              />
            </div>

            <AssetSchedule
              workspace={workspace}
              branch={branch}
              scenario={selectedScenario}
              scenarioType={selectedScenarioType}
              header="Substation Schedule"
              asset={{ id: substation.id, class: 'feeder' }}
              scheduleType="Feeder"
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              editable={!authEnabled || permissions.has('modify_asset_schedule')}
              expanded={expanded}
              onScheduleUploadSuccess={updateScenario}
            />

            <AnalysisResultsSection />
          </>
        )}
      </div>
    </CustomScrollBar>
  );
};

SubstationPanel.propTypes = {
  anyResults: PropTypes.bool.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedScenarioType: PropTypes.string.isRequired,
  selectedAnalysis: nullable(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.array.isRequired,
  branch: PropTypes.string.isRequired,
  analysisActive: PropTypes.func.isRequired,
  downloadingHCReport: PropTypes.bool.isRequired,
  downloadHostingCapacityReport: PropTypes.func.isRequired,
  downloadPowerFlowReport: PropTypes.func.isRequired,
  downloadingPFReport: PropTypes.bool.isRequired,
  getResultsTooltip: PropTypes.func.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  isValidHCReportTimeRange: PropTypes.func.isRequired,
  fetchNodeSubstationDistances: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  containerInAnalysis: PropTypes.bool.isRequired,
  updateSelectedScenario: PropTypes.func.isRequired,
};

export default SubstationPanel;
