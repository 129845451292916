import React from 'react';
import { kWDC, degrees } from 'helpers/units';
import { filterObject } from 'helpers/utils';
import PowerElectronicsUnit from './PowerElectronicsUnit';

const editableValues = [
  {
    id: 'minP', label: 'Max Generation', unit: kWDC, divisor: -1000, type: 'number', min: 0,
  },
  {
    id: 'azimuth', label: 'Azimuth', unit: degrees, divisor: 1, type: 'number', min: 0,
  },
  {
    id: 'tilt', label: 'Tilt', unit: degrees, divisor: 1, type: 'number', min: 0,
  },
];

const PVUnit = props => (
  <PowerElectronicsUnit
    {...props}
    type="pv_unit"
    fields={editableValues}
    name="PV Unit"
  />
);

PVUnit.defaultProps = filterObject(PowerElectronicsUnit.defaultProps, ['type', 'fields', 'name']);
PVUnit.propTypes = filterObject(PowerElectronicsUnit.propTypes, ['type', 'fields', 'name']);

export default PVUnit;
