import React, {
  useEffect, useState, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import Select from 'components/Select';
import StatusBar from 'components/StatusBar';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import Request from 'helpers/Request';
import ThemeContext from 'helpers/ThemeContext';
import { useRequestEffect } from 'hooks/useRequest';

import getBadgedAnalysisOption from '../routes/Network/components/getBadgedAnalysisOption';
import './DropdownSections.scss';
import AnalysisModal from '../routes/Network/containers/AnalysisModalContainer';
import { getAnalyses, ACTIVITY_LOG_STATUS } from '../routes/Network/helpers/NetworkHelpers';

const modalModes = Object.freeze({
  hidden: 'hidden',
  delete: 'delete',
  create: 'create',
  edit: 'edit',
  complete: 'complete',
  confirm: 'confirm',
});

const AnalysisSection = ({
  workspace,
  branch,
  selectedScenario,
  selectedAnalysis,
  actions: {
    setSelectedAnalysis,
    analysisActive,
    setActivePanel,
  },
  auth: {
    permissions,
  },
  logEntries,
}) => {
  const [analyses, setAnalyses] = useState([]);
  const [modalMode, setModalMode] = useState(false);
  const [upgradeInprogress, setUpgradeInProgress] = useState(false);
  const theme = useContext(ThemeContext);
  const { data: migrationNeeded } = useRequestEffect({
    url: `/api/workspace/${workspace}/branch/${branch}/asset_schedule/migration_needed`,
    method: 'get',
    params: {
      scenario_id: selectedScenario,
    },
    refetchOnChange: [workspace, branch, selectedScenario, upgradeInprogress],
  });
  // seems to be a strange bug with useCallback here that causes issues for the
  // tests so using useMemo instead
  const fetchAnalyses = useMemo(() => async (cancellationToken) => {
    const analysesList = await getAnalyses(
      workspace,
      branch,
      selectedScenario,
      permissions,
      cancellationToken,
    );
    setAnalyses(analysesList);
  }, [workspace, branch, selectedScenario, permissions]);

  async function deleteAnalysis() {
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/analysis/${selectedAnalysis.id}`);
    try {
      await request.delete();
      setSelectedAnalysis(null);
      setModalMode(modalModes.hidden);
    } catch {
      // TODO: handle delete errors
    }
  }
  const upgradeAssetSchedule = async () => {
    setUpgradeInProgress(true);
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/asset_schedule/upgrade`);
    try {
      await request.post({
        scenario_id: selectedScenario,
      });
    } catch (error) {
      setUpgradeInProgress(false);
    }
  };

  useEffect(() => {
    const cancellationToken = { cancelled: false };
    fetchAnalyses(cancellationToken);
    return () => { cancellationToken.cancelled = true; };
  }, [selectedScenario, selectedAnalysis, fetchAnalyses]);

  useEffect(() => {
    // if the workspace, branch or scenario change clear the selected analysis
    setSelectedAnalysis(null);
    setUpgradeInProgress(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, selectedScenario]);

  useEffect(() => {
    const preAggEntry = logEntries.find(x => x.branch === branch
      && x.scenario_id === selectedScenario
      && x.activity_type === 'ASSET_SCHEDULE_PREAGG_MIGRATION');
    if (!upgradeInprogress && preAggEntry?.status === ACTIVITY_LOG_STATUS.RUNNING) {
      setUpgradeInProgress(true);
    } else if (((modalMode === modalModes.confirm || upgradeInprogress)
      && preAggEntry?.status === ACTIVITY_LOG_STATUS.COMPLETED)) {
      setModalMode(modalModes.create);
      setUpgradeInProgress(false);
    }
  }, [branch, selectedScenario, logEntries, upgradeInprogress, modalMode]);
  const makeActionButtons = () => {
    const allowedToCreateAnalysis = (
      permissions.has('run_powerflow')
      || permissions.has('run_powerflow_qsts')
      || permissions.has('run_powerflow_qsts_day')
      || permissions.has('run_powerflow_qsts_month')
      || permissions.has('run_hosting_capacity')
      || permissions.has('run_hosting_capacity_month')
      || permissions.has('run_network_hosting_capacity')
      || permissions.has('run_network_hosting_capacity_month')
    );
    let createAnalysisTooltip = 'Create Analysis';
    if (!allowedToCreateAnalysis) {
      createAnalysisTooltip = ''; // permission disabled tooltip shows
    } else if (!selectedScenario) {
      createAnalysisTooltip = 'Select a scenario to create an analysis';
    } else if (upgradeInprogress) {
      createAnalysisTooltip = 'Schedule Upgrade in progress';
    }

    // TODO: Editable Analysis
    const allowedToEditAnalysis = false;
    const editAnalysisTooltip = 'Editing Analyses is not currently supported';

    const selectedAnalysisActive = analysisActive(branch, selectedScenario, selectedAnalysis?.name);

    const allowedToDeleteAnalysis = (
      permissions.has('delete_analysis')
    );
    let deleteAnalysisTooltip = 'Delete Analysis';
    if (!allowedToDeleteAnalysis) {
      deleteAnalysisTooltip = ''; // permission disabled tooltip shows
    } else if (!selectedScenario || !selectedAnalysis || selectedAnalysisActive) {
      deleteAnalysisTooltip = 'Select a finished analysis to delete it';
    }

    return (
      <div className="buttons">
        <PermissionDisabledTip
          key="Create Analysis"
          title="Create Analysis"
          hide={allowedToCreateAnalysis}
          theme={theme}
          message="You do not have permisison to create an analysis"
        >
          <IconButton
            id="addAnalysis"
            icon={upgradeInprogress ? null : 'add'}
            key="Create Analysis"
            tooltip={createAnalysisTooltip}
            className="add-btn"
            onClick={() => {
              migrationNeeded ? setModalMode(modalModes.confirm) : setModalMode(modalModes.create);
            }}
            theme={theme}
            disabled={!selectedScenario || !allowedToCreateAnalysis || upgradeInprogress}
            loading={upgradeInprogress}
          />
        </PermissionDisabledTip>
        <PermissionDisabledTip
          key="Edit Analysis"
          title="Edit Analysis"
          // TODO: Editable Analysis
          hide
          theme={theme}
          message="You do not have permisison to edit an analysis"
        >
          <IconButton
            id="editAnalysis"
            icon="edit"
            key="Edit Analysis"
            tooltip={editAnalysisTooltip}
            className="top-row-buttons"
            onClick={() => setModalMode(modalModes.edit)}
            theme={theme}
            disabled={
              !allowedToEditAnalysis
              || !selectedAnalysis
              || selectedAnalysisActive
            }
          />
        </PermissionDisabledTip>
        <PermissionDisabledTip
          key="Delete Analysis"
          title="Delete Analysis"
          hide={allowedToDeleteAnalysis}
          theme={theme}
          message="You do not have permisison to delete an analysis"
        >
          <IconButton
            id="deleteAnalysis"
            icon="delete"
            key="Delete"
            tooltip={deleteAnalysisTooltip}
            className="top-row-buttons"
            onClick={() => setModalMode(modalModes.delete)}
            theme={theme}
            disabled={
              !allowedToDeleteAnalysis
              || !selectedScenario
              || !selectedAnalysis
              || selectedAnalysisActive
            }
          />
        </PermissionDisabledTip>
      </div>
    );
  };
  const usedAnalysisNames = analyses.map(x => x.name);

  return (
    <div className="analysis-scenario-section analysis-section" id="analysis-selector-container">
      <div className="select-container">
        <div className="select-top-row">
          <p className="select-label">5. Analysis</p>
          {makeActionButtons()}
        </div>
        <Select
          theme={theme}
          options={analyses.map(opt => {
            const isAnalysisActive = analysisActive(branch, selectedScenario, opt.name);
            return getBadgedAnalysisOption(opt, isAnalysisActive);
          })}
          value={selectedAnalysis?.id}
          onChange={e => {
            const analysis = e ? e.analysis : null;
            setSelectedAnalysis(analysis);
          }}
          clearable
          id="analysis-selector"
          disabled={!selectedScenario}
          tooltip={!selectedScenario ? 'Select a scenario to view an analysis' : ''}
        />
      </div>

      {modalMode === modalModes.delete && (
        <Modal
          title="Delete Analysis"
          active
          width="300px"
          onCancel={() => setModalMode(modalModes.hidden)}
          onConfirm={deleteAnalysis}
          labels={{
            confirm: 'Confirm',
          }}
          reverseFooterButtons
          theme={theme}
        >
          <p className="modal-message__p">
            Once an analysis has been deleted, it cannot be restored.
          </p>
          <p className="modal-message__p">
            Would you like to permanently remove this analysis?
          </p>
        </Modal>
      )}
      {modalMode === modalModes.edit && (
        <AnalysisModal
          modalActive
          hasResults
          toggleModal={() => setModalMode(modalModes.hidden)}
          usedAnalysisNames={usedAnalysisNames}
          analyses={analyses}
        />
      )}
      {modalMode === modalModes.create && (
        <AnalysisModal
          modalActive
          hasResults={false}
          toggleModal={(isModalActive = true, isAnalysisComplete = false) => {
            setModalMode(isAnalysisComplete && !isModalActive
              ? modalModes.complete : modalModes.hidden);
          }}
          usedAnalysisNames={usedAnalysisNames}
          analyses={analyses}
        />
      )}
      {modalMode === modalModes.complete && (
        <Modal
          title="Battery Analysis Execution"
          active
          width="300px"
          onCancel={() => setModalMode(modalModes.hidden)}
          onConfirm={() => { setActivePanel('activityLog'); setModalMode(modalModes.hidden); }}
          labels={{
            confirm: 'Show Activity Log',
          }}
          theme={theme}
        >
          <p className="modal-message__p">
            Check Activity Log to view status of Battery Analysis
          </p>
        </Modal>
      )}
      {modalMode === modalModes.confirm && (
        <Modal
          title="Upgrade scheduled data"
          active
          width="400px"
          onCancel={() => setModalMode(modalModes.hidden)}
          onConfirm={() => {
            setActivePanel('activityLog');
            upgradeAssetSchedule();
          }}
          labels={{
            confirm: upgradeInprogress ? <i className="material-icons rotate">refresh</i> : 'yes',
            cancel: 'Discard',
          }}
          theme={theme}
          disableConfirm={upgradeInprogress}
          className="upgrade-modal"
        >
          <div className="modal-message__p">
            { upgradeInprogress ? (
              <>
                <span>Update in progress. This will take couple of minutes.</span>
                <span>You can check the status in activity log and come back later to continue with your analysis</span>
                <StatusBar
                  barColor="#06AFA8"
                  progress={100}
                  processing
                  cornerType="round"
                />
              </>
            )
              : (
                <span>
                  Your scenario data is outdated.  In order to continue creating a new analysis,
                  it needs to be updated. Would you like to proceed?
                </span>
              )}
          </div>
        </Modal>
      )}
    </div>
  );
};

AnalysisSection.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedAnalysis: nullable(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  auth: PropTypes.shape({
    permissions: PropTypes.object.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    setSelectedAnalysis: PropTypes.func.isRequired,
    analysisActive: PropTypes.func.isRequired,
    setActivePanel: PropTypes.func.isRequired,
  }).isRequired,
  logEntries: PropTypes.array.isRequired,
};

export default AnalysisSection;
