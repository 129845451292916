import React from 'react';
import Switch from './Switch';

// Create a value template for a Cut.
// As there are no value difference between Cut and Switch, we use the Swtich template
const Cut = props => (
  <Switch {...props} />
);

export default Cut;
