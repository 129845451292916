import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import { ReactSVG } from 'react-svg';

import './NetworkNavLinks.scss';

const NAV_BUTTONS = [
  {
    name: 'validation',
    activeFor: ['validation'],
    tooltip: 'Network Validation',
    className: 'validation-tab',
    children: (
      <ReactSVG
        className="validation"
        src="/network-error-24px.svg"
      />
    ),
  },
  {
    name: 'violations',
    activeFor: ['violations'],
    tooltip: 'Network Violations',
    icon: 'warning',
    className: 'warning-tab',
  },
  {
    name: 'layers',
    activeFor: ['layers'],
    tooltip: 'Visual Layers',
    icon: 'layers',
    className: 'layer-tab',
  },
  {
    name: 'asset',
    activeFor: ['asset', 'feeder'],
    tooltip: 'View Details',
    icon: 'info',
    className: 'information-tab',
  },
  {
    name: 'activityLog',
    activeFor: ['activityLog'],
    tooltip: 'Activity Log',
    icon: 'assignment',
    className: 'activity-log',
    notificationKey: 'newActivity',
  },
];

// eslint-disable-next-line react/prop-types
const AnalysisMessage = ({ theme, closeMessage }) => (
  <div className={`running-tooltip ${theme}`}>
    <IconButton
      className="close"
      icon="close"
      onClick={closeMessage}
      theme={theme}
    />
    <p className="running-message">
      Analysis is pending. Check activity log for progress.
    </p>
  </div>
);

export default function NetworkNavLinks(props) {
  const {
    active, open, showTooltip, handleTabClick, theme,
  } = props;
  const [hideMessage, setMessageHidden] = useState(false);

  return (
    <div className="asset-panel-icons" id="asset-panel-icons">
      {NAV_BUTTONS.map(button => (
        <IconButton
          key={button.name}
          tooltip={button.tooltip}
          icon={button.icon ? button.icon : null}
          hasNotification={props[button.notificationKey]}
          className={`sliding-panel-tab ${button.className}`}
          active={button.activeFor.includes(active) && open}
          onClick={() => handleTabClick(button.name)}
          theme={theme}
        >
          {button.children ? button.children : null}
        </IconButton>
      ))}
      {showTooltip && !hideMessage && (
        <AnalysisMessage
          theme={theme}
          closeMessage={() => setMessageHidden(true)}
        />
      )}
    </div>
  );
}

NetworkNavLinks.propTypes = {
  active: PropTypes.string,
  open: PropTypes.bool,
  showTooltip: PropTypes.bool,
  handleTabClick: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};
NetworkNavLinks.defaultProps = {
  active: 'asset',
  open: false,
  showTooltip: false,
};
