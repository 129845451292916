import { connect } from 'react-redux';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ServiceDashboard from '../components/ServiceDashboard';

const mapStateToProps = state => ({
  permissions: state.global.permissions,
  theme: state.global.theme,
});

export default connect(mapStateToProps)(ServiceDashboard);
