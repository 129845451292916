import React from 'react';
import PropTypes from 'prop-types';

import { validationSchema } from 'helpers/JSCIM/Shunt/EnergySource';
import { kVll, pu } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import { isDefined } from 'helpers/utils';

import HelpTooltip from 'components/HelpTooltip';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import AssetSingleSelect from '../../Inputs/AssetSingleSelect';

const backfeedingTooltip = (
  'During an optimizing analysis, results will be constrained to prevent backfeeding though the '
  + 'energy source. If all possible solutions would result in backfeeding, the analysis will fail. '
  + 'Analyses which do not perform any optimization will ignore this setting.'
);
const InstanceInfo = ({
  asset: {
    attributes,
    base_voltage: baseVoltage,
    allow_backfeeding,
  },
  theme,
  disabled,
  inEditMode,
  handleSave,
}) => {
  const [
    nominalVoltage, setNominalVoltage, nominalVoltageEdited,
  ] = useTrackingState(attributes?.nominalVoltage);
  const [
    voltageMagnitude, setVoltageMagnitude, voltageMagnitudeEdited,
  ] = useTrackingState(attributes?.voltageMagnitude);
  const [
    allowBackfeeding, setAllowBackfeeding, allowBackfeedingEdited,
  ] = useTrackingState(allow_backfeeding);

  const validation = validationSchema.nominalVoltage.customValidation(
    nominalVoltage, baseVoltage,
  );

  return (
    <div>
      <div className="editable-section">
        <div className="input-spacing">
          <SingleEditableValue
            theme={theme}
            disabled={disabled || !inEditMode}
            unit={kVll}
            min={1}
            type="number"
            id="nominalVoltage"
            label="Nominal Voltage"
            divisor={1000}
            onBlur={() => handleSave({ attributes: { nominalVoltage } })}
            onChange={setNominalVoltage}
            value={nominalVoltage}
            edited={nominalVoltageEdited}
          />
        </div>
        <div className="input-spacing">
          <SingleEditableValue
            theme={theme}
            disabled
            unit={kVll}
            id="baseVoltage"
            label="Base Voltage"
            type="number"
            min={1}
            divisor={1000}
            invalid={!validation.equalBase}
            validationMessage={validation.message}
            showValidationMessage="always"
            value={baseVoltage}
          />
        </div>
        <div className="input-spacing">
          <SingleEditableValue
            theme={theme}
            disabled={disabled || !inEditMode || !isDefined(baseVoltage)}
            unit={pu}
            id="voltageMagnitude"
            label="Operating Voltage"
            type="number"
            min={0}
            divisor={baseVoltage}
            value={baseVoltage ? voltageMagnitude : null}
            onBlur={() => handleSave({ attributes: { voltageMagnitude } })}
            onChange={setVoltageMagnitude}
            edited={voltageMagnitudeEdited}
            invalid={!isDefined(baseVoltage)}
            validationMessage="Cannot display operating voltage without a base voltage"
            showValidationMessage="always"
          />
        </div>
        <AssetSingleSelect
          id="energySourceBackfeeding"
          label={(
            <div className="label-help">
              Limit OPF Backfeeding
              <HelpTooltip className="" message={backfeedingTooltip} />
            </div>
          )}
          value={allowBackfeeding ?? false}
          options={[
            { label: 'Disabled', value: true },
            { label: 'Enabled', value: false },
          ]}
          onChange={(value) => {
            setAllowBackfeeding(value);
            handleSave({ allow_backfeeding: value });
          }}
          edited={allowBackfeedingEdited}
          disabled={disabled || !inEditMode}
        />
      </div>
    </div>
  );
};

InstanceInfo.defaultProps = {
  disabled: true,
  theme: 'dark',
};

InstanceInfo.propTypes = {
  disabled: PropTypes.bool,
  inEditMode: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

export default InstanceInfo;
