import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/Button';
import asyncStates from 'helpers/asyncActionStates';

import JSCIM from 'helpers/JSCIM';
import ThemeContext from 'helpers/ThemeContext';
import { kVAr, kW } from 'helpers/units';
import { alphabetizeByKey } from 'helpers/utils';

import LifecycleSection from './partials/LifecycleSection';
import InverterConstraintDiagram from './partials/InverterConstraintDiagram';
import InverterValues from './partials/InverterValues';
import AssetSchedule from './partials/AssetSchedule';
import ShuntDeviceHeader from './partials/ShuntDeviceHeader';
import EditableControlMode from './partials/EditableControlMode';
import BatteryControlMode from './partials/BatteryControlMode';
import BatteryUnit from './partials/BatteryUnit';
import CustomerProgramSelector from './partials/CustomerProgramSelector';

import './Battery.scss';

// Active, Reactive main panel values
const inverterAttributes = [{
  id: 'maxP',
  label: 'Max Rate of Charge (Real)',
  unit: kW,
  divisor: 1000,
  type: 'number',
  precision: 1,
}, {
  id: 'minP',
  label: 'Max Rate of Discharge (Real)',
  unit: kW,
  divisor: -1000,
  type: 'number',
  precision: 1,
},
{
  id: 'maxQ', label: 'Max Rate of Charge (Reactive)', unit: kVAr, divisor: 1000, type: 'number', precision: 1,
},
{
  id: 'minQ', label: 'Max Rate of Discharge (Reactive)', unit: kVAr, divisor: -1000, type: 'number', precision: 1,
},
];

const controlHelp = {
  globallyOptimized: 'During powerflow and timeseries powerflow, batteries set to \'global\' will operate in \'fixed\' mode.',
  uncontrolled: 'Batteries set to \'fixed\' mode don\'t participate in timeseries powerflow; use \'scheduled\' mode instead.',
};
const controlTypes = [
  { value: 'uncontrolled', label: 'Fixed' },
  { value: 'locallyControlled', disabled: true, label: 'Local' },
  { value: 'globallyOptimized', label: 'Global' },
  { value: 'scheduled', label: 'Schedule' },
];

const controlPanelValues = {
  uncontrolled: [
    {
      id: 'p', label: 'Active Power', unit: kW, divisor: 1000, type: 'number',
    },
    {
      id: 'q', label: 'Reactive Power', unit: kVAr, divisor: 1000, type: 'number',
    },
  ],
};

const Battery = ({
  asset: inverterBat,
  workspace,
  branch,
  displayBranch,
  loadForecast: { selectedScenario, selectedScenarioType },
  timeRange,
  maxRange,
  timeBarZoomLevel,
  selected: { id, class: assetClass },
  toggleFeederPanel,
  inEditMode,
  canEditNetwork,
  setSelectedAssetID,
  editActions,
  expanded,
  authEnabled,
  permissions,
  applyDifferenceModelRequest,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'inverter', id, body);
  const handleUnitSave = (unitID, body) => editActions.editSingleEquipment(workspace, branch, 'bess_unit', unitID, body);

  const controlMode = inverterBat.analysis_control?.mode;
  const controlModeAttributes = { ...inverterBat.attributes };
  return (
    <>
      <div
        className={classNames({
          'asset-panel-values': true,
          'asset-panel-values--expanded': expanded,
        })}
      >
        <ShuntDeviceHeader
          asset={inverterBat}
          assetId={id}
          toggleFeederPanel={toggleFeederPanel}
          inEditMode={inEditMode}
          disabled={!canEditNetwork || saving}
          setSelectedAssetID={setSelectedAssetID}
          handleSave={handleSave}
        />

        <hr className="section-divider" />

        <InverterValues
          editableValues={inverterAttributes}
          validationSchema={JSCIM.Battery.validationSchema}
          handleSave={handleSave}
          disabled={saving || !inEditMode}
          editable={canEditNetwork && inEditMode}
          inverter={{ id, class: assetClass }}
          inverterAttributes={inverterBat.attributes}
          inverterInfo={inverterBat.inverter_info}
          eqLibLink={`/${workspace}/${displayBranch}/library/inverters`}
          infos={inverterBat.inverter_infos}
          container={inverterBat.container}
        />
        <InverterConstraintDiagram
          inverterMaxP={inverterBat.attributes?.maxP}
          inverterMinP={inverterBat.attributes?.minP}
          battProperties={inverterBat.power_electronics_units}
          inverterMaxQ={inverterBat.attributes?.maxQ}
          inverterMinQ={inverterBat.attributes?.minQ}
          ratedS={inverterBat.attributes?.ratedS}
        />

        <hr className="section-divider" />

        <section id="unit-section">
          <div className="unit-section-header">
            <h2 className="title-text">
              Battery Units (
              {inverterBat.power_electronics_units?.length}
              )
            </h2>
            {canEditNetwork && inEditMode && (
              <Button
                label="Add"
                id="add-battery-unit"
                onClick={() => editActions.addNewInstance('bess_unit', id)}
              />
            )}
          </div>
          {alphabetizeByKey(inverterBat.power_electronics_units, 'name').map((unit, idx) => (
            <BatteryUnit
              key={unit.id}
              infos={inverterBat.power_electronics_unit_infos}
              eqLibLink={`/${workspace}/${displayBranch}/library/batteries`}
              unit={unit}
              position={idx + 1}
              editable={canEditNetwork && inEditMode}
              disabled={!canEditNetwork || !inEditMode || saving}
              validationSchema={JSCIM.Battery.validationSchema}
              canDelete={inverterBat.power_electronics_units?.length > 1}
              handleDelete={editActions.deleteReference}
              handleSave={body => handleUnitSave(unit.id, body)}
              container={inverterBat.container}
            />
          ))}
        </section>

        <hr className="section-divider" />
        {!inEditMode && permissions.has('get_der_program_mapping') && (
          <section id="customer-program-section">
            <h2 className="title-text">Customer Program</h2>
            <CustomerProgramSelector
              workspace={workspace}
              branch={branch}
              scenarioId={selectedScenario}
              assetId={id}
              customerPrograms={inverterBat.customer_agreements ?? []}
              disabled={!selectedScenario || !permissions.has('alter_der_program_mapping')}
            />
          </section>
        )}

        <hr className="section-divider" />
        <EditableControlMode
          controlMode={controlMode}
          help={controlHelp[controlMode]}
          asset={assetClass}
          editableValues={controlPanelValues[controlMode]}
          controlModeOptions={controlTypes}
          attributes={controlModeAttributes}
          disabled={saving || !canEditNetwork}
          onSave={handleSave}
          theme={theme}
        >
          {['scheduled', 'globallyOptimized'].includes(controlMode) && !inEditMode && (
            <AssetSchedule
              workspace={workspace}
              branch={branch}
              scenario={selectedScenario}
              scenarioType={selectedScenarioType}
              asset={{ id, class: assetClass }}
              header={controlMode === 'globallyOptimized' ? 'SoC Schedule' : 'PQ Schedule'}
              scheduleType={controlMode === 'globallyOptimized' ? 'SoC' : 'Normal'}
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              panelValues={{
                ...inverterBat.attributes,
                ...inverterBat.power_electronics_units?.reduce((prev, pv) => ({
                  sumMinP: prev.sumMinP + (pv.minP ?? 0),
                  sumMaxP: prev.sumMaxP + (pv.maxP ?? 0),
                  minEnergy: prev.min_energy + (pv.min_energy ?? 0),
                  maxEnergy: prev.max_energy + (pv.max_energy ?? 0),
                }), {
                  sumMinP: 0, sumMaxP: 0, minEnergy: 0, maxEnergy: 0,
                }),
              }}
              editable={!authEnabled || permissions.has('modify_asset_schedule')}
              expanded={expanded}
            />
          )}
          {['uncontrolled', 'scheduled', 'globallyOptimized'].includes(controlMode) && (
            <>
              {inverterBat.power_electronics_units.map((unit, idx) => (
                <BatteryControlMode
                  key={unit.id}
                  battery={{ ...unit, assetInfo: unit.unit_info, name: `Battery Unit ${idx + 1}` }}
                  disabled={saving || !canEditNetwork}
                  validationSchema={Battery.validationSchema}
                  saveEdit={handleUnitSave}
                  showFinal={controlMode === 'globallyOptimized'}
                />
              ))}
            </>
          )}
        </EditableControlMode>
        <LifecycleSection
          disabled={saving || !inEditMode || !canEditNetwork}
          lifecycle={inverterBat.lifecycle}
          onChange={lifecycle => handleSave({ lifecycle })}
          healthMetric={inverterBat.reliability_info?.healthMetric}
          onHealthMetricChange={handleSave}
        />
      </div>
    </>
  );
};

Battery.propTypes = {
  asset: PropTypes.object.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  displayBranch: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  branch: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  editActions: PropTypes.object.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default Battery;
