import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import TextInput from 'components/TextInput';

import asyncActionStates from 'helpers/asyncActionStates';

import Request from 'helpers/Request';

const AnalyticsConfig = ({
  disabled,
  displayAlertMessage,
  open,
  theme,
  workspace,
}) => {
  // Analytics Config State
  const [analyticsLoadingState, setAnalyticsLoadingState] = useState(asyncActionStates.INITIAL);
  const [analyticsEdited, setAnalyticsEdited] = useState(false);
  const [analyticsSavingState, setAnalyticsSavingState] = useState(asyncActionStates.INITIAL);
  const [maxWorkers, setMaxWorkers] = useState('');

  const loadAnalyticsConfig = () => {
    let didCancel = false;

    async function fetchAnalyticsConfig() {
      const request = new Request(`/api/workspace/${workspace}/analytics-config`);
      setAnalyticsLoadingState(asyncActionStates.LOADING);
      try {
        const { data } = await request.get();

        if (didCancel) {
          // Cancelled before the request finished so do nothing
          setAnalyticsLoadingState(asyncActionStates.CANCELLED);
          return;
        }

        setMaxWorkers(data.max_workers);
        setAnalyticsLoadingState(asyncActionStates.SUCCESS);
      } catch (error) {
        displayAlertMessage(
          'Failed to retrieve analytics configuration',
          'Could not retrieve analytics configuration. Please try again.',
          null,
          () => fetchAnalyticsConfig(),
        );
        setAnalyticsLoadingState(asyncActionStates.ERROR);
      }
    }

    // Only do a load when a component is opened
    if (open && !disabled) {
      fetchAnalyticsConfig();
    }
    return () => { didCancel = true; };
  };
  const saveAnalyticsConfig = async () => {
    const requestUrl = `/api/workspace/${workspace}/analytics-config`;
    const request = new Request(requestUrl);
    setAnalyticsSavingState(asyncActionStates.LOADING);

    try {
      await request.put({
        max_workers: maxWorkers,
      });
      setAnalyticsSavingState(asyncActionStates.SUCCESS);
      setAnalyticsEdited(false);
    } catch (error) {
      setAnalyticsSavingState(asyncActionStates.ERROR);
      displayAlertMessage(
        'Failed to save analytics configuration',
        'Could not save analytics configuration. Please try again.',
        null,
        () => saveAnalyticsConfig(),
      );
    }
  };

  useEffect(loadAnalyticsConfig, [open, disabled, displayAlertMessage, workspace]);

  return (
    <div className="workspace-card-tab-panel">
      <div className="column">
        <TextInput
          label="Max Workers"
          value={maxWorkers}
          id="maxWorkers"
          disabled={
            analyticsLoadingState === asyncActionStates.LOADING
            || analyticsSavingState === asyncActionStates.LOADING
            || disabled
          }
          onChange={(e) => {
            setAnalyticsEdited(true);
            setMaxWorkers(e.target.value);
          }}
          inputWidth="300px"
          theme={theme}
        />
      </div>
      <div className="column column-right">
        <Button
          label="Apply Changes"
          className="workspace-card-button analytics-save"
          disabled={
            !analyticsEdited
            || !maxWorkers
            || analyticsSavingState === asyncActionStates.LOADING
            || disabled
          }
          onClick={saveAnalyticsConfig}
          loading={analyticsSavingState === asyncActionStates.LOADING}
          theme={theme}
          type="primary"
        />
      </div>
    </div>
  );
};

AnalyticsConfig.defaultProps = {
  disabled: true,
  open: false,
};

AnalyticsConfig.propTypes = {
  disabled: PropTypes.bool,
  displayAlertMessage: PropTypes.func.isRequired,
  open: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
};

export default AnalyticsConfig;
