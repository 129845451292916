import React, {
  Component, Fragment, ReactNode,
} from 'react';
import classnames from 'classnames';
import moment, { Moment } from 'moment';

import calendar, {
  getNextHour,
  getPreviousHour,
  getNextDay,
  getPreviousDay,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  CALENDAR_MONTHS,
} from 'helpers/calendarHelper';
import IconButton from '../IconButton';
import { ThemeProp } from '../../types/index';
import './Calendar.scss';

type CalendarProps = {
  calendarType: string|null,
  startDate?: string|null|number|any,
  endDate?: string|null|number|any,
  selectStart: boolean,
  onDateChanged?: (date?: Moment|string|null) => void|null,
  onViewChanged: (startDate?: string|null|number|any, endDate?: string|null|number|any,
    aggregation?: string|null) => void|any,
  theme: ThemeProp,
  dots: string[],
  styleFor?: 'timebar' | 'topnav',
  selectedAnalysis?: string,
  subHourInterval?: number,
};
type CalendarState = {
  minute?: Moment|string|number,
  hour: Moment|string|number,
  day: Moment|string|number,
  month: Moment|string|number,
  year: Moment|string|number,
  yearRangeStart: Moment|string|number,
  today: Moment;
  violationTimepoints: string[],
  scenarioTimepoints: string[],
  analysisTimepoints: string[],
  dayTimeout?: NodeJS.Timeout,
};

class Calendar extends Component<CalendarProps, CalendarState> {
  state = {
    ...this.resolveStateFromProp(),
    today: moment.utc(),
    violationTimepoints: [],
    scenarioTimepoints: [],
    analysisTimepoints: [],
    dayTimeout: undefined,
  };

  componentDidMount(): void {
    const now = moment.utc();
    const tomorrow = moment.utc().add(1, 'day');
    const ms = tomorrow.startOf('day').valueOf() - now.valueOf();
    this.setState({
      dayTimeout: setTimeout(() => {
        this.setState({ today: moment.utc() }, this.clearDayTimeout);
      }, ms),
    });

    this.getUpdatedTimepoints();
  }

  componentDidUpdate(prevProps: CalendarProps, prevState: CalendarState): void {
    const {
      startDate: prevStartDate,
      calendarType: prevCalendarType,
      selectedAnalysis: prevSelectedAnalysis,
    } = prevProps;
    const { startDate, calendarType, selectedAnalysis } = this.props;
    const {
      hour: prevHour, day: prevDay, month: prevMonth, year: prevYear, yearRangeStart: prevYearRangeStart,
    } = prevState;
    const {
      hour, day, month, year, yearRangeStart,
    } = this.state;

    if (!moment(startDate).isSame(moment(prevStartDate))) {
      this.setState({ ...this.resolveStateFromProp() });
    }

    if ((calendarType !== prevCalendarType)
      || (calendarType === 'years' && yearRangeStart !== prevYearRangeStart)
      || (calendarType === 'months' && year !== prevYear)
      || (calendarType === 'days' && (year !== prevYear || month !== prevMonth))
      || (calendarType === 'hours' && (year !== prevYear || month !== prevMonth || day !== prevDay))
      || (calendarType === 'minutes' && (year !== prevYear || month !== prevMonth || day !== prevDay || hour !== prevHour))
      || (selectedAnalysis !== prevSelectedAnalysis)) {
      this.getUpdatedTimepoints();
    }
  }

  componentWillUnmount(): void {
    this.clearDayTimeout();
  }

  clearDayTimeout = (): void => {
    if (this.state.dayTimeout) clearTimeout(this.state.dayTimeout);
  }

  getCalendarDates = (): (string|number)[] => {
    const { calendarType } = this.props;
    const { month, year, yearRangeStart } = this.state;
    let displayCalendar: (string|number)[] = [];
    switch (calendarType) {
      case 'minutes':
        displayCalendar = Array.from(Array(60).keys());
        break;
      case 'hours':
        displayCalendar = Array.from(Array(24).keys());
        break;
      case 'days':
        const calendarMonth = (month || month === 0) ? month : this.props.startDate?.utc().month();
        const calendarYear = year || this.props.startDate?.utc().year();
        displayCalendar = calendar(calendarMonth, calendarYear) as [];
        break;
      case 'months':
        displayCalendar = Object.values(CALENDAR_MONTHS);
        break;
      case 'years':
        displayCalendar = Array.from(Array(12).keys(), n => n + yearRangeStart);
        break;
      default:
        displayCalendar = [];
    }
    return displayCalendar;
  };

  // Render the month and year header with arrow controls
  // for navigating through months and years
  renderHeader = (): ReactNode => {
    const { calendarType } = this.props;
    const {
      hour, day, month, year, yearRangeStart,
    } = this.state;

    let dateHeader;
    switch (calendarType) {
      case 'minutes':
        dateHeader = moment({
          hour, date: day, month, year,
        }).format('DD MMMM YYYY HH');
        break;
      case 'hours':
        dateHeader = moment({ date: day, month, year }).format('DD MMMM YYYY');
        break;
      case 'days':
        dateHeader = moment({ month, year }).format('MMMM YYYY');
        break;
      case 'months':
        dateHeader = moment({ year }).format('YYYY');
        break;
      case 'years':
        dateHeader = `${moment({ year: yearRangeStart }).format('YYYY')} - ${moment({ year: yearRangeStart }).add(11, 'year').format('YYYY')}`;
        break;
      default:
        break;
    }
    return (
      <div className="calendar-header">
        <IconButton
          id="left-arrow"
          onClick={this.handlePrevious}
          icon="chevron_left"
          className="arrow"
          theme={this.props.theme}
        />
        <div className="header-title">
          {dateHeader}
        </div>
        <IconButton
          id="right-arrow"
          onClick={this.handleNext}
          icon="chevron_right"
          className="arrow"
          theme={this.props.theme}
        />
      </div>
    );
  }

  renderLegend = (): ReactNode => {
    let legendItems = [
      { colorClass: 'scenario', displayName: 'Schedule' },
      { colorClass: 'violations', displayName: 'Violations' },
    ].filter(value => this.props.dots.includes(value.colorClass));

    if (this.props.dots.some(dot => ['powerflow', 'hosting-capacity', 'ev-capacity'].includes(dot))) {
      legendItems = legendItems.concat({ colorClass: 'analysis', displayName: 'Analysis' });
    }
    return (
      <div className="calendar-legend">
        {legendItems.map(({ colorClass, displayName }) => (
          <Fragment key={colorClass}>
            <div
              className={classnames({
                'calendar-dot': true,
                [colorClass]: true,
              })}
            />
            <span className="legend-text">{displayName}</span>
          </Fragment>
        ))}
      </div>
    );
  }

  // Render the label for day of the week
  // This method is used as a map callback as seen in render()
  renderDayLabel = (day: string, index: number): ReactNode => {
    // Resolve the day of the week label from the WEEK_DAYS object map
    const daylabel = day.toUpperCase();
    return (
      <div
        className={classnames({
          'calendar-day': true,
          'calenar-day--end': (index % 7) + 1 === 7,
        })}
        key={daylabel}
      >
        {daylabel}
      </div>
    );
  }

  renderCalendar = (val: number, index: number): ReactNode => {
    const { calendarType } = this.props;
    let calendarGrid;
    switch (calendarType) {
      case 'minutes':
        calendarGrid = this.renderCalendarMinute(val, index);
        break;
      case 'hours':
        calendarGrid = this.renderCalendarHour(val, index);
        break;
      case 'days':
        calendarGrid = this.renderCalendarDate(val, index);
        break;
      case 'months':
        calendarGrid = this.renderCalendarMonth(val, index);
        break;
      case 'years':
        calendarGrid = this.renderCalendarYear(val, index);
        break;
      default:
        calendarGrid = null;
    }
    return calendarGrid;
  }

  renderDots = (
    showAnalysis: boolean, showScenario: boolean, showViolations: boolean,
  ): ReactNode => (
    <div className="calendar-dot-container">
      {showScenario && <div className="calendar-dot scenario" />}
      {showViolations && <div className="calendar-dot violations" />}
      {showAnalysis && <div className="calendar-dot analysis" />}
    </div>
  )

  getAnalysisDots = (start: Moment, timeframe: 'minute'|'hour'|'day'|'month'|'year'): boolean => (
    (this.props.dots.some(analysis => ['powerflow', 'hosting-capacity', 'ev-capacity'].includes(analysis))
    && this.state.analysisTimepoints.some(timepoint => start.isSame(timepoint, timeframe)))
  );

  // Render a calendar hour as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  renderCalendarMinute = (minute: number, index: number): ReactNode => {
    const {
      startDate,
      endDate,
      selectStart,
      subHourInterval = 60,
    } = this.props;
    const {
      hour,
      day: date,
      month,
      year,
      today,
      scenarioTimepoints, violationTimepoints,
    } = this.state;

    const startOfMinute = moment.utc().set({
      year, month, date, hour, minute, second: 0,
    });
    const endOfMinute = moment.utc().set({
      year, month, date, hour, minute, second: 59,
    });

    const inRange = moment.utc(startDate).isSameOrBefore(startOfMinute, 'minute')
      && moment.utc(endDate).isSameOrAfter(endOfMinute, 'minute');

    const numStartCells = 60 / subHourInterval;
    const startCellArray = Array.from(Array(numStartCells).keys()).map((val) => 60 - ((val + 1) * subHourInterval));
    const endCellArray = startCellArray.map(val => val + subHourInterval - 1);
    const disabled = (selectStart && !startCellArray.includes(startOfMinute.minute()))
      || (!selectStart && (!endCellArray.includes(startOfMinute.minute()) || startOfMinute.isBefore(startDate)));
    // The click handler
    const onClick = selectStart ? this.gotoDate(startOfMinute) : this.gotoDate(endOfMinute);

    const props = {
      index,
      onClick: disabled ? undefined : onClick,
      title: startOfMinute.format('DD MM YYYY HH:mm'),
    };

    const analysisResults = this.getAnalysisDots(startOfMinute, 'minute');

    // Conditionally render a styled date component
    return (
      <div
        className={classnames({
          'regular-minute': true,
          'regular-minute--right': ((index % 10) + 1 === 10),
          'regular-minute--left': ((index % 10) + 1 !== 10),
          'regular-minute--bottom': index > 49,
          'highlighted-date': inRange,
          'highlighted-date--bounding': startOfMinute.isSame(startDate, 'minute') || endOfMinute.isSame(endDate, 'minute'),
          'todays-date': startOfMinute.isSame(today, 'minute'),
          'regular-minute--disabled': disabled,
        })}
        key={`${startOfMinute.toISOString()}`}
        {...props}
      >
        <div>{startOfMinute.format('mm')}</div>
        {this.renderDots(
          analysisResults,
          this.props.dots.includes('scenario')
          && scenarioTimepoints.some(timepoint => startOfMinute.isSame(timepoint, 'minute')),
          this.props.dots.includes('violations')
          && violationTimepoints.some(timepoint => startOfMinute.isSame(moment.utc(timepoint), 'minute')),
        )}
      </div>
    );
  }

  // Render a calendar day as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  renderCalendarHour = (hour: number, index: number): ReactNode => {
    const { startDate, endDate, selectStart } = this.props;
    const {
      day: date,
      month,
      year,
      today,
      scenarioTimepoints, violationTimepoints,
    } = this.state;

    const startOfHour = moment.utc().set({
      year, month, date, hour, minute: 0,
    });
    const endOfHour = moment.utc().set({
      year, month, date, hour, minute: 59,
    });

    const inRange = moment.utc(startDate).isSameOrBefore(startOfHour, 'hour')
      && moment.utc(endDate).isSameOrAfter(endOfHour, 'hour');

    // The click handler
    const onClick = selectStart ? this.gotoDate(startOfHour) : this.gotoDate(endOfHour);

    const props = { index, onClick, title: startOfHour.format('DD MM YYYY HH:mm') };

    const analysisResults = this.getAnalysisDots(startOfHour, 'hour');

    // Conditionally render a styled date component
    return (
      <div
        className={classnames({
          'regular-hour': true,
          'regular-hour--right': ((index % 6) + 1 === 6),
          'regular-hour--left': ((index % 6) + 1 !== 6),
          'regular-hour--bottom': index > 17,
          'highlighted-date': inRange,
          'highlighted-date--bounding': startOfHour.isSame(startDate, 'hour') || endOfHour.isSame(endDate, 'hour'),
          'todays-date': startOfHour.isSame(today, 'hour'),
        })}
        key={`${startOfHour.toISOString()}`}
        {...props}
      >
        <div className="calendar-alignment" />
        <div>{startOfHour.format('HH:mm')}</div>
        {this.renderDots(
          analysisResults,
          this.props.dots.includes('scenario')
          && scenarioTimepoints.some(timepoint => startOfHour.isSame(timepoint, 'hour')),
          this.props.dots.includes('violations')
          && violationTimepoints.some(timepoint => startOfHour.isSame(moment.utc(timepoint), 'hour')),
        )}
      </div>
    );
  }

  // Render a calendar date as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  renderCalendarDate = (date: Moment|string|number, index:number): ReactNode => {
    const {
      month, year, today,
      scenarioTimepoints, violationTimepoints,
    } = this.state;
    const { startDate, endDate } = this.props;
    const start = moment.utc(startDate);
    const end = endDate ? moment.utc(endDate) : moment.utc(startDate).add(7, 'day');
    const _date = moment.utc(date);

    // Check if calendar date is same day as today
    const isToday = _date.isSame(moment.utc(today), 'day');

    // Check if calendar date is same day as currently selected date
    const inRange = _date.isBetween(start, end, 'day', '[]');

    // Check if calendar date is in the same month as the state month and year
    const inMonth = (month || month === 0)
      && year && _date.isSame(moment.utc().set({ year, month }), 'month');

    const isBeforeFirstWeek = moment(_date).isBefore(moment().set({ year, month }));
    const isFirstWeek = inMonth && moment(_date).week() === moment(_date).startOf('month').week() && (moment(_date).date() - moment(_date).startOf('month').date()) < 7;
    const isFirstNextMonth = !inMonth && moment(_date).date() === moment(_date).startOf('month').date() && moment(_date).isAfter(moment().set({ year, month }));
    const startOfWeek = moment(_date).date() === moment(_date).startOf('week').date();

    // The click handler
    const onClick = this.gotoDate(_date);

    const props = { index, onClick, title: _date.format('YYYY-MM-DD') };

    const analysisResults = this.getAnalysisDots(_date, 'day');

    // Conditionally render a styled date component
    return (
      <div
        className={classnames({
          'regular-date': true,
          'regular-date--inMonth': inMonth,
          'regular-date--left': inMonth || (isFirstNextMonth && !startOfWeek),
          'regular-date--bottom': inMonth || isBeforeFirstWeek,
          'regular-date--inMonth--right': (inMonth && (index % 7) + 1 === 7),
          'regular-date--top': isFirstWeek,
          'highlighted-date': inRange && inMonth,
          'highlighted-date--bounding': inMonth && (_date.isSame(start, 'day') || _date.isSame(end, 'day')),
          'todays-date': isToday && inMonth,
        })}
        key={_date.toISOString()}
        {...props}
      >
        <div>{inMonth ? _date.date() : ''}</div>
        {this.renderDots(
          analysisResults,
          scenarioTimepoints.some(timepoint => _date.isSame(timepoint, 'day')),
          violationTimepoints.some(timepoint => _date.isSame(moment.utc(timepoint), 'day')),
        )}
      </div>
    );
  }

  // Render a calendar month as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  renderCalendarMonth = (month: number, index: number):ReactNode => {
    const { startDate, endDate, selectStart } = this.props;
    const {
      year, today,
      scenarioTimepoints, violationTimepoints,
    } = this.state;

    const startOfMonth = moment.utc().set({ year, month: index }).startOf('month');
    const endOfMonth = moment.utc().set({ year, month: index }).endOf('month');

    const inRange = moment.utc(startDate).isSameOrBefore(startOfMonth, 'month')
      && moment.utc(endDate).isSameOrAfter(endOfMonth, 'month');

    // The click handler
    const onClick = selectStart ? this.gotoDate(startOfMonth) : this.gotoDate(endOfMonth);

    const props = { index, onClick, title: startOfMonth.format('YYYY-MM') };

    const analysisResults = this.getAnalysisDots(startOfMonth, 'month');

    // Conditionally render a styled date component
    return (
      <div
        className={classnames({
          'regular-month': true,
          'regular-month--right': ((index % 4) + 1 === 4),
          'regular-month--left': ((index % 4) + 1 !== 4),
          'regular-month--bottom': index > 7,
          'highlighted-date': inRange,
          'highlighted-date--bounding': startOfMonth.isSame(startDate, 'month') || endOfMonth.isSame(endDate, 'month'),
          'todays-date': startOfMonth.isSame(today, 'month'),
        })}
        key={`${month}-${year}`}
        {...props}
      >
        <div className="calendar-alignment" />
        <div>{month}</div>
        {this.renderDots(
          analysisResults,
          scenarioTimepoints.some(timepoint => startOfMonth.isSame(timepoint, 'month')),
          violationTimepoints.some(timepoint => startOfMonth.isSame(moment.utc(timepoint), 'month')),
        )}
      </div>
    );
  }

  // Render a calendar year as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  renderCalendarYear = (year: number, index: number): ReactNode => {
    const { startDate, endDate, selectStart } = this.props;
    const {
      today,
      scenarioTimepoints, violationTimepoints,
    } = this.state;

    const startOfYear = moment.utc().set({ year }).startOf('year');
    const endOfYear = moment.utc().set({ year }).endOf('year');

    const inRange = moment.utc(startDate).isSameOrBefore(startOfYear, 'year')
      && moment.utc(endDate).isSameOrAfter(endOfYear, 'year');

    // The click handler
    const onClick = selectStart ? this.gotoDate(startOfYear) : this.gotoDate(endOfYear);

    const props = { index, onClick, title: startOfYear.format('YYYY') };

    const analysisResults = this.getAnalysisDots(startOfYear, 'year');

    // Conditionally render a styled date component
    return (
      <div
        className={classnames({
          'regular-year': true,
          'regular-year--right': ((index % 4) + 1 === 4),
          'regular-year--left': ((index % 4) + 1 !== 4),
          'regular-year--bottom': index > 7,
          'highlighted-date': inRange,
          'highlighted-date--bounding': startOfYear.isSame(startDate, 'year') || endOfYear.isSame(endDate, 'year'),
          'todays-date': startOfYear.isSame(today, 'year'),
        })}
        key={`${year}`}
        {...props}
      >
        <div className="calendar-alignment" />
        <div>{year}</div>
        {this.renderDots(
          analysisResults,
          scenarioTimepoints.some(timepoint => startOfYear.isSame(timepoint, 'year')),
          violationTimepoints.some(timepoint => startOfYear.isSame(moment.utc(timepoint), 'year')),
        )}
      </div>
    );
  }

  getUpdatedTimepoints = async (): Promise<any> => {
    const {
      hour, day, month, year, yearRangeStart,
    } = this.state;
    let startDate = null;
    let endDate = null;
    let aggregation = null;
    switch (this.props.calendarType) {
      case 'minutes':
        startDate = moment.utc({
          hour, date: day, month, year,
        }).startOf('hour');
        endDate = moment.utc({
          hour, date: day, month, year,
        }).endOf('hour');
        aggregation = `Min_${this.props.subHourInterval}`;
        break;
      case 'hours':
        startDate = moment.utc({ date: day, month, year }).startOf('day');
        endDate = moment.utc({ date: day, month, year }).endOf('day');
        aggregation = 'hour';
        break;
      case 'days':
        startDate = moment.utc({ month, year }).startOf('month');
        endDate = moment.utc({ month, year }).endOf('month');
        aggregation = 'day';
        break;
      case 'months':
        startDate = moment.utc({ month, year }).startOf('year');
        endDate = moment.utc({ month, year }).endOf('year');
        aggregation = 'month';
        break;
      case 'years':
        startDate = moment.utc({ year: yearRangeStart }).startOf('year');
        endDate = moment.utc({ year: yearRangeStart + 11 }).endOf('year');
        aggregation = 'year';
        break;
      default:
        throw new Error('Unsupported calendar type');
    }

    this.setState({
      analysisTimepoints: [],
      scenarioTimepoints: [],
      violationTimepoints: [],
    });

    try {
      const {
        powerflow, hostingCapacity, scenario, evCapacity, violations,
      } = await this.props.onViewChanged(startDate, endDate, aggregation);
      this.setState({
        analysisTimepoints: powerflow || hostingCapacity || evCapacity || [],
        scenarioTimepoints: scenario || [],
        violationTimepoints: violations || [],
      });
    } catch (err) {
      // this is fine
    }
  }

  gotoDate = (date?: Moment|string|null) => (evt: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (evt) evt.preventDefault();
    const { onDateChanged } = this.props;
    if (typeof onDateChanged === 'function') {
      onDateChanged(date);
    }
  }

  gotoPreviousHour = (): void => {
    const {
      hour, day, month, year,
    } = this.state;
    this.setState(getPreviousHour(hour, day, month, year));
  }

  gotoNextHour = (): void => {
    const {
      hour, day, month, year,
    } = this.state;
    this.setState(getNextHour(hour, day, month, year));
  }

  gotoPreviousDay = (): void => {
    const { day, month, year } = this.state;
    this.setState(getPreviousDay(day, month, year));
  }

  gotoNextDay = (): void => {
    const { day, month, year } = this.state;
    this.setState(getNextDay(day, month, year));
  }

  gotoPreviousMonth = (): void => {
    const { month, year } = this.state;
    this.setState(getPreviousMonth(month, year));
  }

  gotoNextMonth = (): void => {
    const { month, year } = this.state;
    this.setState(getNextMonth(month, year));
  }

  gotoPreviousYear = (): void => {
    const { year } = this.state;
    this.setState({ year: year - 1 });
  }

  gotoNextYear = (): void => {
    const { year } = this.state;
    this.setState({ year: year + 1 });
  }

  gotoPreviousDecade = (): void => {
    const { year } = this.state;
    this.setState({ year: year - 10 });
  }

  gotoNextDecade = (): void => {
    const { year } = this.state;
    this.setState({ year: year + 10 });
  }

  gotoPreviousDozen = (): void => {
    const { yearRangeStart } = this.state;
    this.setState({ yearRangeStart: yearRangeStart - 12 });
  }

  gotoNextDozen = (): void => {
    const { yearRangeStart } = this.state;
    this.setState({ yearRangeStart: yearRangeStart + 12 });
  }

  handlePrevious = (evt?: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (evt) evt.preventDefault();
    const { calendarType } = this.props;
    switch (calendarType) {
      case 'minutes':
        if (evt?.shiftKey) {
          this.gotoPreviousDay();
        } else {
          this.gotoPreviousHour();
        }
        break;
      case 'hours':
        if (evt?.shiftKey) {
          this.gotoPreviousMonth();
        } else {
          this.gotoPreviousDay();
        }
        break;
      case 'days':
        if (evt?.shiftKey) {
          this.gotoPreviousYear();
        } else {
          this.gotoPreviousMonth();
        }
        break;
      case 'months':
        if (evt?.shiftKey) {
          this.gotoPreviousDecade();
        } else {
          this.gotoPreviousYear();
        }
        break;
      case 'years':
        this.gotoPreviousDozen();
        break;
      default:
        break;
    }
  }

  handleNext = (evt?: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (evt) evt.preventDefault();
    const { calendarType } = this.props;
    switch (calendarType) {
      case 'minutes':
        if (evt?.shiftKey) {
          this.gotoNextDay();
        } else {
          this.gotoNextHour();
        }
        break;
      case 'hours':
        if (evt?.shiftKey) {
          this.gotoNextMonth();
        } else {
          this.gotoNextDay();
        }
        break;
      case 'days':
        if (evt?.shiftKey) {
          this.gotoNextYear();
        } else {
          this.gotoNextMonth();
        }
        break;
      case 'months':
        if (evt?.shiftKey) {
          this.gotoNextDecade();
        } else {
          this.gotoNextYear();
        }
        break;
      case 'years':
        this.gotoNextDozen();
        break;
      default:
        break;
    }
  }

  resolveStateFromProp() {
    return {
      minute: moment.utc(this.props.startDate).minute(),
      hour: moment.utc(this.props.startDate).hour(),
      day: moment.utc(this.props.startDate).date(),
      month: moment.utc(this.props.startDate).month(),
      year: moment.utc(this.props.startDate).year(),
      yearRangeStart: Math.floor(moment.utc(this.props.startDate).year() / 12) * 12,
    };
  }

  render(): ReactNode {
    const { calendarType, styleFor = 'topnav' } = this.props;
    return (
      <div className={`${this.props.theme} calendar style-for-${styleFor}`}>
        {this.renderHeader()}
        <div className="calendar-grid">
          {calendarType === 'days' && (
            <div style={{ display: 'flex', width: '100%', paddingBottom: '3px' }}>
              {Object.values(WEEK_DAYS).map((value, index) => this.renderDayLabel(value, index))}
            </div>
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {this.getCalendarDates().map((value, index) => this.renderCalendar(value as number, index))}
          </div>
        </div>
        {this.renderLegend()}
      </div>
    );
  }
}

export default Calendar;
