import React, { FunctionComponent } from 'react';

type RefreshIconParams = {
  type?: 'primary' | 'secondary' | 'disabled';
}

const RefreshIcon: FunctionComponent<RefreshIconParams> = ({ type = 'primary' }) => (
  <svg className={`icon-${type}`} width="100%" height="100%" viewBox="-2 -2 16 16" version="1.1">
    <rect className="icon-background" x="-2" y="-2" width="16" height="16" rx="15%" ry="15%" />
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g className="icon-foreground" transform="translate(-628.000000, -1369.000000)" fillRule="nonzero">
        <g transform="translate(628.500000, 1369.174813)">
          <path d="M9.82288276,9.76996262e-14 C9.58700349,9.76996262e-14 9.39579264,0.190516955 9.39579264,0.425540226 L9.39579264,1.97999501 C7.1970055,-0.141023936 3.68887073,-0.084424867 1.56012667,2.10635805 C0.556901568,3.13882979 -0.00276740957,4.52037068 0,5.9574385 C0,6.19246177 0.19122114,6.38297872 0.427100406,6.38297872 C0.662979672,6.38297872 0.854165498,6.19246177 0.854165498,5.9574385 C0.854691008,3.37228225 2.95841075,1.27699468 5.5530076,1.27751828 C6.87071336,1.27776762 8.12771011,1.82942154 9.01742488,2.79786403 L7.12546097,3.42636303 C6.90139318,3.50063996 6.78015034,3.74182181 6.85469779,3.96507646 C6.92924525,4.18833112 7.17130552,4.30913398 7.39537331,4.23485705 L9.95786396,3.38380153 C10.132784,3.32575632 10.2505735,3.16239195 10.25,2.97870678 L10.25,0.425515293 C10.25,0.190516955 10.058762,9.76996262e-14 9.82288276,9.76996262e-14 Z" />
          <path d="M10.5729486,5.36170213 C10.3370688,5.36170213 10.1458574,5.55221834 10.1458574,5.78724069 C10.1453319,8.37238684 8.04160706,10.4676662 5.44700387,10.4671426 C4.12929489,10.4668933 2.87229507,9.91524152 1.98257812,8.94680282 L3.87454666,8.31830627 C4.098615,8.24402963 4.21985814,8.00284873 4.1453105,7.77959494 C4.07076286,7.55634116 3.82870199,7.43553877 3.60463366,7.50981541 L1.04213676,8.36086761 C0.867216314,8.41891259 0.749426539,8.58227632 0.75,8.76596077 L0.75,11.3191423 C0.75,11.5541646 0.941213417,11.7446809 1.17709326,11.7446809 C1.4129731,11.7446809 1.60418442,11.5541646 1.60418442,11.3191423 L1.60418442,9.76466864 C3.80297693,11.8856793 7.31114529,11.8291054 9.43986953,9.6383061 C10.4430971,8.60583839 11.0027674,7.2243029 11,5.78724069 C11,5.55221834 10.8088284,5.36170213 10.5729486,5.36170213 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default RefreshIcon;
