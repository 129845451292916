import React from 'react';
import PropTypes from 'prop-types';

import './NonExpandableSection.scss';

const NonExpandableSection = props => (
  <div className={`non-expandable-section ${props.theme} ${props.className}`}>
    <div className="title">
      <div>
        <p>{props.sectionTitle}</p>
      </div>
      <div className="aux-content">{props.renderTitleAuxContent()}</div>
    </div>

    <div className="non-expandable-section-content">{props.children}</div>
  </div>
);

NonExpandableSection.defaultProps = {
  renderTitleAuxContent: () => {},
  children: null,
  theme: 'dark',
  className: '',
};

NonExpandableSection.propTypes = {
  renderTitleAuxContent: PropTypes.func,
  sectionTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  theme: PropTypes.string,
  className: PropTypes.string,
};

export default NonExpandableSection;
