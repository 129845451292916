import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appStateReducer from 'store/appState';
import { actions } from '../modules/network';
import { powerflowActions } from '../modules/powerflow';
import { loadForecastActions } from '../modules/loadForecast';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import NetworkBottomBar from '../components/NetworkBottomBar';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...actions,
    ...powerflowActions,
    ...appStateReducer.actions,
    ...loadForecastActions,
  }, dispatch),
});

const mapStateToProps = state => ({
  timeRange: state.network.timeRange,
  determiningTimeRange: state.network.determiningTimeRange,
  timepoints: state.network.timepoints,
  powerflowReq: state.requests.powerflow,
  powerFlowErrorMessage: state.powerflow.powerFlowErrorMessage,
  selectedScenario: state.loadForecast.selectedScenario,
  selectedScenarioType: state.loadForecast.selectedScenarioType,
  selectedAnalysis: state.loadForecast.selectedAnalysis,
  qstsLoading: state.network.qstsLoading,
  branch: state.network.branch,
  theme: state.global.theme,
  timebarViolations: state.powerflow.timebarViolations,
  timebarViolationsStatus: state.powerflow.timebarViolationsStatus,
  maxRange: state.network.maxRange,
  timeBarZoomLevel: state.network.timeBarZoomLevel,
  selectedFeeders: state.feeders.selected,
  workspaceName: state.network.workspace,
  subHourInterval: state.loadForecast.subHourInterval,
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkBottomBar);
