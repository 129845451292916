/* eslint-disable react/no-unused-prop-types */
import React, {
  useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import IconButton from 'components/IconButton';
import ThemedTabs from 'components/ThemedTabs';
import asyncStates from 'helpers/asyncActionStates';
import { useAsset } from 'contexts/AssetContext';
import ThemeContext from 'helpers/ThemeContext';
import {
  userCanEdit,
} from 'routes/WorkspaceLayout/routes/Network/helpers/EditHelpers';
import FilesPanel from 'components/Files';
import NotesPanel from 'components/Notes';
import AssetPanelLoading from './components/AssetPanelLoading';
import AssetPanelError from './components/AssetPanelError';
import ResultsPanel from './containers/ResultsPanelContainer';
import Templates from './templates';
import AssetPanelIcon from './AssetPanelIcon';
import { TYPE_MAP } from '../../../helpers/NetworkHelpers';

const {
  LOADING, SUCCESS, NOTALLOWED, ERROR,
} = asyncStates;

const SWITCH_ICON_CLASSES = ['Switch', 'Disconnector'];

const AssetPanel = (props) => {
  const theme = useContext(ThemeContext);
  const {
    asset,
    loading: loadingState,
    refetch,
  } = useAsset(props.selectedNode.id, TYPE_MAP[props.selectedNode.class].assetType);
  const [newPanelValues, setNewPanelValues] = useState({
    name: loadingState === SUCCESS && !asset?.attributes.name
      ? props.selectedNode.id : asset?.attributes.name,
    baseVoltage: asset?.base_voltage,
    phase: asset?.phase || asset?.phases,
  });
  const [icon, setIcon] = useState(props.selectedNode.icon);
  const [edited, setEdited] = useState({});
  const [errors, setErrors] = useState({ isError: false, error: '' });
  const [selectedTab, setSelectedTab] = useState(0);

  const checkErrors = () => {
    const err = {
      isError: false,
      error: '',
    };
    const { applyDifferenceModelRequest, deleteStatus, deleteError } = props;
    if (applyDifferenceModelRequest.editValues === ERROR || deleteStatus === ERROR) {
      err.isError = true;
      if (
        applyDifferenceModelRequest.editValues === ERROR
        && applyDifferenceModelRequest.error
        && applyDifferenceModelRequest.error.response
        && applyDifferenceModelRequest.error.response.data
      ) {
        const { message } = applyDifferenceModelRequest.error.response.data;
        err.error = typeof message === 'string' ? message : 'There was an unexpected error!';
      } else if (
        deleteStatus === ERROR
        && deleteError
        && deleteError.response
        && deleteError.response.data
      ) {
        err.error = deleteError.response.data.message;
      } else {
        err.error = 'Failed to update';
      }
    }
    return err;
  };

  useEffect(() => {
    const node = props.selectedNode;
    let headerIcon = node.icon;
    if (SWITCH_ICON_CLASSES.indexOf(node.class) !== -1 && node.closed !== undefined) {
      headerIcon = node.closed ? 'switch_closed' : node.instance.icon;
    }
    if (asset?.usage_points?.some(value => value.demandResponseProgram?.program_type !== 'none')) {
      headerIcon = 'load_dr';
    }
    setIcon(headerIcon);
    setErrors(checkErrors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedNode, props.SV, loadingState, asset]);

  useEffect(() => {
    let newPV = {
      name: loadingState === SUCCESS && !asset?.attributes.name
        ? props.selectedNode.id : asset?.attributes.name,
      baseVoltage: asset?.base_voltage,
      phase: asset?.phase || asset?.phases,
    };
    if (props.selectedNode.class === 'Battery' || props.selectedNode.class === 'InverterPV') {
      newPV = {
        ...newPV,
        minQ: asset?.attributes?.minQ,
        maxQ: asset?.attributes?.maxQ,
      };
    }
    if (props.selectedNode.class === 'Battery') {
      newPV = {
        ...newPV,
        minP: asset?.attributes?.minP,
        maxP: asset?.attributes?.maxP,
        ...asset?.power_electronics_units?.reduce((prev, batteryUnit) => ({
          minEnergy: prev.min_energy + (batteryUnit.min_energy ?? 0),
          maxEnergy: prev.max_energy + (batteryUnit.max_energy ?? 0),
        }), {
          minEnergy: 0, maxEnergy: 0,
        }),
      };
    }
    if (props.selectedNode.class === 'InverterPV') {
      newPV = {
        ...newPV,
        ratedS: asset?.attributes?.ratedS,
        pvUnitMinP:
          asset?.power_electronics_unit?.reduce((totalMinP, pv) => totalMinP + pv.minP, 0),
      };
    }
    setNewPanelValues(newPV);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedNode.id, asset]);

  useEffect(() => {
    const err = checkErrors();
    if (err.isError !== errors.isError || err.error !== errors.error) {
      setErrors(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applyDifferenceModelRequest, props.deleteStatus, props.deleteError]);

  useEffect(() => {
    if (props.inEditMode && selectedTab === 1) {
      setSelectedTab(0);
    }
  }, [selectedTab, props.inEditMode]);

  useEffect(() => {
    if (!props.loadForecast.selectedAnalysis?.name) {
      setSelectedTab(0);
    }
  }, [props.loadForecast.selectedAnalysis]);

  const handleSaveEdit = ({
    assetType,
    field,
    editedKey = undefined,
    value = undefined,
    isAttribute = false,
    attributeFieldName = 'attributes',
  }) => {
    const editedField = editedKey || field;
    if (!edited[editedField]) return;

    const { workspace, branch } = props;
    const data = { [field]: value || newPanelValues[editedField] };
    const body = isAttribute ? { [attributeFieldName]: data } : data;
    // Allow only name to be empty
    const shouldUpdate = field === 'name' ? true : data[field] !== undefined;

    if (shouldUpdate) {
      props.editActions.editSingleEquipment(
        workspace,
        branch,
        assetType,
        props.selectedNode.id,
        body,
      );
    }
  };

  const handleDeleteClick = () => {
    props.showModal('Asset');
  };

  const handleDuplicateClick = () => props.editActions.duplicateAsset(
    props.selectedNode.id, props.selectedNode.class,
  );

  const handleLocateClick = () => {
    // we are intentionally creating a new object here as that will force
    // equality checks to fail so you can zoom an asset twice
    props.actions.setFlyToAsset({
      id: props.selectedNode.id,
      class: props.selectedNode.class,
    });
  };

  const handleCloseClick = () => props.actions.setSelectedAssetID(props.selectedNode.id);

  const _renderTabs = (canEditNetwork, inEditMode) => {
    const PanelValues = Templates[props.selectedNode.class];
    const PanelContent = (
      <CustomScrollBar>
        <PanelValues
          asset={asset}
          workspace={props.workspace}
          branch={props.branch}
          displayBranch={props.displayBranch}
          feeders={props.feeders}
          edited={edited}
          selected={props.selectedNode}
          applyDifferenceModelRequest={props.applyDifferenceModelRequest}
          toggleFeederPanel={props.toggleFeederPanel}
          setActivePanel={props.setActivePanel}
          timeBarZoomLevel={props.timeBarZoomLevel}
          timeRange={props.timeRange}
          maxRange={props.maxRange}
          permissions={props.permissions}
          authEnabled={props.authEnabled}
          loadForecast={props.loadForecast}
          actions={props.actions}
          editActions={props.editActions}
          theme={theme}
          inEditMode={inEditMode}
          canEditNetwork={canEditNetwork}
          saveEdit={handleSaveEdit}
          expanded={props.panelOptions.expanded}
          selectedFeeders={props.selectedFeeders}
          feeder={asset?.container?.id}
          setSelectedAssetID={props.actions.setSelectedAssetID}
        />
      </CustomScrollBar>
    );

    const canAccessAttachments = !props.authEnabled || props.permissions.has('view_asset_attachments');
    const canAccessNotes = !props.authEnabled || props.permissions.has('view_asset_notes');

    const { notes, files } = props;
    const tabs = [
      {
        disabled: false,
        name: 'General',
      },
      {
        disabled: !props.loadForecast.selectedAnalysis?.name || inEditMode,
        name: 'Results',
      },
      {
        disabled: !canAccessNotes,
        name: `Notes${notes && notes.length ? ` (${notes.length})` : ''}`,
        permissionTipOptions: {
          title: 'Asset Notes',
          placement: 'left',
          hide: canAccessNotes,
        },
      },
      {
        disabled: !canAccessAttachments,
        name: `Files${files && files.length ? ` (${files.length})` : ''}`,
        permissionTipOptions: {
          title: 'Attachments',
          placement: 'left',
          hide: canAccessAttachments,
        },
      },
    ];
    const isLoading = loadingState === asyncStates.INITIAL || loadingState === asyncStates.LOADING;
    const didError = loadingState === asyncStates.ERROR;
    if (isLoading && !asset) return <AssetPanelLoading />;
    if (didError || !asset) return <AssetPanelError retry={refetch} />;

    return (
      <ThemedTabs
        theme={theme}
        tabs={tabs}
        onSelect={(tabIndex) => setSelectedTab(tabIndex)}
        selectedIndex={selectedTab}
      >
        {(TabPanel) => {
          const panels = [
            <TabPanel key="Tab-Panel-content">{PanelContent}</TabPanel>,
            <TabPanel key="Tab-Panel-violations">
              <CustomScrollBar>
                { !inEditMode && props.loadForecast.selectedAnalysis && (
                  <ResultsPanel
                    assetID={props.selectedNode.id}
                    assetClass={props.selectedNode.class}
                    asset={asset}
                    branch={props.displayBranch}
                    expanded={props.panelOptions.expanded}
                    maxRange={props.maxRange}
                    timeRange={props.timeRange}
                    timeBarZoomLevel={props.timeBarZoomLevel}
                    newPanelValues={newPanelValues}
                    scenarioID={props.loadForecast.selectedScenario}
                    analysisName={props.loadForecast.selectedAnalysis?.name}
                    theme={theme}
                    workspace={props.workspace}
                  />
                )}
              </CustomScrollBar>
            </TabPanel>,
            <TabPanel key="Tab-Panel-notes">
              <NotesPanel
                assetID={props.selectedNode.id}
                branch={props.displayBranch}
                inEditMode={props.inEditMode}
                permissions={props.permissions}
                theme={theme}
                workspace={props.workspace}
              />
            </TabPanel>,
            <TabPanel key="Tab-Panel-files">
              <FilesPanel
                assetID={props.selectedNode.id}
                branch={props.branch}
                permissions={props.permissions}
                theme={theme}
                workspace={props.workspace}
              />
            </TabPanel>,
          ];

          return panels;
        }}
      </ThemedTabs>
    );
  };

  const getDeleteAssetProps = () => {
    const deleteProps = { disabled: false, tooltip: 'Delete Asset' };
    const canDelete = !props.authEnabled || props.permissions.has('delete_asset');
    if (!props.inEditMode || !canEditNetwork || !canDelete || !asset) {
      deleteProps.disabled = true;
    }

    if (!deleteProps.disabled) {
      if (props.selectedNode.class === 'ConnectivityNode' && asset.connected_assets?.length > 0) {
        deleteProps.disabled = true;
        deleteProps.tooltip = (
          <div className="delete-asset-tooltip">
            <p>The following equipment must be removed to delete this node:</p>
            <ul>
              {asset.connected_assets.slice(0, 5).map(eq => (
                <li key={eq.id}>{eq.name}</li>
              ))}
              {asset.connected_assets.length > 5 && (
              <li key="hidden">
                {`+ ${asset.connected_assets.length - 5} others`}
              </li>
              )}
            </ul>
          </div>
        );
      }
    }

    return deleteProps;
  };

  const getDuplicateAssetProps = () => {
    const duplicateProps = { disabled: false, tooltip: 'Duplicate Asset' };
    if (!props.inEditMode || !canEditNetwork || !props.permissions.has('duplicate_asset') || !asset) {
      duplicateProps.disabled = true;
    }

    if (!duplicateProps.disabled) {
      if (props.selectedNode.class === 'ACLineSegment') {
        duplicateProps.disabled = asset?.nodes?.length !== 2;
      } else {
        duplicateProps.disabled = true;
      }
    }
    return duplicateProps;
  };

  const { editValues } = props.applyDifferenceModelRequest;
  const { inEditMode, selectedNode } = props;

  // Get the correct template for the selected node
  const canEditNetwork = !props.authEnabled || userCanEdit(props.permissions, props.branch);

  const loadingIcon = editValues === LOADING && (
    <span className="loading-placeholder">
      <i className="material-icons rotate">refresh</i>
    </span>
  );

  return (
    <div className="asset-panel asset-panel-container">
      <div
        className={classNames({
          'asset-panel-header': true,
          'asset-panel-header--expanded': props.panelOptions.expanded,
        })}
      >
        {loadingState === SUCCESS && (
          <div className="asset-panel-header-contents">
            <AssetPanelIcon type={selectedNode.class} icon={icon} />
            <div className="asset-panel-header-description">
              {inEditMode ? (
                <input
                  className={`asset-panel-name ${edited.name ? 'asset-panel-name--edited' : ''}`}
                  value={newPanelValues?.name}
                  onChange={e => {
                    setNewPanelValues({ ...newPanelValues, name: e.target.value });
                    setEdited({ name: true });
                  }}
                  onBlur={() => handleSaveEdit({
                    assetType: TYPE_MAP[selectedNode.class].assetType,
                    field: 'name',
                    isAttribute: true,
                  })}
                  title={newPanelValues?.name}
                  disabled={!inEditMode}
                />
              ) : (
                <p className="asset-panel-name asset-panel-name-noedit">{newPanelValues?.name}</p>
              )}
              <div className="asset-type-container">
                <p>{selectedNode.displayName}</p>
              </div>
            </div>

            <div className="header-btn-container">
              {inEditMode && (
                <>
                  <div
                    style={{
                      height: '24px',
                      width: '32px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {loadingIcon}
                  </div>
                  <IconButton
                    icon="delete"
                    className="delete-asset"
                    onClick={handleDeleteClick}
                    theme={theme}
                    {...getDeleteAssetProps()}
                  />
                  {props.selectedNode.class === 'ACLineSegment' && (
                    <IconButton
                      icon="file_copy"
                      className="duplicate-asset"
                      onClick={handleDuplicateClick}
                      theme={theme}
                      {...getDuplicateAssetProps()}
                    />
                  )}
                </>
              )}

              <IconButton
                icon="my_location"
                onClick={handleLocateClick}
                tooltip="Locate Asset"
                theme={theme}
              />
              <IconButton
                className="clear-asset"
                icon="close"
                onClick={handleCloseClick}
                tooltip="Clear Selection"
                theme={theme}
              />
            </div>
          </div>
        )}
        <div className="feedback-container">
          <p className="modify-network-error">
            {editValues === NOTALLOWED && (
              <>
                <i className="material-icons edit-values-error__i">warning</i>
                You do not have permission to edit assets.
              </>
            )}
            {errors && errors.isError && (
              <>
                <i className="material-icons edit-values-error__i">warning</i>
                {errors.error}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="asset-panel-instance-info">
        {_renderTabs(canEditNetwork, inEditMode)}
      </div>
    </div>
  );
};

AssetPanel.defaultProps = {
  notes: [],
  files: [],
};

AssetPanel.propTypes = {
  // From Global State
  authEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  selectedNode: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  feeders: PropTypes.string.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  zoomToAsset: PropTypes.func.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  SV: PropTypes.object.isRequired,
  setActivePanel: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.oneOf(['hour', 'day', 'month', 'year']).isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  editActions: PropTypes.object.isRequired,
  loadForecast: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  panelOptions: PropTypes.object.isRequired,
  deleteStatus: PropTypes.number.isRequired,
  deleteError: PropTypes.object.isRequired,
  notes: PropTypes.array,
  files: PropTypes.array,
  selectedFeeders: PropTypes.array.isRequired,
};

export default AssetPanel;
