import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import {
  MODAL_TYPES,
} from 'helpers/EditMode';
import Button from '../Button';
import { ThemeProp } from '../../types/index';
import './ButtonToggle.scss';

type ButtonToggleProps = {
  leftLabel: string,
  rightLabel: string,
  disabled?: boolean,
  width?: number,
  height?: number,
  theme?: ThemeProp,
  leftActive: boolean,
  onClickLeft: (modalType: string) => null,
  onClickRight: () => null,
};

const ButtonToggle: FunctionComponent<ButtonToggleProps> = ({
  leftLabel,
  rightLabel,
  leftActive,
  disabled = false,
  width = 200,
  height = 42,
  theme = 'light',
  onClickLeft = null,
  onClickRight = null,
}) => {
  const clickToggle = (left = true) => {
    let toggle;
    if (left) {
      toggle = onClickLeft && onClickLeft(MODAL_TYPES.TOGGLE);
    } else {
      toggle = onClickRight && onClickRight();
    }
    return toggle;
  };

  return (
    <div className={`${theme} toggle-container`} style={{ width: `${width}px`, height: `${height}px` }}>
      <Button
        id="left"
        key="left"
        type={leftActive ? 'primary' : 'secondary'}
        width="48%"
        height="100%"
        className={
          classNames({
            'left-toggle--primary': leftActive,
            'left-toggle--secondary': !leftActive,
            disabled,
          }, 'left-toggle')
        }
        theme={theme}
        onClick={() => clickToggle(true)}
        disabled={disabled}
      >
        {leftLabel}
      </Button>
      <Button
        id="right"
        key="right"
        type={leftActive ? 'secondary' : 'primary'}
        width="48%"
        height="100%"
        className={
            classNames({
              'right-toggle--primary': !leftActive,
              'right-toggle--secondary': leftActive,
              disabled,
            }, 'right-toggle')
          }
        theme={theme}
        onClick={() => clickToggle(false)}
        disabled={disabled}
      >
        {rightLabel}
      </Button>
    </div>
  );
};

export default ButtonToggle;
