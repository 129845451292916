import React from 'react';
import {
  node, string, array, bool, object, func, oneOfType, element,
} from 'prop-types';
import Tooltip from 'components/Tooltip';
import Select from 'components/Select';
import Analytics from 'helpers/Analytics';
import useTrackingState from 'hooks/useTrackingState';

import { transposePhaseAttributes, diffPerPhaseAttributes } from '../../helpers/valueExtractors';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import PerPhaseContainer from './PerPhaseContainer';
import PerPhaseRow from './PerPhaseRow';

export default function EditableControlMode({
  children,
  controlMode,
  controlModeOptions,
  attributes,
  perPhaseAttributes,
  editableValues,
  editablePerPhaseValues,
  asset,
  phases,
  balanced,
  help,
  disabled,
  onSave,
  theme,
  attributeTypeMapping,
}) {
  const [values, setValues] = useTrackingState(attributes);
  const [perPhaseValues, setPerPhaseValues] = useTrackingState(perPhaseAttributes);

  const handleSave = (field, value) => {
    onSave({
      [attributeTypeMapping?.[field] || 'attributes']: value,
    });
  };

  const handleControlModeChange = ({ value: mode }) => {
    onSave({ analysis_control: { mode } });
    Analytics.logEvent(`Change ${asset} Control Mode`, 'Control Modes', mode);
  };

  return (
    <div className="control-mode">
      <div className="control-mode-aux-content">
        <span className="control-mode-title">Control Mode</span>
        <div className="control-mode-select">
          <Select
            name="control-panel-control-type"
            id="control-panel-control-type"
            theme={theme}
            value={controlMode}
            className="custom-react-select-theme"
            disabled={disabled}
            placeholder="Select Control Type"
            options={controlModeOptions}
            clearable={false}
            searchable={false}
            onChange={handleControlModeChange}
          />
          {help && (
            <Tooltip placement="left" content={help}>
              <i className="material-icons help-icon">help_outline</i>
            </Tooltip>
          )}
        </div>
      </div>
      {values && editableValues?.length > 0 && (
        <div className="editable-section">
          {editableValues.map(({ id, ...rest }) => (
            <div className="input-spacing" key={id}>
              <SingleEditableValue
                {...rest}
                id={id}
                key={id}
                name={id}
                value={values[id]}
                onChange={val => setValues({ ...values, [id]: val })}
                edited={values[id] !== attributes[id]}
                onBlur={() => handleSave(id, { [id]: values[id] })}
                precision={3}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
      )}
      {perPhaseValues && editablePerPhaseValues?.length > 0 && balanced && (
        <div className="editable-section">
          {editablePerPhaseValues.map(({ id, ...rest }) => (
            <div className="input-spacing" key={id}>
              <SingleEditableValue
                {...rest}
                id={id}
                key={id}
                name={id}
                value={perPhaseValues[id][phases]}
                onChange={value => setPerPhaseValues({
                  ...perPhaseValues,
                  [id]: {
                    ...perPhaseValues[id],
                    [phases]: value,
                  },
                })}
                edited={perPhaseValues[id][phases] !== perPhaseAttributes?.[id]?.[phases]}
                onBlur={() => {
                  const attrs = transposePhaseAttributes({ [id]: perPhaseValues[id] });
                  handleSave(id, attrs);
                }}
                precision={3}
                disabled={disabled}
                {...Object.keys(perPhaseValues).reduce(
                  (prev, curr) => Object.assign(prev, { [curr]: perPhaseValues?.[curr]?.[phases] }),
                  {},
                )}
              />
            </div>
          ))}
        </div>
      )}
      {perPhaseValues && editablePerPhaseValues?.length > 0 && !balanced && (
        <PerPhaseContainer>
          {editablePerPhaseValues.map(({ id, ...rest }) => (
            <PerPhaseRow
              {...rest}
              id={id}
              key={id}
              values={perPhaseValues[id]}
              phases={phases}
              editable={!disabled}
              edited={perPhaseValues[id] && diffPerPhaseAttributes(
                perPhaseAttributes[id],
                perPhaseValues[id],
              )}
              onChange={(value, _, phase) => setPerPhaseValues({
                ...perPhaseValues,
                [id]: {
                  ...perPhaseValues[id],
                  [phase]: value,
                },
              })}
              onBlur={() => {
                const attrs = transposePhaseAttributes({ [id]: perPhaseValues[id] });
                handleSave(id, attrs);
              }}
              {...perPhaseValues}
            />
          ))}
        </PerPhaseContainer>
      )}
      {children}
    </div>
  );
}

EditableControlMode.defaultProps = {
  children: undefined,
  attributes: undefined,
  perPhaseAttributes: undefined,
  editableValues: [],
  editablePerPhaseValues: [],
  phases: 'ABC',
  balanced: false,
  help: undefined,
  disabled: false,
  attributeTypeMapping: undefined,
};

EditableControlMode.propTypes = {
  children: node,
  controlMode: string.isRequired,
  controlModeOptions: array.isRequired,
  attributes: object,
  editableValues: array,
  asset: string.isRequired,
  help: oneOfType([string, element]),
  disabled: bool,
  onSave: func.isRequired,
  theme: string.isRequired,
  attributeTypeMapping: object,
  // following 4 properties are used together for perPhase fields
  perPhaseAttributes: object,
  editablePerPhaseValues: array,
  phases: string,
  balanced: bool,
};
