import React from 'react';
import Switch from './Switch';

// Creates a value template for Recloser instances.
// As Recloser shares all of its values with Switch, the switch template is used
const Recloser = props => (
  <Switch {...props} />
);

export default Recloser;
