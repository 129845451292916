import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonGroup from 'components/RadioButtonGroup';

const AnalysisSettingsTab = ({
  settings,
  theme,
  updateSettings,
}) => {
  const analysisSettings = settings.analysis || {};
  const invertRealPower = !!analysisSettings.invertRealPower;
  const invertReactivePower = !!analysisSettings.invertReactivePower;

  const realPowerOptions = [
    {
      id: false,
      label: 'Load (+) / Generation (-)',
    },
    {
      id: true,
      label: 'Load (-) / Generation (+)',
    },
  ];
  const reactivePowerOptions = [
    {
      id: false,
      label: 'Inductive (+) / Capacitive (-)',
    },
    {
      id: true,
      label: 'Inductive (-) / Capacitive (+)',
    },
  ];

  return (
    <div className="settings-tab">
      <div className="setting-container">
        <div className="input-section">
          <span className="setting-label">Shunt Device Real Power Sign Convention</span>
          <RadioButtonGroup
            id="real-power-setting"
            onChange={({ target }) => updateSettings('analysis', { invertRealPower: target.value })}
            options={realPowerOptions}
            theme={theme}
            value={invertRealPower}
          />
        </div>
        <div className="input-section">
          <span className="setting-label">Shunt Device Reactive Power Sign Convention</span>
          <RadioButtonGroup
            id="reactive-power-setting"
            onChange={({ target }) => updateSettings('analysis', { invertReactivePower: target.value })}
            options={reactivePowerOptions}
            theme={theme}
            value={invertReactivePower}
          />
        </div>
      </div>
    </div>
  );
};

AnalysisSettingsTab.propTypes = {
  settings: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  updateSettings: PropTypes.func.isRequired,
};

export default AnalysisSettingsTab;
