import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import Tour from 'reactour';
import classNames from 'classnames';
import Button from 'components/Button';
import apm from 'helpers/apm';
import IntlContextProvider from 'contexts/IntlContext';
import WorkspaceSettingsContextProvider from 'contexts/WorkspaceSettingsContext';
import ThemeContext from 'helpers/ThemeContext';
import browserHistory from 'routes/history';
import ShareModal from './ShareModal';
import AlertMessage from './AlertMessage';
import ErrorPage from './ErrorPage';
import SettingsMenu from './SettingsMenu';
import { arrowPosition, createSteps } from './TourHelper';
import './CoreLayout.scss';
import '../../styles/core.scss';

class CoreLayout extends Component {
  state = {
    isTourOpen: !(localStorage.getItem('tour') === 'hide'),
    currentStep: 1,
  };

  componentDidMount() {
    this.setState({ steps: createSteps(this.closeTour) });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdateChecks = [
      this.props.location.pathname !== nextProps.location.pathname,
      this.state.isTourOpen !== nextState.isTourOpen,
      this.state.error !== nextState.error,
      this.state.currentStep !== nextState.currentStep,
      this.props.permissions !== nextProps.permissions,
      this.props.alertMessage !== nextProps.alertMessage,
      this.props.theme !== nextProps.theme,
      this.props.view !== nextProps.view,
    ];
    if (shouldUpdateChecks.some(check => check)) {
      return true;
    }
    return false;
  }

  componentDidCatch(err, info) {
    this.setState({
      error: true,
      errorInfo: {
        info: info.componentStack,
        error: err.toString(),
      },
    });
    apm.captureError(err);
  }

  setTourState = () => {
    this.setState({
      isTourOpen: !(localStorage.getItem('tour') === 'hide'),
    });
  }

  closeTour = () => {
    this.setState({ isTourOpen: false });
    localStorage.setItem('tour', 'hide');
  }

  getRouteMatch = () => {
    const importMatch = matchPath(this.props.location.pathname, {
      path: '/import-cim/:workspace?/:branch?',
    });
    const settingsMatch = matchPath(this.props.location.pathname, {
      path: '/settings/:workspace',
    });
    const normalMatch = matchPath(this.props.location.pathname, {
      path: '/:workspace/:branch/',
    });

    return importMatch || settingsMatch || normalMatch;
  }

  handleProfileClick = () => {
    this.resetErrorState();
    browserHistory.push('/profile');
  };

  handleHomeClick = () => {
    this.resetErrorState();
    // if you are within a workspace, go to workspace root
    // otherwise go to workspace selection
    const routeMatch = this.getRouteMatch();
    const workspaceName = routeMatch ? `/${routeMatch.params.workspace}` : '';

    if (workspaceName) {
      browserHistory.push(`${workspaceName}/master/gis`);
    } else {
      browserHistory.push('/');
    }
  };

  resetErrorState = () => this.setState({ error: false, errorInfo: undefined })

  renderContents = () => {
    if (this.state.error) {
      return (
        <ErrorPage
          onButtonClick={this.handleHomeClick}
          errorInfo={this.state.errorInfo}
        />
      );
    }
    return this.props.children;
  };

  isLoginPage = () => this.props.location.pathname.includes('login');

  setStep = (curr) => {
    if (curr + 1 !== this.state.currentStep) this.setState({ currentStep: curr + 1 });
  }

  render() {
    const {
      authProvider,
      authEnabled,
      permissions,
      inEditMode,
      actions,
      themes,
      location,
      view,
      productDetails,
      alertMessage,
    } = this.props;
    const username = localStorage.getItem('username');
    const theme = themes.join(' ');
    const mainTheme = this.props.theme;
    const match = this.getRouteMatch();
    const workspace = match ? match.params.workspace : null;
    const branch = match ? match.params.branch : 'master';
    const onWorkspace = location.pathname.includes(`/${workspace}/${branch}/gis`);
    return (
      <ThemeContext.Provider value={mainTheme}>
        <IntlContextProvider workspace={workspace}>
          <WorkspaceSettingsContextProvider workspace={workspace} permissions={permissions}>
            <div className={`container text-center ${theme}`}>
              <div className={`top-nav ${theme}`}>
                <button className="top-nav__btn" onClick={this.handleHomeClick} type="button">
                  <img className="top-nav__img" alt="GridOS Logo" src="/gridOS-white-red.png" />
                </button>
                {!this.isLoginPage()
                  && (
                  <div className="top-nav--right">
                    { workspace && <ShareModal theme={theme} permissions={permissions} workspace={`/${workspace}`} /> }
                    { authEnabled && username
                      && (
                      <button className="username" onClick={this.handleProfileClick} type="button">
                        <i className="material-icons">account_circle</i>
                        <p className="username-name">{username}</p>
                      </button>
                      )}
                    <SettingsMenu
                      actions={actions}
                      authEnabled={authEnabled}
                      authProvider={authProvider}
                      permissions={permissions}
                      productDetails={productDetails}
                      theme={mainTheme}
                      showTour={() => this.setState({ isTourOpen: true })}
                      view={view}
                      inEditMode={inEditMode}
                      workspace={workspace}
                    />
                  </div>
                  )}
              </div>
              <div
                className={
                  classNames({
                    'core-layout__viewport': true,
                    'core-layout__viewport--dark': theme === 'dark',
                  })
                }
              >
                {alertMessage
                  && (
                  <AlertMessage
                    alertMessage={alertMessage}
                    clearAlert={actions.clearAlertMessage}
                  />
                  )}
                {this.renderContents()}
                {onWorkspace && view === 'gis' && !inEditMode && (
                  <Tour
                    steps={this.state.steps}
                    startAt={0}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={this.closeTour}
                    showNumber={false}
                    accentColor="#FFFFFF"
                    showCloseButton={false}
                    className={classNames({
                      top: arrowPosition.top.includes(this.state.currentStep),
                      left: arrowPosition.left.includes(this.state.currentStep),
                    })}
                    nextButton={<Button type="tour">Next</Button>}
                    prevButton={<Button type="tour">Prev</Button>}
                    getCurrentStep={this.setStep}
                    lastStepNextButton={<Button type="tour" onClick={this.closeTour}><b>Got it</b></Button>}
                    showNavigationNumber={false}
                    disableInteraction
                    closeWithMask={false}
                  />
                )}
              </div>
            </div>
          </WorkspaceSettingsContextProvider>
        </IntlContextProvider>
      </ThemeContext.Provider>
    );
  }
}

CoreLayout.defaultProps = {
  alertMessage: null,
};

CoreLayout.propTypes = {
  actions: PropTypes.shape({
    getProductDetails: PropTypes.func,
    clearAlertMessage: PropTypes.func,
  }).isRequired,
  alertMessage: PropTypes.object,
  authEnabled: PropTypes.bool.isRequired,
  authProvider: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  location: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  productDetails: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  themes: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
};

export default CoreLayout;
