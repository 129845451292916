import AssetInfo from './AssetInfo';

class WirePosition extends AssetInfo {
  MULTI = 'multi';

  get phase() {
    return this.attributes['WirePosition.phase'] || this.MULTI;
  }

  get xCoord() {
    return this.attributes['WirePosition.xCoord'] || 0;
  }

  get yCoord() {
    return this.attributes['WirePosition.yCoord'] || 0;
  }

  get x() { return this.xCoord; }

  get y() { return this.yCoord; }
}

export default WirePosition;
