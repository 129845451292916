import Inverter from './Inverter';

class Battery extends Inverter {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'Battery';
    this.icon = 'battery';
    this.displayName = this._class;
    this.inverterId = id;
  }

  get batteryId() {
    return this.references['PowerElectronicsConnection.PowerElectronicsUnit'];
  }

  get inverter() {
    return this.cimDict[this.inverterId];
  }

  minMaxEnergyBound(value, { minEnergy, maxEnergy }) {
    const valid = (value <= maxEnergy) && (value >= minEnergy);
    const message = 'Value must be between Minimum and Maximum Energy Level.';
    return {
      valid,
      message: !valid ? message : null,
    };
  }

  validationSchema = {
    ...Inverter.validationSchema,
    ratedEnergy: { min: 1, required: true },
    initialEnergy: {
      customValidation: this.minMaxEnergyBound,
      required: true,
    },
    finalEnergy: {
      customValidation: this.minMaxEnergyBound,
    },
    minEnergy: {
      min: 0,
      required: false,
      customValidation: (value, { maxEnergy }) => {
        const valid = value <= maxEnergy;
        const message = 'Value must be less than or equal to Maximum Energy Level.';
        return {
          valid,
          message: !valid ? message : null,
        };
      },
    },
    maxEnergy: {
      min: 0,
      required: false,
      customValidation: (value, { minEnergy }) => {
        const valid = minEnergy <= value;
        const message = 'Value must be greater than or equal to Minimum Energy Level.';
        return {
          valid,
          message: !valid ? message : null,
        };
      },
    },
    maxP: {
      min: 0,
      required: true,
    },
    minP: {
      min: 0,
      required: false,
    },
    initialP: {
      required: true,
      customValidation: (value, { sumMinP, sumMaxP }) => {
        const minP = this._attributeValue(this, 'minP');
        const maxP = this._attributeValue(this, 'maxP');
        const minLimit = minP ? Math.max(this.minP, sumMinP) : sumMinP;
        const maxLimit = maxP ? Math.min(this.maxP, sumMaxP) : sumMaxP;
        const GTEQMin = minLimit <= value;
        const LTEQMax = value <= maxLimit;
        const valid = GTEQMin && LTEQMax;
        const message = 'Value must be less than or equal to '
          + `${!GTEQMin ? 'negative Max Discharge' : ''}`
          + `${!GTEQMin && !LTEQMax ? 'and ' : ''}`
          + `${!LTEQMax ? 'Max Charge' : ''}`
          + ' of all battery units and the inverter.';
        return {
          valid,
          message: !valid ? message : null,
        };
      },
    },
    initialQ: {
      required: true,
      customValidation: (value, { minQ, maxQ }) => {
        const GTEQMin = minQ <= value;
        const LTEQMax = value <= maxQ;
        const valid = GTEQMin && LTEQMax;
        const message = 'Value must be less than or equal to '
          + `${!GTEQMin ? 'negative of Max Discharge' : ''}`
          + `${!GTEQMin && !LTEQMax ? 'and ' : ''}`
          + `${!LTEQMax ? 'Max Charge' : ''}`
          + ' of the inverter';
        return {
          valid,
          message: !valid ? message : null,
        };
      },
    },
  };
}

export default Battery;
