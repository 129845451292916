import React, { Fragment, PureComponent } from 'react';
import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  ReferenceLine,
  ReferenceArea,
  ComposedChart,
  Area,
} from 'recharts';
import { getNiceTickValues } from 'recharts-scale';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import { kV, pu } from 'helpers/units';
import { getStartOfInterval } from 'components/ZoomableRangeSlider/intervals';
import { toISO, extendDataTimeRange, addMissingTimepoints } from 'helpers/utils';
import ResultsChartCard from './ResultsChartCard';
import './TimeSeriesChart.scss';
import PhaseBadgeContainer from '../../templates/partials/PhaseBadgeContainer';

const leftAxisLabel = 'Voltage';
const leftAxisValueName = 'v_mag';
const rightAxisValueName = 'v_angle';
const DegreeUnit = '\u00B0';
const rightAxisLabel = 'Voltage Angle';
const definedPhases = ['ABC', 'A', 'B', 'C'];
const validUpperLimit = 1.06;
const validLowerLimit = 0.94;

class NodeVoltageTimeSeriesChart extends PureComponent {
  state = {
    activePhases: {
      A: true,
      B: false,
      C: false,
      ABC: false,
    },
    hovered: null,
    selectedUnit: kV,
  };

  componentDidMount() {
    this.setDefaultActivePhase(this.props.timeSeriesData);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id || prevProps.timeSeriesData !== this.props.timeSeriesData) {
      this.setDefaultActivePhase(this.props.timeSeriesData);
    }
  }

  phaseColors = () => {
    const theme = typeof this.context === 'string' ? this.context : 'dark';

    return {
      A: '#639DD1',
      B: '#2dafa8',
      C: '#fd813b',
      ABC: theme === 'dark' ? '#FFFFFF' : '#262626',
    };
  }

  setDefaultActivePhase = (data) => {
    const disabled = this.getDisabledPhases(data);
    const enabledPhase = ['A', 'B', 'C', 'ABC'].find(phase => !disabled.includes(phase));
    const activePhases = {
      A: false, B: false, C: false, ABC: false,
    };
    if (enabledPhase) {
      activePhases[enabledPhase] = true;
    } else {
      activePhases.A = true;
    }
    this.setState({ activePhases });
  }

  handleActivePhaseChange = (value) => {
    // activate the specified phase
    this.setState(prevState => ({ activePhases: { ...prevState.activePhases, ...value } }));
  }

  handleHoverChange = (value) => {
    this.setState({ hovered: value });
  }

  customTooltip = ({ payload, label }) => {
    const values = payload && payload[0] ? payload[0].payload : {};
    const { activePhases, selectedUnit } = this.state;
    const phaseColors = this.phaseColors();
    const hasValue = name => values[`${name}_ABC`] !== undefined
                             || values[`${name}_A`] !== undefined
                             || values[`${name}_B`] !== undefined
                             || values[`${name}_C`] !== undefined;

    const hasMag = hasValue('v_mag');
    const hasAngle = hasValue('v_angle');
    const hasMagRange = hasValue('v_mag_range');
    const hasAngleRange = hasValue('v_angle_range');
    const generateAggRows = phase => (
      <Fragment key={phase}>
        <tr><td>{phase}</td></tr>
        <tr>
          <td>{`${phase} Max:`}</td>
          {!!hasMagRange
            && <td style={{ color: phaseColors[phase] }}>{(values[`v_mag_range_${phase}`][1]).toFixed(3)}</td>}
          {!!hasAngleRange
            && <td style={{ color: phaseColors[phase] }}>{(values[`v_angle_range_${phase}`][1]).toFixed(2)}</td>}
        </tr>
        <tr>
          <td>{`${phase} Average:`}</td>
          {!!hasMag
            && <td style={{ color: phaseColors[phase] }}>{(values[`v_mag_${phase}`]).toFixed(3)}</td>}
          {!!hasAngle
            && <td style={{ color: phaseColors[phase] }}>{(values[`v_angle_${phase}`]).toFixed(2)}</td>}

        </tr>
        <tr>
          <td>{`${phase} Min:`}</td>
          {!!hasMagRange
            && <td style={{ color: phaseColors[phase] }}>{(values[`v_mag_range_${phase}`][0]).toFixed(3)}</td>}
          {!!hasAngleRange
            && <td style={{ color: phaseColors[phase] }}>{(values[`v_angle_range_${phase}`][0]).toFixed(2)}</td>}

        </tr>
      </Fragment>
    );
    const generateRawRows = phase => (
      <tr key={phase}>
        <td>{phase}</td>
        {!!hasMag
          && <td style={{ color: phaseColors[phase] }}>{(values[`v_mag_${phase}`]).toFixed(3)}</td>}
        {!!hasAngle
          && <td style={{ color: phaseColors[phase] }}>{(values[`v_angle_${phase}`]).toFixed(2)}</td>}
      </tr>
    );
    const getFormatString = (aggregation) => {
      switch (aggregation) {
        case 'day':
          return 'YYYY/MM/DD';
        case 'month':
          return 'YYYY/MM';
        case 'year':
          return 'YYYY';
        case 'hour':
        default:
          return 'YYYY/MM/DD HH:mm';
      }
    };

    const isAggregated = values.aggregation !== 'none';
    const phases = Object.keys(activePhases).filter(key => activePhases[key]);

    return (
      <div className="tooltip">
        <p>{moment.parseZone(label).format(getFormatString(values.aggregation))}</p>
        <table>
          <thead>
            <tr>
              {hasMag && (
              <td colSpan="2">
                {leftAxisLabel}
                {' '}
                (
                {selectedUnit}
                )
              </td>
              )}
              {hasAngle && (
              <td colSpan="2">
                {rightAxisLabel}
                {' '}
                (
                {DegreeUnit}
                )
              </td>
              )}
            </tr>
          </thead>
          <tbody>
            {phases.map(activePhase => ((values[`v_mag_${activePhase}`] !== undefined || values[`v_angle_${activePhase}`] !== undefined) && isAggregated
              ? generateAggRows(activePhase)
              : generateRawRows(activePhase)))}
          </tbody>
        </table>
      </div>
    );
  }

  generateLine = (phase, variable, axis) => (
    <Line
      key={`${variable}_${phase}_${axis}`}
      yAxisId={axis}
      name={`${variable}_${phase}`}
      stroke={this.state.activePhases[phase] ? this.phaseColors()[phase] : null}
      strokeDasharray={axis[0] === 'l' ? '5 5' : null}
      strokeWidth={this.state.hovered === phase ? 3 : 1}
      dataKey={`${variable}_${phase}`}
      isAnimationActive={false}
      dot={false}
      type="stepAfter"
    />
  );

  generateArea = (phase, variable, axis) => (
    <Area
      key={`area_${phase}`}
      yAxisId={axis}
      name={`${variable}_range_${phase}`}
      stroke="none"
      fill={this.state.activePhases[phase] ? 'url(#violationsGradient)' : null}
      fillOpacity={0.25}
      dataKey={`${variable}_range_${phase}`}
      isAnimationActive={false}
      type="stepAfter"
    />
  );

  gradientOffset = (data) => {
    const activePhase = Object.keys(this.state.activePhases)
      .find(phase => this.state.activePhases[phase] === true);

    const vMagValues = data.filter(entry => entry[`v_mag_range_${activePhase}`]);

    if (!vMagValues || !vMagValues.length) {
      return [0, 1];
    }

    const isPU = this.state.selectedUnit === pu;

    const getValues = index => data.reduce((values, point) => (point[`v_mag_range_${activePhase}`] ? [...values, point[`v_mag_range_${activePhase}`][index]] : values), []);
    const dataMax = Math.max(...getValues(1));
    const dataMin = Math.min(...getValues(0));

    const size = dataMax - dataMin;
    const baseVoltage = this.props.baseVoltage / 1000;

    const validMax = isPU ? (this.props.maxV || validUpperLimit) : baseVoltage * validUpperLimit;
    const validMin = isPU ? (this.props.minV || validLowerLimit) : baseVoltage * validLowerLimit;

    const distanceFromMax = (dataMax - validMax) / size;
    const distanceFromMin = (validMin - dataMin) / size;
    return [distanceFromMax, 1 - distanceFromMin];
  }

  xAxisFormatter = (aggregation) => {
    switch (aggregation) {
      case 'day':
        return 'YYYY/MM/DD';
      case 'month':
        return 'YYYY/MM';
      case 'year':
        return 'YYYY';
      case 'hour':
      default:
        return 'HH:mm';
    }
  };

  getDisabledPhases() {
    return definedPhases.filter(phase => !this.props.timeSeriesData.some(timePoint => `${leftAxisValueName}_${phase.toLowerCase()}_avg` in timePoint
        || `${rightAxisValueName}_${phase.toLowerCase()}_avg` in timePoint));
  }

  getLegendColour(disabledPhases) {
    const activePhases = ['A', 'B', 'C'].filter(phase => this.state.activePhases[phase] && !disabledPhases.includes(phase));
    const phaseColors = this.phaseColors();
    return activePhases.length === 1 ? phaseColors[activePhases[0]] : phaseColors.ABC;
  }

  puMax = (dataMax) => {
    if (dataMax === Infinity || dataMax === -Infinity) {
      return 1.1;
    }
    const niceTicks = getNiceTickValues([0.9, dataMax]);
    return Math.max(niceTicks[niceTicks.length - 1], 1.1);
  };

  puMin = (dataMin) => {
    if (dataMin === Infinity || dataMin === -Infinity) {
      return 0.9;
    }
    const niceTicks = getNiceTickValues([dataMin, 1.1]);
    return Math.min(niceTicks[0], 0.9);
  };

  graphData(label, useFormatter) {
    const valueFormatter = (value) => {
      if (!value) {
        return value;
      }
      if (this.state.selectedUnit === pu) {
        return (value / this.props.baseVoltage);
      }
      return (value / 1000);
    };

    const { maxRange, timeSeriesData } = this.props;
    let aggregation;

    let data = timeSeriesData.map((e) => {
      const newE = {
        timepoint: e.timepoint,
        aggregation: e.aggregation,
      };
      if (!aggregation) {
        aggregation = e.aggregation !== 'none' ? e.aggregation : 'hour';
      }
      const mapPhaseData = (phase, axisValueName) => {
        const lowerPhase = phase.toLowerCase();
        const avg = useFormatter ? valueFormatter(e[`${axisValueName}_${lowerPhase}_avg`]) : e[`${axisValueName}_${lowerPhase}_avg`];
        const min = useFormatter ? valueFormatter(e[`${axisValueName}_${lowerPhase}_min`]) : e[`${axisValueName}_${lowerPhase}_min`];
        const max = useFormatter ? valueFormatter(e[`${axisValueName}_${lowerPhase}_max`]) : e[`${axisValueName}_${lowerPhase}_max`];
        newE[`${axisValueName}_${phase}`] = avg;
        newE[`${axisValueName}_range_${phase}`] = min !== undefined && max !== undefined ? [min, max] : undefined;
      };
      if (this.state.activePhases.A) {
        mapPhaseData('A', label);
      }
      if (this.state.activePhases.B) {
        mapPhaseData('B', label);
      }
      if (this.state.activePhases.C) {
        mapPhaseData('C', label);
      }
      return newE;
    });

    data = maxRange ? extendDataTimeRange(data, 'timepoint', maxRange.start, moment(maxRange.end).startOf(aggregation)) : data;
    data = addMissingTimepoints(data, 'timepoint');
    return data;
  }

  render() {
    const { activePhases, selectedUnit } = this.state;
    const {
      loading, timeSeriesData, highlightRange, timeBarZoomLevel,
    } = this.props;
    const phaseColors = this.phaseColors();
    const theme = typeof this.context === 'string' ? this.context : 'dark';
    const disabledPhases = this.getDisabledPhases();
    const legendColour = this.getLegendColour(disabledPhases);
    const magData = this.graphData(leftAxisValueName, true);
    const angleData = this.graphData(rightAxisValueName, false);
    const magGradientBreaks = this.gradientOffset(magData);

    const highlightStart = toISO(highlightRange.start);
    const highlightEnd = toISO(getStartOfInterval(highlightRange.end, timeBarZoomLevel));
    let cardState = 'initial';
    if (loading) {
      cardState = 'loading';
    } else if (timeSeriesData.length > 0) {
      cardState = 'loaded';
    }

    return (
      <ResultsChartCard
        title="Voltages"
        className="time-series-chart"
        theme={theme}
        state={cardState}
      >
        <div className="legend" style={{ color: legendColour }}>
          <div className="legend-entry">
            <div
              className="left-axis-box axis-box-solid"
              style={{ borderTopColor: legendColour }}
            />
            <div>
              {leftAxisLabel}
              {' '}
              (LL)
            </div>
          </div>
        </div>
        <div className="unit-label-row">
          <Select
            theme={theme}
            clearable={false}
            searchable={false}
            width={100}
            options={[{ value: kV, label: kV }, { value: pu, label: pu }]}
            value={selectedUnit}
            onChange={e => this.setState({ selectedUnit: e.value })}
          />
        </div>
        <div
          className={classNames({
            'chart-pane': true,
            'chart-pane--expanded': this.props.expanded,
          })}
        >
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart syncId="voltageTimeChart" data={magData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
              <XAxis
                yAxisId="taxis"
                dataKey="timepoint"
                stroke="#949899"
                scale="point"
                tick={false} // dont show any ticks on the upper graph
                tickFormatter={val => moment.parseZone(val).format(this.xAxisFormatter(magData[0].aggregation))}
              />
              <YAxis
                yAxisId="maxis"
                orientation="left"
                tickLine={false}
                width={50}
                stroke="#949899"
                tickFormatter={val => (val ? val.toFixed(3) : val)}
                domain={selectedUnit === pu ? [this.puMin, this.puMax] : ['auto', 'auto']}
              />
              <ReferenceLine
                yAxisId="maxis"
                y={
                  selectedUnit === pu
                    ? this.props.maxV || validUpperLimit
                    : (this.props.baseVoltage / 1000) * validUpperLimit
                }
                stroke="red"
              />
              <ReferenceLine
                yAxisId="maxis"
                y={
                  selectedUnit === pu
                    ? this.props.minV || validLowerLimit
                    : (this.props.baseVoltage / 1000) * validLowerLimit
                }
                stroke="red"
              />
              <defs>
                <linearGradient id="violationsGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset={0} stopColor="red" stopOpacity={1} />
                  <stop offset={magGradientBreaks[0]} stopColor="red" stopOpacity={1} />
                  <stop offset={magGradientBreaks[0]} stopColor={legendColour} stopOpacity={1} />
                  <stop offset={magGradientBreaks[1]} stopColor={legendColour} stopOpacity={1} />
                  <stop offset={magGradientBreaks[1]} stopColor="red" stopOpacity={1} />
                  <stop offset={1} stopColor="red" stopOpacity={1} />
                </linearGradient>
              </defs>
              <Tooltip content={this.customTooltip} />
              +
              {definedPhases.map(ph => this.generateLine(ph, 'v_mag', 'maxis'))}
              {definedPhases.map(ph => this.generateArea(ph, 'v_mag', 'maxis'))}
              {highlightStart === highlightEnd ? (
                <ReferenceLine
                  yAxisId="maxis"
                  x={highlightStart}
                  stroke="teal"
                />
              ) : (
                <ReferenceArea
                  yAxisId="maxis"
                  x1={highlightStart}
                  x2={highlightEnd}
                  ifOverflow="visible"
                  fillOpacity={0.3}
                  fill="teal"
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="legend" style={{ color: legendColour }}>
          <div className="legend-entry">
            <div
              className="right-axis-box axis-box-solid"
              style={{ borderTopColor: legendColour }}
            />
            <div>
              {rightAxisLabel}
              {' '}
              (LL)
            </div>
          </div>
        </div>
        <div className="unit-label-row">
          <div>
            (
            {DegreeUnit}
            )
          </div>
        </div>
        <div
          className={classNames({
            'chart-pane': true,
            'chart-pane--expanded': this.props.expanded,
          })}
        >
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart syncId="voltageTimeChart" data={angleData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
              <XAxis
                yAxisId="taxis"
                dataKey="timepoint"
                stroke="#949899"
                scale="point"
                tickFormatter={val => moment.parseZone(val).format(this.xAxisFormatter(angleData[0].aggregation))}
              />
              <YAxis
                yAxisId="aaxis"
                orientation="left"
                stroke="#949899"
                tickLine={false}
                width={50}
                domain={['auto', 'auto']}
                tickFormatter={val => (val ? val.toFixed(2) : val)}
              />
              <Tooltip content={this.customTooltip} />
              {definedPhases.map(ph => this.generateLine(ph, 'v_angle', 'aaxis'))}
              {definedPhases.map(ph => this.generateArea(ph, 'v_angle', 'aaxis'))}
              {highlightStart === highlightEnd ? (
                <ReferenceLine
                  yAxisId="aaxis"
                  x={highlightStart}
                  stroke="teal"
                />
              ) : (
                <ReferenceArea
                  yAxisId="aaxis"
                  x1={highlightStart}
                  x2={highlightEnd}
                  ifOverflow="visible"
                  fillOpacity={0.3}
                  fill="teal"
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <PhaseBadgeContainer
          activePhases={activePhases}
          disabledPhases={disabledPhases}
          phaseColors={phaseColors}
          onActivePhaseChange={this.handleActivePhaseChange}
          onHoverChange={this.handleHoverChange}
        />
      </ResultsChartCard>
    );
  }
}

NodeVoltageTimeSeriesChart.contextType = ThemeContext;

NodeVoltageTimeSeriesChart.defaultProps = {
  timeSeriesData: [],
  highlightRange: { start: moment(), end: moment() },
  maxRange: { start: moment(), end: moment().add(23, 'h') },
  minV: undefined,
  maxV: undefined,
};

NodeVoltageTimeSeriesChart.propTypes = {
  timeSeriesData: PropTypes.array,
  baseVoltage: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  highlightRange: PropTypes.object,
  maxRange: PropTypes.object,
  minV: PropTypes.number,
  maxV: PropTypes.number,
  expanded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
};

export default NodeVoltageTimeSeriesChart;
