import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from 'store/appState';
import { validationActions } from '../modules/validation';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ValidationPanel from '../components/SlidingInfoPanel/ValidationPanel/ValidationPanel';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...validationActions,
    displayAlertMessage: actions.displayAlertMessage,
  }, dispatch),
});

const mapStateToProps = state => ({
  validation: state.validation,
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationPanel);
