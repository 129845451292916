import React, { FunctionComponent, ReactNode, ReactChild } from 'react';

import './GridLayout.scss';

type GridLayoutProps = {
  className?: string;
  renderHeaderContent?: () => ReactNode;
  children?: ReactChild|ReactNode,
  showHeader?: boolean;
};

const voidFunction = () => null;

const GridLayout: FunctionComponent<GridLayoutProps> = ({
  className = '',
  renderHeaderContent = voidFunction,
  showHeader = false,
  children = null,
}) => (
  <div className="grid-layout">
    {showHeader && (
      <div className="grid-layout-header">
        {renderHeaderContent()}
      </div>
    )}
    <div className={`grid-layout-contents ${className}`}>
      {children}
    </div>
  </div>
);

export default GridLayout;
