import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import ExpandableSection from 'components/ExpandableSection';
import useTrackingState from 'hooks/useTrackingState';
import ThemeContext from 'helpers/ThemeContext';
import { isValidValue, isDefined } from 'helpers/utils';
import EquipmentInfoSelector from './containers/EquipmentInfoSelectorContainer';
import UnitField from './UnitField';

import './PowerElectronicsUnit.scss';

const PowerElectronicsUnit = ({
  unit,
  fields,
  type,
  name,
  position,
  disabled,
  editable,
  canDelete,
  validationSchema,
  handleDelete,
  infos,
  eqLibLink,
  open,
  handleSave,
  container,
}) => {
  const theme = useContext(ThemeContext);
  const [attributes, setAttributes] = useTrackingState(unit);
  const unitInfo = unit.unit_info;
  const handleDeleteClick = () => handleDelete(type, unit.id);
  const mappedUnitInfoOptions = [
    {
      value: unitInfo?.id,
      label: unitInfo?.name ?? 'Select Unit Type',
      disabled: true,
    },
    ...infos?.filter(unitType => unitType.id !== unitInfo?.id)?.map(
      unitType => ({ value: unitType.id, label: unitType.name || unitType.id }),
    ),
  ];
  const onInfoChange = option => handleSave({ unit_info: option?.value ?? null });
  return (
    <ExpandableSection
      className="unit-section"
      open={open}
      renderHeaderContent={() => (
        <span className="unit-header">
          {`${name} ${position}`}
          {editable && (
            <IconButton
              icon="delete"
              tooltip={canDelete ? `Delete ${name}` : `Must have at least one ${name}`}
              onClick={handleDeleteClick}
              theme={theme}
              disabled={!canDelete || disabled}
            />
          )}
        </span>
      )}
    >
      {(infos || unitInfo) && (
        <EquipmentInfoSelector
          id="power-electoric-unit-select"
          onChange={onInfoChange}
          loadOptions={() => mappedUnitInfoOptions}
          defaultOptions={mappedUnitInfoOptions}
          eqLibURL={eqLibLink}
          value={unitInfo?.id}
          disabled={disabled}
          assetId={unit.id}
          feeder={container.id}
        />
      )}
      <div className="unit-fields-section">
        {fields.map((fieldObj) => {
          const { id: field, infoId: infoField = field } = fieldObj;
          const value = attributes[field];
          const canCopy = unitInfo
            && isDefined(unitInfo[infoField])
            && isValidValue(unitInfo[infoField], validationSchema, attributes)
            && unit[field] !== unitInfo[infoField];
          const canClear = attributes[field] || attributes[field] === 0;

          return (
            <UnitField
              {...fieldObj}
              {...attributes}
              key={field}
              htmlFor={`${field}-${unit.id}`}
              id={field}
              field={fieldObj}
              handleSave={() => {
                if (attributes[field] !== unit[field] && attributes[field] !== '') {
                  handleSave({ attributes: { [field]: value } });
                }
              }}
              disabled={disabled}
              editable={editable}
              onCopy={canCopy ? () => handleSave(
                { attributes: { [field]: unit.unit_info[infoField] } },
              ) : null}
              onClear={canClear ? () => handleSave(
                { attributes: { [field]: null } },
              ) : null}
              edited={attributes[field] !== unit[field]}
              validationSchema={validationSchema[field]}
              updateValue={(val) => setAttributes({ ...attributes, [field]: val })}
              value={value}
              theme={theme}
            />
          );
        })}
      </div>
    </ExpandableSection>
  );
};

PowerElectronicsUnit.defaultProps = {
  disabled: true,
  canDelete: false,
  editable: false,
  validationSchema: {},
  eqLibLink: '',
  infos: [],
  open: false,
};

PowerElectronicsUnit.propTypes = {
  disabled: PropTypes.bool,
  canDelete: PropTypes.bool,
  editable: PropTypes.bool,
  unit: PropTypes.object.isRequired,
  validationSchema: PropTypes.object,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired, // Input config
  type: PropTypes.string.isRequired, // Back End Type
  name: PropTypes.string.isRequired, // User facing name,
  infos: PropTypes.array,
  eqLibLink: PropTypes.string,
  open: PropTypes.bool,
  position: PropTypes.number.isRequired,
  handleSave: PropTypes.func.isRequired,
  container: PropTypes.object.isRequired,
};

export default PowerElectronicsUnit;
