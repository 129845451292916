import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpandableSection from 'components/ExpandableSection';
import Input from 'components/Input';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Select from 'components/Select';
import cloneDeep from 'lodash/cloneDeep';
import {
  kW, kWh, kVA, scaleFactorForUnit, percent,
} from 'helpers/units';

import { alphabetizeByKey } from 'helpers/utils';

import './UsagePointSection.scss';

const phaseOptions = [
  { label: 'ABC', value: 'ABC' },
  { label: 'A', value: 'A' },
  { label: 'AB', value: 'AB' },
  { label: 'AC', value: 'AC' },
  { label: 'B', value: 'B' },
  { label: 'BC', value: 'BC' },
  { label: 'C', value: 'C' },
];
const usagePointOptions = [
  { label: 'None', value: 'none' },
  { label: 'Curtailing', value: 'curtailing' },
  { label: 'Shifting', value: 'shifting' },
];

const extractUsagePoints = usagePoints => (
  // sort by phaseCode then by id and reverse that to display new row in last with stable sort order
  alphabetizeByKey(
    alphabetizeByKey(usagePoints ? cloneDeep(usagePoints) : [], 'id'),
    'phaseCode',
  )
);

class UsagePointSection extends Component {
  state = {
    usagePoints: extractUsagePoints(this.props.usagePoints),
  }

  componentDidUpdate(prevProps) {
    if (this.props.usagePoints !== prevProps.usagePoints) {
      this.setState({ usagePoints: extractUsagePoints(this.props.usagePoints) });
    }
  }

  compareOptionValues = (optionString, validOptions) => {
    const optionArray = optionString.split('');
    const phaseArray = validOptions.split('');
    return !optionArray.every(phase => phaseArray.includes(phase));
  }

  generateOptions = (options, validOptions) => {
    const phaseOpt = options.map(opt => ({
      label: opt.label,
      value: opt.value,
      disabled: validOptions ? this.compareOptionValues(opt.value, validOptions) : false,
    }));
    return phaseOpt;
  }

  updateRowValue = (index, attr, val, key) => {
    this.setState(prevState => {
      let usagePointObjects = [...prevState.usagePoints];
      if (key) {
        if (index === 'all') {
          usagePointObjects = usagePointObjects.map((usagePoint) => (
            { ...usagePoint, demandResponseProgram: { ...usagePoint.demandResponseProgram, [key]: val } }
          ));
        } else {
          usagePointObjects[index].demandResponseProgram[key] = val;
        }
      } else {
        usagePointObjects[index][attr] = val;
      }
      return { usagePoints: usagePointObjects };
    });
  }

  usagePointInputFields = (point, label, unit, type, demandResponseType, key) => (
    <div className="usage-point-row" key={`${point.index}${type}`}>
      <div className="usage-point-column">
        <Input
          inputStyle="panel"
          id={`${point.id ? point.id : 'addNew'}-${type}`}
          label={label}
          type="number"
          value={point?.[type]}
          onChange={e => this.updateRowValue(point.index, type, e, key)}
          theme={this.props.theme}
          disabled={point.delete || this.props.disabled}
          onBlur={({ target }) => {
            const value = target.getAttribute('realValue');
            const attr = demandResponseType ? {
              demandResponseProgram:
              { program_type: demandResponseType, [key]: value },
            }
              : { attributes: { [type]: value } };
            this.props.actions.editUsagePoint(
              point.id,
              attr,
            );
          }}
          name={type}
          validation={{
            min: 0,
            required: true,
            ...(unit === percent && { max: 100 }),
          }}
          options={{
            divisor: unit !== percent ? scaleFactorForUnit(unit) : 1,
            precision: 3,
          }}
          unit={unit}
        />
      </div>
    </div>
  );

  render() {
    const usagePoints = this.state.usagePoints.map((point, index) => ({
      index,
      id: point.id ? point.id : null,
      phaseCode: point.phaseCode,
      ratedPower: point.ratedPower,
      usagePointType: point.demandResponseProgram?.program_type || null,
      realPower: point.demandResponseProgram?.maxPCurtailment ?? 0,
      energy: point.demandResponseProgram?.maxDailyEnergyCurtailment ?? 0,
      curtailment: point.demandResponseProgram?.maxPCurtailmentPercent ?? 0,
      delete: point.delete,
    }));
    const { theme, disabled } = this.props;
    const usagePointType = usagePoints.length > 0 ? usagePoints[0].usagePointType : null;
    return (
      <>
        <div className="unit-section-header">
          <h2 className="title-text">
            Usage Points (
            {usagePoints.length}
            )
          </h2>
          <div className="usage-point-add">
            <Button
              disabled={disabled}
              id="add-new-button"
              theme={this.props.theme}
              label="Add"
              onClick={() => this.props.actions.addNewInstance('usage_point', this.props.assetId, { demandResponseProgram: { program_type: usagePointType } })}
            />
          </div>
        </div>
        <div className={`${theme} usage-point`}>
          { usagePoints.length > 0 && (
            <div className="usage-point-row">
              <div className="usage-point-column">
                Usage Point Type
              </div>
              <div className="usage-point-column usage-point-column--select">
                <Select
                  id="usage-point-type"
                  theme={theme}
                  options={this.generateOptions(usagePointOptions)}
                  value={usagePointType}
                  onChange={(e) => {
                    this.updateRowValue('all', 'usagePointType', e.value, 'program_type');
                    this.state.usagePoints.map((usagePoint) => this.props.actions.editUsagePoint(
                      usagePoint.id,
                      { demandResponseProgram: { program_type: e.value } },
                    ));
                  }}
                  searchable={false}
                  clearable={false}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
          {usagePoints && alphabetizeByKey(usagePoints, 'name').map(point => (
            <ExpandableSection
              key={`${point.index}`}
              renderHeaderContent={() => (
                <span className="unit-header">
                  {`Usage Point ${point.index + 1}`}
                  <IconButton
                    theme={theme}
                    icon="delete"
                    onClick={() => this.props.actions.deleteReference('usage_point', point.id)}
                    disabled={(
                      point.delete
                      || usagePoints.length === 1
                      || disabled
                    )}
                    title="delete"
                  />
                </span>
              )}
            >
              <div className="usage-point-section-row">
                <div className="usage-point-row" key={`${point.index} phases`}>
                  <div className="usage-point-column">
                    Phases
                  </div>
                  <div className="usage-point-column usage-point-column--select">
                    <Select
                      id={`${point.id ? point.id : 'addNew'}-phases`}
                      theme={theme}
                      options={this.generateOptions(phaseOptions, this.props.phases)}
                      value={point.phaseCode}
                      onChange={(e) => {
                        this.updateRowValue(point.index, 'phaseCode', e.value);
                        this.props.actions.editUsagePoint(
                          point.id,
                          { attributes: { phaseCode: e.value } },
                        );
                      }}
                      searchable={false}
                      clearable={false}
                      disabled={point.delete || disabled}
                    />
                  </div>
                </div>
                {this.usagePointInputFields(point, 'Rated Power', kVA, 'ratedPower')}
                { (point.usagePointType === 'curtailing')
                    && (
                    <>
                      {this.usagePointInputFields(point, 'Real Power', kW, 'realPower', point.usagePointType, 'maxPCurtailment')}
                      {this.usagePointInputFields(point, 'Energy', kWh, 'energy', point.usagePointType, 'maxDailyEnergyCurtailment')}
                      {this.usagePointInputFields(point, 'Curtailment', percent, 'curtailment', point.usagePointType, 'maxPCurtailmentPercent')}
                    </>
                    )}
              </div>
            </ExpandableSection>
          ))}
        </div>
      </>
    );
  }
}

UsagePointSection.defaultProps = {
  disabled: false,
  usagePoints: [],
};

UsagePointSection.propTypes = {
  actions: PropTypes.shape({
    deleteReference: PropTypes.func,
    editUsagePoint: PropTypes.func,
    addNewInstance: PropTypes.func,
  }).isRequired,
  assetId: PropTypes.string.isRequired,
  usagePoints: PropTypes.array,
  phases: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default UsagePointSection;
