import React from 'react';
import SynchronousMachine from './SynchronousMachine';

// Creates a value template for CHP instances
// Uses SynchronousMachine as that template includes all CHP values
const CHP = props => (
  <SynchronousMachine {...props} />
);

export default CHP;
