/* eslint-disable react/display-name */
import React from 'react';
import Button from 'components/Button';

export const arrowPosition = {
  top: [1, 3, 4, 5],
  left: [2],
};

export const tourStyle = {
  backgroundColor: '#1675EB',
  color: '#ffffff',
  borderRadius: '3px',
};

export const createSteps = closeTour => ([
  {
    selector: '#branch-selector-container',
    content: () => (
      <div>
        <p style={{ fontWeight: '500' }}>You can manage your network here</p>
        <br />
        <p>
          Click on the
          <span style={{ fontWeight: '500', fontSize: '18px' }}>&nbsp;+&nbsp;</span>
          {' '}
          to copy the current network into a new version and make changes
          without impacting your original network model. There is no upper limit on how many
          versions can be created.
        </p>
        <div className="tour-nav">
          <Button type="text" className="skip-btn" onClick={closeTour}>Skip tour</Button>
        </div>
      </div>
    ),
    position: 'bottom',
    style: tourStyle,
  }, {
    selector: '#network-left-rail',
    content: () => (
      <div>
        <p style={{ fontWeight: '500' }}>Network and asset panel</p>
        <br />
        <p>
          View your list of network models and assets here.  Check the checkboxes to view
          them visually on the map.
        </p>
        <div className="tour-nav">
          <Button type="text" className="skip-btn" onClick={closeTour}>Skip tour</Button>
        </div>
      </div>
    ),
    position: 'right',
    style: tourStyle,
  }, {
    selector: '#scenario-selector-container',
    content: () => (
      <div>
        <p style={{ fontWeight: '500' }}>Have multiple scenarios for your network</p>
        <br />
        <p>
          For each network version, you can have multiple scenarios allowing for comparison.
          When a network version is copied, all the scenarios get copied along with it.
        </p>
        <div className="tour-nav">
          <Button type="text" className="skip-btn" onClick={closeTour}>Skip tour</Button>
        </div>
      </div>
    ),
    position: 'bottom',
    style: tourStyle,
  }, {
    selector: '#analysis-selector-container',
    content: () => (
      <div>
        <p style={{ fontWeight: '500' }}>Run and view analyses</p>
        <br />
        <p>
          For each network version and scenario, you can have multiple analyses allowing
          for comparison.
          Click on the
          <span style={{ fontWeight: '500', fontSize: '18px' }}>&nbsp;+&nbsp;</span>
          to create a new analysis or select from the dropdown to view existing results.
        </p>
        <div className="tour-nav">
          <Button type="text" className="skip-btn" onClick={closeTour}>Skip tour</Button>
        </div>
      </div>
    ),
    position: 'bottom',
    style: tourStyle,
  }, {
    selector: '#asset-panel-icons',
    content: () => (
      <div>
        <p style={{ fontWeight: '500' }}>Right Panel Tabs</p>
        <br />
        <p>
          Select different icons to view various information about your network such as
          analyses results, asset info, and more.
        </p>
      </div>
    ),
    position: 'bottom',
    style: tourStyle,
  },
]);
