import React, {
  useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import classNames from 'classnames';
import Button from 'components/Button';
import CustomScrollBar from 'components/CustomScrollBar';
import ThemedTabs from 'components/ThemedTabs';
import { useAttachments } from 'contexts/AttachmentsContext';
import Allocation from 'routes/WorkspaceLayout/routes/Network/containers/AllocationContainer';
import NotesPanel from 'components/Notes';

import AssetSchedule from '../AssetPanel/templates/partials/AssetSchedule';
import AnalysisResultsSection from './components/AnalysisResultsSection';
import ActivityModal from '../ActivityLogPanel/ActivityModal';
import BulkLifecycleUpload from './components/BulkLifecycleUpload';
import SubstationSelect from './containers/SubstationSelect';
import { ANALYSIS_TYPES, getAllBatterySizingResults } from '../../../helpers/NetworkHelpers';

import './FeederPanel.scss';

const FeederPanel = ({
  anyResults,
  containers,
  selectedContainers,
  selectedAnalysis,
  selectedScenario,
  selectedScenarioType,
  workspace,
  analysisActive,
  permissions,
  authEnabled,
  branch,
  downloadingPFReport,
  downloadingHCReport,
  downloadingCTReport,
  theme,
  inEditMode,
  getResultsTooltip,
  downloadPowerFlowReport,
  downloadHostingCapacityReport,
  downloadCostTrackingReport,
  isValidHCReportTimeRange,
  expanded,
  fetchNodeSubstationDistances,
  refreshNetworkData,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  selectedAssetID,
  selectedAssetViewModelClass,
  containerInAnalysis,
  updateSelectedScenario,
}) => {
  const { notes } = useAttachments();
  const [modalActive, toggleModal] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [batteryResults, setBatteryResults] = useState(null);
  const updateScenario = useCallback(
    () => updateSelectedScenario(workspace, branch, selectedScenario, selectedScenarioType),
    [workspace, branch, selectedScenario, selectedScenarioType, updateSelectedScenario],
  );

  const feeder = selectedContainers[0];
  const feeders = containers.map(e => e.id);
  async function fetchBatteryAnalysisResults() {
    setResultsLoading(true);
    try {
      const rawBatteryResults = await getAllBatterySizingResults(
        workspace,
        branch,
        feeders,
        selectedScenario,
        selectedAnalysis.name,
      );
      setBatteryResults(rawBatteryResults);
      setResultsLoading(false);
    } catch (err) {
      setResultsLoading(false);
    }
  }
  useEffect(() => {
    fetchNodeSubstationDistances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeder.id]);
  useEffect(() => {
    if (selectedAnalysis && selectedAnalysis.type === ANALYSIS_TYPES.BATTERY_SIZING) {
      fetchBatteryAnalysisResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, selectedAnalysis && [selectedAnalysis?.id]);

  const canAccessNotes = !authEnabled || permissions.has('view_asset_notes');
  const substations = containers.filter(c => c.type === 'Substation');
  let analysisRunning = false;
  if (selectedAnalysis) {
    analysisRunning = analysisActive(branch, selectedScenario, selectedAnalysis.name);
  }
  return (
    <div className="feeder-content">
      {selectedContainers.length === 1 && (
        <ThemedTabs
          theme={theme}
          tabs={[
            {
              disabled: false,
              name: 'General',
            },
            {
              disabled: !canAccessNotes,
              name: `Notes${notes && notes.length ? ` (${notes.length})` : ''}`,
              permissionTipOptions: {
                title: 'Asset Notes',
                placement: 'left',
                hide: canAccessNotes,
              },
            },
          ]}
        >
          {TabPanel => [
            <TabPanel key="Tab-Panel-1">
              <CustomScrollBar>
                <div
                  className={classNames({
                    'container-panel-tab-contents': true,
                    'container-panel-tab-contents--expanded': expanded,
                  })}
                >
                  <SubstationSelect
                    feeder={feeder}
                    substations={substations}
                    disabled={!inEditMode}
                  />

                  <Allocation workspace={workspace} feeder={feeder} permissions={permissions} />

                  <h3>Reports</h3>
                  {!inEditMode && (
                    <>
                      <div className="powerflow-btn-row">
                        <p>Powerflow</p>
                        <Button
                          disabled={
                            !selectedAnalysis
                            || !anyResults
                            || analysisRunning
                            || downloadingPFReport
                            || !containerInAnalysis
                            || ![
                              ANALYSIS_TYPES.POWERFLOW,
                              ANALYSIS_TYPES.QSTS,
                              ANALYSIS_TYPES.QSTS_OPF,
                            ].includes(selectedAnalysis.type)
                          }
                          onClick={downloadPowerFlowReport}
                          loading={downloadingPFReport}
                          iconPath="/download.svg"
                          label="Download"
                          className="report-download-btn"
                          title={getResultsTooltip(anyResults, analysisRunning, 'Powerflow')}
                          theme={theme}
                        />
                      </div>
                      <div className="hosting-capacity-btn-row">
                        <p>Hosting Capacity</p>
                        <Button
                          onClick={downloadHostingCapacityReport}
                          disabled={
                            !selectedAnalysis
                            || !anyResults
                            || analysisRunning
                            || downloadingHCReport
                            || !containerInAnalysis
                            || !isValidHCReportTimeRange()
                            || selectedAnalysis.type !== ANALYSIS_TYPES.HOSTING_CAPACITY
                          }
                          iconPath="/download.svg"
                          label="Download"
                          loading={downloadingHCReport}
                          className="report-download-btn"
                          title={getResultsTooltip(
                            anyResults,
                            analysisRunning,
                            'Hosting capacity',
                            isValidHCReportTimeRange(),
                          )}
                          theme={theme}
                        />
                      </div>
                      <div className="battery-sizing-btn-row">
                        <p>Battery Sizing</p>
                        <Button
                          onClick={() => {
                            toggleModal(true);
                          }}
                          disabled={
                            !selectedAnalysis
                            || !batteryResults
                            || analysisRunning
                            || selectedAnalysis.type !== ANALYSIS_TYPES.BATTERY_SIZING
                          }
                          iconPath="/download.svg"
                          label="Open"
                          width={91}
                          className="report-download-btn"
                          title={getResultsTooltip(
                            batteryResults,
                            analysisRunning,
                            'Battery sizing',
                            true,
                          )}
                          theme={theme}
                        />
                      </div>
                    </>
                  )}
                  <div className="cost-tracking-btn-row">
                    <p>Network Change and Costing</p>
                    <Button
                      onClick={downloadCostTrackingReport}
                      disabled={downloadingCTReport}
                      iconPath="/download.svg"
                      label="Download"
                      loading={downloadingCTReport}
                      className="report-download-btn"
                      title={getResultsTooltip(
                        true,
                        false,
                        'Network change and costing',
                        true,
                      )}
                      theme={theme}
                    />
                  </div>
                  {!inEditMode && (
                    <>
                      <AssetSchedule
                        workspace={workspace}
                        branch={branch}
                        scenario={selectedScenario}
                        scenarioType={selectedScenarioType}
                        header="Feeder Schedule"
                        asset={{ id: feeder.id, class: 'feeder' }}
                        scheduleType="Feeder"
                        timeRange={timeRange}
                        maxRange={maxRange}
                        timeBarZoomLevel={timeBarZoomLevel}
                        editable={!authEnabled || permissions.has('modify_asset_schedule')}
                        expanded={expanded}
                        onScheduleUploadSuccess={updateScenario}
                      />

                      <AnalysisResultsSection id="analysis-results-section" />
                    </>
                  )}
                  <BulkLifecycleUpload
                    workspace={workspace}
                    branch={branch}
                    feeder={feeder.id}
                    afterUpload={() => refreshNetworkData(workspace, branch, [feeder.id])}
                    selectedAssetID={selectedAssetID}
                    selectedAssetViewModelClass={selectedAssetViewModelClass}
                  />
                </div>
              </CustomScrollBar>
            </TabPanel>,
            <TabPanel key="Tab-Panel-2">
              <NotesPanel
                assetID={selectedContainers[0].id}
                branch={branch}
                permissions={permissions}
                theme={theme}
                workspace={workspace}
              />
            </TabPanel>,
          ]}
        </ThemedTabs>
      )}
      {modalActive === true && (
        <ActivityModal
          toggleModal={toggleModal}
          batteryResults={batteryResults}
          theme={theme}
          resultsLoading={resultsLoading}
          workspace={workspace}
          branch={branch}
        />
      )}
    </div>
  );
};

FeederPanel.defaultProps = {
  downloadingPFReport: false,
  downloadingHCReport: false,
  downloadingCTReport: false,
  selectedContainers: [],
};

FeederPanel.propTypes = {
  anyResults: PropTypes.bool.isRequired,
  containers: PropTypes.array.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedScenarioType: PropTypes.string.isRequired,
  selectedAnalysis: nullable(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  selectedContainers: PropTypes.array,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  analysisActive: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
  downloadingPFReport: PropTypes.bool,
  downloadingHCReport: PropTypes.bool,
  downloadingCTReport: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  getResultsTooltip: PropTypes.func.isRequired,
  downloadPowerFlowReport: PropTypes.func.isRequired,
  downloadHostingCapacityReport: PropTypes.func.isRequired,
  isValidHCReportTimeRange: PropTypes.func.isRequired,
  downloadCostTrackingReport: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  fetchNodeSubstationDistances: PropTypes.func.isRequired,
  refreshNetworkData: PropTypes.func.isRequired,
  selectedAssetID: nullable(PropTypes.string).isRequired,
  selectedAssetViewModelClass: nullable(PropTypes.string).isRequired,
  containerInAnalysis: PropTypes.bool.isRequired,
  updateSelectedScenario: PropTypes.func.isRequired,
};

export default FeederPanel;
