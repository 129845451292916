import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPES } from 'helpers/EditMode';
import IconButton from 'components/IconButton';
import Select from 'components/Select';
import classNames from 'classnames';
import ThemeContext from 'helpers/ThemeContext';
import history from 'routes/history';
import './EquipmentInfoSelector.scss';

// Create a dropdown for selecting equipment infos and a link to the
// info in the equipment library
const EquipmentInfoSelector = (props) => {
  const { actions } = props;
  const theme = useContext(ThemeContext);

  let linkUrl = `${props.eqLibURL}/${props.value}`;

  if (props.value === '') {
    linkUrl = `${props.eqLibURL}/add`;
  }

  const handleSelect = (e) => {
    if (e?.value === 'add') {
      history.push(`${props.eqLibURL}/add`);
    } else {
      props.onChange(e);
    }
  };
  return (
    <div className="equipment-header">
      <div className="equipment-type equipment-type-selector">
        <p>
          { props.displayName ? `Library: ${props.displayName}` : '' }
        </p>
        <div className="equipment-info-selector">
          <Select
            async
            defaultOptions={props.defaultOptions}
            loadOptions={props.loadOptions}
            name={props.id}
            id={props.id}
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
            clearable={props.clearable}
            onChange={handleSelect}
            theme={theme}
            filterOption={(option, input) => {
              if (input) return option?.data?.name?.toLowerCase()?.includes(input.toLowerCase()) ?? false;
              return true;
            }}
          />
          <IconButton
            icon="library_books"
            tooltip="Equipment Library"
            className={classNames({
              'equipment-info-selector-link': true,
              'equipment-info-selector-link--disabled': props.edited || props.value === undefined,
            })}
            onClick={props.inEditMode
              ? () => actions.setSaveModal(
                true,
                MODAL_TYPES.VIEW,
                'library',
                `${linkUrl}?feeder=${props.feeder}&id=${props.assetId}`,
              )
              : () => {
                actions.setView('library');
                history.push(`${linkUrl}?feeder=${props.feeder}&id=${props.assetId}`);
              }}
            disabled={props.value === undefined}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

EquipmentInfoSelector.defaultProps = {
  disabled: false,
  defaultOptions: [],
  edited: false,
  placeholder: 'Select Equipment Type',
  assetId: '',
  feeder: '',
  value: '',
  onChange: null,
  displayName: null,
  clearable: false,
};

EquipmentInfoSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func, // eslint-disable-line
  value: PropTypes.string,
  eqLibURL: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultOptions: PropTypes.array,
  loadOptions: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  assetId: PropTypes.string,
  feeder: PropTypes.string,
  edited: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
  clearable: PropTypes.bool,
};

export default EquipmentInfoSelector;
