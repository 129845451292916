import React, { Component } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import Request from 'helpers/Request';
import browserHistory from 'routes/history';
import {
  MODAL_TYPES, OLD_BRANCH, NEW_BRANCH, DISCARD,
} from 'helpers/EditMode';
import asyncActionStates from 'helpers/asyncActionStates';
import Modal from 'components/Modal';
import RadioButtonGroup from 'components/RadioButtonGroup';
import TextInput from 'components/TextInput';
import AddAssetButton from './AddAssetButton';
import LineToggleButton from './LineToggleButton';
import './EditModeRibbon.scss';

class EditModeRibbon extends Component {
  state = {
    branchNameOption: this.props.canMergeBranch ? OLD_BRANCH : NEW_BRANCH,
    newBranchName: '',
    selectedType: '',
    // default to showing the warning unless we are guanteed not to need it
    // as its safer to warn when nothing will happen than otherwise
    hasAnalyses: true,
    discardError: false,
  };

  componentDidMount() {
    this.fetchAnalysesForDisplayBranch();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.canMergeBranch && prevProps.canMergeBranch) {
      this.setState({ branchNameOption: NEW_BRANCH });
    } else if (prevProps.displayBranch !== this.props.displayBranch) {
      this.fetchAnalysesForDisplayBranch();
    }
  }

  fetchAnalysesForDisplayBranch = async () => {
    const { workspace, displayBranch, permissions } = this.props;
    const request = new Request(`/api/workspace/${workspace}/branch/${displayBranch}/analysis`);

    if (!permissions.has('get_analysis')) {
      this.setState({ hasAnalyses: true });
      return;
    }
    try {
      await request.head();
      this.setState({ hasAnalyses: true });
    } catch (error) {
      if (error.response.status === 404) {
        this.setState({ hasAnalyses: false });
      } else {
        this.setState({ hasAnalyses: true });
      }
    }
  }

  // Add Asset Button Logic
  handleCreationClick = (clickEvent) => {
    const { id } = clickEvent.target;
    const { workspace, branch, selectedAsset } = this.props;
    const { createNewNodeAsset } = this.props.actions;
    this.setState({ selectedType: id });
    createNewNodeAsset(workspace, branch, selectedAsset, id);
  };

  clearAndClose = () => {
    this.props.actions.clearSaveEditStatus();
    this.props.actions.setSelectedProject(null);
    this.props.closeSave();
    this.setState({ branchNameOption: this.props.canMergeBranch ? OLD_BRANCH : NEW_BRANCH, newBranchName: '' });
  }

  saveChanges = async () => {
    if (
      this.props.modalType === MODAL_TYPES.SAVEAS
      && this.state.branchNameOption === OLD_BRANCH) {
      // save changes to existing network version and create a copy
      await this.props.actions.saveEdits(null, this.props.modalType);
      await this.props.actions.saveEdits(
        this.state.newBranchName,
        this.props.modalType,
      );
    } else if (this.props.modalType === MODAL_TYPES.WORKSPACE) {
      // save changes to existing network version and change workspace
      await this.props.actions.saveEdits(null, this.props.modalType);
      if (!this.props.saveEditBranchError) {
        // on successful save, switch workspace and update branch
        browserHistory.push(`/${this.props.switchToWorkspace}/master/gis`);
        this.props.actions.updateSelectedBranch(
          this.props.switchToBranch,
          [],
          true,
          this.props.modalType,
          this.props.switchToWorkspace,
        );
      }
    } else {
      let name;
      if (this.state.branchNameOption === NEW_BRANCH) {
        // name is newly defined branch name unless switching to an existing network version
        name = this.props.modalType === MODAL_TYPES.VERSION ? this.props.switchToBranch : this.state.newBranchName;
      }
      if (name && this.props.modalType === MODAL_TYPES.VERSION) {
        // save changes to another existing network version
        // delete current edit branch, switch to other existing network version
        await this.props.actions.deleteBranch(this.props.workspace, name, this.props.modalType);
        this.props.actions.updateSelectedBranch(name, null, false);
      }
      // save changes to other existing network version
      await this.props.actions.saveEdits(name, this.props.modalType, this.props.switchToBranch);
      if (!this.props.saveEditBranchError) {
        // if requested view has changed to something other than GIS, switch views
        if (this.props.changeToView !== 'gis') this.props.switchToView(this.props.changeToView, this.props.newLink);
        this.props.actions.clearPathModes();
      }
    }
  }

  discardChanges = async () => {
    let req;
    try {
      switch (this.props.modalType) {
        case MODAL_TYPES.VERSION:
        case MODAL_TYPES.WORKSPACE:
          // switching workspace or network version, delete edit branch and switch workspace or network version
          req = new Request(`/api/workspace/${this.props.workspace}/branch/${this.props.branch}`);
          browserHistory.push(`/${this.props.workspace}/master/gis`);
          await req.delete();
          this.props.actions.updateSelectedBranch(
            this.props.switchToBranch,
            [],
            true,
            this.props.modalType,
            this.props.switchToWorkspace,
          );
          break;
        case MODAL_TYPES.SAVEAS:
          // create a new network version, discarding any unsaved changes from existing network version
          // delete existing edit branch, create new network version from existing network version,
          // switch to new network version
          req = new Request(`/api/workspace/${this.props.workspace}/branch/${this.props.branch}`);
          await req.delete();
          await this.props.actions.createBranch(
            this.props.workspace,
            this.state.newBranchName,
            this.props.displayBranch,
            this.props.modalType,
          );
          this.props.actions.updateSelectedBranch(
            this.state.newBranchName,
          );
          break;
        case MODAL_TYPES.VIEW:
          // discard changes, switch views
          this.props.actions.toggleEditMode();
          if (this.props.changeToView !== 'gis') this.props.switchToView(this.props.changeToView, this.props.newLink);
          break;
        case MODAL_TYPES.TOGGLE:
        default:
          // discard changes, return to gis view
          this.props.actions.toggleEditMode();
      }
    } catch (err) {
      this.setState({ discardError: true });
    }
  }

  handleSaveEdits = async () => {
    if (this.state.branchNameOption !== DISCARD) {
      // this block saves changes
      await this.saveChanges();
    } else {
      // this block discards changes
      await this.discardChanges();
    }
    if (!this.props.saveEditBranchError && !this.state.discardError) this.clearAndClose();
  };

  handleSaveModalCancel = () => {
    this.setState({
      branchNameOption: OLD_BRANCH,
      newBranchName: '',
    });
    this.props.closeSave(true);
    this.props.actions.clearSaveEditStatus();
  };

  AddShuntIcon = (buttonValues) => {
    const { selectedAsset, addingAsset, selectedFeeders } = this.props;
    const [container] = selectedFeeders?.filter(feeder => feeder.id === selectedAsset?.feeder);

    return (
      <AddAssetButton
        {...buttonValues}
        selected={this.state.selectedType}
        addingAsset={addingAsset}
        disabled={
          !selectedAsset
          || selectedAsset.asset_type !== 'ConnectivityNode'
          || addingAsset
          || (buttonValues.id === 'equivalent_substation' && container?.type === 'Substation')
        }
        onClick={this.handleCreationClick}
      />
    );
  };

  AddLineIcon = (buttonValues) => {
    const {
      selectedAsset, addingAsset, newAssetID, actions,
    } = this.props;
    return (
      <AddAssetButton
        {...buttonValues}
        className="line-asset-icon"
        selected={newAssetID}
        addingAsset={addingAsset}
        disabled={!selectedAsset || selectedAsset.asset_type !== 'ACLineSegment' || addingAsset}
        onClick={() => actions.selectNewAsset(buttonValues)}
      />
    );
  };

  AddLineEditPathButton = () => {
    const {
      inPathEditMode, selectedAsset, savingAssetEdit, actions,
    } = this.props;
    return (
      <LineToggleButton
        id="edit-line"
        name={inPathEditMode ? 'Save Line' : 'Edit Line'}
        active={inPathEditMode}
        disabled={!selectedAsset || selectedAsset.asset_type !== 'ACLineSegment' || savingAssetEdit}
        onClick={() => actions.togglePathEditMode(false)}
        savingAssetEdit={savingAssetEdit}
      />
    );
  };

  AddLineCreateButton = () => {
    const {
      inPathCreateMode, inPathEditMode, actions, addingAsset,
    } = this.props;
    return (
      <LineToggleButton
        id="add-line"
        name={inPathCreateMode ? 'Exit Line Mode' : 'Add Line'}
        active={inPathCreateMode}
        onClick={actions.togglePathCreateMode}
        disabled={inPathEditMode || addingAsset}
        savingAssetEdit={addingAsset && inPathCreateMode}
      />
    );
  };

  getErrorMessage = () => {
    if (this.props.editSaveMsg) {
      return this.props.editSaveMsg;
    } if (!this.props.canMergeBranch) {
      return 'While you were editing, changes have been made to this network version. Please create a new version to avoid conflicting changes.';
    } if (this.props.saveEditBranchError) {
      return 'Cannot save version at this time. Please try again later.';
    } if (this.state.discardError) {
      return 'Cannot discard changes at this time.  Please try again later.';
    }
    return null;
  };

  getAnalysisDeletedWarning = (version) => (
    <div className="analysis-delete-warning">
      <i className="material-icons">warning</i>
      <p>{`All analyses run for version (${version}) will be deleted.`}</p>
    </div>
  );

  renderRefreshModal = () => (
    <Modal
      title="Edits have already been saved."
      labels={{
        confirm: 'Refresh',
      }}
      showCancel={false}
      onConfirm={() => this.props.actions.updateSelectedBranch(this.props.displayBranch)}
      active
      width="350px"
      theme={this.props.theme}
    >
      <p className="leave-edit-mode-contents">
        This network version is no longer in edit mode. Please refresh the network.
      </p>
    </Modal>
  )

  exitEditMode = () => {
    this.props.closeSave();
    this.props.actions.toggleEditMode();
  }

  cancelExit = () => {
    this.props.closeSave();
  }

  validationMessage = () => {
    let message = 'Must be at least 1 character and can not include special characters or spaces.';
    if (this.props.branches.some(branch => branch.name === this.state.newBranchName)) {
      message = 'This version name already exists.  Please select something else.';
    }
    return message;
  }

  modalTitle = () => {
    let title;
    switch (this.props.modalType) {
      case MODAL_TYPES.SAVEAS:
        title = 'Create Version Copy';
        break;
      case MODAL_TYPES.VERSION:
        title = 'Change Network Version';
        break;
      case MODAL_TYPES.WORKSPACE:
        title = 'Change Workspace';
        break;
      case MODAL_TYPES.TOGGLE:
      default:
        title = 'Exit Edit Mode';
    }
    return title;
  }

  oldBranchOption = (userVisibleBranchName, userVisibleSwitchBranch, invalidBranchName) => {
    let label; let body;
    switch (this.props.modalType) {
      case MODAL_TYPES.SAVEAS:
        label = `Save changes to current version (${userVisibleBranchName}) and create a copy:`;
        body = (
          <div>
            {this.state.hasAnalyses && this.getAnalysisDeletedWarning(userVisibleBranchName)}
            <TextInput
              id="textInput_old"
              value={this.state.branchNameOption === OLD_BRANCH ? this.state.newBranchName : ''}
              onChange={e => this.setState({ newBranchName: e.target.value })}
              theme={this.props.theme}
              disabled={this.state.branchNameOption !== OLD_BRANCH}
              invalid={this.state.branchNameOption === OLD_BRANCH && invalidBranchName}
              validationMessage={this.validationMessage()}
            />
          </div>
        );
        break;
      case MODAL_TYPES.VERSION:
        label = `Save changes to current version (${userVisibleBranchName}) and switch to another version (${userVisibleSwitchBranch})`;
        body = (
          <div>
            {this.state.hasAnalyses && this.getAnalysisDeletedWarning(userVisibleBranchName)}
          </div>
        );
        break;
      case MODAL_TYPES.TOGGLE:
      case MODAL_TYPES.VIEW:
      case MODAL_TYPES.WORKSPACE:
      default:
        const versionName = `${this.props.switchToWorkspace ? `${this.props.workspace}-` : ''}${userVisibleBranchName}`;
        label = `Save changes to current version (${versionName})`;
        body = (<div>{this.state.hasAnalyses && this.getAnalysisDeletedWarning(versionName)}</div>);
    }
    return {
      label,
      body,
    };
  };

  newBranchOption = (userVisibleSwitchBranch, invalidBranchName) => {
    let label; let body = '';
    switch (this.props.modalType) {
      case MODAL_TYPES.VERSION:
        label = `Overwrite (${userVisibleSwitchBranch}) and switch to (${userVisibleSwitchBranch})`;
        break;
      case MODAL_TYPES.TOGGLE:
      case MODAL_TYPES.VIEW:
      case MODAL_TYPES.SAVEAS:
      default:
        label = this.props.saveModalActive && 'Save changes to new version:';
        body = this.props.saveModalActive && (
          <TextInput
            id="textInput_new"
            value={this.state.branchNameOption === NEW_BRANCH ? this.state.newBranchName : ''}
            onChange={e => this.setState({ newBranchName: e.target.value })}
            theme={this.props.theme}
            disabled={this.state.branchNameOption !== NEW_BRANCH}
            invalid={this.state.branchNameOption === NEW_BRANCH && invalidBranchName}
            validationMessage={this.validationMessage()}
          />
        );
    }

    return {
      label,
      body,
    };
  }

  discardOption = (userVisibleSwitchBranch, invalidBranchName) => {
    let label; let body = '';
    switch (this.props.modalType) {
      case MODAL_TYPES.VERSION:
        label = `Discard changes and switch to another version (${userVisibleSwitchBranch})`;
        break;
      case MODAL_TYPES.SAVEAS:
        label = this.props.saveModalActive && 'Discard changes and create a copy of current version:';
        body = this.props.saveModalActive && (
          <TextInput
            id="textInput_discard"
            value={this.state.branchNameOption === DISCARD ? this.state.newBranchName : ''}
            onChange={e => this.setState({ newBranchName: e.target.value })}
            theme={this.props.theme}
            disabled={this.state.branchNameOption !== DISCARD}
            invalid={this.state.branchNameOption === DISCARD && invalidBranchName}
            validationMessage={this.validationMessage()}
          />
        );
        break;
      case MODAL_TYPES.VIEW:
        label = 'Discard changes and switch views';
        break;
      case MODAL_TYPES.WORKSPACE:
        label = 'Discard changes and switch workspaces';
        break;
      case MODAL_TYPES.TOGGLE:
      default:
        label = 'Discard changes and exit';
    }
    return {
      label,
      body,
    };
  }

  getRadioOptions = (invalidBranchName) => {
    const userVisibleBranchName = this.props.displayBranch === 'master' ? 'As Built' : this.props.displayBranch;
    const userVisibleSwitchBranch = this.props.switchToBranch === 'master' ? 'As Built' : this.props.switchToBranch;
    const options = [{
      id: OLD_BRANCH,
      disabled: !this.props.canMergeBranch
        || (this.props.displayBranch === 'master' && !this.props.permissions.has('modify_network_as_built')),
      ...this.oldBranchOption(userVisibleBranchName, userVisibleSwitchBranch, invalidBranchName),
    }];
    if (!([MODAL_TYPES.VIEW, MODAL_TYPES.WORKSPACE].includes(this.props.modalType))) {
      options.push({
        id: NEW_BRANCH,
        disabled: (this.props.switchToBranch === 'master' && !this.props.permissions.has('modify_network_as_built')),
        ...this.newBranchOption(userVisibleSwitchBranch, invalidBranchName),
      });
    }
    options.push({
      id: DISCARD,
      ...this.discardOption(userVisibleSwitchBranch, invalidBranchName),
    });
    return options;
  }

  modalSize = () => {
    let size;
    switch (this.props.modalType) {
      case MODAL_TYPES.SAVEAS:
        size = {
          width: '507px',
          height: '460px',
        };
        break;
      case MODAL_TYPES.TOGGLE:
        size = {
          width: '495px',
          height: '350px',
        };
        break;
      case MODAL_TYPES.VERSION:
      case MODAL_TYPES.VIEW:
      case MODAL_TYPES.WORKSPACE:
      default:
        size = {
          width: 'auto',
          height: 'auto',
        };
    }
    return size;
  };

  render() {
    const {
      theme,
      editBranchMerged,
      saveEditBranchLoading,
      addingAsset,
      newAssetID,
      selectedFeeders,
      saveModalActive,
      modalType,
      actions,
      branches,
      saveEditBranchReq,
      newBranchReq,
      switchToBranch,
    } = this.props;

    const { toggleAddNodeMode } = actions;

    const { branchNameOption, newBranchName } = this.state;
    const invalidBranchName = () => {
      let invalid = false;
      const invalidNewBranch = (!newBranchName || !newBranchName.match(/^[a-zA-Z0-9-_]*$/)) && !switchToBranch;
      if (branchNameOption === NEW_BRANCH) {
        invalid = invalidNewBranch;
      } else {
        invalid = modalType === MODAL_TYPES.SAVEAS && invalidNewBranch;
      }
      invalid = invalid || ((saveEditBranchReq === asyncActionStates.INITIAL
        && newBranchReq === asyncActionStates.INITIAL)
        && !(modalType === MODAL_TYPES.VERSION)
        && branches.some(branch => branch.name === newBranchName));
      return invalid;
    };

    return (
      <div className="edit-mode-ribbon">
        <div className="ribbon-contents">
          <div className="ribbon-buttons">
            {this.AddLineCreateButton()}
            {this.AddLineEditPathButton()}
            {this.AddShuntIcon({ id: 'bess', name: 'Battery', icon: 'battery' })}
            {this.AddShuntIcon({ id: 'pv', name: 'Photovoltaic', icon: 'photovoltaic' })}
            {this.AddShuntIcon({ id: 'wind', name: 'Wind', icon: 'wind' })}
            {this.AddShuntIcon({
              id: 'synchronous_machine',
              name: 'Synchronous Machine',
              icon: 'generator',
            })}
            {this.AddShuntIcon({
              id: 'combined_heat_power',
              name: 'Combined Heat & Power',
              icon: 'CHP',
            })}
            {this.AddShuntIcon({
              id: 'run_of_the_river',
              name: 'Run of River Hydro',
              icon: 'river_hydro_plants',
            })}
            {this.AddShuntIcon({ id: 'capacitor', name: 'Shunt Capacitor', icon: 'shunt_capacitor' })}
            {this.AddShuntIcon({ id: 'equivalent_substation', name: 'Downstream Substation', icon: 'equivalent_substation' })}
            {this.AddShuntIcon({ id: 'slack', name: 'Energy Source', icon: 'energy_source' })}
            {this.AddShuntIcon({ id: 'energy_consumer', name: 'Load', icon: 'load' })}
            {this.AddShuntIcon({ id: 'ev_station', name: 'EV Charging Station', icon: 'ev_station' })}
            {this.AddLineIcon({ id: 'switch', name: 'Switch', icon: 'switch' })}
            {this.AddLineIcon({ id: 'regulator', name: 'Regulator', icon: 'regulator' })}
            {this.AddLineIcon({ id: 'power_transformer', name: 'Transformer', icon: 'transformer' })}
            <AddAssetButton
              id="node"
              name="Connectivity Node"
              icon="node"
              selected={newAssetID}
              addingAsset={addingAsset && newAssetID === 'node'}
              disabled={addingAsset || selectedFeeders.length === 0}
              onClick={toggleAddNodeMode}
              active={newAssetID === 'node'}
            />
          </div>
        </div>

        {editBranchMerged && this.renderRefreshModal()}

        {/* Save Changes to Branch Modal */}
        <Modal
          title={this.modalTitle()}
          onConfirm={this.handleSaveEdits}
          onCancel={this.handleSaveModalCancel}
          active={saveModalActive}
          {...this.modalSize()}
          theme={theme}
          disableConfirm={
            invalidBranchName()
            || !(saveEditBranchReq === asyncActionStates.INITIAL)
          }
          labels={{
            confirm: saveEditBranchLoading ? (<i className="material-icons rotate">refresh</i>) : ('Confirm'),
          }}
        >
          <div className="edit-mode-save-modal-contents">
            {saveModalActive && (
              <div>
                <p>Please select where you would like to save your changes</p>
                <p className="caption-text error" style={{ marginTop: 5 }}>
                  {this.getErrorMessage()}
                </p>
                <RadioButtonGroup
                  value={branchNameOption}
                  onChange={e => this.setState({ branchNameOption: e.target.value, newBranchName: '' })}
                  options={this.getRadioOptions(invalidBranchName())}
                  id="branch-name"
                  listType="column"
                  theme={theme}
                />
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

EditModeRibbon.defaultProps = {
  selectedAsset: null,
  addingAsset: false,
  savingAssetEdit: false,
  inPathEditMode: false,
  inPathCreateMode: false,
  theme: 'dark',
  canMergeBranch: true,
  saveEditBranchError: false,
  saveEditBranchLoading: false,
  editSaveMsg: null,
  newAssetID: null,
  selectedFeeders: [],
  saveModalActive: false,
  switchToBranch: null,
  changeToView: 'gis',
  switchToWorkspace: null,
};

EditModeRibbon.propTypes = {
  addingAsset: PropTypes.bool,
  actions: PropTypes.shape({
    createBranch: PropTypes.func.isRequired,
    togglePathCreateMode: PropTypes.func.isRequired,
    toggleEditMode: PropTypes.func.isRequired,
    createNewNodeAsset: PropTypes.func.isRequired,
    clearSaveEditStatus: PropTypes.func.isRequired,
    toggleAddNodeMode: PropTypes.func.isRequired,
    saveEdits: PropTypes.func.isRequired,
    togglePathEditMode: PropTypes.func.isRequired,
    selectNewAsset: PropTypes.func.isRequired,
    updateSelectedBranch: PropTypes.func.isRequired,
    clearPathModes: PropTypes.func.isRequired,
    deleteBranch: PropTypes.func.isRequired,
    setSelectedProject: PropTypes.func.isRequired,
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  inPathEditMode: PropTypes.bool,
  savingAssetEdit: PropTypes.bool,
  selectedAsset: PropTypes.object,
  theme: PropTypes.string,
  canMergeBranch: PropTypes.bool,
  saveEditBranchLoading: PropTypes.bool,
  saveEditBranchError: PropTypes.bool,
  editBranchMerged: PropTypes.bool.isRequired,
  editSaveMsg: PropTypes.string,
  newAssetID: PropTypes.string,
  selectedFeeders: PropTypes.array,
  inPathCreateMode: PropTypes.bool,
  saveModalActive: PropTypes.bool,
  closeSave: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  branches: PropTypes.array.isRequired,
  saveEditBranchReq: PropTypes.number.isRequired,
  newBranchReq: PropTypes.number.isRequired,
  switchToBranch: PropTypes.string,
  changeToView: PropTypes.string,
  switchToView: PropTypes.func.isRequired,
  switchToWorkspace: PropTypes.string,
  permissions: PropTypes.object.isRequired,
  newLink: nullable(PropTypes.string).isRequired,
};

export default EditModeRibbon;
