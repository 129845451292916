import React from 'react';
import PropTypes from 'prop-types';
import CustomScrollBar from 'components/CustomScrollBar';
import { ErrorIcon, WarningIcon } from './Icons';
import './ValidationErrors.scss';

// Create the errors section of the Workspace Panel
const ValidationErrors = ({ errors }) => {
  if (!errors) {
    return null;
  }

  const { messages } = errors;
  const messageInstances = (messages || []).map(message => (
    <li className="message-instance" key={message.message}>
      {message.severity === 'WARNING' && WarningIcon()}
      {message.severity === 'ERROR' && ErrorIcon()}
      <div className="message-instance--message">{message.message}</div>
    </li>
  ));
  return (
    <div className="validation-errors">
      <div className="errors-container">
        <ul className="errors-list alternating-colors">
          <CustomScrollBar>
            {messageInstances}
          </CustomScrollBar>
        </ul>
      </div>
    </div>
  );
};

ValidationErrors.propTypes = {
  errors: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string,
      severity: PropTypes.oneOf(['ERROR', 'WARNING']),
    })),
  }).isRequired,
};

export default ValidationErrors;
