import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import Select from 'components/Select';
import { chunkArray } from 'helpers/utils';
import Request, { rateLimit } from 'helpers/Request';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import nullable from 'helpers/nullablePropType';
import asyncActionStates from 'helpers/asyncActionStates';
import Analytics from 'helpers/Analytics';
import { ScenarioTypes } from 'helpers/scenarios';
import ScenarioModal from './ScenarioModal';
import './DropdownSections.scss';
import SelectBadgeItem from '../../../components/SelectBadgeItem';

class ScenarioSection extends Component {
  initilUploadStates = {
    scenarioReq: asyncActionStates.INITIAL,
    scenarioErr: null,
    scenarioFileUploads: {},
    fileUploadErrors: {},
    bulkAssetScheduleUpload: asyncActionStates.INITIAL,
    bulkAssetScheduleError: null,
  }

  state = {
    scenarioModalOpen: false,
    scenarioModalMode: 'create',
    ...this.initilUploadStates,
  };

  componentDidMount() {
    const { actions, workspace, branch } = this.props;
    actions.fetchScenarios(workspace, branch);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      actions, workspace, branch, scenario, scenarios,
    } = this.props;
    if (prevProps.workspace !== workspace || prevProps.branch !== branch) {
      actions.fetchScenarios(workspace, branch);
    }
    if (
      scenario === ''
      && scenarios.length === 1
      && prevProps.scenarios.length !== scenarios.length
    ) {
      this.handleScenarioChange(scenarios[0]);
    }

    if (this.state.scenarioReq === asyncActionStates.SUCCESS
        && this.state.scenarioReq !== prevState.scenarioReq) {
      this.setState({
        scenarioModalMode: 'edit',
      });
    }
  }

  handleScenarioChange = (e) => {
    // e is null if cleared
    const value = e ? e.value : '';
    if (typeof this.props.handleScenarioChange === 'function') {
      this.props.handleScenarioChange(value);
      return;
    }

    if (value === '') {
      // Clearing the selection
      this.props.actions.clearSelectedScenario(this.props.workspace, this.props.branch);
    } else if (value !== this.props.scenario) {
      this.props.actions.updateSelectedScenario(this.props.workspace, this.props.branch, value, e.type);
    }
  };

  handleClose = (hasNewScenario = false) => {
    this.setState({ scenarioModalOpen: false });
    if (hasNewScenario) {
      const { workspace, branch, scenario } = this.props;
      this.props.actions.updateAndSelectScenario(workspace, branch, scenario);
    }
    this.setState({ ...this.initilUploadStates });
  };

  uploadSingleScenarioFile = async (workspace, branch, scenario, feeder, file) => {
    const uploadURL = `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${feeder}`;
    this.setState(prevState => ({
      scenarioFileUploads: {
        ...prevState.scenarioFileUploads,
        [feeder]: asyncActionStates.LOADING,
      },
    }));
    try {
      await new Request(uploadURL).post(file, {
        params: {
          scenario_id: scenario,
          asset_type: 'feeder',
          schedule_type: 'Feeder',
        },
      });
      this.setState(prevState => ({
        scenarioFileUploads: {
          ...prevState.scenarioFileUploads,
          [feeder]: asyncActionStates.SUCCESS,
        },
      }));
    } catch (err) {
      let message = 'An Error Occured Uploading Selected File';
      if (err && err.response && err.response.data && err.response.data.errors) {
        message = err.response.data.errors[0].message;
      }
      this.setState(prevState => ({
        scenarioFileUploads: {
          ...prevState.scenarioFileUploads,
          [feeder]: asyncActionStates.ERROR,
        },
        fileUploadErrors: { ...prevState.fileUploadErrors, [feeder]: message },
      }));
    }
  }

  uploadScenarioFiles = async (workspace, branch, scenario, files) => {
    const chunks = chunkArray(Object.entries(files), rateLimit.concurrentRequests);
    const uploadFile = ([feeder, formData]) => this.uploadSingleScenarioFile(
      workspace, branch, scenario, feeder, formData,
    );
    for (let batch = 0; batch < chunks.length; batch += 1) {
      const requests = chunks[batch].map(uploadFile);
      /* eslint-disable no-await-in-loop */
      // ignore lint because we specifically dont want them all running at once
      await Promise.all(requests);
      // wait 1s between batches
      await new Promise(resolve => setTimeout(resolve, rateLimit.resetTimeout));
      /* eslint-enable no-await-in-loop */
    }
  }

  uploadBulkAssetSchedule = async (workspace, branch, scenario, file) => {
    const uploadUrl = `/api/workspace/${workspace}/branch/${branch}/asset_schedule_bulk`;
    this.setState({ bulkAssetScheduleUpload: asyncActionStates.LOADING });
    try {
      await new Request(uploadUrl).post(file, {
        params: { scenario_id: scenario },
      });
      this.setState({ bulkAssetScheduleUpload: asyncActionStates.SUCCESS, bulkAssetScheduleError: '' });
    } catch (err) {
      let message = 'An Error Occured Uploading Selected File';
      if (err && err.response && err.response.data && err.response.data.errors) {
        message = err.response.data.errors[0].message;
      }
      this.setState({ bulkAssetScheduleUpload: asyncActionStates.ERROR, bulkAssetScheduleError: message });
    }
  }

  createSnapshotScenario = async (workspace, branch, name, timestamp, feeders) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`;
    try {
      const response = await new Request(url).post({
        scenario_name: name,
        scenario_type: ScenarioTypes.snapshot,
        timepoint: timestamp,
        container: feeders.map(fdr => fdr.id),
      });
      Analytics.logEvent('Uploaded Scenario', 'Scenarios');
      const { scenario_id } = response.data;
      this.props.actions.updateSelectedScenario(workspace, branch, scenario_id, ScenarioTypes.snapshot);
      this.setState({ scenarioReq: asyncActionStates.SUCCESS });
    } catch (err) {
      let message = 'An error occured while creating scenario';
      if (err?.response?.data?.errors?.length > 0) {
        message = err.response.data.errors[0].message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  deleteScenario = async (workspace, branch, scenarioId) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${scenarioId}`;
    try {
      await new Request(url).delete();
      await this.props.actions.fetchScenarios(workspace, branch);
      await this.props.actions.clearSelectedScenario();
      this.setState({ ...this.initilUploadStates });
    } catch (err) {
      let message = 'An error occured while deleting scenario';
      if (err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  updateScenario = async (workspace, branch, scenario, name, files = {}, asset_schedule = null) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${scenario}`;
    try {
      await new Request(url).patch({
        scenario_name: name,
      });
      Analytics.logEvent('Updated Scenario', 'Scenarios');
      if (Object.keys(files).length > 0) {
        this.uploadScenarioFiles(workspace, branch, scenario, files);
      }
      if (asset_schedule) {
        this.uploadBulkAssetSchedule(workspace, branch, scenario, asset_schedule);
      }
      this.props.actions.fetchScenarios(workspace, branch);
      this.setState({ scenarioReq: asyncActionStates.SUCCESS });
    } catch (err) {
      let message = 'An error occured while updating scenario';
      if (err?.response?.data?.errors && err?.response?.data?.errors?.length > 0) {
        message = err.response.data.errors[0].message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  createScenario = async (workspace, branch, name, files = {}, asset_schedule = null) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`;
    try {
      const results = await new Request(url).post({
        scenario_name: name,
        scenario_type: ScenarioTypes.timeseries,
      });
      Analytics.logEvent('Uploaded Scenario', 'Scenarios');
      const { scenario_id } = results.data;
      if (Object.keys(files).length > 0) {
        this.uploadScenarioFiles(workspace, branch, scenario_id, files);
      }
      if (asset_schedule) {
        this.uploadBulkAssetSchedule(workspace, branch, scenario_id, asset_schedule);
      }
      this.props.actions.updateSelectedScenario(workspace, branch, scenario_id, ScenarioTypes.timeseries);
      this.setState({ scenarioReq: asyncActionStates.SUCCESS });
    } catch (err) {
      let message = 'An error occured while creating scenario';
      if (err?.response?.data?.errors?.length > 0) {
        message = err.response.data.errors[0].message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  handleScenarioCreation = (name, files, assetSchedulFile) => {
    if (this.props.canCreate) {
      this.createScenario(
        this.props.workspace, this.props.branch, name, files, assetSchedulFile,
      );
    }
  };

  handleSnapshotScenarioCreation = (name, timepoint, feeders) => {
    if (this.props.canCreate) {
      this.createSnapshotScenario(
        this.props.workspace, this.props.branch, name, timepoint, feeders,
      );
    }
  };

  handleScenarioEdit = (scenarioId, name, files, assetSchedulFile) => {
    if (this.props.canEdit) {
      this.updateScenario(
        this.props.workspace, this.props.branch, scenarioId, name, files, assetSchedulFile,
      );
    }
  }

  handleReUpload = (scenarioFiles, assetSchedulFile) => {
    if (scenarioFiles) {
      this.uploadScenarioFiles(
        this.props.workspace,
        this.props.branch,
        this.props.scenario,
        scenarioFiles,
      );
    }
    if (assetSchedulFile) {
      this.uploadBulkAssetSchedule(
        this.props.workspace,
        this.props.branch,
        this.props.scenario,
        assetSchedulFile,
      );
    }
  };

  handleMenuSelection = (type) => {
    switch (type) {
      case 'delete':
        if (this.props.canDelete) {
          return this.deleteScenario(
            this.props.workspace,
            this.props.branch,
            this.props.scenario,
          );
        }
        return null;
      case 'edit':
        this.setState({
          scenarioModalOpen: true,
          scenarioModalMode: 'edit',
        });
        return null;
      default:
        return null;
    }
  };

  renderButton = () => {
    if (this.state.scenarioReq === asyncActionStates.LOADING) {
      return <i className="material-icons rotate">refresh</i>;
    }
    return (
      <PermissionDisabledTip title="Create Scenario" hide={this.props.canCreate} theme={this.props.theme}>
        <IconButton
          icon="add"
          id="add-scenario-btn"
          onClick={() => this.setState({ scenarioModalOpen: true, scenarioModalMode: 'create' })}
          theme={this.props.theme}
          disabled={!this.props.canCreate}
          tooltip={this.props.canCreate ? 'Create Scenario' : undefined}
        />
      </PermissionDisabledTip>
    );
  };

  actionButtons = (canEdit, canDelete) => ([
    {
      id: 'edit',
      icon: 'edit',
      key: 'Edit scenario',
      className: 'top-row-buttons',
      tooltip: !canEdit ? null : 'Edit scenario',
      onClick: () => this.setState({
        scenarioModalOpen: true,
        scenarioModalMode: 'edit',
      }),
      theme: this.props.theme,
      disabled: !canEdit,
      disabledMessage: this.props.scenarios.length === 0 || !this.props.scenario
        ? '' : 'You do not have permission to access this feature',
    }, {
      id: 'delete',
      icon: 'delete',
      key: 'Delete scenario',
      className: 'top-row-buttons',
      tooltip: !canDelete ? null : 'Delete scenario',
      onClick: () => this.deleteScenario(
        this.props.workspace,
        this.props.branch,
        this.props.scenario,
      ),
      theme: this.props.theme,
      disabled: !canDelete,
      disabledMessage: this.props.scenarios.length === 0 || !this.props.scenario
        ? '' : 'You do not have permission to access this feature',
    },
  ]);

  render() {
    const {
      canDelete, canEdit, scenarios, scenario,
    } = this.props;
    const deleteDisabled = !canDelete || scenarios.length === 0 || !scenario;
    const editDisabled = !canEdit || scenarios.length === 0 || !scenario;
    const branchCopying = this.props.newBranchReq === asyncActionStates.LOADING
      || this.props.cloningScenarioReq === 'RUNNING';

    const scenarioMap = scenarios.map(m => ({
      ...m,
      label: (
        <SelectBadgeItem
          item={m}
          badgeInfos={[{
            tooltip: m.type === ScenarioTypes.timeseries ? 'Timeseries' : 'Snapshot',
            key: m.value,
            label: m.type === ScenarioTypes.timeseries ? 'T' : 'S',
          }]}
        />
      ),
    }));
    return (
      <div className="branch-scenario-section scenario-section" id="scenario-selector-container" data-test="scenario-selector">
        <div className="select-container">
          <div className="select-top-row">
            <p className="select-label">
              {this.props.addNumber ? `${this.props.addNumber}. ` : ''}
              Scenario
            </p>
            { !this.props.hideButtons && (
              <div className="buttons">
                {this.props.view !== 'results' && this.renderButton()}
                {this.props.view !== 'results' && this.actionButtons(!editDisabled, !deleteDisabled).map(button => (
                  <PermissionDisabledTip
                    key={button.key}
                    title={button.key}
                    hide={!button.disabled}
                    theme={this.props.theme}
                    message={button.disabledMessage}
                  >
                    <IconButton
                      {...button}
                    />
                  </PermissionDisabledTip>
                ))}
              </div>
            )}
          </div>
          <Select
            className="scenario-select"
            theme={this.props.theme}
            options={scenarioMap}
            value={scenario}
            onChange={this.handleScenarioChange}
            clearable
            searchable={false}
            disabled={branchCopying}
            placeholder={branchCopying ? 'Copying...' : 'Select...'}
            id="scenario-selector"
            type={this.props.selectType}
          />
        </div>

        {(this.state.scenarioModalOpen) && (
          <ScenarioModal
            scenario={this.state.scenarioModalMode === 'edit' ? this.props.scenario : null}
            scenarioReq={this.state.scenarioReq}
            scenarioErr={this.state.scenarioErr}
            selectedFeeders={this.props.selectedFeeders}
            theme={this.props.theme}
            handleClose={this.handleClose}
            handleScenarioCreation={this.handleScenarioCreation}
            handleSnapshotScenarioCreation={this.handleSnapshotScenarioCreation}
            handleScenarioEdit={this.handleScenarioEdit}
            handleReUpload={this.handleReUpload}
            scenarios={this.props.scenarios}
            scenarioFileUploads={this.state.scenarioFileUploads}
            fileUploadErrors={this.state.fileUploadErrors}
            bulkAssetScheduleUpload={this.state.bulkAssetScheduleUpload}
            bulkAssetScheduleUploadError={this.state.bulkAssetScheduleError}
          />
        )}
      </div>
    );
  }
}

ScenarioSection.defaultProps = {
  scenarios: [],
  canDelete: false,
  canCreate: false,
  canEdit: false,
  newBranchReq: 0,
  cloningScenarioReq: '',
  selectedFeeders: [],
  view: 'gis',
  addNumber: '',
  handleScenarioChange: null,
  hideButtons: false,
  selectType: 'default',
};

ScenarioSection.propTypes = {
  branch: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  scenario: nullable(PropTypes.string).isRequired,
  theme: PropTypes.string.isRequired,
  scenarios: PropTypes.array,
  canDelete: PropTypes.bool,
  canCreate: PropTypes.bool,
  canEdit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  newBranchReq: PropTypes.number,
  cloningScenarioReq: PropTypes.string,
  selectedFeeders: PropTypes.array,
  actions: PropTypes.shape({
    clearSelectedScenario: PropTypes.func,
    updateSelectedScenario: PropTypes.func,
    updateAndSelectScenario: PropTypes.func,
    fetchScenarios: PropTypes.func,
  }).isRequired,
  view: PropTypes.string,
  addNumber: PropTypes.string,
  handleScenarioChange: PropTypes.func,
  selectType: PropTypes.string,
};

export default ScenarioSection;
