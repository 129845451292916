import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

const DescriptionEditor = ({
  description,
  onChange,
  isDisabled,
}) => {
  const markDownSource = decodeURIComponent(description);

  return (
    <div className="equipment-info-container" id="description-tab">
      <div className="column">
        <h3 className="column-title">Raw Text</h3>
        <textarea
          autoComplete="off"
          className="description-editor"
          onChange={e => onChange(encodeURIComponent(e.target.value))}
          placeholder="Enter a component description as Markdown text here"
          spellCheck
          value={markDownSource}
          wrap="soft"
          disabled={isDisabled}
        />
      </div>
      <div className="column">
        <span className="column-header-row">
          <h3 className="column-title">Rendered Markdown</h3>
          <a target="blank" href="https://github.github.com/gfm/"><i className="material-icons help-icon">help_outline</i></a>
        </span>
        <div className="markdown-body">
          <Markdown
            escapeHtml
            source={markDownSource}
          />
        </div>
      </div>
    </div>
  );
};

DescriptionEditor.defaultProps = {
  description: '',
  isDisabled: false,
};

DescriptionEditor.propTypes = {
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default DescriptionEditor;
