import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { networkEditActions } from 'routes/WorkspaceLayout/routes/Network/modules/networkEdit';
import UsagePointSection from './UsagePointSection';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...networkEditActions,
  }, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  theme: state.global.theme,
});

export default connect(mapStateToProps, mapDispatchToProps)(UsagePointSection);
