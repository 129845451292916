import React from 'react';
import Switch from './Switch';

// Creates a value template for Sectionaliser instances.
// As Sectionaliser shares all of its values with Switch, the switch template is used
const Sectionaliser = props => (
  <Switch {...props} />
);

export default Sectionaliser;
