import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';

import { BETA_PERMISSION } from 'helpers/permissions';
import WorkspaceLayoutContainer from './containers/WorkspaceLayoutContainer';
import CimExplorer from './routes/CimExplorer';
import EquipmentLibrary from './routes/EquipmentLibrary';
import Import from './routes/Import';
import Network from './routes/Network';
import ResultsComparison from './routes/ResultsComparison';
import WorkspaceOverview from './routes/WorkspaceOverview';

const WorkspaceRoutes = (props) => (
  <WorkspaceLayoutContainer>
    <Switch>
      <ProtectedRoute exact path="/:workspace/:branch/cim">
        <CimExplorer />
      </ProtectedRoute>
      <ProtectedRoute
        path={[
          '/:workspace/:branch/library/:equipmentType/:equipmentId',
          '/:workspace/:branch/library',
        ]}
      >
        <EquipmentLibrary />
      </ProtectedRoute>
      <ProtectedRoute path="/:workspace/:branch/import">
        <Import />
      </ProtectedRoute>
      <ProtectedRoute
        path={[
          '/:workspace/:branch/gis/:feeder_ids/asset/:asset',
          '/:workspace/:branch/gis/:feeder_ids',
          '/:workspace/:branch/gis',
        ]}
      >
        <Network />
      </ProtectedRoute>
      <ProtectedRoute path="/:workspace/:branch/comparison">
        <ResultsComparison />
      </ProtectedRoute>
      <ProtectedRoute
        path="/:workspace"
        permissions={props.permissions}
        requiredPermissions={[BETA_PERMISSION]}
      >
        <WorkspaceOverview />
      </ProtectedRoute>
    </Switch>
  </WorkspaceLayoutContainer>
);

WorkspaceRoutes.propTypes = {
  permissions: PropTypes.object.isRequired,
};

export default WorkspaceRoutes;
