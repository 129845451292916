import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import { pu } from 'helpers/units';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import OPFOptions from './OPFOptions';
import './QSTSMenu.scss';

const { QSTS_OPF, QSTS_NWE } = ANALYSIS_TYPES;

export const longDescriptions = {
  loss:
    'Optimize assets to reduce system losses.',
  'peak-shaving':
    'Optimize assets to reduce load peaks',
  cvr:
    'Optimize assets to lower average system voltage',
  'voltage-deviation':
    'Optimize assets to reduce voltage deviation across the system',
  cost:
    'Optimize assets to lower total system cost',
};

const defaultUnconstrained = new Set(['loss', 'peak-shaving']);
const defaultConstrained = new Set(['cvr', 'voltage-deviation', 'cost']);

class QSTSMenu extends Component {
  state = { objective: '' };

  componentDidMount() {
    if (this.props.selectedAnalysisType === QSTS_NWE) this.setState({ objective: 'no-violations' });
  }

  updateObjective = (value) => {
    let newSimulationOptions;
    if ((defaultConstrained.has(this.state.objective) || this.state.objective === '')
      && defaultUnconstrained.has(value)) {
      newSimulationOptions = {
        ...this.props.simulationOptions,
        dont_constrain_currents: true,
        dont_constrain_powers: true,
        dont_constrain_voltages: true,
      };
    } else if (defaultUnconstrained.has(this.state.objective)
      && defaultConstrained.has(value)) {
      newSimulationOptions = {
        ...this.props.simulationOptions,
        dont_constrain_currents: false,
        dont_constrain_powers: false,
        dont_constrain_voltages: false,
      };
    }
    if (newSimulationOptions) {
      this.props.setSimulationOptions(newSimulationOptions);
    }
    this.setState({ objective: value });
  };

  buttonState = state => this.setState({ buttonDisabled: state });

  render() {
    const theme = typeof this.context === 'string' ? this.context : 'dark';
    const {
      substations,
      simulationOptions: { voltage_deviation_target },
      selectedAnalysisType,
    } = this.props;

    const objectives = [
      { value: 'loss', label: 'Loss' },
      { value: 'peak-shaving', label: 'Peak Shaving' },
      { value: 'cvr', label: 'CVR' },
      { value: 'voltage-deviation', label: 'Voltage Optimization' },
      { value: 'cost', label: 'Cost' },
    ];

    return (
      <div className="qsts-menu config-panel">
        {selectedAnalysisType === QSTS_OPF && (
          <>
            <p className="title-text">Optimal Powerflow</p>
            <div className="menu-row">
              <p className="label">Select Objective</p>
              <Select
                theme={theme}
                searchable={false}
                clearable={false}
                options={objectives}
                value={this.state.objective}
                onChange={({ value }) => this.updateObjective(value)}
              />
            </div>

            <p className={`info-text ${this.state.objective ? '' : 'info-text-placeholder'}`}>
              {
                longDescriptions[this.state.objective]
                || 'Select objective to view description'
              }
            </p>
          </>
        )}

        {this.state.objective === 'voltage-deviation'
          && (
          <NumberInput
            label="Voltage Optimization Target"
            value={voltage_deviation_target !== undefined ? voltage_deviation_target : '1.0'}
            unit={pu}
            onChange={
              ({ value }) => this.props.setSimulationOptions({
                ...this.props.simulationOptions,
                voltage_deviation_target: value,
              })
            }
            id="voltage-deviation-target-input"
            step="0.01"
            theme={theme}
          />
          )}

        <OPFOptions
          simulationOptions={this.props.simulationOptions}
          setSimulationOptions={this.props.setSimulationOptions}
          theme={theme}
          // Todo: Support multi substation analysis
          substationId={substations && substations.length ? substations[0].id : undefined}
          disableButton={this.buttonState}
          selectedAnalysisType={selectedAnalysisType}
        />
        <div className="menu-footer">
          <Button
            className="back-btn"
            id="back-btn"
            type="secondary"
            theme={theme}
            onClick={this.props.onPreviousClick}
            label="Previous"
          />
          <Button
            label="Run Analysis"
            id="submit-qsts"
            type="primary"
            theme={theme}
            disabled={this.state.buttonDisabled || !this.state.objective}
            onClick={() => this.props.onSubmit(this.state.objective, this.props.simulationOptions)}
          />
        </div>
      </div>
    );
  }
}

QSTSMenu.contextType = ThemeContext;

QSTSMenu.defaultProps = {
  substations: [],
};

QSTSMenu.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  simulationOptions: PropTypes.object.isRequired,
  setSimulationOptions: PropTypes.func.isRequired,
  substations: PropTypes.array,
  selectedAnalysisType: PropTypes.string.isRequired,
};

export default QSTSMenu;
