import AssetInfo from './AssetInfo';
import WirePosition from './WirePosition';

const configurationTypes = {
  multi: {
    overhead: {
      secondary: 'overheadTriplexSecondary',
      distribution: 'overheadMultiConductor',
    },
    underground: {
      secondary: 'undergroundTriplexSecondary',
      distribution: 'undergroundMultiConductor',
    },
  },
  individual: {
    overhead: { distribution: 'overhead4WireDistribution' },
    underground: { distribution: 'undergroundCable' },
  },
};

export const conductorIsValid = (wireSpacingConfiguration, conductorType) => {
  switch (wireSpacingConfiguration) {
    case 'overheadTriplexSecondary':
      return ['WireInfo'].includes(conductorType);
    case 'overheadMultiConductor':
      return ['WireInfo'].includes(conductorType);
    case 'undergroundTriplexSecondary':
      return ['WireInfo'].includes(conductorType);
    case 'undergroundMultiConductor':
      return ['WireInfo'].includes(conductorType);
    case 'overhead4WireDistribution':
      return ['OverheadWireInfo'].includes(conductorType);
    case 'undergroundCable':
      return ['CableInfo', 'ConcentricNeutralCableInfo', 'TapeShieldCableInfo'].includes(conductorType);
    default:
      return false;
  }
};

export const getConfigurationType = (wireSpacingInfo) => {
  const wire_positions = wireSpacingInfo.wirePositions || wireSpacingInfo.wire_positions || [];
  const { usage } = wireSpacingInfo;
  const spacingType = wire_positions.some((pos) => !pos.phase || pos.phase === 'multi')
    ? 'multi' : 'individual';
  const overOrUnder = wire_positions.some(wirePosition => (wirePosition.yCoord > 0))
    ? 'overhead' : 'underground';
  return configurationTypes[spacingType][overOrUnder][usage];
};

export const isMulti = (wireSpacing) => {
  const configurationType = getConfigurationType(wireSpacing) || '';
  return (configurationType.includes('Multi') || configurationType.includes('Triplex'));
};

class WireSpacingInfo extends AssetInfo {
  get wirePositions() {
    try {
      return this.references['WireSpacingInfo.WirePositions'].map(wpid => (
        new WirePosition(wpid, this.cimDict)
      ));
    } catch (err) {
      return [];
    }
  }

  get isMultiConductor() {
    return this.wirePositions.some(wirePosition => (wirePosition.phase === wirePosition.MULTI));
  }

  get configurationType() {
    return getConfigurationType(this);
  }

  get isCable() {
    return this.attributes['WireSpacingInfo.isCable'];
  }

  get phaseWireCount() {
    return this.attributes['WireSpacingInfo.phaseWireCount'];
  }

  get phaseWireSpacing() {
    return this.attributes['WireSpacingInfo.phaseWireSpacing'];
  }

  get usage() {
    return this.attributes['WireSpacingInfo.usage'] || 'distribution';
  }

  get WirePositions() {
    return this.wirePositions.reduce((lu, position) => {
      lu[position.phase] = position;
      return lu;
    }, {
      A: null, B: null, C: null, N: null,
    });
  }
}

export default WireSpacingInfo;
