import React, {
  FunctionComponent, useState, useEffect, useContext,
} from 'react';
import { Moment } from 'moment';
import ResultsCard from 'components/ResultsCard';
import { ThemeProp } from 'types/index';
import asyncActionStates from 'helpers/asyncActionStates';
import { violationCategory, violationTypes, violationsCountCreator } from 'routes/WorkspaceLayout/routes/Network/helpers/PowerflowHelpers';
import Request from 'helpers/Request';
import ValueCard from './ValueCard';
import ViolationChart from './ViolationChart';
import { ResultsComparisonContext } from '../context/ResultsComparisonContext';

type ViolationsMagType = {
  name: number,
  value: number,
}

type ViolationsProps = {
  theme: ThemeProp,
  violationLimit: number | null,
  violationType: string,
  setViolationType: (value: string) => void,
  isBaseline: boolean,
  workspace: string | null,
  resultsBranch: string | null,
  maxRangeSelected: {
    start?: Moment|null,
    end?: Moment|null,
  },
  scenario: string|null,
  analysis: {[key: string]: any}|null,
  feeders?: [],
  violationsError: boolean,
  setTooManyViolationsError: (error: boolean) => void,
}

const Violations: FunctionComponent<ViolationsProps> = ({
  theme,
  violationLimit,
  violationType,
  setViolationType,
  isBaseline,
  workspace,
  resultsBranch,
  feeders,
  maxRangeSelected,
  scenario,
  analysis,
  violationsError,
  setTooManyViolationsError,
}) => {
  const [violationsMag, setViolationsMag] = useState<{[key: string]: any}>({});
  const [violationsCount, setViolationsCount] = useState([] as any);
  const [loading, setLoading] = useState(asyncActionStates.INITIAL);

  const { baselineResults: { baselineViolations }, setBaselineResults } = useContext(ResultsComparisonContext);
  const resultsType = 'baselineViolations';

  useEffect(() => {
    const fetchViolations = async () => {
      const categories = Object.values(violationCategory).map(cat => cat.label);
      const vMag: any = categories.reduce((cats: any, cat: string) => {
        cats[cat] = [];
        return cats;
      }, {});
      const url = `/api/workspace/${workspace}/branch/${resultsBranch}`
      + '/power-flow-results/violations/summary';
      const params = {
        feeder: feeders?.map((fdr: any) => fdr?.id) || [],
        start_date: maxRangeSelected?.start?.toISOString(),
        end_date: maxRangeSelected?.end?.toISOString(),
        scenario_id: scenario,
        analysis_name: analysis?.name,
      };
      try {
        setLoading(asyncActionStates.LOADING);
        const request = new Request(url);
        const results = await request.get({ params });
        const violations = results.data;
        // Create a dictionary of all violations grouped by Violation Type
        violations.forEach((v: any) => {
          const type = violationTypes.get(v.violation) || 'Other';
          if (!vMag[type]) {
            vMag[type] = [];
          }
          vMag[type].push({ name: v.amount / 100, value: 1 });
        });
        setViolationsMag(vMag);
        const vCount = violationsCountCreator(vMag);
        setViolationsCount(vCount);
        if (isBaseline) {
          const newBaseline = {
            ...baselineViolations,
            violationsMag: vMag,
            violationsCount: vCount,
          };
          setBaselineResults(resultsType, newBaseline);
        }
        setLoading(asyncActionStates.SUCCESS);
        setTooManyViolationsError(false);
      } catch (err) {
        setLoading(asyncActionStates.ERROR);
        if (err?.response?.data?.message === 'There are too many violations in the timeframe selected.') {
          setTooManyViolationsError(true);
        }
      }
    };
    setViolationsMag({});
    setViolationsCount([]);
    if (workspace && resultsBranch && scenario && maxRangeSelected && analysis) {
      fetchViolations();
    }
  // baselineViolations intentionally excluded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspace,
    resultsBranch,
    feeders,
    scenario,
    maxRangeSelected,
    analysis,
    isBaseline,
    setBaselineResults,
    setLoading,
  ]);
  const violationsTotal = violationsCount?.reduce((total: number, type: any) => (total + type.value), 0) || null;

  const baselineViolationsTotal = baselineViolations?.violationsCount?.reduce(
    (total: number, type: ViolationsMagType) => (total + type.value), 0,
  ) || null;

  const violationsTotalOverLimit = Object.keys(violationsMag).reduce((total, type: string) => {
    const typeViolations = violationsMag[type]?.reduce((percent_total: number, percentOver: ViolationsMagType) => {
      if (violationLimit && percentOver.name >= violationLimit) percent_total += percentOver.value;
      return percent_total;
    }, 0);
    return total + typeViolations;
  }, 0) || null;

  const baselineViolationsTotalOverLimit = (baselineViolations?.violationsMag
    && Object.keys(baselineViolations?.violationsMag).reduce((total, type: string) => {
      const typeViolations = baselineViolations?.violationsMag[type]
        ?.reduce((percent_total: number, percentOver: ViolationsMagType) => {
          if (violationLimit && percentOver.name >= violationLimit) percent_total += percentOver.value;
          return percent_total;
        }, 0);
      return total + typeViolations;
    }, 0)) || null;

  const placeholderElement = (
    <div className="no-violations-placeholder">{`${!isBaseline ? 'This analysis or the baseline analysis has no violations data to display' : 'This analysis has no violations data to display'}`}</div>
  );
  return (
    <ResultsCard
      theme={theme}
      expandableContents={baselineViolationsTotal ? (
        <ViolationChart
          violationsMag={violationsMag}
          theme={theme}
          baselineViolations={baselineViolations}
          violationType={violationType}
          setViolationType={setViolationType}
          isBaseline={isBaseline}
          loading={loading}
        />
      ) : placeholderElement}
      className="results-comparison-card summary-card"
    >
      <div className="eight grid-columns no-margin">
        <ValueCard
          includeWarning
          value={violationsTotal ?? 0}
          baselineValue={baselineViolationsTotal}
          tooltipMsg={violationsTotal ? '' : 'This analysis has no violations'}
          warnLowSeverity={!violationsTotal}
          label="Violations"
          showComparisonPercent
          loading={loading === asyncActionStates.LOADING}
          error={violationsError}
        />
        {!!violationLimit && (
          <ValueCard
            includeWarning
            value={violationsTotalOverLimit ?? 0}
            baselineValue={baselineViolationsTotalOverLimit}
            warnLowSeverity={!violationsTotal}
            label={`Over limit (${violationLimit}%)`}
            showComparisonPercent
            loading={loading === asyncActionStates.LOADING}
            error={violationsError}
          />
        )}
      </div>
    </ResultsCard>
  );
};

export default Violations;
