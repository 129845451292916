import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateContainer } from 'routes/WorkspaceLayout/routes/Network/modules/networkEdit';

import VoltageLimits from './VoltageLimits';

export default connect(
  state => ({
    branch: state.network.branch,
    workspace: state.network.workspace,
    request: state.network.applyDifferenceModelRequest.updateSubstation,
  }),
  dispatch => ({ ...bindActionCreators({ updateContainer }, dispatch) }),
)(VoltageLimits);
