import React, {
  useState, useEffect, useContext, createContext,
} from 'react';
import { useSelector } from 'react-redux';
import Request from 'helpers/Request';
import apm from 'helpers/apm';
import asyncActionStates from 'helpers/asyncActionStates';

// Do not use the raw context in consumers as there is some setup required.
const AssetContext = createContext();

// Hook to initalize and consume this context.
export const useAsset = (assetId, assetType) => {
  const {
    _setAssetInfo, assetInfo, asset, loading, refetch,
  } = useContext(AssetContext);
  if (!assetInfo || assetId !== assetInfo.assetId) {
    _setAssetInfo({ assetId, assetType });
    return {
      asset: undefined,
      loading,
      refetch,
    };
  }
  return {
    asset, loading, refetch,
  };
};

// eslint-disable-next-line react/prop-types
export default function AssetContextProvider({ children }) {
  const [asset, setAsset] = useState();
  const [loading, setLoading] = useState(asyncActionStates.INITIAL);
  const [assetInfo, _setAssetInfo] = useState();
  const [activeRequest, setActiveRequest] = useState(null);
  const { assetId, assetType } = assetInfo || {};
  const {
    workspace,
    branch,
    inEditMode,
    applyDifferenceModelRequest,
  } = useSelector(state => ({
    workspace: state.network.workspace,
    branch: state.network.branch,
    inEditMode: state.edit.inEditMode,
    applyDifferenceModelRequest: state.network.applyDifferenceModelRequest,
  }));
  const updateReq = applyDifferenceModelRequest.editValues;

  const getAsset = async () => {
    setLoading(asyncActionStates.LOADING);
    // cancel the old request
    if (activeRequest) {
      activeRequest.cancel();
    }

    let data;
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset_panel/${assetType}/${assetId}`,
    );
    setActiveRequest(request);
    try {
      const res = await request.get({
        params: {
          editing: inEditMode,
        },
      });

      data = res.data;
      if (typeof data !== 'object') {
        const err = new Error(
          JSON.stringify({
            message: 'Asset Panel GET API returned malformed response',
            response: data,
            workspace,
            branch,
            assetType,
            assetId,
          }),
        );
        apm.captureError(err);
        throw err;
      }
      setAsset(data);
      setLoading(asyncActionStates.SUCCESS);
    } catch (error) {
      // check if the current request was cancelled
      if (!request?.wasCancelled) {
        setAsset();
        setLoading(asyncActionStates.ERROR);
      }
    }
  };

  // Initial Asset data request
  useEffect(() => {
    let didCancel = false;
    if (didCancel) {
      return () => {};
    }
    if (assetId) {
      getAsset();
    }
    return () => {
      didCancel = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, assetId]);

  // Refetch Asset data after an update
  useEffect(() => {
    let didCancel = false;
    if (didCancel) {
      return () => {};
    }
    if (assetId && updateReq === asyncActionStates.SUCCESS) {
      getAsset();
    }
    return () => {
      didCancel = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReq, assetId]);

  return (
    <AssetContext.Provider
      value={{
        asset,
        loading,
        refetch: () => getAsset(),
        _setAssetInfo: (aInfo) => {
          setAsset();
          _setAssetInfo(aInfo);
        },
        assetInfo,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
}
