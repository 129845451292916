import React, { Component, ReactNode } from 'react';
import { CimJSONObject } from '../cim';

type ClassInstanceProps = {
  id: string;
  name: string;
  selectedInstance?: CimJSONObject;
  selectedID: string|null;
  setSelectedInstance: (id: string|null) => void;
  setHoveredInstance: (id: string|null) => void;
  style: React.CSSProperties;
};

/**
 * Display the name of a CIM instance with a togglable list of its
 * attributes and references.
 */
class CIMClassInstance extends Component<ClassInstanceProps> {
  /**
   * Set or clear the current instance and set the toggle state
   */
  handleClick = (id: string): void => {
    if (this.props.selectedID === id) {
      this.props.setSelectedInstance(null);
    } else {
      this.props.setSelectedInstance(id);
    }
  }

  /**
   * Set the current instance on hover if there are no selected instances
   */
  handleMouseEnter = (id: string): void => {
    if (!this.props.selectedInstance) {
      this.props.setHoveredInstance(id);
    }
  }

  /**
   * Clear the currently selected instance on mouse leave if the current instance
   * was not clicked
   */
  handleMouseLeave = (): void => {
    this.props.setHoveredInstance(null);
  }

  render(): ReactNode {
    const {
      selectedID, selectedInstance, id, name,
    } = this.props;
    const status = selectedInstance && selectedID === id ? 'active' : 'inactive';
    return (
      <div
        style={this.props.style}
        className="cim-types"
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={() => this.handleMouseEnter(id)}
      >
        <button
          className={`cim-types__button cim-types--${status}`}
          onClick={() => this.handleClick(id)}
          title={name}
          type="button"
        >
          <i className="material-icons">crop_square</i>
          {name}
        </button>
      </div>
    );
  }
}

export default CIMClassInstance;
