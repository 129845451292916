import React from 'react';
import PropTypes from 'prop-types';
import './FileForm.scss';

// Create a button like file form that wraps a custom element in the label.
const FileForm = props => (
  <form
    encType="multipart/form-data"
    className={`file-form-btn ${props.className} ${props.disabled && 'file-form-btn--disabled'}`}
    onChange={props.onChange}
  >
    <input
      ref={props.createRef}
      className="file-form-input"
      type="file"
      name="files[]"
      id={props.id}
      accept={props.accept}
    />
    <label className="file-form-input__label" htmlFor={props.id}>
      {props.children}
    </label>
  </form>
);

FileForm.defaultProps = {
  accept: '*',
  className: '',
  disabled: false,
  createRef: () => null,
};

FileForm.propTypes = {
  accept: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  createRef: PropTypes.func,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default FileForm;
