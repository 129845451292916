import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import Input from 'components/Input';
import TextInput from 'components/TextInput';

import { kVll } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';

import SingleEditableValue from '../../Inputs/SingleEditableValue';
import AssetSelector from '../partials/AssetSelector';
import ListValue from '../partials/ListValue';

const formatCoords = (lat, long) => {
  if (!lat || !long) {
    return '';
  }
  const [latVal, longVal] = [lat, long].map((val, index) => {
    const directionMap = {
      0: ['N', 'S'],
      1: ['E', 'W'],
    };

    const parsedVal = Number.parseFloat(val);
    const isNeg = Math.sign(parsedVal) === -1;
    const parsedStr = (isNeg ? Math.abs(parsedVal) : parsedVal).toFixed(6);
    const directions = directionMap[index];
    return isNeg ? `${parsedStr} ${directions[1]}` : `${parsedStr} ${directions[0]}`;
  });
  return `${latVal}, ${longVal}`;
};

const InstanceInfo = ({
  disabled,
  asset,
  assetId,
  toggleFeederPanel,
  inEditMode,
  handleSave,
  theme,
}) => {
  const coordRef = useRef(null);
  const copyRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [latitude, setLatitude, latitudeEdited] = useTrackingState(asset.coordinates?.latitude);
  const [longitude, setLongitude, longitudeEdited] = useTrackingState(asset.coordinates?.longitude);

  const getCoordinateString = (format = false) => {
    const {
      coordinates: { latitude: lat, longitude: long },
    } = asset;
    const baseStr = lat === undefined || long === undefined ? '' : `${lat}, ${long}`;
    return format ? formatCoords(lat, long) : baseStr;
  };

  const copyCoordinates = () => {
    copyRef.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  return (
    <div>
      <div className="header-container">
        {inEditMode ? (
          <AssetSelector
            name="Feeder/Substation"
            disabled={disabled}
            assetList={asset.containers}
            onChange={container => handleSave(container)}
            selectedAsset={asset.container.id}
            id={assetId}
            linkedAssetType="container"
          />
        ) : (
          <ListValue onClick={toggleFeederPanel} values={asset.container} label="Feeder/Substation" />
        )}
        <div className="phase-coordinates">
          <TextInput
            label="Phase"
            className="display-only"
            value={asset.phase}
            id="phase"
            inputStyle="panel"
            theme={theme}
            disabled
          />

          {!inEditMode
            && (
            <div style={{ display: 'flex', width: '355px', alignItems: 'center' }}>
              <>
                <TextInput
                  label="Coordinates"
                  className="display-only"
                  value={getCoordinateString(true)}
                  id="node-coordinates"
                  inputStyle="panel"
                  theme={theme}
                  ref={coordRef}
                  disabled
                />
                <IconButton
                  icon="assignment"
                  onClick={copyCoordinates}
                  tooltip={copied ? 'Copied!' : 'Copy Coordinates'}
                  theme={theme}
                />
                <input
                  readOnly
                  style={{
                    position: 'absolute', top: 0, left: 0, height: 10, width: 10, opacity: 0,
                  }}
                  value={getCoordinateString()}
                  ref={copyRef}
                />
              </>
            </div>
            )}
          {inEditMode
            && (
            <>
              <SingleEditableValue
                theme={theme}
                type="text"
                id="latitude"
                label="Latitude"
                value={latitude}
                edited={latitudeEdited}
                onBlur={() => {
                  handleSave({
                    position: {
                      latitude,
                      longitude: asset.coordinates?.longitude,
                    },
                  });
                }}
                onChange={setLatitude}
                invalid={Number.isNaN(Number(latitude))}
              />
              <SingleEditableValue
                theme={theme}
                type="text"
                id="longitude"
                label="Longitude"
                value={longitude}
                edited={longitudeEdited}
                onBlur={() => {
                  handleSave({
                    position: {
                      latitude: asset.coordinates?.latitude,
                      longitude,
                    },
                  });
                }}
                onChange={setLongitude}
                invalid={Number.isNaN(Number(longitude))}
              />
            </>
            )}
        </div>
      </div>

      <hr className="section-divider" />

      <div className="editable-section">
        <Input
          label="Nominal Voltage"
          value={asset.base_voltage}
          key="baseVoltage"
          id="baseVoltage"
          type="number"
          unit={kVll}
          inputStyle="panel"
          theme={theme}
          disabled
          options={{
            divisor: 1000,
          }}

        />
      </div>
    </div>
  );
};

InstanceInfo.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.shape({
    container: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    containers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    phase: PropTypes.string,
    coordinates: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    base_voltage: PropTypes.number,
  }).isRequired, // JSCIM Object
  assetId: PropTypes.string.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default InstanceInfo;
