import React, { Component, ReactNode } from 'react';
import { List, AutoSizer } from 'react-virtualized';

import { ParsedReference } from '../references';

type ReferenceListProps = {
  followReferenceLink: (id: string) => void;
  property: string;
  references: ParsedReference[];
};
type ReferenceListState = {
  expanded: boolean;
};

/**
 * @class CIMReferenceListItem
 * Displays references for a class in {@see CIMReferenceList}.
 * When the reference list is clicked, if there is a single item
 * We navigate to that object. If there are multiple we expand to the list
 * and the user can click on an item to navigate to that reference
 */
class CIMReferenceListItem extends Component<ReferenceListProps, ReferenceListState> {
  state = { expanded: false };

  getAdditionalInfo = (reference: ParsedReference): string => {
    let info = '';

    if (reference.phase) {
      info += ` (${reference.phase})`;
    }

    if (reference.sequenceNumber) {
      info += ` - ${reference.sequenceNumber}`;
    }

    return info;
  }

  getSubItems(references: ParsedReference[]): ReactNode {
    return (
      <div
        style={{ height: Math.min(references.length * 40, 120) }}
        className="cim-reference-list"
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="gray-scrollbars"
              overscanColumnCount={10}
              rowCount={references.length}
              rowHeight={30}
              width={width}
              height={height}
              scrollToAlignment="start"
              rowRenderer={({ index, key, style }) => {
                const displayString = `${references[index].name || references[index].id}: ${references[index].extraData}`;
                return (
                  <button
                    key={key}
                    style={style}
                    className="cim-types__button cim-reference-list-item"
                    id={references[index].id}
                    onClick={() => this.handleSubLevelClick(references[index].id)}
                    title={displayString}
                    type="button"
                  >
                    <i className="material-icons">stop</i>
                    <span className="ref-name">{displayString}</span>
                    <span style={{ flexShrink: 0 }}>
                      {this.getAdditionalInfo(references[index])}
                    </span>
                  </button>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    );
  }

  handleTopLevelClick = (): void => this.setState(prevState => ({ expanded: !prevState.expanded }));

  handleSubLevelClick = (id: string): void => this.props.followReferenceLink(id);

  render(): ReactNode {
    const { references, property } = this.props;
    const { expanded } = this.state;
    const displayString = `${property} (${references.length})`;
    const caret = expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right';

    return (
      <div className="cim-reference">
        <div className="cim-reference-list-header">
          <i className="material-icons">{caret}</i>
          <i className="material-icons">crop_square</i>
          <button
            className="cim-reference__button"
            onClick={this.handleTopLevelClick}
            type="button"
          >
            {displayString}
          </button>
        </div>
        {expanded && this.getSubItems(references)}
      </div>
    );
  }
}

export default CIMReferenceListItem;
