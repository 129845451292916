import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const ArrowDown: FunctionComponent<IconPropsSchema> = ({
  height = '12px',
  width = '25px',
  color = '#000000',
}) => (
  <svg width={width} height={height} viewBox="0 0 9 10" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Custom-Preset-Copy-3" transform="translate(-698.000000, -1591.000000)" fill={color}>
        <g id="dropdown/primary/selected" transform="translate(509.000000, 1567.000000)">
          <g id="dropdown/icon" transform="translate(189.000000, 24.000000)">
            <polygon id="Rectangle-Copy-22" transform="translate(4.500000, 4.500000) rotate(45.000000) translate(-4.500000, -4.500000) " points="7 2 7 7 2 7" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowDown;
