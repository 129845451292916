import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, TabList, Tab, TabPanel,
} from 'react-tabs';
import PermissionDisabledTip from '../PermissionDisabledTip';
import './ThemedTabs.scss';

const ThemedTabs = props => (
  <Tabs
    className={`themed-tabs ${props.className ? `${props.className} ` : ''}${props.theme} ${
      props.underline
    }`}
    defaultIndex={!props.selectedIndex && !props.onSelect ? props.defaultIndex : null}
    onSelect={props.onSelect ? tabIndex => props.onSelect(tabIndex) : null}
    selectedIndex={props.selectedIndex}
  >
    <TabList>
      <div className="tab-container">
        {props.tabs.map(tab => (tab.disabled && !!tab.permissionTipOptions ? (
          <Tab disabled={tab.disabled} key={tab.name}>
            <PermissionDisabledTip
              {...tab.permissionTipOptions}
              key={tab.name}
            >
              {tab.name}
            </PermissionDisabledTip>
          </Tab>
        ) : (
          <Tab disabled={tab.disabled} key={tab.name}>
            {tab.name}
          </Tab>
        )))}
      </div>
      {props.headerRenderer && (
        <div className="extra-content-container">
          {props.headerRenderer()}
        </div>
      )}
    </TabList>
    {props.children(TabPanel)}
  </Tabs>
);

ThemedTabs.defaultProps = {
  theme: 'light',
  className: null,
  underline: 'shadow',
  defaultIndex: 0,
  onSelect: null,
  selectedIndex: null,
  headerRenderer: null,
};

ThemedTabs.propTypes = {
  className: PropTypes.string,
  underline: PropTypes.oneOf(['line', 'shadow']),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      disabled: PropTypes.bool,
      permissionTipOptions: PropTypes.shape({
        hide: PropTypes.bool,
        title: PropTypes.string,
        placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'none']),
        children: PropTypes.element,
      }),
    }),
  ).isRequired,
  children: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  defaultIndex: PropTypes.number,
  onSelect: PropTypes.func,
  selectedIndex: PropTypes.number,
  headerRenderer: PropTypes.func,
};

export default ThemedTabs;
